import React from "react";

import IntlMessage from "util/IntlMessage";

export default function getData(USER_PERMIT) {
  const sidebarArr = [];
  if (USER_PERMIT && USER_PERMIT.SEARCH_DIAMOND && USER_PERMIT.SEARCH_DIAMOND.view) {
    sidebarArr.push({
      name: <IntlMessage id="app.Search" />,
      url: "/diamond-search",
      subMenu: true,
    });
  }
  if (USER_PERMIT && USER_PERMIT.NEW_ARRIVAL && USER_PERMIT.NEW_ARRIVAL.view) {
    sidebarArr.push({
      name: <IntlMessage id="app.new-Arrival" />,
      url: "/new-arrival",
      subMenu3: false,
      showFlag: true,
      id: 1,
    });
  }
  if (USER_PERMIT && USER_PERMIT.BID_TO_BUY && USER_PERMIT.BID_TO_BUY.view) {
    sidebarArr.push({
      name: <IntlMessage id="app.bid-to-buy" />,
      url: "/bid-to-buy",
      subMenu3: false,
      showFlag: true,
      id: 2,
    });
  }
  // sidebarArr.push({
  //   name: "KGK Exclusive",
  //   url: "/KGK-exclusive",
  //   //subMenu3: true,
  // });
  // sidebarArr.push({
  //   name: <IntlMessage id="app.fancy-diamonds" />,
  //   url: "/fancy-search",
  //   subMenu1: true,
  // });
  // sidebarArr.push({
  //   name: <IntlMessage id="app.Upcoming" />,
  //   url: "/upcoming",
  //   subMenu4: false,
  // });
  sidebarArr.push({
    name: <IntlMessage id="app.myAccount" />,
    url: "/my-account/summary",
    subMenu4: false,
  });
  // sidebarArr.push({
  //   name: <IntlMessage id="app.Exclusive" />,
  //   url: "/diamond-search",
  //   subMenu2: true,
  // });
  return sidebarArr;
}
