import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import urlJoin from "url-join";

import _ from "lodash";

import SendEmailPopup from "./SendEmail";

import { BASE_URL, PAGES } from "../../../constants/Common";
import { EXPORT_EXCEL } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
// import { LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from "../CommonButton/OpenNotification";

const LISTINGPAGES = PAGES;

const ShareDropdown = (props) => {
  const [sendEmail, setSendEmail] = useState(false);

  const onDownloadClick = (emailData) => {
    let checked = [];
    if (props.checked && props.checked.length !== 0) {
      checked = props.checked;
    } else if (props.noCheckedForDownload && props.noCheckedForDownload.length !== 0) {
      checked = props.noCheckedForDownload;
    } else {
      OpenNotification({
        type: "error",
        title: "No stone(stones) to share.",
      });
    }
    if (checked && checked.length !== 0) {
      const obj = {
        isExcel: true,
        img: false,
        certFile: false,
        videoFile: false,
        pltFile: false,
        sideFile: false,
        frontFile: false,
        prpFile: false,
        faceUp: false,
        zip: false,
      };

      if (sendEmail) {
        obj.sendEmail = true;
        obj.email = {
          ...emailData,
        };
      }
      const id = _.map(checked, "vStnId");
      const temReq = {
        filter: _.isEmpty(id) ? { vStnId: [...id], isFcCol: false } : { vStnId: [...id], isFcCol: false },
        ...obj,
      };
      if (props.orderThi) {
        const orderDiamondId = _.map(checked, "id");
        const orderDiamondStatus = _.map(checked, "status");
        if (orderDiamondStatus.includes(2)) {
          temReq.orderDiamond = orderDiamondId;
        }
      }

      const objData = {
        ...EXPORT_EXCEL,

        request: {
          ...temReq,
        },
      };
      UtilService.callApi(objData, async (err, data) => {
        if (err) {
          return OpenNotification({
            type: "error",
            title: err.data.message || err.data.data.message || "Failed to Share.",
          });
        }
        if ((await data) && data.code === "OK") {
          const file_path = urlJoin(BASE_URL, data.data.data);

          if (!obj.sendEmail) {
            window.open(file_path, "Download");
          }
          if (props.onCloseSharePopup) {
            props.onCloseSharePopup();
          }
          setSendEmail(false);
          if (props.clearAll) {
            props.clearAll();
          }
          OpenNotification({
            type: "success",
            title: data.message,
          });
        } else {
          OpenNotification({
            type: "error",
            title: data.message,
          });
        }
      });
    }
  };
  const WhatsAppShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: "error",
        title: "No stone(stones) to share.",
      });
    } else if (props.checked.length === 1) {
        const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${props.checked[0].id}`;
        window.open(`https://web.whatsapp.com/send?text=${path}`);
      } else if (props.checked.length >= 2) {
        let msg = "KGK : Diamonds Detail%0A%0A";

        props.checked.forEach((element) => {
          const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${element.id}`;
          msg += path + "%0A%0A";
        });
        window.open(`https://web.whatsapp.com/send?text=${msg}`);
      }
  };
  const SkypeShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: "error",
        title: "No stone(stones) to share.",
      });
    } else if (props.checked.length === 1) {
        const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${props.checked[0].id}`;
        window.open(`https://web.skype.com/share?url=${path}`);
      } else if (props.checked.length >= 2) {
        let msg = "KGK : Diamonds Detail%0A%0A";

        props.checked.forEach((element) => {
          const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${element.id}`;
          msg += path + "%0A%0A";
        });
        window.open(`https://web.skype.com/share?url=${msg}`);
      }
  };
  // const Wechat = () => {
  //   if (props.checked && props.checked.length === 0) {
  //     OpenNotification({
  //       type: "error",
  //       title: "No stone(stones) to share.",
  //     });
  //   } else if (props.checked.length === 1) {
  //       const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${props.checked[0].id}`;
  //       window.open(`https://web.wechat.com/send?text=${path}`);
  //     } else if (props.checked.length >= 2) {
  //       let msg = "KGK : Diamonds Detail%0A%0A";

  //       props.checked.forEach((element) => {
  //         const path = `${window.location.origin}/${LISTINGPAGES.DETAIL}/${element.id}`;
  //         msg += path + "%0A%0A";
  //       });
  //       window.open(`https://web.wechat.com/send?text=${msg}`);
  //     }
  // };
  return (
    <>
      <div>
        <div className="searchPopupCommon">
          <div className="shareButtonIcon">
            <button className="shareButtonItem" onClick={() => setSendEmail(!sendEmail)}>
              Email
            </button>
            <button onClick={WhatsAppShare} className="shareButtonItem ml-5">
              WhatsApp
            </button>
            <button onClick={SkypeShare} className="shareButtonItem ml-5">
              Skype
            </button>
            {/* <button onClick={Wechat} className="shareButtonItem ml-5">
              WeChat
            </button> */}
          </div>
        </div>
      </div>
      <SendEmailPopup sucess={onDownloadClick} sendEmail={sendEmail} onCancel={() => setSendEmail((v) => !v)} />
    </>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(ShareDropdown));
