import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import find from "lodash/find";
import findIndex from "lodash/findIndex";

import IntlMessage from "util/IntlMessage";

import { HeadingCalc } from "./ConfirmStonePopup";
import WatchPopupMobile from "./WatchPopupMobile";

import { trackTypeObj } from "../../../constants/Common";
import { handleInsertTrack, getColumn, isMobile } from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import OpenNotification from "../CommonButton/OpenNotification";

const currentType = "WatchlistPopup";

const WatchlistPopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [exp, setExp] = useState(true);
  const [data, setData] = useState(props.checked.map((x) => ({ ...x, expBack: x.back - 1 })));
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];

  // const getBackSelect = (row) => {
  //   return (
  //     <select
  //       dropdownClassName="popupZindex"
  //       value={row.expBack}
  //       onChange={(e) => {
  //         let val = e.target.value;
  //         let line = data;
  //         let ind = findIndex(line, { id: row.id });
  //         line[ind].expBack = val;
  //         setData(line);
  //         setExp(!exp);
  //       }}
  //       style={{ width: 120 }}
  //     >
  //       <option value={row.back - 1}>{(row.back - 1).toFixed(2)}</option>
  //       <option value={row.back - 2}>{(row.back - 2).toFixed(2)}</option>
  //       <option value={row.back - 3}>{(row.back - 3).toFixed(2)}</option>
  //     </select>
  //   );
  // };

  const getBackSelect = useCallback((row) => {
    return (
      <select
        dropdownClassName="popupZindex"
        value={row.expBack}
        onChange={(e) => {
          const val = e.target.value;
          const line = data;
          const ind = findIndex(line, { id: row.id });
          line[ind].expBack = val;
          setData(line);
          setExp(!exp);
        }}
        style={{ width: 120 }}
      >
        <option value={row.back - 1}>{(row.back - 1).toFixed(2)}</option>
        <option value={row.back - 2}>{(row.back - 2).toFixed(2)}</option>
        <option value={row.back - 3}>{(row.back - 3).toFixed(2)}</option>
      </select>
    );
  }, []);

  useEffect(() => {
    const Columns = getColumn();
    let index = findIndex(Columns, { id: "back" });
    if (!index) index = 1;
    let columns = [
      ...Columns.slice(0, index + 1),
      {
        Header: "Exp Disc%",
        accessor: "expBack",
        id: "expBack",
        Cell: ({ row }) => getBackSelect(row.original),
      },
    ];
    columns = [...columns, ...Columns.filter((el) => !find(columns, { id: el.id }))];
    setColumns(columns);
  }, [exp, getBackSelect]);

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <span
          onClick={() => {
            if (!checked.length) {
              OpenNotification({ type: "error", title: "Please select stone(s) to add in watch." });
              return;
            }
            handleInsertTrack(
              trackTypeObj.WATCHLIST,
              checked.map((c) => ({ ...c, expBack: find(data, { id: c.id }).expBack })),
              (status) => {
                props.onClose();
                props.clearAll();
                if (status) props.setCount(!props.deleteReminder.count);
              }
            );
          }}
          className="commonButton"
        >
          Add
        </span>
        <span className="commonButton" onClick={props.onClose}>
          Cancel
        </span>
      </div>
    );
  };

  return isMobile() ? (
    <WatchPopupMobile data={data} currentType={currentType} parent={{ btnAction, getBackSelect }} />
  ) : (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <h2 className="popupInnerTitle mr-40">
          <IntlMessage id="app.WatchList" />
        </h2>
        {HeadingCalc(checked)}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table
            data={data}
            columns={columns}
            areAllChecked
            noGrp
            canSort={false}
            currentType={currentType}
            noCheckBox
          />
        </div>
        <div className="d-flex justify-content-between offerBottomBox flex-wrap mt-20">
          <p className="offerNote">
            <b>Note:</b>
            {' '}
            Any stone(s) put in Watch List is not kept on hold for you as it is available for other
            customers as well.
          </p>
        </div>
        {btnAction()}
      </div>
    </div>
  );
};

export default WatchlistPopup;
