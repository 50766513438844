import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Select } from "antd";
// import ipapi from 'ipapi.co';
import _ from "lodash";

import { switchLanguage } from "../../appRedux/actions/Common";
import languageData from "../../constants/languageData";

const { Option } = Select;

const LangDropdown = (props) => {
  // const [country_code, setCountryCode] = useState('');
  const [langDefaultVal, setLangDefaultVal] = useState(
    (props.commonData.locale && props.commonData.locale.name) || languageData[0].name
  );

  useEffect(() => {
    const fetchForIP = async () => {
      const response = await fetch("https://geolocation-db.com/json/");
      const res = await response.json();
      if (res && res.country_code) {
        const findCountry = _.find(languageData, { country_code: res.country_code });
        // setCountryCode(res.country_code);
        if (findCountry && findCountry.languageId) {
          setLangDefaultVal(findCountry.name);
          props.switchLanguage(findCountry.languageId);
        }
      }
    };

    fetchForIP();
  }, []);

  return (
    <Select
      className="lang-steps dropdownLag"
      dropdownClassName="dropUibox"
      defaultValue={langDefaultVal}
      style={{ width: 100 }}
      onChange={props.switchLanguage}
    >
      {languageData.map((language) => (
        <Option key={language.languageId} value={language.languageId}>
          {language.name}
        </Option>
      ))}
    </Select>
  );
};
// class LangDropdown extends Component {
//   state = {
//     country_code: '',
//     langDefaultVal: (this.props.commonData.locale && this.props.commonData.locale.name) || languageData[0].name,
//   };

//   componentDidMount() {
//     const self = this;
//     const callback = function (res) {
//       if (res && res.country_code) {
//         const findCountry = _.find(languageData, { country_code: res.country_code });
//         const setLanguage = {
//           country_code: res.country_code,
//         };
//         if (findCountry && findCountry.languageId) {
//           setLanguage.langDefaultVal = findCountry.name;
//           self.props.switchLanguage(findCountry.languageId);
//         }
//         self.setState(setLanguage);
//       }
//     };

//     ipapi.location(callback);
//   }

//   render() {
//     return (
//       <Select
//         className="lang-steps dropdownLag"
//         dropdownClassName="dropUibox"
//         defaultValue={this.state.langDefaultVal}
//         style={{ width: 100 }}
//         onChange={this.props.switchLanguage}
//       >
//         {languageData.map((language) => (
//           <Option key={language.languageId} value={language.languageId}>
//             {language.name}
//           </Option>
//         ))}
//       </Select>
//     );
//   }
// }

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps, { switchLanguage })(LangDropdown);
