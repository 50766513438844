import React, { Component } from "react";
import { connect } from "react-redux";

import urlJoin from "url-join";

import IntlMessage from "util/IntlMessage";

import RoundIcon from "./RoundIcon";

import emailSVG from "../../../assets/svg/Dashboard/email.svg";
import messageSVG from "../../../assets/svg/Dashboard/message.svg";
import skypeSVG from "../../../assets/svg/Dashboard/skype.svg";
import userSVG from "../../../assets/svg/Dashboard/user.svg";
import whatsappSVG from "../../../assets/svg/Dashboard/whatsapp.svg";
import { BASE_URL } from "../../../constants/Common";
import Heading from "../../common/Heading";

class KAMDetail extends Component {
  state = {
    picError: false,
  };
  render() {
    let sales = {};
    if (this.props.kam) {
      sales = this.props.sale;
    } else {
      sales = this.props.sales;
    }
    return (
      <div className="dashboardCardTop align-items-center">
        <Heading title={<IntlMessage id="app.salesPersonDetails" />} className="dashboardCardTop" />
        {Object.keys(this.props.sale.salesPerson).length ? (
          <div className="d-flex flex-wrap width-100 justify-content-center">
            <div className="kamDetailBlock">
              {sales.salesPerson && sales.salesPerson.firstName && (
                <div className="KamDetailItem">
                  {sales.salesPerson.photoId ? (
                    !this.state.picError ? (
                      <span>
                        <img
                          onError={() => {
                            this.setState({ picError: true });
                          }}
                          src={urlJoin(BASE_URL, sales.salesPerson.photoId)}
                          alt=""
                        />
                      </span>
                    ) : (
                      <span>
                        <img src={userSVG} alt="" />
                      </span>
                    )
                  ) : (
                    <span>
                      <img src={userSVG} alt="" />
                    </span>
                  )}

                  <span>
                    {sales.salesPerson.firstName} {sales.salesPerson.lastName}
                  </span>
                </div>
              )}
              {sales.salesPerson && sales.salesPerson.email && (
                <div className="KamDetailItem">
                  <span>
                    <img src={emailSVG} alt="" />
                  </span>
                  {/* <span>{sales.salesPerson.email}</span> */}
                  <span>
                    <a href={`mailto:${sales.salesPerson.email}`} style={{ color: "#383a35" }}>
                      {sales.salesPerson.email}
                    </a>
                  </span>
                </div>
              )}

              <div className="KamDetailItem">
                {sales.salesPerson && sales.salesPerson.whatsapp && <RoundIcon image={whatsappSVG} />}
                {sales.salesPerson && sales.salesPerson.skype && <RoundIcon image={skypeSVG} />}
                {sales.salesPerson && sales.salesPerson.mobile && (
                  <>
                    <RoundIcon image={messageSVG} />
                    <span>
                      {" "}
                      <a href={`tel:${sales.salesPerson.mobile}`} style={{ color: "#383a35" }}>
                        {sales.salesPerson.mobile}
                      </a>
                    </span>
                    {/* <span>{sales.salesPerson.mobile}</span> */}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p style={{ marginTop: "20px" }}>No Sales Person Detail Found</p>
        )}
      </div>
    );
  }
}

export default connect((props) => {
  return props;
}, {})(KAMDetail);
