import React from "react";

import { Tooltip } from "antd";

const DiamondDetailAction = (props) => {
  return (
    <Tooltip title={props.tooltip} overlayClassName="tooltipShow" placement={props.placement ? props.placement : null}>
      <div
        onClick={props.onClick}
        className={`diamondDetailActionItem ${props.arrow && "align-items-center d-flex j-space-between"} ${
          props.fillButton && "commonButton"
        } ${props.outline && "commonOutline"}`}
      >
        {props.image && <img src={props.image} alt="i" />}
        <span>{props.title}</span>
        {props.arrow && <img className="arrowIcon" src={props.arrow} alt="i" />}
      </div>
    </Tooltip>
  );
};
export default DiamondDetailAction;
