import React, { Component } from "react";

import { find, findIndex } from "lodash";
import moment from "moment";

import { getColumn, handleSaveQuote, isMobile } from "components/DiamondList/DiamondListFunctions";
import Table from "components/DiamondList/Table";
import OpenNotification from "components/common/CommonButton/OpenNotification";
import ExpandTable from "components/common/ExpandTable";
import Heading from "components/common/Heading";
import InputBlock from "components/common/InputBlock";
import SelectOption from "components/common/SelectOption";
import TextArea from "components/common/TextArea";

import { Storage } from "services/storage";
import UtilService from "services/util";

import { roundOfferDiscount } from "util/_extra-utils";
import { prepareStoneForBargain } from "util/stone-utils";
import { formatDecimal, isArray, isEmpty, isNotEmpty, isNumber, isNumeric } from "util/utils";
import { parseDecimal } from "util/utils";
import { formatCurrency, formatNumber } from "util/utils";
import { classNames } from "util/utils";

import { DISCOUNT_SETTINGS_TYPE } from "constants/Common";
import { DISCOUNT_SETTING } from "constants/apiConstant";

import MobileOfferPopup from "./MobileOfferPopup";
import SelectStone from "./SelectStone";
import "components/common/LeftMultipleSelect/leftOption.less";
import { calculate } from "./SelectStone";

import minusSVG from "assets/img/minus.jpg";
import plusSVG from "assets/img/plus.jpg";

const currentType = "QuotePopup";
//const OFFER_DISCOUNT_MAX = 5;
//const OFFER_DISCOUNT_MIN = -5;
const OFFER_DISCOUNT_MAX = 8;
const OFFER_DISCOUNT_MIN = -8;
//const path = window?.location?.pathname?.split('/')[1];

class QuotePopup extends Component {
  state = {
    visible: false,
    columns: [],
    data: this.props?.editOffer ?? this.props?.checked,
    checked: [],
    comment: this.props?.editOffer?.[0]?.remarks,
    expire: this.props?.editOffer?.[0]?.offerValidDate
      ? moment(this.props.editOffer[0].offerValidDate, "YYYY-MM-DD")
          .startOf("day")
          .diff(moment().startOf("day"), "days")
      : "",
    defaultColumn: [],
    offerPrCt: 0,
    offerDis: 0,
    offerAmt: 0,
    terms: this.props?.editOffer?.[0]?.terms?.id,
    error: "",
    preShip: this.props?.editOffer?.[0]?.preferShippingCompany?.id,
    cor: this.props?.editOffer?.[0]?.courierAccountNo,
    reqDate: "",
    offerLimit: {},
  };

  get isUpdate() {
    return isArray(this.props.editOffer) && !isEmpty(this.props.editOffer);
  }

  get list() {
    return this.isUpdate ? this.props.editOffer : !isEmpty(this.props?.checked) ? this.props.checked : [];
  }

  getDiscSetting = () => {
    const obj = DISCOUNT_SETTING;
    obj.request = { type: DISCOUNT_SETTINGS_TYPE.MIN_MAX };
    UtilService.callApi(obj, (err, res) => {
      if (res && res.code === "OK") {
        this.setState({
          offerLimit: res?.data?.settings,
          expire: res?.data?.settings?.days,
        });
      }
    });
  };

  componentDidMount() {
    if (!isArray(this.list)) return;
    this?.props?.offerCollection && this.getDiscSetting();
    const data = this.list.map((stone) => prepareStoneForBargain(stone, { isUpdate: this.isUpdate }));

    if (this.isUpdate && !isEmpty(data)) {
      const offerPrCt = parseDecimal(data[0].newPricePerCarat ?? data[0].ctPr);
      const offerDis = parseDecimal(data[0].newDiscount ?? data[0].back);
      const offerAmt = parseDecimal(data[0].newAmount ?? data[0].amt);
      this.setState({ offerPrCt, offerDis, offerAmt });
    }
    if (this.props.detail) {
      this.setState({
        offerDis: data?.[0]?.calcDiscount,
        offerPrCt: isNumeric(data?.[0]?.calcPricePerCarat) ? parseDecimal(data?.[0]?.calcPricePerCarat) : 0.0,
        offerAmt: isNumeric(data?.[0]?.calcAmount) ? parseDecimal(data?.[0]?.calcAmount) : 0.0,
      });
    }
    const _columns = [...getColumn()];
    const slice = (() => {
      const indexLocNm = findIndex(_columns, { id: "locNm" });
      const indexShdNm = findIndex(_columns, { id: "shdNm" });
      return _columns.splice(indexLocNm, indexShdNm - indexLocNm + 1);
    })();

    const indexAmt = findIndex(_columns, { id: "amt" });

    const getOfferDiscount =
      (...args) =>
      () =>
        this.getOfferDiscount(...args);

    const columns = [
      ..._columns.slice(0, indexAmt + 1),
      {
        Header: "Off. Dis.",
        accessor: "calcDiscount",
        id: "calcDiscount",
        Cell({ row }) {
          return (
            <div className="tableInput">
              {(row.original.back || row.original.rap) && (
                <img src={minusSVG} width="15px" onClick={getOfferDiscount(row.original.stoneId, true)} alt="minus" />
              )}

              <input style={{ width: "calc(100% - 30px)" }} value={row.original.calcDiscount} disabled />
              {(row.original.back || row.original.rap) && (
                <img src={plusSVG} width="15px" onClick={getOfferDiscount(row.original.stoneId, false)} alt="plus" />
              )}
            </div>
          );
        },
      },
      {
        Header: "Off. Pr/Ct.",
        accessor: "calcPricePerCarat",
        id: "calcPricePerCarat",
        Cell({ row }) {
          const value = isNumeric(row.original.calcPricePerCarat) ? formatDecimal(row.original.calcPricePerCarat) : 0.0;
          return (
            <div className="tableInput">
              {(!row.original.back || !row.original.rap) && (
                <img
                  src={minusSVG}
                  width="15px"
                  onClick={getOfferDiscount(row.original.stoneId, true, true)}
                  alt="minus"
                />
              )}
              <input value={value} style={{ color: "#4eb45e", width: "calc(100% - 15px)" }} disabled />
              {(!row.original.back || !row.original.rap) && (
                <img
                  src={plusSVG}
                  width="15px"
                  onClick={getOfferDiscount(row.original.stoneId, false, true)}
                  alt="plus"
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Off. Amt.",
        accessor: "calcAmount",
        id: "calcAmount",
        Cell({ row }) {
          const value = isNumeric(row.original.calcAmount) ? formatDecimal(row.original.calcAmount) : 0.0;
          return (
            <div className="tableInput">
              <input style={{ color: "#4eb45e" }} value={value} disabled />
            </div>
          );
        },
      },
      ...slice,
      ..._columns.slice(indexAmt + 1, _columns.length),
    ];
    this.setState({ columns, data, defaultColumn: columns, oldData: data, checked: data });
  }
  offerHeading = () => {
    const _isMobile = isMobile();
    if (_isMobile && !this.state.data?.length) return null;

    const sum = calculate(this.state.data);
    const obj = {
      "CT. :": parseFloat(
        this.state.data
          .map((stone) => Number(stone.crt))
          .filter(isNumeric)
          .reduce((a, b) => a + b)
      ).toFixed(2),
      "Disc% :": parseFloat(sum.final_discount).toFixed(2),
      "Price/Ct :": formatNumber(parseFloat(sum.final_price).toFixed(2)),
      "Amount :": formatNumber(parseFloat(sum.final_value).toFixed(2)),
      "Offer Disc :": formatNumber(parseFloat(sum.offerFinalDisc).toFixed(2)),
      "Offer PR/CT :": formatNumber(parseFloat(sum.offerFinalPr).toFixed(2)),
      "Offer Amount :": formatNumber(parseFloat(sum.offerFinalAmt).toFixed(2)),
    };

    return (
      <div className="DiamondDetailPopup">
        {Object.entries(obj).map(([key, value]) => {
          const label = _isMobile ? value : key;
          const content = _isMobile ? key.slice(0, key.length - 1) : value;

          return (
            <div className="DiamondDetailPopupItem" key={key}>
              <span>{label}</span>
              <span>{content}</span>
            </div>
          );
        })}
      </div>
    );
  };

  getOfferDiscount = (stoneId, minus, fancy) => {
    const API_MIN = this.state?.offerLimit.minBuynowDiscount;
    const API_MAX = this.state?.offerLimit.maxBuynowDiscount;

    const DISCOUNT_LIMIT_MIN = this?.props?.offerCollection ? API_MIN : OFFER_DISCOUNT_MIN;
    const DISCOUNT_LIMIT_MAX = this?.props?.offerCollection ? API_MAX : OFFER_DISCOUNT_MAX;

    const data = [...this.state.data];
    const index = data.findIndex((rec) => rec.stoneId === stoneId);
    data[index] = { ...data[index] };

    const offerDis = Number(data[index]?.calcDiscount ?? 0);
    const calcDiscount = isNumber(offerDis) ? offerDis + (minus ? -0.5 : +0.5) : 0;

    const originalBack = data[index].original.back;

    if (calcDiscount > (fancy ? DISCOUNT_LIMIT_MAX : originalBack + DISCOUNT_LIMIT_MAX)) {
      OpenNotification({ type: "error", title: `Discount can't be more than ${data[index].calcDiscount}%` });
    } else if (calcDiscount < (fancy ? 0 + DISCOUNT_LIMIT_MIN : originalBack + DISCOUNT_LIMIT_MIN)) {
      OpenNotification({ type: "error", title: `Discount can't be less than the ${data[index].calcDiscount}%` });
    } else {
      data[index].calcDiscount = parseDecimal(calcDiscount);
      data[index].calcPricePerCarat = parseDecimal(
        (calcDiscount / 100 + 1) * (fancy ? data[index].baseCalcPricePerCarat : data[index]?.rap)
      );
      data[index].calcAmount = parseDecimal(data[index]?.calcPricePerCarat * data[index]?.crt);
      const checked = data.filter((el) => find(this.list, { id: el.id }));
      this.setState({ data, checked });
    }
  };

  getOfferDiscountDetail = (stoneId, dis, fancy) => {
    const API_MIN = this.state?.offerLimit.minBuynowDiscount;
    const API_MAX = this.state?.offerLimit.maxBuynowDiscount;

    const DISCOUNT_LIMIT_MIN = this?.props?.offerCollection ? API_MIN : OFFER_DISCOUNT_MIN;
    const DISCOUNT_LIMIT_MAX = this?.props?.offerCollection ? API_MAX : OFFER_DISCOUNT_MAX;

    const data = [...this.state.data];
    const index = data.findIndex((rec) => rec.stoneId === stoneId);
    data[index] = { ...data[index] };

    let calcDiscount = roundOfferDiscount(dis);

    const originalBack = data[index].original.back;
    if (calcDiscount > (fancy ? DISCOUNT_LIMIT_MAX : originalBack + DISCOUNT_LIMIT_MAX)) {
      this.setState({ offerDis: originalBack });
      OpenNotification({
        type: "error",
        title: `Discount can't be more than ${fancy ? DISCOUNT_LIMIT_MAX : originalBack + DISCOUNT_LIMIT_MAX}%`,
      });
      if (fancy && this.props.detail) {
        calcDiscount = 0;
        data[index].calcDiscount = parseDecimal(calcDiscount);
        data[index].calcPricePerCarat = parseDecimal(
          (calcDiscount / 100 + 1) * (fancy ? data[index].baseCalcPricePerCarat : data[index]?.rap)
        );
        data[index].calcAmount = parseDecimal(data[index]?.calcPricePerCarat * data[index]?.crt);
        const checked = data.filter((el) => find(this.list, { id: el.id }));
        this.setState({
          data,
          checked,
          offerAmt: data[index].calcAmount,
          offerPrCt: data[index].calcPricePerCarat,
          offerDis: calcDiscount,
        });
      }
    } else if (calcDiscount < (fancy ? 0 + DISCOUNT_LIMIT_MIN : originalBack - DISCOUNT_LIMIT_MIN)) {
      this.setState({ offerDis: originalBack });
      OpenNotification({
        type: "error",
        title: `Discount can't be less than the ${fancy ? 0 + DISCOUNT_LIMIT_MIN : originalBack - DISCOUNT_LIMIT_MIN}%`,
      });
      if (fancy && this.props.detail) {
        calcDiscount = 0;
        data[index].calcDiscount = parseDecimal(calcDiscount);
        data[index].calcPricePerCarat = parseDecimal(
          (calcDiscount / 100 + 1) * (fancy ? data[index].baseCalcPricePerCarat : data[index]?.rap)
        );
        data[index].calcAmount = parseDecimal(data[index]?.calcPricePerCarat * data[index]?.crt);
        const checked = data.filter((el) => find(this.list, { id: el.id }));
        this.setState({
          data,
          checked,
          offerAmt: data[index].calcAmount,
          offerPrCt: data[index].calcPricePerCarat,
          offerDis: calcDiscount,
        });
      }
    } else {
      data[index].calcDiscount = parseDecimal(calcDiscount);
      data[index].calcPricePerCarat = parseDecimal(
        (calcDiscount / 100 + 1) * (fancy ? data[index].baseCalcPricePerCarat : data[index]?.rap)
      );
      data[index].calcAmount = parseDecimal(data[index]?.calcPricePerCarat * data[index]?.crt);
      const checked = data.filter((el) => find(this.list, { id: el.id }));
      this.setState({
        data,
        checked,
        offerAmt: data[index].calcAmount,
        offerPrCt: data[index].calcPricePerCarat,
        offerDis: calcDiscount,
      });
    }
  };
  submit = () => {
    const currentDate = new Date();

    if (this.state.expire) {
      if (Number(this.state.expire) < 1 || Number(this.state.expire) > 5) {
        OpenNotification({
          type: "error",
          title: "Validity must be minimum 1 day and maximum 3 days.",
        });
      } else {
        if (this.state.terms) {
          // if (this.state.preShip) {
          const data = [...this.state.data];
          currentDate.setDate(currentDate.getDate() + Number(this.state.expire));
          handleSaveQuote(
            data,
            this.state.reqDate ? this.state.reqDate : currentDate.toISOString(),
            this.state.comment,
            () => {
              this.props.throwNotifyCount(this.props.notifyCount + 1);
              this.props.onClose();
              this.props.clearAll();
            },
            Boolean(this.props.editOffer),
            this.state.terms,
            this.state.preShip,
            this.state.cor
          );
          // }
        } else {
          OpenNotification({
            type: "error",
            title: "Please select payment terms.",
          });
        }
      }
    } else {
      OpenNotification({
        type: "error",
        title: "Please select offer validity.",
      });
    }
  };

  disabledDate = (current) => current && current < moment().subtract(1, "days");

  dateHandleChange = (e) => this.setState({ expire: e ? new Date(e._d).toISOString() : null });

  getBottom = () => {
    const terms = Storage.get("master", false).DAY_TERM ?? [];
    const preShip = Storage.get("master", false).PRE_SHIP_COMPANY ?? [];
    const termOptionList = isArray(terms)
      ? terms.map((item) => ({ id: item.id[0], name: item.name }))
      : [this.props?.editOffer?.[0]?.terms].filter(isNotEmpty);
    const preShipCompanyList = isArray(preShip) ? preShip.map((item) => ({ id: item.id[0], name: item.name })) : [];

    return (
      <>
        <div className={`confirmListOption mt-10 ${this.props.detail && "diamondetailOffer"}`}>
          {this.props.detail && (
            <>
              <div className="from-group width-100 mb-10">
                <label className="commonLabel">
                  Offer Amount: <b>{formatCurrency(this.state.offerAmt)}</b>
                </label>
              </div>
              <InputBlock
                onChange={(e) => this.setState({ offerDis: e.target.value })}
                onBlur={(e) => {
                  this.getOfferDiscountDetail(
                    this.state.data?.[0]?.stoneId,
                    Number(e.target.value),
                    !this.state.data?.[0]?.back || !this.state.data?.[0]?.rap ? true : false
                  );
                }}
                value={this.state.offerDis}
                label="Offer Dis% *"
                type="number"
              />
              <InputBlock value={this.state.offerPrCt} label="Offer Price/Carat *" disabled />
            </>
          )}

          {!this?.props?.offerCollection && (
            <InputBlock
              hasError={this.state.error}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  if (!this.state.expire.length) {
                    if (e.target.value === "0") {
                      this.setState({ expire: "" });
                    } else {
                      const dates = new Date();
                      dates.setDate(dates.getDate() + Number(e.target.value.trim()));
                      this.setState({
                        [e.target.name]: e.target.value.trim(),
                        reqDate: dates.toISOString(),
                      });
                    }
                  } else {
                    const dates = new Date();
                    dates.setDate(dates.getDate() + Number(e.target.value.trim()));
                    this.setState({
                      [e.target.name]: e.target.value.trim(),
                      reqDate: dates.toISOString(),
                    });
                  }
                }
              }}
              value={this.state.expire}
              type="text"
              label="Validity (Days) *"
              name="expire"
              onFocus={() => this.setState({ error: "Minimum 1 day and Maximum 3 days." })}
              onBlur={() => this.setState({ error: "" })}
              placeholder="Validity (Days) *"
            />
          )}

          <SelectOption
            selectValue={termOptionList}
            placeholder="Payment Terms"
            label="Payment Terms *"
            value={this.state.terms}
            onChange={(terms) => this.setState({ terms })}
          />
          <SelectOption
            selectValue={preShipCompanyList}
            placeholder="Prefer Shipping Company"
            label="Prefer Shipping Company"
            value={this.state.preShip ? this.state.preShip : undefined}
            onChange={(e) => {
              this.setState({ preShip: e });
            }}
          />
          <InputBlock
            value={this.state.cor}
            type="text"
            label="Courier Account No"
            placeholder="Courier Account No"
            onChange={(e) => {
              this.setState({ cor: e.target.value });
            }}
          />
          <TextArea
            value={this.state.comment}
            onChange={(e) => {
              this.setState({ comment: e.target.value });
            }}
            label="Comment"
            className={`${this.props.detail && "width-100"}`}
          />
        </div>
      </>
    );
  };

  render() {
    let filteredColumns = [];
    filteredColumns = this.state.defaultColumn.filter((column) => {
      return column.id !== "dna";
    });

    return !this.props.detail && isMobile() ? (
      <MobileOfferPopup
        detail={this.props.detail}
        getBottom={this.getBottom}
        onClose={this.props.onClose}
        submit={this.submit}
        // handleChange={(e) => {}}
        getOfferDiscount={this.getOfferDiscount}
        // disabledDate={disabledDate}
        data={this.state.data}
        currentType={currentType}
        parent={this}
      />
    ) : (
      <div>
        {!this.props.detail && (
          <div className="d-flex align-items-center j-space-between offerTopBlock offerWrapper mb-10">
            <Heading className="popupInnerTitle mb-0 mr-40" title="Send Offer" />
            {this.offerHeading()}
            <div>
              <ExpandTable defaultColumn={filteredColumns} setColumn={(e) => this.setState({ columns: e })} />
            </div>
            {this.state.data.length > 0 && (
              <SelectStone sum={{}} buy checked={this.state.data} clearAll={() => this.setState({ checked: [] })} />
            )}
          </div>
        )}
        <div
          className={classNames([
            `searchPopupCommon searchPopupCommonTable PopupHeight`,
            this.props.detail && "diamondetailInfoBox",
          ])}
        >
          {!this.props.detail && (
            <div className="searchResultTable tabInnerTableScroll tableHideCol hidecss">
              <Table
                finalDestination
                {...this.state}
                handleSort={(sort) => this.setState({ sort }, () => this.fetch())}
                handleCheck={(checked) => this.setState({ checked })}
                canSort={[]}
                filterArray={{}}
                selectedFilterArray={{}}
                selectFilter={() => {}}
                nodots
                noCheckBox
              />
            </div>
          )}
          <div className="detailPopupHalfBlock">{this.getBottom()}</div>
          <div className={`sideBarPopupButton ${this.props.detail && "diamonddetailPopupButton"}`}>
            <span className="commonButton" onClick={this.submit}>
              Save Offer
            </span>
            <span className="commonOutline" onClick={this.props.onClose}>
              Cancel Offer
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default QuotePopup;
