import React from "react";

const HeaderRightSidebar = (props) => {
  return (
    <div className={`HeaderRightSidebar ${props.activeClass}`}>
      <div
        className="headerRightOverlay"
        onMouseEnter={props.handleOut}
        onMouseOver={props.handleOut}
        onKeyDown={props.handleOut}
      />
      <div className="headerRightSidebarTop" />
      <div className="dropdownRightSide topBannerActive">{props.children}</div>
    </div>
  );
};
export default HeaderRightSidebar;
