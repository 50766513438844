import React, { useReducer, useRef, useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { Spin } from "antd";
import moment from "moment";

import GridHeightContainer from "containers/GridHeightContainer/GridHeightContainer";

import Countdown from "components/Offer/Timer";
import NoDataShow from "components/common/NoDataShow";

import { BidService } from "services/BidService";

import IntlMessage from "util/IntlMessage";

import { store } from "appRedux/store";

import BidToBuyMobile from "./BidToBuyMobile";

import { setNewArrivalFlag, setBidFlag } from "../../appRedux/actions/Common";
import { DIAMOND_BID, DIAMOND_WEB_STATUS, LOCAL_STORAGE_VAR } from "../../constants/Common";
import { getCurrentTime } from "../../services/util";
import { isArray, isString } from "../../util/utils";
import QuickSearch from "../Dashboard/QuickSearch";
import { getState, isArrayEqual } from "../DiamondList";
import { getColumn, fetchDiamondPaginate, isMobile, fetchDiamondList } from "../DiamondList/DiamondListFunctions";
import Table from "../DiamondList/TableBack";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";
import SelectStone from "../common/DiamondListing/SelectStone";
import Heading from "../common/Heading";
import NoDataFound from "components/common/NoDataFound";

function getBidStartTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf("day").add(11, "hours");
  return moment().startOf("day").add(15, "hours");
}

function getBidEndTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf("day").add(14, "hours").add(59, "minutes");
  return moment().startOf("day").add(10, "hours").add(59, "minutes");
}

export function getBidEndCounter(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf("day").add(14, "hours").add(59, "minutes");
  if (moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN))
    return moment().startOf("day").add(10, "hours").add(59, "minutes");
  return moment().startOf("day").add(1, "days").add(10, "hours").add(59, "minutes");
}

export function getBidType() {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
}

const BidToBuy = (props) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const { location } = props;
  const [state, setState] = useState(getState);
  // const [terms, showTerm] = useState(true);
  const [setMount, setMountData] = useState(false);
  const [currTime, setCurrTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [nextBidData, setNextBidData] = useState({});
  // const [bidStatus, setBidStatus] = useState();
  // const [showError, setShowError] = useState(false);
  const [bidEnds, setBidEnds] = useState(false);
  const sortRef = useRef([]);
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const { totalBidDiamondCountBywebStatus } = props.commonData;
  const dispatch = useDispatch();
  const clearSelection = React.useCallback(() => {
    store.dispatch({ type: "RESET_SELECTED_ROWS" });
    if (document.getElementsByTagName("table")[0]) {
      const tblRows = document.getElementsByTagName("table")[0].rows;
      for (const row of tblRows) row.classList.remove("selectTr");
    }
  }, []);

  const getTitle = () => {
    return JSON.stringify(nextBidData) !== "[]" && state.data && state.data.length ? (
      <>
        <IntlMessage id="app.bid-to-buy" /> ({state?.count})
      </>
    ) : (
      <>
        <IntlMessage id="app.bid-to-buy" /> ({0})
      </>
    );
  };
  const getSelection = () => {
    return (
      <SelectStone
        sum={state.sum}
        profilListing={getBidType() ? "listingSelected" : ""}
        currentType={currentType.current}
      />
    );
  };

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    return () => {
      window.removeEventListener("resize", forceUpdate);
    };
  });

  const setResponse = (res) => {
    // setShowError(res?.count === 0);
    const columns = getColumn();
    if (res) {
      const exportFilterData = res?.seachFilterTag?.searchData;
      const diamondData = res.data || [];
      let defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d.isDefaultChecked = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      const maxbid = [
        {
          Header: "Current Max. Bid",
          accessor: "maxBid",
          id: "maxBid",
          width: 230,
          Cell: ({ row }) => parseFloat(row.original.maxBid || 0).toFixed(2),
        },
      ];
      const amtIndex = columns.findIndex((el) => el.id === "amt");
      const bidCol = columns.splice(amtIndex + 1, 0, ...maxbid);
      setLoading(false);
      setState((state) => ({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        columns: [...bidCol, ...columns],
        loading: false,
        exportFilterData,
      }));
    } else {
      setState((state) => ({ ...state, loading: false, defaultChecked: [] }));
      setLoading(false);
    }
  };
  const fetch = useCallback(() => {
    setLoading(true);
    setState((state) => ({ ...state, loading: true, data: [] }));

    const diamondSearchId = isString(location.search) ? [location.search.replace("?", "")].filter(Boolean) : undefined;

    const payload = {
      page: state.page,
      limit: state.limit,
      sort: sortRef.current,
    };

    const filters = isArray(diamondSearchId) ? [{ diamondSearchId }] : undefined;

    isArray(diamondSearchId) && diamondSearchId.length !== 0
      ? fetchDiamondList(payload, filters, setResponse)
      : fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.BID, setResponse);
  }, [state.page, state.limit, location.search]);

  useEffect(() => {
    async function setTimers() {
      const time = await getCurrentTime();
      const nextBid = await BidService.getConfig({ dynamicBidType: BidService.DYNAMIC_BID_TYPES.BID_TO_BUY })
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === "E_NOT_FOUND") {
            setState({ ...state, loading: false, data: [] });
            props.setBidFlag(false);
            setLoading(false);
          }
        });
      if (nextBid?.dynamicBidType === 2 && JSON.stringify(nextBid) !== "[]") {
        props.setBidFlag(true);
      } else {
        props.setBidFlag(false);
      }
      // const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;

      // showTerm(data ? true : false);
      // let zone = nextBid?.sub_types?.timeZone === 'CET' ? time.CET : time.IST;
      setCurrTime(time.iso);
      setMountData(true);
      setNextBidData(nextBid && nextBid?.dynamicBidType === 2 ? nextBid : []);
      // setBidStatus(nextBid && nextBid.sub_types && nextBid.sub_types.allowDiamondFilterWSts);
      clearSelection();
      nextBid && nextBid.sub_types && nextBid?.dynamicBidType === 2 && fetch();
    }
    setTimers();
  }, []);

  const paginate = async () => {
    setLoading(true);
    const nextBid = await BidService.getConfig({ dynamicBidType: BidService.DYNAMIC_BID_TYPES.BID_TO_BUY })
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === "E_NOT_FOUND") {
          setState({ ...state, data: [] });
          setLoading(false);
        }
      });
    // const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
    // showTerm(data ? true : false);
    setNextBidData(nextBid && nextBid?.dynamicBidType === 2 ? nextBid : []);
    getSelection();
    nextBid?.dynamicBidType === 2 && fetch();
  };
  useEffect(() => {
    setMount && paginate();
  }, [state.page, state.limit, location]);

  const clearAllSelectedRows = useCallback(() => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const onPaginationChange = useCallback((page, limit) => setState((state) => ({ ...state, page, limit })), []);

  // React.useEffect(() => {
  //   fetch();
  // }, [fetch]);

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        state.nextBid?.dynamicBidType === 1 && fetch();
      }
    },
    [fetch]
  );

  // const setTimer = React.useCallback(async () => {
  //   const time = await getCurrentTime();
  //   const nextBid = await BidService.getNextBidData();
  //   showTerm(Boolean(nextBid?.sub_types?.ruleFile));
  //   setCurrTime(time.iso);
  //   setNextBidData(nextBid);
  //   clearSelection();
  //   nextBid && nextBid.sub_types && nextBid?.dynamicBidType === 2 && fetch();
  // }, [fetch]);

  // useEffect(() => {
  //   setTimer();
  // }, [setTimer]);

  const bidEndTime = async () => {
    const nextBid = await BidService.getConfig({ dynamicBidType: BidService.DYNAMIC_BID_TYPES.BID_TO_BUY })
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === "E_NOT_FOUND") {
          setState({ ...state, loading: false, data: [] });
          setLoading(false);
        }
      });
    // const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
    // showTerm(data ? true : false);
    setBidEnds(true);
    getSelection();
    setNextBidData(nextBid && nextBid?.dynamicBidType === 2 ? nextBid : []);
  };
  useEffect(() => {
    bidEnds === true && window.location.reload();
  }, [bidEnds]);

  const getBidBlock = () => {
    return getBidType() ? (
      <div className={`bidEndBlock${!isMobile() ? " d-flex align-items-center" : ""}`}>
        {JSON.stringify(nextBidData) !== "[]" ? (
          <span className="bidEndLabel">
            <IntlMessage id="app.BidEndIn" /> :
          </span>
        ) : (
          ""
        )}
        {/* <Countdown date={getBidEndCounter(getBidType())} fetch={fetch} /> */}
        {JSON.stringify(nextBidData) !== "[]" ? (
          <Countdown
            currTime={currTime}
            date={
              nextBidData?.sub_types?.currentBidEndDate &&
              moment(nextBidData.sub_types.currentBidEndDate).subtract(1, "hour")
            }
            // fetch={nextBidData && nextBidData.sub_types && nextBidData.sub_types.currentBidEndDate && fetch}
            bidEndTime={bidEndTime}
          />
        ) : (
          ""
        )}
      </div>
    ) : null;
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        dynamicBidType={{ dynamicBidType: BidService.DYNAMIC_BID_TYPES.BID_TO_BUY }}
        bidConfigurationId={nextBidData && nextBidData.id}
        subTypeCode={nextBidData && nextBidData.sub_types && nextBidData.sub_types.subTypeCode}
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        myBidStone
        myBid
        excel
        importexcel
        nocart
        // nowatch
        noreminder
        noquote
        noconfirm
        nonote
        // noappointment
        nofinalcalc
      />
    );
  };
  const getOffAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        dynamicBidType={{ dynamicBidType: BidService.DYNAMIC_BID_TYPES.NEW_ARRIVAL }}
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        nopagination
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        bidStone
        myBid
        nonocompare
        noprint
        noshare
        nodownload
        // excel
        nocart
        nowatch
        noreminder
        noquote
        noconfirm
        nonote
        noappointment
        nofinalcalc
        // noappointment={getBidType() === DIAMOND_BID.TYPE.BLIND}
      />
    );
  };

  if (isMobile()) {
    return JSON.stringify(nextBidData) === "[]" ? (
      <div className="searchInnerResult">
        <div className="searchResultTable">
          <NoDataShow message={<IntlMessage id="app.BidInactive" />} />
          {getOffAction()}
        </div>
      </div>
    ) : (
      <BidToBuyMobile
        {...props}
        {...state}
        currentType={currentType.current}
        parent={{ getTitle, getSelection, getBidBlock, getAction, getOffAction }}
      />
    );
  }
  return (
    <div className="searchResultListWrapper">
      <QuickSearch dataFilter={state.filterArrayData} diamondList />
      <div className="selectStoneHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock">
          <Heading className="mb-0 ms-4">{getTitle()}</Heading>
          {getSelection()}
          {getBidBlock()}
        </div>
      </div>
      {loading && (
        <div className="searchInnerResult ">
          <div className="searchResultTable">
            <NoDataShow message={<Spin />} />
          </div>
        </div>
      )}
      {
        JSON.stringify(nextBidData) !== "[]" && state.data && state.data.length ? (
          <GridHeightContainer
            className="searchInnerResult searchResultTable"
            subtractFrom=".HeaderSticky,.dashboardQuickSearch,.selectStoneHeader,.diamondListingAction"
            adjustment={-12}
          >
            <Table
              data={state.data}
              loading={loading}
              ref={tableRef}
              handleSort={handleSort}
              currentType={currentType.current}
              defaultChecked={state.defaultChecked}
              columns={state.columns}
            />
          </GridHeightContainer>
        ) : (
          <>
            <div style={{ height: "75vh" }}>
              <NoDataFound loading={true} />
            </div>
          </>
        )
        //: (
        //  totalBidDiamondCountBywebStatus === 0 && (
        //   <div className="searchInnerResult ">
        ///    <div className="searchResultTable">
        //      <NoDataShow message={<IntlMessage id="app.noDiamondsInBid" />} />
        //   </div>
        // </div>
        //)
        //)
      }

      {JSON.stringify(nextBidData) === "[]" && totalBidDiamondCountBywebStatus !== 0 ? (
        <div className="searchInnerResult">
          <div className="searchResultTable">
            <NoDataShow message={<IntlMessage id="app.BidInactive" />} />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {showError ? (
        <div className="searchInnerResult">
          <div className="searchResultTable">
            <NoDataShow message={'No Diamonds Available'} />
          </div>
        </div>
      ) : (
        ''
      )} */}

      {JSON.stringify(nextBidData) !== "[]" && state.data && state.data.length ? getAction() : getOffAction()}
      {/* {getDrawer()} */}
    </div>
  );
};

export default connect(
  (state) => ({
    commonData: state?.commonData,
  }),
  {
    setNewArrivalFlag,
    setBidFlag,
  }
)(React.memo(withRouter(BidToBuy)));
