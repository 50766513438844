import React, { useEffect, useState } from "react";
import { Link , withRouter } from "react-router-dom";

import OpenNotification from "components/common/CommonButton/OpenNotification";

import IntlMessage from "util/IntlMessage";

import CaratRange from "./CaratRange";
import ClarityRange from "./ClarityRange";
import ColorRange from "./ColorRange";
import ShapeSelector from "./Shape";

import { LOCAL_STORAGE_VAR, PAGES } from "../../../constants/Common";
import { handleMatchPair, handleSearch } from "../../../containers/APIFunctions";
// import { LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import Heading from "../../common/Heading";

// import { PAGES } from 'constants/Common';

const LISTINGPAGES = PAGES;
function QuickSearch(props) {
  const { history, location } = props;

  const [shpIds, setShapeIds] = useState([]);
  const [colorIds, setColorIds] = useState([]);
  const [clarIds, setClarIds] = useState([]);
  const [caratIds, setcaratIds] = useState([]);
  const [count, setCount] = useState(0);
  const [id, setId] = useState("");
  const [filter, setFilter] = useState({});
  const currentType = React.useMemo(() => {
    return (history.location.state && history.location.state.currentType) || location.pathname;
  }, [history.location.state, location.pathname]);

  useEffect(() => {
    setFilter({ ...props.dataFilter });
    if (props.dataFilter && props.dataFilter.shp && props.dataFilter.shp.length !== 0) {
      const id = [];
      const shapes = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).SHAPE;
      if (shapes && shapes.length !== 0) {
        props.dataFilter.shp.forEach((main) => {
          shapes.forEach((sh) => {
            if (sh.webDisplay === main) {
              id.push(sh.id[0]);
            }
          });
        });
        setShapeIds([...id]);
      }
    }
  }, [props.dataFilter]);

  useEffect(() => {
    if (props.dashboard) {
      const staticfilter = {
        col: ["5d035ebbac36b81af74acc9e", "5d035ebcac36b81af74acdf7"],
        clr: ["5d035ebcac36b81af74acdd3", "5d035ebcac36b81af74acd69"],
        shp: ["5d0366315fc57f2a3fad098c"],
        or: [
          {
            crt: {
              ">=": 0.01,
              "<=": 0.99,
            },
          },
        ],
      };
      const isDashboardSearch = true;
      handleSearch(
        staticfilter,
        false,
        (cb) => {
          setId(cb.id);
          setCount(cb.count);
          // if (cb.count > 1001) {
          //   setIsQuickSearch(true);
          // }
        },
        false,
        isDashboardSearch
      );
    }
  }, [props.dashboard]);

  const searchIt = React.useCallback(
    (shape, color, clarity, car) => {
      let filter = {};
      // setIsQuickSearch(false);

      if (currentType === "/new-arrival") {
        filter = { col: color, clr: clarity, shp: shape, or: car, wSts: "N" };
      } else if (currentType === "/bid-to-buy") {
        filter = { col: color, clr: clarity, shp: shape, or: car, wSts: "B" };
      } else {
        filter = { col: color, clr: clarity, shp: shape, or: car };
      }

      currentType !== "/match-pair"
        ? handleSearch(filter, false, (cb) => {
            setId("");
            if (currentType === "/bid-to-buy") {
              props.history.push(`/${LISTINGPAGES.BID}?${cb.id}`, { currentType });
            } else if (currentType === "/new-arrival") {
              props.history.push(`/new-arrival?${cb.id}`, { currentType });
            } else if (!props.dashboard) {
              props.history.push(`/${LISTINGPAGES.LIST}?${cb.id}`);
            }
            setId(cb.id);
            setCount(cb.count);
          })
        : handleMatchPair(filter, (cb) => {
            setId("");
            props.history.push(`/match-pair?${cb}`);
            setId(cb);
          });
    },
    [currentType, props.dashboard, props.history]
  );

  const shapeSearch = React.useCallback(
    (list) => {
      setShapeIds(list);
      searchIt(list, colorIds, clarIds, caratIds);
    },
    [caratIds, clarIds, colorIds, searchIt]
  );

  const caratSearch = React.useCallback(
    (list) => {
      setcaratIds(list);
      searchIt(shpIds, colorIds, clarIds, list);
    },
    [clarIds, colorIds, searchIt, shpIds]
  );
  const colorSearch = React.useCallback(
    (list) => {
      setColorIds(list);
      searchIt(shpIds, list, clarIds, caratIds);
    },
    [caratIds, clarIds, searchIt, shpIds]
  );
  const claritySearch = React.useCallback(
    (list) => {
      setClarIds(list);
      searchIt(shpIds, colorIds, list, caratIds);
    },
    [caratIds, colorIds, searchIt, shpIds]
  );

  const searchDiamond = () => {
    if (count > 1000) {
      OpenNotification({
        type: "error",
        title: "Please modify your search, maximum 1000 stones can be displayed",
      });
    } else {
      return history.push(`/${LISTINGPAGES.LIST}?${id}`);
    }
    // if (isQuickSearch) {
    //   return history.push(`/${LISTINGPAGES.LIST}?${quickSearchId + Math.ceil(Math.random() * 100000)}`);
    // } else if (count > 1000 && !isQuickSearch) {
    //   OpenNotification({
    //     type: 'error',
    //     title: 'Please modify your search, maximum 1000 stones can be displayed',
    //   });
    // } else if (count < 1001 && !isQuickSearch) {
    //   return history.push(`/${LISTINGPAGES.LIST}?${id}`);
    // }
  };

  return (
    <div className="dashboardQuickSearch">
      {props.diamondList ? null : (
        <Heading title={<IntlMessage id="app.quick-search" />} className="text-center dashboardTitle" />
      )}
      <div className="width-100 d-flex flex-wrap">
        <ShapeSelector setIds={setShapeIds} shpIds={shpIds} setShapeIds={shapeSearch} dash={props.dashboard} />
        <CaratRange setIds={setcaratIds} preCrt={filter.or ?? []} setcaratIds={caratSearch} />
        <ColorRange setIds={setColorIds} preRange={filter.col ?? []} setColorIds={colorSearch} />
        <ClarityRange setIds={setClarIds} preRange={filter.clr ?? []} setClarIds={claritySearch} />
      </div>
      {props.diamondList ? null : (
        <div className="quickFilterLink d-flex j-space-between align-items-center">
          <Link to={`/diamond-search`} className="textViewAll" id="step6">
            <IntlMessage id="app.viewAdvanceSearch" />
          </Link>
          <button disabled={id === ""} onClick={searchDiamond} className="commonButton">
            <IntlMessage id="app.search" />
            {/* {`${count ? count : ''}`} */}
          </button>
        </div>
      )}
    </div>
  );
}

export default React.memo(withRouter(QuickSearch));
