import React from "react";

import { Tooltip } from "antd";

import IntlMessage from "util/IntlMessage";

import { commonLogout } from "constants/Common";

import logoutPNG from "../../../assets/svg/Header/logout.png";

import "./default.less";

const SignOutIcon = () => {
  return (
    <Tooltip arrow title={<IntlMessage id="app.SignOut" />} placement="bottom" onClick={commonLogout}>
      <img src={logoutPNG} alt="Sign out" height={28} width={30} />
    </Tooltip>
  );
};
export default SignOutIcon;
