import React, { Component } from "react";

import IntlMessage from "util/IntlMessage";

import Heading from "../../common/Heading";
import SavedSearchCard from "../SavedSearch/SavedSearchCard";
// import { Link } from 'react-router-dom';

class RecentSearch extends Component {
  state = {
    recentData: [],
  };
  componentDidUpdate(prevProps) {
    if (prevProps.recent !== this.props.recent) {
      this.setState({ recentData: this.props.recent });
    }
  }
  render() {
    const { recentData } = this.state;

    return (
      <div className="rightBlockItem">
        <div className="d-flex j-space-between align-items-center dashboardCardTop">
          <Heading title={<IntlMessage id="app.recentsearchtitle" />} />
        </div>
        <div className="sacedSearchBox">
          {recentData && recentData.length !== 0
            ? recentData.map((recent, ind) => {
                return <SavedSearchCard recentSearch saveId={recent.id} saveDetail={recent} key={ind} />;
              })
            : "No Recent Search"}
        </div>
        {/* <Link to="/account/saved-search" className="textViewAll">View All</Link> */}
      </div>
    );
  }
}
export default RecentSearch;
