import React from "react";
import Slider from "react-slick";

import { FILE_URLS, LAB_LINKS, LOCAL_STORAGE_VAR, PAGES } from "../../constants/Common";
// import DiamondSearchGrid from '../DiamondList/DiamondGrid';
import { getParams } from "../DiamondList/DiamondListFunctions";
import { formatNumber } from "../common/DiamondListing/SelectStone";
import { STATUS, STATUS_COLOR } from "../common/DiamondListing/Status";
import Image from "../common/Image";

const LISTINGPAGES = PAGES;

const settings = {
  dots: true,
  infinite: false,
  loop: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        // infinite:true,
      },
    },
  ],
};

const RecentlyView = (props) => {
  const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`))?.SHAPE || {};

  return (
    <div>
      <Slider {...settings}>
        {props.diamondList &&
          props.diamondList.length !== 0 &&
          props.diamondList.map((block, index) => {
            return (
              <div className="diamondGridView" key={index}>
                <div className="daimondGridViewItem width-100">
                  <div className="DiamondGridViewImg">
                    <Image src={FILE_URLS.img.replace("***", block.vStnId)} alt="" />
                    <div className="diamondGridLabel">{/* <label>DD:1</label> */}</div>
                  </div>
                  <div className="gridViewContent">
                    <div className="gridLeftContent">
                      <h2>
                        {masterData?.find((x) => x.code === block.shpNm)?.name === undefined
                          ? block.shpNm
                          : masterData?.find((x) => x.code === block.shpNm)?.name}
                      </h2>
                      <a href={`/${LISTINGPAGES.DETAIL}/${block.id}`} target="_blank" rel="noopener noreferrer">
                        {block.vStnId}
                      </a>
                      <p>{getParams(block).join(" ")}</p>
                    </div>
                    <div className="gridRightContent">
                      <span
                        onClick={() => {
                          if (block.lbNm && block.rptNo) {
                            window.open(LAB_LINKS[block.lbNm.toUpperCase()].replace("***", block.rptNo));
                          }
                        }}
                      >
                        {block.lbNm}
                      </span>
                      <span>
                        $
                        {formatNumber(Math.round(block.amt))}
                      </span>
                      <span className="statusShow">
                        {STATUS[block.wSts]}
                        <span className={`statusIcon ${  STATUS_COLOR[block.wSts]}`} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};
export default RecentlyView;
