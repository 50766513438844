import React, { memo, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { map, sum } from "lodash";
import each from "lodash/each";

import IntlMessage from "util/IntlMessage";

import { PAGES } from "constants/Common";

import { getPath, isMobile } from "../../DiamondList/DiamondListFunctions";

const LISTINGPAGES = PAGES;

export function formatNumber(num) {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
}

export function calculate(dataList) {
  const sum_total = {
    total_pieces: 0,
    total_carat: 0,
    total_avg_rap: 0,
    final_discount: 0,
    final_price: 0,
    final_value: 0,
    total_rapaport: 0,
    avg_discount: 0,
    final_net_value: 0,
    final_rate: 0,
    final_term_discount: 0,
    avg_term_discount: 0,
    bid_value: 0,
    final_bid_price: 0,
    final_bid_discount: 0,
  };
  const total = {
    fancy_total: { ...sum_total },
    non_fancy_total: { ...sum_total },
  };
  total.fancy_total.isFancy = 1;
  total.non_fancy_total.isFancy = 0;
  each(dataList, (lst) => {
    const use_total_key = lst.isFancy ? "fancy_total" : "non_fancy_total";
    total[use_total_key].total_pieces += 1;
    total[use_total_key].total_carat += lst.crt && parseFloat(lst.crt) ? parseFloat(lst.crt) : 0;
    lst.rap_avg = lst.rap ? parseFloat(lst.rap) * parseFloat(lst.crt || 0) : 0;
    total[use_total_key].total_avg_rap += lst.rap_avg;
    total[use_total_key].total_rapaport += lst.rap ? parseFloat(lst.rap) : 0;
    total[use_total_key].final_value += lst.amt || 0;
    total[use_total_key].final_net_value += lst.calcAmount || 0;
    total[use_total_key].bid_value += lst.bidAmount || 0;
  });
  total.fancy_total.final_price =
    total.fancy_total.final_value && total.fancy_total.total_carat
      ? total.fancy_total.final_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_price =
    total.non_fancy_total.final_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.final_bid_price =
    total.fancy_total.bid_value && total.fancy_total.total_carat
      ? total.fancy_total.bid_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_bid_price =
    total.non_fancy_total.bid_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.bid_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.final_rate =
    total.fancy_total.final_net_value && total.fancy_total.total_carat
      ? total.fancy_total.final_net_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_rate =
    total.non_fancy_total.final_net_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_net_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.total_avg_rap = total.fancy_total.total_avg_rap
    ? total.fancy_total.total_avg_rap / total.fancy_total.total_carat
    : 0;
  total.non_fancy_total.total_avg_rap = total.non_fancy_total.total_avg_rap
    ? total.non_fancy_total.total_avg_rap / total.non_fancy_total.total_carat
    : 0;
  total.fancy_total.final_discount =
    total.fancy_total.final_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_price / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_discount =
    total.non_fancy_total.final_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  total.fancy_total.final_term_discount =
    total.fancy_total.final_rate && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_rate / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_term_discount =
    total.non_fancy_total.final_rate && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_rate / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  total.fancy_total.final_bid_discount =
    total.fancy_total.final_bid_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_bid_price / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_bid_discount =
    total.non_fancy_total.final_bid_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_bid_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  const allTotal = {
    bid_value: total.fancy_total.bid_value + total.non_fancy_total.bid_value,
    total_pieces: total.fancy_total.total_pieces + total.non_fancy_total.total_pieces,
    total_carat: total.fancy_total.total_carat + total.non_fancy_total.total_carat,
    final_rapaport: total.fancy_total.total_avg_rap + total.non_fancy_total.total_avg_rap,
    final_discount: total.fancy_total.final_discount
      ? total.fancy_total.final_discount
      : total.non_fancy_total.final_discount,
    final_bid_discount: total.fancy_total.final_bid_discount
      ? total.fancy_total.final_bid_discount
      : total.non_fancy_total.final_bid_discount,
    final_value: total.fancy_total.final_value + total.non_fancy_total.final_value,
    final_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_value + total.non_fancy_total.final_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_bid_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.bid_value + total.non_fancy_total.bid_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    total_rapaport: total.fancy_total.total_rapaport + total.non_fancy_total.total_rapaport,
    final_net_value: total.fancy_total.final_net_value + total.non_fancy_total.final_net_value,
    final_rate:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_net_value + total.non_fancy_total.final_net_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_term_discount: total.fancy_total.final_term_discount
      ? total.fancy_total.final_term_discount
      : total.non_fancy_total.final_term_discount,
  };
  allTotal.avg_discount = allTotal.final_rapaport ? allTotal.final_price / allTotal.final_rapaport : 0;
  allTotal.avg_term_discount = allTotal.final_rapaport ? allTotal.final_rate / allTotal.final_rapaport : 0;

  // total offer amt
  const finalquoteArray = map(dataList, "calcAmount");
  allTotal.offerFinalAmt = sum(finalquoteArray.map((i) => Number(i)));

  // offer PR/CT
  allTotal.offerFinalPr = allTotal.offerFinalAmt / allTotal.total_carat;

  // offer Disc%
  const avgRapByCrt = sum(map(dataList, (d) => d.rap * d.crt)) / allTotal.total_carat;
  allTotal.offerFinalDisc = (1 - allTotal.offerFinalPr / Number(avgRapByCrt)) * -100;

  // total offer amt
  const finalBidArray = map(dataList, "bidAmount");
  allTotal.bidFinalAmt = sum(finalBidArray.map((i) => Number(i)));

  // offer PR/CT
  allTotal.bidFinalPr = allTotal.bidFinalAmt / allTotal.total_carat;

  // offer Disc%
  const _avgRapByCrt = sum(map(dataList, (d) => d.rap * d.crt)) / allTotal.total_carat;
  allTotal.bidFinalDisc = (1 - allTotal.bidFinalPr / Number(_avgRapByCrt)) * -100;

  return allTotal;
}

const SelectStone = (props) => {
  const propssum = props.sum || {};

  const checked = useSelector((state) => state.diamondData.selectedRows[props.currentType]) || [];
  const dispatch = useDispatch();

  const clearAll = useCallback(() => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const summation = useMemo(() => calculate(checked), [checked]);

  const objects = useMemo(
    () =>
      [
        {
          label: <IntlMessage id="app.Pieces" />,
          check: summation.total_pieces,
          sum: propssum.pieces || propssum.count || 0,
        },
        {
          label: <IntlMessage id="app.Carats" />,
          check: parseFloat(summation.total_carat).toFixed(2),
          sum: parseFloat(propssum.totalCarat || 0).toFixed(2),
        },
        getPath() !== LISTINGPAGES.UPCOMING
          ? {
              label: <span>Rap Price</span>,
              check: parseFloat(summation.final_rapaport).toFixed(2),
              sum: parseFloat(propssum.rapPrice || 0).toFixed(2),
            }
          : null,
        getPath() !== LISTINGPAGES.UPCOMING
          ? {
              label: <span>Avg. Dis</span>,
              check: parseFloat(summation.final_discount).toFixed(2),
              sum: parseFloat(propssum.avgDiscount || 0).toFixed(2),
            }
          : null,
        getPath() !== LISTINGPAGES.UPCOMING
          ? {
              label: <span>Total Ct/Pr</span>,
              check: Math.round(summation.final_price),
              sum: Math.round(propssum.totalPricePerCarat || 0),
            }
          : null,
        getPath() !== LISTINGPAGES.UPCOMING
          ? {
              label: <IntlMessage id="app.Amount" />,
              check: Math.round(summation.final_value),
              sum: Math.round(propssum.totalAmount || 0),
            }
          : null,
        getPath() === LISTINGPAGES.MYBID
          ? {
              label: "Avg Bid Disc",
              check: parseFloat(summation?.final_bid_discount || 0).toFixed(2),
              sum: parseFloat(propssum?.calcBidDiscount || 0).toFixed(2),
            }
          : null,
        getPath() === LISTINGPAGES.MYBID
          ? {
              label: "Total Bid Ct/Pr",
              check: Math.round(summation?.final_bid_price || 0),
              sum: Math.round(propssum?.totalBidPricePerCarat || 0),
            }
          : null,
        getPath() === LISTINGPAGES.MYBID
          ? {
              label: "Bid Amount",
              check: Math.round(summation?.bid_value || 0),
              sum: Math.round(propssum?.totalBidAmount || 0),
            }
          : null,
      ].filter((el) => el && el),
    [propssum, summation]
  );

  return isMobile()
    ? checked.length > 0 && (
    <div className="mobileStoneSelect">
      {objects.map((item) => {
            return (
              <div className="mobileSelectStoneItem" key={item.label}>
                {checked.length ? (
                  <span>{formatNumber(item.check)}</span>
                ) : // style={{ color: 'red', fontWeight: '500' }}
                null}
                {window.location.pathname === "/account/order-list" ||
                window.location.pathname === "/account/watchlist" ||
                window.location.pathname === "/account/cart" ||
                window.location.pathname === "/account/view-diamonds" ||
                window.location.pathname === "/account/offer-list" ||
                window.location.pathname === "/account/notes" ? (
                  ""
                ) : (
                  <span>{`/${  formatNumber(isNaN(item.sum) ? 0 : item.sum)}`}</span>
                )}
                {item.label}
              </div>
            );
          })}
    </div>
      )
    : checked.length > 0 && (
    <div className={`selectStoneValueBlock ${props.profilListing}`} style={props.blockStyle || {}}>
      {objects.map((item, i) => {
            return (
              <div className="selectStoneValueItem" key={item.label + i}>
                <span className="selectStoneLabel">
                  {item.label}
                  {' '}
                  :
                  {' '}
                </span>
                <span className="selectStopnValue">
                  <span className="themeColor">{formatNumber(item.check)}</span>
                  {window.location.pathname === "/account/order-list" ||
                  window.location.pathname === "/account/watchlist" ||
                  window.location.pathname === "/account/cart" ||
                  window.location.pathname === "/account/view-diamonds" ||
                  window.location.pathname === "/account/offer-list" ||
                  window.location.pathname === "/account/notes"
                    ? ""
                    : `/${  formatNumber(item.sum)}`}
                </span>
              </div>
            );
          })}
      <div
        className={props.profilListing ? "searchStoneClose" : "searchStoneClose"}
        onClick={clearAll}
        style={props.clearStyle || {}}
      >
        Clear all
      </div>
    </div>
      );
};
export default memo(SelectStone);
