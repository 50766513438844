import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Analytics() {
  const routePath = useLocation();

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=G-1S1HX22DP1";
    script.async = true;
    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-1S1HX22DP1", { page_path: window.location.pathname });
  }, [routePath]);

  return null;
}
