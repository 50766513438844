import React, { useState } from "react";

import CaratRange from "./CaratRange";
import ClarityRange from "./ClarityRange";
import ColorRange from "./ColorRange";
import ShapeSelector from "./Shape";
import "./quickSearch.less";

const MobileQuickItem = (props) => {
  return (
    <div className={`quickSearchBox ${props.className}`} onClick={props.onClick}>
      <span className="searchTitle">{props.title}</span>
      <span className="searchValue">{props.value}</span>
    </div>
  );
};

const MobileQuickSearch = () => {
  const [shape, SetShape] = useState(false);
  const [color, SetColor] = useState(false);
  const [carat, SetCarat] = useState(false);
  const [clarity, SetClarity] = useState(false);
  const shapehandle = () => {
    SetShape(!shape);
    SetColor(false);
    SetCarat(false);
    SetClarity(false);
  };
  const colorhandle = () => {
    SetColor(!color);
    SetShape(false);
    SetCarat(false);
    SetClarity(false);
  };
  const carathandle = () => {
    SetCarat(!carat);
    SetColor(false);
    SetShape(false);
    SetClarity(false);
  };
  const clarityhandle = () => {
    SetClarity(!clarity);
    SetCarat(false);
    SetColor(false);
    SetShape(false);
  };
  return (
    <div className="mobileQuickSearchBlock">
      <div className="d-flex mobileQuickSearch">
        <MobileQuickItem className={shape && "active"} title="Shape" value="Round" onClick={shapehandle} />
        <MobileQuickItem className={color && "active"} title="Color" value="D-F" onClick={colorhandle} />
        <MobileQuickItem className={carat && "active"} title="Carat" value="0.18-0.99" onClick={carathandle} />
        <MobileQuickItem className={clarity && "active"} title="Clarity" value="VVS1-VVS2" onClick={clarityhandle} />
      </div>
      <div className="quickSearchMainBox">
        {shape && <ShapeSelector titleHide />}
        {color && <ColorRange titleHide />}
        {carat && <CaratRange titleHide />}
        {clarity && <ClarityRange titleHide />}
      </div>
      {/* {shape &&
        <Drawer
          title={false}
          onClose={onClose}
          visible={shape}
          wrapClassName="diamondListinSidebar xl-size smallSidebarMobilePopup"
        >
        </Drawer>
        } */}
    </div>
  );
};
export default MobileQuickSearch;
