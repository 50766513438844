import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import clone from "lodash/clone";
import find from "lodash/find";
import join from "lodash/join";

import IntlMessage from "util/IntlMessage";

import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import { FETCH_USER, ADMIN_UPDATE_USER } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";
import SelectOption from "../../common/SelectOption";

export const companyDetail = {
  BusinessType: [
    {
      id: "Independent Jeweler",
      name: "Independent Jeweler",
    },
    {
      id: "Online Jewelry Store",
      name: "Online Jewelry Store",
    },
    {
      id: "Diamond Dealer/ Broker",
      name: "Diamond Dealer/ Broker",
    },
    {
      id: "Diamond Manufacturer / Cutter",
      name: "Diamond Manufacturer / Cutter",
    },
    {
      id: "Jewelry Manufacturer",
      name: "Jewelry Manufacturer",
    },
    {
      id: "Jewelry Retail Chain",
      name: "Jewelry Retail Chain",
    },
    {
      id: "Pawn shop",
      name: "Pawn shop",
    },
  ],
};

const SOCIAL_ARRAY =
  (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).SOCIALS) ||
  [];

class SocialInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      cities: [],
      states: [],
      countries: [],
      errors: [],
      doc: [],
      socialDynamic: [],
      rapId: "",
      instaId: "",
      socialArray: SOCIAL_ARRAY,
      temSocial: SOCIAL_ARRAY,
      tempId: [],
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    const self = this;
    const obj = {
      ...FETCH_USER,
    };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } if (data && data.code === "OK") {
        self.setState({ data: data.data }, () => {
          if (self.state.data && self.state.data.socialNetworks && self.state.data.socialNetworks.length !== 0) {
            const insta = find(self.state?.data?.socialNetworks, { name: "INSTAGRAM" })?.socialtext;
            const rap = find(self.state?.data?.socialNetworks, { name: "RAPNET" })?.socialtext;
            if (insta) {
              self.setState({ instaId: insta });
            }
            if (rap) {
              self.setState({ rapId: rap });
            }
            let temp = self.state.data.socialNetworks;
            temp = temp.filter((t, ind) => t.name !== "INSTAGRAM" && t.name !== "RAPNET");
            self.setState({ socialDynamic: temp }, () => {
              if (self.state.socialDynamic && self.state.socialDynamic.length !== 0) {
                const ar = self.state.tempId;
                self.state.socialDynamic.forEach((ab) => {
                  const id = ab.socialId.split("/")[0];
                  ar.push(id);
                });
                self.setState({ tempId: ar }, () => {
                  const abc = self.state.temSocial.filter((soc) => !self.state.tempId.includes(soc.id[0]));
                  self.setState({ socialArray: abc });
                });
              }
            });
          }
        });
      }
    });
  }

  updateUser() {
    const self = this;
    const reqObj = clone(self.state.data);

    reqObj.name = `${reqObj.firstName} ${reqObj.lastName}`;
    reqObj.kyc = self.props.kyc;
    reqObj.address = join([reqObj.address, reqObj.address2, reqObj.address3], ",");
    reqObj.socialNetworks = [];
    if (self.state.socialDynamic && self.state.socialDynamic.length !== 0) {
      reqObj.socialNetworks = [...self.state.socialDynamic];
    }
    let tempFlag = false;
    if (reqObj.socialNetworks && reqObj.socialNetworks.length !== 0) {
      reqObj.socialNetworks.forEach((so) => {
        if (!so.socialId) {
          tempFlag = true;
        } else if (!so.name) {
          tempFlag = true;
        } else if (!so.socialtext) {
          tempFlag = true;
        }
      });
    }
    if (self.state.rapId) {
      reqObj.socialNetworks.push({ socialId: "", name: "RAPNET", socialtext: self.state.rapId });
    }
    if (self.state.instaId) {
      reqObj.socialNetworks.push({ socialId: "", name: "INSTAGRAM", socialtext: self.state.instaId });
    }

    if (!tempFlag) {
      const obj = {
        ...ADMIN_UPDATE_USER,
        request: reqObj,
      };

      // if (self.handleValidation()) {
      UtilService.callApi(obj, (err, data) => {
        if (err && err.data) {
          if (err.data.code === "E_USER_NOT_FOUND") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (err.data.code === "E_DUPLICATE") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }
        if (data && data.code === "OK") {
          // self.props.onClose();
          const storageObj = reqObj;
          storageObj.account = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;
          UtilService.setLocalStorageItem(storageObj, `${LOCAL_STORAGE_VAR}-user`);

          OpenNotification({
            type: "success",
            title: "Personal details are updated successfully.",
          });
          self.props.onClose();
        }
      });
    } else {
      OpenNotification({
        type: "error",
        title: "Please select and write all social ids you have added.",
      });
    }
  }

  render() {
    return (
      <div>
        <div className="profileHead">
          <Heading title={<IntlMessage id="app.socialTitle" />} className="p-0 popupInnerTitle" />
        </div>
        <div className="profileDetailSpace">
          {this.state.socialDynamic &&
            this.state.socialDynamic.length !== 0 &&
            this.state.socialDynamic.map((soc, ind) => {
              return (
                <>
                  {" "}
                  <SelectOption
                    social
                    groupClass
                    defaultValue="Select Social ID"
                    selectValue={this.state.socialArray}
                    label={<IntlMessage id="app.SocialID" />}
                    onChange={(e) => {
                      const sp = e.split("/");
                      const tem = this.state.socialDynamic;
                      const ar = this.state.tempId;
                      ar[ind] = sp[0];

                      tem[ind].socialId = e;
                      tem[ind].name = sp[1];

                      this.setState({ socialDynamic: tem });
                      if (this.state.temSocial && this.state.temSocial.length !== 0) {
                        this.setState({ tempId: ar }, () => {
                          const abc = this.state.temSocial.filter((soc) => !this.state.tempId.includes(soc.id[0]));
                          this.setState({ socialArray: abc });
                        });
                      }
                    }}
                    value={soc.socialId ? soc.socialId : "Select Social ID"}
                  />
                  <InputBlock
                    onChange={(e) => {
                      const tem = this.state.socialDynamic;
                      tem[ind].socialtext = e.target.value;

                      this.setState({ socialDynamic: tem });
                    }}
                    type="text"
                    label={<IntlMessage id="app.ID" />}
                    placeholder="ID"
                    value={soc.socialtext}
                  />
                </>
              );
            })}
          {this.state.socialDynamic && this.state.socialDynamic.length >= 1 && (
            <span
              className="commonOutline"
              onClick={() => {
                const tempArray = this.state.socialDynamic;
                const tem = this.state.tempId;

                if (tempArray && tempArray.length !== 0) {
                  tempArray.splice(tempArray.length - 1, 1);
                  this.setState({ socialDynamic: tempArray, addClass: true });
                }
                if (tem && tem.length !== 0) {
                  tem.splice(tem.length - 1, 1);
                  this.setState({ tempId: tem }, () => {
                    const abc = this.state.temSocial.filter((soc) => !this.state.tempId.includes(soc.id[0]));
                    this.setState({ socialArray: abc });
                  });
                }
              }}
            >
              <IntlMessage id="app.RemoveAbove" />
            </span>
          )}
          <span
            className="commonOutline"
            onClick={() => {
              const tempArray = this.state.socialDynamic;
              tempArray.push({ socialId: "", socialtext: "", name: "" });
              this.setState({ socialDynamic: tempArray, addClass: true });
            }}
          >
            <IntlMessage id="app.AddMore" />
          </span>
        </div>
        <div className="sideBarPopupButton">
          <span className="commonButton" onClick={() => this.updateUser()}>
            <IntlMessage id="app.SaveSocial" />
          </span>
          <span className="commonOutline" onClick={() => this.props.onClose?.()}>
            <IntlMessage id="app.Cancel" />
          </span>
        </div>
      </div>
    );
  }
}
export default withRouter(SocialInformation);
