import "react-app-polyfill/ie9";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { store } from "appRedux/store/index";
import Root from "config/Root";

import "styles/_main.less";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// const render = () => {
//   ReactDOM.render(
//     <>
//       <div
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           backgroundColor: '#f2f2f2',
//           height: '100vh',
//           textAlign: '-webkit-center',
//           userSelect: 'none',
//         }}
//       >
//         <img style={{ objectFit: 'contain' }} src={require('../src/assets/svg/logo.svg')} alt="KGk" />

//         <br />
//         <br />
//         <h1>We'll be back.</h1>
//         <h3>We're busy updating the kgk.live application for you.</h3>
//         <h3>Please check back soon.</h3>
//       </div>
//     </>,

//     document.getElementById('root'),
//   );
// };

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById("root")
  );
};

render(Root);

if (module.hot) {
  module.hot.accept("./config/Root", () => {
    const newApp = Root.default;
    render(newApp);
  });
}
