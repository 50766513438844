import React from "react";

import userEditSVG from "../../../assets/svg/Profile/useredit.svg";

const ProfileUpload = (props) => {
  return (
    <div className="userEditBlock">
      {/* profileUploadBlock */}
      <input type="file" accept={props.accept ? props.accept : null} onChange={props.onChange} />
      <img src={userEditSVG} alt="" />
      {/* <label>{props.label}</label> */}
    </div>
  );
};
export default ProfileUpload;
