import React, { useState, useEffect, useCallback } from "react";

import { Slider } from "antd";
import first from "lodash/first";
import includes from "lodash/includes";
import last from "lodash/last";

import IntlMessage from "util/IntlMessage";

import { LOCAL_STORAGE_VAR } from "../../../constants/Common";

const marks = {
  0: "FL",
  8: "IF",
  18: "VVS1",
  30: "VVS2",
  40: "VS1",
  50: "VS2",
  60: "SI1",
  70: "SI2",
  80: "I1",
  90: "I2",
  100: "I3",
};

const ClarityRange = (props) => {
  const [def, setDef] = useState([0, 100]);
  const [inSide, setInside] = useState(false);

  const throwId = useCallback(
    (fil) => {
      const getArray = [];
      if (!inSide) {
        fil.forEach((co) => {
          Object.keys(marks).forEach((v) => {
            if (marks[v] === co) {
              getArray.push(Number(v));
            }
          });
        });
        const sorted = getArray.slice().sort((a, b) => {
          return a - b;
        });

        const data = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).CLARITY;
        const dt = [];
        let ids = [];

        const ft = Number(sorted[0]);
        const lt = Number(sorted[sorted.length - 1]);

        Object.keys(marks).forEach((v) => {
          if (v >= ft && v <= lt) {
            dt.push(marks[v]);
          }
        });

        data.forEach((d) => {
          if (includes(dt, d.code)) {
            ids = ids.concat(d.id);
          }
        });
        return props.setIds(ids);
      }
    },
    [inSide, props]
  );

  const setPreClarity = useCallback(
    (fil) => {
      const getArray = [];
      if (!inSide) {
        fil.forEach((co) => {
          Object.keys(marks).forEach((v) => {
            if (marks[v] === co) {
              getArray.push(Number(v));
            }
          });
        });
        const sorted = getArray.slice().sort((a, b) => {
          return a - b;
        });

        const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];
        setDef([...defaultArray]);
      }
    },
    [inSide]
  );

  useEffect(() => {
    if (props.preRange && props.preRange.length !== 0) {
      setPreClarity([...props.preRange]);
      throwId([...props.preRange]);
    }
  }, [props.preRange]);

  const getClarRange = (range) => {
    setDef([...range]);
    setInside(true);
  };

  const getClarRangeAfter = (range) => {
    const data = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).CLARITY;
    const dt = [];
    let ids = [];

    const ft = first(range);
    const lt = last(range);

    Object.keys(marks).forEach((v) => {
      if (v >= ft && v <= lt) {
        dt.push(marks[v]);
      }
    });

    data.forEach((d) => {
      if (includes(dt, d.code)) {
        ids = ids.concat(d.id);
      }
    });
    return props.setClarIds(ids);
  };

  return (
    <div className="dashquickSearchItem d-flex align-items-center">
      {props.titleHide ? null : (
        <div className="dashquickSearchLabel">
          <span>
            <IntlMessage id="app.clarity" />
          </span>
        </div>
      )}
      <div className="dashquickSearchBlock">
        <Slider
          onChange={getClarRange}
          onAfterChange={getClarRangeAfter}
          step={10}
          marks={marks}
          range
          value={def}
          tooltipVisible={false}
        />
      </div>
    </div>
  );
};
export default React.memo(ClarityRange);
