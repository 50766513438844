import React from "react";
import "./input.less";

const InputBlock = (props) => {
  let groupClass = "from-group inputBlock";
  if (props.hasError) {
    groupClass += " error-message";
  }
  if (props.disabledClass) {
    groupClass = props.disabledClass;
  }
  return (
    <div className={groupClass}>
      {props.label ? <label>{props.label}</label> : null}
      <div className={`inputWrapper ${(props.mobileISD || props.mobileSTD) && "mobileFiled"}`}>
        {props.mobileISD && <input className="smallInput" placeholder={props.ISDplaceholder} />}
        {props.mobileSTD && <input className="smallInput" placeholder={props.STDplaceholder} />}
        <input
          placeholder={props.placeholder}
          value={props.value ? props.value : ""}
          type={props.type ? props.type : "text"}
          onChange={props.onChange ? props.onChange : null}
          onBlur={props.onBlur ? props.onBlur : null}
          onKeyDown={(e) => {
            if (e.keyCode === 13) props.loginKeyDown && props.loginKeyDown();
          }}
          {...props}
        />
      </div>
      <span style={{ color: "red" }}>{props.hasError ? props.hasError : ""}</span>
    </div>
  );
};
export default InputBlock;
