import React from "react";
import { useSelector } from "react-redux";

import { Drawer } from "antd";

import MobileQuickSearch from "components/Dashboard/QuickSearch/MobileQuickSearch";
import { ListingCards } from "components/common/DiamondListing/MobileComfirmStone";
import MobileGroupCard from "components/common/MobileDiamondList/MobileGroupCard";
import NoDataFound from "components/common/NoDataFound";

import { usePathname } from "util/hooks";
import useRowSelect from "util/useRowSelect";
import { formatDate, formatTime, isArray, isEmpty } from "util/utils";

import { PAGES } from "constants/Common";

import Heading from "../common/Heading";
import SelectOption from "../common/SelectOption";

const LISTINGPAGES = PAGES;

const getGroupedData = (arr, key) => {
  const data = [];
  let iter = -1;
  arr.forEach((x) => {
    if (x[key]) iter += 1;
    if (!data[iter]) data[iter] = [];
    data[iter].push(x);
  });
  return data;
};

function MobileViewList(props) {
  const path = usePathname();
  const [status, setStatus] = React.useState(1);
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const { getTitle, getListTop, getSelection, getGridView, getAction, noQuickSearch, setOrderStatus, setOfficeStatus } =
    props.parent;
  const { toggleRowSelection } = useRowSelect(props.currentType);
  const title = React.useMemo(() => getTitle?.(), [getTitle]);
  const listTop = React.useMemo(() => getListTop?.(), [getListTop]);
  const selection = React.useMemo(() => getSelection?.(), [getSelection]);
  const gridView = React.useMemo(() => getGridView?.(), [getGridView]);
  const actions = React.useMemo(() => getAction?.(), [getAction]);

  const data = React.useMemo(() => {
    return path === LISTINGPAGES.QUOTE
      ? props.data.map((record) => [record])
      : path === LISTINGPAGES.OFFICE
      ? getGroupedData(props.data, "isOfficeHeader")
      : path === LISTINGPAGES.MATCHPAIR
      ? getGroupedData(props.data, "isMatchHeader")
      : path === LISTINGPAGES.CONFIRM
      ? getGroupedData(props.data, "isConfirmHeader")
      : path === LISTINGPAGES.UPCOMING
      ? getGroupedData(props.data, "isUpcomingHeader")
      : null;
  }, [path, props.data]);
  const selectedRows = useSelector((state) => state.diamondData.selectedRows[props.currentType]);
  const checked = React.useMemo(() => selectedRows ?? props.checkedData ?? [], [props.checkedData, selectedRows]);
  const getGroupTitle = React.useCallback(
    (item) => {
      return path === LISTINGPAGES.QUOTE
        ? formatDate(item.updatedAt)
        : path === LISTINGPAGES.OFFICE
        ? `${formatDate(item.date)} - [${formatTime(item.cabinSlot[0].start)} - ${formatTime(item.cabinSlot[0].end)}]`
        : path === LISTINGPAGES.MATCHPAIR
        ? item.groupingTitle
        : path === LISTINGPAGES.CONFIRM
        ? item.memoNo
        : path === LISTINGPAGES.UPCOMING
        ? `${formatDate(item.inDt)  } 3 PM IST`
        : null;
    },
    [path]
  );
  const ids = (checked || []).map(({ id }) => id);
  return (
    <div className="searchResultListWrapper">
      <div className="selectStoneHeader">
        <div className="listingTopBlock width-100">
          {noQuickSearch && <MobileQuickSearch />}
          <div className="d-flex justify-content-between align-items-center width-100">
            <Heading title={title} />
            {listTop}
            {" "}
            {props.showStatus && (
              <span
                style={{ marginRight: "25px", textDecoration: "underline", fontWeight: "bold" }}
                onClick={() => setModal(!modal)}
              >
                {status === 1 ? "Pending" : status === 2 ? "Approved" : "Rejected"}
              </span>
            )}
            {props.status && (
              <span
                style={{ marginRight: "25px", textDecoration: "underline", fontWeight: "bold" }}
                onClick={() => setModal1(!modal1)}
              >
                {status === 1 ? "Pending" : status === 2 ? "Approved" : "Rejected"}
              </span>
            )}
          </div>
          {selection}
        </div>
      </div>

      <div className="searchInnerResult">
        {props.listView ? (
          <div className="diamondListMobile">
            {props.mathPair ? (
              <>
                {Number(props.count) <= 1000 && (
                  <>
                    {isArray(data) && !isEmpty(data) ? (
                      data.map((item, index) => {
                        return (
                          <MobileGroupCard
                            onClick={() => {
                              if (props.mathPair) {
                                item.forEach((x) => {
                                  if (ids.includes(x.id)) {
                                    toggleRowSelection(x, false);
                                  } else {
                                    toggleRowSelection(x, true);
                                  }
                                });
                              }
                            }}
                            key={index}
                            title={getGroupTitle(item?.[0])}
                          >
                            <ListingCards
                              onClick={() => {
                                if (props.mathPair) {
                                  item.forEach((x) => {
                                    if (ids.includes(x.id)) {
                                      toggleRowSelection(x, false);
                                    } else {
                                      toggleRowSelection(x, true);
                                    }
                                  });
                                }
                              }}
                              matchPair={props.mathPair}
                              data={item}
                              quotePage={path === LISTINGPAGES.QUOTE}
                              currentType={props.currentType}
                            />
                          </MobileGroupCard>
                        );
                      })
                    ) : (
                      <ListingCards
                        data={props.data}
                        note={path === LISTINGPAGES.NOTE}
                        currentType={props.currentType}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {isArray(data) && !isEmpty(data) ? (
                  data.map((item, index) => {
                    return (
                      <MobileGroupCard
                        onClick={() => {
                          if (props.mathPair) {
                            item.forEach((x) => {
                              if (ids.includes(x.id)) {
                                toggleRowSelection(x, false);
                              } else {
                                toggleRowSelection(x, true);
                              }
                            });
                          }
                        }}
                        key={index}
                        title={getGroupTitle(item?.[0])}
                      >
                        <ListingCards
                          onClick={() => {
                            if (props.mathPair) {
                              item.forEach((x) => {
                                if (ids.includes(x.id)) {
                                  toggleRowSelection(x, false);
                                } else {
                                  toggleRowSelection(x, true);
                                }
                              });
                            }
                          }}
                          matchPair={props.mathPair}
                          data={item}
                          quotePage={path === LISTINGPAGES.QUOTE}
                          currentType={props.currentType}
                        />
                      </MobileGroupCard>
                    );
                  })
                ) : (
                  <ListingCards data={props.data} note={path === LISTINGPAGES.NOTE} currentType={props.currentType} />
                )}
              </>
            )}

            {isEmpty(props.data) && <NoDataFound loading={props.loading} />}
            {props.mathPair && Number(props.count) > 1000 && (
              <div className="empty-data">
                <h3>Please modify your search, maximum 1000 stones can be displayed </h3>
              </div>
            )}
          </div>
        ) : (
          <div className="searchResultGridView">
            {props.mathPair ? <>{Number(props.count) <= 1000 && gridView}</> : gridView}
            {props.mathPair && Number(props.count) > 1000 && (
              <div className="empty-data">
                <h3>Please modify your search, maximum 1000 stones can be displayed </h3>
              </div>
            )}
          </div>
        )}
        {actions}
      </div>
      {modal && (
        <Drawer
          title={false}
          onClose={() => setModal(!modal)}
          visible={modal}
          wrapClassName="diamondListinSidebar smallSidebarMobilePopup"
        >
          <div>
            <div className="offerInputBox">
              <SelectOption
                value={status}
                selectValue={[
                  { name: "Pending", id: 1 },
                  { name: "Approved", id: 2 },
                  { name: "Rejected", id: 3 },
                  { name: "Canceled", id: 4 },
                ]}
                onChange={(value) => {
                  setOrderStatus(value);
                  setStatus(value);
                  setModal(false);
                }}
                placeholder="Please select status"
                label="Please select status"
              />
            </div>
          </div>
        </Drawer>
      )}
      {modal1 && (
        <Drawer
          title={false}
          onClose={() => setModal1(!modal1)}
          visible={modal1}
          wrapClassName="diamondListinSidebar smallSidebarMobilePopup"
        >
          <div>
            <div className="offerInputBox">
              <SelectOption
                value={status}
                selectValue={[
                  { name: "Pending", id: 1 },
                  { name: "Approved", id: 2 },
                  { name: "Rejected", id: 3 },
                ]}
                onChange={(value) => {
                  setOfficeStatus(value);
                  setStatus(value);
                  setModal1(false);
                }}
                placeholder="Please select status"
                label="Please select status"
              />
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
}

export default React.memo(MobileViewList);
