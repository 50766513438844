import React, { Component } from "react";
import { useTable } from "react-table";

import { Drawer } from "antd";
import _ from "lodash";

import { handleAlter, getPath } from "./DiamondListFunctions";
import DiamondListingHead from "./ListTableHeader";

import camSVG from "../../assets/svg/camera.svg";
import certSVG from "../../assets/svg/certi.svg";
import vidSVG from "../../assets/svg/video.svg";
import { LAB_LINKS, FILE_URLS, PAGES } from "../../constants/Common";
import DiamondDetail from "../DiamondDetail";
import CheckBox from "../common/CheckBox";
import Status from "../common/DiamondListing/Status";
import TableGrouping from "../common/DiamondListing/TableGrouping";

const LISTINGPAGES = PAGES;

export const canSortCommon = {
  stoneId: "Stone No",
  shpNm: "Shape",
  crt: "Carat",
  colNm: "Color",
  clrNm: "Clarity",
};
export const LIMIT = 100;
// export const FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm'];
export const floatkeys = [
  "depPer",
  "ratio",
  "crt",
  "rapAvg",
  "pAng",
  "pHgt",
  "cHgt",
  "back",
  "cAng",
  "fnDis",
  "height",
  "width",
  "length",
  "grdlPer",
  "strLn",
  "newDiscount",
  "calcDiscount",
  "calcAmount",
  "newAmount",
  "calcPricePerCarat",
  "newPricePerCarat",
];
export const roundkeys = ["ctPr", "amt", "rap", "tblPer", "lwrHal"];
const DISPLAY_TOTAL = ["ctPr", "amt", "rap", "crt", "fnCtpr", "fnAmt", "calcAmount"];
export const NOCHECKIDS = ["Details", "Action", "quote", "expBack", "hours", "bidPricePerCarat", "note"];

const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass ? item.cellClass.replace("text-", "") : "center",
      width: item.width ? item.width + "px" : "100px",
      // fontWeight:
      //     type === 'cell' && item.id === 'dna' //  (item.id === 'stoneId' || item.id === 'vStnId')
      //         ? '600'
      //         : '',
      color:
        type === "cell" && (item.id === "lbNm" || item.id === "rptNo" || item.link)
          ? // item.id === 'shpNm' ||
            "#008cba"
          : "",
    },
  },
];
const headerProps = (props, { column }) => getStyles(props, column, "header");
const cellProps = (props, { cell }) => getStyles(props, cell.column, "cell");

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      detail: null,
    };
  }

  handleSort = (id) => {
    // let id = _.find(this.props.columns, { id: colid }).sort
    let sort = _.cloneDeep(this.props.sort);
    let found = false;
    _.each(sort, (val) => {
      if (Object.prototype.hasOwnProperty.call(val, id)) {
        found = val;
      }
    });
    // _.each(sort, (val) => {
    //   if (val.hasOwnProperty(id)) found = val;
    // });
    if (!found) sort.push({ [id]: "ASC" });
    else {
      const index = sort.indexOf(found);
      // if (found[id] === 'DESC') sort = sort.filter((el) => !el.hasOwnProperty(id));
      if (found[id] === "DESC") {
        sort = sort.filter((el) => !Object.prototype.hasOwnProperty.call(el, id));
      } else {
        found[id] = "DESC";
        sort[index] = found;
      }
    }
    this.props.handleSort(sort);
  };

  selectFilter = (x, colId, remove = false) => {
    let selection = _.cloneDeep(this.props.selectedFilterArray);
    if (!remove) {
      if (selection[colId]) selection[colId].push(x);
      else selection[colId] = [x];
    } else {
      selection[colId] = selection[colId].filter((el) => el !== x);
      if (!selection[colId].length) selection = _.omit(selection, colId);
    }
    this.props.selectFilter(selection);
  };
  addDefaultSrc = () => {
    this.setState({ detail: { i: "" } });
  };
  render() {
    const self = this;
    const columns = this.props.nocheck
      ? this.props.columns
      : [
          {
            Header: "",
            width: 50,
            accessor: "action",
            id: "action",
            Cell: ({ row }) => (
              <div className="selectActionIcon">
                {!self.props.nostatus && <Status nocheck={self.props.noCheckBox} status={row.original.wSts} />}
                {!self.props.noCheckBox && (
                  <div className="selectActionIconWrapper">
                    <CheckBox
                      checked={_.find(self.props.checked, {
                        stoneId: row.original.stoneId,
                      })}
                    />
                  </div>
                )}
              </div>
            ),
          },
          ...this.props.columns,
        ];
    const allCheck =
      !self.props.nocheck &&
      !self.props.noCheckBox &&
      self.props.data.length === self.props.checked.length &&
      self.props.data.length !== 0 &&
      JSON.stringify(self.props.checked.map((x) => x.stoneId).sort()) ===
        JSON.stringify(self.props.data.map((x) => x.stoneId).sort());

    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, k) => (
              <tr key={k} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps(headerProps)}
                    style={{ width: column.width ? column.width + "px" : "100px" }}
                    key={i}
                  >
                    {column.id !== "action" && (
                      <DiamondListingHead
                        {...self.props}
                        header={column.render("Header")}
                        column={column}
                        selectFilter={self.selectFilter}
                        sortClick={(k) => self.handleSort(k)}
                      />
                    )}
                    {column.id === "action" && (
                      <div className="selectActionIcon">
                        <div className="selectActionIconWrapper">
                          {!self.props.nostatus && <Status nocheck={self.props.noCheckBox} status="all" />}
                          {!self.props.noCheckBox && (
                            <CheckBox
                              checked={allCheck}
                              onChange={() => {
                                if (self.props.nocheck || self.props.noCheckBox) return;
                                self.props.handleCheck(allCheck ? [] : self.props.data);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {self.props.loading ? (
            <></>
          ) : (
            <tbody {...getTableBodyProps()}>
              {rows.length ? (
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <>
                      {row.original.isMatchHeader && (
                        <TableGrouping
                          displayTotal={DISPLAY_TOTAL.filter((el) => (_.find(columns, { id: el }) ? true : false))}
                          columns={columns}
                          row={row.original}
                          key={i}
                        />
                      )}
                      {(row.original.isConfirmHeader ||
                        row.original.isUpcomingHeader ||
                        (getPath() === LISTINGPAGES.QUOTE && row.original.updatedAt)) && (
                        <TableGrouping row={row.original} />
                      )}
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, k) => {
                          return (
                            <td
                              key={k}
                              {...cell.getCellProps(cellProps)}
                              onClick={() => {
                                if (cell.column.link && cell.value) {
                                  let field = cell.column.link.slice(
                                    cell.column.link.indexOf("$") + 1,
                                    cell.column.link.length
                                  );
                                  field = field.slice(0, field.indexOf("$"));
                                  const link = cell.column.link.replace("$" + field + "$", row.original[field]);
                                  window.open(link);
                                } else if (cell.column.id === "vStnId" || cell.column.id === "shpNm") {
                                  self.setState({ visible: row.original });
                                } else if (cell.column.id === "ftc" && cell.value) {
                                  window.open(`/ftc/${row.original.id}`);
                                } else if (
                                  (cell.column.id === "lbNm" || cell.column.id === "rptNo") &&
                                  row.original.lbNm &&
                                  row.original.rptNo
                                ) {
                                  window.open(
                                    LAB_LINKS[row.original.lbNm.toUpperCase()].replace("***", row.original.rptNo)
                                  );
                                } else if (!NOCHECKIDS.includes(cell.column.id)) {
                                  if (self.props.nocheck || self.props.noCheckBox) return;
                                  const newchecked = handleAlter(self.props.checked, row.original, "stoneId");
                                  self.props.handleCheck(newchecked);
                                }
                              }}
                            >
                              {cell.column.id !== "Details" && cell.render("Cell")}
                              {cell.column.id === "Details" && (
                                <>
                                  {true && (
                                    <img
                                      style={{ marginRight: "5px" }}
                                      src={camSVG}
                                      width="15px"
                                      alt=""
                                      onClick={() =>
                                        this.setState({
                                          detail: { i: row.original },
                                        })
                                      }
                                    />
                                  )}
                                  {true && (
                                    <img
                                      style={{ marginRight: "5px" }}
                                      src={vidSVG}
                                      width="15px"
                                      alt=""
                                      onClick={() =>
                                        this.setState({
                                          detail: { v: row.original },
                                        })
                                      }
                                    />
                                  )}
                                  {true && (
                                    <img
                                      src={certSVG}
                                      width="15px"
                                      alt=""
                                      onClick={() =>
                                        this.setState({
                                          detail: { c: row.original },
                                        })
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          )}
        </table>
      );
    };

    let filterRows = this.props.data;
    const selectedFilter = this.props.selectedFilterArray ? this.props.selectedFilterArray : {};
    Object.keys(selectedFilter).forEach((key) => {
      filterRows = filterRows.filter((el) => selectedFilter[key].includes(el[key]));
    });

    return (
      <>
        <Table columns={columns} data={filterRows} />
        {this.state.visible && !this.props.finalDestination && (
          <Drawer
            title={false}
            onClose={() => this.setState({ visible: false })}
            visible={this.state.visible}
            wrapClassName="diamondDetailPopup"
          >
            <DiamondDetail
              diamondPopup={true}
              data={this.state.visible}
              onClose={() => this.setState({ visible: false })}
            />
          </Drawer>
        )}
        {this.state.detail && (
          <Drawer
            title={false}
            onClose={() => this.setState({ detail: null })}
            visible={this.state.detail}
            wrapClassName="diamondListinSidebar onlyImageSlider"
          >
            <div className="diamondImagePopup">
              {!this.state.detail.c && !this.state.detail.v && this.state.detail.i ? (
                <div className="diamondDetailImageBox">
                  <img
                    alt=""
                    onError={this.addDefaultSrc}
                    src={FILE_URLS.img.replace("***", this.state.detail.i.vStnId)}
                  />{" "}
                </div>
              ) : (
                "Not Image Found"
              )}
              {this.state.detail.v && (
                <iframe
                  src={FILE_URLS.videoFile.split("***").join(this.state.detail.v.vStnId)}
                  height="100%"
                  width="100%"
                  title={FILE_URLS.videoFile.split("***").join(this.state.detail.v.vStnId)}
                />
              )}
              {this.state.detail.c && (
                <iframe
                  src={FILE_URLS.certFile.replace("***", this.state.detail.c.rptNo)}
                  height="100%"
                  width="100%"
                  title={FILE_URLS.videoFile.split("***").join(this.state.detail.v.vStnId)}
                />
              )}
            </div>
          </Drawer>
        )}
      </>
    );
  }
}

export default Table;
