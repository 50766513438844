import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import groupBy from "lodash/groupBy";
import last from "lodash/last";
import size from "lodash/size";

import Header from "components/Header";

import IntlMessage from "util/IntlMessage";
import { formatDecimal, formatNumber, queryStringToObject } from "util/utils";

import BasicDetail from "./BasicDetail";
import Compare from "./Compare";
import DiamondDetailAction from "./DiamondDetailAction";
import EnquiryDetail from "./EnquiryDetail";
import ExtraButton from "./ExtraButton";
import DiamondDetailLeft from "./LeftSection2";
import RecentlyView from "./RecentlyView";

import { CURRENCY_SYMBOL, KYC_STATUS, PAGES } from "../../constants/Common";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import DetailView from "../../constants/DiamondDetail";
import { GET_DIAMOND_DATA, SHAPE, VIEW_DIAMOND, VIEW_DIAMOND_AUTH } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { getGridHeight } from "../DiamondList";
import "react-loading-skeleton/dist/skeleton.css";
import { downloadZip, isMobile, handleHitCounter, handleHitCounterNoAuth } from "../DiamondList/DiamondListFunctions";
import { GuestUserPopup } from "../GuestUserPopup";
import { NoKycPop } from "../NoKycPop";
import ConfirmStonePopup from "../common/DiamondListing/ConfirmStonePopup";
import QuotePopup from "../common/DiamondListing/QuotePopup";
import SendEmail from "../common/DiamondListing/SendEmail";
import Heading from "../common/Heading";

import "../../styles/DiamondDetail2.less";
import diamondSvg from "assets/svg/Diamond.svg";

const LISTINGPAGES = PAGES;
const floatkeys = [
  "amt",
  "back",
  "cAng",
  "cHgt",
  "crt",
  "ctPr",
  "depPer",
  "fnDis",
  "grdlPer",
  "height",
  "length",
  "pAng",
  "pHgt",
  "rap",
  "rapAvg",
  "ratio",
  "strLn",
  "width",
];
const roundkeys = ["tblPer", "lwrHal"];

class DiamondDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      show: false,
      detail: true,
      data: {},
      sendEmail: false,
      isGuest: UtilService.getLocalStorageItem(
        window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
      ),
      showGuest: false,
      more: false,
      currentUrl: "",
      noKyc: false,
      isKyc: false,
      accurateStock: {},
      suggestedStock: {},
      getCompareDiamonds: undefined,
      showSkeleton: true,
    };
  }

  get view() {
    const query = queryStringToObject();
    query.view = Number(query.view);
    return query.view;
  }

  get urlMatch() {
    return ["/account/order-list", "/bid-to-buy", "/new-arrival", "/my-bid"].includes(this.props.location.pathname);
  }

  placeOrderDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        this.setState({
          visible: true,
          detail: false,
          show: false,
          offer: false,
        });
      }
    }
  };

  placeOffer = () => {
    this.setState({ offer: true, detail: false, visible: false });
  };

  EnquiryDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        this.setState({ show: true, detail: false, visible: false });
      }
    }
  };

  handleMoreOption = () => {
    this.setState({ more: !this.state.more });
  };

  popupClose = () => {
    this.setState({ detail: true, visible: false, show: false, offer: false });
  };

  componentDidMount = () => {
    const requestConfig = {
      method: SHAPE.method,
      request: {
        masters: ["SHADE", "SHAPE"],
      },
      url: SHAPE.url,
    };
    UtilService.callApi(requestConfig, async (err, res) => {
      if (!err && res.code === "OK") {
        const shape = res?.data?.SHAPE;
        this.setState({ shape: shape });
      }
    });

    const userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    let id = last(window.location.pathname.split("/"));
    if (this.props.diamondPopup) {
      if (this.props.data && this.props.data.id) {
        id = this.props.data.diamondId ? this.props.data.diamondId : this.props.data.id;
      }
    }
    if (id !== LISTINGPAGES.DETAILS && id !== LISTINGPAGES.DETAIL) {
      userData && userData.account ? handleHitCounter(id) : handleHitCounterNoAuth(id);

      let url = VIEW_DIAMOND.url;
      if (userData && userData.account) {
        url = VIEW_DIAMOND_AUTH.url;
      }
      const api = `${url}/${id}`;
      const objData = {
        method: VIEW_DIAMOND.method,
        request: {
          //showSimilar: true,
          // compareDiamonds: true,
          // showRecent: true,
        },
        url: api,
        showLoader: true,
      };
      UtilService.callApi(objData, async (err, data) => {
        if (err) throw err;
        if (data && data.code === "OK") {
          this.setState({ data: data.data, getCompareDiamonds: !this.state.getCompareDiamonds, showSkeleton: false });
        }
      });
    }

    const _url = GET_DIAMOND_DATA.url;
    const _api = `${_url}`;
    const _objData = {
      method: GET_DIAMOND_DATA.method,
      request: {
        filters: [{ sectionType: 14 }],
        isAppendMasters: true,
        isSkipSave: true,
        page: 1,
        limit: 10,
        sort: [{ updatedAt: "DESC" }],
      },
      url: _api,
    };
    UtilService.callApi(_objData, async (err, res) => {
      if (err) throw err;
      if (res && res.code === "OK") {
        this.setState({ accurateStock: res.data[0] });
      }
    });
    // let i = 0;
    for (let i = 0; i < 1; i++) {
      const request =
        i === 1
          ? { filters: [{ sectionType: 14 }], isAppendMasters: true, isSkipSave: true }
          : {
              filters: [{ sectionType: 14, suggestedStockType: "suggested" }],
              isAppendMasters: true,
              isSkipSave: true,
              page: 1,
              limit: 10,
              sort: [{ updatedAt: "DESC" }],
            };
      const _url = GET_DIAMOND_DATA.url;
      const _api = `${_url}`;
      const _objData = {
        method: GET_DIAMOND_DATA.method,
        request: request,
        url: _api,
      };
      UtilService.callApi(_objData, async (err, res) => {
        if (err) throw err;
        if (res && res.code === "OK") {
          i === 0 ? this.setState({ accurateStock: res.data[0] }) : this.setState({ suggestedStock: res.data[0] });
        }
      });
    }

    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

    if (authUser && authUser.account) {
      if (!authUser.account.isKycUploaded) {
        this.setState({ isKyc: !authUser.account.isKycUploaded });
      } else {
        if (authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();

    if (this.state.getCompareDiamonds !== prevState.getCompareDiamonds) {
      const userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
      let id = last(window.location.pathname.split("/"));
      if (this.props.diamondPopup) {
        if (this.props.data && this.props.data.id) {
          id = this.props.data.diamondId ? this.props.data.diamondId : this.props.data.id;
        }
      }
      if (id !== LISTINGPAGES.DETAILS && id !== LISTINGPAGES.DETAIL) {
        userData && userData.account ? handleHitCounter(id) : handleHitCounterNoAuth(id);

        let url = VIEW_DIAMOND.url;
        if (userData && userData.account) {
          url = VIEW_DIAMOND_AUTH.url;
        }
        const api = `${url}/${id}`;
        const objData = {
          method: VIEW_DIAMOND.method,
          request: {
            compareDiamonds: true,
            showRecent: true,
          },
          url: api,
          showLoader: true,
        };
        UtilService.callApi(objData, async (err, data) => {
          if (err) throw err;
          if (data && data.code === "OK") {
            this.setState({ data: data.data, showSkeleton: false });
          }
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.authUser && nextProps.auth.authUser.account) {
      if (!nextProps.auth.authUser.account.isKycUploaded) {
        this.setState({
          isKyc: !nextProps.auth.authUser.account.isKycUploaded,
        });
      } else {
        if (nextProps.auth.authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }
  verifyCerti = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        window.open(`https://gia.edu/report-check?reportno=${data.rptNo}`);
      }
    }
  };

  downloadZip = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        downloadZip(
          {
            filter: {
              vStnId: [data.vStnId],
            },
            isExcel: true,
            img: true,
            certFile: true,
            videoFile: true,
            hAFile: true,
            arrowFile: true,
            assetFile: true,
          },
          () => {}
        );
      }
    }
  };

  sendMail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        this.setState({ sendEmail: true });
      }
    }
  };
  actionButtons = (data) => {
    const userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const path = window.location.search.split("?")?.[1];
    //let isPair = true;
    const isPair = data.isPair ?? false;
    return !data || data.wSts === "U" ? null : (
      <div className="diamondDetailBox">
        <div className="detailTopBasic">
          <div className="detailBasic">
            <BasicDetail data={data} shape={this?.state?.shape || {}} />
            <div className="basicSmallDetail">
              <span>{data.cutNm ? data.cutNm : "-"}</span>
              <span> | {data.polNm}</span>
              <span>
                {" "}
                {data.polNm && "|"} {data.symNm && data.symNm}
              </span>
              <span>
                {" "}
                {data.symNm && "|"} {data.fluNm && data.fluNm}
              </span>
              <span>
                {" "}
                {data.fluNm && "|"} {data.lbNm && data.lbNm}
              </span>
            </div>
          </div>
          {!this.state.isGuest && !this.state.isKyc && userData && userData.account && (
            <div className="detailPriceBlock">
              {data.amt ? (
                <span>{CURRENCY_SYMBOL + formatNumber(parseFloat(data.amt).toFixed(2).toLocaleString("en-US"))}</span>
              ) : (
                <></>
              )}
              {data.back ? <span className="offerPrice">( {`${parseFloat(data.back).toFixed(2)}`}% )</span> : undefined}
            </div>
          )}
        </div>
        <div className="diamondDetailBottomAction">
          <div className="d-flex flex-wrap">
            {!isPair && !this.state.isGuest && userData && userData.account ? (
              !(this.view < 1 || this.urlMatch) ? (
                <>
                  {USER_PERMIT?.CONFIRM_STONE?.view &&
                  Object.keys(data).length &&
                  data.wSts !== "N" &&
                  data.wSts !== "B" ? (
                    path !== "actBtn=true" ? (
                      <DiamondDetailAction
                        fillButton
                        title={<IntlMessage id="app.BuyNow" />}
                        onClick={() => {
                          if (this.state.isKyc) {
                            this.setState({ noKyc: true });
                          } else {
                            this.placeOrderDetail();
                          }
                        }}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  {USER_PERMIT?.QUOTE?.view && Object.keys(data).length && data.wSts !== "N" && data.wSts !== "B" ? (
                    path !== "actBtn=true" ? (
                      <DiamondDetailAction
                        outline
                        title={<IntlMessage id="app.AddTOOffer" />}
                        onClick={() => {
                          if (this.state.isKyc) {
                            this.setState({ noKyc: true });
                          } else {
                            this.placeOffer();
                          }
                        }}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                {!isPair && this.state.isGuest ? (
                  <DiamondDetailAction
                    outline
                    title="Register"
                    onClick={() => {
                      // this.props.history.push('/signup')
                      window.open("/signup", "_self");
                      window.localStorage.clear();
                      window.sessionStorage.clear();
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          {!isPair && this.state.visible && Object.keys(data).length && data.wSts !== "N" && data.wSts !== "B" ? (
            <ConfirmStonePopup
              popupClass={!this.props.diamondPopup ? null : "halfBlockPopup"}
              detail
              checked={[data]}
              onClose={this.popupClose}
            />
          ) : (
            <></>
          )}
          {!isPair && this.state.offer && Object.keys(data).length && data.wSts !== "N" && data.wSts !== "B" ? (
            <QuotePopup detail checked={[data]} onClose={this.popupClose} />
          ) : (
            <></>
          )}
          {(!isPair && this.view < 1) || (!isPair && this.urlMatch) || (!isPair && path === "actBtn=true") ? (
            <ExtraButton
              showShare={
                USER_PERMIT?.SHARE_VIA_MAIL?.view &&
                USER_PERMIT?.SHARE_VIA_WHATSAPP?.view &&
                USER_PERMIT?.SHARE_VIA_SKYPE?.view
              }
              showDownload={USER_PERMIT?.DOWNLOAD?.view}
              isGuest={this.state.isGuest}
              showPop={() => this.setState({ showGuest: true })}
              iskyc={this.state.isKyc}
              showKyc={() => this.setState({ noKyc: true })}
              url={this.state.currentUrl}
              fileName={this.state.fileName}
              data={data}
            />
          ) : !isPair ? (
            <ExtraButton
              showWatch={
                USER_PERMIT?.WATCHLIST?.view && Object.keys(data).length && data.wSts !== "N" && data.wSts !== "B"
              }
              showCart={USER_PERMIT?.CART?.view && Object.keys(data).length && data.wSts !== "N" && data.wSts !== "B"}
              showShare={
                USER_PERMIT?.SHARE_VIA_MAIL?.view &&
                USER_PERMIT?.SHARE_VIA_WHATSAPP?.view &&
                USER_PERMIT?.SHARE_VIA_SKYPE?.view
              }
              showDownload={USER_PERMIT?.DOWNLOAD?.view}
              isGuest={this.state.isGuest}
              showPop={() => this.setState({ showGuest: true })}
              iskyc={this.state.isKyc}
              showKyc={() => this.setState({ noKyc: true })}
              url={this.state.currentUrl}
              fileName={this.state.fileName}
              data={data}
            />
          ) : (
            <></>
          )}
          <div className="diamondetailTable">
            <div className="diamondDetailbasicWrap">
              <div className="diamondDetailBasic">
                <div className="diamondDetailLebl">
                  <IntlMessage id="app.length" />
                </div>
                <div className="diamondDetailValue">{data.length ? data.length.toFixed(2) : "-"}</div>
              </div>
              <div className="diamondDetailBasic">
                <div className="diamondDetailLebl">
                  <IntlMessage id="app.width" />
                </div>
                <div className="diamondDetailValue">{data.width ? data.width.toFixed(2) : "-"}</div>
              </div>
              <div className="diamondDetailBasic">
                <div className="diamondDetailLebl">
                  <IntlMessage id="app.depth" />
                </div>
                <div className="diamondDetailValue">{data.height ? data.height.toFixed(2) : "-"}</div>
              </div>
              <div className="diamondDetailBasic">
                <div className="diamondDetailLebl">
                  <IntlMessage id="app.table%" />
                </div>
                <div className="diamondDetailValue">{data.tblPer ? formatDecimal(data.tblPer) : "-"}</div>
              </div>
              <div className="diamondDetailBasic">
                <div className="diamondDetailLebl">
                  <IntlMessage id="app.depPer" />
                </div>
                <div className="diamondDetailValue">{data.depPer ? parseFloat(data.depPer).toFixed(2) : "-"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  diamondDetail = (data, key) => {
    const grouped = groupBy(data, key);
    let first = grouped[1] || [];
    const second = grouped[2] || [];
    if ((!first || !first.length) && (!second || !second.length)) {
      first = Object.values(grouped)[0];
    }
    const maxIndex = Math.max(first.length, second.length);
    const arr = [];
    for (let i = 0; i < maxIndex; i++) {
      first[i] && arr.push(first[i]);
      second[i] && arr.push(second[i]);
    }
    return arr;
  };

  leftPart = (data) => {
    const gridHeight1 = getGridHeight(["headerTopMenu", "covidStrip", "HeaderStripBlock", "diamondDetailTopTab"], 15);

    return (
      <div className="diamondDetailInnerDetail">
        {data && size(data) ? (
          <DiamondDetailLeft
            sendUrl={(currentUrl, fileName) => this.setState({ currentUrl, fileName })}
            item={data}
            rptNo={data.rptNo}
            pktNo={data.vStnId}
            style={
              isMobile() ? {} : !this.props.diamondPopup ? { height: gridHeight1, overflow: "auto" } : { height: "" }
            }
          />
        ) : null}
      </div>
    );
  };

  getEmailModal = (data) => {
    return (
      <SendEmail
        path={`${window.location.origin}/${LISTINGPAGES.DETAILS}/${data.id}`}
        xRay={true}
        sendEmail={this.state.sendEmail}
        onCancel={() => this.setState({ sendEmail: false })}
        onDetailClose={() => this.setState({ sendEmail: false })}
      />
    );
  };
  render() {
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`)
      ? UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account
      : {};
    const favicon = document.getElementById("favicon");
    const userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    if (!userData) {
      (document.title = "Diamond Detail") && (favicon.href = diamondSvg);
    }

    const data = this.state.data;
    // this.props.diamondPopup ? this.props.data : this.state.data;
    const gridHeight = getGridHeight(["headerTopMenu", "covidStrip", "HeaderStripBlock", "diamondDetailBottomBox"], 15);
    const shapeFull = this.state?.shape || {};

    const params = Object.keys(DetailView).map((k) => (
      <div className="diamondDetailMore" key={DetailView[k]["name"]}>
        <h2 className="detailSmallTitle">{DetailView[k]["name"]}</h2>
        <div className="diamondDetailMoreHalf">
          {this.diamondDetail(DetailView[k]["data"], "part").map((item) => {
            if (data.wSts === "U" && ["back", "ctPr", "amt"].includes(item.key)) return false;
            return (
              <ul key={item.key}>
                <li>
                  {!item.hide && (
                    <>
                      <span className="detailLabel">{item.label}</span>
                      <span className="detailvalue">
                        {!data[item["key"]]
                          ? "-"
                          : ["shpNm"].includes(item?.key)
                          ? shapeFull?.find((x) => x.code === data?.shpNm ?? data?.shpNm)?.name === undefined
                            ? data.shpNm
                            : shapeFull?.find((x) => x.code === data?.shpNm)?.name
                          : floatkeys.includes(item.key)
                          ? formatDecimal(data[item["key"]])
                          : roundkeys.includes(item.key)
                          ? formatDecimal(data[item["key"]])
                          : data[item["key"]]}
                      </span>
                    </>
                  )}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    ));
    //let isPair = true;
    const isPair = data.isPair ?? false;

    return (
      <>
        <Header />

        <div className="diamondDetailWrapper diamondDetailVersion2">
          <div className="diamondDetailInner">
            <div className="diamondDetailMainTab">
              {this.props.diamondPopup && <div className="diamondDetailLeft">{this.leftPart(data)}</div>}
              {!this.props.diamondPopup && (
                <div className="diamondDetailLeft">
                  {this.state.showSkeleton ? <Skeleton height={550} /> : this.leftPart(data)}
                </div>
              )}
              <div className={`diamondDetailRight mt-10 ${!this.props.diamondPopup && "diamondDetailPage"}`}>
                {this.props.diamondPopup ? this.actionButtons(data) : null}

                {!this.props.diamondPopup ? this.actionButtons(data) : null}
                {this.state.show && (
                  <EnquiryDetail
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: "auto" } : { height: "auto" }}
                    checked={[data]}
                    onCancel={() => this.popupClose()}
                    onClose={() => this.popupClose()}
                  />
                )}
              </div>
            </div>

            <div className={`diamondDetailBlock ${this.state.more && "active"}`}>
              <Heading title={<IntlMessage id="app.diamonddetail" />} className="p-0" />
              {this.state.detail && <div className="mt-10 d-flex flex-wrap">{params}</div>}
              {!this.state.detail && <div className="mt-10 d-flex flex-wrap">{params}</div>}
              {isMobile() && (
                <span className="moreLink" onClick={this.handleMoreOption}>
                  {!this.state.more ? <>More</> : <>Less</>}
                </span>
              )}
            </div>
            {!isPair &&
              this.state.data &&
              this.state.data.alternativeDiamonds &&
              this.state.data.alternativeDiamonds.length !== 0 && (
                <div className="detailCompareBlock">
                  <Heading title={<IntlMessage id="app.CompareAlternateDiamonds" />} className="p-0" />
                  <Compare
                    dataAmt={data.alternativeDiamonds ? data.amt : undefined}
                    compareList={this.state.data.alternativeDiamonds}
                  />
                </div>
              )}
            {!isPair && this.state.data && this.state.data.similarStones && this.state.data.similarStones.length !== 0 && (
              <div className="recentlyViewProduct">
                <Heading title={<IntlMessage id="app.SimilarDiamonds" />} className="p-0" />
                <div>
                  <RecentlyView diamondList={this.state.data.similarStones} />
                </div>
              </div>
            )}

            {!isPair &&
              this.state.data &&
              this.state.data.recentlyViewed &&
              this.state.data.recentlyViewed.length !== 0 && (
                <div className="recentlyViewProduct">
                  <Heading title={<IntlMessage id="app.RecentlyViewed" />} className="p-0" />
                  <div>
                    <RecentlyView diamondList={this.state.data.recentlyViewed} />
                  </div>
                </div>
              )}

            {!isPair && this.state.accurateStock && this.state.accurateStock.diamonds?.length !== 0 && (
              <div className="recentlyViewProduct">
                <Heading title={<IntlMessage id="app.AccurateStock" />} className="p-0" />
                <div>
                  <RecentlyView diamondList={this.state.accurateStock.diamonds} />
                </div>
              </div>
            )}
            {!isPair && this.state.suggestedStock && this.state.suggestedStock.diamonds?.length !== 0 && (
              <div className="recentlyViewProduct">
                <Heading title={<IntlMessage id="app.SuggestedStock" />} className="p-0" />
                <div>
                  <RecentlyView diamondList={this.state.suggestedStock.diamonds} />
                </div>
              </div>
            )}
          </div>
          {!isPair && this.getEmailModal(data)}
          {!isPair && this.state.showGuest && (
            <GuestUserPopup
              visible={this.state.showGuest}
              register={() => {
                window.open("/signup", "_self");
                window.localStorage.clear();
                window.sessionStorage.clear();
              }}
              handleCancel={() => {
                this.setState({ showGuest: false });
              }}
            />
          )}
          {!isPair && this.state.noKyc && (
            <NoKycPop
              status={authUser.isApproved}
              visible={this.state.noKyc}
              handleCancel={() => {
                this.setState({ noKyc: false });
              }}
            />
          )}
        </div>
      </>
    );
  }
}
export default connect((props) => {
  return props;
}, {})(withRouter(DiamondDetail));
