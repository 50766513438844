import React from "react";
import Slider from "react-slick";

import { memoize } from "lodash";

import { FILE_URLS } from "constants/Common";

import vidSVG from "assets/svg/DiamondDetail/video.svg";
import LeftSliderSlide from "./LeftSliderSlide";
import MagnifierImage from "./MagnifierImage";

const setting = [
  { breakpoint: 1400, settings: { slidesToShow: 5 } },
  { breakpoint: 991, settings: { slidesToShow: 5 } },
  { breakpoint: 767, settings: { slidesToShow: 3, infinite: false, vertical: false } },
];

function DiamondDetailLeft(props) {
  const { sendUrl: _sendUrl } = props;
  const [iconSliderRef, setIconSliderRef] = React.useState();
  const [mainSliderRef, setMainSliderRef] = React.useState();
  const [slides, setSlides] = React.useState(new Array(7).fill(undefined).map((_, i) => i));

  const sendUrlRef = React.useRef(_sendUrl);
  React.useEffect(() => {
    sendUrlRef.current = _sendUrl;
  }, [_sendUrl]);

  const returnId = React.useCallback((id, name) => sendUrlRef.current(id, name), []);

  const hideSlide = React.useMemo(() => {
    return memoize((id) => {
      return () => {
        setSlides((list) => {
          const index = list.indexOf(id);
          if (index > -1) {
            list.splice(index, 1);
            return [...list];
          }
          return list;
        });
      };
    });
  }, []);

  React.useEffect(() => {
    if (props.pktNo && returnId) {
      returnId(FILE_URLS.mp4video.replace("***", props.pktNo), "360-video");
    }
  }, [returnId, props.pktNo]);

  return (
    <>
      <Slider
        className="smallSliderBlock"
        asNavFor={iconSliderRef}
        ref={setMainSliderRef}
        slidesToShow={slides.length < 5 ? slides.length : 5}
        responsive={setting}
        infinite={false}
        loop={false}
        vertical
        swipeToSlide
        focusOnSelect
      >
        {slides.includes(0) && (
          <div>
            <div
              className="smallSliderImage htmlVideo"
              onClick={() => returnId(FILE_URLS.mp4video.replace("***", props.pktNo), "360-video")}
            >
              <img src={vidSVG} alt="" />
            </div>
          </div>
        )}
        {slides.includes(1) && (
          <div
            className="smallSliderImage htmlVideo"
            onClick={() => returnId(FILE_URLS.img.replace("***", props.pktNo), "still-image")}
          >
            <img onError={hideSlide(1)} src={FILE_URLS.img.replace("***", props.pktNo)} alt="" />
          </div>
        )}
        {slides.includes(2) && (
          <div
            className="smallSliderImage htmlVideo"
            onClick={() => returnId(FILE_URLS.front.replace("***", props.pktNo), "front-image")}
          >
            <img onError={hideSlide(2)} src={FILE_URLS.front.replace("***", props.pktNo)} alt="" />
          </div>
        )}
        {slides.includes(3) && (
          <div
            className="smallSliderImage htmlVideo"
            onClick={() => returnId(FILE_URLS.side.replace("***", props.pktNo), "side-image")}
          >
            <img onError={hideSlide(3)} src={FILE_URLS.side.replace("***", props.pktNo)} alt="" />
          </div>
        )}
        {slides.includes(4) && (
          <div
            className="smallSliderImage htmlVideo"
            onClick={() => returnId(FILE_URLS.certFile.replace("***", props.rptNo), "certificate")}
          >
            <img onError={hideSlide(4)} src={FILE_URLS.certFile.replace("***", props.rptNo)} alt="" />
          </div>
        )}
        {slides.includes(5) && (
          <div
            className="smallSliderImage htmlVideo"
            onClick={() => returnId(FILE_URLS.propCerti.replace("***", props.rptNo), "proportions-image")}
          >
            <img onError={hideSlide(5)} src={FILE_URLS.propCerti.replace("***", props.rptNo)} alt="" />
          </div>
        )}
        {slides.includes(6) && (
          <div
            className="smallSliderImage htmlVideo"
            onClick={() => returnId(FILE_URLS.plotCerti.replace("***", props.rptNo), "plotting-image")}
          >
            <img onError={hideSlide(6)} src={FILE_URLS.plotCerti.replace("***", props.rptNo)} alt="" />
          </div>
        )}
      </Slider>
      <Slider className="leftBigImage" infinite={false} arrows={false} ref={setIconSliderRef} asNavFor={mainSliderRef}>
        {slides.includes(0) && (
          <LeftSliderSlide className="diamondDetailImageBox htmlViewImage">
            <iframe title="htmlViewImage" key="htmlViewImage" src={FILE_URLS.videoFile.replace("***", props.pktNo)} />
          </LeftSliderSlide>
        )}
        {slides.includes(1) && (
          <LeftSliderSlide>
            <MagnifierImage image={FILE_URLS.img.replace("***", props.pktNo)} />
            <div className="diamondGridLabel">{/* <label>DD:1</label> */}</div>
          </LeftSliderSlide>
        )}
        {slides.includes(2) && (
          <LeftSliderSlide>
            <MagnifierImage image={FILE_URLS.front.replace("***", props.pktNo)} />
          </LeftSliderSlide>
        )}
        {slides.includes(3) && (
          <LeftSliderSlide>
            <MagnifierImage image={FILE_URLS.side.replace("***", props.pktNo)} />
          </LeftSliderSlide>
        )}
        {slides.includes(4) && (
          <LeftSliderSlide>
            <MagnifierImage image={FILE_URLS.certFile.replace("***", props.rptNo)} />
          </LeftSliderSlide>
        )}
        {slides.includes(5) && (
          <LeftSliderSlide>
            <MagnifierImage image={FILE_URLS.propCerti.replace("***", props.rptNo)} />
          </LeftSliderSlide>
        )}
        {slides.includes(6) && (
          <LeftSliderSlide>
            <MagnifierImage image={FILE_URLS.plotCerti.replace("***", props.rptNo)} />
          </LeftSliderSlide>
        )}
      </Slider>
    </>
  );
}

export default React.memo(DiamondDetailLeft);
