import React, { memo, useEffect } from "react";

import find from "lodash/find";

import { isNumeric } from "util/utils";

import { HeadingCalc } from "./ConfirmStonePopup";

import useRowSelect from "../../../util/useRowSelect";
import MobileViewListCard from "../../DiamondList/MobileViewListCard";
import Heading from "../Heading";

export const ListingCards = memo(
  ({
    handleDiscountInput,
    data,
    currentType,
    finalCalc = false,
    bidPopup = false,
    quote = false,
    quoteClick,
    checked = null,
    bid = false,
    note = false,
    watchpopup = false,
    quotePage = false,
    nextBidData,
  }) => {
    const { isRowChecked } = useRowSelect(currentType);
    return (
      <>
        {data.map((x, i) => {
          const isChecked = !checked ? isRowChecked(x.id) : !!find(checked, { id: x.id });
          const diffInterval = isNumeric(nextBidData?.sub_types?.diffInterval, true)
            ? Number(nextBidData?.sub_types?.diffInterval)
            : 0.5;
          return (
            <MobileViewListCard
              diffInterval={diffInterval}
              handleDiscountInput={handleDiscountInput}
              quote={quote}
              quotePage={quotePage}
              note={note}
              watchpopup={watchpopup}
              bidPopup={bidPopup}
              bid={bid}
              finalCalc={finalCalc}
              quoteClick={quoteClick}
              data={x}
              onClick={() => {}}
              activeClass={isChecked ? "active" : ""}
              key={i}
            />
          );
        })}
      </>
    );
  }
);

const BIDStoneMobile = (props) => {
  const { toggleRowSelection, isRowChecked } = useRowSelect(props.currentType);

  const dep = props && props.data;

  useEffect(() => {
    dep.map((row) => {
      toggleRowSelection(row, true);
      return true;
    });
  }, [dep]);
  return (
    <>
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title="Bid Stone" />
          </div>
          {HeadingCalc(
            props.data.filter((x) => isRowChecked(x.id)),
            props.VALUES
          )}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards
              bidPopup={props.bidPopup}
              nextBidData={props.nextBidData}
              handleDiscountInput={props.handleDiscountInput}
              data={props.data}
              currentType={props.currentType}
            />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap" />
        </div>
        <div className="sideBarPopupButton">
          <span
            className="commonButton"
            onClick={() => {
              props.submit();
            }}
          >
            Bid Stone
          </span>
          <span
            className="commonButton"
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel Bid
          </span>
        </div>
      </div>
    </>
  );
};

export default memo(BIDStoneMobile);
