import React from "react";
import "./countBox.less";

const CountBox = (props) => {
  return (
    <div
      className={`${props.className ? props.className : `${props.count}`.length === 1 ? "countBoxSingle" : "countBox"}`}
    >
      {props.count}
    </div>
  );
};
export default CountBox;
