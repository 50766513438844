import isEmpty from "lodash/isEmpty";
import validator from "validator";

import Messages from "../../constants/ValidationMessage";

export function signupValidator(data) {
  const errors = {};
  if (data.firstName !== undefined && validator.isEmpty(data.firstName)) {
    errors.firstName = Messages.FirstName;
  }

  if (data.firstName !== undefined && !validator.isEmpty(data.firstName)) {
    if (data.firstName !== undefined && !validator.matches(data.firstName, /^[a-zA-Z ]*$/)) {
      errors.firstName = Messages.NameValid;
    }
  }
  if (data.lastName !== undefined && validator.isEmpty(data.lastName)) {
    errors.lastName = Messages.LastName;
  }

  if (data.lastName !== undefined && !validator.isEmpty(data.lastName)) {
    if (data.lastName !== undefined && !validator.matches(data.lastName, /^[a-zA-Z ]*$/)) {
      errors.lastName = Messages.NameValid;
    }
  }
  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }

  if (data.mobile !== undefined && validator.isEmpty(data.mobile)) {
    errors.mobile = Messages.PhoneRequire;
  }
  if (data.mobile !== undefined && !validator.isEmpty(data.mobile)) {
    if (data.mobile !== undefined && !validator.isNumeric(data.mobile)) {
      errors.mobile = Messages.PhoneValid;
    }
  }

  if (data.pinCode !== undefined && validator.isEmpty(data.pinCode)) {
    errors.pinCode = Messages.pinCode;
  }
  if (data.zipCode !== undefined && validator.isEmpty(data.zipCode)) {
    errors.zipCode = Messages.pinCode;
  }
  if (data.username !== undefined && validator.isEmpty(data.username)) {
    errors.username = Messages.UserName;
  }

  if (data.address !== undefined && validator.isEmpty(data.address)) {
    errors.address = Messages.Address;
  }
  if (data.city === null) {
    errors.city = Messages.City;
  }
  if (data.state === null) {
    errors.state = Messages.State;
  }
  if (data.country === null) {
    errors.country = Messages.Country;
  }

  if (data.city !== undefined && data.city !== null && validator.isEmpty(data.city)) {
    errors.city = Messages.City;
  }

  if (data.state !== undefined && data.state !== null && validator.isEmpty(data.state)) {
    errors.state = Messages.State;
  }

  if (data.country !== undefined && data.country !== null && validator.isEmpty(data.country)) {
    errors.country = Messages.Country;
  }

  if (!data.noCheck) {
    if (data.password !== undefined && validator.isEmpty(data.password)) {
      errors.password = Messages.Password;
    }
  }

  // let regx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,19}$/;

  // if (data.password !== undefined && !validator.isEmpty(data.password)) {
  //     if (
  //         data.password !== undefined &&
  //         validator.matches(data.password, regx) === false
  //     ) {
  //         errors.password = Messages.PasswordValid;
  //     }
  //     if (
  //         data.password !== undefined &&
  //         validator.isLength(data.password, { min: 8 }) === false
  //     ) {
  //         errors.password = Messages.PasswordLength;
  //     }
  // }

  if (data.confirmPassword !== undefined && validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = Messages.ConfirmPassword;
  }
  if (
    data.password !== undefined &&
    data.confirmPassword !== undefined &&
    !validator.isEmpty(data.password) &&
    !validator.isEmpty(data.confirmPassword)
  ) {
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = Messages.SamePasword;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}

export function forgotPassword(data) {
  const errors = {};

  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}
export function shareEmail(data) {
  const errors = {};
  if (data.name !== undefined && validator.isEmpty(data.name)) {
    errors.name = Messages.Name;
  }
  if (data.subject !== undefined && validator.isEmpty(data.subject)) {
    errors.subject = Messages.Subject;
  }
  if (data.message !== undefined && validator.isEmpty(data.message)) {
    errors.message = Messages.Message;
  }

  if (data.name !== undefined && !validator.isEmpty(data.name)) {
    if (data.name !== undefined && !validator.matches(data.name, /^[a-zA-Z ]*$/)) {
      errors.name = Messages.NameValid;
    }
  }
  if (data.to !== undefined && validator.isEmpty(data.to)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.to !== undefined && !validator.isEmpty(data.to)) {
    if (data.to !== undefined && !validator.isEmail(data.to)) {
      errors.email = Messages.EmailValid;
    }
  }
  if (data.cc !== undefined && !validator.isEmpty(data.cc)) {
    if (data.cc !== undefined && !validator.isEmail(data.cc)) {
      errors.cc = Messages.EmailValid;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}

export function profileValidator(data) {
  const errors = {};
  if (data.firstName !== undefined && validator.isEmpty(data.firstName)) {
    errors.firstName = Messages.FirstName;
  }

  if (data.firstName !== undefined && !validator.isEmpty(data.firstName)) {
    if (data.firstName !== undefined && !validator.matches(data.firstName, /^[a-zA-Z ]*$/)) {
      errors.firstName = Messages.NameValid;
    }
  }

  if (data.lastName !== undefined && !validator.isEmpty(data.lastName)) {
    if (data.lastName !== undefined && !validator.matches(data.lastName, /^[a-zA-Z ]*$/)) {
      errors.lastName = Messages.NameValid;
    }
  }
  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }

  if (data.mobile !== undefined && validator.isEmpty(data.mobile)) {
    errors.mobile = Messages.PhoneRequire;
  }
  if (data.mobile !== undefined && !validator.isEmpty(data.mobile)) {
    if (data.mobile !== undefined && !validator.isNumeric(data.mobile)) {
      errors.mobile = Messages.PhoneValid;
    }
  }
  if (data.whatsapp !== undefined && !validator.isEmpty(data.whatsapp)) {
    if (data.whatsapp !== undefined && !validator.isNumeric(data.whatsapp)) {
      errors.whatsapp = Messages.PhoneValid;
    }
  }

  if (data.pinCode !== undefined && validator.isEmpty(data.pinCode)) {
    errors.pinCode = Messages.pinCode;
  }

  if (data.city === null) {
    errors.city = Messages.City;
  }
  if (data.state === null) {
    errors.state = Messages.State;
  }
  if (data.country === null) {
    errors.country = Messages.Country;
  }

  if (data.city !== undefined && data.city !== null && validator.isEmpty(data.city)) {
    errors.city = Messages.City;
  }

  if (data.state !== undefined && data.state !== null && validator.isEmpty(data.state)) {
    errors.state = Messages.State;
  }

  if (data.country !== undefined && data.country !== null && validator.isEmpty(data.country)) {
    errors.country = Messages.Country;
  }

  return { errors, isValid: isEmpty(errors) };
}

export function resetPasswordValidator(data) {
  const errors = {};
  if (data.newPassword !== undefined && validator.isEmpty(data.newPassword)) {
    errors.newPassword = Messages.Password;
  }
  const regx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,19}$/;

  if (data.newPassword !== undefined && !validator.isEmpty(data.newPassword)) {
    if (data.newPassword !== undefined && validator.matches(data.newPassword, regx) === false) {
      errors.newPassword = Messages.PasswordValid;
    }
    if (data.newPassword !== undefined && validator.isLength(data.newPassword, { min: 8 }) === false) {
      errors.newPassword = Messages.PasswordLength;
    }
  }
  if (data.confirmPassword !== undefined && validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = Messages.ConfirmPassword;
  }
  if (
    data.newPassword !== undefined &&
    data.confirmPassword !== undefined &&
    !validator.isEmpty(data.newPassword) &&
    !validator.isEmpty(data.confirmPassword)
  ) {
    if (data.newPassword !== data.confirmPassword) {
      errors.confirmPassword = Messages.SamePasword;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}

export function signUpCompany(data) {
  const errors = {};
  if (data.firstName !== undefined && validator.isEmpty(data.firstName)) {
    errors.firstName = Messages.FirstName;
  }

  if (data.firstName !== undefined && !validator.isEmpty(data.firstName)) {
    if (data.firstName !== undefined && !validator.matches(data.firstName, /^[a-zA-Z ]*$/)) {
      errors.firstName = Messages.NameValid;
    }
  }
  if (data.lastName !== undefined && validator.isEmpty(data.lastName)) {
    errors.lastName = Messages.LastName;
  }

  if (data.lastName !== undefined && !validator.isEmpty(data.lastName)) {
    if (data.lastName !== undefined && !validator.matches(data.lastName, /^[a-zA-Z ]*$/)) {
      errors.lastName = Messages.NameValid;
    }
  }
  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }

  if (data.mobile !== undefined && validator.isEmpty(data.mobile)) {
    errors.mobile = Messages.PhoneRequire;
  }
  if (data.mobile !== undefined && !validator.isEmpty(data.mobile)) {
    if (data.mobile !== undefined && !validator.isNumeric(data.mobile)) {
      errors.mobile = Messages.PhoneValid;
    }
  }

  if (data.pinCode !== undefined && validator.isEmpty(data.pinCode)) {
    errors.pinCode = Messages.pinCode;
  }

  if (
    data.pinCode !== undefined &&
    !validator.isEmpty(data.pinCode) &&
    validator.isLength(data.pinCode, { max: 10 }) === false
  ) {
    errors.pinCode = Messages.Ziplength;
  }

  if (data.zipCode !== undefined && validator.isEmpty(data.zipCode)) {
    errors.zipCode = Messages.pinCode;
  }

  if (
    data.zipCode !== undefined &&
    !validator.isEmpty(data.zipCode) &&
    validator.isLength(data.zipCode, { max: 10 }) === false
  ) {
    errors.zipCode = Messages.Ziplength;
  }
  if (data.username !== undefined && validator.isEmpty(data.username)) {
    errors.username = Messages.UserName;
  }

  if (data.address !== undefined && validator.isEmpty(data.address)) {
    errors.address = Messages.Address;
  }
  if (data.city === null) {
    errors.city = Messages.City;
  }
  if (data.state === null) {
    errors.state = Messages.State;
  }
  if (data.country === null) {
    errors.country = Messages.Country;
  }

  if (data.city !== undefined && data.city !== null && validator.isEmpty(data.city)) {
    errors.city = Messages.City;
  }

  if (data.state !== undefined && data.state !== null && validator.isEmpty(data.state)) {
    errors.state = Messages.State;
  }

  if (data.country !== undefined && data.country !== null && validator.isEmpty(data.country)) {
    errors.country = Messages.Country;
  }

  if (!data.noCheck) {
    if (data.password !== undefined && validator.isEmpty(data.password)) {
      errors.password = Messages.Password;
    }
  }
  // let regx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,19}$/;

  // if (data.password !== undefined && !validator.isEmpty(data.password)) {
  //     if (
  //         data.password !== undefined &&
  //         validator.matches(data.password, regx) === false
  //     ) {
  //         errors.password = Messages.PasswordValid;
  //     }
  //     if (
  //         data.password !== undefined &&
  //         validator.isLength(data.password, { min: 8 }) === false
  //     ) {
  //         errors.password = Messages.PasswordLength;
  //     }
  // }

  if (data.confirmPassword !== undefined && validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = Messages.ConfirmPassword;
  }

  if (
    data.password !== undefined &&
    data.confirmPassword !== undefined &&
    !validator.isEmpty(data.password) &&
    !validator.isEmpty(data.confirmPassword)
  ) {
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = Messages.SamePasword;
    }
  }

  if (data.companyName !== undefined && validator.isEmpty(data.companyName)) {
    errors.companyName = Messages.CompanyName;
  }
  if (data.businessType !== undefined && validator.isEmpty(data.businessType)) {
    errors.businessType = Messages.BusinessType;
  }
  if (data.companyType !== undefined && validator.isEmpty(data.companyType)) {
    errors.companyType = Messages.CompanyType;
  }
  const regxWeb =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

  if (data.Website !== undefined && !validator.isEmpty(data.Website)) {
    if (data.Website !== undefined && !validator.matches(data.Website, regxWeb)) {
      errors.Website = Messages.Website;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}
