import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";

import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { GrFacebookOption } from "react-icons/gr";

const Footer = () => {
  return (
    <footer className="kgk_landing_footer">
      <Container>
        <Row>
          <Col>
            <div className="kgk_landing_footer_content_main">
              <div className="kgk_landing_footer_social">
                <ul>
                  <li>
                    <span> Let’s Connect </span>
                  </li>
                  {/* <li>
                    <NavLink to="/" onClick={() => window.open('https://www.youtube.com/c/KGKGroupOfficial', '_blank')}>
                      <AiFillYoutube />
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      to="/"
                      onClick={() => window.open("https://www.facebook.com/people/KGK-Live/100085100452895/", "_blank")}
                    >
                      <GrFacebookOption />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/" onClick={() => window.open("https://www.instagram.com/kgk_live/", "_blank")}>
                      <AiOutlineInstagram />
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/"
                      onClick={() =>
                        window.open(
                          'https://twitter.com/kgk_group?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
                          '_blank',
                        )
                      }
                    >
                      <AiOutlineTwitter />
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      to="/"
                      onClick={() =>
                        window.open(
                          "https://api.whatsapp.com/send?phone=+32486047074&text=Hi KGK Live! I have a few queries. Could you please help me out?"
                        )
                      }
                    >
                      <BsWhatsapp />
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="kgk_landing_footer_text">
                <p>
                  Copyright © 2021 KGK Group | <NavLink to="/privacy-policy">Legal and Privacy </NavLink>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default withRouter(Footer);
