import React from "react";
import { compose } from "redux";

import MobileAccount from "components/Account/MobileAccount";
import AccountRightDetail from "components/Account/MyAccount/Summary/AccountRightDetail";
// import Banner from 'components/Account/MyAccount/Summary/Banner';
import KAMDetail from "components/Dashboard/KAMDetail";
// import News from 'components/Dashboard/News';
// import RecentActivity from 'components/Dashboard/RecentActivity';
import RecentSearch from "components/Dashboard/RecentSearch";
import HomeSavedSearch from "components/Dashboard/SavedSearch";

import { Storage } from "services/storage";
import { callingApi } from "services/util";

import IntlMessage from "util/IntlMessage";
import { withMobile } from "util/hocs";

import { commonLogout, trackTypeObj } from "constants/Common";
// import { GET_DASHBOARD_DATA, GET_BANNERS } from 'constants/apiConstant';
import { GET_DASHBOARD_DATA } from "constants/apiConstant";

const Summary = (props) => {
  const [details, setDetails] = React.useState({});
  const [counts, setCounts] = React.useState({});
  const [saved, setSaved] = React.useState([]);
  const [recent, setRecent] = React.useState([]);
  // const [news, setNews] = React.useState([]);
  const [seller, setSeller] = React.useState({});
  // const [recentActivity, setRecentActivity] = React.useState([]);
  // const [acc, setAcc] = React.useState([]);
  // const [load, setLoad] = React.useState(true);

  React.useEffect(() => {
    const getCount = async () => {
      const requestConfig = {
        ...GET_DASHBOARD_DATA,
        request: {
          account: true,
          dashboardCount: true,
          news: true,
          orderData: true,
          recentActivity: true,
          recentSearch: true,
          savedSearch: true,
          seller: true,
          shippingCount: true,
          track: true,
        },
      };
      const [err, res] = await callingApi(requestConfig);

      if (!err) {
        const { tracks, ...count } = { ...res?.data };
        if (count) setCounts({ ...count });
        // if (res.data.news) setNews([...res.data.news]);
        if (res.data.tracks) setDetails({ ...res.data.tracks });
        if (res?.data?.seller) setSeller({ ...res.data.seller });
        // if (res.data.recentActivity) setRecentActivity([...res.data.recentActivity]);
        if (res.data?.savedSearch?.list?.length) setSaved([...res.data.savedSearch.list]);
        if (res.data?.recentSearch?.list?.length) setRecent([...res.data.recentSearch.list]);
      }
    };
    // const getBanner = async () => {
    //   const requestConfig = {
    //     ...GET_BANNERS,
    //     request: { filter: { type: ['8'], isActive: true } },
    //   };
    //   const [err, res] = await callingApi(requestConfig);
    //   if (!err && res?.data?.list && res.data.list.length !== 0) {
    //     setAcc([{ ...res.data.list?.find?.((record) => record?.type === '8') }]);
    //   }
    //   setLoad(false);
    // };

    getCount();
    // getBanner();
  }, []);

  const { isMobile } = props;

  const USER_PERMIT = Storage.get("userPermissions");

  const HeaderLinks = [
    {
      link: "/account/cart",
      title: <IntlMessage id="app.cart" />,
      details: details[trackTypeObj.CART],
      isShow: USER_PERMIT?.CART?.view,
      noCount: true,
    },
    {
      link: "/account/watchlist",
      title: <IntlMessage id="app.watchlist" />,
      details: details[trackTypeObj.WATCHLIST],
      isShow: USER_PERMIT?.WATCHLIST?.view,
      noCount: true,
    },
    {
      link: "/account/view-diamonds",
      title: <IntlMessage id="app.office-list" />,
      details: details[trackTypeObj.REQUEST],
      isShow: USER_PERMIT?.OFFICE?.view,
      noCount: true,
    },
    {
      link: "/account/order-list",
      title: <IntlMessage id="app.orderHistory" />,
      details: counts.orderData && counts.orderData.length !== 0 ? counts.orderData : [],
      isShow: USER_PERMIT?.CONFIRM_STONE?.view,
      noCount: true,
      orderYes: true,
    },
    {
      link: "/account/offer-list",
      title: <IntlMessage id="app.Offer" />,
      details: details[trackTypeObj.QUOTE],
      isShow: USER_PERMIT?.QUOTE?.view,
      noCount: true,
    },
    {
      link: "/account/notes",
      title: <IntlMessage id="app.Comment" />,
      details: details[trackTypeObj.COMMENT],
      isShow: USER_PERMIT?.NOTES?.view,
      noCount: true,
    },
    // {
    //   link: '/account/saved-search',
    //   title: <IntlMessage id="app.saved-search" />,
    //   basicDetailnull: true,
    //   noCount: false,
    //   isShow: USER_PERMIT?.SAVE_SEARCH?.view,
    // },
    {
      link: "/my-bid",
      title: <IntlMessage id="app.myBid" />,
      basicDetailnull: true,
      noCount: false,
      isShow: USER_PERMIT?.BID?.view,
    },
    {
      link: "/account/my-demand",
      title: <IntlMessage id="app.Demand" />,
      basicDetailnull: true,
      noCount: false,
      isShow: USER_PERMIT?.DEMAND?.view,
    },
  ];

  return (
    <>
      {isMobile && <MobileAccount />}
      <div>
        {/* <div className="mb-20 accountSummaryBlock">
          {!load && (
            <div className="accountMainTopLeft">
              <Banner acc={acc} />
            </div>
          )}

          <div className="accountMainTopLeft">
            <AccountRightDetail HeaderLinks={HeaderLinks} />
          </div>
        </div> */}
        <AccountRightDetail HeaderLinks={HeaderLinks} />

        <div className="dashboardCardBoxMain">
          {USER_PERMIT?.SAVE_SEARCH?.view && (
            <div className="homeRightInner mb-10">
              <HomeSavedSearch save={saved} />
            </div>
          )}
          <div className="homeRightInner mb-10">
            <RecentSearch recent={recent} />
          </div>
          {/* <div className="homeRightInner mb-10">
            <RecentActivity recent={recentActivity} />
          </div> */}
          <div className="homeRightInner mb-10">
            <KAMDetail kam sale={{ salesPerson: { ...seller } }} />
          </div>
          {/* <div className=" homeRightInner dashboardInnerLeft mb-10">
            <News newsList={news} />
          </div> */}
        </div>
        <div className="logoutMobileOption" onClick={() => commonLogout()}>
          <IntlMessage id="app.Logout" />
        </div>
      </div>
    </>
  );
};
export default compose(withMobile)(Summary);
