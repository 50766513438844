import React from "react";

import { formatCurrency, formatDate, formatDecimal, formatTime } from "util/utils";

import { ORDER_LIST_STATUS } from "constants/Common";

import DiamondTotalCard from "./DiamondTotalCard";

export const OFFERSTATUS = {
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
};

const TableGrouping = (props) => {
  const pairSummary = props.pairSummary?.length ? props.pairSummary[0] : [];
  return (
    <>
      {props.tableHide ? null : (
        <div className="tableGropupingBox">
          <div className="tableGroupTitle">
            <span className="tableGroupTitleInner">
              {props.Collapse && (
                <div className="tableCollapse" onClick={props.onClick}>
                  <img src={props.img} alt="" />
                </div>
              )}
              {props.groupingTitle && <span>{props.groupingTitle}</span>}
              {props.coupleStones && (
                <>
                  <span>
                    {[
                      `Pair Group : ${props.row.pairGroup ?? "-"}`,
                      `Pcs. :  ${pairSummary.totalPcs ?? 0}`,
                      `Total Cts.:  ${formatDecimal(pairSummary.totalCrt ?? 0)}`,
                      `Total Amount: ${formatCurrency(pairSummary.totalAmt ?? 0)}`,
                    ].join(" | ")}
                  </span>
                </>
              )}
              {props.multipleCheckBox && <div className="checkBoxSelect">{props.children}</div>}

              {props.row?.groupingTitle && <span>{props.row.groupingTitle}</span>}

              {props.row?.groupingTitle && props.row?.memoNo && <span>&nbsp;|&nbsp;</span>}
              {props.row?.memoNo && !props.row?.isOfferHeader && (
                <>
                  <span>
                    {[
                      formatDate(props.row.createdAt),
                      props.row.memoNo ?? "-",
                      ORDER_LIST_STATUS[props.row.status] ?? "-",
                      `Pcs. :  ${props.row?.totalDiamonds ?? "-"}`,
                      `Total Cts.:  ${formatDecimal(props.row?.totalCrt ?? 0)}`,
                      `Total Amount: ${formatCurrency(props.row?.totalAmount ?? 0)}`,
                    ].join(" | ")}
                  </span>
                </>
              )}
              {props.row?.isOfferHeader && props.row?.updatedAt && <span>{props.row?.groupTitle}</span>}
              {((props.row?.memoNo && !props.row?.isOfferHeader) ||
                (props.row?.isOfferHeader && props.row?.updatedAt)) &&
                props.row?.isUpcomingHeader && <span>&nbsp;|&nbsp;</span>}
              {props.row?.isUpcomingHeader && (
              <span>
                {formatDate(props.row.inDt)}
                {' '}
                3 PM IST
              </span>
)}

              {props.row?.isUpcomingHeader && props.row?.isOfficeHeader && <span>&nbsp;|&nbsp;</span>}
              {props.row?.isOfficeHeader && (
                <span>
                  {formatDate(props.row.date)}
                  {' '}
                  - [
                  {formatTime(props.row.cabinSlot[0].start)}
                  {' '}
                  -
                  {" "}
                  {formatTime(props.row.cabinSlot[0].end)}
                  ]
                </span>
              )}
            </span>
            {/* <div className="tableHeaderMain">{props.row ? props.row.totalDiamonds : 0} Diamond</div> */}
          </div>
        </div>
      )}
      <tr className="tableGroupingTr">
        {!props.columns && (
          <td className="groupingTableAmount" style={{ color: "transparent" }}>
            0
          </td>
        )}
        {props.columns
          ? props.columns.map((x) => {
              return (
                <td key={x.id} className="groupingTableAmount">
                  {props.displayTotal.includes(x.id) && <DiamondTotalCard row={props.row} col={x.id} />}
                </td>
              );
            })
          : null}
        {props.offerDis && (
          <>
            <td colSpan={props.totalAmountPlace} />
            <td className="groupingTableAmount">
              <div className="tableInput tableTopOfferInput">
                <input />
              </div>
            </td>
          </>
        )}
        {props.offerDis && (
          <td className="groupingTableAmount">
            <div className="tableInput tableTopOfferInput">
              <input />
            </div>
          </td>
        )}
      </tr>
    </>
  );
};
export default TableGrouping;
