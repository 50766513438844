import React, { Component } from "react";

import "./headerstrip.less";
import { Dropdown } from "antd";

import { isNotEmpty } from "util/utils";

import LangDropdown from "./LangDropdown";

import calSVG from "../../../../assets/svg/PriceCalc/calculator.svg";
import PriceCalculator from "../../../PriceCalculator";

class HeaderStrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      salesOption: false,
      rates: {},
      visible: false,
    };
  }

  // componentDidMount() {
  //   this.timerID = setInterval(() => this.tick(), 1000);
  // }

  // componentWillUnmount() {
  //   clearInterval(this.timerID);
  // }

  salesDropdown = () => {
    this.setState({ salesOption: !this.state.salesOption });
  };

  tick() {
    if (document.hasFocus()) this.setState({ date: new Date() });
  }

  render() {
    return (
      <div>
        <div className={`HeaderStripBlock ${this.props.className}`}>
          <div className="HeaderStripLeft">
            {/* <marquee style={{ letterSpacing: '1px', color: '#f2f2f2' }}>
              <span>
                <strong>kgk.live maintenance scheduled on 14th April 2023 (10:00 AM to 05:00 PM IST)</strong>
              </span>
            </marquee> */}
            {/* <HeaderStirpItem
              title={<IntlMessage id="app.7Days" />}
              value="73.75"
            />
            <HeaderStirpItem
              title={<IntlMessage id="app.AdvancePayment" />}
              value="73.75"
            />
            <HeaderStirpItem title="COD" value="73.75" />
            <HeaderStirpItem
              title={<IntlMessage id="app.Time" />}
              value={this.state.date.toLocaleTimeString()}
            /> */}
            {/* <marquee> */}
            {/* <span>Evaluate COVID‑19 symptoms and understand next steps</span> */}
            {isNotEmpty(this.props.main) &&
              this.props?.main[0]?.fromdateTime < this.props?.time &&
              this.props?.time < this.props?.main[0]?.toDateTime && (
                <span>
                  <>
                    {/* {this.props?.main[0]?.eventName}
                  {'  '} */}
                    {this.props?.main[0]?.description}
                  </>
                  {/* {this.props.event}
              {'  '}
              {this.props.desc} */}
                </span>
              )}
            {/* </marquee> */}
          </div>
          <div className="HeaderStripRight d-flex align-items-center">
            <Dropdown
              overlayClassName="priceCalcDropWrapper"
              overlay={
                <PriceCalculator
                  onClick={() => {
                    this.setState({ visible: false });
                  }}
                />
              }
              trigger={["click"]}
            >
              <span className="headerStipIcon" onClick={() => this.setState({ visible: true })}>
                <img src={calSVG} alt="img" />
              </span>
            </Dropdown>
            <div className="SelectLanguage">
              <LangDropdown />
            </div>
            {/* <Dropdown overlay={ <div className="salesPersonDropdown">
                  <SalesPerson/>
                </div>} trigger={['click']}>
                <div className="SalesPersonWrapper" onClick={e => e.preventDefault()}>
                <>
                  <div className="salesPersonProfile">
                    <img src={require("../../../../assets/img/header/user.jpg")} alt="profile"/>
                  </div>
                  <div className="salesPersonDetail" onClick={this.salesDropdown}>
                      <span>Vishal Virani</span>
                      {this.state.salesOption ?
                        <>
                          <img className="buttonArrow" src={require("../../../../assets/svg/Dashboard/up-arrow.svg")} alt=""/>
                        </>
                        :
                        <>
                          <img className="buttonArrow" src={require("../../../../assets/svg/Dashboard/down-arrow-white.svg")} alt=""/>
                        </>
                        }
                  </div>
                  </>
                </div>
            </Dropdown> */}
          </div>
        </div>
        {/* <div className="downArrow" onClick={this.props.onClick}>
          <img
            className="closeImage"
            src={require("../../../../assets/svg/Header/down-arrow.svg")}
            alt=""
          />
        </div> */}
      </div>
    );
  }
}
export default HeaderStrip;
