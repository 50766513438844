import React from "react";

import urlJoin from "url-join";

import IntlMessage from "util/IntlMessage";

import "./uploadImage.less";
import ptSVG from "../../../assets/svg/plus-thin.svg";
import { BASE_URL_ATTACH } from "../../../constants/Common";
import pdfSVG from "../FileUploaded/pdf.svg";

const UploadImage = (props) => {
  return (
    <div className={`uploadeImageBlock ${props.leftAlign && "leftAlign"}`}>
      <input disabled={props.disabled} type="file" onChange={props.onUpload} />
      <div className="uploadeBlock">
        {props.file ? (
          props.file
            .split("/")
            .slice(-1)[0]
            .match(/.(pdf)$/i) ? (
              <img src={pdfSVG} alt="pdf" />
          ) : (
            <img src={urlJoin(BASE_URL_ATTACH, props.file)} alt="" />
          )
        ) : (
          <img src={ptSVG} alt="" />
        )}
      </div>
      <span className="title">
        {props.title ? (
          props.title
        ) : (
          <>
            <IntlMessage id="app.UploadIDProof" />
          </>
        )}
      </span>
    </div>
  );
};

export default UploadImage;
