import React, { useReducer, useRef, useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Radio, Dropdown, DatePicker } from "antd";
import { cloneDeep, findIndex, isObject } from "lodash";
import moment from "moment";

import GridHeightContainer from "containers/GridHeightContainer/GridHeightContainer";

import { BidService } from "services/BidService";
import { getCurrentTime } from "services/util";

import IntlMessage from "util/IntlMessage";
import { isEmpty } from "util/utils";

import BidListMobile from "./BidListMobile";
import { fetchBid, getColumn, TITLE, getPath, isMobile } from "./DiamondListFunctions";
import Table from "./TableBack";

import calSVG from "../../assets/svg/calendar.svg";
import { DIAMOND_BID, LOCAL_STORAGE_VAR } from "../../constants/Common";
import { getBidType } from "../NewArrivalBidIt";
import Countdown from "../Offer/Timer";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";
import SelectStone, { formatNumber } from "../common/DiamondListing/SelectStone";
import Heading from "../common/Heading";

import { getState, isArrayEqual } from ".";

const BIDTAB = {
  LIVE: "1",
  HISTORY: "2",
};
const BidList = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const currentType = useRef(location.pathname);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  // const [terms, showTerm] = useState(false);
  const [tab, setTab] = useState(BIDTAB.LIVE);
  const [date, setDate] = useState({
    from: moment().startOf("day").add(-6, "days"),
    to: moment(),
  });
  const [datePicker, setDatePicker] = useState(false);
  const [currTime, setCurrTime] = useState("");
  const [nextBidData, setNextBidData] = useState({});
  const [setMount, setMountData] = useState(false);
  // const [bidStatus, setBidStatus] = useState();
  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip, tab]);

  const bidType = {
    dynamicBidType:
      location?.state?.tab === "/bid-to-buy"
        ? BidService.DYNAMIC_BID_TYPES.BID_TO_BUY
        : location?.state?.tab === "/new-arrival"
        ? BidService.DYNAMIC_BID_TYPES.NEW_ARRIVAL
        : undefined,
  };

  const fetchcolumn = () => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: "back" });
    if (!index) index = 1;
    const ctr = Columns.filter((el) => el.id === "ctPr");
    const amnt = Columns.filter((el) => el.id === "amt");
    Columns = Columns.filter((el) => el.id !== "ctPr" && el.id !== "amt");
    const columns = [
      tab === BIDTAB.HISTORY
        ? {
            Header: "Status",
            accessor: "status",
            Cell: ({ cell }) =>
              cell.value === DIAMOND_BID.STATUS.WON ? (
                <div className="activeStatus">
                  <span>Won</span>
                </div>
              ) : (
                <div className="activeStatus red">
                  <span>Loss</span>
                </div>
              ),
          }
        : null,
      ...Columns.slice(0, index + 1),
      ctr && ctr[0] ? ctr[0] : null,
      amnt && amnt[0] ? amnt[0] : null,
      {
        Header: "Bid Disc%",
        accessor: "bidDiscount",
        Cell: ({ cell, row }) => {
          return row.original.isFcCol
            ? "-"
            : cell.value < 0
            ? `-${parseFloat(Math.abs(cell.value)).toFixed(2)}`
            : `+${parseFloat(Math.abs(cell.value)).toFixed(2)}`;
        },
      },
      {
        Header: "Bid Price/Ct",
        accessor: "bidPricePerCarat",
        Cell: ({ cell }) => formatNumber(parseFloat(cell.value).toFixed(2)),
      },
      {
        Header: "Bid Amount",
        accessor: "bidAmount",
        Cell: ({ cell }) => formatNumber(parseFloat(cell.value).toFixed(2)),
      },
      tab !== BIDTAB.HISTORY
        ? {
            Header: "Current Max. Bid",
            accessor: "maxBid",
            id: "maxBid",
            width: 230,
            Cell: ({ row }) => formatNumber(parseFloat(row.original.maxBid || 0).toFixed(2)),
          }
        : null,
      ...Columns.slice(index + 1, Columns.length),
    ]
      .filter((el) => el && el)
      .map((col) => ({ ...col, id: col.accessor }));
    return columns;
  };
  const setResponse = (res) => {
    const columns = fetchcolumn();
    if (res) {
      const diamondData = res.data || [];
      let defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d.isDefaultChecked = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState({
        ...state,
        ...res,
        columns,
        data: diamondData,
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, columns, defaultChecked: [] });
    }
  };
  const fetch = useCallback(async () => {
    setState((state) => ({ ...state, loading: true, data: [] }));
    // const nextBid = await BidService.getConfig(bidType);
    const payload = { ...state, sort: sortRef.current, date };
    fetchBid(
      payload,
      // location?.state?.tab === "/new-arrival" ? 1 : 2,
      location?.state?.tab === "/new-arrival" ? 1 :location?.state?.tab === "/bid-to-buy" ? 2 : undefined,
      tab === BIDTAB.LIVE || tab === BIDTAB.HISTORY ? true : undefined,
      tab === BIDTAB.LIVE ? [getBidType()] : [DIAMOND_BID.TYPE.OPEN, DIAMOND_BID.TYPE.BLIND],
      tab === BIDTAB.LIVE ? [DIAMOND_BID.STATUS.PENDING] : [DIAMOND_BID.STATUS.WON, DIAMOND_BID.STATUS.LOSS],
      setResponse
    );
  }, [state.page, state.limit, sortRef.current, state.sum, date, tab]);

  const getSelection = () => {
    return tab === BIDTAB.HISTORY ? null : (
      <SelectStone
        profilListing={getBidType() ? "listingSelected" : ""}
        sum={state.sum}
        currentType={currentType.current}
      />
    );
  };

  useEffect(() => {
    fetch();
  }, [state.page, state.limit, tab, datePicker, date]);

  useEffect(() => {
    async function setTimers() {
      const time = await getCurrentTime();
      const nextBid = await BidService.getConfig(bidType)
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === "E_NOT_FOUND") {
            setState({ ...state, loading: false, data: [] });
          }
        });
      // const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
      // showTerm(data ? true : false);
      // let zone = nextBid?.sub_types?.timeZone === 'CET' ? time.CET : time.IST;
      setCurrTime(time.iso);
      setMountData(true);
      setNextBidData(nextBid || {});
      // setBidStatus(nextBid && nextBid.sub_types && nextBid.sub_types.allowDiamondFilterWSts);
      nextBid && nextBid.sub_types && fetch();
      if (isEmpty(nextBid)) {
        await setTab(BIDTAB.HISTORY);
      } else {
        setTab(BIDTAB.LIVE);
      }
    }
    setTimers();
  }, []);

  const bidEndTime = async () => {
    const nextBid = await BidService.getConfig(bidType)
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === "E_NOT_FOUND") {
          setState({ ...state, loading: false, data: [] });
        }
      });
    // const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
    // showTerm(data ? true : false);
    setNextBidData(nextBid || {});
    getSelection();
  };

  const paginate = async () => {
    const nextBid = await BidService.getConfig(bidType)
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === "E_NOT_FOUND") {
          setState({ ...state, loading: false, data: [] });
        }
      });
    // const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
    // showTerm(data ? true : false);
    setNextBidData(nextBid || {});
    getSelection();
    fetch();
  };

  useEffect(() => {
    setMount && paginate();
  }, [state.page, state.limit]);

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [sortRef.current]
  );

  const clearAllSelectedRows = useCallback(() => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum]
  );

  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const TIME = [
    { name: "Today", dateFrom: moment().startOf("day"), dateTo: moment() },
    {
      name: "Yesterday",
      dateFrom: moment().startOf("day").add(-1, "days"),
      dateTo: moment().endOf("day").add(-1, "days"),
    },
    {
      name: "Last 7 Days",
      dateFrom: moment().startOf("day").add(-6, "days"),
      dateTo: moment(),
    },
    {
      name: "This Month",
      dateFrom: moment().startOf("month"),
      dateTo: moment(),
    },
    { name: "This Year", dateFrom: moment().startOf("year"), dateTo: moment() },
    { name: "All", dateFrom: moment(user.createdAt), dateTo: moment() },
    { name: "Custom Range", datePicker: true },
  ];

  const getTitle = () => {
    return JSON.stringify(nextBidData) !== "{}" && state.data && state.data.length
      ? `${TITLE[getPath()]  } (${  state.count  })`
      : `${TITLE[getPath()]  } (0)`;
  };

  const getAction = () => {
    return tab === BIDTAB.LIVE ? (
      <DiamondListingAction
        {...state}
        {...props}
        // delete
        nocart
        nowatch
        nonote
        noreminder
        noconfirm
        // noappointment={getBidType() === DIAMOND_BID.TYPE.BLIND}
        noquote
        noappointment
        update
        modify
        nofinalcalc
        showStatus
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
      />
    ) : null;
  };

  const getBidBlock = () => {
    return getBidType() ? (
      <div className={`bidEndBlock${  !isMobile() ? " d-flex align-items-center" : ""}`}>
        {JSON.stringify(nextBidData) !== "{}" ? (
          <span className="bidEndLabel">
            <IntlMessage id="app.BidEndIn" />
            {' '}
            :
          </span>
        ) : (
          ""
        )}
        {/* <Countdown date={getBidEndCounter(getBidType())} fetch={fetch} /> */}
        {JSON.stringify(nextBidData) !== "{}" ? (
          <Countdown
            currTime={currTime}
            date={
              nextBidData?.sub_types?.currentBidEndDate &&
              moment(nextBidData.sub_types.currentBidEndDate).subtract(1, "hour")
            }
            // date ={moment(process.env.REACT_APP_BID_END_TIME, 'DD/MM/YYYY hh:mm')}
            // fetch={nextBidData?.sub_types?.currentBidEndDate && fetch}
            bidEndTime={bidEndTime}
          />
        ) : (
          ""
        )}
      </div>
    ) : null;
  };

  const getRadio = () => {
    return (
      <Radio.Group value={tab} onChange={(e) => setTab(e.target.value)}>
        {isObject(nextBidData) && !isEmpty(nextBidData) && <Radio value={BIDTAB.LIVE}>Live Bid</Radio>}
        <Radio value={BIDTAB.HISTORY}>Bid History</Radio>
      </Radio.Group>
    );
  };

  const getDropDown = () => {
    return tab === BIDTAB.HISTORY ? (
      <div
        className="bidhsitoryBlock"
        style={{
          background: "rgb(255, 255, 255)",
          cursor: "pointer",
          padding: "4px 9px",
          borderRadius: "5px",
          border: "1px solid #ddd",
          width: "230px",
          color: "#000000",
          marginRight: "5px",
        }}
      >
        <Dropdown
          overlayClassName="mobileSortingDropdown"
          overlay={(
            <div className="mobileSortingDropdownList">
              <ul>
                {TIME.map((t) => {
                  return (
                    <li
                      key={t.name}
                      style={{ color: "inherit", cursor: "pointer" }}
                      onClick={() => {
                        if (t.datePicker) setDatePicker(true);
                        else
                          setDate({
                            from: t.dateFrom,
                            to: t.dateTo,
                          });
                      }}
                    >
                      {t.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          trigger={["click"]}
        >
          {datePicker ? (
            <DatePicker.RangePicker
              open
              onOpenChange={() => setDatePicker(false)}
              onChange={(e) => {
                setDatePicker(false);
                setDate({ from: e[0], to: e[1] });
              }}
              disabledDate={(current) => current && current > moment().endOf("day")}
            />
          ) : (
            <span>
              <img src={calSVG} width="15px" style={{ marginRight: "10px" }} alt="" />
              <span>
                {date.from.format("ll")}
                {' '}
                -
                {date.to.format("ll")}
              </span>
            </span>
          )}
        </Dropdown>
      </div>
    ) : null;
  };

  if (isMobile()) {
    return (
      <BidListMobile
        {...props}
        {...state}
        data={state.data}
        displayStatus={tab === BIDTAB.HISTORY}
        BidTab={tab === BIDTAB.LIVE}
        currentType={currentType.current}
        parent={{
          getTitle,
          getSelection,
          getBidBlock,
          getAction,
          getRadio,
          getDropDown,
        }}
      />
    );
  }
  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div
          className="d-flex justify-content-between align-items-center width-100 listingTopBlock"
          style={{ position: "relative" }}
        >
          <Heading className="whiteNowrap ms-4">{getTitle()}</Heading>
          {getSelection()}
          <div className="d-flex flex-wrap justify-content-end">
            <div className="d-flex align-items-center j-content-center">
              <div style={{ display: "block" }}>{getRadio()}</div>
              {getDropDown()}
              {history.location?.state?.tab === "/new-arrival" ? (
                <Link className="commonOutline smallButton" to="/new-arrival">
                  Go to Bid
                </Link>
              ) : (
                <Link className="commonOutline smallButton" to="/bid-to-buy">
                  Go to Bid
                </Link>
              )}
            </div>
            {getBidBlock()}
          </div>
        </div>
      </div>
      <GridHeightContainer
        account={57}
        className="searchInnerResult searchResultTable"
        subtractFrom=".HeaderSticky,.dashboardQuickSearch,.selectStoneHeader,.diamondListingAction"
        adjustment={-12}
      >
        <Table
          noCheckBox={tab === BIDTAB.HISTORY}
          data={state.data}
          columns={state.columns}
          loading={state.loading}
          handleSort={handleSort}
          currentType={currentType.current}
          defaultChecked={state.defaultChecked}
        />
      </GridHeightContainer>
      {getAction()}
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(BidList);
