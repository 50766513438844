import React from "react";

const MobileGroupCard = (props) => {
  return (
    <div className="mobileGroupingCard">
      <div className="mobileGroupingHead" onClick={props.onClick}>
        {props.title}
      </div>
      <div className="mobileGroupingList">{props.children}</div>
    </div>
  );
};
export default MobileGroupCard;
