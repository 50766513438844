import React, { useRef, useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { Drawer, Spin } from "antd";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";

import GridHeightContainer from "containers/GridHeightContainer/GridHeightContainer";

import NoDataShow from "components/common/NoDataShow";

import { BidService } from "services/BidService";

import IntlMessage from "util/IntlMessage";

import { store } from "appRedux/store";

import NewArrivalBidMobile from "./NewArrivalBidMobile";
import BidTermsConditions from "./TermsConditions";

import { setNewArrivalFlag, setBidFlag } from "../../appRedux/actions/Common";
import { DIAMOND_BID, DIAMOND_WEB_STATUS, LOCAL_STORAGE_VAR } from "../../constants/Common";
import { getCurrentTime } from "../../services/util";
import { isArray, isEmpty, isString } from "../../util/utils";
import QuickSearch from "../Dashboard/QuickSearch";
import { getState, isArrayEqual } from "../DiamondList";
import { getColumn, fetchDiamondPaginate, isMobile, fetchDiamondList } from "../DiamondList/DiamondListFunctions";
import Table from "../DiamondList/TableBack";
import Countdown from "../Offer/Timer";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";
import SelectStone from "../common/DiamondListing/SelectStone";
import Heading from "../common/Heading";
import NoDataFound from "components/common/NoDataFound";

function getBidStartTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf("day").add(11, "hours");
  return moment().startOf("day").add(15, "hours");
}

function getBidEndTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf("day").add(14, "hours").add(59, "minutes");
  return moment().startOf("day").add(10, "hours").add(59, "minutes");
}

export function getBidEndCounter(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf("day").add(14, "hours").add(59, "minutes");
  if (moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN))
    return moment().startOf("day").add(10, "hours").add(59, "minutes");
  return moment().startOf("day").add(1, "days").add(10, "hours").add(59, "minutes");
}

export function getBidType() {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
}

const NewArrivalBidIt = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [terms, showTerm] = useState(true);
  const [setMount, setMountData] = useState(false);
  const [currTime, setCurrTime] = useState("");
  const [nextBidData, setNextBidData] = useState({});
  // const [bidStatus, setBidStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [subTypeCod, setSubTypeCod] = useState("");
  const [bidConfigurationId, setBidConfigurationId] = useState("");
  const [bidEnds, setBidEnds] = useState(false);
  const { totalNewArrDiamondCountBywebStatus } = props.commonData;
  const [filteredDiamondCount, setFilteredDiamondCount] = useState(0);

  const getSelection = () => {
    return (
      <SelectStone
        sum={state.sum}
        profilListing={getBidType() ? "listingSelected" : ""}
        currentType={currentType.current}
      />
    );
  };

  const setResponse = (res) => {
    const columns = getColumn();
    if (res) {
      const exportFilterData = res?.seachFilterTag?.searchData;
      const diamondData = res.data || [];
      let defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d.isDefaultChecked = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      const maxbid = [
        {
          Header: "Current Max. Bid",
          accessor: "maxBid",
          id: "maxBid",
          width: 230,
          Cell: ({ row }) => parseFloat(row.original.maxBid || 0).toFixed(2),
        },
      ];
      const amtIndex = columns.findIndex((el) => el.id === "amt");
      const bidCol = columns.splice(amtIndex + 1, 0, ...maxbid);
      setLoading(false);
      setState({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        columns: [...bidCol, ...columns],
        loading: false,
        exportFilterData,
      });
      setFilteredDiamondCount(diamondData.length)
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
      setLoading(false);
    }
  };

  const fetch = useCallback(() => {
    setLoading(true);
    setState((state) => ({ ...state, loading: true, data: [] }));

    const diamondSearchId = isString(location.search) ? [location.search.replace("?", "")].filter(Boolean) : undefined;

    const payload = {
      page: state.page,
      limit: state.limit,
      sort: sortRef.current,
    };

    const filters = diamondSearchId ? [{ diamondSearchId }] : undefined;
    isArray(diamondSearchId) && diamondSearchId.length !== 0
      ? fetchDiamondList(payload, filters, setResponse)
      : fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.NEWARRIVAL, setResponse);
  }, [state.page, state.limit, location.search]);

  const clearSelection = React.useCallback(() => {
    store.dispatch({ type: "RESET_SELECTED_ROWS" });
    if (document.getElementsByTagName("table")[0]) {
      const tblRows = document.getElementsByTagName("table")[0].rows;
      for (const row of tblRows) row.classList.remove("selectTr");
    }
  }, []);

  useEffect(() => {
    async function setTimers() {
      const time = await getCurrentTime();

      const nextBid = await BidService.getConfig({ dynamicBidType: BidService.DYNAMIC_BID_TYPES.NEW_ARRIVAL })
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === "E_NOT_FOUND") {
            setState({ ...state, loading: false, data: [] });
            props.setNewArrivalFlag(false);
            setLoading(false);
          }
        });
      if (nextBid?.dynamicBidType === 1 && JSON.stringify(nextBid) !== "[]") {
        props.setNewArrivalFlag(true);
      } else {
        props.setNewArrivalFlag(false);
      }
      const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
      const subTypeCode = nextBid && nextBid.sub_types && nextBid.sub_types.subTypeCode;
      const bidConfigurationId = nextBid && nextBid.id;
      setBidConfigurationId(bidConfigurationId);
      setSubTypeCod(subTypeCode);
      showTerm(!!data);
      // let zone = nextBid?.sub_types?.timeZone === 'CET' ? time.CET : time.IST;
      setCurrTime(time.iso);
      setMountData(true);
      setNextBidData(nextBid && nextBid?.dynamicBidType === 1 ? nextBid : []);

      // setBidStatus(nextBid && nextBid.sub_types && nextBid.sub_types.allowDiamondFilterWSts);
      clearSelection();
      nextBid && nextBid.sub_types && nextBid?.dynamicBidType === 1 && fetch();
    }
    setTimers();
  }, []);

  const bidEndTime = async () => {
    const nextBid = await BidService.getConfig({ dynamicBidType: BidService.DYNAMIC_BID_TYPES.NEW_ARRIVAL })
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === "E_NOT_FOUND") {
          setState({ ...state, data: [] });
          setLoading(false);
        }
      });
    const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
    showTerm(!!data);
    setNextBidData(nextBid && nextBid?.dynamicBidType === 1 ? nextBid : []);
    setBidEnds(true);

    getSelection();
    nextBid && nextBid?.dynamicBidType === 1 && fetch();
  };

  useEffect(() => {
    bidEnds === true && window.location.reload();
  }, [bidEnds]);

  const clearAllSelectedRows = useCallback(() => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum]
  );

  useEffect(() => {
    fetch();
  }, [state.page, state.limit, location.search, fetch]);

  const paginate = async () => {
    setLoading(true);
    const nextBid = await BidService.getConfig({ dynamicBidType: BidService.DYNAMIC_BID_TYPES.NEW_ARRIVAL })
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === "E_NOT_FOUND") {
          setState({ ...state, data: [] });
          setLoading(false);
        }
      });
    const data = nextBid && nextBid.sub_types && nextBid.sub_types.ruleFile;
    showTerm(!!data);
    setNextBidData(nextBid && nextBid?.dynamicBidType === 1 ? nextBid : []);

    getSelection();
    nextBid?.dynamicBidType === 1 && fetch();
  };

  useEffect(() => {
    setMount && paginate();
  }, [state.page, state.limit]);

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch]
  );

  const getTitle = () => {
    return JSON.stringify(nextBidData) !== "[]" && state.data && state.data.length ? (
      <>
        <IntlMessage id="app.newArrival" /> ({filteredDiamondCount})
      </>
    ) : (
      <>
        <IntlMessage id="app.newArrival" /> ({0})
      </>
    );
  };

  const getBidBlock = () => {
    return getBidType() ? (
      <div className={`bidEndBlock${!isMobile() ? " d-flex align-items-center" : ""}`}>
        {JSON.stringify(nextBidData) !== "[]" ? (
          <span className="bidEndLabel">
            <IntlMessage id="app.BidEndIn" /> :
          </span>
        ) : (
          ""
        )}
        {/* <Countdown date={getBidEndCounter(getBidType())} fetch={fetch} /> */}
        {JSON.stringify(nextBidData) !== "[]" ? (
          <Countdown
            currTime={currTime}
            // date={
            //   nextBidData &&
            //   nextBidData?.sub_types &&
            //   nextBidData?.sub_types?.currentBidEndDate &&
            //   nextBidData?.sub_types?.timezone === 'CET'
            //     ? moment(nextBidData?.sub_types?.currentBidEndDate)
            //         .tz('Asia/Kolkata')
            //         .format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
            //     : moment(nextBidData?.sub_types?.currentBidEndDate)
            //         .tz('Europe/Berlin')
            //         .format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
            // }
            date={
              nextBidData?.sub_types?.currentBidEndDate &&
              moment(nextBidData.sub_types.currentBidEndDate).subtract(1, "hour")
            } // subtract because convert to utc
            fetch={nextBidData && nextBidData.sub_types && nextBidData.sub_types.currentBidEndDate && fetch}
            bidEndTime={bidEndTime}
          />
        ) : (
          ""
        )}
      </div>
    ) : null;
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        reqparamId={props.location?.search}
        dynamicBidType={{ dynamicBidType: BidService.DYNAMIC_BID_TYPES.NEW_ARRIVAL }}
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        myBidStone
        myBid
        excel
        nocart
        // nowatch
        noreminder
        importexcel
        noquote
        noconfirm
        nonote
        // noappointment
        nofinalcalc
        subTypeCode={subTypeCod}
        bidConfigurationId={bidConfigurationId}
        // noappointment={getBidType() === DIAMOND_BID.TYPE.BLIND}
      />
    );
  };

  const getOffAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        dynamicBidType={{ dynamicBidType: BidService.DYNAMIC_BID_TYPES.NEW_ARRIVAL }}
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        nopagination
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        bidStone
        myBid
        // excel
        nonocompare
        noprint
        noshare
        nodownload
        nocart
        nowatch
        noreminder
        noquote
        noconfirm
        nonote
        noappointment
        nofinalcalc
      />
    );
  };

  const drawer = React.useMemo(() => {
    return (
      <Drawer
        onClose={() => showTerm(false)}
        visible={terms}
        wrapClassName="diamondListinSidebar sm-size"
        destroyOnClose
      >
        <BidTermsConditions nextBidData={nextBidData} />
      </Drawer>
    );
  }, [nextBidData, terms]);

  const getDrawer = () => drawer;

  if (isMobile()) {
    return (
      <>
        {JSON.stringify(nextBidData) === "[]" && (
          <div className="searchInnerResult">
            <div className="searchResultTable">
              <NoDataShow message={<IntlMessage id="app.BidInactive" />} />
              {getOffAction()}
            </div>
          </div>
        )}
        {JSON.stringify(nextBidData) !== "[]" && state.data.length ? (
          <NewArrivalBidMobile
            {...props}
            {...state}
            currentType={currentType.current}
            parent={{ getTitle, getSelection, getBidBlock, getAction, getDrawer }}
          />
        ) : undefined}
      </>
    );
  }

  return (
    <div className="searchResultListWrapper">
      <QuickSearch dataFilter={state.filterArrayData} diamondList />
      <div className="selectStoneHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock">
          <Heading className="mb-0 ms-4" title={getTitle()} />
          {getSelection()}
          {getBidBlock()}
        </div>
      </div>
      {loading && (
        <div className="searchInnerResult ">
          <div className="searchResultTable">
            <NoDataShow message={<Spin />} />
          </div>
        </div>
      )}
      {
        JSON.stringify(nextBidData) !== "[]" && state.data && state.data.length ? (
          <GridHeightContainer
            className="searchInnerResult searchResultTable"
            subtractFrom=".HeaderSticky,.dashboardQuickSearch,.selectStoneHeader,.diamondListingAction"
            adjustment={-12}
          >
            <Table
              data={state.data}
              loading={loading}
              ref={tableRef}
              handleSort={handleSort}
              currentType={currentType.current}
              defaultChecked={state.defaultChecked}
              columns={state.columns}
              setFilteredDiamondCount={setFilteredDiamondCount}
            />
          </GridHeightContainer>
        ) : (
          <>
            <div style={{ height: "75vh" }}>
              <NoDataFound />
            </div>
          </>
        )
        // : (
        // totalNewArrDiamondCountBywebStatus === 0 && (
        //    <div className="searchInnerResult ">
        //      <div className="searchResultTable">
        //        <NoDataShow message={<IntlMessage id="app.noDiamondsInBid" />} />
        //      </div>
        //    </div>
        //  )
        //)
      }

      {JSON.stringify(nextBidData) === "[]" && totalNewArrDiamondCountBywebStatus !== 0 ? (
        <div className="searchInnerResult">
          <div className="searchResultTable">
            <NoDataShow message={<IntlMessage id="app.BidInactive" />} />
          </div>
        </div>
      ) : (
        ""
      )}

      {JSON.stringify(nextBidData) !== "[]" && state.data && state.data.length ? getAction() : getOffAction()}
      {!isEmpty(nextBidData) && drawer}
    </div>
  );
};

export default compose(
  connect(
    (state) => ({
      commonData: state?.commonData,
    }),
    {
      setNewArrivalFlag,
      setBidFlag,
    }
  ),
  withRouter
)(NewArrivalBidIt);
