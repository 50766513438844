const ValidationMessages = {
  // validation messages
  Name: "Please enter the name.",
  Subject: "Please enter subject.",
  FirstName: "Please enter the First Name.",
  LastName: "Please enter the Last name.",
  NameValid: "Please enter only alphabets.",
  EmailRequired: "Please enter the Email address.",
  EmailValid: "Please enter the valid Email address.",
  PhoneRequire: "Please enter the Mobile number.",
  PhoneValid: "Please enter the valid Phone number.",
  Address: "Please enter Address.",
  City: "Please select the City.",
  State: "Please select the State.",
  Country: "Please select the Country.",
  CompanyName: "Please enter the Company name.",
  BusinessType: "Please select business type.",
  Website: "Please write proper website.",
  CompanyType: "Please select company type.",
  pinCode: "Please enter the Zip Code.",
  UserName: "Please enter the Username.",
  Password: "Please enter the Password.",
  ConfirmPassword: "Please enter Confirm Password.",
  SamePasword: "Confirm password must match with the password.",
  PasswordValid: "Please enter valid password.",
  PasswordLength: "Password must be atleast 8 character.",
  Message: "Please enter message.",
  Ziplength: "Zip code must be less than 10 digits.",
};

export default ValidationMessages;
