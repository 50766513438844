import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import urlJoin from "url-join";

import { notification } from "antd";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

import IntlMessage from "util/IntlMessage";

import { BASE_URL, MIME_TYPES, LOCAL_STORAGE_VAR, KYC_STATUS } from "../../../constants/Common";
import {
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  UPLOAD_FILE,
  UPDATE_USER_PROFILE,
  GET_CITY_LIST,
  GET_USER_PROFILE,
} from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import { isMobile } from "../../DiamondList/DiamondListFunctions";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";
import ProfileUpload from "../../common/ProfileUpload";
import SelectOption from "../../common/SelectOption";
import UploadImage from "../../common/UploadImage/";
//import DefaultUser from '../../common/UserProfileDefault';
import UserProfileDefault from "../../common/UserProfileDefault";

export const companyDetail = {
  BusinessType: [
    { id: "Independent Jeweler", name: "Independent Jeweler" },
    { id: "Online Jewelry Store", name: "Online Jewelry Store" },
    { id: "Diamond Dealer/ Broker", name: "Diamond Dealer/ Broker" },
    { id: "Diamond Manufacturer / Cutter", name: "Diamond Manufacturer / Cutter" },
    { id: "Jewelry Manufacturer", name: "Jewelry Manufacturer" },
    { id: "Jewelry Retail Chain", name: "Jewelry Retail Chain" },
    { id: "Pawn shop", name: "Pawn shop" },
  ],
};

class CompanyInformation extends Component {
  state = {
    data: {},
    cities: [],
    states: [],
    countries: [],
    errors: [],
    doc: [],
    path: "",
    isApproved: KYC_STATUS.PENDING,
    changes: false,
  };

  componentDidMount() {
    this.getCountries();
    this.fetchAccount();
  }

  getCountries = () => {
    const obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        this.setState({ countries: data.data });
      }
    });
  };

  getStates = (countryId) => {
    const obj = {
      ...GET_STATE_LIST,
      request: { country: countryId },
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        this.setState({ states: data.data });
      }
    });
  };

  getCities = (stateId) => {
    const obj = {
      ...GET_CITY_LIST,
      request: { state: stateId },
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        this.setState({ cities: data.data });
      }
    });
  };

  fetchAccount() {
    const obj = { ...GET_USER_PROFILE };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        if (data.data.isApproved) {
          this.setState({ isApproved: data.data.isApproved });
        }
        !isEmpty(data.data.kyc) &&
          data.data.kyc.forEach((k) => {
            if (k.type === "business") this.setState({ doc: [k.docType], path: k.path }, () => {});
          });
        this.setState({ data: data.data });
        this.getStates(data.data.country);
        this.getCities(data.data.state);
      }
    });
  }

  updateAccount() {
    const reqObj = clone(this.state.data);
    //reqObj.kyc = [{ ...reqObj.kyc, path: reqObj.businessId }];
    reqObj.kyc = this.props.kyc;
    if (this.state.changes) {
      reqObj.isKycUploaded = true;
      reqObj.isApproved = 1;
    }
    const obj = {
      ...UPDATE_USER_PROFILE,
      request: reqObj,
    };
    if (this.handleValidation()) {
      UtilService.callApi(obj, (err, data) => {
        if (err && err.data) {
          if (err.data.code === "E_USER_NOT_FOUND") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (err.data.code === "E_DUPLICATE") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }

        if (data && data.code === "OK") {
          OpenNotification({
            type: "success",
            title: "Company details are updated successfully.",
          });
          const setObj = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
          setObj.account = reqObj;
          UtilService.setLocalStorageItem(setObj, `${LOCAL_STORAGE_VAR}-user`);

          this.props.onClose();
        }
      });
    }
  }

  uploadDocument = (ee, type) => {
    const e = cloneDeep(ee);
    const errors = clone(this.state.errors);
    const formData = clone(this.state.data);
    let path = clone(this.state.path);

    const blob = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = (ev) => {
      const arr = new Uint8Array(ev.target.result).subarray(0, 4);
      let header = "";
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: "error",
          title: "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", e.target.files[0]);

        const objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, (err, data) => {
          if (err) throw err;
          if (data && data.code === "OK") {
            errors[type] = undefined;
            if (type === "businessId") {
              this.props.handleKyc("business", data.data.files[0].absolutePath, this.state.doc);
              path = data.data.files[0].absolutePath;
            }
            formData[type] = data.data.files[0].absolutePath;
            this.setState({ data: formData, path: path, errors });
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  handleInputChange = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ data: data });
  };

  handleValidation = () => {
    const formData = { ...this.state.data };
    const errors = { ...this.state.errors };
    let formIsValid = true;
    const blankField = [];
    const invalidFiels = [];
    if (!formData["address"]) {
      blankField.push("Address 1");
      formIsValid = false;
    }

    if (!formData["companyName"]) {
      blankField.push("companyName");
      formIsValid = false;
    }

    if (blankField.length > 0) {
      const arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFiels.length > 0) {
      const arr = invalidFiels;
      const outStr =
        arr.length === 1
          ? arr[0]
          : arr.length === 2
          ? arr.join(" and ")
          : arr.length > 2
          ? arr.slice(0, -1).join(", ") + " and " + arr.slice(-1)
          : "";

      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  render() {
    const BUSINESS_DOCS =
      (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
        JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_BUSINESS) ||
      [];
    const { countries, states, cities, data } = this.state;

    const { BUSINESS_TYPE } = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
    const { NATURE_OF_ORG } = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
    return (
      <div>
        <div className="profileHead">
          <Heading title={<IntlMessage id="app.CompanyDetails" />} className="p-0 popupInnerTitle"></Heading>
          <div className="personalProfileWrapper">
            <div className="editProfileLogo">
              {data.vendorLogo ? (
                <img src={urlJoin(BASE_URL, data.vendorLogo)} alt="" />
              ) : (
                <UserProfileDefault size="lg-size" />
              )}
            </div>
            <div className="profileAction">
              <ProfileUpload
                label={<IntlMessage id="app.EditProfile" />}
                accept="jpg, png,jpeg,PNG,JPEG,JPG"
                onChange={(e) => this.uploadDocument(e, "vendorLogo")}
              />
              {data.vendorLogo && (
                <span onClick={() => this.handleInputChange("vendorLogo", "")}>
                  <IntlMessage id="app.RemoveProfile" />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="profileDetailSpace">
          <IntlMessage id="app.CompanyName">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("name", e.target.value)}
                placeholder={placeholder}
                value={data.companyName}
                disabled={true}
                disabledClass={"disable_Class"}
                label={
                  <>
                    <IntlMessage id="app.CompanyName" />*
                  </>
                }
              />
            )}
          </IntlMessage>
          <SelectOption
            defaultValue={<IntlMessage id="app.BusinessType" />}
            value={isEmpty(data.businessType) ? undefined : find(BUSINESS_TYPE, { id: [data.businessType] }).name}
            selectValue={BUSINESS_TYPE}
            disabled
            disabledClass={"disable_Class"}
            onChange={(e) => {
              this.handleInputChange("businessType", e[0]);
            }}
            label={
              <>
                <IntlMessage id="app.SelectBusinessType" /> *
              </>
            }
          />
          <SelectOption
            defaultValue={<IntlMessage id="app.comp-type" />}
            value={isEmpty(data.companyType) ? undefined : find(NATURE_OF_ORG, { id: [data.companyType] }).name}
            selectValue={NATURE_OF_ORG}
            disabled
            disabledClass={"disable_Class"}
            onChange={(e) => {
              this.handleInputChange("companyType", e[0]);
            }}
            label={
              <>
                <IntlMessage id="app.comp-type" /> *
              </>
            }
          />
          <InputBlock
            type="text"
            label={
              <>
                <IntlMessage id="app.WebsiteUrl" /> *
              </>
            }
            placeholder="Website Url"
            onChange={(e) => this.handleInputChange("Website", e.target.value)}
            value={data.Website}
          />
          <IntlMessage id="app.address">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("address", e.target.value)}
                placeholder={placeholder}
                value={data.address}
                label={
                  <>
                    <IntlMessage id="app.address" />*
                  </>
                }
              />
            )}
          </IntlMessage>
          <SelectOption
            show
            value={data.country}
            selectValue={countries}
            showSearch
            onChange={(value) => {
              const data = clone(this.state.data);
              data["state"] = undefined;
              data["city"] = undefined;

              data["country"] = value;
              this.getStates(value);
              this.setState({ data, cities: [] });
            }}
            label={
              <>
                <IntlMessage id="app.CountryLabel" />
              </>
            }
          />
          <SelectOption
            showSearch
            defaultValue={<IntlMessage id="app.State" />}
            value={data.state}
            selectValue={states}
            onChange={(value) => {
              const data = clone(this.state.data);

              data["state"] = value;
              this.getCities(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessage id="app.StateLabel" />
              </>
            }
          />
          <SelectOption
            showSearch
            defaultValue={<IntlMessage id="app.City" />}
            value={data.city}
            selectValue={cities}
            onChange={(value) => {
              const data = clone(this.state.data);
              data["city"] = value;
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessage id="app.CityLabel" />
              </>
            }
          />
          <IntlMessage id="app.ZipCode">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("zipCode", e.target.value)}
                type="number"
                placeholder={placeholder}
                value={data.zipCode}
                label={
                  <>
                    <IntlMessage id="app.ZipCode" />
                  </>
                }
              />
            )}
          </IntlMessage>
          <SelectOption
            value={
              find(BUSINESS_DOCS, { id: this.state.doc }) ? find(BUSINESS_DOCS, { id: this.state.doc }).name : undefined
            }
            placeholder={"Select Document"}
            selectValue={BUSINESS_DOCS}
            onChange={(value) => {
              this.setState({ doc: value, changes: true }, () =>
                this.props.handleKyc("business", null, this.state.doc)
              );
            }}
            label={
              <>
                <IntlMessage id="app.DocumentType*" />
              </>
            }
          />
          <UploadImage
            file={this.state.path}
            onUpload={(e) => {
              this.setState({ changes: true });
              this.uploadDocument(e, "businessId");
            }}
            leftAlign
          />
          {this.state.isApproved === KYC_STATUS.APPROVED && (
            <span>
              <IntlMessage id="app.KYCApproved" />
            </span>
          )}
        </div>
        <div className="sideBarPopupButton">
          <span className="commonButton" onClick={() => this.updateAccount()}>
            <IntlMessage id="app.SaveCompanyDetail" />
          </span>
          <span
            className="commonOutline"
            onClick={() => {
              isMobile() ? this.props.history.goBack() : this.props.onClose();
            }}
          >
            <IntlMessage id="app.Cancel" />
          </span>
        </div>
      </div>
    );
  }
}
export default withRouter(CompanyInformation);
