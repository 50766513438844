import React, { useState, useEffect } from "react";

import { Drawer, Select } from "antd";

import CaratRange from "components/Dashboard/QuickSearch/CaratRange";
import ClarityRange from "components/Dashboard/QuickSearch/ClarityRange";
import ColorRange from "components/Dashboard/QuickSearch/ColorRange";
import ShapeSelector from "components/Dashboard/QuickSearch/Shape";
import CommonModal from "components/common/CommonModal";

import UtilService from "services/util";

import { useIsMobile } from "util/hooks";
import { compareArrays, isArray } from "util/utils";

import { LOCAL_STORAGE_VAR } from "constants/Common";
import { USER_SETTING_LIST, USER_SETTING_UPSERT } from "constants/apiConstant";

import eyeCloseSVG from "assets/svg/DiamondList/eye-close.svg";
import eyeSVG from "assets/svg/DiamondList/eye.svg";
import filterSVG from "assets/svg/DiamondList/filter.svg";
import FilterTopOption from "./FilterOption";
import FilterTop from "./FilterTop";

import OpenNotification from "../CommonButton/OpenNotification";


const setting1 = [
  "Stock No",
  "DNA",
  "Details",
  "Loc",
  "Cut",
  "Pol",
  "Sym",
  "Fls",
  "Shape",
  "Carat",
  "Col",
  "Clarity",
  " Rap($)",
  "Rap Value",
  " Disc%",
  "Price/Ct.",
  "Amt($)",
];
const setting2 = [...setting1, "Report No", "Shade", "Milky"];
const setting3 = [...setting2, "Tinge Intensity", " Girdle%"];
// const setting4 = ['Stock No', 'Loc', 'Shape', 'Carat', 'Col', 'Clarity', 'Shade'];

export const quickSearchData = {
  shape: [
    { value: "Round" },
    { value: "Princess" },
    { value: "Pear" },
    { value: "Oval" },
    { value: "Marquise" },
    { value: "Emerald" },
    { value: "Heart" },
    { value: "Radiant" },
    { value: "Cushion" },
    { value: "Other" },
  ],
  color: [
    { value: "DEF" },
    { value: "GHI" },
    { value: "J-" },
    { value: "Oval" },
    { value: "Marquise" },
    { value: "Emerald" },
    { value: "Heart" },
    { value: "Radiant" },
    { value: "Cushion" },
    { value: "Other" },
  ],
  clarity: [{ value: "LC" }, { value: "FL-IF" }, { value: "VVS" }, { value: "VS" }, { value: "SI" }, { value: "I" }],
  carat: [
    { value: "0.0018-0.29" },
    { value: "0.23-0.299" },
    { value: "0.3-0.349" },
    { value: "0.4-0.499" },
    { value: "0.5-0.599" },
    { value: "0.6-0.699" },
  ],
};

function ListingTop(props) {
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useState([]);
  const [allSettings, setAllSettings] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [columnsSettingType, setColumnsSettingType] = useState("All Columns");
  const [callUpdateApi, setCallUpdateApi] = useState([]);
  let isMobile = useIsMobile();
  const openFilter = () => setFilterOpen(true);
  const closeFilter = () => setFilterOpen(false);
  if (isArray(isMobile)) isMobile = isMobile[0];
  const { resetColumns, setResetColumns } = props;

  const fetchSettings = () => {
    const obj = {
      // url: "/admin/user-setting/paginate",
      // method: "post",
      ...USER_SETTING_LIST,
    };

    UtilService.callApi(obj, async (err, data) => {
      if (data && data.code === "OK") {
        if (data.data.setting) {
          setSettings(data.data.setting);
          setAllSettings(data.data.setting);
          const settingFromLocal = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`));
          const settingKeys = await settingFromLocal.map((s) => {
            return s.title;
          });
          if (settingKeys?.length && compareArrays(settingKeys, setting1) === true) {
            setColumnsSettingType("Recommended");
          } else if (settingKeys?.length && compareArrays(settingKeys, setting2) === true) {
            setColumnsSettingType("Setting 1");
          } else if (settingKeys?.length && compareArrays(settingKeys, setting3) === true) {
            setColumnsSettingType("Setting 2");
          }
        }
      }
    });
  };

  const handleUpdateSetting = ({ set }) => {
    const obj = {
      setting: set,
      // setting: isDefaultCall ? allSettings : settings,
    };

    // const api = '/admin/user-setting/upsert';
    const objData = {
      // method: "post",
      // url: api,
      ...USER_SETTING_UPSERT,
      request: obj,
    };

    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(set));
        // localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(isDefaultCall ? allSettings : settings));
        OpenNotification({
          type: "success",
          title: "Your diamond sequence is updated successfully.",
        });

        setResetColumns(!resetColumns);
      }
    });
  };

  useEffect(() => {
    if (settings?.length === 0) {
      fetchSettings();
    }
  }, [settings]);
  useEffect(() => {
    if (callUpdateApi?.length > 0) {
      handleUpdateSetting({ set: callUpdateApi });
    }
    return () => {
      setCallUpdateApi(null);
    };
  }, [callUpdateApi]);

  const handleColumnsDropdown = async (settingType) => {
    await setColumnsSettingType(settingType);
    let res = [];

    if (allSettings && allSettings.length) {
      if (settingType === "setting 1") {
        res = await allSettings.filter((s) => {
          return setting1.find((item) => {
            return item === s.title;
          });
        });
      }
      if (settingType === "setting 2") {
        res = await allSettings.filter((s) => {
          return setting2.find((item) => {
            return item === s.title;
          });
        });
      }
      if (settingType === "setting 3") {
        res = await allSettings.filter((s) => {
          return setting3.find((item) => {
            return item === s.title;
          });
        });
      }
      // if (settingType === 'setting4') {
      //   res = await allSettings.filter((s) => {
      //     return setting4.find((item) => {
      //       return item === s.title;
      //     });
      //   });
      // }
      if (settingType === "default") {
        handleUpdateSetting({ set: allSettings, isDefaultCall: true });
      }
    }
    if (res && res.length) {
      res = res.map((i) => {
        i.isActive = true;
        return i;
      });

      setSettings(res);
      setCallUpdateApi(res);
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="d-flex align-items-center mobileRightAction">
          {props.listGridIcon && (
            <div className="gridListIcon">
              <img src={props.image} alt="gridIcon" onClick={props.onClick} />
            </div>
          )}
          {Boolean(props.filterTop) && (
            <div className="filterOption" onClick={openFilter}>
              <span className="filterStatus">{props.filterTop}</span>
              <img src={filterSVG} alt="filter" />
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex j-space-between filterTopMainBlock align-items-center">
          {props.filterTop ? (
            <div className="searchInnerFilterMain">
              <>
                {props.ids.map((item, index) => {
                  return (
                    <FilterTop
                      key={index}
                      id={item.id}
                      handleRemoveFilter={props.handleRemoveFilter}
                      handleFilterChange={props.handleFilterChange}
                      filterTitle={`Filter ${index + 1}`}
                      activeClass={item.isActive ? `active` : ""}
                      image={item.isActive ? eyeSVG : eyeCloseSVG}
                    />
                  );
                })}
              </>
            </div>
          ) : undefined}
          {props.matchpairfilterTop && (
            <div className="searchInnerFilterMain">
              <>
                <FilterTop filterTitle="ROUND 0.50-0.69 DEF IFVVS1" image={eyeCloseSVG} />
                <FilterTop filterTitle="Pear 0.50-0.69 GHI IFVVS1" image={eyeSVG} />
                <FilterTop filterTitle="ROUND 0.50-0.69 EF IFVVS2" image={eyeSVG} />
              </>
            </div>
          )}
          {(props.confirmList || props.officeList) && (
            <div className="d-flex">
              <FilterTopOption onClick={(e) => props.getStatus(e)} confirmList dateFilter={false} />
            </div>
          )}
          {props.offerList && (
            <div className="d-flex">
              <FilterTopOption onClick={(e) => props.getStatus(e)} offerList dateFilter={false} />
            </div>
          )}
          {props.Cart && (
            <div className="d-flex">
              <FilterTopOption SearchReultOption dateFilter={false} />
            </div>
          )}
          {props.listGridIcon && (
            <div className="gridListIcon">
              <img src={props.image} alt="gridIcon" onClick={props.onClick} />
            </div>
          )}
          <div className="d-flex">
            <div className="searchInnerFilterMain">
              <Select
                className="updateStausBlock "
                placeholder="Column setting"
                value={columnsSettingType}
                onChange={(val) => handleColumnsDropdown(val)}
                style={{ width: "150px" }}
              >
                <Select.Option value="default">All Columns</Select.Option>
                <Select.Option value="setting 1">Recommended</Select.Option>
                <Select.Option value="setting 2">Setting 1</Select.Option>
                <Select.Option value="setting 3">Setting 2</Select.Option>
              </Select>
            </div>
          </div>

          {showModal && (
            <CommonModal
              visible={showModal}
              handleOk={setShowModal}
              handleCancel={() => setShowModal(false)}
              title="Modify Diamond Search"
              footerShow
              submitTitle="Apply"
              cancelTitle="Cancel"
            >
              <div className="dashboardQuickSearch">
                <div className="width-100 d-flex flex-wrap">
                  <ShapeSelector />
                  <ColorRange />
                  <CaratRange />
                  <ClarityRange />
                </div>
              </div>
            </CommonModal>
          )}
        </div>
      )}
      {filterOpen && (
        <Drawer onClose={closeFilter} visible={filterOpen} wrapClassName="mobileFilterSideBar" destroyOnClose>
          {/* <MobileFilterSidebar /> */}
          {" "}
          <div className="searchInnerFilterMain">
            <>
              {isArray(props.ids) &&
                props.ids.map((item, index) => {
                  return (
                    <FilterTop
                      key={index}
                      id={item.id}
                      handleRemoveFilter={props.handleRemoveFilter}
                      handleFilterChange={props.handleFilterChange}
                      filterTitle={`Filter ${index + 1}`}
                      activeClass={item.isActive ? `active` : ""}
                      image={item.isActive ? eyeSVG : eyeCloseSVG}
                    />
                  );
                })}
            </>
          </div>
        </Drawer>
      )}
    </>
  );
}

export default React.memo(ListingTop);
