import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { groupBy } from "lodash";

import { LOCAL_STORAGE_VAR, SEARCH_ALL_CONST } from "../../../constants/Common";
import { setDateFormat, setTimeFormat, checkForMaster } from "../../../services/Commonfunction";
// import { Button } from 'antd';

const SavedSearchCard = (props) => {
  const [saveDetail, setSaveDetail] = useState({});
  const [searchData, setSearchData] = useState({});

  const performArray = (saveDetail) => {
    if (saveDetail && saveDetail.searchData) {
      const Master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));

      const tempArray = [];
      Object.keys(saveDetail.searchData).forEach((d) => {
        if (saveDetail.searchData[d] instanceof Array) {
          saveDetail.searchData[d] &&
            saveDetail.searchData[d].length !== 0 &&
            saveDetail.searchData[d].forEach((d2) => {
              const MasterCode = checkForMaster(d);
              if (MasterCode === "CARAT") {
                if (saveDetail.searchData[d][0]?.crt) {
                  tempArray.push({
                    parentCode: MasterCode,
                    search: `${saveDetail?.searchData[d][0]?.crt[">="]}-${
                      saveDetail?.searchData[d][saveDetail?.searchData[d]?.length - 1]?.crt["<="]
                    }`,
                  });
                }
              } else if (Master && MasterCode !== undefined && Master[MasterCode] && Master[MasterCode].length !== 0) {
                  Master[MasterCode].forEach((a) => {
                    if (d2 === a.id[0]) {
                      tempArray.push({
                        parentCode: MasterCode,
                        search: a.name,
                      });
                    }
                  });
                }
            });
        }
      });
      if (tempArray && tempArray.length !== 0) {
        const groupArray = groupBy(tempArray, "parentCode");

        setSearchData({ ...groupArray });
      }
    }
  };

  useEffect(() => {
    setSaveDetail({ ...props.saveDetail });
    performArray(props.saveDetail);
  }, [props.saveDetail]);
  return (
    <div
      onClick={() => {
        if (!props.recentActivity) {
          props.history.push(`/diamond-list?${  props.saveId}`);
        }
      }}
      key={props.key}
      className="savedSearchItem"
    >
      {props.recentSearch ? null : (
        <div className={`savedSearchHead ${props.recentActivity && "pb-0"}`}>
          {props.recentActivity ? (
            <div className="searchTitle">
              {saveDetail.page ? saveDetail.page : "--"} 
              {' '}
              {saveDetail.section && `(${saveDetail.section})`}
            </div>
          ) : (
            <div className="searchTitle">{saveDetail.name ? saveDetail.name : "--"}</div>
          )}

          <div className="searchTime">
            <span>{saveDetail.createdAt ? setDateFormat(saveDetail.createdAt) : "--"}</span>
            <span>{saveDetail.createdAt ? setTimeFormat(saveDetail.createdAt) : "--"}</span>
          </div>
        </div>
      )}
      {props.recentActivity ? null : (
        <div className="savedSearchDetail">
          {searchData && Object.keys(searchData).length !== 0
            ? Object.keys(searchData).map((obKey, i) => {
                return (
                  <div key={i} className="detail">
                    <span key={i}>
                      {obKey}
                      {' '}
                      :
                    </span>
                    {searchData[obKey].length === 1 ? (
                      <span>{searchData[obKey][0].search}</span>
                    ) : searchData[obKey].length > 1 ? (
                      <span>
                        {searchData[obKey][0].search} 
                        {' '}
                        {obKey !== "CARAT" && (
                        <>
                          (
                          {`+${searchData[obKey].length - 1}`}
                          )
                        </>
)}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            : SEARCH_ALL_CONST.map((variant, i) => {
                return (
                  <div key={i} className="detail">
                    <span key={i}>
                      {variant}
                      {' '}
                      :
                    </span>
                    <span>All </span>
                  </div>
                );
              })}
        </div>
      )}
      {props.recentSearch && (
        <div className="searchTime bottomSection">
          <span>{saveDetail.createdAt ? setDateFormat(saveDetail.createdAt) : "--"}</span>
          <span>{saveDetail.createdAt ? setTimeFormat(saveDetail.createdAt) : "--"}</span>
        </div>
      )}
      {/* {!props.recentActivity && (
        <Button
          onClick={() => {
            if (!props.recentActivity) {
              props.history.push('/diamond-list?' + props.saveId);
            }
          }}
        >
          Open
        </Button>
      )} */}
    </div>
  );
};
export default withRouter(SavedSearchCard);
