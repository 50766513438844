import React from "react";

import { Dropdown, Tooltip } from "antd";
import _ from "lodash";

import { FILTER_COLUMNS } from "util/tableConstant";

import SortingFilterDropdown from "./OverlayDropdown";
// import { FILTER_COLUMNS } from './Table';

import closeSVG from "../../assets/svg/DiamondList/close.svg";
import shortingDownwhtSVG from "../../assets/svg/DiamondList/shortinglistdown-white.svg";
import shortingDownSVG from "../../assets/svg/DiamondList/shortinglistdown.svg";
import shortingUpwhtSVG from "../../assets/svg/DiamondList/shortinglistup-white.svg";
import shortingUpSVG from "../../assets/svg/DiamondList/shortinglistup.svg";
import sortingSVG from "../../assets/svg/DiamondList/sorting.svg";

const DiamondListingHead = (props) => {
  // const item = !props.sort ? [] : props.sort.filter((el) => el.hasOwnProperty(props.column.id));
  const item = !props.sort ? [] : props.sort.filter((el) => Object.prototype.hasOwnProperty.call(el, props.column.id));
  const asc = item.length && item[0][props.column.id] === "ASC";
  const desc = item.length && item[0][props.column.id] === "DESC";

  return (
    <Dropdown
      overlayClassName="tabelDropdownClass"
      overlay={
        (props.sort && props.sort.length) ||
        (props.FilterOption !== false && FILTER_COLUMNS.includes(props.column.id)) ? (
          <SortingFilterDropdown {...props} column={props.column.id} />
        ) : (
          <></>
        )
      }
      trigger={["click"]}
    >
      <div
        className={`tableHeaderMain ${props.nodots && "squeceChange"} ${
          props.canSort &&
          Object.prototype.hasOwnProperty.call(props.canSort, props.column.id) &&
          !(asc || desc) &&
          "shortingBoxShow"
        }`}
        onClick={(e) => e.preventDefault()}
      >
        {!props.nodots && (
          <span className="listIcon">
            <img src={sortingSVG} alt="icon" />
          </span>
        )}
        {props.column.desc ? (
          <Tooltip title={props.column.desc}>
            <span className="taleHeadTitle">{props.header}</span>
          </Tooltip>
        ) : (
          <span className="taleHeadTitle">{props.header}</span>
        )}
        {props.canSort && Object.prototype.hasOwnProperty.call(props.canSort, props.column.id) && (asc || desc) ? (
          <div className="selectShortingMainBlock">
            <div className="selectShorting" onClick={() => props.sortClick(props.column.id)}>
              <span className="selectShortingCount" style={!(asc || desc) ? { margin: 0 } : {}}>
                {props.sort.indexOf(item[0]) + 1}
              </span>
              {asc || desc ? (
                <span className="selectShortingArrow">
                  <img alt="" src={asc ? shortingUpwhtSVG : shortingDownwhtSVG} />
                </span>
              ) : null}
            </div>
            <div
              className="shortingClose"
              onClick={() => {
                const sort = [];
                const { id } = props.column;
                _.each(props.sort, (val) => {
                  if (!Object.prototype.hasOwnProperty.call(val, id)) {
                    sort.push(val);
                  }
                });

                // _.each(props.sort, (val) => {
                //   if (!val.hasOwnProperty(id)) sort.push(val);
                // });

                props.handleSort(sort);
              }}
            >
              <img src={closeSVG} alt="icon" />
            </div>
          </div>
        ) : null}
        {props.canSort && Object.prototype.hasOwnProperty.call(props.canSort, props.column.id) && !(asc || desc) && (
          <div className="shortingRound" onClick={() => props.sortClick(props.column.id)}>
            <div className="shortingRoundInner">
              <img src={shortingUpSVG} alt="icon" />
              <img src={shortingDownSVG} alt="icon" />
            </div>
          </div>
        )}
        {/* {props.canSort && props.canSort.hasOwnProperty(props.column.id) && !(asc || desc) && (
          <div className="shortingRound" onClick={() => props.sortClick(props.column.id)}>
            <div className="shortingRoundInner">
              <img src={shortingUpSVG} alt="icon" />
              <img src={shortingDownSVG} alt="icon" />
            </div>
          </div>
        )} */}
      </div>
    </Dropdown>
  );
};
export default DiamondListingHead;
