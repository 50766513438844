import React from "react";

import closeThemeSVG from "../../../assets/svg/DiamondList/close-theme.svg";

const MainFilterTop = (props) => {
  return (
    <div className={`searchInnerFilterItem ${props.activeClass}`}>
      <span className="searchInnerFilterTitle">{props.title}</span>
      <span className="searchMainFilterIcon filterView">
        <img src={props.eyeImage} alt="" />
      </span>
      {props.closeOff ? null : (
        <span
          onClick={(e) => {
            props.handleRemoveFilter(props.id);
            e.stopPropagation();
          }}
          className="searchMainFilterIcon filterClose"
        >
          <img src={closeThemeSVG} alt="" />
        </span>
      )}
    </div>
  );
};
export default MainFilterTop;
