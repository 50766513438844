import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import find from "lodash/find";

import Advertisement from "./Advertisement";
import Footer from "./Footer";
import QuickSearch from "./QuickSearch";

import { LOCAL_STORAGE_VAR, KYC_STATUS } from "../../constants/Common";
import { GET_BANNERS, GET_DASHBOARD_DATA } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { GuestUserPopup } from "../GuestUserPopup";
import { NoKycPop } from "../NoKycPop";
import "react-loading-skeleton/dist/skeleton.css";

export function setGridHeightFn() {
  const allContainers = {};
  let totalHeight = window.innerHeight - 18;
  allContainers.headerHeight = document.getElementsByClassName("headerTopMenu")[0];
  allContainers.covidStrip = document.getElementsByClassName("covidStrip")[0];
  allContainers.HeaderStripBlock = document.getElementsByClassName("HeaderStripBlock ")[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridHeight: 0,
      topBanner: [],
      otherBanners: [],
      load: true,
      isGuest: UtilService.getLocalStorageItem(
        window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
      ),
      showGuest: false,
      noKyc: false,
      isKyc: false,
      news: [],
    };
  }
  componentDidMount() {
    this.fetchdata();
    this.getCount();
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

    if (authUser && authUser.account) {
      if (!authUser.account.isKycUploaded) {
        this.setState({ isKyc: !authUser.account.isKycUploaded });
      } else {
        if (authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.authUser && nextProps.auth.authUser.account) {
      if (!nextProps.auth.authUser.account.isKycUploaded) {
        this.setState({ isKyc: !nextProps.auth.authUser.account.isKycUploaded });
      } else {
        if (nextProps.auth.authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
  }
  fetchdata = () => {
    this.setState({ load: true }, () => {
      const obj = {
        ...GET_BANNERS,

        request: {
          filter: { type: ["1", "2", "3", "4", "5"], isActive: true },
        },
      };
      UtilService.callApi(obj, (err, data) => {
        if (data && data.code === "OK") {
          if (data.data && data.data.list && data.data.list.length !== 0) {
            const bannerTop = find(data.data.list, {
              type: "1",
            });
            const otherBanner = data.data.list.filter((x) => x.type !== "1");
            this.setState({ otherBanners: otherBanner, topBanner: [{ ...bannerTop }], load: false });
          }
        }
      });
    });
  };
  getCount = () => {
    const self = this;
    const obj = {
      news: true,
    };
    const api = GET_DASHBOARD_DATA.url;
    const objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        if (data.data.news) {
          self.setState({ news: data.data.news });
        }
        self.setState({ seller: data.data.seller });
      }
    });
  };
  render() {
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;

    return (
      <>
        <div className="homePageMainWrapper">
          {!this.state.load ? (
            <div className="dashboardTopBlock d-flex j-space-between mb-20">
              <Advertisement
                other={this.state.otherBanners && this.state.otherBanners.length !== 0 ? this.state.otherBanners : []}
                main={this.state.topBanner && this.state.topBanner.length !== 0 ? this.state.topBanner : []}
              />
            </div>
          ) : (
            <Skeleton height={597} />
          )}
          {/* <div className="quickMemuWrapper">
            <Heading title={<IntlMessage id="app.quickActions" />} className="text-center dashboardTitle" />
            <QuickMenu
              isGuest={this.state.isGuest}
              showGuest={() => {
                this.setState({ showGuest: true });
              }}
              isKyc={this.state.isKyc}
              showKyc={() => {
                this.setState({ noKyc: true });
              }}
            />
          </div> */}
          <div className="dashboardQuickSearchMainBlock" id="step5">
            <QuickSearch dashboard />
          </div>

          {/* <div className="dashboardCardBoxMain">
            <div className=" homeRightInner dashboardInnerLeft mb-10">
              <News newsList={this.state.news} />
            </div>
            <div className=" homeRightInner">
              <KAMDetail kam sale={{ salesPerson: { ...this.state.seller } }} />
            </div>
          </div> */}
        </div>
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
        {this.state.noKyc && (
          <NoKycPop
            status={authUser.isApproved}
            visible={this.state.noKyc}
            handleCancel={() => {
              this.setState({ noKyc: false });
            }}
          />
        )}
        <Footer />
      </>
    );
  }
}
export default connect((props) => {
  return props;
}, {})(withRouter(Home));
