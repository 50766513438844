import React, { useState, useEffect } from "react";

import { Slider } from "antd";

import IntlMessage from "util/IntlMessage";

import lftArrSVG from "../../../assets/svg/Dashboard/left-arrow.svg";
import downArrow from "../../../assets/svg/Search/down-arrow.svg";
import upArrow from "../../../assets/svg/Search/up-arrow.svg";

const marks = {
  0.01: "0.01",
  0.99: "0.99",
};
const marks1 = {
  1: "1.00",
  1.99: "1.99",
};
// const marks12 = {
//   0.01: '0.01',
//   1.99: '1.99',
// };
const marks2 = {
  2: "2.00",
  2.99: "2.99",
};
// const marks123 = {
//   0.01: '0.01',
//   2.99: '2.99',
// };
const marks3 = {
  3: "3.00",
  3.99: "3.99",
};
// const marks1234 = {
//   0.01: '0.01',
//   3.99: '3.99',
// };
const marks4 = {
  4: "4.00",
  4.99: "4.99",
};
// const marks12345 = {
//   0.01: '0.01',
//   4.99: '4.99',
// };
const marks5 = {
  5: "5.00",
  100: "100.00",
};
// const marksaall = {
//   0.01: '0.01',
//   100: '100.00',
// };

const CaratRange = (props) => {
  const [carat1, SetCarat1] = useState(false);
  const [carat1Val, SetCarat1Val] = useState([0.01, 0.99]);
  const [carat2, SetCarat2] = useState(false);
  const [carat2Val, SetCarat2Val] = useState([1.0, 1.99]);
  const [carat3, SetCarat3] = useState(false);
  const [carat3Val, SetCarat3Val] = useState([2.0, 2.99]);
  const [carat4, SetCarat4] = useState(false);
  const [carat4Val, SetCarat4Val] = useState([3.0, 3.99]);
  const [carat5, SetCarat5] = useState(false);
  const [carat5Val, SetCarat5Val] = useState([4.0, 4.99]);
  const [carat6, SetCarat6] = useState(false);
  const [carat6Val, SetCarat6Val] = useState([5.0, 100]);

  const [caratArray, SetCaratArray] = useState([]);

  const [caratRangeExpand, SetcaratRangeExpand] = useState(false);
  const [infoCarat, setInfoCarat] = useState(false);
  const [infoCaratVal, setInfoCaratVal] = useState([0.01, 100]);
  const [infoCaratValTemp, setInfoCaratValTemp] = useState([0.01, 100]);
  // const setReset = () => {
  //   SetCarat1(false);
  //   SetCarat2(false);
  //   SetCarat3(false);
  //   SetCarat4(false);
  //   SetCarat5(false);
  //   SetCarat6(false);
  // };
  useEffect(() => {
    // setReset()
    if (props.preCrt !== undefined && props.preCrt !== null && props.preCrt.length !== 0) {
      props.setIds(props.preCrt);
      SetCaratArray(props.preCrt);
      const temCal = [];
      props.preCrt.forEach((fl) => {
        if (fl.crt) {
          if (Number(fl.crt[">="]) >= 0.01 && Number(fl.crt["<="]) <= 0.99) {
            SetCarat1(true);
            SetCarat1Val([Number(fl.crt[">="]), Number(fl.crt["<="])]);
          }
          if (Number(fl.crt[">="]) >= 1.0 && Number(fl.crt["<="]) <= 1.99) {
            SetCarat2(true);
            SetCarat2Val([Number(fl.crt[">="]), Number(fl.crt["<="])]);
          }
          if (Number(fl.crt[">="]) >= 2.0 && Number(fl.crt["<="]) <= 2.99) {
            SetCarat3(true);
            SetCarat3Val([Number(fl.crt[">="]), Number(fl.crt["<="])]);
          }
          if (Number(fl.crt[">="]) >= 3.0 && Number(fl.crt["<="]) <= 3.99) {
            SetCarat4(true);
            SetCarat4Val([Number(fl.crt[">="]), Number(fl.crt["<="])]);
          }
          if (Number(fl.crt[">="]) >= 4.0 && Number(fl.crt["<="]) <= 4.99) {
            SetCarat5(true);
            SetCarat5Val([Number(fl.crt[">="]), Number(fl.crt["<="])]);
          }
          if (Number(fl.crt[">="]) >= 5.0 && Number(fl.crt["<="]) <= 100) {
            SetCarat6(true);
            SetCarat6Val([Number(fl.crt[">="]), Number(fl.crt["<="])]);
          }
          temCal.push(fl.crt[">="]);
          temCal.push(fl.crt["<="]);
        }
      });
      const sorted = temCal.slice().sort((a, b) => {
        return a - b;
      });
      const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];
      setInfoCaratVal([...defaultArray]);
      setInfoCaratValTemp([...defaultArray]);
    }
  }, [props, props.preCrt]);
  const moreLess = () => {
    SetcaratRangeExpand(!caratRangeExpand);
  };
  const carat1Click = (bool, crt, index) => {
    let tempArray = caratArray;
    if (bool) {
      tempArray.splice(index, 0, crt);
    } else if (tempArray && tempArray.length !== 0) {
      const tempo = [];
      tempArray.forEach((tem) => {
        if (tem.crt) {
          if (tem.crt["<="] <= crt.crt["<="] && tem.crt[">="] >= crt.crt[">="]) {
            // tempArray.splice(ind, 1)
          } else {
            tempo.push(tem);
          }
        }
      });
      tempArray = tempo;
    }
    if (tempArray && tempArray.length !== 0) {
      const temCal = [];

      tempArray.forEach((te) => {
        temCal.push(te.crt[">="]);
        temCal.push(te.crt["<="]);
      });
      const sorted = temCal.slice().sort((a, b) => {
        return a - b;
      });
      const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];

      setInfoCaratVal([...defaultArray]);
      setInfoCaratValTemp([...defaultArray]);
    }

    SetCaratArray([...tempArray]);
    SetCarat1(bool);
    props.setcaratIds([...tempArray]);
  };
  const carat2Click = (bool, crt, index) => {
    const tempArray = caratArray;
    if (bool) {
      tempArray.splice(index, 0, crt);
    } else if (tempArray && tempArray.length !== 0) {
      tempArray.forEach((tem, ind) => {
        if (tem.crt && tem.crt["<="] <= crt.crt["<="] && tem.crt[">="] >= crt.crt[">="]) {
          tempArray.splice(ind, 1);
        }
      });
    }
    if (tempArray && tempArray.length !== 0) {
      const temCal = [];

      tempArray.forEach((te) => {
        temCal.push(te.crt[">="]);
        temCal.push(te.crt["<="]);
      });
      const sorted = temCal.slice().sort((a, b) => {
        return a - b;
      });
      const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];

      setInfoCaratVal([...defaultArray]);
      setInfoCaratValTemp([...defaultArray]);
    }

    SetCaratArray([...tempArray]);
    props.setcaratIds([...tempArray]);

    SetCarat2(!carat2);
  };
  const carat3Click = (bool, crt, index) => {
    const tempArray = caratArray;
    if (bool) {
      tempArray.splice(index, 0, crt);
    } else if (tempArray && tempArray.length !== 0) {
      tempArray.forEach((tem, ind) => {
        if (tem.crt && tem.crt["<="] <= crt.crt["<="] && tem.crt[">="] >= crt.crt[">="]) {
          tempArray.splice(ind, 1);
        }
      });
    }
    if (tempArray && tempArray.length !== 0) {
      const temCal = [];

      tempArray.forEach((te) => {
        temCal.push(te.crt[">="]);
        temCal.push(te.crt["<="]);
      });
      const sorted = temCal.slice().sort((a, b) => {
        return a - b;
      });
      const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];

      setInfoCaratVal([...defaultArray]);
      setInfoCaratValTemp([...defaultArray]);
    }

    SetCaratArray([...tempArray]);
    props.setcaratIds([...tempArray]);

    SetCarat3(bool);
  };
  const carat4Click = (bool, crt, index) => {
    const tempArray = caratArray;
    if (bool) {
      tempArray.splice(index, 0, crt);
    } else if (tempArray && tempArray.length !== 0) {
      tempArray.forEach((tem, ind) => {
        if (tem.crt && tem.crt["<="] <= crt.crt["<="] && tem.crt[">="] >= crt.crt[">="]) {
          tempArray.splice(ind, 1);
        }
      });
    }
    if (tempArray && tempArray.length !== 0) {
      const temCal = [];

      tempArray.forEach((te) => {
        temCal.push(te.crt[">="]);
        temCal.push(te.crt["<="]);
      });
      const sorted = temCal.slice().sort((a, b) => {
        return a - b;
      });
      const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];

      setInfoCaratVal([...defaultArray]);
      setInfoCaratValTemp([...defaultArray]);
    }

    SetCaratArray([...tempArray]);
    props.setcaratIds([...tempArray]);

    SetCarat4(bool);
  };
  const carat5Click = (bool, crt, index) => {
    const tempArray = caratArray;
    if (bool) {
      tempArray.splice(index, 0, crt);
    } else if (tempArray && tempArray.length !== 0) {
      tempArray.forEach((tem, ind) => {
        if (tem.crt && tem.crt["<="] <= crt.crt["<="] && tem.crt[">="] >= crt.crt[">="]) {
          tempArray.splice(ind, 1);
        }
      });
    }
    if (tempArray && tempArray.length !== 0) {
      const temCal = [];

      tempArray.forEach((te) => {
        temCal.push(te.crt[">="]);
        temCal.push(te.crt["<="]);
      });
      const sorted = temCal.slice().sort((a, b) => {
        return a - b;
      });
      const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];

      setInfoCaratVal([...defaultArray]);
      setInfoCaratValTemp([...defaultArray]);
    }

    SetCaratArray([...tempArray]);
    props.setcaratIds([...tempArray]);

    SetCarat5(bool);
  };
  const carat6Click = (bool, crt, index) => {
    const tempArray = caratArray;
    if (bool) {
      tempArray.splice(index, 0, crt);
    } else if (tempArray && tempArray.length !== 0) {
      tempArray.forEach((tem, ind) => {
        if (tem.crt && tem.crt["<="] <= crt.crt["<="] && tem.crt[">="] >= crt.crt[">="]) {
          tempArray.splice(ind, 1);
        }
      });
    }
    if (tempArray && tempArray.length !== 0) {
      const temCal = [];

      tempArray.forEach((te) => {
        temCal.push(te.crt[">="]);
        temCal.push(te.crt["<="]);
      });
      const sorted = temCal.slice().sort((a, b) => {
        return a - b;
      });
      const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];

      setInfoCaratVal([...defaultArray]);
      setInfoCaratValTemp([...defaultArray]);
    }

    SetCaratArray([...tempArray]);
    props.setcaratIds([...tempArray]);

    SetCarat6(bool);

    // SetCarat1(true);
    // SetCarat2(true);
    // SetCarat3(true);
    // SetCarat4(true);
    // SetCarat5(true);
  };
  const onChange = (value, arrVal) => {
    const tem = [...caratArray];
    if (tem && tem.length !== 0) {
      tem.forEach((t, ind) => {
        if (t.crt) {
          if (t.crt[">="] >= arrVal[0] && t.crt["<="] <= arrVal[1]) {
            tem.splice(ind, 1);
          }
        }
      });
    }
    tem.push({ crt: { ">=": value[0], "<=": value[1] } });
    const temCal = [];
    tem.forEach((te) => {
      temCal.push(te.crt[">="]);
      temCal.push(te.crt["<="]);
    });
    const sorted = temCal.slice().sort((a, b) => {
      return a - b;
    });
    const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];

    setInfoCaratVal([...defaultArray]);
    props.setcaratIds([...tem]);
    SetCaratArray([...tem]);
  };
  const onChangeSingle = (value) => {
    const tem = [{ crt: { ">=": value[0], "<=": value[1] } }];
    props.setcaratIds([...tem]);
  };
  return (
    <div className={`dashquickSearchItem d-flex align-items-center ${props.diamondSearch && "diamondSearchItem"}`}>
      {props.titleHide ? null : (
        <div className="dashquickSearchLabel">
          <span>
            <IntlMessage id="app.carat" />
            <span onClick={() => setInfoCarat(!infoCarat)} title="Show Carats" style={{ cursor: "pointer" }}>
              {infoCarat ? (
                <img alt="info" height="35px" width="16px" style={{ marginLeft: "8px" }} src={upArrow} />
              ) : (
                <img alt="info" height="35px" width="16px" style={{ marginLeft: "8px" }} src={downArrow} />
              )}
            </span>
          </span>
        </div>
      )}
      <div className="dashquickSearchBlock d-flex align-items-center j-space-between flex-wrap">
        <div className="dashquickSearchCarat">
          <ul className="d-flex">
            <li
              className={`d-flex align-items-center ${carat1 ? "active" : ""}`}
              onClick={() => carat1Click(!carat1, { crt: { ">=": 0.01, "<=": 0.99 } }, 0)}
            >
              <img src={lftArrSVG} alt="" />
              1
            </li>
            <li
              className={carat2 ? "active" : ""}
              onClick={() => carat2Click(!carat2, { crt: { ">=": 1.0, "<=": 1.99 } }, 1)}
            >
              1
            </li>
            <li
              className={carat3 ? "active" : ""}
              onClick={() => carat3Click(!carat3, { crt: { ">=": 2.0, "<=": 2.99 } }, 2)}
            >
              2
            </li>
            <li
              className={carat4 ? "active" : ""}
              onClick={() => carat4Click(!carat4, { crt: { ">=": 3.0, "<=": 3.99 } }, 3)}
            >
              3
            </li>
            <li
              className={carat5 ? "active" : ""}
              onClick={() => carat5Click(!carat5, { crt: { ">=": 4.0, "<=": 4.99 } }, 4)}
            >
              4
            </li>
            <li
              className={carat6 ? "active" : ""}
              onClick={() => carat6Click(!carat6, { crt: { ">=": 5.0, "<=": 100 } }, 5)}
            >
              5+
            </li>
          </ul>
        </div>
        <div className="pr-10 caratSlider">
          {!infoCarat ? (
            carat1 || carat2 || carat3 || carat4 || carat5 || carat6 ? (
              <Slider
                range={{ draggableTrack: true }}
                marks={{
                  [Number(infoCaratValTemp[0])]: `${infoCaratValTemp[0]}`,
                  [Number(infoCaratValTemp[1])]: `${infoCaratValTemp[1]}`,
                }}
                value={infoCaratVal}
                onAfterChange={(e) => onChangeSingle(e)}
                onChange={(e) => {
                  setInfoCaratVal([...e]);
                }}
                step={0.01}
                tooltipPlacement="bottom"
                min={Number(infoCaratValTemp[0])}
                max={Number(infoCaratValTemp[1])}
                tipFormatter={(value) => {
                  return value;
                }}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {infoCarat ? (
            carat1 || carat2 || carat3 || carat4 || carat5 || carat6 ? (
              <>
                {carat1 && (
                  <Slider
                    onAfterChange={(e) => onChange(e, [0.01, 0.99])}
                    range={{ draggableTrack: true }}
                    marks={marks}
                    value={carat1Val}
                    onChange={(e) => {
                      SetCarat1Val(e);
                    }}
                    step={0.01}
                    tooltipPlacement="bottom"
                    min={0.01}
                    max={0.99}
                  />
                )}
                {carat2 && (
                  <Slider
                    onAfterChange={(e) => onChange(e, [1, 1.99])}
                    range={{ draggableTrack: true }}
                    marks={marks1}
                    value={carat2Val}
                    onChange={(e) => {
                      SetCarat2Val(e);
                    }}
                    step={0.01}
                    tooltipPlacement="bottom"
                    min={1}
                    max={1.99}
                  />
                )}
                {carat3 && (
                  <Slider
                    onAfterChange={(e) => onChange(e, [2, 2.99])}
                    range={{ draggableTrack: true }}
                    marks={marks2}
                    value={carat3Val}
                    onChange={(e) => {
                      SetCarat3Val(e);
                    }}
                    step={0.01}
                    tooltipPlacement="bottom"
                    min={2}
                    max={2.99}
                  />
                )}
                {carat4 && (
                  <Slider
                    onAfterChange={(e) => onChange(e, [3, 3.99])}
                    range={{ draggableTrack: true }}
                    marks={marks3}
                    value={carat4Val}
                    onChange={(e) => {
                      SetCarat4Val(e);
                    }}
                    step={0.01}
                    tooltipPlacement="bottom"
                    min={3}
                    max={3.99}
                  />
                )}
                {carat5 && (
                  <Slider
                    onAfterChange={(e) => onChange(e, [4, 4.99])}
                    range={{ draggableTrack: true }}
                    marks={marks4}
                    value={carat5Val}
                    onChange={(e) => {
                      SetCarat5Val(e);
                    }}
                    step={0.01}
                    tooltipPlacement="bottom"
                    min={4}
                    max={4.99}
                  />
                )}
                {carat6 && (
                  <Slider
                    onAfterChange={(e) => onChange(e, [5, 100])}
                    range={{ draggableTrack: true }}
                    marks={marks5}
                    value={carat6Val}
                    onChange={(e) => {
                      SetCarat6Val(e);
                    }}
                    step={0.01}
                    tooltipPlacement="bottom"
                    min={5}
                    max={100}
                  />
                )}
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {/* {!infoCarat ?
            carat1 || carat2 || carat3 || carat4 || carat5 || carat6 ?
              <Slider
                range
                marks={infoCaratObj}
                value={[...infoCaratVal]}
                onChange={(e) => {
                  onChange(e, [infoCaratVal[0], infoCaratVal[1]])
                  setInfoCaratVal([...e])

                }}
                step={0.01}

                tooltipPlacement='bottom'
                min={infoCaratObj[Object.keys(infoCaratObj)[0]]}
                max={infoCaratObj[Object.keys(infoCaratObj)[1]]}
              />
              : ''
            : <>
              {false && carat1 && carat2 && carat3 && carat4 && carat5 && carat6 ? (
                <Slider
                  range
                  marks={marksaall}
                  defaultValue={[0.01, 100]}
                  onChange={(e) => onChange(e, [0.01, 100])}
                  step={0.01}

                  tooltipPlacement='bottom'
                  min={0.01}
                  max={100}
                />
              ) : (
                  <>
                    {false && carat1 && carat2 && carat3 && carat4 && carat5 ? (
                      <Slider
                        range
                        marks={marks12345}
                        defaultValue={[0.01, 4.99]}
                        onChange={(e) => onChange(e, [0.01, 4.99])}

                        step={0.01}

                        tooltipPlacement='bottom'
                        min={0.01}
                        max={4.99}
                      />
                    ) : (
                        <>
                          {false && carat1 && carat2 && carat3 && carat4 ? (
                            <Slider
                              range
                              marks={marks1234}
                              defaultValue={[0.01, 3.99]}
                              onChange={(e) => onChange(e, [0.01, 3.99])}

                              step={0.01}

                              tooltipPlacement='bottom'
                              min={0.01}
                              max={3.99}
                            />
                          ) : (
                              <>
                                {false && carat1 && carat2 && carat3 ? (
                                  <Slider
                                    range
                                    marks={marks123}
                                    defaultValue={[0.01, 2.99]}
                                    onChange={(e) => onChange(e, [0.01, 2.99])}

                                    step={0.01}

                                    tooltipPlacement='bottom'
                                    min={0.01}
                                    max={2.99}
                                  />
                                ) : (
                                    <>
                                      {false && carat1 && carat2 ? (
                                        <Slider
                                          range
                                          marks={marks12}
                                          defaultValue={[0.01, 1.99]}
                                          onChange={(e) => onChange(e, [0.01, 1.99])}

                                          step={0.01}

                                          tooltipPlacement='bottom'
                                          min={0.01}
                                          max={1.99}
                                        />
                                      ) : (
                                          <>
                                            {carat1 && (
                                              <Slider
                                                range
                                                marks={marks}
                                                defaultValue={[0.01, 0.99]}
                                                onChange={(e) => onChange(e, [0.01, 0.99])}

                                                step={0.01}

                                                tooltipPlacement='bottom'
                                                min={0.01}
                                                max={0.99}
                                              />
                                            )}
                                            {carat2 && (
                                              <Slider
                                                range
                                                marks={marks1}
                                                defaultValue={[1.00, 1.99]}
                                                onChange={(e) => onChange(e, [1, 1.99])}

                                                step={0.01}

                                                tooltipPlacement='bottom'
                                                min={1}
                                                max={1.99}
                                              />
                                            )}
                                          </>
                                        )}
                                      {carat3 && (
                                        <Slider
                                          range
                                          marks={marks2}
                                          defaultValue={[2.00, 2.99]}
                                          onChange={(e) => onChange(e, [2, 2.99])}

                                          step={0.01}

                                          tooltipPlacement='bottom'
                                          min={2}
                                          max={2.99}
                                        />
                                      )}
                                    </>
                                  )}
                                {carat4 && (
                                  <Slider
                                    range
                                    marks={marks3}
                                    defaultValue={[3.00, 3.99]}
                                    onChange={(e) => onChange(e, [3, 3.99])}

                                    step={0.01}

                                    tooltipPlacement='bottom'
                                    min={3}
                                    max={3.99}
                                  />
                                )}
                              </>
                            )}
                          {carat5 && (
                            <Slider
                              range
                              marks={marks4}
                              defaultValue={[4.00, 4.99]}
                              onChange={(e) => onChange(e, [4, 4.99])}

                              step={0.01}

                              tooltipPlacement='bottom'
                              min={4}
                              max={4.99}
                            />
                          )}
                        </>
                      )}
                    {carat6 && (
                      <Slider
                        range
                        marks={marks5}
                        defaultValue={[5.00, 100]}
                        onChange={(e) => onChange(e, [5, 100])}

                        step={0.01}

                        tooltipPlacement='bottom'
                        min={5}
                        max={100}
                      />
                    )}
                  </>
                )}
            </>

          } */}

          {props.diamondSearch && (
            <div className={`diamondSearchDetail ${caratRangeExpand && "active"}`}>
              <ul className="diamondSearchList caratRangeMobileBlock">
                <li>0.01 - 0.29</li>
                <li>0.30 - 0.39</li>
                <li>0.40 - 0.49</li>
                <li>0.50 - 0.69</li>
                <li>0.70 - 0.89</li>
                <li>0.90 - 0.99</li>
                <li>1.00 - 1.49</li>
                <li>1.50 - 1.99</li>
                <li>2.00 - 2.99</li>
                <li>3.00 - 4.99</li>
                <li>5.00 - 100</li>
              </ul>
              <div className="shapeMoewLess" onClick={moreLess}>
                {!caratRangeExpand ? (
                  <>
                    <b>More</b>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <b>Less</b>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CaratRange);
