import React from "react";
import "./upDownBox.less";

import dnArrSVG from "../../../assets/svg/Header/down-arrow.svg";
import upArrSVG from "../../../assets/svg/Header/down-arrow.svg";

const UpDownBox = (props) => {
  return (
    <div className={`downArrow ${props.downArrow && "upArrow"}`} onClick={props.onClick}>
      {props.downArrow ? (
        <img className="closeImage" src={dnArrSVG} alt="" />
      ) : (
        <img className="closeImage" src={upArrSVG} alt="" />
      )}
    </div>
  );
};
export default UpDownBox;
