import React, { Component } from "react";
import { connect } from "react-redux";

import IntlMessage from "util/IntlMessage";

import OpenNotification from "../common/CommonButton/OpenNotification";

class InputRange extends Component {
  state = { from: "", to: "", isChanged: false };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.commonData.reset && nextProps.commonData.reset.searchReset) {
      if (this.props.commonData.reset !== nextProps.commonData.reset) {
        this.setState({ from: "", to: "" });
      }
    }

    if (nextProps.commonData.modifySearch && nextProps.commonData.modifySearch[this.props.apiKey]) {
      if (this.props.commonData.modifySearch !== nextProps.commonData.modifySearch) {
        const from = nextProps.commonData.modifySearch[this.props.apiKey][">="];
        const to = nextProps.commonData.modifySearch[this.props.apiKey]["<="];

        this.setState({ from, to }, () => {
          this.onChange("from", from);
          this.onChange("to", to);
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.commonData.modifySearch && this.props.commonData.modifySearch[this.props.apiKey]) {
      const from = this.props.commonData.modifySearch[this.props.apiKey][">="];
      const to = this.props.commonData.modifySearch[this.props.apiKey]["<="];

      this.setState({ from, to }, () => {
        this.onChange("from", from);
        this.onChange("to", to);
      });
    }
  }

  onMinChange = (e) => {
    this.onChange("from", e.target.value);
  };

  onMaxChange = (e) => {
    this.onChange("to", e.target.value);
  };

  onChange = (field, value) => {
    this.setState({ [field]: value, isChanged: true });
  };

  validate = () => {
    const { Min, Max, allowZero = false, allowSame = true } = this.props;
    const { from, to } = this.state;
    if ((from && to) || (parseInt(from) === 0 && to)) {
      if (parseInt(from) < Min || parseInt(to) > Max) {
        this.setState({ from: "", to: "" });
        this.props.selectedRange("", "", this.props.apiKey);
        return OpenNotification({
          type: "error",
          title: `Must enter between ${Min} to ${Max}.`,
        });
      }
      if (allowSame ? parseInt(from) > parseInt(to) : parseInt(from) >= parseInt(to)) {
        this.setState((prevState) => ({
          from: parseInt(from) > Max ? "" : prevState.from,
          to: "",
          isChanged: true,
        }));
        this.props.selectedRange("", "", this.props.apiKey);
        return OpenNotification({
          type: "error",
          title: "To must be greater than from.",
        });
      }
      this.props.selectedRange(from, to, this.props.apiKey);
    }
    if (parseInt(to) < Min || parseInt(to) > Max) {
      this.setState((prevState) => ({
        from: prevState.from,
        to: "",
        isChanged: true,
      }));
      this.props.selectedRange("", "", this.props.apiKey);
      return OpenNotification({
        type: "error",
        title: `Must enter between ${Min} to ${Max}.`,
      });
    }
    if (!allowZero && parseInt(from) === 0 && parseInt(to) === 0) {
      this.setState({ from: "", to: "" });
      this.props.selectedRange("", "", this.props.apiKey);
      return OpenNotification({
        type: "error",
        title: `Must enter between ${Min} to ${Max}.`,
      });
    }
    if ((!from || !to || from === "" || to === "") && this.state.isChanged) {
      this.props.selectedRange("", "", this.props.apiKey);
    }
  };

  render() {
    const { noNegative, Min } = this.props;

    return (
      <div className="diamondSearchInnerblock">
        <label className="smallLabel">{this.props.title}</label>
        <div className="diamondSearchCaratRange">
          <div className="fromToValue">
            <div className="fromToValueInput">
              <label>{<IntlMessage id="app.from" />}</label>
              <input
                type="number"
                value={this.state.from}
                onChange={(e) => this.onMinChange(e)}
                onBlur={() => this.validate()}
                min={Min}
                onKeyDown={(e) => {
                  if (e.keyCode === 189 || e.keyCode === 109) {
                    // Dash sign prevent
                    noNegative && e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="fromToValueInput">
              <label>{<IntlMessage id="app.to" />}</label>
              <input
                type="number"
                value={this.state.to}
                onChange={(e) => this.onMaxChange(e)}
                onBlur={() => this.validate()}
                min={Min}
                onKeyDown={(e) => {
                  if (e.keyCode === 189 || e.keyCode === 109) {
                    // Dash sign prevent
                    noNegative && e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// NEVER PULL IRRELEVANT REDUCERS
// const mapStateToProps = (props) => {
//   return props;
// };
export default connect(({ commonData }) => ({ commonData }))(InputRange);
