import React from "react";
import { useSelector } from "react-redux";

import { Select } from "antd";
import PropTypes from "prop-types";

import { handleTrackDelete } from "components/DiamondList/DiamondListFunctions";
import DiamondListingAction from "components/common/DiamondListing/DiamondListingAction";
// import QuotePopup from 'components/common/DiamondListing/QuotePopup';
import Heading from "components/common/Heading";
import Loader from "components/common/Loader";
import NoDataFound from "components/common/NoDataFound";

import { OfferService } from "services/OfferService";
import { RowSelectService } from "services/RowSelectService";

import { getStoneSummary } from "util/_extra-utils";
import { useList, useLoading, usePagination } from "util/hooks";
// import { notify } from 'util/notify';
import { prepareStoneForBargain } from "util/stone-utils";
import { isEmpty, isNumber, classNames , formatCurrency, formatDateTime, formatDecimal } from "util/utils";

import { TRACK_TYPES } from "constants/Common";
import { OFFER_STATUS_LABEL_MAP } from "constants/offer.consts";

import deleteSvg from "../../assets/svg/Account/delettable.svg";
import editSvg from "../../assets/svg/Account/edit.svg";

const statusDropDownOptions = (() => {
  return Object.entries(OFFER_STATUS_LABEL_MAP).map(([value, label]) => {
    value = Number(value);
    return { value, label };
  });
})();

const OfferListItem = React.memo((props) => {
  const { currentType, record, inTrackList, getList, setState, clearAllSelectedRows } = props;
  const stoneDetails = React.useMemo(() => getStoneSummary(record), [record]);
  const backDep = record && record?.original?.back;
  const ctprDep = record && record?.original?.ctPr;
  const isFcColDep = record && record?.original?.isFcCol;

  const discPerCtPr = React.useMemo(() => {
    return (
      <>
        {!isFcColDep && isNumber(Number(backDep)) ? formatDecimal(backDep) : "-"}
        &nbsp;/&nbsp;
        {isNumber(Number(ctprDep)) ? formatDecimal(ctprDep) : "-"}
      </>
    );
  }, [backDep, ctprDep, isFcColDep]);

  const bargainTrackDep = record?.bargainTrack;
  const trackPricePerCaratDep = record?.defaultTrack?.trackPricePerCarat;
  const isFcColDeps = record?.isFcCol;
  const bargain = React.useMemo(() => {
    if (isEmpty(record.bargainTrack)) return;

    return bargainTrackDep.map((bargain, index) => {
      const admin = [1, 2, 8].includes(bargain?.userType);
      return (
        <span key={index} className={classNames(["offerPrice"])} style={{ color: admin ? "#04b304" : "#ff0000" }}>
          {!isFcColDeps
            ? ` | ${`${formatDecimal(bargain?.trackDiscount ?? 0)  }%`} |
               ${formatCurrency(bargain?.trackPricePerCarat ?? 0)} |
               ${formatCurrency(bargain?.trackAmount ?? 0)}`
            : `${formatCurrency(trackPricePerCaratDep)}`}
        </span>
      );
    });
  }, [bargainTrackDep, trackPricePerCaratDep, isFcColDeps]);

  const offerPerCtPr = React.useMemo(() => {
    if (isEmpty(record.currentValidTrack)) return;

    return (
      <>
        {!record.original.isFcCol && isNumber(Number(record.currentValidTrack?.trackDiscount))
          ? formatDecimal(record.currentValidTrack?.trackDiscount)
          : "-"}
        &nbsp;/&nbsp;
        {isNumber(Number(record.currentValidTrack?.trackPricePerCarat))
          ? formatDecimal(record.currentValidTrack?.trackPricePerCarat)
          : "-"}
      </>
    );
  }, [record.currentValidTrack, record.original.isFcCol]);

  const isSelected = useSelector((state) => {
    const idList = state.diamondData.selectedRowIds?.[props.currentType] ?? [];
    return idList.includes(record.id);
  });

  const toggleSelection = React.useCallback(() => {
    // if (record?.disableSelection) {
    //   if (record?.onCheckError) notify.error({ message: record?.onCheckError });
    //   return;
    // }
    isSelected
      ? RowSelectService.unSelectRows(currentType, [record.original])
      : RowSelectService.selectRows(currentType, [record.original]);
  }, [currentType, isSelected, record.original]);

  const client = [4, 10].includes(record?.defaultTrack?.userType);
  const admin = [1, 2, 8].includes(record?.defaultTrack?.userType);
  // const offer = () => {
  //   return <QuotePopup detail={true} checked={[record]} />;
  // };

  return (
    <div className="mobileGroupingCard" key={record.id}>
      <div className="mobileGroupingHead">{record.groupTitle}</div>
      <div className="mobileGroupingList">
        <div className={classNames(["diamodListItemWrapper green null quotePageWrapper", isSelected && "active"])}>
          <div onClick={toggleSelection}>
            <div className="mobileListHeader">
              <div className="mobileListHeaderBlock">
                <div className="mobileListItem basicDetail">
                  <span>{stoneDetails}</span>
                </div>
                <div className="mobileListItem">
                  <span>
                    Amount:
                    {formatCurrency(record.original?.amt) || "-"}
                  </span>
                </div>
              </div>
              <div className="mobileListHeaderBlock">
                <div className="mobileListItem">
                  <span>
                    Disc % / Price/CT:&nbsp;
                    {discPerCtPr}
                  </span>
                </div>
                {/* <div className="mobileListItem">
                  <span>Offer % / Price/CT:&nbsp;{offerPerCtPr}</span>
                </div> */}
              </div>
            </div>
            <div className="mobileListHeaderBlock">
              <div className="mobileListItem">
                <span>
                  Disc % / Price/CT:&nbsp;
                  {discPerCtPr}
                </span>
              </div>
              {offerPerCtPr && (
                <div className="mobileListItem">
                  <span>
                    Offer % / Price/CT:&nbsp;
                    {offerPerCtPr}
                  </span>
                </div>
              )}
            </div>
            <div className="mobileListHeaderBlock">
              <div className="mobileListItem">
                <span
                  className={classNames(["offerPrice", admin && "admin", client && "client"])}
                  style={{ color: admin ? "#04b304" : "#ff0000" }}
                >
                  <strong style={{ color: "black" }}>Bargaining:&nbsp;</strong>
                  {`${formatDecimal(record?.defaultTrack?.trackDiscount)  }%`}
                  {' '}
&nbsp;|&nbsp;
                  {formatCurrency(record?.defaultTrack?.trackPricePerCarat ?? 0)}
&nbsp;|&nbsp;
                  {formatCurrency(record?.defaultTrack?.trackAmount)}
                  {bargain}
                </span>
              </div>
            </div>
          </div>
          <div className="mobileDiamondListBottom">
            <div className="mobileListHeaderBlock">
              <div className="mobileListItem">
                <span>
                  <strong>Expires At:</strong> 
                  {' '}
                  {formatDateTime(record.offerValidDate) || "-"}
                </span>
              </div>
              <div className="button-icon">
                {record?.offerStatus === 1 && (
                  <div className="actionButton">
                    <div
                      onClick={() => {
                        setState({ editOffer: [record] });
                      }}
                      className="actionAction"
                    >
                      <img src={editSvg} alt="" />
                    </div>
                    <div
                      onClick={() => {
                        handleTrackDelete(TRACK_TYPES.QUOTE, [record.id], inTrackList, () => {
                          clearAllSelectedRows();
                          getList();
                        });
                      }}
                      className="actionAction"
                    >
                      <img src={deleteSvg} alt="" />
                    </div>
                  </div>
                )}
                {/* <div className="actionAction"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function OfferList(props) {
  const [loading, startLoading, stopLoading] = useLoading();
  const [list, count, setList, resetList] = useList();
  const [page, limit, setPagination] = usePagination();
  const [inTrackList, setInTrackList] = React.useState([]);
  const [status, setStatus] = React.useState(1);
  // const [total, setTotal] = React.useState({});
  const { location, history } = props;

  const currentType = React.useMemo(() => {
    return (history.location.state && history.location.state.currentType) || location.pathname;
  }, [history.location.state, location.pathname]);
  const requestPayload = React.useMemo(() => {
    return { page, limit, offerStatus: status, trackType: TRACK_TYPES.QUOTE };
  }, [limit, page, status]);

  const getList = React.useCallback(async () => {
    props.setState({ editOffer: null });
    resetList();
    startLoading();
    const res = await OfferService.getOfferList(requestPayload);
    setList(
      res.list.map((stone) => prepareStoneForBargain(stone, { noModify: true })),
      res.count
    );
    // setTotal(res.total);
    setInTrackList(res.inTrackDiamonds);
    stopLoading();
  }, [requestPayload, resetList, setInTrackList, setList, startLoading, stopLoading]);

  React.useEffect(() => {
    getList();
  }, [getList]);

  if (loading) return <Loader />;

  return (
    <div className="searchResultListWrapper">
      <div className="diamondListMobile selectStoneHeader">
        <div className="listingTopBlock width-100">
          <div className="d-flex justify-content-between">
            <Heading>
              My Offer List
              {count > 0 && `(${count})`}
            </Heading>
            <Select value={status} onChange={setStatus}>
              {statusDropDownOptions.map(({ label, value }) => {
                return (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      <div className="diamondListMobile searchInnerResult">
        {list.map((record, index) => (
          <OfferListItem
            clearAllSelectedRows={props.clearAllSelectedRows}
            setState={props.setState}
            getList={getList}
            inTrackList={inTrackList}
            key={`${record?.id}-${index}`}
            currentType={currentType}
            record={record}
          />
        ))}
        {!loading && isEmpty(list) && <NoDataFound />}
      </div>
      <DiamondListingAction
        acceptBtn
        delete
        modify
        nofinalcalc
        noquote
        permKey="offer"
        editOffer={props.editOffer}
        trackType={TRACK_TYPES.QUOTE}
        currentType={currentType}
        offerStatus={status}
        onPaginationChange={setPagination}
        fetch={getList}
      />
    </div>
  );
}

OfferList.propTypes = {
  currentType: PropTypes.string,
  data: PropTypes.array,
};

export default React.memo(OfferList);
