import React from "react";

const RoundIcon = (props) => {
  return (
    <div className="KamDetailItemIcon">
      <img src={props.image} alt="" />
    </div>
  );
};
export default RoundIcon;
