import React from "react";

import { isString } from "util/utils";
import "./title.less";

function Heading(props) {
  const { className, children, title, ...restProps } = props;

  return (
    <h2
      className={["mainHeadingTitle", className]}
      title={isString(title) ? title : undefined}
      {...restProps}
      // style={{ paddingLeft: '20px' }}
    >
      {children ?? title}
    </h2>
  );
}

export default React.memo(Heading);
