import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link, NavLink, withRouter } from "react-router-dom";
import Slider from "react-slick";

import { FiSearch } from "react-icons/fi";
import { VscMenu } from "react-icons/vsc";

import Footer from "./Footer";
import LandingHeader from "./LandingHeader/LandingHeader";
import { HelmetTag } from "./common/Helmet/HelmetTag";

import Forevermark from "../assets/img/landing-page/De_Beers_Forevermark_Logo.png";
import Layer16 from "../assets/img/landing-page/Layer-16.png";
import RioTinto from "../assets/img/landing-page/Rio-Tinto.png";
import Sightholder from "../assets/img/landing-page/Sightholder-Signature-Futura-black-1.png";
import Logo2 from "../assets/img/landing-page/gemo.png";
import Logo1 from "../assets/img/landing-page/gia3-1-300x99.png";
import Logo3 from "../assets/img/landing-page/hrd-antwerp-logo.png";
import India from "../assets/img/landing-page/india-flag.png";
import LandingWrapper from "../assets/img/landing-page/kgk-2.jpg";
import KgkLogo from "../assets/img/landing-page/logo.png";
import UnitedStates from "../assets/img/landing-page/united-states.png";
import LandingWrapperMobile from "../assets/img/landing-page/wrapper-mobile.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const KgkHome = () => {
  // const [show, setShow] = useState(false);
  // const [search, setSearch] = useState(false);
  const [hostName, setHostName] = useState("www.kgk.live");
  // const handleShow = () => setShow(true);
  // const handleSearch = () => setSearch(true);

  useEffect(() => {
    if (window?.location) {
      setHostName(window?.location?.hostname);
    }
  }, []);

  useEffect(() => {
    if (hostName === "client.kgk.live" && hostName !== "") {
      window.open("https://www.kgk.live", "_self");
    }
  }, [hostName]);

  const Banner = {
    fade: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <HelmetTag
        title="Online Diamond Store| Every Diamond You Need - KGK Live "
        des="Be it natural diamonds or a        gemstone of a rare origin, KGK Live is the place to be. A diamond wholesaler with roots all across the world. Get your diamonds now."
        keywords="natural diamonds, online diamond store, diamond wholesaler"
      />
      <div className="landing_page_main">
        <div className="header-d d-none">
          <Container>
            <Row>
              <Col>
                <div className="kgk_header_content_main">
                  <div className="kgk_header_logo">
                    <img alt="img" src={KgkLogo} />
                  </div>
                  <div className="kgk_header_menu_main">
                    <ul>
                      <li>
                        <NavLink to="/signin"> LOGIN </NavLink>
                      </li>
                      <li>
                        <NavLink to="/signup">REGISTER </NavLink>
                      </li>
                      <li>
                        <span>
                          {/* <span onClick={handleSearch}> */}
                          <FiSearch />
                        </span>
                      </li>
                      <li>
                        <span>
                          {/* <span onClick={handleShow}> */}
                          <VscMenu />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <LandingHeader />

        <div className="kgk_landing_wrapper">
          {/* WRAPPER IMAGE DESKTOP */}
          <img alt="img" className="kgk_wrapper_img_desktop" src={LandingWrapper} />

          {/* WRAPPER IMAGE MOBILE */}
          <img alt="img" className="kgk_wrapper_img_mobile" src={LandingWrapperMobile} />

          {/* LANDING PAGE BANNER START */}
          <section className="kgk_banner_section">
            <Container>
              <Row>
                <Col xl={6} lg={7} md={8} xs={12}>
                  <Slider {...Banner} className="banner_slider">
                    <div className="kgk_banner_caption">
                      <h3> WELCOME TO YOUR </h3>
                      <h1>
                        ONLINE 
                        {' '}
                        <br />
                        {' '}
                        DIAMOND 
                        {' '}
                        <br />
                        {' '}
                        MARKET
                      </h1>
                      <p>ANY SHAPE. ANY SIZE. ANY COLOR. YOU'LL FIND IT HERE </p>

                      {/* <Button className="kgk_landing_btn_border">
                        <NavLink to="">
                          SEARCH DIAMOND <FiSearch />
                        </NavLink>
                      </Button> */}
                    </div>

                    <div className="kgk_banner_caption">
                      <h3 className="invisible"> .. </h3>
                      <h1>Your Virtual Diamond Buying Paradise</h1>
                      <p>The Perfect Diamonds For Your Jewelry </p>

                      {/* <Button className="kgk_landing_btn_border">
                        <NavLink to="">
                          SEARCH DIAMOND <FiSearch />
                        </NavLink>
                      </Button> */}
                    </div>

                    <div className="kgk_banner_caption">
                      <h3 className="invisible"> .. </h3>
                      <h1> EXPERIENCE The Beauty Of Diamonds</h1>
                      <p>From The Comfort Of Your Home </p>

                      {/* <Button className="kgk_landing_btn_border">
                        <NavLink to="">
                          SEARCH DIAMOND <FiSearch />
                        </NavLink>
                      </Button> */}
                    </div>

                    {/* <div className="kgk_banner_caption">
                    <h3 className='invisible'> .. </h3>
                    <h1>
                      Where Diamonds Meet Quality & Precision
                    </h1>
                    <p>Every Shape, Every Size, Every Color You Need. </p>

                    <Button className="kgk_landing_btn_border">

                      <NavLink to="">

                        SEARCH DIAMOND <FiSearch />
                      </NavLink>
                    </Button>
                  </div> */}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </section>
          {/* LANDING PAGE BANNER END  */}

          {/* CONVENIENCE SECTION SATRT  */}
          <section className="kgk_s2_section">
            <Container>
              <Row>
                <Col xl={6} lg={8} md={7} xs={12} className="m-auto">
                  <div className="kgk_s2_content_main">
                    <h2 className="kgk_landing_title">
                      <span> C </span>
                      ONVENIENCE
                    </h2>
                    <p className="kgk_landing_p">
                      Now finding the right diamond can be done from the comfort of your home or office. SELECTING,
                      INSPECTING, BIDDING OR BUYING ONLINE IS AS EASY AS.... doing it in person. Simple menus to choose
                      and select, videos and images to thoroughly inspect each diamond, and GIA Certificates; all you
                      need to do is click.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* CONVENIENCE SECTION END  */}

          {/* CONTROL SECTION SATRT  */}
          <section className="kgk_s3_section">
            <Container>
              <Row>
                <Col xl={5} lg={8} md={9} xs={12} className="mr-auto">
                  <div className="kgk_s3_content_main">
                    <h2 className="kgk_landing_title">
                      <span> C </span>
                      ONTROL
                    </h2>
                    <p className="kgk_landing_p">
                      Just like doing it in person, every action is confirmed and validated by sending you an email. So,
                      you are totally in control and no action is taken without you confirming it by mail.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* CONTROL SECTION END  */}

          {/* IN PERSON SECTION START  */}
          <section className="kgk_s4_section in_person">
            <Container>
              <Row>
                <Col xl={4} lg={8} md={5} xs={12} className="kgk_ml_auto p-0">
                  <div className="kgk_s4_content_main">
                    <h2 className="kgk_landing_title">
                      <span> I </span>
                      {' '}
                      N PERSON
                    </h2>
                    <p className="kgk_landing_p">
                      Alternatively, should you wish to inspect the stone in person, you can schedule an office visit.
                      You can do this simply by sending in a request from your account.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* IN PERSON SECTION END  */}

          {/* REGISTER SECTION START  */}
          <section className="kgk_s5_register_section">
            <Container>
              <Row>
                <Col xl={8} lg={8} md={9} xs={12} className="m-auto">
                  <div className="kgk_s5_content_main">
                    <h2 className="kgk_landing_title"> REGISTER </h2>
                    <p className="kgk_landing_p">
                      Once you register, a whole new world opens up. Search for diamonds from anywhere in the world.
                      Inspect every piece 360° with specially designed video tools. See GIA certificate, Bid for various
                      diamonds. Send offers for stones. All with just a click. A sales representative will be available
                      to answer queries, take orders, and guide you through the process.
                    </p>
                    <Button className="kgk_fill_btn">
                      <NavLink to="/signup"> REGISTER NOW </NavLink>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* REGISTER SECTION END  */}

          {/* KGK SECTION START  */}
          <section className="kgk_s6_section">
            <Container>
              <Row>
                <Col xl={6} lg={12} md={12} xs={12} className="offset-xl-3">
                  <div className="kgk_s6_content_main">
                    <h2 className="kgk_landing_title"> KGK </h2>
                    <p className="kgk_landing_p">
                      Since 1905, KGK Diamonds has been providing excellent customer service in the diamond, gemstone
                      and jewellery, industry.
                    </p>
                    <p className="kgk_landing_p">
                      Now, KGK takes the same assurance of reliability into digital space. As a vertically integrated
                      business from mines to retail, you get the benefit of a value chain offering considerable
                      competitive advantages.
                    </p>
                    <Button className="kgk_fill_btn">
                      <NavLink to="/about-us"> KNOW MORE </NavLink>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* KGK section END  */}

          {/* DISTRIBUTION NETWORK SECTION START  */}
          <section className="kgk_s7_section">
            <Container>
              <Row>
                <Col xl={6} lg={8} md={8} xs={12} className="">
                  <div className="kgk_s7_content_main">
                    <h2 className="kgk_landing_title"> DISTRIBUTION NETWORK </h2>
                    <p className="kgk_landing_p">
                      The 16000-member team of KGK is spread across the globe. With expertise honed over decades, we
                      have offices spread over 17 countries in Asia, North and South America, Europe, and Africa.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* DISTRIBUTION NETWORK SECTION END  */}

          {/* GRADING SYSTEM SECTION START  */}
          <section className="kgk_s8_section">
            <Container>
              <Row>
                <Col xl={6} lg={12} md={12} xs={12} className="offset-xl-6 p-0">
                  <div className="kgk_s8_content_main">
                    <h2 className="kgk_landing_title"> GRADING SYSTEM </h2>
                    <p className="kgk_landing_p">
                      KGK Grading Process (KGP) is one of the most stringent and transparent quality check standards in
                      the world. KGP gives in-depth details of the stone that can be seen with the high-quality video of
                      each stone. Our Quality Check (QC) goes through a rigorous and intense process by our finest
                      craftsman. So, every diamond you get from KGK surpasses the quality standards of the 4C industry
                      standard. For us at KGK, the trust of our client is our most important asset.
                    </p>
                    <Button className="kgk_fill_btn">
                      <NavLink to="/grading"> EXPLORE</NavLink>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* GRADING SYSTEM SECTION END  */}

          {/* CERTIFICATION SECTION START  */}
          <section className="kgk_s9_section">
            <Container>
              <Row>
                <Col xl={6} lg={8} md={9} xs={12} className="m-auto p-0">
                  <div className="kgk_s9_content_main">
                    <h2 className="kgk_landing_title"> CERTIFICATION </h2>
                    <p className="kgk_landing_p">
                      KGK offers every diamond with the international standard KGPR (KGK Grading Process Report). Our
                      diamonds are certified by international labs like Gemological Institute of America (GIA), Hoge
                      Raad Voor Diamant (HRD), DeBeers Forevermark, Code of Origin DeBeers, International Gemological
                      Institute (IGI), and National Gemological Testing Center (NGTC).
                    </p>

                    <p className="kgk_landing_p mt-xl-3">
                      As a leading global diamond manufacturer, we supply diamonds of the highest specification
                      standards in every shape, size, color & clarity.
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={8} md={10} xs={12} className="m-auto p-0">
                  <div className="kgk_s9_a2_logo">
                    <ul>
                      <li>
                        <img alt="img" src={Logo1} />
                      </li>
                      <li>
                        <img alt="img" src={Logo2} />
                      </li>
                      <li>
                        <img alt="img" src={Logo3} />
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* CERTIFICATION SECTION END  */}

          {/* ETHICAL SOURCING SECTION START  */}
          <section className="kgk_s10_section">
            <Container>
              <Row>
                <Col xl={4} lg={4} md={4} xs={12} className="offset-xl-1 offset-lg-2 offset-md-2">
                  <div className="kgk_s10_content_main">
                    <h2 className="kgk_landing_title"> ETHICAL SOURCING </h2>
                    <p className="kgk_landing_p">
                      AT KGK, we take special care to ensure that our diamonds are “conflict-free”. To ensure that
                      diamonds mined in a war zone and sold to finance violence are not part of our inventory, we have
                      several internationally recognized certifications. - DeBeers Code of Origin, GIA Diamond Origin
                      Program, Tracr Association, Responsible Jewellery Council, Best Practice Principles of DeBeers and
                      Sarine Diamond Journey are all part of those certifications.
                    </p>

                    <Button className="kgk_fill_btn">
                      <NavLink to="/"> KNOW MORE </NavLink>
                    </Button>
                  </div>
                </Col>

                <Col xl={4} lg={5} md={5} xs={12} className="offset-xl-1">
                  <div className="kgk_s10_a2_content_main">
                    <h3 className="kgk_landing_title"> ALL KGK DIAMONDS </h3>
                    <h3 className="kgk_landing_title"> HAVE A KIMBERLEY </h3>
                    <h3 className="kgk_landing_title"> PROCESS CERTIFICATE </h3>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* ETHICAL SOURCING SECTION END  */}

          {/* TESTIMONIALS SECTION START  */}
          {/* <section className="kgk_s11_section">
          <Container>
            <Row>
              <Col xl={6} lg={8} md={9} xs={12} className="m-auto p-0">
                <h2 className="kgk_landing_title testi_title_center"> TESTIMONIALS </h2>

                <Slider {...Testimonial} className="kgk_slick_testimonials">
                  <div className="kgk_testimonidal_box_content">
                    <div className="kgk_testimonidal_box_img">
                      <img  alt="img" src={Testi1} />
                    </div>
                    <div className="kgk_testimonidal_box_text">
                      <p className="kgk_landing_p">
                        KGK Diamonds is a competent and reliable partner. Our long association is a testament to the
                        quality and professionalism that they bring to the table.
                      </p>
                      <h4> - Pieter DeLong </h4>
                      <span> Procurement Head, DeBeers </span>
                    </div>
                  </div>

                  <div className="kgk_testimonidal_box_content">
                    <div className="kgk_testimonidal_box_img">
                      <img  alt="img" src={Testi1} />
                    </div>
                    <div className="kgk_testimonidal_box_text">
                      <p className="kgk_landing_p">
                        KGK Diamonds is a competent and reliable partner. Our long association is a testament to the
                        quality and professionalism that they bring to the table.
                      </p>
                      <h4> - Pieter DeLong </h4>
                      <span> Procurement Head, DeBeers </span>
                    </div>
                  </div>

                  <div className="kgk_testimonidal_box_content">
                    <div className="kgk_testimonidal_box_img">
                      <img  alt="img" src={Testi1} />
                    </div>
                    <div className="kgk_testimonidal_box_text">
                      <p className="kgk_landing_p">
                        KGK Diamonds is a competent and reliable partner. Our long association is a testament to the
                        quality and professionalism that they bring to the table.
                      </p>
                      <h4> - Pieter DeLong </h4>
                      <span> Procurement Head, DeBeers </span>
                    </div>
                  </div>
                </Slider>
              </Col>
            </Row>
          </Container>
        </section> */}
          {/* TESTIMONIALS SECTION END  */}

          {/* ASSOCIATIONS SECTION START  */}
          <section className="kgk_s12_section">
            <Container>
              <Row>
                <Col xl={6} lg={8} md={10} xs={12} className="m-auto p-0">
                  <div className="kgk_s12_content_main">
                    <h2 className="kgk_landing_title"> ASSOCIATIONS </h2>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={10} lg={10} md={9} xs={12} className="m-auto p-0">
                  <div className="kgk_s12_a2_logo">
                    <ul>
                      <li className="kgk_associations_logo_order_1">
                        <Link to="/" onClick={() => window.open("https://www.forevermark.com/en-in/", "_blank")}>
                          <img alt="img" src={Forevermark} />
                        </Link>
                      </li>
                      {/* <li className="kgk_associations_logo_order_3">
                      <Link to="/" onClick={() => window.open('https://www.igcgroup.com/', '_blank')}>
                        <img  alt="img" src={Alrosa} />
                      </Link>
                    </li> */}

                      {/* <li className="kgk_associations_logo_order_5">
                      <Link to="/" onClick={() => window.open('https://diamonds.netball.com.au/', '_blank')}>
                        <img  alt="img" src={Australian} />
                      </Link>
                    </li> */}
                      <li className="kgk_associations_logo_order_4">
                        <Link to="/" onClick={() => window.open("https://www.riotinto.com/en", "_blank")}>
                          <img alt="img" src={RioTinto} />
                        </Link>
                      </li>
                      <li className="kgk_associations_logo_order_2">
                        <Link to="/" onClick={() => window.open("https://www.debeersgroup.com/sightholders", "_blank")}>
                          <img alt="img" src={Sightholder} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* ASSOCIATIONS SECTION END  */}

          {/*  GET IN TOUCH SECTION START  */}
          <section className="kgk_s13_section">
            <Container>
              <Row>
                <Col xl={12} lg={12} md={12} xs={12} className="m-auto p-0">
                  <div className="kgk_s13_content_main">
                    <h2 className="kgk_landing_title"> GET IN TOUCH </h2>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xl={3} lg={4} md={4} xs={12} className="">
                  <div className="kgk_getin_tuch_box_main">
                    <img alt="img" src={India} />
                    <h4>INDIA </h4>
                    <p className="kgk_getin_tuch_dimond_title"> KGK Diamonds (I) Pvt. Ltd </p>
                    <address
                      onClick={() => {
                        window.open(
                          "https://www.google.co.in/maps/place/Bharat+Diamond+Bourse+-+Mumbai/@19.0643555,72.8617432,17z/data=!4m5!3m4!1s0x3be7c9db48bcc7d1:0x52c692071ad14131!8m2!3d19.0643504!4d72.8639319?shorturl=1",
                          "_blank"
                        );
                      }}
                    >
                      DE 4011 to 4016, Tower D, 
                      {' '}
                      <br />
                      Bharat Diamond Bourse, 
                      {' '}
                      <br />
                      Bandra Kurla Complex-Bandra East, 
                      {' '}
                      <br />
                      Mumbai - 400051 
                      {' '}
                      <br />
                    </address>
                    <p
                      onClick={() => {
                        window.location.href = `tel:+912240799994`;
                      }}
                    >
                      +91 22 4079 9994
                    </p>

                    <p
                      onClick={() => {
                        window.location.href = `mailto:sales.mumbai2@kgkmail.com`;
                      }}
                      className="mt-lg-2"
                    >
                      sales.mumbai2@kgkmail.com
                    </p>
                  </div>
                </Col>

                <Col xl={3} lg={4} md={4} xs={12} className="">
                  <div className="kgk_getin_tuch_box_main belgium_box">
                    <img alt="img" src={Layer16} />
                    <h4> BELGIUM </h4>

                    <p className="kgk_getin_tuch_dimond_title">KGK DIAMONDS BV</p>

                    <address
                      onClick={() => {
                        window.open(
                          "https://www.google.com/maps/place/KGK+Diamonds+BVBA/@51.2209968,4.4074782,16z/data=!4m5!3m4!1s0x0:0x36d44da0ec43e878!8m2!3d51.2158156!4d4.4185504",
                          "_blank"
                        );
                      }}
                    >
                      Hoveniersstraat No. 2, Suit No. 708, 901 Box No 433, 2018 Antwerp,
                      <br />
                      Belgium 
                      {' '}
                      <br />
                    </address>
                    <p
                      onClick={() => {
                        window.location.href = `tel:+3232131157`;
                      }}
                    >
                      +32 32131157
                    </p>
                    <p
                      onClick={() => {
                        window.location.href = `tel:+32486047074`;
                      }}
                    >
                      +32 486047074
                    </p>
                    <p
                      onClick={() => {
                        window.location.href = `tel: +3232131158`;
                      }}
                    >
                      +32 32131158
                    </p>

                    <p
                      onClick={() => {
                        window.location.href = `mailto:kgk.live@kgkmail.com`;
                      }}
                      className="mt-lg-3"
                    >
                      kgk.live@kgkmail.com
                    </p>
                    <p
                      onClick={() => {
                        window.location.href = `mailto:sales.antwerp@kgkmail.com`;
                      }}
                      className="mt-lg-1"
                    >
                      sales.antwerp@kgkmail.com
                    </p>
                  </div>
                </Col>

                <Col xl={3} lg={4} md={4} xs={12} className="">
                  <div className="kgk_getin_tuch_box_main">
                    <img alt="img" src={UnitedStates} />
                    <h4> USA </h4>

                    <p className="kgk_getin_tuch_dimond_title">Sparkle Gems Inc.</p>

                    <address
                      onClick={() => {
                        window.open(
                          "https://www.google.com/maps/place/Sparkle+Gems+Inc.+(KGK+International)/@40.7570482,-73.9797699,15z/data=!4m5!3m4!1s0x0:0x626877993842b763!8m2!3d40.7570482!4d-73.9797699",
                          "_blank"
                        );
                      }}
                    >
                      20 W 47th St #1101, 
                      {' '}
                      <br />
                      New York, NY 10036, 
                      {' '}
                      <br />
                      United States 
                      {' '}
                      <br />
                    </address>
                    <p
                      onClick={() => {
                        window.location.href = `tel:+1 212-279-1312`;
                      }}
                    >
                      +1 212-279-1312
                    </p>
                    <p
                      onClick={() => {
                        window.location.href = `tel:+1 646-288-5207`;
                      }}
                    >
                      +1 646-288-5207
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/*  GET IN TOUCH SECTION END  */}

          {/*  FOOTER START  */}
          <Footer />
          {/*  FOOTER END  */}
        </div>
      </div>
    </>
  );
};

export default withRouter(KgkHome);
