import uniq from "lodash/uniq";

import { callingApi } from "services/util";

import { hasKey, isArray, isBoolean, isEmpty, isNotEmpty, isObject, pruneEmpty } from "util/utils";

import { COMPOSITE_FILTERS, FILTER_MASTER_MAP } from "constants/search.consts";

export class SearchService {
  static getSearchFilter(options = {}) {
    if (!isObject(options)) return {};
    if (!isBoolean(options.isFcCol)) options.isFcCol = false;
    delete options.excludeFilter;

    Object.entries(options).forEach(([key, val]) => {
      if (isArray(val)) {
        isEmpty(val) ? delete options[key] : (options[key] = uniq(val));
      }
      if (isObject(val)) {
        if (Object.keys(val).length === 2 && hasKey(val, "<=") && hasKey(val, ">=")) {
          if (isEmpty(val["<="]) || isEmpty(val[">="])) delete options[key];
        }
      }
    });

    const hasStoneId =
      isArray(options.or) && options.or.some((param) => !isEmpty(param?.stoneId ?? param?.vStnId ?? param?.rptNo));
    if (hasStoneId) {
      options = { or: options.or.filter((param) => !isEmpty(param?.stoneId ?? param?.vStnId ?? param?.rptNo)) };
    }

    if (isEmpty(options.or)) delete options.or;

    return pruneEmpty(options);
  }

  static getSearchFilterLength(searchFilter = {}) {
    const keysToIgnore = ["isFcCol", "sgiSts", "sSts", "webSts", "wSts"];
    keysToIgnore.forEach((key) => delete searchFilter?.[key]);

    const filterLength = Object.keys(searchFilter).length;
    const orLength = isArray(searchFilter.or)
      ? Object.keys(
          searchFilter.or.reduce((prev, curr) => {
            return { ...(isObject(prev) && (prev?.nin ?? prev)), ...(isObject(curr) && (curr?.nin ?? curr)) };
          }, {})
        )?.length ?? 0
      : 0;

    const length = filterLength + (orLength > 0 ? orLength - 1 : 0);
    return length;
  }

  static getCompositeFilterState(master) {
    master = master ?? Storage.get("master", false);

    const entries = Object.entries(COMPOSITE_FILTERS).map(([compositeStateKey, compositeFilter]) => {
      const entries = Object.entries(compositeFilter).map(([filterKey, filterCodeList]) => {
        const masterList = master[FILTER_MASTER_MAP[filterKey]];
        const filterIdList = masterList
          .filter((item) => filterCodeList.includes(item?.code))
          .map((item) => item?.id?.[0] ?? item?.id)
          .filter(isNotEmpty)
          .sort();
        return [filterKey, filterIdList];
      });
      return [compositeStateKey, Object.fromEntries(entries)];
    });

    return Object.fromEntries(entries);
  }

  static checkFilterAllowance(masterList) {
    const masters = Storage.get("master", false);
    const entries = masterList.map((key) => {
      const filteredList = masters?.[key]?.filter?.((rec) => rec?.isActive);
      return [key, filteredList];
    });
    const filters = pruneEmpty(Object.fromEntries(entries));
    return !isEmpty(filters);
  }

  static async getFilterFromId(id) {
    const [, res] = await callingApi({
      method: "post",
      url: "/web/v1/diamond/search/list",
      request: { id, isAppendMasters: true },
    });

    return res?.data?.list?.[0]?.searchData;
  }

  static async getIdFromFilter(filter) {
    const [, res] = await callingApi({
      method: "post",
      url: "/web/v1/diamond/paginate",
      request: {
        filters: [filter],
        isNotReturnTotal: true,
        isReturnCountOnly: true,
      },
    });
    return res?.data?.[0]?.filter?.id;
  }
}
