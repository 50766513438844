import React from "react";

import { notification } from "antd";
import * as _ from "lodash";
import moment from "moment";

import { logWarn } from "util/utils";

import { Storage } from "./storage";

import { BASE_URL as baseURL, LOCAL_STORAGE_VAR, FILE_TYPES_PDF, FILE_TYPES , commonLogout, fields, requiredFields, fieldsrequiredGuest, fieldsGuest } from "../constants/Common";
import "../assets/scss/global.css";
import { CREATE_ANALYTICS, USER_LOGOUT, GET_TIME } from "../constants/apiConstant";
import { axiosFunction } from "../util/Api";

const myUploadProgress = (data) => {
  const showProgressDoc = document.querySelectorAll("#loader.APILoader .showProgress span")[0];
  const showProgressMain = document.querySelectorAll("#loader.APILoader .showProgress")[0];
  if (showProgressDoc) {
    if (showProgressMain && showProgressMain.classList && showProgressMain.classList.contains("display-none")) {
      showProgressMain.classList.remove("display-none");
    }
    showProgressDoc.innerText = `${data}%`;
    showProgressDoc.style.width = `${data}%`;
  }
};

const callApiSync = (config, cb) => {
  const options = {
    headers: {
      "Content-Type": config?.isMultipart
        ? undefined
        : config.url === "/web/v1/dynamic-diamond-bid/create-by-excel"
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "application/json",
      devicetype: config?.deviceType ?? 1,
    },
  };

  if (config?.isMultipart) {
    options.transformRequest = {};
    options.onUploadProgress = (e) => myUploadProgress(Math.round((e.loaded * 100) / e.total));
  }

  const token = config?.auth ?? config?.updateToken ?? Storage.get("token");
  if (token) options.headers.Authorization = `JWT ${token}`;

  if (config?.showLoader) document.body.classList.add("loading-indicator");

  axiosFunction
    .request({ url: config?.url, method: config?.method, baseURL, data: config?.request, ...options })
    .then(({ data }) => {
      try {
        cb(data?.code !== "OK", data);
        if (data?.code === "E_UNAUTHORIZED") commonLogout();
      } catch (error) {
        logWarn(error);
      }
      document.body.classList.remove("loading-indicator");
    })
    .catch((error) => {
      try {
        cb(error?.response);
      } catch (error) {
        logWarn(error);
      }
      document.body.classList.remove("loading-indicator");
    });
};

export const callingApi = (object) => new Promise((resolve) => callApiSync(object, (...args) => resolve(args)));

const UtilService = {
  // roundNumer(number, place) {
  //     return +(Math.round(number + `e+${place}`) + `e-${place}`)
  // },
  subString(string, start, end) {
    return string.substring(start, end);
  },
  displayDate: (date) => {
    return date ? moment(date).format("MMM DD YYYY, HH:mm:ss a") : "-";
  },
  callApi: callApiSync,

  SummationOfDiamond(list, cb) {
    let finalData = {
      totalpcs: 0,
      totalCarat: 0,
      // totalAvgDis: 0,
      totalAmount: 0,
      totalPricePerCarat: 0,
      // fancyrapAvg: 0,
    };
    let fancyTotalPcs = 0;
    let fancyTotalCarat = 0;
    let fancyAmount = 0;
    let nonFancyTotalPcs = 0;
    let nonFancyTotalCarat = 0;
    // nonFancyrapAvg = 0,
    let nonFancyAmount = 0;
    const checkFancyData = _.partition(list, (o) => {
      return o.rap === 0;
    });

    _.forEach(list, (value) => {
      if (value.rap === 0) {
        fancyTotalPcs = _.size(checkFancyData[0]);
        fancyTotalCarat = _.sumBy(checkFancyData[0], "crt");
        fancyAmount = _.sumBy(checkFancyData[0], "amt");
      } else {
        nonFancyTotalPcs = _.size(checkFancyData[1]);
        nonFancyTotalCarat = _.sumBy(checkFancyData[1], "crt");
        nonFancyAmount = _.sumBy(checkFancyData[1], "amt");
        // nonFancyrapAvg = _.sumBy(checkFancyData[1], "rapAvg");
      }
    });

    const totalpcs = fancyTotalPcs + nonFancyTotalPcs;
    const totalCarat = fancyTotalCarat + nonFancyTotalCarat;
    const totalAmount = fancyAmount + nonFancyAmount;
    // let rapAvg = nonFancyrapAvg;
    // let totalAvgDis = (nonFancyAmount / rapAvg) * 100 - 100;
    const totalPricePerCarat = totalAmount / totalCarat;
    finalData = {
      totalpcs: totalpcs || 0,
      totalCarat: totalCarat || 0,
      // totalAvgDis: totalAvgDis ? totalAvgDis : 0,
      totalAmount: totalAmount || 0,
      totalPricePerCarat: totalPricePerCarat || 0,
    };

    cb(null, finalData);
  },

  showErrorInBulk(obj, err, isSimple = false) {
    const blank = [];
    const invalid = [];
    let fill = true;
    _.each(requiredFields, (field) => {
      if (field === "iAgree" && obj.iAgree === false) {
        fill = false;
        blank.push(fields.iAgree);
      } else if (obj[field] === "") {
        fill = false;
        blank.push(fields[field]);
      }
    });

    if (err) {
      if (isSimple) {
        Object.keys(err).map((field) => {
          invalid.push(fields[field]);
          return true;
        });
      } else {
        _.each(err, (field) => {
          invalid.push(fields[field.errors[0].field]);
        });
      }
    }
    if (blank.length > 0) {
      // let blankFields = blank.map((f, i) => {
      //     return <span>{f + (i !== blank.length - 1 ? ", " : "")}</span>;
      // });

      const arr = blank;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = `${arr.slice(0, -1).join(", ")  } and ${  arr.slice(-1)}`;
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalid.length > 0) {
      const blankFields = invalid.map((f, i) => {
        return <span key={i}>{f + (i !== invalid.length - 1 ? ", " : "")}</span>;
      });
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: blankFields,
      });
    }

    return {
      fill,
      invalid,
      blank,
    };
  },

  showErrorInBulkGuest(obj, err, isSimple = false) {
    const blank = [];
    const invalid = [];
    let fill = true;
    _.each(fieldsrequiredGuest, (field) => {
      if (obj[field] === "") {
        fill = false;
        blank.push(fieldsGuest[field]);
      }
    });
    if (err) {
      if (isSimple) {
        Object.keys(err).map((field) => {
          invalid.push(fieldsGuest[field]);
          return true;
        });
      } else {
        _.each(err, (field) => {
          invalid.push(fieldsGuest[field.errors[0].field]);
        });
      }
    }
    if (blank.length > 0) {
      const blankFields = blank.map((f, i) => {
        return <span key={i}>{f + (i !== blank.length - 1 ? ", " : "")}</span>;
      });
      notification.error({
        message: "Required Fields",
        description: blankFields,
        placement: "bottomLeft",
      });
    }
    if (invalid.length > 0) {
      const blankFields = invalid.map((f, i) => {
        return <span key={i}>{f + (i !== invalid.length - 1 ? ", " : "")}</span>;
      });
      notification.error({
        message: "Invalid Fields",
        description: blankFields,
        placement: "bottomLeft",
      });
    }
    return {
      fill,
      invalid,
      blank,
    };
  },
  getFullName(user) {
    if (!user || _.isEmpty(user)) {
      return null;
    }
    user.firstName = user.firstName ? user.firstName : "";
    user.lastName = user.lastName ? user.lastName : "";
    const fullName =
      `${user.firstName.charAt(0).toUpperCase() +
      user.firstName.slice(1).toLowerCase() 
      } ${ 
      user.lastName.charAt(0).toUpperCase() 
      }${user.lastName.slice(1).toLowerCase()}`;
    return fullName;
  },
  getLocalStorageItem(name) {
    const localItem = localStorage.getItem(name);
    if (localItem && localItem.length && localItem !== "undefined") {
      const decode = decodeURIComponent(escape(window.atob(localItem)));
      return JSON.parse(decode);
    } 
      return null;
    
  },
  setLocalStorageItem(userString, name) {
    const encryptedString = window.btoa(unescape(encodeURIComponent(JSON.stringify(userString))));
    localStorage.setItem(name, encryptedString);
  },
  fileMimeType(blob, pdf, cb) {
    const {type} = blob;

    const fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = "";
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      let validity = false;
      if (pdf) {
        if (type in FILE_TYPES_PDF) {
          validity = FILE_TYPES_PDF[type].includes(header.toLowerCase());

          cb(validity);
        } else {
          cb(false);
        }
      } else if (type in FILE_TYPES) {
          validity = FILE_TYPES[type].includes(header.toLowerCase());
          cb(validity);
        } else {
          cb(false);
        }
    };
    fileReader.readAsArrayBuffer(blob);
  },
};

export const getTrackData = (str) => {
  const [page, section, action] = str.split("/");
  return {
    page,
    section,
    action,
  };
};
export const track = (data) =>
  UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) &&
  UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`).length
    ? UtilService.callApi({
        // method: "post",
        // url: "/apis/analytics/create",
        ...CREATE_ANALYTICS,
        request: getTrackData(data),
      })
    : "";

window.track = track;

export const getShortPath = () => window.location.pathname.split("/")[1];

export const loaderActivityFn = (showLoader) => {
  const getLoaderEle = document.querySelectorAll("#loader.APILoader")[0];
  const getProgressBar = document.querySelectorAll("#loader.APILoader .showProgress")[0];
  if (showLoader) {
    getLoaderEle.classList.add("loading");
  } else if (getLoaderEle && getLoaderEle.classList && getLoaderEle.classList.contains("loading")) {
    getLoaderEle.classList.remove("loading");
    if (getProgressBar && getProgressBar.classList && !getProgressBar.classList.contains("display-none")) {
      getProgressBar.classList.add("display-none");
    }
  }
};

window.shortPath = getShortPath;

export const getCookie = (cname) => {
  const name = `${cname  }=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getCurrentTime = () => {
  return new Promise((resolve, reject) => {
    const objData = { ...GET_TIME, url: `${GET_TIME.url}?${Math.random()}` };
    UtilService.callApi(objData, (err, res) => {
      if (err) return reject(err);
      if (res?.code === "OK") return resolve(res.data);
      reject(err);
    });
  });
};

export const logoutAPICall = () => {
  UtilService.callApi(USER_LOGOUT, async () => {});
};

export default UtilService;
