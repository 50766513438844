import React from "react";

import { isMobile, handleInsertTrack, downloadFile } from "components/DiamondList/DiamondListFunctions";
import CommonModal from "components/common/CommonModal";
import ShareDropdown from "components/common/DiamondListing/ShareDropdown";

import IntlMessage from "util/IntlMessage";
import { isEmpty, isString } from "util/utils";

import { LAB_LINKS, trackTypeObj } from "constants/Common";

import DNASVG from "assets/svg/DiamondDetail/DNA_icon.svg";
import cartSVG from "assets/svg/DiamondDetail/cart.svg";
import certSVG from "assets/svg/DiamondDetail/certificate.svg";
import dnldzipSVG from "assets/svg/DiamondDetail/download-zip.svg";
import shareSVG from "assets/svg/DiamondDetail/share.svg";
import shpingcartSVG from "assets/svg/DiamondDetail/shopping-cart.svg";
import watchlistSVG from "assets/svg/DiamondDetail/watchlist.svg";

const ExtraButton = (props) => {
  const { data, isGuest, iskyc, showCart, showDownload, showKyc, showPop, showShare, showWatch, url: path } = props;

  const rptNo = props.data?.rptNo ?? "";
  const lbNm = !isEmpty(rptNo) ? props.data?.lbNm : "";
  const id = props.data?.vStnId ?? rptNo;

  const [show, setShow] = React.useState(false);

  const handleDownload = React.useCallback(() => {
    if (isGuest) return showPop();
    if (iskyc) return showKyc();
    if (!isString(path)) return;

    const ext = `.${path.split(".").pop()}`;
    const fileName = `${id}-${props.fileName ?? ""}`;

    downloadFile({ path, fileName, ext });
  }, [isGuest, showPop, iskyc, showKyc, path, props.name, id]);

  return (
    <>
      {!isMobile ? (
        <div className="diamondDetailShare d-flex mt-10 flex-wrap">
          {showWatch ? (
            <div
              onClick={() => {
                if (!isGuest) {
                  if (iskyc) {
                    showKyc();
                  } else {
                    handleInsertTrack(trackTypeObj.WATCHLIST, [data], () => {});
                  }
                } else {
                  showPop();
                }
              }}
              className="diamondDetailShareItem"
            >
              <span>Add To Watch</span>
              <img src={watchlistSVG} alt="" />
            </div>
          ) : (
            <></>
          )}
          {showCart ? (
            <div
              onClick={() => {
                if (!isGuest) {
                  if (iskyc) {
                    showKyc();
                  } else {
                    handleInsertTrack(trackTypeObj.CART, [data], () => {});
                  }
                } else {
                  showPop();
                }
              }}
              className="diamondDetailShareItem"
            >
              <span>
                <IntlMessage id="app.AddToCart" />
              </span>
              <img src={cartSVG} alt="" />
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="diamonddetailActionwrap">
        <div className="diamondDetailShare d-flex mt-10">
          {showWatch ? (
            <div
              onClick={() => {
                if (!isGuest) {
                  if (iskyc) {
                    showKyc();
                  } else {
                    handleInsertTrack(trackTypeObj.WATCHLIST, [data], () => {});
                  }
                } else {
                  showPop();
                }
              }}
              className="diamondDetailShareItem"
            >
              <span>
                <IntlMessage id="app.AddToWatchlist" />
              </span>
              <img src={watchlistSVG} alt="" />
            </div>
          ) : (
            <></>
          )}
          {showCart ? (
            <div
              onClick={() => {
                if (!isGuest) {
                  if (iskyc) {
                    showKyc();
                  } else {
                    handleInsertTrack(trackTypeObj.CART, [data], () => {});
                  }
                } else {
                  showPop();
                }
              }}
              className="diamondDetailShareItem"
            >
              <span>
                <IntlMessage id="app.AddToCart" />
              </span>
              <img src={shpingcartSVG} alt="" />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="diamondDetailShare d-flex flex-wrap">
          {showShare ? (
            <div
              onClick={() => {
                if (!isGuest) {
                  if (iskyc) {
                    showKyc();
                  } else {
                    setShow(true);
                  }
                } else {
                  showPop();
                }
              }}
              className="diamondDetailShareItem"
            >
              <span>
                <IntlMessage id="app.Share" />
              </span>
              <img src={shareSVG} alt="" />
            </div>
          ) : (
            <></>
          )}
          {showDownload ? (
            <div onClick={handleDownload} className="diamondDetailShareItem">
              <span>
                {" "}
                <IntlMessage id="app.Download" />
              </span>
              <img src={dnldzipSVG} alt="" />
            </div>
          ) : (
            <></>
          )}
          {showWatch ? (
            <div
              onClick={() => {
                if (!isGuest) {
                  if (iskyc) {
                    showKyc();
                  } else if (rptNo) {
                      window.open(LAB_LINKS[lbNm.toUpperCase()].replace("***", rptNo));
                    }
                } else {
                  showPop();
                }
              }}
              className="diamondDetailShareItem"
            >
              <span>
                <IntlMessage id="app.CERTIFICATE" />
              </span>
              <img src={certSVG} alt="" />
            </div>
          ) : (
            <></>
          )}

          {data.vStnId ? (
            <div
              onClick={() => {
                if (!isGuest) {
                  if (iskyc) {
                    showKyc();
                  } else if (rptNo) {
                      window.open(`
         https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/DNA.html?id=${data.vStnId}`);
                    }
                } else {
                  showPop();
                }
              }}
              className="diamondDetailShareItem"
            >
              <span>
                <IntlMessage id="app.DNA" />
              </span>
              <img src={DNASVG} alt="" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <CommonModal
        modalSize="ListingModal"
        title="Share Stone"
        handleCancel={() => {
          setShow(false);
        }}
        visible={show}
        wrapClassName=""
      >
        <ShareDropdown
          {...props}
          checked={[data]}
          onCloseSharePopup={() => {
            setShow(false);
          }}
        />
      </CommonModal>
    </>
  );
};

export default ExtraButton;
