import React from "react";

import { Spin } from "antd";

function Loader(props) {
  const { loading = true, message, ...forwardProps } = props;
  if (!loading) return null;

  return (
    <div className="empty-data" {...forwardProps}>
      <h3>{message ?? <Spin />}</h3>
    </div>
  );
}

export default React.memo(Loader);
