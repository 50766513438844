import React from "react";
import "./checkBox.less";

const CheckBox = ({ label, ...props }) => {
  return (
    <div className={`customCheckBox d-flex flex-wrap ${props.className}`}>
      <input type="checkbox" {...props} />
      <span className="customCheckBoxLebel" />
      {label && <label>{label}</label>}
    </div>
  );
};
export default CheckBox;
