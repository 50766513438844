import React, { Component } from "react";
import Magnifier from "react-magnifier";

import IntlMessage from "util/IntlMessage";

class MagnifierImage extends Component {
  state = {
    activeKey: "Image",
    showDetails: false,
    Path: "",
    content: "",
    zoom: 1,
    maginfierState: false,
    error: false,
  };
  componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image) {
      this.setState({ error: false });
    }
  }
  render() {
    const { error } = this.state;
    return (
      <>
        {error ? (
          <span>
            <IntlMessage id="app.noData" />
          </span>
        ) : (
          <Magnifier
            onLoad={() => {
              if (this.props.onLoad) {
                this.props.onLoad();
              }
            }}
            className={this.props.className}
            onMouseMove={this.handlechange}
            src={this.props.image}
            zoomFactor={this.state.zoom}
            onWheel={this.handleWheelChange}
            onMouseEnter={() => {
              this.setState({ maginfierState: true });
            }}
            onMouseOut={() => {
              this.setState({ maginfierState: false });
            }}
            onError={() => {
              this.setState({ error: true }, () => {
                if (this.props.onError) {
                  this.props.onError();
                }
              });
            }}
          />
        )}
      </>
    );
  }
}
export default MagnifierImage;
