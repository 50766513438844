import React from "react";
import { useSelector } from "react-redux";
import { generatePath, useLocation, useRouteMatch } from "react-router-dom";

import { LoaderService } from "services/LoaderService";

import { formatInlineList, isArray, isFunction, isNumber, isObject, queryStringToObject, resolve } from "util/utils";

import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH, TABLE_PAGE_LIMIT } from "constants/Common";

export const useRefEffect = (ref, value) => {
  React.useEffect(() => void (ref.current = value), [ref, value]);
};

export const useAutoRef = (value) => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  React.useDebugValue(ref.current);
  return ref;
};

export const useBoolean = (initialValue = false) => {
  const [state, _setState] = React.useState(Boolean(initialValue));

  const setState = React.useMemo(() => {
    return {
      true: () => _setState(true),
      false: () => _setState(false),
      toggle: () => _setState((v) => !v),
    };
  }, []);

  React.useDebugValue(state);
  return [state, setState];
};

export const useCompositeState = (initialState = {}) => {
  const [state, _setState] = React.useState(initialState);
  const initialStateRef = React.useRef(initialState);

  const setState = React.useCallback((objectOrCallback, spread = true) => {
    const callback = isFunction(objectOrCallback) ? objectOrCallback : undefined;
    const object = isObject(objectOrCallback) ? objectOrCallback : {};

    _setState((state) => {
      const _object = callback ? callback(state) : object;
      return spread ? { ...state, ..._object } : { ..._object };
    });
  }, []);

  const resetState = React.useCallback(() => {
    _setState(initialStateRef.current);
  }, []);

  React.useDebugValue(state);
  return [state, setState, resetState];
};

export const useCounter = (init = 0) => {
  const [count, set] = React.useState(init);
  // const inc = React.useCallback((cb = () => {}) => {set((n) => ((n = n > 0 ? ++n : 1), cb(n), n))}, []);
  const inc = React.useCallback((cb = () => {}) => {
    set((n) => {
      n = n > 0 ? ++n : 1;
      cb(n);
      return n;
    });
  }, []);
  // const dec = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? --n : 0), cb(n), n)), []);
  const dec = React.useCallback(
    (cb = () => {}) =>
      set((n) => {
        n = n > 0 ? --n : 0;
        cb(n);
        return n;
      }),
    []
  );
  React.useDebugValue(count);
  return [count, inc, dec];
};

export const useLoading = (init = false, show = true) => {
  const [count, inc, dec] = useCounter(init ? 1 : 0);
  const countRef = React.useRef(count);
  React.useEffect(() => void (countRef.current = count), [count]);

  const start = React.useCallback(() => inc(() => show && LoaderService.startLoading()), [inc, show]);
  const stop = React.useCallback(() => dec(() => show && LoaderService.stopLoading()), [dec, show]);

  React.useEffect(() => {
    if (show) return () => {};
    const hasClass = document?.body?.classList?.contains?.("progress");
    void (count > 0
      ? !hasClass && document?.body?.classList?.add?.("progress")
      : hasClass && document?.body?.classList?.remove?.("progress"));
  }, [show, count]);

  React.useEffect(() => {
    return () => {
      if (show && isNumber(countRef.current) && countRef.current > 0) {
        LoaderService.adjustCount(-Math.abs(countRef.current));
      }
    };
  }, [show]);

  React.useDebugValue(count);
  return [Boolean(count), start, stop];
};

export const usePagination = (page = 1, limit = TABLE_PAGE_LIMIT) => {
  const [state, _setState] = React.useState({ page, limit });
  const paramsRef = useAutoRef({ page, limit });

  const setPagination = React.useCallback((page, limit) => {
    _setState((state) => ({
      page: page ?? state.page,
      limit: limit ?? state.limit,
    }));
  }, []);

  const resetPagination = React.useCallback(() => {
    const { page, limit } = paramsRef.current ?? {};
    _setState({ page, limit });
  }, [paramsRef]);

  React.useDebugValue(state);
  return [state?.page, state?.limit, setPagination, resetPagination];
};

export const useList = (list = [], count = 0) => {
  const [state, _setState] = React.useState({ list, count });

  const setList = React.useCallback((list, count) => {
    _setState((state) => ({
      list: list ?? state.list,
      count: count ?? state.count,
    }));
  }, []);

  const resetList = React.useCallback(() => {
    _setState(() => ({ list: [], count: 0 }));
  }, []);

  React.useDebugValue(state);
  return [state?.list, state?.count, setList, resetList];
};

export const useIsMobile = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const setSize = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    setSize();
    window.addEventListener("resize", setSize);
    return () => window.removeEventListener("resize", setSize);
  }, []);

  const output = React.useMemo(() => {
    const isMobile = width <= MOBILE_MAX_WIDTH;
    const isTablet = width <= TABLET_MAX_WIDTH;
    return [isMobile, isTablet];
  }, [width]);

  React.useDebugValue(output);
  return output;
};

export const useQueryParams = () => {
  const location = useLocation();
  const output = React.useMemo(() => queryStringToObject(location.search), [location.search]);
  React.useDebugValue(output);
  return output;
};

export const usePathname = () => {
  const match = useRouteMatch();
  const pathname = React.useMemo(() => generatePath(match.path)?.split("/")?.pop(), [match.path]);
  React.useDebugValue(pathname);
  return pathname;
};

export const useCurrentType = usePathname;

export const useSelectedRows = (currentType, options) => {
  options = { mapFields: [], ...options };
  const optionsRef = useAutoRef(options);

  const rowList = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] ?? []);

  const rowMap = React.useMemo(() => {
    const { mapFields } = optionsRef.current;
    return Object.fromEntries(mapFields.map((field) => [field, rowList.map((row) => row?.[field])]));
  }, [optionsRef, rowList]);

  React.useDebugValue({ rowList, rowMap });
  return [rowList, rowMap];
};

export function useInlineList(initialValue) {
  const [value, _setValue] = React.useState(() => {
    const value = resolve(initialValue);
    return formatInlineList(isArray(value) ? value.join(",") : value, { allowAppend: false });
  });

  const handleChange = React.useCallback((eventOrValue) => {
    const value = formatInlineList(resolve(eventOrValue), { allowAppend: true });
    _setValue(value);
  }, []);

  const handleBlur = React.useCallback(() => {
    _setValue((value) => formatInlineList(value, { allowAppend: false }));
  }, []);

  const setValue = React.useCallback((eventOrValue) => {
    const value = formatInlineList(resolve(eventOrValue), { allowAppend: false });
    _setValue(value);
  }, []);

  const output = [value, handleChange, handleBlur, setValue];

  React.useDebugValue(output);
  return output;
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => void clearTimeout(handler);
  }, [value, delay]);

  React.useDebugValue({ debouncedValue });
  return debouncedValue;
};
