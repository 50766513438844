import React from "react";

import loaderSVG from "../../assets/img/loader.svg";

const APILoader = () => (
  <div
    id="loader"
    className="APILoader main-loader --fixed"
    style={{
      width: "100%",
    }}
  >
    <div id="loader-inner">
      <div className="">
        <img src={loaderSVG} alt="KGK" />
        <h3 className="showProgress display-none">
          <span className="after" />
        </h3>
      </div>
    </div>
  </div>
);
export default APILoader;
