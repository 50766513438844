import React from "react";
// import { useState } from 'react';
import { Component } from "react";

import isEmpty from "lodash/isEmpty";

import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import { FETCH_USER } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import CardView from "../Profile/CardView";

// const PersonalCardView = (props) => {
class PersonalCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = () => {
    const self = this;

    const obj = {
      ...FETCH_USER,
    };

    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        !isEmpty(data.data.kyc) &&
          data.data.kyc.forEach((k) => {
            if (k.type === "personal") self.setState({ doc: [k.docType], path: k.path }, () => {});
          });
      }
    });
  };
  render() {
    const dt = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));

    return (
      <CardView
        personalDetails
        companyDetail={false}
        pDetails={dt}
        path={this.state.path}
        onClose={this.props.onClose}
        onClick={this.props.onClick}
      />
    );
  }
}
export default PersonalCardView;
