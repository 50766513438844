import React from "react";

import { callingApi } from "services/util";

import IntlMessage from "util/IntlMessage";
import { useIsMobile } from "util/hooks";
import { notify } from "util/notify";
import { classNames, isString } from "util/utils";

import { BID_IMPORT_EXCEL } from "constants/apiConstant";

import warn from "assets/img/warning.png";
import FilePicker from "./FilePicker";

import Heading from "../Heading";


function ImportExcelPopUp(props) {
  const [isMobile] = useIsMobile();

  const uploadExcell = async (params = {}) => {
    const request = new FormData();
    Object.entries(params).forEach(([key, value]) => request.append(key, value));

    const [err, res] = await callingApi({ ...BID_IMPORT_EXCEL, request });
    if (err) {
      const errorMessage = err?.data?.message;
      if (isString(errorMessage)) notify.error({ message: errorMessage });
    }
    const statusMessage = res?.data?.message;
    const importMessage = res?.data?.[0]?.result?.message;

    if (isString(statusMessage)) notify.success({ message: statusMessage });
    if (isString(importMessage)) notify.success({ message: importMessage });
    return res && props.onClose();
  };
  const uploadExcel = React.useCallback(
    async (file) => {
      uploadExcell({ file, subTypeCode: props.subTypeCode, bidConfigurationId: props.bidConfigurationId });
    },
    [props.bidConfigurationId, props.subTypeCode]
  );

  return (
    <>
      <div>
        {props.isMobile && <Heading title="Bid Stone" className="p-0" />}

        <div className={classNames([!isMobile && "searchPopupCommon"])}>
          <div className={classNames([isMobile ? "shareStoneMobileItem" : "shareOptionSet d-flex"])}>
            <div className={classNames([isMobile ? "mobileShareOption" : "shareOptionSetItem"])}>
              <div>
                <img
                  src={warn}
                  style={
                    isMobile
                      ? { width: "50px", marginLeft: "110px" }
                      : { width: "50px", marginLeft: "180px", marginBottom: "25px" }
                  }
                  alt=""
                />
              </div>
              <span style={{ coloe: "black", fontWeight: "500" }}>
                While importing the excel, only keep stones that you are adding for Bid. Please remove other stones
                prior importing. Also, import excel must have 500 or less diamonds.
              </span>
            </div>
          </div>
          <div className={classNames([isMobile ? "sideBarPopupButton" : "commonModalButton mt-20"])}>
            <span className={classNames([isMobile ? "commonButton" : "fillButton"])}>
              <FilePicker label={<IntlMessage id="app.importExcel" />} extensions="xlsx" onChange={uploadExcel} />
            </span>
            {isMobile && (
              <span onClick={props.onClose} className={classNames([isMobile && "commonOutline"])}>
                <IntlMessage id="app.Cancel" />
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ImportExcelPopUp);
