import React, { useState, useEffect } from "react";

import "../../styles/PriceCalculator.less";
import { InputNumber } from "antd";
import { find, isEmpty } from "lodash";

import IntlMessage from "util/IntlMessage";
import { formatDecimal } from "util/utils";

import removeSVG from "../../assets/svg/PriceCalc/remove.svg";
import closeSVG from "../../assets/svg/close.svg";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import { PRICE_CALC } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import OpenNotification from "../common/CommonButton/OpenNotification";
import InputBlock from "../common/InputBlock";
import SelectOption from "../common/SelectOption";

const PriceCalculator = (props) => {
  const maxLimit = 5.99;
  const masters = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  const [selected, setSelected] = useState({});
  const [prices, setPrices] = useState({});
  const [activeInput, setActiveInput] = useState("");

  const masterColor = masters.COLOR.filter((place, index) => {
    return index < 10;
  });
  const masterClarity = masters.CLARITY.filter((place, index) => {
    return index > 0;
  });

  const onClick = () => {
    setSelected({});
    setPrices({});
    setActiveInput("");
    props.onClick();
  };
  useEffect(() => {
    const obj = {
      ...selected,
      shp: masters.SHAPE?.[0].id,
      col: masterColor?.[0].id,
      clr: masterClarity?.[0].id,
      price: 0,
      isCurrent: true,
    };
    setSelected(obj);
  }, []);

  const diamondPrice = (diamond) => {
    if (selected.crt !== "") {
      diamond.price = diamond.apiPrice ? diamond.apiPrice.toFixed(2) : 0;
      diamond.totalPrice = diamond.price && selected.crt ? (diamond.price * selected.crt).toFixed(2) : 0;

      diamond.calcPricePerCarat =
        diamond.price * (1 + diamond.rap / 100) ? (diamond.price * (1 + diamond.rap / 100)).toFixed(2) : 0;
      diamond.calcAmount =
        diamond.crt && diamond.calcPricePerCarat * diamond.crt
          ? (diamond.calcPricePerCarat * diamond.crt).toFixed(2)
          : 0;
    }
    setPrices(diamond);
    return diamond;
  };

  const setTypedNumber = (num) => {
    const _num = parseInt(num);
    setSelected({
      ...selected,
      [activeInput]: selected[activeInput] ? parseInt(`${selected[activeInput]}${_num}`) : _num,
    });
  };

  const removeTypedNumber = () => {
    setSelected({
      ...selected,
      [activeInput]: selected[activeInput] && parseInt(selected[activeInput].toString().slice(0, -1)),
    });
  };

  const setSigns = () => {
    if (selected[activeInput] > 0) {
      setSelected({
        ...selected,
        [activeInput]: selected[activeInput] && parseInt(`-${selected[activeInput]}`),
      });
    } else {
      setSelected({
        ...selected,
        [activeInput]: selected[activeInput] && parseInt(selected[activeInput].toString().slice(1)),
      });
    }
  };
  useEffect(() => {
    if (isEmpty(selected)) return;
    const pearId = masters.SHAPE?.find?.((shape) => shape.code === "PEAR")?.id;
    const roundId = masters.SHAPE?.find?.((shape) => shape.code === "ROUND")?.id;
    const caratLimit = selected.crt > maxLimit ? maxLimit : selected.crt;
    const obj = {
      ...selected,
      crt: caratLimit,
      shp: selected.shp?.[0] === roundId?.[0] ? roundId : pearId,
      // carat: selected.crt,
      // color: selected.col,
      // clarity: selected.clr,
      // price: selected.price,
      isCurrent: true,
    };

    const objData = {
      ...PRICE_CALC,
      request: obj,
    };

    UtilService.callApi(objData, (err, data) => {
      if (err) {
        if (err.data && err.data.message) {
          return OpenNotification({
            type: "error",
            title: err.data.message,
          });
        }
      } else if (data && data.code === "OK") {
        const pricelist = data.data[0].priceList.find((d) => {
          return selected.col.includes(d.col) && selected.clr.includes(d.clr);
        });
        diamondPrice({
          apiPrice: pricelist ? pricelist.price : 0,
          rap: selected.price,
          crt: selected.crt,
        });
      }
    });
  }, [selected]);

  return (
    <>
      <div className="priceCalculatorBlock">
        <div className="calcClose" onClick={() => onClick()}>
          <img src={closeSVG} alt="" />
        </div>
        <div className="priceCalculatorInner">
          <div className="calcTopBlock">
            <h2>
              <IntlMessage id="app.YourDiamond" />
            </h2>
            <div className="priceSelectBlock">
              <InputBlock
                value={selected.crt}
                onChange={(e) => setSelected({ ...selected, crt: e.target.value })}
                placeholder="99.99"
                type="number"
                label={<IntlMessage id="app.carat" />}
                onFocus={() => setActiveInput("crt")}
              />
              <SelectOption
                value={selected.shp && find(masters.SHAPE, { id: selected.shp }).name}
                selectValue={masters.SHAPE}
                label={<IntlMessage id="app.Shape" />}
                defaultValue={[masters.SHAPE?.[0].id]}
                onChange={(value) => setSelected({ ...selected, shp: value })}
              />
              <SelectOption
                value={selected.col && find(masterColor, { id: selected.col }).name}
                selectValue={masterColor}
                label={<IntlMessage id="app.Color" />}
                defaultValue={[masterColor?.[0].id]}
                onChange={(value) => setSelected({ ...selected, col: value })}
              />
              <SelectOption
                value={selected.clr && find(masterClarity, { id: selected.clr }).name}
                selectValue={masterClarity}
                defaultValue={[masterClarity?.[0].id]}
                label={<IntlMessage id="app.Clarity" />}
                onChange={(value) => setSelected({ ...selected, clr: value })}
              />
            </div>
          </div>
          <div className="priceBlock">
            <div className="priceViewLeft">
              <h3>
                <IntlMessage id="app.ListPrice" />
              </h3>
              <div className="priceViewBlock">
                <div className="priceValueItem">
                  <label>$/CT</label>
                  <span>{prices.price ? formatDecimal(prices.price) : 0}</span>
                </div>
                <div className="priceValueItem">
                  <label>
                    {" "}
                    $
                    <IntlMessage id="app.Total" />
                  </label>
                  <span>{prices.totalPrice ? prices.totalPrice : 0}</span>
                </div>
              </div>
            </div>
            <div className="priceEditRight">
              <h3>
                <IntlMessage id="app.your-price" />
              </h3>
              <div className="priceEditBlock">
                <div className="priceEditLeft">
                  <div className="priceValueItem">
                    <p style={{ fontSize: "14px" }}>$/CT</p>
                    <div>{prices.calcPricePerCarat ? formatDecimal(prices.calcPricePerCarat) : 0}</div>
                  </div>
                  <div className="priceValueItem" style={{ marginTop: "10px" }}>
                    <p style={{ fontSize: "14px" }}>
                      $
                      <IntlMessage id="app.Total" />
                    </p>
                    <div>{prices.calcAmount ? prices.calcAmount : 0}</div>
                  </div>
                </div>

                <div className="rapPriceRight">
                  <label className="commonLabel">
                    <IntlMessage id="app.rappercentage" />
                  </label>
                  <InputNumber
                    type="Number"
                    // min={-99}
                    // max={0}
                    value={selected.price}
                    onChange={(value) => setSelected({ ...selected, price: value })}
                    onFocus={() => setActiveInput("price")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="priceCalcType">
            <div onClick={() => setTypedNumber("7")} className="priceCalcTypeItem">
              7
            </div>
            <div onClick={() => setTypedNumber("8")} className="priceCalcTypeItem">
              8
            </div>
            <div onClick={() => setTypedNumber("9")} className="priceCalcTypeItem">
              9
            </div>
            <div onClick={() => setTypedNumber("4")} className="priceCalcTypeItem">
              4
            </div>
            <div onClick={() => setTypedNumber("5")} className="priceCalcTypeItem">
              5
            </div>
            <div onClick={() => setTypedNumber("6")} className="priceCalcTypeItem">
              6
            </div>
            <div onClick={() => setTypedNumber("1")} className="priceCalcTypeItem">
              1
            </div>
            <div onClick={() => setTypedNumber("2")} className="priceCalcTypeItem">
              2
            </div>
            <div onClick={() => setTypedNumber("3")} className="priceCalcTypeItem">
              3
            </div>
            <div onClick={() => setSigns()} className="priceCalcTypeItem">
              +/-
            </div>
            <div onClick={() => setTypedNumber("0")} className="priceCalcTypeItem">
              0
            </div>
            <div onClick={() => removeTypedNumber()} className="priceCalcTypeItem">
              <img src={removeSVG} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceCalculator;
