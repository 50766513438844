import React, { memo } from "react";

import { LAB_LINKS, FILE_URLS, LOCAL_STORAGE_VAR, PAGES } from "../../../constants/Common";
import useRowSelect from "../../../util/useRowSelect";
import CheckBox from "../../common/CheckBox";
import { formatNumber } from "../../common/DiamondListing/SelectStone";
import { STATUS, STATUS_COLOR } from "../../common/DiamondListing/Status";
import Image from "../../common/Image";
import { getParams } from "../DiamondListFunctions";

const LISTINGPAGES = PAGES;

const DiamondSearchGrid = (props) => {
  const { isRowChecked, toggleRowSelection } = useRowSelect(props.currentType);
  const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).SHAPE || {};
  return (
    <div className="diamondGridView">
      {!props.loading &&
        props.data.map((block) => {
          const isChecked = isRowChecked(block.id);
          return (
            <div key={`grid_${block.id}`} className="daimondGridViewItem">
              <div className="DiamondGridViewImg">
                <div className="diamondGridLabel">{/* <label>DD:1</label> */}</div>
                <div className="diamondGrdiCheckbox">
                  <CheckBox checked={isChecked} onChange={() => toggleRowSelection(block, !isChecked)} />
                </div>
                {true && <Image list={props.list} src={FILE_URLS.img.replace("***", block.vStnId)} alt="" />}
              </div>
              <div className="gridViewContent">
                <div className="gridLeftContent">
                  <h2>
                    {masterData?.find((x) => x.code === block.shpNm)?.webDisplay === undefined
                      ? block.shpNm
                      : masterData?.find((x) => x.code === block.shpNm)?.webDisplay}
                  </h2>
                  <a href={`/${LISTINGPAGES.DETAIL}/${block.id}`} target="_blank" rel="noopener noreferrer">
                    {block.vStnId}
                  </a>
                  <p>{getParams(block).join(" ")}</p>
                </div>
                <div className="gridRightContent">
                  <span
                    onClick={() => {
                      if (block.lbNm && block.rptNo) {
                        window.open(LAB_LINKS[block.lbNm.toUpperCase()].replace("", block.rptNo));
                      }
                    }}
                  >
                    {block.lbNm}
                  </span>
                  <span>
                    $
                    {formatNumber(Math.round(block.amt))}
                  </span>
                  <span className="statusShow">
                    {STATUS[block.wSts]}
                    <span className={`statusIcon ${  STATUS_COLOR[block.wSts]}`} />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(DiamondSearchGrid);
