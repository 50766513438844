import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";

import Auth from "./Auth";
import Commonm from "./Common";
import DashData from "./DashData";
import bidConfig from "./bidConfig";
import DeleteReminder from "./deleteReminder";
import Diamond from "./diamond";
import loader from "./loader";
import notifyReducer from "./notifyReducer";
import setTimer from "./timer";

const reducers = combineReducers({
  loader,
  routing: routerReducer,
  auth: Auth,
  commonData: Commonm,
  diamondData: Diamond,
  deleteReminder: DeleteReminder,
  setTimer,
  bidConfig,
  sales: DashData,
  notify: notifyReducer,
});

export default reducers;
