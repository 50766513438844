import React, { useCallback } from "react";

import urlJoin from "url-join";

import { Tooltip } from "antd";
import { memoize, find, cloneDeep, groupBy, size, uniq, each, omit } from "lodash";
import moment from "moment";

import { Storage } from "services/storage";
import { getStoneSummary } from "util/_extra-utils";
import { FILTER_COLUMNS } from "util/tableConstant";
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatNumber,
  getPathName,
  isArray,
  isEmpty,
  isNotEmpty,
  logInfo,
  reduceTotal,
} from "util/utils";

// import { floatkeys, roundkeys } from './TableBack';

import clsblkSVG from "../../assets/svg/DiamondList/close-black.svg";
import { trackTypeObj, LOCAL_STORAGE_VAR, DIAMOND_WEB_STATUS, PAGES, BASE_DOWNLOAD_URL } from "../../constants/Common";
import {
  SEARCH_DIAMOND_LIST,
  FETCH_CONFIRM_STONE,
  FETCH_DIAMOND_TRACK,
  CREATE_TRACK,
  UPDATE_TRACK,
  PRINT_PDF,
  EXPORT_EXCEL,
  CONFIRM_STONE,
  DELETE_TRACK,
  CREATE_BID,
  SEND_EMAIL_XRAY,
  FEATURE_STONE,
  BID_PAGINATE,
  BID_DELETE,
  UPDATE_BID,
  SEARCH_LIST,
  DOWNLOAD_ZIP,
  DOWNLOAD_FILE,
  NOTE_UPSERT,
  FETCH_NOTES,
  NOTE_DELETE,
  PROJECT_SETTING,
  FETCH_OFFICE_DIAMOND,
  DELETE_OFFICE_DIAMOND,
  DIAMOND_DATA_BY_ID,
  HIT_NOAUTH,
  DIAMOND_TRACK_STATUS_UPDATE,
  GET_MATCH_PAIR,
  EXCLUSIVE_SEARCH_LIST,
  COUNTER_OFFER,
  UNHOLD_TRACK,
  DISCOUNT_SETTING,
} from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { isNumber } from "../../util/utils";
import OpenNotification from "../common/CommonButton/OpenNotification";

const floatkeys = [
  "amt",
  "back",
  "cAng",
  "cHgt",
  "crt",
  "ctPr",
  "depPer",
  "fnDis",
  "grdlPer",
  "height",
  "length",
  "pAng",
  "pHgt",
  "rap",
  "rapAvg",
  "ratio",
  "strLn",
  "width",
];
const roundkeys = ["tblPer", "lwrHal"];

export function getPath(...args) {
  return getPathName(...args);
}

const LOCATION = {
  india: "IND",
  beligium: "BEL",
  newyork: "NY",
  hongkong: "HK",
  dubai: "DXB",
};
const NOTUPCOMING = ["amt", "ctPr", "rapAvg", "back", "rap"];
const DISPLAY_TOTAL = ["ctPr", "amt", "rap", "crt", "fnCtpr", "fnAmt"];

const LISTINGPAGES = PAGES;

export const TITLE = {
  [PAGES.LIST]: "Diamond List",
  [PAGES.UPCOMING]: "Upcoming",
  [PAGES.FANCY_SEARCH]: "Fancy Diamonds",
  [PAGES.EXCLUSIVE]: "Exclusive",
  BID: "New Arrival",
  [PAGES.STONE_OF_DAY]: "Stone Of The Day",
  [PAGES.NEWARR]: "New Arrivals",
  BITBUY: "Bid To Buy",
  [PAGES.MATCHPAIR]: "Match Pair",
  [PAGES.MYBID]: "My Bid",
  [PAGES.EXCLUSIVE]: "Exclusive Diamonds",
  [PAGES.COLLECTION]: "Offer List",
  [PAGES.CART]: "My Cart",
  [PAGES.WATCHLIST]: "My Watchlist",
  [PAGES.REMINDER]: "My Reminder",
  [PAGES.CONFIRM]: "My Order List",
  [PAGES.QUOTE]: "My Offer List",
  [PAGES.NOTE]: "My Notes",
  [PAGES.OFFICE]: "My View Request",
  pairdiamonds: "Pair Diamonds",
};

const ColumnFilter = React.memo((props) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];
      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x === id),
          1
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue]
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: "1px solid #e9ebf0" } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x ?? "-"}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img src={clsblkSVG} alt="icon" />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {availableOptions.length ? (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`} onClick={() => toggleFilter(x)}>
                {x ?? "-"}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
});

// Define a custom filter filter function!
function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;
export function isMobile() {
  return window.innerWidth <= 767;
}

export function getColumn(columnList = null) {
  const Columns = [];
  const master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`) || columnList) {
    const getColumnList = columnList || JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`));

    getColumnList.forEach((dataa) => {
      const data = {};
      Object.keys(dataa).map((x) => (data[x] = dataa[x]));
      if (data.isActive && data.field) {
        data.field = data.field.trim();
        data.title = data.title ? data.title.trim() : data.title;
        data.key = data.key ? data.key.trim() : data.key;
        if (find(Columns, { id: data.field })) return;
        if (data.field === "reminderDate" && getPath() !== LISTINGPAGES.REMINDER) return false;
        if (data.field === "remarks" && getPath() !== LISTINGPAGES.NOTE) return false;
        if (data.field === "remarks" && getPath() === LISTINGPAGES.NOTE) data.title = "Notes";
        if (NOTUPCOMING.includes(data.field) && getPath() === LISTINGPAGES.UPCOMING) return false;

        const canFilter = FILTER_COLUMNS.includes(data.field);

        let cell;

        if (!cell && ["rapAvg"].includes(data.field)) {
          cell = ({ cell }) => (
            <div className="numberValue">
              {isNumber(cell.value) && cell.value > 0 ? formatDecimal(cell.value) : "-"}
            </div>
          );
        }
        if (!cell && ["tblPer"].includes(data.field)) {
          cell = ({ cell }) => (
            <div className="numberValue">{isNumber(cell.value) ? formatDecimal(cell.value) : "-"}</div>
          );
        }

        if (!cell && floatkeys.includes(data.field)) {
          cell = ({ cell }) => <div className="numberValue">{formatDecimal(cell.value) ?? "-"}</div>;
        }

        if (!cell && roundkeys.includes(data.field)) {
          cell = ({ cell }) => <div className="numberValue">{formatNumber(Math.round(cell.value)) ?? "-"}</div>;
        }

        Columns.push({
          Header: data.title,
          accessor: data.field,
          id: data.field,
          desc: data.desc,
          link: data.link,
          sort: data.sort,
          sequence: data.sequence,
          Cell:
            cell ??
            (({ row, cell }) => {
              // className certificateNo redColor greenColor
              if (data.field === "dna") cell.value = "DNA";
              let locationTooltip = "";
              if (data.field === "locNm") {
                const locFound = cell.value && master ? find(master["LOCATION"], { id: [row.original.loc] }) : null;
                locationTooltip = locFound ? locFound.description : "";
              }
              const fullName = find(master?.[data?.key], { code: cell?.value })?.description ?? null;

              return (
                <div
                  className={
                    data.field === "dna" // data.field === 'stoneId' || data.field === 'vStnId'
                      ? "underline"
                      : floatkeys.includes(data.field) || roundkeys.includes(data.field)
                      ? "numberValue"
                      : null || data.field === "vStnId"
                      ? "packetNumber"
                      : null
                  }
                >
                  {data.isFlag && cell.value ? (
                    typeof data.sLabel === "string" ? (
                      data.sLabel
                    ) : (
                      "Yes"
                    )
                  ) : data.isFlag && !cell.value ? (
                    typeof data.nLabel === "string" ? (
                      data.nLabel
                    ) : (
                      "-"
                    )
                  ) : cell.value === true ? (
                    "Y"
                  ) : !cell.value ? (
                    "-"
                  ) : fullName ? (
                    <Tooltip key={cell.row.id + cell.column.id} title={fullName}>
                      {cell.value}
                    </Tooltip>
                  ) : data.field === "reminderDate" ? (
                    moment(row.original.reminderDate).format("ll")
                  ) : row.original.isFcCol && data.field === "colNm" ? (
                    <div className="normalTableDropdown">
                      <span className="tableDropdownTitle">{row.original.fcColNm}</span>
                      <div className="tableDropdown">
                        {[row.original.intenNm, row.original.ovrtnNm, row.original.fcColNm]
                          .filter(isNotEmpty)
                          .map((d, index) => (
                            <span key={index}>{d}</span>
                          ))}
                      </div>
                    </div>
                  ) : data.field === "locNm" ? (
                    <Tooltip title={locationTooltip}>{LOCATION[cell.value.trim().toLowerCase()] || cell.value}</Tooltip>
                  ) : (
                    cell.value
                  )}
                </div>
              );
            }),
          Filter: canFilter ? ColumnFilter : <></>,
          filter: filterArrayIncludes,
        });

        if (data.field === "vStnId" && getPath() === LISTINGPAGES.CONFIRM) {
          Columns.push({
            Header: "Invoice",
            accessor: "invoice",
            id: "invoice",
            desc: data.desc,
            link: data.link,
            Cell: () => "Invoice",
            Filter: canFilter ? ColumnFilter : <></>,
            filter: filterArrayIncludes,
          });
        }
      }
    });
  }
  if (getPath() === LISTINGPAGES.OFFICE) {
    const OFFERSTATUS = {
      1: "Pending",
      2: "Accepted",
      3: "Rejected",
    };
    Columns.push({
      Header: "Status",
      accessor: "status",
      id: "status",
      desc: "",
      link: "",
      Cell({ row }) {
        return (
          <div className="activeStatus pending">
            <span>{OFFERSTATUS[row.original.status]}</span>
          </div>
        );
      },
      Filter: <></>,
      filter: filterArrayIncludes,
    });
  }
  if (
    UtilService.getLocalStorageItem(
      window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
    )
  ) {
    return Columns.filter((el) => !["back", "amt", "ctPr"].includes(el.id));
  } else {
    return Columns;
  }
}
export const memoizedGetColumn = memoize(getColumn, (...args) => JSON.stringify(args));

export function handleAlter(array, item, key) {
  let checked = cloneDeep(array) || [];
  if (find(checked, { [key]: item[key] })) checked = checked.filter((el) => el[key] !== item[key]);
  else checked.push(item);
  return checked;
}
export function handleHitCounter(id) {
  const objData = {
    ...DIAMOND_DATA_BY_ID,
    request: {
      isHitCounter: true,
      id,
    },
  };
  UtilService.callApi(objData, async (err) => {
    if (err) throw err;
    // if (data && data.code === 'OK') { }
  });
}
export function handleHitCounterNoAuth(id) {
  const objData = {
    ...HIT_NOAUTH,
    request: {
      id,
    },
  };
  UtilService.callApi(objData, async (err) => {
    if (err) throw err;
    // if (data && data.code === 'OK') { }
  });
}

export function getParams(props_row) {
  const params = [
    // inside ? null : props_row.shpNm,
    // inside ? null : props_row.lbNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.colNm,
    // props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
    // inside ? null : props_row.fluNm
  ].filter((el) => {
    return el && el;
  });
  return params;
}

export const getStoneInfo = getStoneSummary;

export function getStoneTitle(obj) {
  const params = obj.map((data) => ({
    shp: data.shpNm,
    crt: parseFloat(data.crt).toFixed(2),
    col: data.isFcCol ? data.fcColNm : data.colNm,
    clr: data.clrNm,
    cut: data.cutNm,
    pol: data.polNm,
    sym: data.symNm,
  }));
  let display = [];
  const keyArr = ["shp", "crt", "col", "clr", "cut", "pol", "sym"];
  keyArr.forEach((key) => {
    const word = groupBy(params, key);
    if (size(word) === 1) display.push(params[0][key]);
  });
  display = display.filter((el) => el && el).join(" ");
  return display;
}

export function compareStones(list) {
  if (list.length === 0) {
    OpenNotification({
      type: "error",
      title: "Please select stone(s) to compare.",
    });
    return;
  }
  if (list.length > 15) {
    OpenNotification({
      type: "error",
      title: "Maximum 15 stones can be compared.",
    });
    return;
  }
  let data =
    // localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
    // ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
    // :
    [];
  list.map((x) => data.push(x.id));
  data = uniq(data);
  if (data.length === 1) {
    OpenNotification({
      type: "error",
      title: "Please select minimum two stones to compare.",
    });
    return;
  }
  if (data.length > 15) {
    data = data.slice(data.length - 15, data.length);
  }
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-compare-diamond`, JSON.stringify(data));
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-now`, JSON.stringify(window.location.pathname + window.location.search));
  window.open("/compare");
}

export function fetchDiamondList(state, filters, cb) {
  if (filters && filters.length && Array.isArray(filters[0].diamondSearchId)) {
    filters[0].diamondSearchId = filters[0].diamondSearchId.join(",");
  }

  const obj = SEARCH_DIAMOND_LIST;
  obj.request = {
    filters,
    isAppendMasters: true,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    isSkipSave: true,
  };
  UtilService.callApi(obj, (err, res) => {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let needLogout = false;
    const filterArray = {};
    if (res && res?.code === "OK") {
      const data = res?.data?.[0];
      seachFilterTag = data?.filter;
      rows = data?.diamonds ? data?.diamonds : [];
      sum = data?.total ? data?.total[0] : [];
      count = data?.count;
      needLogout = !!data?.guestLoginFlag;
      FILTER_COLUMNS.forEach((x) => {
        if (rows && rows.length) {
          filterArray[x] = uniq(rows.map((r) => r[x]));
        }
      });
      inTrackDiamonds = data?.inTrackDiamonds ? data?.inTrackDiamonds : [];
    }
    cb({
      data: needLogout ? [] : rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      needLogout,
    });
  });
}

export function fetchStoneOfDay(state, type, cb) {
  const obj = FEATURE_STONE;
  obj.request = {
    type,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    const sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === "OK") {
      rows = data.data.list;
      count = data.data.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function handleExclusiveSearchListId(cb) {
  Storage.set("last-filter", undefined);

  const searchAPI = SEARCH_DIAMOND_LIST;
  const searchAPIObj = {
    filters: [
      {
        // or: [
        //   { crt: { '>=': '5.00', '<=': '5.99' } },
        //   { crt: { '>=': '6.00', '<=': '9.99' } },
        //   { crt: { '>=': '10.00', '<=': '19.99' } },
        //   { crt: { '>=': '20.000', '<=': '100' } },
        // ],
        wSts: "E",
      },
    ],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };
  const objData = {
    ...searchAPI,
    request: searchAPIObj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (data && data.code === "OK") {
      const obj = SEARCH_LIST;
      obj.request = {
        id: data.data[0].filter.id,
        isAppendMasters: true,
      };
      UtilService.callApi(obj, (err, res) => {
        const output = res && res?.code === "OK" ? res?.data?.list?.[0]?.searchData : null;
        Storage.set("last-filter", output);
        cb(output);
      });
    } else cb(null);
  });
}

export function handleFancySearchList(state, cb) {
  const obj = SEARCH_DIAMOND_LIST;
  obj.request = {
    page: state.page,
    limit: state.limit,
    filters: [{ isFcCol: true }],
    sort: state.sort,
  };

  UtilService.callApi(obj, (err, res) => {
    Storage.set("last-filter", undefined);

    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    const filterArray = {};
    if (res && res?.code === "OK") {
      const data = res?.data?.[0];
      seachFilterTag = data?.filter;
      rows = data?.diamonds;
      sum = data?.total[0] || {};
      count = data?.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      Storage.set("last-filter", data?.filter?.searchData);
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
    });
  });
}
export function handlewhiteSearchList(state, cb) {
  const obj = SEARCH_DIAMOND_LIST;
  obj.request = {
    page: state.page,
    limit: state.limit,
    filters: [
      {
        isFcCol: false,
      },
    ],
    sort: state.sort,
  };

  UtilService.callApi(obj, (err, res) => {
    Storage.set("last-filter", undefined);

    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    const filterArray = {};
    if (res && res?.code === "OK") {
      const data = res?.data?.[0];
      seachFilterTag = data?.filter;
      rows = data?.diamonds;
      sum = data?.total[0] || {};
      count = data?.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      Storage.set("last-filter", data?.filter?.searchData);
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
    });
  });
}

export function handleCollectionId(cb) {
  Storage.set("last-filter", undefined);

  const filters = [{ or: [{ wSts: "k" }] }];

  const reqConf = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      filters,
      isNotReturnTotal: true,
      isReturnCountOnly: true,
    },
  };
  UtilService.callApi(reqConf, (err, res) => {
    if (res && res.code === "OK") {
      const obj = EXCLUSIVE_SEARCH_LIST;
      obj.request = {
        diamondSearchId: res?.data?.[0]?.filter.id,
        isAppendMasters: true,
        page: 1,
        limit: 250,
        sort: [],
      };
      UtilService.callApi(obj, (err2, res2) => {
        if (res2.code !== "OK") return cb(null);
        Storage.set("last-filter", res?.data?.[0]?.filter.searchData);
        cb(res2?.data?.list?.[0]?.id);
      });
    } else cb(null);
  });
}

export function fetchOffer(state, cb) {
  const obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.QUOTE,
      sort: state.sort,
      offerStatus: state.offerStatus,
    },
  };
  UtilService.callApi(obj, (err, res) => {
    let data = [];
    let count = 0;
    let sum = {};
    const filterArray = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];

    if (!err && res?.code === "OK") {
      const list = res.data.list.map((record) => ({
        ...record,
        ...record?.diamond,
        trackId: record.id,
        groupKey: formatDateTime(record.createdAt),
      }));
      data = Object.values(groupBy(list, "groupKey"))
        .map((list) => {
          const groupTitle = [
            `Created At: ${formatDateTime(list?.[0]?.createdAt)}`,
            `Expires At: ${formatDate(list?.[0]?.offerValidDate)}`,
            `Pcs.: ${list?.length ?? "-"}`,
            `Total Cts.: ${formatDecimal(reduceTotal(list, "crt") ?? 0)}`,
            `Total Amount: ${formatCurrency(reduceTotal(list, "newAmount") ?? 0)}`,
          ].join(" | ");

          list[0].isOfferHeader = true;
          list[0].isGroupHeader = true;
          list.forEach((rec) => {
            rec.groupTitle = groupTitle;
          });
          return list;
        })
        .flat();

      sum = res?.data?.total?.[0];
      count = res?.data?.count;
      inTrackDiamonds = res?.data?.inTrackDiamonds;
      FILTER_COLUMNS.forEach((col) => void (filterArray[col] = uniq(data.map((r) => r[col]))));
    }

    cb({
      sum,
      data,
      count,
      filterArray,
      seachFilterTag,
      inTrackDiamonds,
      selectedFilterArray: {},
    });
  });
}

export function fetchBid(state, bidId, resultType, type, status, cb) {
  const obj = BID_PAGINATE;
  obj.request = {
    bidType: type,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    status,
    from: type.length === 2 && state.date.from ? state.date.from.toISOString() : null,
    to: type.length === 2 && state.date.to ? state.date.to.toISOString() : null,
    // ...(resultType &&{ dynamicBidType: bidId }),
    ...(resultType && !undefined && { dynamicBidType: bidId }), //declined the filter because we want both types of bids
  };

  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        trackId: x.id,
        LowerBid: x.LowerBid,
        bidAmount: x.bidAmount,
        bidDiscount: x.bidDiscount,
        bidPricePerCarat: x.bidPricePerCarat,
        bidType: x.bidType,
        status: x.status,
        updatedAt: x.updatedAt,
        grpBy: x.updatedAt.split("T")[0],
      }));
      if (type.length === 2) {
        const grp = groupBy(rows, "grpBy");
        rows = [];
        Object.keys(grp).forEach((grpNo) => {
          const val = grp[grpNo];
          val[0].isBidHeader = true;
          val[0].groupingTitle = moment(val[0].updatedAt).format("DD/MM/YYYY");
          rows = [...rows, ...val];
        });
      }
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

// export function fetchMatchPair(state, diamondSearchId, cb) {
export function fetchMatchPair(state, id, cb) {
  const obj = GET_MATCH_PAIR;
  obj.request = {
    filter: {
      diamondSearchId: id,
    },
    page: state.page,
    limit: state.limit,
    sort: state.sort,
  };
  if (!id) {
    obj.request.filter = {};
  }
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === "OK") {
      const grp = groupBy(data.data.list, "groupNo");
      Object.keys(grp).forEach((grpNo) => {
        const val = grp[grpNo];
        val[0].isMatchHeader = true;
        val[0].groupingTitle = getStoneTitle(val);
        val[0].totalDiamonds = val.length;
        val[0].groupData = {};
        DISPLAY_TOTAL.map((i) => {
          val[0].groupData[i] = [];
          val.map((x) => {
            if (x[i]) val[0].groupData[i].push(x[i]);
            return true;
          });
          return true;
        });
        rows = [...rows, ...val];
      });
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchConfirmStone(state, cb) {
  const obj = FETCH_CONFIRM_STONE;
  obj.request = {
    page: state.page,
    limit: state.limit,
    filter: { status: state.status },
    isPdf: state.isPdf ? state.isPdf : false,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    const sum = {};
    const seachFilterTag = {};
    const inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === "OK") {
      if (data.data && data.data.list && data.data.list.length) {
        const grp = data.data.list.filter((x) => x.memoDetails && x.memoDetails.length);
        each(grp, (x) => {
          const val = x.memoDetails;
          const obj = omit(x, "memoDetails");
          val[0] = {
            ...obj,
            ...val[0],
            comment: x.comment,
            isConfirmHeader: true,
            totalDiamonds: val.length,
          };
          rows = [...rows, ...val];
        });
      } else {
        // const server = BASE_DOWNLOAD_URL;
        // const file_path = server + data.data;
        const file_path = urlJoin(BASE_DOWNLOAD_URL, data.data);

        window.open(file_path, "Download");
      }

      // rows = uniqBy(rows, "vStnId");
      count = data.data.count;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchCart(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.CART,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => x.diamond);
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchOfficeList(state, cb) {
  const obj = FETCH_OFFICE_DIAMOND;
  obj.request = {
    page: state?.page,
    limit: state?.limit,
    sort: [{ date: "ASC" }],
    filter: { status: state?.status },
    newArrivalShow: true,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      if (!isEmpty(data.data)) {
        const grp = data.data.list.filter((x) => x.diamonds && x.diamonds.length);
        each(grp, (x) => {
          const val = x.diamonds;
          val[0] = {
            ...val[0],
            isOfficeHeader: true,
            cabinSlot: x.cabinSlot,
            date: x.date,
            // slottId: x.id,
          };
          each(val, (all) => {
            all.slottId = x.id;
            all.status = x.status;
          });
          rows = [...rows, ...val];
        });

        // rows = uniqBy(rows, "vStnId");
        count = data.data.count;
        sum = data.data.total;
        FILTER_COLUMNS.forEach((x) => {
          filterArray[x] = uniq(rows.map((r) => r[x]));
        });
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchWatch(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.WATCHLIST,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        newDiscount: x.newDiscount,
        trackDiscount: x.trackDiscount,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchNotes(state, cb) {
  const obj = FETCH_NOTES;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        remarks: x.remarks,
        trackId: x.id,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchReminder(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.REMINDER,
    sort: state.sort,
  };
  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        reminderDate: x.reminderDate,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchDiamondPaginate(params, type, cb) {
  const { page, limit, sort, filters, diamondSearchId } = params;
  const obj = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      page,
      limit,
      ...(isArray(diamondSearchId)
        ? { diamondSearchId }
        : {
            sort,
            viewType: type.viewType,
            filters: type.viewType ? filters : [{ wSts: type }],
          }),
    },
  };

  if (type === DIAMOND_WEB_STATUS.UPCOMING) {
    obj.request.isUpcoming = true;
  }

  UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === "OK") {
      seachFilterTag = data.data[0].filter;
      rows = data.data[0].diamonds;
      if (type === DIAMOND_WEB_STATUS.UPCOMING) {
        const grp = groupBy(rows, "inDt");
        rows = [];
        Object.keys(grp).forEach((x) => {
          grp[x][0].isUpcomingHeader = true;
          grp[x][0].totalDiamonds = grp[x].length;
          rows = [...rows, ...grp[x]];
        });
      }
      sum = data.data[0].total[0] || {};
      count = data.data[0].count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const handleInsertTrack = (trackType, selected, cb, date, remark) => {
  const diamonds = [];
  selected.map((s) =>
    diamonds.push({
      diamond: s.id,
      trackPricePerCarat: s.ctPr,
      trackAmount: s.amt,
      newDiscount: trackType === trackTypeObj.WATCHLIST ? s.back : null,
      trackDiscount: trackType === trackTypeObj.WATCHLIST ? s.back : null,
    })
  );
  const obj = {
    reminderDate: date,
    trackType,
    diamonds,
    remark,
  };
  const objData = {
    ...CREATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    const flag =
      trackType !== trackTypeObj.REMINDER
        ? true
        : !(
            data &&
            data.data &&
            data.data.created &&
            data.data.created.length === 0 &&
            data.data.exists &&
            data.data.exists.length !== 0
          )
        ? false
        : true;
    if (data && data.code === "OK" && flag) {
      if (trackType === trackTypeObj.CART || trackType === trackTypeObj.WATCHLIST) {
        if (data?.data?.created.length) {
          cb(true);
        } else {
          cb(false);
        }
      } else {
        cb(true);
      }
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    }
    OpenNotification({
      type: "error",
      title: data ? data.message : err && err.data ? err.data.message : "",
    });
    cb(false);
  });
};

export function handleNoteUpsert(selected, cb) {
  const obj = NOTE_UPSERT;
  obj.request = {
    diamonds: selected.map((d) => ({ diamond: d.id, remarks: d.note.trim() })),
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
}

export const handleUpsertTrack = (trackType, selected, inTrackDiamonds, cb, date) => {
  const ids = selected.map((x) => x.id);
  const id = [];
  const diamonds = find(inTrackDiamonds, { _id: trackType }) ? find(inTrackDiamonds, { _id: trackType }).diamonds : [];
  if (diamonds) {
    each(ids, (diamondId) => {
      const trackId = find(diamonds, { id: diamondId });
      if (trackId) id.push(find(diamonds, { id: diamondId }).trackId);
    });
  }
  const obj = {
    reminderDate: date,
    trackType,
    id,
  };
  const objData = {
    ...UPDATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (data && data.code === "OK") {
      cb(true);
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    }
    OpenNotification({
      type: "error",
      title: data ? data.message : err && err.data ? err.data.message : "",
    });
    cb(false);
  });
};

export const handlePrint = (filter, id, isOrder) => {
  let obj = {
    id,
  };
  if (isOrder) {
    delete obj.id;
    obj.orderDiamond = id;
  }
  if (isEmpty(id)) {
    obj = { filter };
  }
  const objData = {
    ...PRINT_PDF,
    request: obj,
  };
  UtilService.callApi(objData, async (err, data) => {
    if (err) {
      throw err;
    }
    if (data && data.code === "OK") {
      // const server = BASE_DOWNLOAD_URL;
      // const file_path = server + data.data;
      const file_path = urlJoin(BASE_DOWNLOAD_URL, data.data);
      window.open(file_path, "Download");
    }
  });
};

export const handleDownloadExcel = (filter = {}, id = [], cb) => {
  const objData = {
    ...EXPORT_EXCEL,
    request: isEmpty(filter) ? { id } : { filter },
  };
  UtilService.callApi(objData, async (err, data) => {
    if (err) {
      OpenNotification({
        type: "error",
        title: err?.data?.message,
      });
      // throw err;
    }
    if (data && data.code === "OK") {
      // const server = BASE_DOWNLOAD_URL;
      // const file_path = server + data.data.data;
      const file_path = urlJoin(BASE_DOWNLOAD_URL, data.data.data);
      window.open(file_path, "Download");
      cb(true);
    }
  });
};

export function handleConfirmStone(
  ids,
  comment,
  date,
  company,
  cb,
  terms,
  preferShippingCompany,
  courierAccountNo,
  isFromCounterOffer
) {
  const obj = {
    ...CONFIRM_STONE,
    request: {
      diamonds: ids,
      comment,
      company,
      date,
      terms,
      preferShippingCompany,
      courierAccountNo,
      isFromCounterOffer,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        // title: err ? err.data : '',
        title: data ? data.message : err ? err.data.message : "",
      });
      cb(false);
    }
  });
}

export function handleNoteDelete(id, cb) {
  const obj = NOTE_DELETE;
  obj.request = { id };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err.data.message,
      });
      cb(null);
    }
  });
}

export function handleTrackDelete(trackType, ids, inTrackDiamonds, cb) {
  const id = [];
  const diamonds = find(inTrackDiamonds, { _id: trackType }) ? find(inTrackDiamonds, { _id: trackType }).diamonds : [];
  if (diamonds) {
    each(ids, (diamondId) => {
      const trackId = find(diamonds, { id: diamondId });
      if (trackId) id.push(find(diamonds, { id: diamondId }).trackId);
    });
  }
  const obj = {
    ...DELETE_TRACK,
    request: {
      trackType,
      id,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
}

export const unHoldFromCart = (trackType, ids, inTrackDiamonds, cb) => {
  const id = [];
  const diamonds = find(inTrackDiamonds, { _id: trackType }) ? find(inTrackDiamonds, { _id: trackType }).diamonds : [];
  if (diamonds) {
    each(ids, (diamondId) => {
      const trackId = find(diamonds, { id: diamondId });
      if (trackId) id.push(find(diamonds, { id: diamondId }).trackId);
    });
  }
  const obj = {
    ...UNHOLD_TRACK,
    request: {
      trackIds: id,
    },
  };
  UtilService.callApi(obj, (err, res) => {
    if (res?.code === "OK") {
      OpenNotification({
        type: "success",
        title: res?.message ? res.message : "Remove Stone(s) successfully.",
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: res ? res.message : err && err.res ? err.res.message : "Failed to remove stone(s).",
      });
    }
  });
};

export function handleBidDelete(id, cb) {
  const obj = BID_DELETE;
  obj.request = { id };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: data ? data.message : err && err.data ? err.data.message : "",
      });
      cb(false);
    }
  });
}

export const handleSendEmail = (apiObj, cb) => {
  const obj = {
    ...EXPORT_EXCEL,
    request: apiObj,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      cb(true);
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    }
    cb(false);
    return OpenNotification({
      type: "error",
      title: data.message,
    });
  });
};

export function handleBidUpsert(rows, id, code, cb) {
  const obj = getPath() === LISTINGPAGES.MYBID ? UPDATE_BID : CREATE_BID;
  obj.request =
    getPath() === LISTINGPAGES.MYBID
      ? {
          diamonds: rows.map((x) => ({
            id: x.trackId,
            bidPricePerCarat: x.bidPricePerCarat,
            bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
            bidAmount: x.bidAmount,
            trackPricePerCarat: x.ctPr,
            trackAmount: x.amt,
            trackDiscount: x.back,
            deviceType: 1,
            status: x.status,
          })),
        }
      : {
          // bidType: type,
          bidConfigurationId: id,
          subTypeCode: code,
          diamonds: rows.map((x) => ({
            diamond: x.id,
            bidPricePerCarat: x.bidPricePerCarat,
            bidAmount: x.bidAmount,
            bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
            trackPricePerCarat: x.ctPr,
            trackAmount: x.amt,
            trackDiscount: x.back,
            vnd: x.vnd,
            vStnId: x.vStnId,
          })),
        };

  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

function offerObject(item) {
  // const offerValidDate = DateTime.local()
  //   .plus({ hour: item.hours ?? 2 })
  //   .toISO();

  return {
    vStnId: item.vStnId,
    diamond: item.id,
    // offerValidDate,

    newAmount: item.calcAmount,
    newPricePerCarat: item.calcPricePerCarat,
    newDiscount: item.calcDiscount,

    trackAmount: item.calcAmount,
    trackPricePerCarat: item.calcPricePerCarat,
    trackDiscount: item.calcDiscount,
  };
}

export function handleSaveQuote(
  selectedStones,
  date,
  remarks,
  cb,
  update,
  terms,
  preferShippingCompany,
  courierAccountNo
) {
  const TRACK = update ? COUNTER_OFFER : CREATE_TRACK;
  TRACK.request = update
    ? {
        tracks: selectedStones.map((stone) => ({
          ...offerObject(stone, true),
          preferShippingCompany,
          offerValidDate: date,
          id: stone.trackId,
          courierAccountNo,
          remarks,
        })),
      }
    : {
        trackType: trackTypeObj.QUOTE,
        diamonds: selectedStones.map((x) => ({
          ...offerObject(x),
          offerValidDate: date,
          // id: stone.trackId,
        })),
        offerList: getPath() === PAGES.COLLECTION ? true : undefined,
        remarks,
        terms,
        preferShippingCompany,
        courierAccountNo,
      };

  UtilService.callApi(TRACK, (err, data) => {
    if (data && data.code === "OK") {
      if (update) {
        OpenNotification({
          type: "success",
          title: data.message,
        });
        cb(true);
      } else {
        OpenNotification({
          type: data.data.created.length === 0 ? "error" : "success",
          title: data.message,
        });
        if (data.data.created.length > 0) cb(true);
        else cb(false);
      }
    } else {
      OpenNotification({
        type: "error",
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

export const handleSendXRayEmail = (apiObj, path, cb) => {
  apiObj.message = `${apiObj.message} <a href=${path}> View File</a>`;
  const obj = {
    ...SEND_EMAIL_XRAY,
    request: apiObj,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      cb(true);
      return OpenNotification({
        type: "success",
        title: data.message,
      });
    }
    cb(false);
    return OpenNotification({
      type: "error",
      title: data.message,
    });
  });
};

export function allSortObject(columns) {
  const sort = {};
  each(columns, (col) => {
    // if (col.id !== 'Details') sort[col.id] = col.Header
    if (col.sort) sort[col.sort] = col.Header;
  });
  return sort;
}

export function getProjectSetting(type, cb) {
  const obj = PROJECT_SETTING;
  obj.request = {
    filter: {
      type,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") cb(data.data.data);
    else cb([]);
  });
}

export function getSetting(type, cb) {
  const obj = DISCOUNT_SETTING;
  obj.request = { type };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") cb(data.data.data);
    else cb([]);
  });
}

export const downloadZip = (id, cb) => {
  const obj = {
    method: DOWNLOAD_ZIP.method,
    url: DOWNLOAD_ZIP.url,
    request: id,
    showLoader: true,
  };

  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === "OK") {
      if (isArray(data.data)) {
        data.data.forEach((url) => window.open(urlJoin(BASE_DOWNLOAD_URL, url), "Download"));
      } else {
        const file_path = urlJoin(BASE_DOWNLOAD_URL, data.data);
        window.open(file_path, "Download");
      }
    } else if (data && data.code === "E_NOT_FOUND") {
      OpenNotification({
        type: "error",
        title: data.message,
      });
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
    }
    cb(true);
  });
};
export const downloadFile = (request = {}) => {
  document.body.classList.add("loading-indicator");
  UtilService.callApi({ ...DOWNLOAD_FILE, request }, async (err, res) => {
    document.body.classList.add("loading-indicator");

    try {
      if (err || res?.code !== "OK")
        return OpenNotification({
          type: "error",
          title: err.data.message || res.message,
        });

      await new Promise((r) => setTimeout(r, 1000));
      const file_path = urlJoin(BASE_DOWNLOAD_URL, res.data.path);
      window.open(file_path);
      // window.open(`${BASE_DOWNLOAD_URL}${res.data.path}`);
      // const response = await axios.get(`${BASE_DOWNLOAD_URL}${res.data.path}`, {
      //   responseType: 'blob',
      // });

      // const url = URL.createObjectURL(response.data);
      // const tag = document.createElement('a');
      // void ((tag.href = url), (tag.download = request.fileName));
      // void (document.body.appendChild(tag), tag.click(), tag.remove());
      // document.body.classList.remove('loading-indicator');

      // const xhr = new XMLHttpRequest();
      // xhr.onload = function () {
      //   const url = URL.createObjectURL(this.response);
      //   const tag = document.createElement('a');
      //   void ((tag.href = url), (tag.download = request.fileName));
      //   void (document.body.appendChild(tag), tag.click(), tag.remove());
      //   document.body.classList.remove('loading-indicator');
      // };

      // xhr.responseType = 'blob';

      // xhr.open('GET', `${BASE_DOWNLOAD_URL}${res.data.path}`, true);
      // xhr.send();
    } catch (error) {
      logInfo({ error });
      document.body.classList.remove("loading-indicator");
    }
  });
};

export const downloadVideo = (apiObj) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", apiObj.path, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement("a");
      tag.href = imageUrl;
      tag.target = "_blank";
      tag.download = apiObj.fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.onerror = (err) => {
      alert(err);
    };
    xhr.send();
  } catch (e) {
    logInfo(e);
  }
};

export const handleDeleteOffice = (filter, cb) => {
  const objData = {
    ...DELETE_OFFICE_DIAMOND,
    request: filter,
  };
  UtilService.callApi(objData, (err, data) => {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(true);
    }
  });
};

export const DiamondTrackStatusUpdate = (id, offerStatus, cb) => {
  const objData = {
    ...DIAMOND_TRACK_STATUS_UPDATE,
    request: { id, ...offerStatus },
  };
  UtilService.callApi(objData, async (err, data) => {
    if (data && data.code === "OK") {
      OpenNotification({
        type: "success",
        title: data.data.message || data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: "error",
        title: err.data.message || data.message,
      });
      cb(false);
    }
  });
};
