import React from "react";

import { classNames } from "../../util/utils";

function LeftSliderSlide(props) {
  const { className, children, ...restProps } = props;

  return (
    <div className={classNames(["diamondDetailImageBox", className])} {...restProps}>
      {children}
    </div>
  );
}

export default React.memo(LeftSliderSlide);
