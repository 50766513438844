import React from "react";

import { LOCAL_STORAGE_VAR, USERS_TYPE } from "constants/Common";

import CommonModal from "./common/CommonModal";

import UtilService from "../services/util";

export const GuestUserPopup = (props) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    setVisible(user.type === USERS_TYPE.PERMANENT_GUEST ? props.visible : false);
  }, [props.visible]);

  return (
    <div>
      <CommonModal
        noCancelGuest={props.noCancel}
        noCancel={props.noCancel}
        visible={visible}
        handleOk={props.register}
        handleCancel={() => props.handleCancel()}
        title="Note"
        modalSize="xs-size"
        footerShow
        submitTitle="Register"
        cancelTitle="Continue as a Guest"
      >
        <div>
          <p>You are currently logged in as a guest user. Please register yourself to access this page!</p>
        </div>
      </CommonModal>
    </div>
  );
};
