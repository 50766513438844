import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";

import _ from "lodash";

// import IntlMessage from 'util/IntlMessage';

import { GuestUserPopup } from "./GuestUserPopup";
import { NoKycPop } from "./NoKycPop";
import getData from "./common/svgSidebar";

import "react-tippy/dist/tippy.css";
// import jpg2 from '../assets/img/header/2.jpg';
// import jpg3 from '../assets/img/header/3.jpg';
// import fancyDiaSVG from '../assets/img/header/Fancy-diamond.jpg';
// import dropdownImg from '../assets/img/header/dropdown.jpg';
import searchSVG from "../assets/svg/Header/search.svg";
import { LOCAL_STORAGE_VAR, KYC_STATUS } from "../constants/Common";
import UtilService from "../services/util";

// import down from 'assets/svg/downarrow.svg';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGuest: UtilService.getLocalStorageItem(
        window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
      ),
      showGuest: false,
      noKyc: false,
      isKyc: false,
      sidebarSvgFiles: [],
    };
  }
  componentDidMount() {
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    const array = [];
    const permissionData = this.props.commonData.pagePermission();
    const sidebarSvg = getData(permissionData).filter((i) => i.name.props.id !== "app.myAccount");

    sidebarSvg.forEach((data) => {
      array.push({ ...data, isActive: false });
    });
    this.setState({
      sidebarSvgFiles: array,
    });

    if (authUser && authUser.account) {
      if (!authUser.account.isKycUploaded) {
        this.setState({ isKyc: !authUser.account.isKycUploaded });
      } else {
        if (authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.authUser && nextProps.auth.authUser.account) {
      if (!nextProps.auth.authUser.account.isKycUploaded) {
        this.setState({ isKyc: !nextProps.auth.authUser.account.isKycUploaded });
      } else {
        if (nextProps.auth.authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }
  setSvgFlages = () => {
    const array = this.state.sidebarSvgFiles;
    array.forEach((data) => {
      data.isActive = false;
    });
  };

  handleButton = (index) => {
    this.setSvgFlages();
    const array = this.state.sidebarSvgFiles;
    array[index].isActive = true;
    this.setState({
      sidebarSvgFiles: array,
    });
  };

  render() {
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;
    const crnt_url = window.location.pathname;
    const permissionData = this.props.commonData.pagePermission();
    const sidebarSvgFiles = getData(permissionData).filter((i) => i.name.props.id !== "app.myAccount");

    const cuIndex = _.findIndex(sidebarSvgFiles, { url: crnt_url });
    const { totalNewArrDiamondCountBywebStatus, totalBidDiamondCountBywebStatus } = this.props.commonData;

    return (
      <div className="headerMenuBox">
        <div className="headerMeniInner">
          <ul id="links" className="">
            {this.state.sidebarSvgFiles.map((dataSvg, index) => {
              return (
                <>
                  {this.props.showOnlySearch && this.props.showOnlySearch !== undefined ? (
                    <li
                      key={index}
                      id={`icon_click${index}`}
                      style={{ color: dataSvg.isActive ? "red" : "black" }}
                      className={
                        dataSvg.isActive || index === cuIndex
                          ? "d-flex align-items-center active-sidebar line-h-60"
                          : "d-flex align-items-center line-h-60"
                      }
                      onClick={() => this.handleButton(index)}
                    >
                      <div className="posi-relative">
                        {!this.state.isGuest ? (
                          dataSvg.url === "/diamond-search" ? (
                            <Link to={dataSvg.url} className="menuItem d-flex-search" id="step1">
                              {dataSvg.name}
                              <img src={searchSVG} alt="" />
                            </Link>
                          ) : this.state.isKyc ? (
                            <>
                              {dataSvg.name.props.id === "app.new-Arrival" && totalNewArrDiamondCountBywebStatus && (
                                <span
                                  onClick={() => {
                                    this.setState({
                                      noKyc: true,
                                    });
                                  }}
                                  className="menuItem "
                                >
                                  {dataSvg.name}
                                </span>
                              )}
                              {dataSvg.name.props.id === "app.bid-to-buy" && totalNewArrDiamondCountBywebStatus && (
                                <span
                                  onClick={() => {
                                    this.setState({
                                      noKyc: true,
                                    });
                                  }}
                                  className="menuItem "
                                >
                                  {dataSvg.name}
                                </span>
                              )}
                              {dataSvg.name.props.id !== "app.new-Arrival" &&
                                dataSvg.name.props.id !== "app.bid-to-buy" && (
                                  <span
                                    onClick={() => {
                                      this.setState({
                                        noKyc: true,
                                      });
                                    }}
                                    className="menuItem "
                                  >
                                    {dataSvg.name}
                                  </span>
                                )}
                            </>
                          ) : (
                            <></>
                          )
                        ) : dataSvg.url === "/diamond-search" ? (
                          <Link to={dataSvg.url} className="menuItem ">
                            {dataSvg.name}
                          </Link>
                        ) : (
                          <span
                            onClick={() => {
                              this.setState({
                                showGuest: true,
                              });
                            }}
                            className="menuItem"
                          >
                            {dataSvg.name}
                          </span>
                        )}
                      </div>
                    </li>
                  ) : !this.props.showOnlySearch && this.props.showOnlySearch !== undefined ? (
                    <li
                      key={index}
                      id={`icon_click${index}`}
                      style={{ color: dataSvg.isActive ? "red" : "black" }}
                      className={
                        dataSvg.isActive || index === cuIndex
                          ? "d-flex align-items-center active-sidebar line-h-60"
                          : "d-flex align-items-center line-h-60"
                      }
                      onClick={() => this.handleButton(index)}
                    >
                      <div className="posi-relative">
                        {!this.state.isGuest ? (
                          dataSvg.url === "/diamond-search" ? (
                            <Link to={dataSvg.url} className="menuItem d-flex-search" id="step1">
                              {dataSvg.name}
                              <img src={searchSVG} alt="" />
                            </Link>
                          ) : this.state.isKyc ? (
                            <>
                              {dataSvg.name.props.id === "app.new-Arrival" && totalNewArrDiamondCountBywebStatus && (
                                <span
                                  onClick={() => {
                                    this.setState({
                                      noKyc: true,
                                    });
                                  }}
                                  className="menuItem "
                                >
                                  {dataSvg.name}
                                </span>
                              )}
                              {dataSvg.name.props.id === "app.bid-to-buy" && totalBidDiamondCountBywebStatus && (
                                <span
                                  onClick={() => {
                                    this.setState({
                                      noKyc: true,
                                    });
                                  }}
                                  className="menuItem "
                                >
                                  {dataSvg.name}
                                </span>
                              )}
                              {dataSvg.name.props.id !== "app.new-Arrival" &&
                                dataSvg.name.props.id !== "app.bid-to-buy" && (
                                  <span
                                    onClick={() => {
                                      this.setState({
                                        noKyc: true,
                                      });
                                    }}
                                    className="menuItem "
                                  >
                                    {dataSvg.name}
                                  </span>
                                )}
                            </>
                          ) : (
                            <>
                              {/* {dataSvg?.showFlag &&
                            dataSvg?.name?.props?.id === 'app.new-Arrival' &&
                            totalNewArrDiamondCountBywebStatus && (
                              <span
                                className="posi-absolute font-w-800"
                                style={{
                                  color:
                                    totalNewArrDiamondCountBywebStatus > 0 &&
                                    flagArr.filter((d) => d[dataSvg.id])?.[0]?.[dataSvg.id]
                                      ? '#00ff00'
                                      : 'red',
                                }}
                              >
                                {totalNewArrDiamondCountBywebStatus > 0 && flagArr?.length ? (
                                  flagArr.filter((d) => d[dataSvg.id])?.[0]?.[dataSvg.id] ? (
                                    <IntlMessage id="app.open" />
                                  ) : (
                                    <IntlMessage id="app.Close" />
                                  )
                                ) : (
                                  <IntlMessage id="app.Close" />
                                )}
                              </span>
                            )} */}
                              {/* {dataSvg?.showFlag &&
                            dataSvg?.name?.props?.id === 'app.bid-to-buy' &&
                            totalBidDiamondCountBywebStatus && (
                              <span
                                className="posi-absolute font-w-800"
                                style={{
                                  color:
                                    totalBidDiamondCountBywebStatus > 0 &&
                                    flagArr.filter((d) => d[dataSvg.id])?.[0]?.[dataSvg.id]
                                      ? '#00ff00'
                                      : 'red',
                                }}
                              >
                                {totalBidDiamondCountBywebStatus > 0 && flagArr?.length ? (
                                  flagArr.filter((d) => d[dataSvg.id])?.[0]?.[dataSvg.id] ? (
                                    <IntlMessage id="app.open" />
                                  ) : (
                                    <IntlMessage id="app.Close" />
                                  )
                                ) : (
                                  <IntlMessage id="app.Close" />
                                )}
                              </span>
                            )} */}
                              {dataSvg.name.props.id === "app.new-Arrival" && totalNewArrDiamondCountBywebStatus ? (
                                <Link to={dataSvg.url} className="menuItem" id="step8">
                                  {dataSvg.name}
                                </Link>
                              ) : (
                                <></>
                              )}
                              {dataSvg.name.props.id === "app.bid-to-buy" && totalBidDiamondCountBywebStatus ? (
                                <Link to={dataSvg.url} className="menuItem" id="step9">
                                  {dataSvg.name}
                                </Link>
                              ) : (
                                <></>
                              )}
                              {/* {dataSvg.name.props.id !== 'app.new-Arrival' && dataSvg.name.props.id !== 'app.bid-to-buy' && (
                            <Link to={dataSvg.url} className="menuItem">
                              {dataSvg.name}
                            </Link>
                          )} */}
                            </>
                          )
                        ) : dataSvg.url === "/diamond-search" ? (
                          <Link to={dataSvg.url} className="menuItem ">
                            {dataSvg.name}
                          </Link>
                        ) : (
                          <Link
                            onClick={() => {
                              this.setState({
                                showGuest: true,
                              });
                            }}
                            className="menuItem"
                          >
                            {dataSvg.name}
                          </Link>
                        )}
                      </div>
                      {/* {dataSvg.subMenu && !this.state.isGuest && !this.state.isKyc && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem1">
                          <div className="d-flex j-space-between lign-h-normal">
                            <ul>
                              <li>
                                <Link to="/upcoming">
                                  <IntlMessage id="app.Upcomming" />
                                </Link>
                              </li>
                              <li>
                                <Link to="/kgk-exclusive">
                                  <IntlMessage id="app.Exclusive" />
                                </Link>
                              </li>
                              <li>
                                <Link to="/offerlist">
                                  <IntlMessage id="app.OfferList" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu1 && !this.state.isGuest && !this.state.isKyc && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>Fancy Diamond</h3>
                          <div className="d-flex j-space-between ">
                            <ul>
                              <li>
                                <span>
                                  <IntlMessage id="app.newArrival" />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <IntlMessage id="app.match-pair" />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <IntlMessage id="app.non-certified" />
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <span>
                                  <IntlMessage id="app.parcel" />
                                </span>
                              </li>
                              <li>
                                <span>DOR</span>
                              </li>
                              <li>
                                <span>Forevermark</span>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <span>Canadamark</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="submenuItem">
                          <h3>
                            <IntlMessage id="app.Discover" />
                          </h3>
                          <ul>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img src={fancyDiaSVG} alt="img" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu2 && !this.state.isGuest && !this.state.isKyc && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>
                            <IntlMessage id="app.Exclusive" />
                          </h3>
                          <ul>
                            <li>
                              <Link to="/made-in-botswana">
                                <IntlMessage id="app.madeinbotswana" />
                              </Link>
                            </li>
                            <li>
                              <span>
                                <IntlMessage id="app.FinestarEmerald" />
                              </span>
                            </li>
                            <li>
                              <span>CEO's suggestion</span>
                            </li>
                          </ul>
                        </div>
                        <div className="submenuItem">
                          <h3>
                            <IntlMessage id="app.Discover" />
                          </h3>
                          <ul>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img src={jpg2} alt="img" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu3 && !this.state.isGuest && !this.state.isKyc && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>
                            <IntlMessage id="app.newArrival" />
                          </h3>
                          <ul>
                            <li>
                              <span href="/new-arrival">
                                <IntlMessage id="app.LookNBid" />
                              </span>
                            </li>
                            <li>
                              <span href="/new-arrival">
                                <IntlMessage id="app.BlindBid" />
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="submenuItem">
                          <h3>Discover</h3>
                          <ul>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img src={dropdownImg} alt="i" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dataSvg.subMenu4 && !this.state.isGuest && !this.state.isKyc && (
                    <div className="menuDropdown">
                      <div className="submenuWrapper">
                        <div className="submenuItem">
                          <h3>
                            <IntlMessage id="app.Upcoming" />
                          </h3>
                          <ul>
                            <li>
                              <span>
                                <IntlMessage id="app.InManufacturing" />
                              </span>
                            </li>
                            <li>
                              <span>
                                <IntlMessage id="app.InLab" />
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="submenuItem">
                          <h3>
                            <IntlMessage id="app.Discover" />
                          </h3>
                          <ul>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                            <li>
                              <span>Lorem Ipsum is simply dummy</span>
                            </li>
                          </ul>
                        </div>
                        <div className="subMenuImage">
                          <div className="submenuImageContent">
                            <div className="sibmenuImg">
                              <img src={jpg3} alt="i" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </ul>
        </div>
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
        {this.state.noKyc && (
          <NoKycPop
            status={authUser.isApproved}
            visible={this.state.noKyc}
            handleCancel={() => {
              this.setState({ noKyc: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (props) {
  return {
    auth: props.auth,
    commonData: props.commonData,
  };
};

export default compose(connect(mapStateToProps), withRouter)(Sidebar);
