import React from "react";

import { Spin } from "antd";
import PropTypes from "prop-types";

import IntlMessage from "util/IntlMessage";

function NoDataFound(props) {
  const { loading, message, ...forwardProps } = props;

  return (
    <div className="empty-data" {...forwardProps}>
      <h3>{loading ? <Spin /> : message ?? <IntlMessage id="app.noData" />}</h3>
    </div>
  );
}

NoDataFound.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.any,
};

export default React.memo(NoDataFound);
