import React, { Component } from "react";

import { Drawer } from "antd";

import { Storage } from "services/storage";

import IntlMessage from "util/IntlMessage";

import { MULTIPLE_SEARCH } from "constants/Common";

import confirmSVG from "../../assets/svg/DiamondSearch/confirm.svg";
import moreSVG from "../../assets/svg/DiamondSearch/more.svg";
import refreshSVG from "../../assets/svg/DiamondSearch/refresh.svg";
import searchSVG from "../../assets/svg/DiamondSearch/search.svg";
import downArrowSVG from "../../assets/svg/Search/down-arrow.svg";

class MobileBottomAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreSearch: false,
      selectsaved: false,
    };
  }
  moreSearchOption = () => {
    this.setState({
      moreSearch: true,
    });
  };
  selectSavedSearch = () => {
    this.setState({
      selectsaved: true,
    });
  };
  onClose = () => {
    this.setState({
      moreSearch: false,
    });
  };
  onClose1 = () => {
    this.setState({
      selectsaved: false,
    });
  };
  render() {
    const P = Storage.get("userPermissions");
    return (
      <>
        <div className="mobileSearchBottom">
          <div className="mobileSearchActionItem">
            <span
              onClick={() => {
                this.props.multipleSearch.length !== 0
                  ? this.props.handleMutipleSearch(true)
                  : this.props.handleSearch();
              }}
            >
              <img src={searchSVG} alt="" />
              <span>
                <IntlMessage id={this.props.count === 0 ? "app.add-demand" : "app.search-btn-footer"} />
              </span>
            </span>
          </div>

          <div className="mobileSearchActionItem">
            <span onClick={() => this.props.handleMatchPair()}>
              <img src={confirmSVG} alt="" />
              <span>
                <IntlMessage id="app.matchpairDashboard" />
              </span>
            </span>
          </div>
          <div className="mobileSearchActionItem">
            <span onClick={() => this.props.handleRefresh()}>
              <img src={refreshSVG} alt="" />
              <span>Reset</span>
            </span>
          </div>
          <div className="mobileSearchActionItem" onClick={this.moreSearchOption}>
            <span>
              <img src={moreSVG} alt="" />
              <span>More</span>
            </span>
          </div>
        </div>
        {this.state.moreSearch && (
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.moreSearch}
            wrapClassName="mobileSidebar mobileBottomSidebar"
          >
            <div className="MoreSearchOptionItem">
              <ul>
                <li onClick={this.props.handleNewArrival}>
                  <span>
                    <IntlMessage id="app.new-Arrival" />
                  </span>
                </li>
                <li onClick={this.props.handleBidToBuy}>
                  <span>
                    <IntlMessage id="app.bid-to-buy" />
                  </span>
                </li>
                <li onClick={this.props.saveSearchPopup}>
                  <span>Save & Search</span>
                </li>
                {MULTIPLE_SEARCH && P?.SEARCH_DIAMOND?.view && (
                  <li onClick={() => this.props.handleMutipleSearch()}>
                    <span>Add Another Search</span>
                    {`${this.props.multipleSearch.length ? `(${this.props.multipleSearch.length})` : ""}`}
                  </li>
                )}
                {this.props.USER_PERMIT?.SAVE_SEARCH?.view && (
                  <li onClick={this.selectSavedSearch}>
                    <span>Select Saved Search</span>
                    <img src={downArrowSVG} alt="" />
                  </li>
                )}
              </ul>
            </div>
          </Drawer>
        )}
        {this.state.selectsaved && (
          <Drawer
            title={false}
            onClose={this.onClose1}
            visible={this.state.selectsaved}
            wrapClassName="mobileSidebar mobileBottomSidebar"
          >
            <div className="MoreSearchOptionItem">
              <ul>
                {this.props.searchList.length > 0
                  ? this.props.searchList.map((se, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            this.props.onChange(se.id);
                            this.onClose1();
                            this.onClose();
                          }}
                        >
                          <span>{se.name}</span>
                        </li>
                      );
                    })
                  : "No Saved List Found"}
              </ul>
            </div>
          </Drawer>
        )}
      </>
    );
  }
}
export default MobileBottomAction;
