import React, { Component } from "react";
import { connect } from "react-redux";

import concat from "lodash/concat";
import each from "lodash/each";
import find from "lodash/find";
import flatten from "lodash/flatten";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import join from "lodash/join";
import keys from "lodash/keys";
//import map from 'lodash/map';
import omit from "lodash/omit";
//import pullAt from 'lodash/pullAt';
//import split from 'lodash/split';
//import trim from 'lodash/trim';
import uniqWith from "lodash/uniqWith";

import { SearchService } from "services/SearchService";

import { notify } from "util/notify";

import {
  handleSearch,
  getSearchList,
  handleSavedSearch,
  handleMatchPair,
  handleSelectSearch,
  getCount,
  handleMultiSearch,
  handleNewArrival,
  handleBidToBuy,
} from "./APIFunctions";

import { resetDiamondSearch, modifyDiamondSearch } from "../appRedux/actions/Common";
// import { LISTINGPAGES } from '../components/DiamondList/DiamondListFunctions';
import DiamondSearchComponent from "../components/DiamondSearch";
import OpenNotification from "../components/common/CommonButton/OpenNotification";
import { LOCAL_STORAGE_VAR, DIAMOND_WEB_STATUS, PAGES } from "../constants/Common";
import { formatInlineList, hasKey, isArray, isEmpty, isNotEmpty, isString, resolve } from "../util/utils";

const LISTINGPAGES = PAGES;
const STONE_ID_FIELDS = ["stoneId", "rptNo", "vStnId"];
class DiamondSearch extends Component {
  state = { ...this.initialState };

  get initialState() {
    return {
      excludeFilter: {},
      diamParams: {
        shp: {},
        carat: {},
        col: {},
        range: {},
        fcCol: {},
        clr: {},
        flu: {},
        shd: {},
        lb: {},
        cut: {},
        pol: {},
        sym: {},
        hA: {},
        cult: {},
        opnCult: {},
        brlncyNm: {},
        org: {},
        opInc: {},
        opPav: {},
        opCrwn: {},
        grdl: {},
        keyToSymbol: {},
        loc: {},
        inten: {},
        ovrtn: {},
        mlk: {},
        lstr: {},

        eCln: {},
        mixTint: {},
        blkInc: {},
        //whtInc: {},

        natural: {},
        blkCrn: {},
        blkSd: {},
        wSd: {},
        opTbl: {},
        nrlCrwn: {},
        nrlGrdl: {},
        nrlPav: {},
        srfcGrning: {},
        intrnlGrning: {},
        tingeInt: {},
        wCrn: {},
      },
      selectedMaster: {},
      or: [],
      threeEX: false,
      threeEXNon: false,
      multipleSearch: [],
      threeVGPlus: false,
      threeVG: false,
      selectedClrGrps: [],
      selectedClarGrps: [],
      clrSwitch: 0,
      claritySwitch: true,
      showSavedSearch: false,
      searchTitle: "",
      searchList: [],
      stoneIds: "",
      showDemand: false,
      noBGM: false,
      BGM: false,
      noBLACK: false,
      brown: false,
      milky: false,
      eyeCln: false,
      newArr: false,
      searchId: "",
      loader: false,
      mixTint: false,
    };
  }

  get searchFilter() {
    const filter = { ...this.state.selectedMaster };

    return {
      ...filter,
    };
  }

  componentDidMount = () => {
    this.setSearch();
  };

  setSearch = () => {
    this.setState({ loader: true });
    const searchId = window.location.pathname.split("/").pop();
    this.getData();
    if (!["/dashboard"].includes(window.location.pathname)) {
      getSearchList((list) => {
        if (!isEmpty(list)) this.setState({ searchList: list });
      });
    }
    if (searchId !== "diamond-search") {
      handleSelectSearch(searchId, (searchData, title, type) => {
        if (!isEmpty(searchData)) {
          type === 2 && this.setState({ searchTitle: title, searchId });
          this.handleAfterSelectSearch(searchData);
        }
      });
      // getCount({}, (count) => this.setState({ count, loader: false }));
    } else {
      const selectedMaster = { ...this.state.selectedMaster };

      if (this.props.location.state?.isFcCol) {
        selectedMaster["isFcCol"] = true;
        this.setState({ clrSwitch: 1 });
      }
      getCount(selectedMaster, (count) => this.setState({ count, loader: false }));
      this.setState({ selectedMaster, selectedClrGrps: [] });
    }
  };

  getData = () => {
    const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
    if (isEmpty(masterData)) return {};

    const colorMaster = isArray(masterData.COLOR) ? [...masterData.COLOR] : [];

    const nonFancyColorMaster = (() => {
      const _colorMaster = [...colorMaster];
      const fancyIndex = _colorMaster.findIndex((color) => color.code === "N");
      return _colorMaster.slice(0, fancyIndex + 1);
    })();

    const rangeMaster = (() => {
      const _colorMaster = [...colorMaster];
      const fancyIndex = _colorMaster.findIndex((color) => color.code === "N");
      return _colorMaster.slice(fancyIndex + 1);
    })();

    const params = { ...this.state.diamParams };

    params.shp = { key: "shp", list: masterData.SHAPE };
    params.carat = { key: "carat", list: masterData.SIZE };
    params.col = { key: "col", list: nonFancyColorMaster };
    params.range = { key: "col", list: rangeMaster };
    params.fcCol = { key: "fcCol", list: masterData.FANCY_COLOR };
    params.clr = { key: "clr", list: masterData.CLARITY };
    params.flu = { key: "flu", list: masterData.FLUORESCENCE };
    params.shd = { key: "shd", list: masterData.SHADE };
    params.lb = { key: "lb", list: masterData.LAB };
    params.cut = { key: "cut", list: masterData.CUT };
    params.pol = { key: "pol", list: masterData.POLISH };
    params.sym = { key: "sym", list: masterData.SYMMETRY };
    params.hA = { key: "hA", list: masterData.H_AND_A };
    params.cult = { key: "cult", list: masterData.CULET };
    params.opnCult = { key: "opnCult", list: masterData.CULET_COND };
    params.org = { key: "org", list: masterData.COUNTRYOFORIGIN };
    params.brlncyNm = { key: "brlncyNm", list: masterData.BRILLIANCY };

    params.opInc = { key: "opInc", list: masterData.OPEN_INCLUSION };
    params.opPav = { key: "opPav", list: masterData.OPEN_PAVILION };
    params.opCrwn = { key: "opCrwn", list: masterData.OPEN_CROWN };
    params.grdl = { key: "grdl", list: masterData.GIRDLE };
    params.keyToSymbol = { key: "keyToSymbol", list: masterData.KEY_TO_SYMBOLS };
    params.loc = { key: "loc", list: masterData.LOCATION };
    params.inten = { key: "inten", list: masterData.INTENSITY };
    params.ovrtn = { key: "ovrtn", list: masterData.OVERTONE };
    params.mlk = { key: "mlk", list: masterData.MILKEY };
    params.lstr = { key: "lstr", list: masterData.LUSTER };

    params.eCln = { key: "eCln", list: masterData.EYECLEAN };
    params.mixTint = { key: "mixTint", list: masterData.TINGE_INTENSITY };
    params.blkInc = { key: "blkInc", list: masterData.BLACK_INCLUSION };

    params.natural = { key: "natural", list: masterData.NATURAL };

    params.nrlCrwn = { key: "nrlCrwn", list: masterData.NATURAL_CROWN };
    params.nrlGrdl = { key: "nrlGrdl", list: masterData.NATURAL_GIRDLE };
    params.nrlPav = { key: "nrlPav", list: masterData.NATURAL_PAVILION };

    params.srfcGrning = { key: "srfcGrning", list: masterData.SURFACE_GRAINING };
    params.intrnlGrning = { key: "intrnlGrning", list: masterData.INTERNAL_GRAINING };

    params.blkCrn = { key: "blkCrn", list: masterData.TABLE_BLACK };
    params.blkSd = { key: "blkSd", list: masterData.SIDE_BLACK };
    params.wCrn = { key: "wCrn", list: masterData.TABLE_INCLUSION };
    params.wSd = { key: "wSd", list: masterData.SIDE_INCLUSION };
    params.opCrwn = { key: "opCrwn", list: masterData.OPEN_INCLUSION_CROWN };
    params.opTbl = { key: "opTbl", list: masterData.OPEN_INCLUSION_TABLE };
    params.opPav = { key: "opPav", list: masterData.OPEN_INCLUSION_PAVILION };

    Object.entries(params).forEach(([field, { list }]) => {
      params[field].list = list || [];
    });
    this.setState({ diamParams: params });
  };

  // rptrue, rptrue, yes, yes, 👏, 👏
  handleSelection = (idList, check, field, certi, rptrue) => {
    const selectedMaster = { ...this.state.selectedMaster };

    if (rptrue) {
      if (certi && certi.length !== 0) {
        if (this.state.or && this.state.or.length) {
          this.state.or.forEach((ab, ind) => {
            if (ab.vStnId || ab.rptNo || ab.stoneId) {
              this.state.or.splice(ind, 1);
            }
          });
          selectedMaster.or = [...this.state.or];
        }
        selectedMaster.or = [...[{ stoneId: certi }, { rptNo: certi }, { vStnId: certi }]];
      } else {
        delete selectedMaster.or;
      }
    } else {
      selectedMaster[field] = isArray(selectedMaster[field]) ? selectedMaster[field] : [];
      const list = isEmpty(idList) ? this.state.diamParams?.[field]?.list?.map?.((data) => data?.id)?.flat?.() : idList;
      if (isArray(list)) selectedMaster[field] = [...selectedMaster[field], ...list].sort();
    }
    if (!check) {
      selectedMaster[field] = isArray(this.state.selectedMaster[field])
        ? this.state.selectedMaster[field].filter((val) => idList.indexOf(val) === -1)
        : [];
    }
    if (field === "col" && selectedMaster[field].length <= 0 && !selectedMaster["isFcCol"]) {
      this.handleClrSwitch(0);
    }
    this.setState({ selectedMaster, loader: true }, () => {
      getCount(this.state.selectedMaster, (count, newArrivalCount, bidToBuyCount) => {
        this.setState({ count, newArrivalCount, bidToBuyCount, loader: false });
      });
    });
  };

  selectedCarat = (selected, params) => {
    const selectedMaster = { ...this.state.selectedMaster };
    let or = isArray(selectedMaster.or) ? [...selectedMaster.or] : [];

    const addToList = (rangeStr) => {
      const [first, last] = isString(rangeStr) ? rangeStr.split("-") : Array(2).fill(undefined);
      if (isEmpty(first) || isEmpty(last)) return;
      or.push({ crt: { ">=": Number(first), "<=": Number(last) } });
      or = uniqWith(or, isEqual);
    };

    const filterList = (rangeStr) => {
      const [first, last] = isString(rangeStr) ? rangeStr.split("-") : Array(2).fill(undefined);
      if (isEmpty(first) || isEmpty(last)) return;
      or = or.filter((filter) =>
        hasKey(filter, "crt") ? filter.crt[">="] !== Number(first) || filter.crt["<="] !== Number(last) : true
      );
    };

    if (isArray(selected)) {
      isString(params) ? filterList(params) : selected.forEach(addToList);
    } else if (isArray(selected?.[params?.key])) {
      selected[params?.key].forEach((range) => (params?.checked ? addToList(range) : filterList(range)));
    }

    selectedMaster.or = or.filter(isNotEmpty);
    this.setState({ selectedMaster, or });
    getCount(selectedMaster, (count) => this.setState({ count }));
  };

  selectedKeyToSymbol = (selected) => {
    const selectedMaster = { ...this.state.selectedMaster };
    selectedMaster.kToSArr = { ...selected };
    this.setState({ selectedMaster, loader: true });
    getCount(selectedMaster, (count) => this.setState({ count, loader: false }));
  };

  selectedRange = (From, To, master) => {
    const selectedMaster = { ...this.state.selectedMaster };

    if (From === "" && To === "") {
      delete selectedMaster[master];
    } else {
      selectedMaster[master] = { ">=": From, "<=": To };
    }

    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster });
  };

  handleCombineSearch = (opt, checked, multiple) => {
    this.setState({ [opt]: !this.state[[opt]] }, () => {
      const selectedMaster = { ...this.state.selectedMaster };
      const { diamParams } = this.state;
      let excludeFilter = { ...this.state.excludeFilter };
      const filter = {
        threeEX: {
          cut: ["EX"],
          pol: ["EX"],
          sym: ["EX"],
        },
        threeEXNon: {
          cut: ["I", "EX", "NONE"],
          pol: ["I", "EX", "NONE"],
          sym: ["I", "EX", "NONE"],
          flu: ["I", "EX", "VG", "NON"],
        },
        threeVG: {
          cut: ["VG"],
          pol: ["EX", "VG"],
          sym: ["EX", "VG"],
        },
        noBGM: {
          shd: !this.state.BGM ? ["WH", "YEL"] : ["BR", "GRN", "M", "PNK", "BLACKISH", "WH", "YEL"],
          mlk: !this.state.BGM ? ["NONE"] : ["ML1", "ML2", "ML3", "NONE"],
        },
        BGM: {
          shd: !this.state.noBGM
            ? ["BR", "GRN", "M", "PNK", "BLACKISH"]
            : ["BR", "GRN", "M", "PNK", "BLACKISH", "WH", "YEL"],
          mlk: !this.state.noBGM ? ["ML1", "ML2", "ML3"] : ["ML1", "ML2", "ML3", "NONE"],
        },
        noBLACK: { blkCrn: ["B0"], blkSd: ["SB0"] },
        milky: { mlk: ["ML1", "ML2", "ML3"] },
        eyeCln: { eCln: ["Y"] },
      };

      if (!multiple) {
        keys(filter).forEach((k) => {
          if (k !== opt) {
            if (k !== "BGM" && k !== "noBLACK" && k !== "noBGM") {
              Object.keys(filter[k]).forEach((d1) => void (selectedMaster[d1] = []));
              excludeFilter = omit(excludeFilter, k);
              this.setState({ [k]: false }); // Other keys will be false at a time.
            }
          }
        });
      }

      if (checked) {
        Object.keys(filter[opt]).forEach((k) => {
          let ids = [];
          diamParams[k].list.forEach((d2) => {
            if (includes(filter[opt][k], d2.code)) ids = concat(ids, d2.id);
          });
          selectedMaster[k] = ids;
        });
        if (opt === "BGM" || opt === "noBGM" || opt === "noBLACK") {
          if (this.state.noBGM) {
            excludeFilter["noBGM"] = checked;
          }
          if (this.state.BGM) {
            excludeFilter["BGM"] = checked;
          }
          if (this.state.noBLACK) {
            excludeFilter["noBLACK"] = checked;
          }
        } else {
          excludeFilter[opt] = checked;
        }
      } else {
        Object.keys(filter[opt]).forEach((d1) => {
          selectedMaster[d1] = [];
        });
        if (opt === "BGM" || opt === "noBGM") {
          if (this.state.BGM) {
            Object.keys(filter["BGM"]).forEach((k) => {
              let ids = [];
              diamParams[k].list.forEach((d2) => {
                if (includes(filter["BGM"][k], d2.code)) ids = concat(ids, d2.id);
              });
              selectedMaster[k] = ids;
            });
          }
          if (this.state.noBGM) {
            Object.keys(filter["noBGM"]).forEach((k) => {
              let ids = [];
              diamParams[k].list.forEach((d2) => {
                if (includes(filter["noBGM"][k], d2.code)) ids = concat(ids, d2.id);
              });
              selectedMaster[k] = ids;
            });
          }
        }
        excludeFilter = omit(excludeFilter, opt);
      }
      selectedMaster.excludeFilter = excludeFilter;
      getCount(selectedMaster, (count) => this.setState({ count }));
      this.setState({ selectedMaster, excludeFilter });
    });
  };

  handleClrSwitch = (type) => {
    this.setState({ loader: true });
    const selectedMaster = { ...this.state.selectedMaster };

    // selectedMaster.col = type === 2 ? this.state.diamParams?.range?.list?.map?.((range) => range.id)?.flat?.() : [];

    selectedMaster.fcCol = [];
    selectedMaster.isFcCol = type === 1;

    this.setState({ selectedMaster, selectedClrGrps: [], clrSwitch: type }, () => {
      getCount(selectedMaster, (count) => this.setState({ count, loader: false }));
    });
  };

  getMaster = (key) => {
    const { diamParams } = this.state;
    const tempArray = [];
    isArray(diamParams[key].list) &&
      diamParams[key].list.forEach((d) => {
        each(d.id, (id) => tempArray.push(id));
      });
    this.handleSelection(tempArray, true, key, "", "", true);
  };

  handleRefresh = () => {
    this.setState({ ...this.initialState, diamParams: this.state.diamParams, loader: true }, () => {
      getSearchList((list) => {
        if (!isEmpty(list)) this.setState({ searchList: list });
      });
      getCount(this.state.selectedMaster, (count) => this.setState({ count, loader: false }));
      this.props.resetDiamondSearch({ searchReset: Math.random() });
    });
    this.props.history.push(`/diamond-search`);
  };

  handleLocationChange = (val) => {
    const selectedMaster = { ...this.state.selectedMaster };
    selectedMaster["loc"] = flatten(val);
    this.setState({ selectedMaster }, () => {
      getCount(this.state.selectedMaster, (count) => this.setState({ count }));
    });
  };

  handleOriginChange = (val) => {
    const selectedMaster = { ...this.state.selectedMaster };
    selectedMaster["org"] = flatten(val);

    this.setState({ selectedMaster }, () => {
      getCount(this.state.selectedMaster, (count) => this.setState({ count }));
    });
  };

  handleSetStoneIds = (e) => {
    const stoneIdStr = formatInlineList(resolve(e), { allowAppend: true });
    const stoneIdList = formatInlineList(resolve(e), { allowAppend: false, returnString: false });

    const selection = { ...this.state.selectedMaster };
    const orFilters = isArray(selection.or)
      ? selection.or.filter((filter) => Object.keys(filter).some((key) => STONE_ID_FIELDS.includes(key)))
      : [];

    stoneIdList.forEach((id) => orFilters.push({ stoneId: [id] }, { rptNo: [id] }, { vStnId: [id] }));
    selection.or = orFilters;

    this.setState({ stoneIdList, stoneIds: stoneIdStr, selectedMaster: selection }, this.getCount);
    // const selectedMaster = { ...this.state.selectedMaster };
    // const or = [];
    // let arr = stoneIds.includes(',')
    //   ? split(stoneIds, ',')
    //   : stoneIds.includes(' ')
    //   ? split(stoneIds, ' ')
    //   : stoneIds;

    // arr = isArray(arr) ? arr.map((a) => trim(a)) : [stoneIds];

    // map(arr, (a, i) => {
    //   if (a === '' || isEmpty(a) || a === ' ') {
    //     pullAt(arr, [i]);
    //   }
    // });
    // arr.length && or.push({ vStnId: arr });
    // arr.length && or.push({ rptNo: arr });

    // selectedMaster['or'] = or;
    // this.setState({ selectedMaster });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleAfterSelectSearch = (searchData) => {
    const { diamParams } = this.state;

    const wSts = searchData.wSts || [];
    const eyeCln = searchData.eCln ? true : false;
    const upcoming = searchData.wSts && includes(searchData.wSts, DIAMOND_WEB_STATUS.UPCOMING);
    const newArr = searchData.wSts && includes(searchData.wSts, DIAMOND_WEB_STATUS.BID);
    const canadamark = searchData.isCm ? true : false;
    const type2 = searchData.type2 ? true : false;
    const xRay = searchData.isXray;
    const dorKeys = searchData.isDor || [];
    const fmKeys = searchData.isFm || [];

    const opTbl = searchData.opTbl && find(diamParams["opTbl"].list, { id: searchData.opTbl }).code;
    const opCrwn = searchData.opCrwn && find(diamParams["opCrwn"].list, { id: searchData.opCrwn }).code;
    const opPav = searchData.opPav && find(diamParams["opPav"].list, { id: searchData.opPav }).code;
    const isSeal = searchData.isSeal === true ? "YES" : searchData.isSeal === false ? "NO" : "";
    const threeEX = searchData.excludeFilter && searchData.excludeFilter.threeEX;
    const threeEXNon = searchData.excludeFilter && searchData.excludeFilter.threeEXNon;
    const noBGM = searchData.excludeFilter && searchData.excludeFilter.noBGM;
    const BGM = searchData.excludeFilter && searchData.excludeFilter.BGM;
    const noBLACK = searchData.excludeFilter && searchData.excludeFilter.noBLACK;
    const clrSwitch = searchData.isFcCol ? 1 : 0;
    this.props.modifyDiamondSearch({ modifyData: searchData });

    const stoneIds =
      searchData.or
        ?.filter?.((filter) => Object.keys(filter).some((key) => STONE_ID_FIELDS.includes(key)))
        ?.map?.(Object.values)
        ?.flat?.() ?? [];

    this.setState(
      {
        selectedMaster: searchData,
        or: searchData?.or !== undefined ? searchData.or : [],
        wSts,
        eyeCln,
        upcoming,
        canadamark,
        type2,
        xRay,
        dorKeys,
        clrSwitch,
        fmKeys,
        newArr,
        opTbl,
        opPav,
        opCrwn,
        isSeal,
        noBGM,
        BGM,
        noBLACK,
        threeEXNon,
        threeEX,
        stoneIds: formatInlineList(stoneIds, {}),
        stoneIdList: formatInlineList(stoneIds, { returnString: false }),
      },
      () => {
        getCount(this.state.selectedMaster, (count) => this.setState({ count }));
      }
    );
  };

  getCount() {
    return getCount(this.searchFilter, (count) => this.setState({ count }));
  }

  handleReverseState = (state) => {
    this.setState({ [state]: !this.state[[state]] }, () => {
      const selectedMaster = { ...this.state.selectedMaster };
      if (this.state.newArr) {
        selectedMaster.wSts = ["B"];
      } else {
        delete selectedMaster.wSts;
      }
      getCount(selectedMaster, (count) => this.setState({ count, loader: false }));
    });
  };

  handleMutipleSearch = (search) => {
    if (this.searchFilterLength > 0 && Number(this.state.count) > 1000) {
      notify.destroy();
      notify.error({ message: "Please modify your search, maximum 1000 stones can be displayed" });
      return;
    }

    const multipleSearch = [...this.state.multipleSearch];
    if (this.searchFilterLength) multipleSearch.push(this.state.selectedMaster);

    if (!search) this.handleRefresh();

    this.setState({ multipleSearch }, () => {
      if (search) this.handleMultipleSearchApiCall();
    });
  };

  handleMultipleSearchApiCall = () => {
    const { multipleSearch } = this.state;
    handleMultiSearch(multipleSearch, (success) => {
      this.props.history.push(`/${LISTINGPAGES.LIST}?${join(success.ids, ",")}`);
    });
  };

  // static getDerivedStateFromProps(props, state) {
  //   return { ...state };
  // }

  get searchFilterLength() {
    return SearchService.getSearchFilterLength(this.searchFilter);
  }

  render() {
    const permission = this.props.commonData.pagePermission()["searchDiamond"];
    return (
      <DiamondSearchComponent
        {...this.state}
        handleMutipleSearch={this.handleMutipleSearch}
        permission={permission}
        handleSelection={this.handleSelection}
        selectedCaratFn={this.selectedCarat}
        handleSearch={() => {
          if (Number(this.state.count) > 1000) {
            OpenNotification({
              type: "error",
              title: "Please modify your search, maximum 1000 stones can be displayed",
            });
          } else {
            handleSearch(
              this.state.selectedMaster,
              this.state.newArr,
              (cb) => {
                if (cb.NOSTONE === "NOSTONE") {
                  this.setState({ showDemand: true });
                } else {
                  this.props.history.push(`/${LISTINGPAGES.LIST}?` + cb.id);
                }
              },
              true
            );
          }
        }}
        handleSavedSearch={() => {
          if (Number(this.state.count) > 1000) {
            OpenNotification({
              type: "error",
              title: "Please modify your search, maximum 1000 stones can be displayed",
            });
          } else {
            if (this.state.multipleSearch[0]) {
              handleSavedSearch(this.state.multipleSearch, this.state.searchTitle, this.state.searchId, (id) => {
                if (id) {
                  const { multipleSearch } = this.state;
                  handleMultiSearch(multipleSearch, (success) => {
                    this.props.history.push(`/${LISTINGPAGES.LIST}?${join(success.ids, ",")}`);
                  });
                }
              });
            } else {
              handleSavedSearch(this.state.selectedMaster, this.state.searchTitle, this.state.searchId, (id) => {
                if (id) {
                  this.setState({ showSavedSearch: false, searchId: "" });
                  this.props.history.push("/diamond-list?" + id);
                }
              });
            }
          }
        }}
        handleMatchPair={() =>
          handleMatchPair(this.state.selectedMaster, (id) => id && this.props.history.push("/match-pair?" + id))
        }
        handleNewArrival={() => {
          const selectedMaster = { ...this.state.selectedMaster };
          handleNewArrival(selectedMaster, (cb) => {
            this.props.history.push(`/new-arrival?` + cb.id);
          });
        }}
        handleBidToBuy={() => {
          const selectedMaster = { ...this.state.selectedMaster };
          handleBidToBuy(selectedMaster, (cb) => {
            this.props.history.push(`/${LISTINGPAGES.BID}?` + cb.id);
          });
        }}
        selectedMaster={this.state.selectedMaster}
        handleClrSwitch={this.handleClrSwitch}
        selectedKeyToSymbol={this.selectedKeyToSymbol}
        selectedRange={this.selectedRange}
        handleCombineSearch={this.handleCombineSearch}
        handleRefresh={this.handleRefresh}
        handleLocationChange={this.handleLocationChange}
        handleOriginChange={this.handleOriginChange}
        handleChange={this.handleChange}
        handleSetStoneIds={this.handleSetStoneIds}
        handleAfterSelectSearch={this.handleAfterSelectSearch}
        // isFancy={this.props.history.location.state?.isFcCol}
        handleReverseState={this.handleReverseState}
      />
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};

export default connect(mapStateToProps, {
  resetDiamondSearch,
  modifyDiamondSearch,
})(DiamondSearch);
