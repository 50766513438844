import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import urlJoin from "url-join";

import diamondLogoSVG from "../../assets/svg/diamondLogo.svg";
import logoSVG from "../../assets/svg/logo.svg";
import { BASE_URL, LOCAL_STORAGE_VAR, WHITE_LABEL } from "../../constants/Common";
import UtilService from "../../services/util";

const Logo = () => {
  const [error, setError] = useState(true);
  const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  return (
    <>
      {WHITE_LABEL ? (
        <>
          {error && authUser && authUser.account && authUser.account.vendorLogo ? (
            <img
              onError={() => {
                setError(false);
              }}
              style={{ objectFit: "contain" }}
              src={urlJoin(BASE_URL, authUser.account.vendorLogo)}
              alt="KGk"
            />
          ) : (
            <img style={{ objectFit: "contain" }} src={diamondLogoSVG} alt="KGk" />
          )}
        </>
      ) : (
        <img style={{ objectFit: "contain" }} src={logoSVG} alt="KGk" />
      )}
    </>
  );
};

export default withRouter(Logo);
