import React from "react";

import { useIsMobile, useCurrentType } from "./hooks";

export const withMobile = (WrappedComponent) => {
  return function WithMobile(props) {
    const [isMobile, isTablet] = useIsMobile();
    return <WrappedComponent {...props} isMobile={isMobile} isTablet={isTablet} />;
  };
};

export const withCurrentType = (WrappedComponent) => {
  return function WithMobile(props) {
    const currentType = useCurrentType();
    return <WrappedComponent {...props} currentType={currentType} />;
  };
};
