import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import _ from "lodash";
import first from "lodash/first";
import without from "lodash/without";

import IntlMessage from "util/IntlMessage";

import Header from "./components/Header";
import MobileHeader from "./components/MobileHeader";
import APILoader from "./components/common/APILoader";
import Loader from "./components/common/Loader";
import { pageDetails } from "./constants/Common";

class MainApp extends Component {
  state = { isMobile: window.innerWidth <= 767 };

  componentDidMount() {
    window.addEventListener("resize", this.setIsMobile);
    const currentPage = _.first(_.without(window.location.pathname.split("/"), ""));
    const currentPageDetails = _.find(pageDetails, { url: "/" + currentPage });
    this.setState({ currentPageDetails });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setIsMobile);
  }

  setIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 767 });
  };

  render() {
    if (window.drift) {
      window.drift.on("campaign:dismiss", () => {});

      if (window.drift && window.drift.api) {
        window.drift.api.widget.hide();
      }
    }

    const { isMobile } = this.state;
    const { loading } = this.props;

    return (
      <div>
        {/* <OnlineChat /> */}
        {loading && <Loader loading={loading} fixed />}
        <APILoader />
        {isMobile ? (
          <>
            {window.location.pathname === "/dashboard" ||
            window.location.pathname === "/version2" ||
            window.location.pathname === "/diamond-search2" ? null : (
              <MobileHeader />
            )}
          </>
        ) : (
          <>
            {window.location.pathname === "/dashboard" ||
            window.location.pathname === "/version2" ||
            window.location.pathname === "/diamond-search2" ? null : (
              <Header />
            )}
          </>
        )}
        <div className="content-area pageHeight without-footer">{this.props.children}</div>
        {this.state.currentPageDetails && !this.state?.currentPageDetails?.isHideFooter && (
          <div className="content-footer">
            <p>
              <IntlMessage id="app.copyrightText" /> KGK
            </p>
          </div>
        )}
        {first(without(window.location.pathname.split("/"), "")) !== "diamond-search" &&
          first(without(window.location.pathname.split("/"), "")) !== "fancy-search" &&
          this.props.commonData.pagePermission().quickSearch &&
          this.props.commonData.pagePermission().quickSearch.view && <div></div>}
      </div>
    );
  }
}

export default connect((state) => ({ commonData: state?.commonData }))(withRouter(MainApp));
