import antdSA from "antd/lib/locale-provider/ar_EG";
import appLocaleData from "react-intl/locale-data/ar";

import arMessages from "../locales/ar_SA.json";

const arLang = {
  messages: {
    ...arMessages,
  },
  antd: antdSA,
  locale: "ar",
  data: appLocaleData,
};
export default arLang;
