import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import IntlMessage from "util/IntlMessage";
import { withMobile } from "util/hocs";

// import logoutSVG from '../../../../../assets/svg/Dashboard/logout.svg';
// import { commonLogout } from '../../../../../constants/Common';
import HeaderAccountList from "../../../../common/HeaderAccountList";
import Heading from "../../../../common/Heading";
import "./AccountRightDetail.less";

class AccountRightDetail extends Component {
  render() {
    // const { isMobile } = this.props;
    return (
      <div className="dashboardRightBlock">
        <div className="d-flex dashboardCardTop align-items-center j-space-between">
          <Heading title={<IntlMessage id="app.account" />} />

          {/* {isMobile ? null : (
            <div className="logOutOption d-flex align-items-center" onClick={() => commonLogout()}>
              <span>
                <IntlMessage id="app.SignOut" />
              </span>
              <img src={logoutSVG} alt="" />
            </div>
          )} */}
        </div>
        {/* <div className="dashboardRightInner"> */}
        <div className="userDetailList">
          <ul>
            {this.props.HeaderLinks &&
              this.props.HeaderLinks.length !== 0 &&
              this.props.HeaderLinks.map((x, i) => {
                return (
                  (i === 0 || i === 1 || i === 2 || i === 3) &&
                  x.isShow && <HeaderAccountList orderThi={x.orderYes} arrownone key={i} {...this.props} {...x} />
                );
              })}
          </ul>
          {/* </div>
        <div className="userDetailList"> */}
          <ul>
            {this.props.HeaderLinks &&
              this.props.HeaderLinks.length !== 0 &&
              this.props.HeaderLinks.map((x, i) => {
                return (
                  (i === 4 || i === 5 || i === 6 || i === 7) &&
                  x.isShow && <HeaderAccountList orderThi={x.orderYes} arrownone key={i} {...this.props} {...x} />
                );
              })}
          </ul>
        </div>
        {/* </div> */}
      </div>
    );
  }
}
export default compose(withMobile, withRouter)(AccountRightDetail);
