import { CART_COUNT, NOTIFY_COUNT } from "../../constants/ActionTypes";

const INIT_STATE = {
  notifyCount: 0,
  cartCount: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFY_COUNT: {
      return {
        ...state,
        notifyCount: action.payload,
      };
    }
    case CART_COUNT: {
      return {
        ...state,
        cartCount: action.payload,
      };
    }

    default:
      return state;
  }
};
