import React from "react";

import { usePathname } from "util/hooks";

import { DIAMOND_BID, PAGES } from "constants/Common";

import StagePopover from "../../DiamondList/StagePopover";

// All: "All",
// A: "Available",
// M: "On Memo",
// H: "Hold",
// U: "Upcoming",
// B: "Bid",
// C: "Confirm",

export const STATUS = {
  all: "Status",
  All: "All",
  A: "A",
  M: "M",
  H: "H",
  U: "U",
  B: "B",
  C: "C",
  E: "E",
  N: "N",
};

export const STATUS_COLOR = {
  A: "green",
  M: "blue",
  H: "red",
  W: "green",
  L: "red",
  B: "sky",
  U: "upcoming",
  E: "exclusive",
  C: "confirm",
  N: "new-arrival",
};

const Status = (props) => {
  const pathname = usePathname();

  const status =
    props.winloss === DIAMOND_BID.STATUS.WON ? "W" : props.winloss === DIAMOND_BID.STATUS.LOSS ? "L" : props.status;

  return (
    <div className="showSatusBlock">
      {props.status !== "all" && <div className={`SelectcolorWiseList ${STATUS_COLOR[status]}`} />}
      <span>{STATUS[props.status]}</span>
      {!props.nocheck && props.status === "all" && PAGES.NEWARR !== pathname && PAGES.BID !== pathname && (
        <StagePopover page="SEARCH" />
      )}
    </div>
  );
};

export default React.memo(Status);
