import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tab, Tabs, TabList } from "react-tabs";

import urlJoin from "url-join";

import { SnippetsOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { each, includes, flatten, map, uniqBy } from "lodash";

import { handleSelectSearch } from "containers/APIFunctions";

import MasterFilter from "components/Dashboard/QuickSearch/MasterFilter";
import AddDemandPopup from "components/DiamondSearch/AddDemandPopup";
import Carat from "components/DiamondSearch/Carat";
import InputRange from "components/DiamondSearch/InputRange";
import { KeyToSymbol } from "components/DiamondSearch/KeyToSymbol";
import MobileBottomAction from "components/DiamondSearch/MobileBottomAction";
import SavedSearchPopup from "components/DiamondSearch/SavedSearchPopup";
import Heading from "components/common/Heading";
import SelectOption from "components/common/SelectOption";

import UtilService from "services/util";

import IntlMessage from "util/IntlMessage";
import { withMobile } from "util/hocs";
import { isEmpty, isNumeric, titleCase } from "util/utils";

import { BASE_URL, KYC_STATUS, LOCAL_STORAGE_VAR, MULTIPLE_SEARCH } from "constants/Common";
import { GET_STONEID_CERTI } from "constants/apiConstant";

import { setSearchWidthValue } from "appRedux/actions/Common";

import { getGridHeight } from "../DiamondList";
import { GuestUserPopup } from "../GuestUserPopup";
import { NoKycPop } from "../NoKycPop";
import OpenNotification from "../common/CommonButton/OpenNotification";

import downArrow from "assets/svg/Search/down-arrow.svg";
import upArrow from "assets/svg/Search/up-arrow.svg";

class DiamondSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchId: undefined,
      shapeExtend: false,
      showParameter: false,
      showOtherParams: false,
      showKeyToSymbol: false,
      showGirdle: false,
      incPatterns: false,
      shapeExtend1: false,
      shapeExtend2: false,
      shapeExtendColor: false,
      shapeExtendinColor: false,
      shapeExtendin1Color: false,
      showWhiteInclusion: false,
      value: 1,
      diamondInput: "",
      diamondCerti: [],
      diamondCertiValue: [],
      showCount: false,
      noKyc: false,
      isKyc: false,
      // isGuest: Storage.get('guest', false),
      isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
      showGuest: false,
      isMultipleSearch: false,
      deSelectingOptn: false,
      initialSearchWidth: 300,
      searchWidth: props?.commonData?.searchWidthFrmRedux,
      totalDiamondsSeacrhed: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
    if (prevProps.or !== this.props.or) {
      let temp = [];

      if (this.props.or && this.props.or.length !== 0) {
        this.props.or.forEach((sel) => {
          if (sel && sel.vStnId && sel.vStnId.length !== 0) {
            temp = sel.vStnId;
          }
        });
        this.setState({ diamondCertiValue: temp });
      }
    }

    if (this.props.commonData.searchWidthFrmRedux !== prevProps.commonData.searchWidthFrmRedux) {
      this.setState({ searchWidth: this.props.commonData.searchWidthFrmRedux });
      this.forceUpdate();
    }
  }

  componentDidMount() {
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

    if (authUser && authUser.account) {
      if (!authUser.account.isKycUploaded) {
        this.setState({ isKyc: !authUser.account.isKycUploaded });
      } else {
        if (authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.authUser && nextProps.auth.authUser.account) {
      if (!nextProps.auth.authUser.account.isKycUploaded) {
        this.setState({
          isKyc: !nextProps.auth.authUser.account.isKycUploaded,
        });
      } else {
        if (nextProps.auth.authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setSearchWidthValue(300);
  }

  moreLess = () => {
    this.setState({
      shapeExtend: !this.state.shapeExtend,
    });
  };
  moreLess1 = () => {
    this.setState({
      shapeExtend1: !this.state.shapeExtend1,
    });
  };
  moreLess2 = () => {
    this.setState({
      shapeExtend2: !this.state.shapeExtend2,
    });
  };
  moreLessColor = () => {
    this.setState({
      shapeExtendColor: !this.state.shapeExtendColor,
    });
  };
  moreLessinColor = () => {
    this.setState({
      shapeExtendinColor: !this.state.shapeExtendinColor,
    });
  };
  moreLessin1Color = () => {
    this.setState({
      shapeExtendin1Color: !this.state.shapeExtendin1Color,
    });
  };

  getToggle = (key) => {
    return (
      <ul className="diamondSearchList">
        <li
          className={this.props[key] === "YES" && `active`}
          onClick={() => {
            this.props.handleToggle(key, "YES");
            this.props.handleChange(key, "YES");
          }}
        >
          YES
        </li>
        <li
          className={this.props[key] === "NO" && `active`}
          onClick={() => {
            this.props.handleToggle(key, "NO");
            this.props.handleChange(key, "NO");
          }}
        >
          NO
        </li>
      </ul>
    );
  };
  getMultiple = (e) => {
    this.setState({ diamondCerti: [], isMultipleSearch: true, totalDiamondsSeacrhed: 0 });
    const stones = e.includes(" ") ? e.split(" ") : e.split(",");

    if (stones?.length > 50) {
      this.setState({ totalDiamondsSeacrhed: stones?.length });
      OpenNotification({
        type: "error",
        title: "Maximum 50 stones can be searched at once.",
      });
    } else {
      each(stones, (v) => {
        const id = v.toUpperCase();
        const objData = {
          ...GET_STONEID_CERTI,
          request: {
            page: 1,
            limit: 1,
            startWith: {
              keyword: id,
              keys: ["rptNo", "vStnId", "stoneId"],
            },
          },
        };

        UtilService.callApi(objData, async (err, data) => {
          if (data && data.code === "OK" && data.data.length && data.data[0] === id) {
            this.setState((state) => ({
              diamondCerti: [...state.diamondCerti, { id: id, name: id }],
              diamondInput: "",
            }));
          }
        });
      });
    }
  };
  getFilFn = (word) => {
    this.setState(
      { diamondInput: word.toUpperCase(), isMultipleSearch: false, diamondCerti: [], totalDiamondsSeacrhed: 0 },
      () => {
        if (this.state.diamondInput && this.state.diamondInput.length >= 3) {
          const objData = {
            ...GET_STONEID_CERTI,
            request: {
              page: 1,
              limit: 15,
              startWith: {
                keyword: this.state.diamondInput,
                keys: ["rptNo", "vStnId", "stoneId"],
              },
              sort: [
                {
                  createdAt: "DESC",
                },
              ],
            },
          };

          UtilService.callApi(objData, async (err, data) => {
            this.setState({ diamondCerti: [] });

            if (err) throw err;
            if (data && data.code === "OK") {
              if (data.data && data.data.length) {
                const temp = [];
                data.data.forEach((da) => {
                  temp.push({ id: da, name: da });
                });
                this.setState({ diamondCerti: temp });
              }
            }
          });
        }
      }
    );
  };

  render() {
    const { diamParams = {}, selectedMaster = {}, searchList = [] } = this.props;
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const gridHeight = getGridHeight(["HeaderSticky"], 0);
    const { isMobile } = this.props;
    const { totalDiamondsSeacrhed, searchWidth, initialSearchWidth } = this.state;
    const deselectOption = () => {
      this.setState({ deSelectingOptn: true });
      this.props.setSearchWidthValue(
        this.state.diamondCertiValue && this.state.diamondCertiValue.length > 30
          ? 1060
          : this.state.diamondCertiValue &&
            this.state.diamondCertiValue.length > 10 &&
            this.state.diamondCertiValue.length < 29
          ? 850
          : this.state.diamondCertiValue && this.state.diamondCertiValue.length === 0
          ? initialSearchWidth
          : 450
      );
    };

    const handleOnChangeSearch = async (e) => {
      if (totalDiamondsSeacrhed > 50) {
        this.setState({ diamondInput: "", diamondCerti: [], diamondCertiValue: [] });
      } else {
        let stones = [];
        if (this.state.isMultipleSearch && e !== undefined && e.length) {
          stones = (await e[0].includes(" ")) ? e[0].split(" ") : e[0].split(",");
        }
        this.setState(
          {
            diamondCertiValue: this.state.isMultipleSearch && this.state.deSelectingOptn === false ? stones : e,
          },
          () => {
            if (this.state.diamondCertiValue && this.state.diamondCertiValue.length === 0) {
              this.setState({ diamondInput: "", diamondCerti: [] });
            }
            let tempArray = [];
            if (this.state.stoneArray) {
              tempArray = [...this.state.diamondCertiValue, ...this.state.stoneArray.split(",")];
            } else {
              tempArray = [...this.state.diamondCertiValue];
            }
            this.props.handleSelection("", true, "", tempArray, true);
            this.props.setSearchWidthValue(
              tempArray && tempArray.length > 30
                ? 1060
                : tempArray && tempArray.length > 10 && tempArray.length < 29
                ? 850
                : tempArray && tempArray.length === 0
                ? initialSearchWidth
                : 450
            );
            this.setState({
              deSelectingOptn: false,
            });
          }
        );
      }
    };

    const execPaste = async (clipText) => {
      const copiedData = clipText.trim();
      let stones = [];
      if (copiedData.includes("\r\n")) {
        stones = (await copiedData.includes("\r\n")) ? copiedData.split("\r\n") : copiedData.split(",");
      } else if (copiedData.includes(" ")) {
        stones = (await copiedData.includes(" ")) ? copiedData.split(" ") : copiedData.split(",");
      } else {
        stones = (await copiedData.includes(",")) ? copiedData.split(",") : copiedData.split(",");
      }
      if (stones?.length > 50) {
        OpenNotification({
          type: "error",
          title: "Maximum 50 stones can be searched at once.",
        });
        this.setState({
          diamondInput: "",
          diamondCerti: [],
          diamondCertiValue: [],
        });
        this.props.setSearchWidthValue(initialSearchWidth);
      } else {
        handleOnChangeSearch(stones);
      }
    };

    const handlePasteBtn = async () => {
      this.setState({ diamondInput: "", diamondCerti: [], diamondCertiValue: [] });
      if ("clipboard" in navigator) {
        navigator.clipboard.readText().then((clipText) => {
          execPaste(clipText);
        });
      } else {
        const pasteEvent = new ClipboardEvent("paste");
        document.dispatchEvent(pasteEvent);
        const navi = pasteEvent?.path?.[1]?.navigator;
        if ("clipboard" in navi) {
          navi.clipboard.readText().then((clipText) => {
            execPaste(clipText);
          });
        }
      }
    };

    return (
      <div className="diamondSearch" style={{ height: gridHeight, overflow: "auto" }}>
        <div className="d-flex justify-space-between searchTopBox align-items-center px-15">
          <Heading className="mb-0 ms-4 mb-0">
            <IntlMessage id="app.search-diamond" />
          </Heading>
          <div className="searchTopInputValue d-flex ">
            {/* <div className="searchTopInputValue d-flex align-items-center"> */}
            <div
              className="searchTopBlock border-f"
              style={{ width: searchWidth, dispaly: "flex", flexDirection: "row" }}
            >
              <IntlMessage id="app.StockNo" defaultMessage="search">
                {(placeholder) => (
                  <>
                    <SelectOption
                      getFil={(e) => (e.includes(" ") || e.includes(",") ? this.getMultiple(e) : this.getFilFn(e))}
                      diamond
                      diamondInput={this.state.diamondInput}
                      // defaultValue={this.state.diamondCertiValue}
                      mode={this.state.isMultipleSearch ? "tags" : "multiple"}
                      placeholder={`${placeholder} / Report No.`}
                      selectValue={totalDiamondsSeacrhed > 50 ? [] : uniqBy(this.state.diamondCerti, "id")}
                      value={totalDiamondsSeacrhed > 50 ? [] : this.state.diamondCertiValue}
                      onChange={(e) => handleOnChangeSearch(e)}
                      showSearch
                      onDeselect={(e) => deselectOption(e)}
                      // onInputKeyDown={(e) => {
                      //   // this.props.multipleSearch.length !== 0
                      //   //    ? this.props.handleMutipleSearch(true)
                      //   // this.props.handleSearch();
                      // }}
                    />
                  </>
                )}
              </IntlMessage>
            </div>
            <div style={{ padding: "15px" }}>
              <SnippetsOutlined
                style={{ fontSize: "28px", color: "#7f5f42" }}
                title="Paste"
                onClick={() => handlePasteBtn()}
              />
            </div>
          </div>
        </div>
        {isMobile && (
          <div className="d-flex j-space-between mobileSearchTab align-items-center">
            <Tabs selectedIndex={this.props.clrSwitch} className="customerSubTab">
              <TabList className="">
                <Tab onClick={() => this.props.handleClrSwitch(0)}>White Diamond</Tab>
                <Tab onClick={() => this.props.handleClrSwitch(1)}>Fancy Diamond</Tab>
              </TabList>
            </Tabs>
            <div className="searchCountBlock">
              <span className="searchCountLabel">Diamonds</span>
              <span className="searchCount">{this.props.count}</span>
            </div>
          </div>
        )}
        <div className="diamondSearchWrapper">
          <div className="diamondSearchItem">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessage id="app.Shape" />
              </label>
            </div>
            <div className="diamondSearchDetail width-100">
              <ul className={`diamondSearchShape ${this.state.shapeExtend && "active"}`}>
                <li
                  className={selectedMaster?.shp?.length === diamParams?.shp?.list?.length ? "active " : ""}
                  onClick={() => {
                    diamParams.shp.list.length &&
                      this.props.handleSelection(
                        flatten(map(diamParams.shp.list, "id")),
                        selectedMaster.shp && selectedMaster.shp.length > 0 ? false : true,
                        "shp"
                      );
                  }}
                >
                  <span className="alldiamondsearch">All</span>
                </li>
                {diamParams.shp.list &&
                  diamParams.shp.list.map((s, i) => {
                    s.checked = false;
                    each(s.id, (id) => {
                      s.checked = Boolean(selectedMaster.shp && selectedMaster.shp.includes(id));
                    });

                    return (
                      <li
                        key={i}
                        title={titleCase(s.webDisplay)}
                        className={s.checked && `active`}
                        onClick={() => this.props.handleSelection(s.id, !s.checked, "shp", "shape")}
                      >
                        <span>
                          <img src={urlJoin(BASE_URL, s.image)} alt="" />
                        </span>
                        <span className="shapeTitle">{titleCase(s.name)}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <Carat data={diamParams.carat.list} selectedCaratFn={this.props.selectedCaratFn} />
          <div className="diamondSearchItem">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessage id="app.compare.color" />
              </label>
              <div className="colorTab">
                <a
                  href="#!"
                  className={`${this.props.clrSwitch === 0 && "active"}`}
                  onClick={() => this.props.handleClrSwitch(0)}
                >
                  <IntlMessage id="app.white" />
                </a>
                <a
                  href="#!"
                  className={`${this.props.clrSwitch === 1 && "active"}`}
                  onClick={() => this.props.handleClrSwitch(1)}
                >
                  <IntlMessage id="app.fancy" />
                </a>
                <a
                  href="#!"
                  className={`${this.props.clrSwitch === 2 && "active"}`}
                  onClick={() => this.props.handleClrSwitch(2)}
                >
                  <IntlMessage id="app.range" />
                </a>
              </div>
            </div>
            <div className="diamondSearchDetail">
              {this.props.clrSwitch === 0 && (
                <MasterFilter
                  field={diamParams?.col}
                  value={selectedMaster?.col}
                  onChange={this.props.handleSelection}
                />
              )}
              {this.props.clrSwitch === 1 && (
                <MasterFilter
                  field={diamParams?.fcCol}
                  value={selectedMaster?.fcCol}
                  onChange={this.props.handleSelection}
                />
              )}
              {this.props.clrSwitch === 2 && (
                <MasterFilter
                  field={diamParams?.range}
                  value={selectedMaster?.col}
                  onChange={this.props.handleSelection}
                />
              )}
            </div>
          </div>
          {this.props.clrSwitch === 1 && (
            <>
              <div className={`diamondSearchItem ${this.props.clrSwitch === 1 && "fancyColorBlock"}`}>
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.intensity" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.inten}
                    value={selectedMaster?.inten}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
              <div className="diamondSearchItem">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.overtone" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.ovrtn}
                    value={selectedMaster?.ovrtn}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
            </>
          )}
          <div className="diamondSearchItem">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessage id="app.diamonddetails.Additionalinfo.coloeshade" />
              </label>
            </div>
            <div className={`diamondSearchDetail ${this.state.shapeExtend1 && "active"}`}>
              <ul className="diamondSearchList">
                <MasterFilter
                  field={diamParams?.shd}
                  value={selectedMaster?.shd}
                  onChange={this.props.handleSelection}
                />
                <li
                  className={this.props.BGM && `active`}
                  onClick={() => {
                    this.props.handleCombineSearch("BGM", !this.props.BGM, true);
                  }}
                >
                  BGM
                </li>
                <li
                  className={this.props.noBGM && `active`}
                  onClick={() => this.props.handleCombineSearch("noBGM", !this.props.noBGM, true)}
                >
                  NO BGM
                </li>
                <li
                  className={this.props.noBLACK && `active`}
                  onClick={() => this.props.handleCombineSearch("noBLACK", !this.props.noBLACK, true)}
                >
                  NO BLACK
                </li>
              </ul>
            </div>
          </div>

          <div className="diamondSearchItem">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessage id="app.tingeIntesity" />
              </label>
            </div>
            <div className={`diamondSearchDetail ${this.state.shapeExtend1 && "active"}`}>
              <ul className="diamondSearchList">
                <MasterFilter
                  field={diamParams?.mixTint}
                  value={selectedMaster?.mixTint}
                  onChange={this.props.handleSelection}
                />
              </ul>
            </div>
          </div>

          <div className="diamondSearchItem">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessage id="app.Clarity" />
              </label>
              <div className="colorTab"></div>
            </div>
            <div className="diamondSearchDetail">
              {!this.props.claritySwitch && (
                <ul className="diamondSearchList">
                  {diamParams.clarGrp.list.map((d, index) => (
                    <li
                      key={index}
                      className={includes(this.props.selectedClarGrps, d.grp) && `active`}
                      onClick={() => {
                        this.props.handleClarityGrpSearch(d.grp);
                      }}
                    >
                      {d.grp}
                    </li>
                  ))}
                </ul>
              )}
              {this.props.claritySwitch && (
                <MasterFilter
                  field={diamParams?.clr}
                  value={selectedMaster?.clr}
                  onChange={this.props.handleSelection}
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="diamondSearchItem makeGroup">
              <div className="d-flex align-items-center flex-wrap makeGroupingTop">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.make" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <ul className="diamondSearchList">
                    <li
                      className={this.props.threeEX && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch("threeEX", !this.props.threeEX, false);
                      }}
                    >
                      3EX
                    </li>
                    <li
                      className={this.props.threeEXNon && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch("threeEXNon", !this.props.threeEXNon, false);
                      }}
                    >
                      3EX-NON
                    </li>
                    <li
                      className={this.props.threeVG && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch("threeVG", !this.props.threeVG, false);
                      }}
                    >
                      3VG+
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap makeGroupingTop">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.compare.cut" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.cut}
                    value={selectedMaster?.cut}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap makeGroupingTop">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.compare.polish" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.pol}
                    value={selectedMaster?.pol}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap makeGroupingTop">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.symm" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.sym}
                    value={selectedMaster?.sym}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
            </div>
            <div className="diamondSearchItem floBlock">
              <div>
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.compare.flouresence" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.flu}
                    value={selectedMaster?.flu}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
            </div>
            <div className="diamondSearchItem labBlock">
              <div>
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.compare.lab" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.lb}
                    value={selectedMaster?.lb}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-xs-wrap">
            <div className="diamondSearchItem makeGroup">
              <div className="d-flex align-items-center flex-wrap makeGroupingTop">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.hna" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.hA}
                    value={selectedMaster?.hA}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap makeGroupingTop">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessage id="app.BRILLIANCE" />
                  </label>
                </div>
                <div className="diamondSearchDetail">
                  <MasterFilter
                    field={diamParams?.brlncyNm}
                    value={selectedMaster?.brlncyNm}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
            </div>
            <div className="diamondSearchItem w-xs-100" style={{ width: "40%", marginLeft: "5px" }}>
              <div>
                <div className="diamondSearchLabel">
                  <label>
                    <span>
                      <IntlMessage id="app.diamonddetails.Additionalinfo.Location" />
                    </span>
                  </label>
                </div>
                <div className="location ">
                  <div className="searchSelectItem multiSelect width-100" style={{ marginTop: "22px" }}>
                    <Select
                      value={this.props.selectedMaster.loc}
                      mode="multiple"
                      placeholder={<IntlMessage id="app.Select" />}
                      onChange={(val) => this.props.handleLocationChange(val)}
                      className="width-100"
                    >
                      {diamParams.loc.list &&
                        diamParams.loc.list.map((d, index) => (
                          <Select.Option key={index} value={d.id[0]}>
                            {d.webDisplay}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-xs-wrap">
            <div className="diamondSearchItem w-xs-100" style={{ width: "60%" }}>
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessage id="app.PriceNDisc" />
                </label>
              </div>
              <div className="diamondSearchDetail">
                <div className="d-flex align-items-center searchSelectValue">
                  <InputRange
                    className="mobileLabelHidden width-50"
                    title={<IntlMessage id="app.Disc%" />}
                    allowSame
                    Min={-100}
                    Max={100}
                    master={diamParams["back"]}
                    selectedRange={this.props.selectedRange}
                    apiKey="back"
                  />
                  <InputRange
                    className="width-50"
                    allowSame
                    Min={0}
                    Max={99999999}
                    master={diamParams["ctPr"]}
                    title={<IntlMessage id="app.PricePerCarat" />}
                    selectedRange={this.props.selectedRange}
                    apiKey="ctPr"
                  />
                </div>
              </div>
            </div>
            <div className="diamondSearchItem w-xs-100" style={{ width: "40%", marginLeft: "5px" }}>
              <div>
                <div className="diamondSearchLabel">
                  <label>
                    <span>
                      <IntlMessage id="app.CountryOfOrigin" />
                    </span>
                  </label>
                </div>
                <div className="d-flex j-space-between location">
                  <div className="searchSelectItem multiSelect width-100" style={{ marginTop: "22px" }}>
                    <Select
                      value={this.props.selectedMaster.org}
                      mode="multiple"
                      placeholder={<IntlMessage id="app.Select" />}
                      onChange={(val) => this.props.handleOriginChange(val)}
                      className="width-100"
                    >
                      {diamParams.org.list &&
                        diamParams.org.list.map((d, index) => (
                          <Select.Option key={index} value={d.name}>
                            {d.webDisplay}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="diamondSearchItem">
            <div
              className="hideShowBlock"
              onClick={() =>
                this.setState({
                  showParameter: !this.state.showParameter,
                })
              }
            >
              <span>
                <IntlMessage id="app.param" />
              </span>
              {this.state.showParameter ? <img src={upArrow} alt="" /> : <img src={downArrow} alt="" />}
            </div>
            {this.state.showParameter && (
              <div className="fullBlockInnerBlock">
                <div className="d-flex align-items-center">
                  <div className="diamondSearchDetail fullWidthBlock">
                    <div className="d-flex align-items-center flex-wrap">
                      <InputRange
                        title={<IntlMessage id="app.diamonddetails.Additionalinfo.Tableper" />}
                        allowSame
                        Min={0}
                        Max={100}
                        master={diamParams["tblPer"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="tblPer"
                        range={this.props.selectedMaster.tblPer}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.total-depth" />}
                        allowSame
                        Min={0}
                        Max={100}
                        master={diamParams["depPer"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="depPer"
                        range={this.props.selectedMaster.depPer}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.ratio" />}
                        allowSame
                        Min={0}
                        Max={100}
                        master={diamParams["ratio"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="ratio"
                        range={this.props.selectedMaster.ratio}
                        noNegative
                      />
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <InputRange
                        title="Length"
                        allowSame
                        Min={0}
                        Max={100}
                        master={diamParams["length"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="length"
                        range={this.props.selectedMaster.length}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.width" />}
                        allowSame
                        Min={0}
                        Max={100}
                        master={diamParams["width"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="width"
                        range={this.props.selectedMaster.width}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.depth" />}
                        allowSame
                        Min={0}
                        Max={100}
                        master={diamParams["height"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="height"
                        noNegative
                      />
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                      <InputRange
                        title={<IntlMessage id="app.crAn" />}
                        allowSame
                        Min={0}
                        Max={360}
                        master={diamParams["cAng"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="cAng"
                        range={this.props.selectedMaster.cAng}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.crHt" />}
                        allowSame
                        Min={0}
                        Max={99999999}
                        master={diamParams["cHgt"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="cHgt"
                        range={this.props.selectedMaster.cHgt}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.girdle%" />}
                        allowSame
                        Min={0}
                        Max={100}
                        master={diamParams["grdlPer"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="grdlPer"
                        range={this.props.selectedMaster.cHgt}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.pavAn" />}
                        allowSame
                        Min={0}
                        Max={360}
                        master={diamParams["pAng"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="pAng"
                        range={this.props.selectedMaster.pAng}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.pavHt" />}
                        allowSame
                        Min={0}
                        Max={99999999}
                        master={diamParams["pHgt"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="pHgt"
                        range={this.props.selectedMaster.pHgt}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.diamonddetails.Additionalinfo.LowerHalf" />}
                        allowSame
                        Min={0}
                        Max={99999999}
                        master={diamParams["lwr"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="lwr"
                        range={this.props.selectedMaster.lwr}
                        noNegative
                      />
                      <InputRange
                        title={<IntlMessage id="app.diamonddetails.Additionalinfo.StarLength" />}
                        allowSame
                        Min={0}
                        Max={99999999}
                        master={diamParams["strLngth"]}
                        selectedRange={this.props.selectedRange}
                        apiKey="strLngth"
                        range={this.props.selectedMaster.strLngth}
                        noNegative
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="diamondSearchItem">
            <div
              className="hideShowBlock"
              onClick={() => this.setState({ showWhiteInclusion: !this.state.showWhiteInclusion })}
            >
              <span>
                {/* <span>Inclusions and Other Parameters</span> */}
                <IntlMessage id="app.InclusionsNOtherParameters" />
              </span>
              {this.state.showOtherParams ? <img src={upArrow} alt="" /> : <img src={downArrow} alt="" />}
            </div>
            {this.state.showWhiteInclusion && (
              <div className="fullBlockInnerBlock">
                <div className="inclusionBlockMain d-flex">
                  <div className="blInclusion inclusion">
                    <div>
                      <label style={{ color: "black", fontWeight: "700", fontSize: "20px" }}>
                        <IntlMessage id="app.BlackInclusion" />
                      </label>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-wrap inclusionBlock">
                      <div className="diamondSearchDetail">
                        <div className="d-flex align-items-center inclusionLabelBlock">
                          <MasterFilter
                            name={<IntlMessage id="app.diamonddetails.Inclusion.blacktable" />}
                            field={diamParams?.blkCrn}
                            value={selectedMaster?.blkCrn}
                            onChange={this.props.handleSelection}
                            labelBold
                          />
                        </div>
                        <div className="d-flex align-items-center inclusionLabelBlock">
                          <MasterFilter
                            name={<IntlMessage id="app.SideBlack" />}
                            field={diamParams?.blkSd}
                            value={selectedMaster?.blkSd}
                            onChange={this.props.handleSelection}
                            labelBold
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wtInclusion inclusion">
                    <div>
                      <label style={{ color: "black", fontWeight: "700", fontSize: "20px" }}>
                        <IntlMessage id="app.WhiteInclusion" />
                      </label>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-wrap inclusionBlock">
                      <div className="diamondSearchDetail">
                        <div className="d-flex align-items-center inclusionLabelBlock">
                          <MasterFilter
                            name={<IntlMessage id="app.TableInclusion" />}
                            field={diamParams?.wCrn}
                            value={selectedMaster?.wCrn}
                            onChange={this.props.handleSelection}
                            labelBold
                          />
                        </div>
                        <div className="d-flex align-items-center inclusionLabelBlock">
                          <MasterFilter
                            name={<IntlMessage id="app.Sideinclusion" />}
                            field={diamParams?.wSd}
                            value={selectedMaster?.wSd}
                            onChange={this.props.handleSelection}
                            labelBold
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inclusionBlockMain d-flex">
                  <div className="blInclusion inclusion">
                    <div className="d-flex align-items-center d-flex mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.diamonddetails.Inclusion.crownopen" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.opCrwn}
                          value={selectedMaster?.opCrwn}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.diamonddetails.Inclusion.tableopen" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.opTbl}
                          value={selectedMaster?.opTbl}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.diamonddetails.Inclusion.pavillionopen" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.opPav}
                          value={selectedMaster?.opPav}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.diamonddetails.Inclusion.milky" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.mlk}
                          value={selectedMaster?.mlk}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.diamonddetails.Inclusion.luster" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.lstr}
                          value={selectedMaster?.lstr}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.diamonddetails.Inclusion.eyeclean" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.eCln}
                          value={selectedMaster?.eCln}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="wtInclusion inclusion">
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.NaturalCrown" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.nrlCrwn}
                          value={selectedMaster?.nrlCrwn}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.NaturalGirdle" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.nrlGrdl}
                          value={selectedMaster?.nrlGrdl}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.NaturalPavilion" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.nrlPav}
                          value={selectedMaster?.nrlPav}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.SurfaceGraining" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.srfcGrning}
                          value={selectedMaster?.srfcGrning}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-10 flex-xs-wrap">
                      <div className="diamondSearchLabel">
                        <label>
                          <IntlMessage id="app.InternalGraining" />
                        </label>
                      </div>
                      <div className="diamondSearchDetail">
                        <MasterFilter
                          field={diamParams?.intrnlGrning}
                          value={selectedMaster?.intrnlGrning}
                          onChange={this.props.handleSelection}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="diamondSearchItem">
            <div className="hideShowBlock" onClick={() => this.setState({ showGirdle: !this.state.showGirdle })}>
              <span>
                <IntlMessage id="app.girdle" />
              </span>
              {this.state.showGirdle ? <img src={upArrow} alt="" /> : <img src={downArrow} alt="" />}
            </div>
            {this.state.showGirdle && (
              <div className="diamondSearchDetail width-100">
                <div className="diamondSearchList">
                  <MasterFilter
                    field={diamParams?.grdl}
                    value={selectedMaster?.grdl}
                    onChange={this.props.handleSelection}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="diamondSearchItem">
            <div
              className="hideShowBlock"
              onClick={() => this.setState({ showKeyToSymbol: !this.state.showKeyToSymbol })}
            >
              <span>
                <IntlMessage id="app.KeyToSymbolReportComments" />
              </span>
              {this.state.showKeyToSymbol ? <img src={upArrow} alt="" /> : <img src={downArrow} alt="" />}
            </div>
            {this.state.showKeyToSymbol && (
              <KeyToSymbol
                master={diamParams["keyToSymbol"].list}
                value={this.props?.selectedMaster?.kToSArr ?? {}}
                onChange={this.props.selectedKeyToSymbol}
              />
            )}
          </div>
        </div>
        <MobileBottomAction
          searchList={searchList}
          USER_PERMIT={USER_PERMIT}
          {...this.props}
          saveSearchPopup={() => this.props.handleReverseState("showSavedSearch")}
          value={this.state.searchId}
          onChange={(searchId) => {
            this.setState({ searchId }, () => {
              handleSelectSearch(searchId, (searchData, name) => {
                if (!isEmpty(searchData)) {
                  this.props.handleChange("searchTitle", name);
                  this.props.handleChange("searchId", searchId);
                  this.props.handleAfterSelectSearch(searchData);
                }
              });
            });
          }}
        />
        <div className="botoomStickyBar diamondSearchSticky ">
          <div className="d-flex align-items-center">
            {USER_PERMIT?.SEARCH_DIAMOND?.view &&
              (this.props.count < 1000 ? (
                <a
                  href="#!"
                  className="bottomStickyButton"
                  onClick={() => {
                    this.props.multipleSearch.length !== 0
                      ? this.props.handleMutipleSearch(true)
                      : this.props.handleSearch();
                  }}
                >
                  <span>
                    <IntlMessage id={this.props.count === 0 ? "app.add-demand" : "app.search-btn-footer"} />
                    {isNumeric(this.props.count) && Number(this.props.count) > 0 && ` (${this.props.count})`}
                  </span>
                </a>
              ) : (
                <div className=" bottomStickyButton disableStyle">
                  <a
                    href="#!"
                    onClick={() =>
                      OpenNotification({
                        type: "error",
                        title: " Please modify your search, maximum 1000 stones can be displayed",
                      })
                    }
                  >
                    <span>
                      <IntlMessage id={this.props.count === 0 ? "app.add-demand" : "app.search-btn-footer"} />
                      {isNumeric(this.props.count) && Number(this.props.count) > 0 && ` (${this.props.count})`}
                    </span>
                  </a>
                </div>
              ))}
            {USER_PERMIT?.SAVE_SEARCH?.view && !this.state.searchId && (
              <a
                href="#!"
                className="bottomStickyButton"
                onClick={() => {
                  if (this.state.isGuest) return this.setState({ showGuest: true });
                  if (this.state.isKyc) return this.setState({ noKyc: true });
                  if (Number(this.props.count) > 1000)
                    return OpenNotification({
                      type: "error",
                      title: "Please modify your search, maximum 1000 stones can be displayed",
                    });

                  this.props.handleReverseState("showSavedSearch");
                }}
              >
                <span>
                  <IntlMessage id="app.AddSaveNSearch" />
                </span>
              </a>
            )}
            {USER_PERMIT?.SEARCH_DIAMOND?.update && this.props.searchId && (
              <a
                href="#!"
                className="bottomStickyButton"
                onClick={() => {
                  if (this.state.isGuest) return this.setState({ showGuest: true });
                  if (this.state.isKyc) return this.setState({ noKyc: true });
                  if (Number(this.props.count) > 1000)
                    return OpenNotification({
                      type: "error",
                      title: "Please modify your search, maximum 1000 stones can be displayed",
                    });
                  this.props.handleReverseState("showSavedSearch");
                }}
              >
                <span>
                  <IntlMessage id="app.update-search" />
                </span>
              </a>
            )}
            {MULTIPLE_SEARCH && USER_PERMIT?.SEARCH_DIAMOND?.view && (
              <a
                href="#!"
                onClick={() => {
                  if (this.state.isGuest) return this.setState({ showGuest: true });
                  if (this.state.isKyc) return this.setState({ noKyc: true });

                  this.props.handleMutipleSearch();
                }}
                className="bottomStickyButton"
              >
                <span>
                  <IntlMessage id="app.addAnotherSearch" />
                  {`${this.props.multipleSearch.length ? `(${this.props.multipleSearch.length})` : ""}`}
                </span>
              </a>
            )}
            {/* {USER_PERMIT?.MATCH_PAIR?.view && (
              <a
                href="#!"
                className="bottomStickyButton"
                onClick={() => {
                  if (this.state.isGuest) return this.setState({ showGuest: true });
                  if (this.state.isKyc) return this.setState({ noKyc: true });
                  this.props.handleMatchPair();
                }}
              >
                <span>
                  <IntlMessage id="app.matchpairDashboard" />
                </span>
              </a>
            )} */}
            {/* <a href="/pair-diamonds" className="bottomStickyButton buttonHlightlight">
              <span>
                <IntlMessage id="couple diamonds" />
                Pair diamonds
              </span>
            </a> */}
            {/* {USER_PERMIT?.NEW_ARRIVAL?.view && (
              <a
                href="#!"
                className="bottomStickyButton buttonHlightlight"
                onClick={() => {
                  if (this.state.isGuest) return this.setState({ showGuest: true });
                  if (this.state.isKyc) return this.setState({ noKyc: true });
                  this.props.handleNewArrival();
                }}
              >
                <span>
                  <IntlMessage id="app.newArrival" />
                  {isNumeric(this.props.newArrivalCount) &&
                    Number(this.props.newArrivalCount) > 0 &&
                    ` (${this.props.newArrivalCount})`}
                </span>
              </a>
            )}

            {USER_PERMIT?.BID_TO_BUY?.view && (
              <a
                href="#!"
                className="bottomStickyButton buttonHlightlight"
                onClick={() => {
                  if (this.state.isGuest) return this.setState({ showGuest: true });
                  if (this.state.isKyc) return this.setState({ noKyc: true });
                  this.props.handleBidToBuy();
                }}
              >
                <span>
                  <IntlMessage id="app.bid-to-buy" />
                  {isNumeric(this.props.bidToBuyCount) &&
                    Number(this.props.bidToBuyCount) > 0 &&
                    ` (${this.props.bidToBuyCount})`}
                </span>
              </a>
            )} */}
            <a
              href="#!"
              className="bottomStickyButton"
              onClick={() => this.setState({ searchId: undefined }, () => this.props.handleRefresh())}
            >
              <span>
                <IntlMessage id="app.Reset" />
              </span>
            </a>
          </div>
          {USER_PERMIT?.SAVE_SEARCH?.view && (
            <div className="select-diamonds">
              <Select
                value={this.state.searchId}
                placeholder={<IntlMessage id="app.select-saved-search" />}
                onChange={(searchId) => {
                  this.setState({ searchId }, () => {
                    handleSelectSearch(searchId, (searchData, name) => {
                      if (!isEmpty(searchData)) {
                        this.props.handleChange("searchTitle", name);
                        this.props.handleChange("searchId", searchId);
                        this.props.handleAfterSelectSearch(searchData);
                      }
                    });
                  });
                }}
                style={{ width: "200px" }}
              >
                {searchList.map((s, i) => (
                  <Select.Option key={i} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </div>
        <SavedSearchPopup {...this.props} />
        <AddDemandPopup {...this.props} />
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => this.setState({ showGuest: false })}
          />
        )}
        {this.state.noKyc && (
          <NoKycPop visible={this.state.noKyc} handleCancel={() => this.setState({ noKyc: false })} />
        )}
      </div>
    );
  }
}
// export default compose(
//   connect((state) => ({ auth: state?.auth, commonData: state?.commonData })),
//   withMobile,
//   withRouter,
//   // setSearchWidthValue,
// )(DiamondSearch);

// export default Header;
function mapStateToProps(props) {
  return {
    auth: props?.auth,
    commonData: props?.commonData,
  };
}

export default connect(mapStateToProps, {
  withMobile,
  withRouter,
  setSearchWidthValue,
})(withRouter(DiamondSearch));
