import React from "react";

import CellInput from "components/CellInput";

import { roundBidDiscount } from "util/_extra-utils";
import { formatDecimal, objectToQueryString } from "util/utils";

import minusJPG from "assets/img/minus.jpg";
import plusJPG from "assets/img/plus.jpg";
import { getParams, getStoneInfo } from "./DiamondListFunctions";

import { DIAMOND_BID, LOCAL_STORAGE_VAR, PAGES } from "../../constants/Common";
import { OFFERSTATUS } from "../Offer";
import { formatNumber } from "../common/DiamondListing/SelectStone";
import { STATUS, STATUS_COLOR } from "../common/DiamondListing/Status";


const LISTINGPAGES = PAGES;

const MobileViewListCard = (props) => {
  const view = ["/account/order-list", "/bid-to-buy", "/new-arrival", "/my-bid"].includes(window.location.pathname)
    ? 0
    : 1;

  const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).SHAPE || {};

  const value = masterData?.find((x) => x.code === props.data.shpNm)?.webDisplay;
  const shpNm = value === undefined ? props.data.shpNm : value;

  return (
    <div
      style={{
        backgroundColor: props.bid ? (props.data.status === DIAMOND_BID.STATUS.WON ? "#9eda96" : "#e98388") : "",
      }}
      className={`diamodListItemWrapper ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : "green"} ${
        props.activeClass ? props.activeClass : null
      } ${props.quotePage ? "quotePageWrapper" : null} ${props.bid ? "green" : ""}`}
    >
      {!props.quotePage && (
        <>
          <div className="mobileListHeader" onClick={props.onClick}>
            <div className="mobileListHeaderBlock">
              <a
                className="mobileListItem"
                href={`/${  LISTINGPAGES.DETAIL  }/${  props.data.id  }${objectToQueryString({ view })}`}
              >
                <span>{props.data.vStnId}</span>
              </a>
              <div className="mobileListItem">
                <span>{shpNm}</span>
              </div>
              <div className="mobileListItem">
                <span>{`${formatNumber(parseFloat(props.data.back).toFixed(2))}%`}</span>
              </div>
              <div className="mobileListItem themeColor">
                <span>{props?.data?.ctPr ? `${formatNumber(parseFloat(props.data.ctPr).toFixed(2))}$/Cts` : "-"}</span>
              </div>
            </div>
          </div>
          <div className="mobileDiamondListBottom" onClick={props.onClick}>
            <div className="mobileListHeaderBlock">
              <div className="mobileListItem basicDetail">
                <span>
                  {getParams(props.data).join(" ")} 
                  {' '}
                  {props.data.lbNm}
                </span>
              </div>

              <div className="mobileListItem">
                <span>{}</span>
              </div>

              <div className="mobileListItem">
                <span>{props.data.msrmnt}</span>
              </div>
              <div className="mobileListItem totalPrice">
                <span>{props?.data?.amt ? `${formatNumber(parseFloat(props.data.amt).toFixed(2))}$/Amt` : "-"}</span>
              </div>
            </div>
          </div>
        </>
      )}
      {props.quote && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem" onClick={() => props.quoteClick("quote", props.data)}>
            <span>Quote : </span>
            <span>{props.data.quote}</span>
          </div>
          <div className="quoteExtraItem" onClick={() => props.quoteClick("hours", props.data)}>
            <span>Hour : </span>
            <span>{props.data.hours}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Final Quote : </span>
            <span>{parseFloat(props.data.finalquote).toFixed(2)}</span>
          </div>
        </div>
      )}
      {props.quoteMobile && (
        <>
          <div className="d-flex j-space-between quoteExtraDetail w-100">
            <div className="quoteExtraItem w-50">
              <span>OFF. DiS(%) : </span>
              <span className="mobileInputField">
                {(props.data.back || props.data.rap) && (
                  <img
                    style={{ marginRight: "3px", alignItems: "center" }}
                    src={minusJPG}
                    width="18px"
                    height="18px"
                    onClick={() => props.getOfferDiscount(props.data.stoneId, true)}
                    alt="minus"
                  />
                )}
                <input value={props.disCountVal} style={{ color: "#000" }} disabled />
                {(props.data.back || props.data.rap) && (
                  <img
                    style={{ marginLeft: "3px" }}
                    src={plusJPG}
                    width="18px"
                    height="18px"
                    onClick={() => props.getOfferDiscount(props.data.stoneId, false)}
                    alt="plus"
                  />
                )}
              </span>
            </div>
            <div className="quoteExtraItem w-50">
              <span>OFF. PR/CT : </span>
              <span className="mobileInputField">
                {(!props.data.back || !props.data.rap) && (
                  <img
                    style={{ marginRight: "3px", alignItems: "center" }}
                    src={minusJPG}
                    width="18px"
                    height="18px"
                    onClick={() => props.getOfferDiscount(props.data.stoneId, true, true)}
                    alt="minus"
                  />
                )}
                {/* <input value={props.calcPricePerCarat} style={{ color: '#4eb45e' }} disabled /> */}
                <input value={formatDecimal(props.calcPricePerCarat)} style={{ color: "#000" }} disabled />
                {(!props.data.back || !props.data.rap) && (
                  <img
                    style={{ marginLeft: "3px" }}
                    src={plusJPG}
                    width="18px"
                    height="18px"
                    onClick={() => props.getOfferDiscount(props.data.stoneId, false, true)}
                    alt="plus"
                  />
                )}
              </span>
            </div>
          </div>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem w-50">
              <span>OFF. AMT : </span>
              <span className="mobileInputField">
                {/* <input style={{ color: '#4eb45e' }} value={props.calcAmount} disabled /> */}
                <input style={{ color: "#000" }} value={formatDecimal(props.calcAmount)} disabled />
              </span>
            </div>
          </div>
        </>
      )}
      {props.bid && (
        <>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Bid Disc(%) : </span>
              <span>
                {props.data.isFcCol
                  ? "-"
                  : props.data.bidDiscount < 0
                  ? `-${parseFloat(Math.abs(props.data.bidDiscount)).toFixed(2)}`
                  : `+${parseFloat(Math.abs(props.data.bidDiscount)).toFixed(2)}`}
              </span>
            </div>
            <div className="quoteExtraItem">
              <span>Bid Price/CT : </span>
              <span>{formatNumber(parseFloat(props.data.bidPricePerCarat).toFixed(2))}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Bid Amt : </span>
              <span>{formatNumber(parseFloat(props.data.bidAmount).toFixed(2))}</span>
            </div>
          </div>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Type : </span>
              <span>{STATUS[props.data.wSts]}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Status : </span>
              <span style={{ color: `${props.data.status === DIAMOND_BID.STATUS.WON ? "#145e00" : "#db1c1c"}` }}>
                {props.data.status === DIAMOND_BID.STATUS.WON ? "Won" : "Loss"}
              </span>
            </div>
          </div>
        </>
      )}
      {props.BidTab && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Bid Disc(%) : </span>
            <span>
              {props.data.isFcCol
                ? "-"
                : props.data.bidDiscount < 0
                ? `-${parseFloat(Math.abs(props.data.bidDiscount)).toFixed(2)}`
                : `+${parseFloat(Math.abs(props.data.bidDiscount)).toFixed(2)}`}
            </span>
          </div>

          <div className="quoteExtraItem">
            <span>Bid Price/CT : </span>
            <span>{formatNumber(parseFloat(props.data.bidPricePerCarat).toFixed(2))}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Bid Amt : </span>
            <span>{formatNumber(parseFloat(props.data.bidAmount).toFixed(2))}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Max. Bid : </span>
            <span>{formatNumber(parseFloat(props.data.maxBid || 0).toFixed(2))}</span>
          </div>
        </div>
      )}
      {props.finalCalc && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="mobileCardThreeBlock">
            <span>Final Disc% : </span>
            <span>{parseFloat(props.data.calcDiscount).toFixed(2)}</span>
          </div>
          <div className="mobileCardThreeBlock text-center">
            <span>Final Rate : </span>
            <span>
              $
              {parseFloat(props.data.calcPricePerCarat).toFixed(2)}
            </span>
          </div>
          <div className="mobileCardThreeBlock text-right">
            <span>Final Value : </span>
            <span>
              $
              {parseFloat(props.data.calcAmount).toFixed(2)}
            </span>
          </div>
        </div>
      )}
      {props.bidPopup && (
        <>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Bid DiS(%) : </span>
              <span className="mobileInputField">
                <CellInput
                  diffInterval={props.diffInterval}
                  style={{ paddingLeft: Number(props.data.bidDiscount) > 0 ? "8px" : 0, width: "70%" }}
                  onChange={props.handleDiscountInput(props.data.stoneId)}
                  transformInput={roundBidDiscount}
                  value={props.data.bidDiscount}
                  showIncrButton
                  showDecrButton
                />
              </span>
            </div>
            <div className="quoteExtraItem">
              <span>Bid PR/CT : </span>
              <span className="mobileInputField">
                <input value={formatNumber(props.data.bidPricePerCarat)} disabled />
              </span>
            </div>
          </div>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              <span>Bid Value : </span>
              <span className="mobileInputField">
                <input value={formatNumber(parseFloat(props.data.bidAmount || 0).toFixed(2))} disabled />
              </span>
            </div>
          </div>
        </>
      )}
      {props.watchpopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="watchlistPopupSelect">
            <span>Exp. Disc(%) : </span>
            <span>{props.watchpopup(props.data)}</span>
          </div>
        </div>
      )}
      {props.note && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Notes : </span>
            <span>{props.data.remarks || "-"}</span>
          </div>
        </div>
      )}
      {props.quotePage && (
        <>
          <div className="mobileListHeader" onClick={props.onClick}>
            <div className="mobileListHeaderBlock">
              {/* <div className="mobileListItem">
                <Countdown date={moment(props.data.offerValidDate)} />
              </div> */}
              <div className="mobileListItem">
                <span>
                  {(props.data.back ? `${parseFloat(props.data.back).toFixed(2)  } / ` : "") +
                    parseFloat(props.data.ctPr).toFixed(0)}
                </span>
              </div>
              <div className="mobileListItem themeColor">
                <span>
                  {(props.data.newDiscount ? `${parseFloat(props.data.newDiscount).toFixed(2)  } / ` : "") +
                    parseFloat(props.data.newPricePerCarat).toFixed(0)}
                </span>
              </div>
            </div>
          </div>
          <div className="mobileDiamondListBottom" onClick={props.onClick}>
            <div className="mobileListHeaderBlock">
              <div className="mobileListItem basicDetail">
                <span>{getStoneInfo(props.data)}</span>
              </div>
              <div className="mobileListItem activeStatus pending">
                <span>{OFFERSTATUS[props.data.offerStatus]}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default MobileViewListCard;
