import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { Tooltip, Spin } from "antd";
import _ from "lodash";
import moment from "moment";

import { withMobile } from "util/hocs";

import plusSVG from "../../assets/svg/plus.svg";
import delSVG from "../../assets/svg/savedSearch/delete.svg";
import editSVG from "../../assets/svg/savedSearch/edit.svg";
import searchSVG from "../../assets/svg/savedSearch/search.svg";
import { PAGES, diamondParameters } from "../../constants/Common";
import { SEARCH_LIST, DELETE_SEARCH } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { getGridHeight } from "../DiamondList";
// import { LISTINGPAGES } from '../DiamondList/DiamondListFunctions';
import OpenNotification from "../common/CommonButton/OpenNotification";
import DeleteConfirmation from "../common/DeleteConfirmation";
import Heading from "../common/Heading";
import NoDataShow from "../common/NoDataShow";
import Pagination from "../common/Pagination";

const LISTINGPAGES = PAGES;

class SavedSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      total: 0,
      page: 1,
      limit: 10,
      showModal: false,
      id: "",
      loading: false,
    };
  }

  componentDidMount() {
    const self = this;
    window.addEventListener("resize", () => {
      self.forceUpdate();
    });
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
  }
  getData() {
    const self = this;
    self.setState({ loading: true }, () => {
      const obj = {
        type: 2,
        page: self.state.page,
        limit: self.state.limit,
        isAppendMasters: true,
      };

      const api = SEARCH_LIST.url;
      const objData = {
        method: SEARCH_LIST.method,
        url: api,
        request: obj,
      };

      UtilService.callApi(objData, (err, data) => {
        self.setState({ loading: false });
        if (err) {
          if (err.data && err.data.code === "E_NOT_FOUND") {
            return self.setState({ rows: [], total: 0 });
          } else {
            throw err;
          }
        }
        if (data && data.code === "OK") {
          self.setState({ rows: data.data.list, total: data.data.count });
        }
      });
    });
  }

  handleDelete() {
    const self = this;
    const obj = {
      id: this.state.id,
    };
    const api = DELETE_SEARCH.url;
    const objData = {
      method: DELETE_SEARCH.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        if ((self.state.total - 1) % self.state.limit === 0) {
          if (self.state.page > 1) await self.setState({ page: self.state.page - 1 });
        }
        self.setState({ showModal: false, id: "" });
        self.getData();
        OpenNotification({
          type: "success",
          title: "Your saved search is deleted successfully.",
        });
      }
    });
  }
  emptyData = (loading, length) => {
    if (loading) return <NoDataShow message={<Spin />} />;
    else if (length === 0) return <NoDataShow />;
    else return <></>;
  };
  render() {
    const { isMobile } = this.props;
    const gridHeight = getGridHeight(
      [
        "headerTopMenu",
        "accountTopBlock",
        "accountTabBox",
        "botoomStickyBar",
        "covidStrip",
        "HeaderStripBlock",
        "popupInnerTitle",
      ],
      10
    );

    return (
      <>
        <div className="savedSearchMainWrapper">
          {isMobile && <Heading title="Saved Search " className="popupInnerTitle p-lr-15"></Heading>}
          <div className="savedSearchWrapper" style={{ height: gridHeight, overflow: "auto" }}>
            {this.state.rows.map((data, index) => (
              <div key={index} className="savedSearchItem">
                <div className="savedSearchTop">
                  <span className="savedTitle">{data.name}</span>
                  <span className="savedSearchAction">
                    {this.props.isUpdate && (
                      <div className="savedSearchActionItem">
                        <img
                          src={editSVG}
                          alt="icon"
                          onClick={() => {
                            this.props.history.push(`/${LISTINGPAGES.SEARCH}/${data.id}`);
                          }}
                        />
                      </div>
                    )}
                    {this.props.isDelete && (
                      <div className="savedSearchActionItem">
                        <img
                          onClick={() =>
                            this.setState({
                              showModal: !this.state.showModal,
                              id: data.id,
                            })
                          }
                          src={delSVG}
                          alt="icon"
                        />
                      </div>
                    )}

                    <div className="savedSearchActionItem">
                      <img
                        src={searchSVG}
                        alt="icon"
                        onClick={() => {
                          this.props.history.push(`/diamond-list?${data.id}`);
                        }}
                      />
                    </div>
                  </span>
                </div>
                <div className="savedSearchBottom">
                  {Object.entries(data.displayData).length === 0 ? (
                    <div className="savedSearchValue">
                      <span>All</span>
                    </div>
                  ) : (
                    Object.keys(data.displayData).map((filter, ind) => {
                      const param = _.find(diamondParameters, { key: filter });
                      if (param) {
                        if (param.key === "or") {
                          if (_.has(data.displayData["or"][0], "crt")) {
                            const arr = data.displayData["or"].map((carat) => {
                              return carat["crt"][">="] + " to " + carat["crt"]["<="];
                            });
                            return (
                              <div className="savedSearchValue" key={ind}>
                                <span>Carat : </span>
                                <span>{_.join(arr, ", ")}</span>
                              </div>
                            );
                          } else if (
                            //Stone ID/ Report Number/ Seller Stone Number
                            _.has(data.displayData["or"][0], "stoneId") ||
                            _.has(data.displayData["or"][0], "vStnId") ||
                            _.has(data.displayData["or"][0], "pairStkNo")
                          ) {
                            return data.displayData["or"].map((id) => {
                              if (_.keys(id)[0] === "stoneId") {
                                return (
                                  <div className="savedSearchValue">
                                    <span>Stone ID/ Report Number : </span>
                                    <span>{_.join(_.values(id), ", ")}</span>
                                  </div>
                                );
                              }
                              if (_.keys(id)[0] === "vStnId") {
                                return (
                                  <div className="savedSearchValue" key={ind}>
                                    <span>Seller Stone Number : </span>
                                    <span>{_.join(_.values(id), ", ")}</span>
                                  </div>
                                );
                              }
                              if (_.keys(id)[0] === "pairStkNo") {
                                return (
                                  <div className="savedSearchValue" key={ind}>
                                    <span>Pair Number : </span>
                                    <span>{_.join(_.values(id), ", ")}</span>
                                  </div>
                                );
                              }
                              return null;
                            });
                          }
                        } else if (param.key === "vnd") {
                          const arr = data.displayData["vnd"].map((v) => {
                            return v.companyName;
                          });
                          return (
                            <div className="savedSearchValue" key={ind}>
                              <span>{param.name} : </span>
                              <span>{_.join(arr, ", ")}</span>
                            </div>
                          );
                        } else {
                          if (_.isObject(data.displayData[filter]) && !_.isArray(data.displayData[filter])) {
                            if (filter === "kToSArr") {
                              const arr = Object.values(data.displayData["kToSArr"])[0];
                              const key = Object.keys(data.displayData["kToSArr"])[0];

                              return (
                                <div className="savedSearchValue" key={ind}>
                                  <span>
                                    {param.name}&nbsp;:&nbsp;
                                    <span>{key === "in" ? "Contains" : "Not contains"}</span>
                                  </span>
                                  <span>{_.join(arr, ", ")}</span>
                                </div>
                              );
                            } else if (filter === "type2") {
                              return (
                                <div className="savedSearchValue" key={ind}>
                                  <span>{param.name}&nbsp;:&nbsp;</span>
                                  <span>YES</span>
                                </div>
                              );
                            } else {
                              return (
                                <div className="savedSearchValue" key={ind}>
                                  <span>{param.name} : </span>
                                  <span>
                                    {(filter === "marketingIssueDate"
                                      ? moment(data.displayData[filter][">="]).format("LL")
                                      : data.displayData[filter][">="]) +
                                      "  to  " +
                                      (filter === "marketingIssueDate"
                                        ? moment(data.displayData[filter]["<="]).format("LL")
                                        : data.displayData[filter]["<="])}
                                  </span>
                                </div>
                              );
                            }
                          } else if (typeof data.displayData[param.key] === "boolean") {
                            return (
                              <div className="savedSearchValue" key={ind}>
                                <span>{param.name} : </span>
                                <span>{data.displayData[param.key] === true ? "YES" : "NO"}</span>
                              </div>
                            );
                          } else {
                            let disp = data.displayData[param.key];
                            if (param.key === "showSrNo") {
                              if (disp.includes(",")) disp = disp.split(",");
                              else disp = disp.split(" ");
                            }
                            return (
                              <div className="savedSearchValue" key={ind}>
                                <span>{param.name} : </span>
                                <span>{_.join(disp, ", ")}</span>
                              </div>
                            );
                          }
                        }
                      }
                      return null;
                    })
                  )}
                </div>
              </div>
            ))}
            {this.emptyData(this.state.loading, this.state.rows.length)}
          </div>

          <DeleteConfirmation
            showModal={this.state.showModal}
            handleCancel={() => this.setState({ showModal: !this.state.showModal })}
            handleDelete={() => this.handleDelete()}
          />
        </div>
        <Tooltip title="Add New Search">
          <a className="addOption" href="/diamond-search">
            <img src={plusSVG} alt="" />
          </a>
        </Tooltip>
        <div className="botoomStickyBar bottom-0">
          <div></div>
          <Pagination
            count={this.state.total}
            limit={this.state.limit}
            page={this.state.page}
            onPaginationChange={(page) => {
              this.setState({ page: page }, () => {
                this.getData();
              });
            }}
            data={this.state.rows}
            noLimitChange
          />
        </div>
      </>
    );
  }
}

export default compose(
  connect((store) => ({ commonData: store.commonData })),
  withMobile,
  withRouter
)(SavedSearch);
