import React from "react";

import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import PropTypes from "prop-types";

function EmbedPdf(props) {
  const { fileUrl, noPlugins, fit = "pageFit" } = props;

  const _defaultLayoutPlugin = defaultLayoutPlugin();

  const defaultScale = React.useMemo(() => {
    if (fit === "pageWidth") return SpecialZoomLevel.PageWidth;
    if (fit === "pageFit") return SpecialZoomLevel.PageFit;
    return SpecialZoomLevel.ActualSize;
  }, [fit]);

  const plugins = React.useMemo(() => {
    if (noPlugins) return [];
    return [_defaultLayoutPlugin];
  }, [_defaultLayoutPlugin, noPlugins]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
      <Viewer fileUrl={fileUrl} defaultScale={defaultScale} plugins={plugins} />
    </Worker>
  );
}

EmbedPdf.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  fit: PropTypes.string,
  noPlugins: PropTypes.bool,
};

export default React.memo(EmbedPdf);
