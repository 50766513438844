import React from "react";
import { withRouter } from "react-router-dom";

import "../assets/scss/grading.css";
import Footer from "./Footer";
import StaticHeader from "./Static/StaticHeader";

import diamond from "../assets/img/landing-page/diamonds.png";

const GradingSystem = () => {
  return (
    <>
      <div className="grading_header">
        <StaticHeader />
      </div>

      <section id="ethical" className="d-flex  ethical-bg-img clip-r">
        <div className="container">
          <div className="ethical-slider position-relative">
            <div className="row">
              <div className="col-xl-12 col-lg-12 text-center position-transferam ">
                <div className>
                  <h2>KGK International</h2>
                  <h2>Diamond Grading Process</h2>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-posi pt-5 position-relative">
            <div className="posi-ab">
              <div className="diamond-icon">
                <div className="col-xl-10 mx-auto">
                  <div className="right-text">
                    <figure className="banner-img mb-5">
                      {" "}
                      <img src={diamond} alt="" className="img-fluid" />{" "}
                    </figure>
                  </div>
                </div>
              </div>
              <div className="poster-text">
                <div className="col-xl-10 banner-bg-color mx-auto">
                  <div className="text-center p-3 text-dark">
                    <p className="fw-boldt_white t_white">
                      KGK Diamonds Grading Process (KGP) is one of the most stringent and transparent quality checks
                      standards in the world. KGP gives in-depth details of the stone that can be seen with the
                      high-quality video of each stone. Our Quality Check (QC) goes through a rigorous and intense
                      processes by our finest craftsman. So, every diamond you get from KGK surpasses the quality
                      standards of the 4C industry standard. For us at KGK, the trust of our client is our most
                      important asset.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="white-t" className="white-t">
          <div className="container">
            <div className="p-5 shadow mobile">
              <div className="row justify-content-between mb-4">
                <div className="col-49 roe">
                  <div className="col-xl-11">
                    <div className="text-center mb-4">
                      <h3>White Inclusion Table</h3>{" "}
                    </div>
                    <div className="table  ">
                      <div className="d-flex flex-wrap">
                        <div className="row bgcolor">
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">T0</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Table Clean</div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">T2</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Medium Inclusions</div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">T1</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Pinpoint to Minor inclusions</div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">T3</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Heavy Inclusions</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-49 border-left roe">
                  <div className="col-xl-11 offset-lg-1">
                    <div className="text-center mb-4">
                      <h3>White Inclusion side</h3>{" "}
                    </div>
                    <div className="side">
                      <div className="d-flex flex-wrap">
                        <div className="row bgcolor">
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">S0</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Side Clean</div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">S2</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Medium Inclusions</div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">S1</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Pinpoint to minor inclusions</div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">S3</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Heavy Inclusions</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center bottom-text">
                <div className="col-xl-2" />
                <div className="col-xl-9 mx-auto">
                  <p className="mb-0">
                    Key: T0 indicates no white inclusion; T1,T2 &amp; T3 indicate an increasing proportion of inclusion
                    in a table.{" "}
                  </p>
                  <p>Same for side inclusions as well</p>
                </div>
                <div className="col-xl-2" />
              </div>
            </div>
          </div>
        </section>
        <section id="black-t" className="black-t">
          <div className="container">
            <div className="p-5 shadow mobile">
              <div className="row justify-content-between mb-4">
                <div className="col-49">
                  <div className="col-xl-11">
                    <div className="text-center mb-4">
                      <h3>Black Inclusion Table</h3>{" "}
                    </div>
                    <div className="table  ">
                      <div className="row">
                        <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                          <div className="t_white">B0</div>
                          <span className="t_white"> - </span>
                          <div className="t_white">Table No Black</div>
                        </div>
                        <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                          <div className="t_white">B1</div>
                          <span className="t_white"> - </span>
                          <div className="t_white">Pinpoint Black</div>
                        </div>
                        <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                          <div className="t_white">BPP</div>
                          <span className="t_white"> - </span>
                          <div className="t_white">Pinpoint Black</div>
                        </div>
                        <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                          <div className="t_white">B2</div>
                          <span className="t_white"> - </span>
                          <div className="t_white">Medium Black</div>
                        </div>
                        <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto">
                          <div className="t_white">B3</div>
                          <span className="t_white"> - </span>
                          <div className="t_white">Heavy Black</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-49 border-left">
                  <div className="col-xl-11 offset-lg-1 ">
                    <div className="text-center mb-4">
                      <h3>Black Inclusion side</h3>{" "}
                    </div>
                    <div className="side">
                      <div className="d-flex flex-wrap">
                        <div className="row ">
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">SB0</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Side Clean</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">SB1</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Pinpoint Black</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">SBPP</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Pinpoint Black</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">SB2</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Medium Black</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto">
                            <div className="t_white">SB3</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Heavy Black</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center bottom-text">
                <div className="col-xl-2" />
                <div className="col-xl-9 mx-auto">
                  <p className="mb-0">
                    Key: B0 indicates no black inclusion on the table whereas BPP, B1, B2 &amp; B3 indicate an
                    increasing proportion of black inclusion in a table. Same for side inclusions as well.
                  </p>
                </div>
                <div className="col-xl-2" />
              </div>
            </div>
          </div>
        </section>
        <section id="sepret-two" className="sepret-two">
          <div className="container">
            <div className="row justify-content-between mobile">
              <div className="col-48 inclusion-pattern">
                <div className="p-5 shadow ">
                  <div className="row justify-content-between mb-4">
                    <div className="col-xl-12">
                      <div className="text-center mb-4">
                        <h3>Internal Graining</h3>{" "}
                      </div>
                      <div className="table  ">
                        <div className="row">
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">N</div>
                            <span className="t_white"> : </span>
                            <div className="t_white">None/Negligible inclusion</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">S</div>
                            <span className="t_white"> : </span>
                            <div className="t_white">Scattered inclusion</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto">
                            <div className="t_white">C</div>
                            <span className="t_white"> : </span>
                            <div className="t_white">Concentrated inclusion</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center bottom-text">
                    <div className="col-xl-12 mx-auto">
                      <p className="mb-0">
                        This QC parameter defines the appearance of inclusion in a stone. S means the inclusions are
                        scattered in the stone whereas C means the inclusion is concentrated. N means no or negligible
                        inclusion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-48 eye-clean">
                <div className="p-5 shadow">
                  <div className="row justify-content-between mb-4">
                    <div className>
                      <div className="col-xl-12">
                        <div className="text-center mb-4">
                          <h3>Eye Clean</h3>{" "}
                        </div>
                        <div className="table">
                          <div className="row">
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center justify-content-center">
                              <div className="t_white">Y</div>
                              <span className="t_white"> : </span>
                              <div className="t_white">Yes</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center justify-content-center">
                              <div className="t_white">N</div>
                              <span className="t_white"> : </span>
                              <div className="t_white">No</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto justify-content-center">
                              <div className="t_white">B</div>
                              <span className="t_white"> : </span>
                              <div className="t_white">Border</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center bottom-text">
                    <div className="col-xl-12 mx-auto">
                      <p className="mb-0">
                        This QC parameter defines the naked eye view of a stone. Y means Yes the stone is eye clean, N
                        means No the stone is not eye-clean &amp; B means borderline stone which is an in-between eye
                        clean &amp; no clean category.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="sepret-two" className="sepret-two">
          <div className="container">
            <div className="row justify-content-between mobile">
              <div className="col-48 milky">
                <div className="p-5 shadow">
                  <div className="row justify-content-between mb-4">
                    <div className="col-xl-12">
                      <div className="text-center mb-4">
                        <h3>Milky</h3>{" "}
                      </div>
                      <div className="table  ">
                        <div className="d-flex flex-wrap">
                          <div className="row">
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">MO</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">No Milky</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">M2</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">Medium Milky</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">M1</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">Slight Milky/Cloudy</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">M3</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">Heavy Milky</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center bottom-text">
                    <div className="col-xl-12 mx-auto">
                      <p className="mb-0">
                        This Qc parameter defines the Milky (BGM) aspect of a diamond. M0 means no milky whereas M1, M2
                        &amp; M3 indicate the intensity of milky in the stone in increasing order.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-48 tinge">
                <div className="p-5 shadow">
                  <div className="row justify-content-between mb-4">
                    <div className>
                      <div className="col-xl-12">
                        <div className="text-center mb-4">
                          <h3>Tinge</h3>{" "}
                        </div>
                        <div className="table">
                          <div className="d-flex flex-wrap">
                            <div className="row">
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">MH</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">White</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">YEL</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Yellow</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">BR</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Brown Tinge</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">M</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Mix tinge</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">GRN</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Green Tinge</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">PNK</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Pink Tinge</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto">
                                <div className="t_white">Blackish</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Blackish tinge</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center bottom-text">
                    <div className="col-xl-12 mx-auto">
                      <p className="mb-0">
                        This Qc parameter indicates the Colour Tinge(Hue) BGM aspect of a diamond. WH &amp; Yel are
                        white(D-I) &amp; yellow(J to M) whereas Br, M, Grn, Pnk, Blackish are different types of shades
                        in diamonds.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="sepret-two" className="sepret-two">
          <div className="container">
            <div className="row justify-content-between mobile">
              <div className="col-48 Intensity ">
                <div className="p-5 shadow">
                  <div className="row justify-content-between mb-4">
                    <div className="col-xl-12">
                      <div className="text-center mb-4">
                        <h3>Tinge Intensity</h3>{" "}
                      </div>
                      <div className="table  ">
                        <div className="flex-wrap">
                          <div className="row">
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">N</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">None</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">F</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">Faint</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">M</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">Medium</div>
                            </div>
                            <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                              <div className="t_white">S</div>
                              <span className="t_white"> - </span>
                              <div className="t_white">Strong</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center bottom-text">
                    <div className="col-xl-12 mx-auto">
                      <p className="mb-0">
                        This Qc parameter explains the intensity of tinge in a stone from light to dark. Faint is very
                        light tinge, the medium is slightly more tinge &amp; strong is a heavy tinge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-48 Natural">
                <div className="p-5 shadow">
                  <div className="row justify-content-between mb-3">
                    <div className>
                      <div className="col-xl-12">
                        <div className="text-center mb-4">
                          <h3>Open/Natural</h3>{" "}
                        </div>
                        <div className="table">
                          <div className="flex-wrap">
                            <div className="row">
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">N</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">None</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">VS</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Very Small</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">M</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Medium</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                                <div className="t_white">S</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Small</div>
                              </div>
                              <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto">
                                <div className="t_white">L</div>
                                <span className="t_white"> - </span>
                                <div className="t_white">Large</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center bottom-text">
                    <div className="col-xl-12 mx-auto">
                      <p className="mb-0">
                        Open &amp; naturals are surface flaws of diamonds. Naturals &amp; openings are given when
                        inclusion is open in a surface, cavity, chips, knot, etc. Open/naturals are given for table,
                        crown, girdle &amp; pavilion. N means there is NO open/natural, VS means hairline/very thin, S,
                        M, L in the increasing order as per the size of open/natural.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="graining-t" className="graining-t">
          <div className="container">
            <div className="p-5 shadow">
              <div className="row justify-content-between mb-4 mobile">
                <div className="col-49">
                  <div className="col-xl-11">
                    <div className="text-center mb-4">
                      <h3>Internal Graining</h3>{" "}
                    </div>
                    <div className="table  ">
                      <div className="flex-wrap">
                        <div className="row">
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">IG0</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">No internal</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">IG1</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Very light graining</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto">
                            <div className="t_white">IG2</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Graining</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-49 border-left">
                  <div className="col-xl-11 offset-lg-1 ">
                    <div className="text-center mb-4">
                      <h3>Surface graining</h3>{" "}
                    </div>
                    <div className="side">
                      <div className="flex-wrap">
                        <div className="row ">
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">G0</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">No surface</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center">
                            <div className="t_white">G1</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Very light graining</div>
                          </div>
                          <div className="bgcolor col-xl-6 col-lg-6 col-md-12 border-1 d-flex align-items-center mx-auto">
                            <div className="t_white">G2</div>
                            <span className="t_white"> - </span>
                            <div className="t_white">Graining</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center bottom-text">
                <div className="col-xl-10 mx-auto">
                  <p className="mb-0">
                    Graining lines inside a diamond are described in internal graining &amp; on surface are described in
                    surface graining. IG0/SG0 means there is no graining in the stone whereas IG1,IG2 or SG1 , SG2 means
                    there is graining in the increasing order.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default withRouter(GradingSystem);
