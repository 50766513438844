import React, { memo, useEffect, useState } from "react";

import { Drawer } from "antd";
import find from "lodash/find";

// import moment from 'moment';
import { isNumeric, parseDecimal } from "util/utils";

import { HeadingCalc } from "./ConfirmStonePopup";

import useRowSelect from "../../../util/useRowSelect";
import MobileViewListCard from "../../DiamondList/MobileViewListCard";
import Heading from "../Heading";

export const OfferDetailFill = React.memo((props) => {
  return (
    <div>
      {props?.getBottom?.()}
      <div className="sideBarPopupButton">
        <span className="commonButton" onClick={props.submit}>
          Save Offer
        </span>
        <span className="commonOutline" onClick={props.onClose}>
          Cancel
        </span>
      </div>
    </div>
  );
});

export const ListingCards = memo(
  ({
    getOfferDiscount,
    data,
    currentType,
    finalCalc = false,
    bidPopup = false,
    quote = false,
    quoteClick,
    checked = null,
    bid = false,
    note = false,
    watchpopup = false,
    quotePage = false,
    // matchPair = false,
  }) => {
    const { isRowChecked } = useRowSelect(currentType);
    return (
      <>
        {data.map((x, i) => {
          const isChecked = !checked ? isRowChecked(x.id) : !!find(checked, { id: x.id });
          const calcPricePerCarat = isNumeric(x.calcPricePerCarat) ? parseDecimal(x.calcPricePerCarat) : 0.0;
          const calcAmount = isNumeric(x.calcAmount) ? parseDecimal(x.calcAmount) : 0.0;

          return (
            <MobileViewListCard
              getOfferDiscount={getOfferDiscount}
              disCountVal={x.calcDiscount}
              calcPricePerCarat={calcPricePerCarat}
              calcAmount={calcAmount}
              quoteMobile
              quote={quote}
              quotePage={quotePage}
              note={note}
              watchpopup={watchpopup}
              bidPopup={bidPopup}
              bid={bid}
              finalCalc={finalCalc}
              quoteClick={quoteClick}
              data={x}
              onClick={() => {}}
              activeClass={isChecked ? "active" : ""}
              key={i}
            />
          );
        })}
      </>
    );
  }
);

const MobileOfferPopup = (props) => {
  const { toggleRowSelection, isRowChecked } = useRowSelect(props.currentType);
  const [menu, showMenu] = useState(false);
  const dep = props && props.data;
  useEffect(() => {
    dep.map((row) => {
      toggleRowSelection(row, true);
      return true;
    });
  }, [dep]);
  return (
    <>
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            {/* <Heading className="popupInnerTitle" title="Confirm Stone Send Offer "></Heading> */}
            <Heading className="popupInnerTitle" title="Send Offer " />
          </div>
          {HeadingCalc(props.data.filter((x) => isRowChecked(x.id)))}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards getOfferDiscount={props.getOfferDiscount} data={props.data} currentType={props.currentType} />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap" />
        </div>
        <div className="sideBarPopupButton">
          <span
            className="commonButton"
            onClick={() => {
              showMenu(true);
            }}
          >
            Save Offer
          </span>
          <span
            className="commonButton"
            onClick={() => {
              showMenu(false);
              props.onClose();
            }}
          >
            Cancel
          </span>
        </div>
      </div>
      {menu && (
        <Drawer
          onClose={() => showMenu(false)}
          visible={menu}
          wrapClassName="diamondListinSidebar smallSidebarMobilePopup"
          destroyOnClose
        >
          <OfferDetailFill getBottom={props.getBottom} submit={props.submit} onClose={() => showMenu(false)} />
        </Drawer>
      )}
    </>
  );
};

export default memo(MobileOfferPopup);
