import React, { useState, useEffect } from "react";

import { Drawer } from "antd";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

import IntlMessage from "util/IntlMessage";
import { useBoolean } from "util/hooks";

import { LOCAL_STORAGE_VAR } from "../../../../constants/Common";
import Heading from "../../../common/Heading";
import CompanyInformation from "../../CompanyInformation";
import CompanyCardView from "../../CompanyInformation/CompanyCardView";
import PersonalInformation from "../../PersonalInformation";
import PersonalCardView from "../../PersonalInformation/PersonalCardView";
import SocialInformation from "../../SocialInformation";
import SocialCardView from "../../SocialInformation/SocialCardView";

const KYCDetail = () => {
  const [showPersonalInfo, setShowPersonalInfo] = useBoolean();
  const [showCompanyInfo, setShowCompanyInfo] = useBoolean();
  const [showSocialInfo, setShowSocialInfo] = useBoolean();
  const [kyc, setKyc] = useState([]);

  const onClose = () => {
    setShowPersonalInfo.false();
    setShowCompanyInfo.false();
    setShowSocialInfo.false();
  };

  const PERSONAL_DOCS =
    (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
      JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_PERSONAL) ||
    [];

  const BUSINESS_DOCS =
    (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
      JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_BUSINESS) ||
    [];
  const handleKyc = (type, path, doc) => {
    const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    let kycs = !isEmpty(kyc) ? kyc : (user && user.account && user.account.kyc) || [];

    const dt = type === "personal" ? find(PERSONAL_DOCS, { id: doc }) : find(BUSINESS_DOCS, { id: doc });
    if (!isEmpty(kycs)) {
      kycs = kycs.map((item) => {
        if (item.type === type) {
          return {
            ...item,
            path: !isEmpty(path) ? path : item.path,
            docType: dt?.id[0],
          };
        }
        return item;
      });
    }

    setKyc([...kycs]);
  };

  useEffect(() => {
    const getKyc = () => {
      const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
      const kycs = !isEmpty(kyc) ? kyc : (user && user.account && user.account.kyc) || [];
      setKyc([...kycs]);
    };
    getKyc();
  }, []);

  return (
    <>
      <div className="kycDetailsMain">
        <div className="mb-50">
          <Heading title={<IntlMessage id="app.PersonalInformation" className="p-0" />} />
          <PersonalCardView personalDetails onClick={setShowPersonalInfo.true} />
        </div>
        <Heading title={<IntlMessage id="app.CompanyInformation" className="p-0" />} />
        <CompanyCardView companyDetail onClick={setShowCompanyInfo.true} />
        <Heading title={<IntlMessage id="app.socialNetworking" className="p-0" />} />
        <SocialCardView companyDetail onClick={setShowSocialInfo.true} />
      </div>
      <Drawer
        onClose={setShowPersonalInfo.false}
        visible={showPersonalInfo}
        wrapClassName="diamondListinSidebar profileSidebar"
        destroyOnClose
      >
        <PersonalInformation kyc={kyc} handleKyc={(type, path, doc) => handleKyc(type, path, doc)} onClose={onClose} />
      </Drawer>
      <Drawer
        onClose={setShowCompanyInfo.false}
        visible={showCompanyInfo}
        wrapClassName="diamondListinSidebar profileSidebar"
        destroyOnClose
      >
        <CompanyInformation kyc={kyc} handleKyc={(type, path, doc) => handleKyc(type, path, doc)} onClose={onClose} />
      </Drawer>
      <Drawer
        onClose={setShowSocialInfo.false}
        visible={showSocialInfo}
        wrapClassName="diamondListinSidebar profileSidebar"
        destroyOnClose
      >
        <SocialInformation onClose={onClose} />
      </Drawer>
    </>
  );
};
export default KYCDetail;
