import React from "react";
import { Helmet } from "react-helmet";

export const HelmetTag = ({ href, isCanonical = false, title, des = "", keywords = "" }) => {
  return (
    <>
      {isCanonical ? (
        <Helmet>{href !== "" && <link rel="canonical" href={href} />}</Helmet>
      ) : (
        <Helmet>
          {title !== "" && <title>{title}</title>}
          {des !== "" && <meta name="description" content={des} />}
          {keywords !== "" && <meta name="keywords" content={keywords} />}
        </Helmet>
      )}
    </>
  );
};
