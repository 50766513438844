import React, { Component } from "react";
import { connect } from "react-redux";

import _, { size } from "lodash";

import IntlMessage from "util/IntlMessage";

import { currentPageData } from "../../appRedux/actions/Common";
import OpenNotification from "../common/CommonButton/OpenNotification";

let selectedCarat = {};

class Carat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      From: "",
      To: "",
      showTag: false,
      tagsState: [],
      caratUI: false,
      selected: {},
      selectAll: false,
      caratSizeGroup: {},
      carats: [],
      focus: false,
    };
  }
  focusLabel = () => {
    this.setState({
      focus: !this.state.focus,
    });
  };
  componentDidMount() {
    selectedCarat = {};
    this.setState({ selected: {} });
  }
  componentDidUpdate(prevProps) {
    if (this.props.commonData && this.props.commonData.reset && this.props.commonData.reset.searchReset) {
      if (prevProps.commonData.reset.searchReset !== this.props.commonData.reset.searchReset) {
        this.setState({
          selected: {},
          tagsState: [],
          showTag: false,
          From: "",
          To: "",
        });
        selectedCarat = {};
      }
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      this.setState({
        carats: nextProps.data,
        caratSizeGroup: _.groupBy(nextProps.data, "group"),
      });
    }

    if (nextProps.commonData.modifySearch && nextProps.commonData.modifySearch["or"]) {
      if (this.props.commonData.modifySearch !== nextProps.commonData.modifySearch) {
        const or = nextProps.commonData.modifySearch["or"];
        // const modifyData = or.filter((el) => {
        //   return el.hasOwnProperty('crt');
        // });
        const modifyData = or.filter((el) => {
          return Object.prototype.hasOwnProperty.call(el, "crt");
        });
        const wholeData = _.groupBy(nextProps.data, "group");
        /**
         * simiplify and uniquify filtered carat data
         */
        let modifyArr = modifyData.map((val) => val.crt[">="] + "-" + val.crt["<="]);
        modifyArr = _.uniq(modifyArr);

        /**
         * seprate the ranges that are match our wholeData(default carats ranges)
         */
        const temp = {};
        Object.keys(wholeData).map((key) => {
          const data = wholeData[key].filter((val) => modifyArr.indexOf(val.group) !== -1);
          if (data.length) {
            temp[key] = data.map((x) => x.group);
            return true;
          } else {
            return false;
          }
        });

        /**
         * remove separated ranges from modifyArr to indentify custom ranges of carat
         */
        _.each(temp, (val, key) => {
          temp[key].map((val1) => {
            modifyArr = _.filter(modifyArr, (value) => {
              return value !== val1;
            });
            return true;
          });
        });
        selectedCarat = temp;
        // await this.setState({ selected: temp }, () => {
        //   this.props.selectedCaratFn(this.state.selected);
        // });
        this.setState({ tagsState: modifyArr, showTag: true }, () => {
          this.props.selectedCaratFn(this.state.tagsState);
        });
      }
    }
  }

  async handleAddCarat() {
    if (
      this.state.From &&
      this.state.To &&
      Number(this.state.From) <= Number(this.state.To) &&
      !_.includes(this.state.tagsState, this.state.From + "-" + this.state.To)
    ) {
      this.setState(
        (prevState) => ({
          tagsState: prevState.tagsState.concat(parseFloat(this.state.From) + "-" + parseFloat(this.state.To)),
        }),
        () => {
          this.props.selectedCaratFn(this.state.tagsState);
        }
      );
      return this.setState({
        showTag: true,
        From: "",
        To: "",
      });
    } else if (
      this.state.From &&
      this.state.To &&
      _.includes(this.state.tagsState, this.state.From + "-" + this.state.To)
    ) {
      OpenNotification({
        type: "success",
        title: "Carat range is already added.",
      });
    } else if (this.state.From && this.state.To && Number(this.state.From) > Number(this.state.To)) {
      OpenNotification({
        type: "error",
        title: "To must be greater than from.",
      });
    }
    await this.setState({ From: "", To: "" });
  }

  onTagClose(tagInput) {
    this.setState(
      (prevState) => ({
        tagsState: prevState.tagsState.filter((x) => x !== tagInput),
      }),
      () => {
        // let first = _.head(tagInput.split("-"));
        // let last = _.last(tagInput.split("-"));
        // let modifyData = {};

        // if (this.props.commonData && this.props.commonData.pageData) {
        //   modifyData = this.props.commonData.pageData.modifyData || {};
        // }
        // this.props.currentPageData({
        //   modifyData: {
        //     ...modifyData,
        //     or: this.props.objSelectedMaster.or.filter(
        //       (x) => x.crt[">="] !== first || x.crt["<="] !== last
        //     ),
        //   },
        // });

        this.props.selectedCaratFn(this.state.tagsState, tagInput);
      }
    );
  }

  handleCaratSelection = ([key, list]) => {
    const checked = size(this.state.selected[key]) < 1;
    const group = list.map((range) => range.group.trim());

    checked ? void (selectedCarat[key] = group) : delete selectedCarat[key];

    this.setState({ selected: selectedCarat }, () => {
      this.props.selectedCaratFn({ [key]: group }, { checked, key });
    });
  };

  showCaratRanges() {
    this.setState({ caratUI: !this.state.caratUI });
  }

  render() {
    const { caratSizeGroup = {} } = this.state;
    return (
      <div className="diamondSearchItem">
        <div className="diamondSearchLabel">
          <label>
            <IntlMessage id="app.carat-range" />
          </label>
        </div>
        <div className="diamondSearchDetail">
          <div className=" d-flex align-items-center flex-wrap">
            <div className="diamondSearchCaratRange">
              <div className="fromToValue">
                <div className={`fromToValueInput ${this.state.focus && "focus"}`}>
                  <label>
                    <IntlMessage id="app.from" />
                  </label>
                  <input
                    onClick={this.focusLabel}
                    type="number"
                    value={this.state.From}
                    onChange={(e) => this.setState({ From: e.target.value })}
                  />
                </div>
                <div className="fromToValueInput">
                  <label>
                    <IntlMessage id="app.to" />
                  </label>
                  <input
                    type="number"
                    value={this.state.To}
                    onBlur={() => this.handleAddCarat()}
                    onChange={(e) => this.setState({ To: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="commonButton addCaratButton" onClick={this.handleAddCarat.bind(this)}>
              <IntlMessage id="app.AddCarat" />
            </div>
            <div className="diamondSearchList caratBoxSize mt-20">
              {this.state.showTag &&
                this.state.tagsState.map((tagInput, index) => {
                  return (
                    <li key={index} onClick={this.onTagClose.bind(this, tagInput)} className="addedCarat active">
                      {tagInput}
                    </li>
                    // <Tag className="addedCarat" key={tagInput} onClose={this.onTagClose.bind(this, tagInput)} closable>
                    //   {tagInput}
                    // </Tag>
                  );
                })}
            </div>
          </div>
          <ul className="diamondSearchList caratBoxSize mt-10">
            {Object.entries(caratSizeGroup).map((carat, index) => {
              const caratKey = carat[0];
              return (
                <li
                  key={index}
                  onClick={this.handleCaratSelection.bind(this, carat)}
                  className={`${this.state.selected[caratKey] ? "active" : ""}`}
                >
                  {/* {caratKey} */}
                  {carat[1][0]["webDisplay"]}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps, { currentPageData })(Carat);
