import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";

import { Spin } from "antd";

import GridHeightContainer from "containers/GridHeightContainer/GridHeightContainer";

import UtilService from "services/util";

import IntlMessage from "util/IntlMessage";
import { sortAlphaNumPairGroup } from "util/utils";

import { GET_PAIR_DIAMONDS_LIST, CONFIRM_PAIR_DIAMONDS, USER_SETTING_LIST } from "constants/apiConstant";

import { getPath, TITLE } from "./DiamondList/DiamondListFunctions";
import OpenNotification from "./common/CommonButton/OpenNotification";
import { formatNumber } from "./common/DiamondListing/SelectStone";
import Heading from "./common/Heading";
import Pagination from "./common/Pagination";
import PairDiamondTable from "./common/PairDiamondTable";

const definedSetting = [
  // 'Stock No',
  "DNA",
  // 'Details',
  "Loc",
  "Cut",
  "Pol",
  "Sym",
  "Fls",
  "Shape",
  "Carat",
  "Col",
  "Clarity",
  " Rap($)",
  // 'Rap Value',
  " Disc%",
  "Price/Ct.",
  "Amt($)",
  "Report No",
  "Shade",
  "Milky",
];
const INITIAL_PAGE = 1;
const PAGE_INITIAL_LIMIT = 100;

const PairDiamond = () => {
  const [groupData, setGroupData] = useState([]);
  const [allPairsData, setAllPairsData] = useState([]);
  const [pairsCount, setPairsCount] = useState(0);
  const [settings, setSettings] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [pageLimit, setPageLimit] = useState(PAGE_INITIAL_LIMIT);
  const [pairSummary, setPairSummary] = useState([]);
  const [isPaginating, setIsPaginating] = useState(null);
  const [, setErr] = useState(null);

  const execFetch = useCallback(() => {
    const reqObj = {
      page,
      limit: pageLimit,
    };
    const obj = {
      // url: "/admin/pair-diamonds/paginate",
      // method: "post",
      ...GET_PAIR_DIAMONDS_LIST,
      request: reqObj,
    };
    UtilService.callApi(obj, async (err, res) => {
      if (err && err.data) {
        setErr(err.data);
      } else if (res && res.code === "OK" && res.data) {
        const sortedDataByPair = res.data.data.sort(sortAlphaNumPairGroup);
        setAllPairsData(sortedDataByPair);
        setPairsCount(res.data.pairStonesCount);
        setGroupData(res.data.groupData);
      }
    });
  }, [pageLimit, page]);

  const fetchPairDiamonds = useCallback(() => {
    execFetch();
  }, [execFetch]);

  useMemo(() => {
    if (isPaginating !== null) {
      execFetch();
    }
  }, [execFetch, isPaginating]);

  const handlePagination = async (p, pSize) => {
    setPage(p);
    setPageLimit(pSize);
    setIsPaginating(isPaginating !== true);
  };

  useEffect(() => {
    const fetchColSettings = () => {
      const obj = {
        // url: "/admin/user-setting/paginate",
        // method: "post",
        ...USER_SETTING_LIST,
      };

      UtilService.callApi(obj, async (err, data) => {
        if (err && err.data) {
          setErr(err.data);
        } else if (data && data.code === "OK") {
          if (data.data.setting) {
            const res = await data.data.setting.filter((s) => {
              return definedSetting.find((item) => {
                return item === s.title;
              });
            });
            const newSet = await res.map((item, i) => {
              const text = item.title;
              const dataField = item.field;
              const dataIndex = item.field;
              const {key} = item;
              const num = i;
              return {
                ...item,
                text,
                dataField,
                dataIndex,
                width: 100,
                key,
                ellipsis: true,
                id: item.key,
                accessor: dataField,
                Header: text,
                num,
              };
            });

            const sortByPairGroup = newSet.sort(sortAlphaNumPairGroup);
            setSettings(sortByPairGroup);
          }
        }
      });
    };

    fetchPairDiamonds();
    fetchColSettings();
  }, [fetchPairDiamonds]);

  useEffect(() => {
    async function calTotal() {
      const pairTotalSummary = Object.entries(groupData).map((d) => {
        let totalObj = {
          pairGroup: d[0],
          totalPcs: d[1].length,
        };
        let totalCrt = 0;
        let totalAmt = 0;
        d[1].forEach((i) => {
          totalAmt += i.amt;
          totalCrt += i.crt;
          totalObj = {
            ...totalObj,
            totalAmt: totalAmt.toFixed(2),
            totalCrt: totalCrt.toFixed(2),
          };
        });
        return totalObj;
      });

      if (pairTotalSummary?.length) {
        setPairSummary(pairTotalSummary);
      }
    }
    if (Object.keys(groupData)?.length > 0) calTotal();
  }, [groupData]);

  const getTitle = () => {
    const titleName = getPath().replace("-", "");
    return (
      <>
        {TITLE[titleName]} 
        {' '}
        {`( ${formatNumber(pairsCount)} )`}
      </>
    );
  };

  const handleConfirmPair = () => {
    const diamonds = selectedRows.map((r) => {
      return r.vStnId;
    });

    const reqObj = {
      diamonds,
    };

    const obj = {
      ...CONFIRM_PAIR_DIAMONDS,
      request: reqObj,
    };

    UtilService.callApi(obj, async (err, data) => {
      if (err && err.data) {
        OpenNotification({
          type: "error",
          title: err.data.message,
        });
        setSelectedRows([]);
      } else if (data && data.code === "OK") {
        OpenNotification({
          type: "success",
          title: data.message,
        });
      }
      setSelectedRows([]);
    });
  };

  return (
    <div className="searchResultListWrapper">
      <div className="selectStoneHeader">
        <div
          style={{ overflow: "auto", padding: "0px 0px 10px 15px" }}
          className="d-flex justify-content-between align-items-center width-100 listingTopBlock"
        >
          <Heading>{getTitle()}</Heading>
        </div>
      </div>
      <div className="searchInnerResult">
        <GridHeightContainer
          className="searchResultTable"
          subtractFrom=".HeaderSticky,.dashboardQuickSearch,.selectStoneHeader,.mobileSearchBottom,.diamondListingAction,.accountTabBox"
          adjustment={-10}
        >
          {allPairsData?.length && settings?.length && pairSummary?.length ? (
            <PairDiamondTable
              data={allPairsData}
              columns={settings}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              pairSummary={pairSummary}
            />
          ) : (
            <>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "inherit",
                }}
              >
                <Spin size="large" />
              </Container>
            </>
          )}
        </GridHeightContainer>
      </div>
      <div className="botoomStickyBar diamondSearchSticky">
        <div className="d-flex align-items-center">
          <button
            className="bottomStickyButton"
            onClick={() => {
              handleConfirmPair();
            }}
            disabled={selectedRows?.length === 0}
          >
            <span>
              <IntlMessage id="app.confirmStones" />
            </span>
          </button>
          <p
            className="bottomStickyButton"
            onClick={() => {
              setSelectedRows([]);
            }}
            disabled={selectedRows?.length === 0}
          >
            <span>
              <IntlMessage id="app.Reset" />
            </span>
          </p>
        </div>
        <Pagination
          count={pairsCount}
          limit={pageLimit}
          page={page}
          onPaginationChange={(p, pSize) => {
            handlePagination(p, pSize);
          }}
          dataLength={pairsCount}
          pairdData
          // noLimitChange
        />
      </div>
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(PairDiamond);
