import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tooltip, Select } from "antd";
import { DateTime } from "luxon";

import GridHeightContainer from "containers/GridHeightContainer/GridHeightContainer";

import { getState } from "components/DiamondList";
import { getStoneInfo, fetchOffer, handleTrackDelete, TITLE } from 'components/DiamondList/DiamondListFunctions'; // prettier-ignore
import MobileViewList from "components/DiamondList/MobileViewList";
import Table from "components/DiamondList/TableBack";
import DiamondListingAction from "components/common/DiamondListing/DiamondListingAction";

import { Storage } from "services/storage";

import IntlMessage from "util/IntlMessage";
import { useCompositeState, useIsMobile, usePathname } from "util/hooks";
import { prepareStoneForBargain } from "util/stone-utils";
import { formatDateTime, formatNumber, formatDecimal, classNames, formatCurrency , isArray, isEmpty, isNumber } from "util/utils";

import { TRACK_TYPES } from "constants/Common";


import deleteSvg from "assets/svg/Account/delettable.svg";
import editSvg from "assets/svg/Account/edit.svg";
import OfferList from "./OfferList";

export const OFFERSTATUS = { 1: "Pending", 2: "Accepted", 3: "Rejected" };

function Offer(props) {
  const history = useHistory();

  const [state, setState] = useCompositeState(getState());
  const [offerStatus, setOfferStatus] = React.useState(1);
  const [isMobile] = useIsMobile();

  const currentType = React.useRef(history.location.pathname);
  const tableRef = React.useRef();
  const path = usePathname();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isMobile) void props.onSumChange?.(state.sum, currentType.current);
  }, [state.sum, isMobile, props]);

  React.useEffect(() => {
    const unlisten = history.listen((location) => {
      if (history.location.pathname !== location.pathname) {
        dispatch({ type: "RESET_SELECTED_ROWS" });
        setState(getState(props));
      }
      if (history.location.search !== location.search) {
        setState(getState(props));
      }
    });

    return () => {
      unlisten();
    };
  }, [dispatch, history, props, setState]);

  const setResponse = React.useCallback(
    (res) => {
      if (!res) return setState({ loading: false, defaultChecked: [] });

      const diamondData = res.data || [];
      let defaultChecked = [];
      if (Storage.get("more", false)) {
        defaultChecked = Storage.get("compare-diamond", false) ?? [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.forEach((d) => {
            if (defaultChecked.includes(d.id)) d.isDefaultChecked = true;
          });
        }
        Storage.delete("more");
      }

      setState({
        ...res,
        data: diamondData.map((stone) => prepareStoneForBargain(stone, { noModify: true })),
        defaultChecked,
        loading: false,
      });
    },
    [setState]
  );

  const fetch = React.useCallback(() => {
    setState({ editOffer: null, loading: true, data: [] });
    fetchOffer({ page: state.page, limit: state.limit, sort: state.sort, offerStatus }, setResponse);
  }, [setState, state.page, state.limit, state.sort, offerStatus, setResponse]);

  const clearAllSelectedRows = React.useCallback(() => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  }, [dispatch]);

  const columns = React.useMemo(() => {
    const columns = [
      {
        Header: "No",
        accessor: "no",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "Stone Info",
        accessor: "stoneinfo",
        Cell: ({ row }) => getStoneInfo(row.original.original),
      },
      {
        Header: "Disc % / Price/Ct / Amt($)",
        accessor: "dispricCt",
        Cell({ row }) {
          return (
            <span>
              <span>
                {!row.original.original.isFcCol && isNumber(Number(row.original.original.back))
                  ? formatDecimal(row.original.original.back)
                  : "-"}
              </span>
              <span>&nbsp;/&nbsp;</span>
              <span>
                {isNumber(Number(row.original.original.ctPr)) ? formatDecimal(row.original.original.ctPr) : "-"}
              </span>
              <span>&nbsp;/&nbsp;</span>
              <span>
                {parseFloat(row.original.original.amt)
                  ? parseFloat(row.original.original.amt.toFixed(2)).toLocaleString("en-US")
                  : "-"}
              </span>
            </span>
          );
        },
      },
      {
        Header: "Offered % / Price/Ct",
        accessor: "quotepricect",
        Cell({ row }) {
          if (isEmpty(row.original.currentValidTrack)) return "-";

          return (
            <span>
              <strong>
                {!row.original.original.isFcCol && isNumber(Number(row.original.currentValidTrack?.trackDiscount))
                  ? formatDecimal(row.original.currentValidTrack?.trackDiscount)
                  : "-"}
              </strong>
              <span>&nbsp;/&nbsp;</span>
              <span>
                {isNumber(Number(row.original.currentValidTrack?.trackPricePerCarat))
                  ? formatDecimal(row.original.currentValidTrack?.trackPricePerCarat)
                  : "-"}
              </span>
            </span>
          );
        },
      },
      {
        Header: "Amt($)",
        accessor: "newAmount",
        Cell: ({ row }) =>
          row.original.newAmount ? parseFloat(row.original.newAmount.toFixed(2)).toLocaleString("en-US") : "",
      },
      {
        Header: "Bargain",
        accessor: "bargain",
        Cell({ row }) {
          const trackList =
            isArray(row.original.bargainTrack) && !isEmpty(row.original.bargainTrack)
              ? [row.original.defaultTrack, ...row.original.bargainTrack]
              : [row.original.defaultTrack];

          return (
            <div>
              {trackList.map((track, index) => {
                const client = [4, 10].includes(track?.userType);
                const admin = [1, 2, 8].includes(track?.userType);

                return (
                  <Tooltip key={index} title={formatDateTime(track?.updatedAt)}>
                    <span className={classNames(["offerPrice", admin && "admin", client && "client"])}>
                      {row.original.isFcCol
                        ? `${formatCurrency(track?.trackPricePerCarat ?? 0)}`
                        : `${formatDecimal(track?.trackDiscount ?? 0)}% | ${formatCurrency(
                            track?.trackPricePerCarat ?? 0
                          )} | ${formatCurrency(track?.trackAmount ?? 0)}`}
                    </span>
                    {trackList.length > index + 1 && (
                      <span className="offerPrice">
                        <br />
                      </span>
                    )}
                  </Tooltip>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: "Comment",
        accessor: "remarks",
        Cell: ({ cell }) => cell.value || "-",
      },
      {
        Header: "Active",
        accessor: "active",
        Cell({ row }) {
          return (
            <div className={classNames([`activeStatus`, [3, 4].includes(row.original.original.offerStatus) && `red`])}>
              <span>{OFFERSTATUS[row.original.original.offerStatus]}</span>
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "Action",
        Cell({ row }) {
          const { offerStatus, offerValidDate, confirmedStone } = { ...row.original, ...row.original.original };

          const diff = React.useMemo(() => {
            const endDate = DateTime.fromISO(offerValidDate);
            const currDate = DateTime.local();
            return endDate.isValid ? endDate.diff(currDate).toMillis() : undefined;
          }, [offerValidDate]);

          if (offerStatus !== 1 || confirmedStone) return null;

          return (
            <div className="actionButton">
              {diff > 0 && (
                <div className="actionAction" onClick={() => setState({ editOffer: [row.original.original] })}>
                  <img src={editSvg} alt="" />
                </div>
              )}
              <div
                className="actionAction"
                onClick={() => {
                  handleTrackDelete(TRACK_TYPES.QUOTE, [row.original.original.id], state.inTrackDiamonds, () => {
                    clearAllSelectedRows();
                    fetch();
                  });
                }}
              >
                <img src={deleteSvg} alt="" />
              </div>
            </div>
          );
        },
      },
    ]
      .filter((a) => !a.show)
      .map((c) => ({ ...c, id: c.accessor }));

    return columns;
  }, [clearAllSelectedRows, fetch, setState, state.inTrackDiamonds]);

  const onPaginationChange = React.useCallback((page, limit) => setState({ page, limit }), [setState]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  React.useEffect(() => {
    setState({ page: 1 });
  }, [offerStatus, setState]);

  const getTitle = () => `${TITLE[path]  } (${  formatNumber(state.count)  })`;

  const getAction = () => {
    return (
      <DiamondListingAction
        permKey="offer"
        trackType={TRACK_TYPES.QUOTE}
        {...state}
        {...props}
        currentType={currentType.current}
        acceptBtn
        delete
        noquote
        modify
        nofinalcalc
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        offerStatus={offerStatus}
      />
    );
  };
  if (isMobile)
    return (
      <OfferList
        clearAllSelectedRows={clearAllSelectedRows}
        editOffer={state.editOffer}
        setState={setState}
        {...props}
      />
    );

  if (isMobile)
    return <MobileViewList listView {...props} {...state} currentType={currentType} parent={{ getTitle, getAction }} />;

  return (
    <div className="searchResultListWrapper profilePage">
      <div className="searchInnerResult">
        <div className="d-flex j-space-between filterTopMainBlock align-items-center">
          <div className="searchInnerFilterMain">
            <Select
              className="updateStausBlock md-sm-size"
              placeholder="Please select"
              value={offerStatus}
              onChange={setOfferStatus}
              style={{ width: "100%" }}
            >
              <Select.Option value={1}>
                <IntlMessage id="app.pending" />
              </Select.Option>
              <Select.Option value={2}>
                <IntlMessage id="app.approved" />
              </Select.Option>
              <Select.Option value={3}>
                <IntlMessage id="app.rejected" />
              </Select.Option>
            </Select>
          </div>
        </div>
        <GridHeightContainer
          className="searchResultTable profilePageTable"
          subtractFrom=".HeaderSticky,.accountTopBlock,.accountTabBox,.filterTopMainBlock,.diamondListingAction,.mobileSearchBottom"
          adjustment={-10}
        >
          <Table
            data={state.data}
            columns={columns}
            loading={state.loading}
            ref={tableRef}
            nostatus
            canSort={false}
            currentType={currentType.current}
            defaultChecked={state.defaultChecked}
            FilterOption={false}
          />
        </GridHeightContainer>
        <DiamondListingAction
          permKey="offer"
          trackType={TRACK_TYPES.QUOTE}
          {...state}
          {...props}
          currentType={currentType.current}
          acceptBtn
          delete
          noquote
          modify
          nofinalcalc
          onPaginationChange={onPaginationChange}
          clearAll={clearAllSelectedRows}
          fetch={fetch}
          offerStatus={offerStatus}
        />
      </div>
    </div>
  );
}

export default Offer;
