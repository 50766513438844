import React from "react";

const TextArea = (props) => {
  return (
    <div className={`commonTextArea from-group ${props.className}`}>
      <label>{props.label}</label>
      <textarea value={props.value} onChange={props.onChange} placeholder={props.placeholder} />
    </div>
  );
};
export default TextArea;
