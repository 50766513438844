import React, { useState } from "react";

import { trackTypeObj } from "../../constants/Common";
import { handleInsertTrack } from "../DiamondList/DiamondListFunctions";

const EnquiryDetail = (props) => {
  const [remark, setRemark] = useState("");

  return (
    <div className="diamondDetailRightPopup mt-10" style={props.style}>
      <h2 className="popupInnerTitle">Place Enquiry</h2>
      <div className="commonTextArea from-group">
        <label>Remarks</label>
        <textarea onChange={(e) => setRemark(e.target.value)} />
      </div>
      <div className="popupNote">
        <span>Please Note :</span>
        <p>
          Shipping charges have been revised, For further details, 
          {' '}
          <span>Click Here.</span>
        </p>
      </div>
      <div className="sideBarPopupButton">
        <span
          className="commonButton"
          onClick={() =>
            handleInsertTrack(
              trackTypeObj.ENQUIRY,
              props.checked,
              () => {
                props.onClose();
              },
              "",
              remark
            )
          }
        >
          Submit
        </span>
        <span className="commonButton" onClick={() => props.onCancel()}>
          Cancel
        </span>
      </div>
    </div>
  );
};
export default EnquiryDetail;
