import React, { useState } from "react";
import { Container, Row, Col, Offcanvas, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { FiSearch } from "react-icons/fi";
import { VscMenu } from "react-icons/vsc";

import LangDropdown from "components/Header/LangDropdown";

import IntlMessage from "util/IntlMessage";
import { isEmpty, titleCase } from "util/utils";

import userSVG from "assets/svg/Header/user.svg";
import KgkLogo from "../../assets/img/landing-page/logo.png";

import "../Static/StaticHeader/staticHeader.less";

const LandingHeader = () => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const searchClose = () => setSearch(false);
  const handleSearch = () => setSearch(true);
  const user = useSelector((state) => state.auth.authUser);

  return (
    <>
      <div className="header-d">
        <Container>
          <Row>
            <Col>
              <div className="kgk_header_content_main">
                <div className="kgk_header_logo">
                  <NavLink to="/">
                    <img src={KgkLogo} alt="logo" style={{ height: "80px" }} />
                  </NavLink>
                </div>
                <div className="kgk_header_menu_main staticHeaderWrapper">
                  <ul className="staticHeaderRight">
                    <li className="m_d_none">
                      <LangDropdown />
                    </li>
                    <li>
                      <img
                        className="defulticon"
                        style={{ marginRight: 0, marginleft: 10, width: "30px !important" }}
                        src={userSVG}
                        alt="icon"
                      />
                      <NavLink className="headerUserName" to="/signin">
                        {isEmpty(user) ? "Login / Register" : titleCase(user?.name)}
                      </NavLink>
                    </li>

                    <li>
                      <span onClick={handleSearch}>
                        <FiSearch />
                      </span>
                    </li>
                    <li>
                      <span onClick={handleShow}>
                        <VscMenu />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/*  Menu Drawer  */}
      <Offcanvas className="kgk_landing_menu_drawer" placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="kgk_header_logo">
              <div className="kgk_header_drawer_logo">
                <NavLink to="/">
                  {" "}
                  <img src={KgkLogo} alt="logo" />
                  {" "}
                </NavLink>
              </div>
              <div className="m_show ">
                <LangDropdown />
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="kgk_drawer_meni_list">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <NavLink to="/">
                  <IntlMessage id="app.home" />
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink to="/about-us">
                  <IntlMessage id="app.AboutUs" />
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink to="/contact-us">
                  <IntlMessage id="app.ContactUs" />
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink to="/privacy-policy">
                  <IntlMessage id="app.privacyPolicy" />
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink to="/terms-condition">
                  <IntlMessage id="app.signup.temsCondition" />
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink to="/news">
                  <IntlMessage id="app.Home.NewsNEvent" />
                </NavLink>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/*  Search */}
      <Offcanvas className="kgk_landing_Search" placement="top" show={search} onHide={searchClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="kgk_Search_header" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="kgk_search_main">
            <input type="text" placeholder="Search" />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default withRouter(LandingHeader);
