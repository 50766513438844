import React, { Component } from "react";
import { connect } from "react-redux";

import clone from "lodash/clone";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import IntlMessage from "util/IntlMessage";

import { HeadingCalc } from "./ConfirmStonePopup";
import NotePopupMobile from "./NotePopupMobile";

import { trackTypeObj } from "../../../constants/Common";
import { getColumn, handleNoteUpsert, isMobile } from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import OpenNotification from "../CommonButton/OpenNotification";
import Heading from "../Heading";

const currentType = "NotePopup";

class NotePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      checked: [],
      note: "",
    };
  }

  allNoteChange = (value) => {
    this.ipValChange(value);
    this.setState({ note: value });
  };

  componentDidMount() {
    const track = this.props.inTrackDiamonds ? this.props.inTrackDiamonds : [];
    let notes = [];
    if (find(track, { _id: trackTypeObj.COMMENT })) notes = find(track, { _id: trackTypeObj.COMMENT }).diamonds;
    const data = this.props.checked.map((x) => ({
      ...x,
      note: find(notes, { id: x.id }) ? find(notes, { id: x.id }).remarks : "",
    }));
    const columns = [
      {
        Header: ({ props }) => (
          <div {...props}>
            <span>Notes</span>
            <span className="tableInput noteAdd">
              <input onChange={(e) => this.allNoteChange(e.target.value)} value={this.state.note} />
            </span>
          </div>
        ),
        Cell: ({ row }) => (
          <span className="tableInput noteAdd">
            <input onChange={(e) => this.ipValChange(e.target.value, row.original.id)} value={row.original.note} />
          </span>
        ),
        id: "note",
        accessor: "note",
      },
      ...getColumn(),
    ];
    this.setState({ columns, data, checked: data });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length !== this.state.checked.length) {
      this.setState({ checked: nextProps.checkedData });
    }
  }

  ipValChange = (value, ide) => {
    const val = clone(value);
    let line = clone(this.state.data);
    if (ide) {
      const ind = findIndex(line, { id: ide });
      line[ind].note = val;
    } else line = line.map((l) => ({ ...l, note: val }));
    this.setState({ data: line }, () => {
      const checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ checked });
    });
  };

  checkCheck = () => {
    if (!this.state.checked.length) {
      OpenNotification({
        type: "error",
        title: "Please select stone(s) to add note.",
      });
      return false;
    } else return true;
  };

  submit = () => {
    const self = this;
    if (!this.checkCheck()) return;
    if (this.state.checked.filter((x) => x.note.trim() === "").length) {
      OpenNotification({
        type: "error",
        title: "Please enter note.",
      });
      return;
    }
    handleNoteUpsert(this.state.checked, (flag) => {
      if (flag) {
        self.props.clearAll();
        self.props.setCount(!self.props.deleteReminder.count);
      }
      self.props.throwNotifyCount(self.props.notifyCount + 1);
      self.props.onClose();
    });
  };

  render() {
    return isMobile() ? (
      <NotePopupMobile {...this.state} onClose={this.props.onClose} parent={this} />
    ) : (
      <div>
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
          <Heading className="popupInnerTitle mr-40" title={<IntlMessage id="app.addNote" />}></Heading>
          {HeadingCalc(this.state.checked)}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable notePopupScroll">
            <Table
              {...this.state}
              areAllChecked={true}
              currentType={currentType}
              FilterOption={false}
              canSort={false}
              nodots
              noGrp
            />
          </div>
          <div className="sideBarPopupButton">
            <span className="commonButton" onClick={this.submit}>
              <IntlMessage id="app.addNote" />
            </span>
            <span className="commonButton" onClick={this.props.onClose}>
              <IntlMessage id="app.Cancel" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData.selectedRows[currentType] || [] };
};

export default connect(mapStateToProps, null)(NotePopup);
