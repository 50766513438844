import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import IntlMessage from "util/IntlMessage";

import { NOTIFY_REDIRECT, NOTIFY_STATUS } from "../../../constants/Common";
import { GET_NOTIFICATIONS, MARK_AS_READ } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
// import { isMobile } from '../../DiamondList/DiamondListFunctions';
import Loader from "../Loader";

const NotificationItem = (props) => {
  return (
    <>
      <div className="notificationTitle">{props.status && props.status}</div>

      <div onClick={props.onClick} className={`notificationDropdownDetail ${!props.read ? "" : "readMessage"}`}>
        <h2>{props.title}</h2>
        {props.pcv && (
          <div className="d-flex flex-wrap basicDetail">
            <div className="detail">
              <span className="mr-5">{props.pcv}</span>
            </div>
            {/* <div className="detail"><span className="mr-5">Carat : </span><span>0.10</span></div>
                    <div className="detail"><span>Value : </span><span>$1,000,00</span></div> */}
          </div>
        )}
        <span>{props.time}</span>
      </div>
    </>
  );
};

const NotificationDropdown = (props) => {
  const [notifyData, setNotifyData] = useState([]);
  const [tempFalg, setTempFalg] = useState(true);
  const [load, setLoad] = useState(false);
  // const [total, setTotal] = useState(0);

  useEffect(() => {
    if (tempFalg) {
      const getNotify = () => {
        setLoad(true);
        const obj = {
          ...GET_NOTIFICATIONS,
          request: {
            page: 1,
            limit: 10,
          },
        };
        UtilService.callApi(obj, (err, data) => {
          setLoad(false);
          if (err) {
            setNotifyData([]);
          } else if (data && data.code === "OK") {
            if (data.data && data.data.list && data.data.list.length) {
              // setTotal(data.data.unReadCount);
              setNotifyData([...data.data.list]);
              props.getUpdatedCount(data.data.unReadCount);
              props.throwNotifyCount(data.data.unReadCount);
            }
          } else {
            setNotifyData([]);
          }
        });
      };
      getNotify();
      setTempFalg(false);
    }
  }, [tempFalg]);

  const readNotify = (id) => {
    props.handleOut();

    const obj = {
      ...MARK_AS_READ,
      request: {
        id,
      },
    };
    UtilService.callApi(obj);
  };
  // const readAll = () => {
  //   isMobile() ? props.history.push('/dashboard') : props.handleOut();

  //   const obj = {
  //     ...MARK_AS_READ,
  //     request: {},
  //   };
  //   UtilService.callApi(obj);
  // };
  const redirectionNotify = (activity) => {
    switch (activity) {
      case NOTIFY_REDIRECT.OFFER:
        props.history.push("/account/offer-list");

        break;
      case NOTIFY_REDIRECT.CART:
        props.history.push("/account/cart");

        break;
      case NOTIFY_REDIRECT.WATCHLIST:
        props.history.push("/account/watchlist");
        break;
      case NOTIFY_REDIRECT.ORDER:
        props.history.push("/account/order-list");

        break;
      case NOTIFY_REDIRECT.ENQUIRY:
        props.history.push("/account/enquiry");

        break;
      case NOTIFY_REDIRECT.OFFICE:
        props.history.push("/account/view-diamonds");

        break;
      case NOTIFY_REDIRECT.COMMENT:
        props.history.push("/account/notes");

        break;

      default:
        props.history.push("/account/cart");

        break;
    }
  };
  const getDateShow = (actualDates) => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    let months;

    let dateDisplay = {
      hours: "",
      status: "",
    };
    // let today = new Date()
    const todays = new Date(actualDates);
    const oneDayLess = new Date(new Date().setDate(new Date().getDate() - 2));
    const timediff = new Date() - todays;
    const todayDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    const oneLess = new Date(oneDayLess.getFullYear(), oneDayLess.getMonth(), oneDayLess.getDate());

    const dynamicDate = new Date(todays.getFullYear(), todays.getMonth(), todays.getDate());
    if (todayDate <= dynamicDate) {
      const hours = Math.floor(timediff / hour);
      if (hours < 1) {
        const m = Math.floor(timediff / minute);
        const disMinute = `${m}`.replace("-", "");

        dateDisplay = {
          hours: `${disMinute} minutes ago`,
          status: NOTIFY_STATUS.TODAY,
        };
      } else {
        const disHours = `${hours}`.replace("-", "");

        dateDisplay = {
          hours: `${disHours} hours ago`,
          status: NOTIFY_STATUS.TODAY,
        };
      }
    } else if (oneLess <= dynamicDate) {
      const hours = Math.floor(timediff / hour);
      const disHour = `${hours}`.replace("-", "");

      dateDisplay = {
        hours: `${disHour} hours ago`,
        status: NOTIFY_STATUS.YESTERDAY,
      };
    } else {
      const daysCount = Math.floor(timediff / day);
      if (daysCount > 30) {
        months = (todays.getFullYear() - new Date().getFullYear()) * 12;
        months -= new Date().getMonth() + 1;
        months += todays.getMonth();
        const dis = `${months}`.replace("-", "");
        dateDisplay = {
          hours: `${dis} months ago`,
          status: NOTIFY_STATUS.PAST,
        };
      } else {
        const disDay = `${daysCount}`.replace("-", "");
        dateDisplay = {
          hours: `${disDay} days ago`,
          status: NOTIFY_STATUS.PAST,
        };
      }
    }
    return dateDisplay;
  };

  let tempFlagToday;
  let tempFlagYesterday;
  let tempFlagPast;
  if (load) return <Loader />;
  return (
    <div className="notificationDropdown">
      <div className="notificationItem">
        {/* {total && total !== 0 ? (
          <h3>
            <button
              onClick={() => {
                readAll();
              }}
              style={{ float: 'right', padding: '3px' }}
            >
              <IntlMessage id="app.markallread" />
            </button>
          </h3>
        ) : (
          ''
        )} */}
        <div className="notificationInner">
          {notifyData && notifyData.length !== 0
            ? notifyData.map((not, ind) => {
                const display = getDateShow(not.createdAt);
                let tempStatus = "";
                if (display && display.status === NOTIFY_STATUS.TODAY) {
                  if (!tempFlagToday) {
                    tempFlagToday = true;
                    tempStatus = NOTIFY_STATUS.TODAY;
                  } else {
                    tempStatus = "";
                  }
                } else if (display && display.status === NOTIFY_STATUS.YESTERDAY) {
                  if (!tempFlagYesterday) {
                    tempFlagYesterday = true;
                    tempStatus = NOTIFY_STATUS.YESTERDAY;
                  } else {
                    tempStatus = "";
                  }
                } else if (!tempFlagPast) {
                    tempFlagPast = true;
                    tempStatus = NOTIFY_STATUS.PAST;
                  } else {
                    tempStatus = "";
                  }
                return (
                  <NotificationItem
                    onClick={() => {
                      redirectionNotify(not.activityType);
                      readNotify(not.id);
                    }}
                    pcv={not.message}
                    status={tempStatus}
                    read={not.isRead}
                    title={not.title}
                    time={display && display.hours ? display.hours : "--"}
                    key={ind}
                  />
                );
              })
            : !load && "No Notification Found"}
          <a href="/notifications" className="viewLink">
            <IntlMessage id="app.ViewAllNotifications" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default withRouter(NotificationDropdown);
