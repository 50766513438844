export const FILTER_MASTER_MAP = {
  cut: "CUT",
  pol: "POLISH",
  sym: "SYMMETRY",
  flu: "FLUORESCENCE",
  shd: "SHADE",
  eCln: "EYECLEAN",
  mlk: "MILKEY",
  mixTint: "MIX_TINT",
};

export const COMPOSITE_FILTER_TYPES = {
  BROWN: "brown",
  EYE_CLN: "eyeCln",
  MIX_TINT: "mixTint",
  MLK: "mlk",
  NO_BGM: "noBGM",
  THREE_EX: "threeEX",
  THREE_VG: "threeVG",
  THREE_VG_PLUS: "threeVGPlus",
};

export const COMPOSITE_FILTERS = {
  brown: { shd: ["BR1", "BR2", "BR3"] },
  eyeCln: { eCln: ["BORDERLINE", "E1", "YES"] },
  mixTint: { mixTint: ["MT1", "MT2", "MT3"] },
  mlk: { mlk: ["ML0", "ML1", "ML2", "ML3"] },
  noBGM: { mixTint: ["NONE"], mlk: ["NONE"], shd: ["NONE", "WH", "YL"] },
  threeEX: { cut: ["EX"], pol: ["EX"], sym: ["EX"] },
  threeVG: { cut: ["VG"], pol: ["EX", "G", "VG"], sym: ["EX", "G", "VG"] },
  threeVGPlus: { cut: ["EX", "VG"], pol: ["EX", "VG"], sym: ["EX", "VG"] },
};

export const TECHNICAL_FILTERS = ["SHADE", "H_AND_A", "LUSTER", "GRAINING", "EYECLEAN"];

export const INCLUSION_FILTERS = [
  "WHITE_INCLUSION_TABLE",
  "WHITE_INCLUSION_SIDE",
  "BLACK_INCLUSION_TABLE",
  "BLACK_INCLUSION_SIDE",
  "OPEN_INCLUSION_TABLE",
  "OPEN_INCLUSION_PAVILION",
  "EXTRA_FACET_PAVILION",
  "EXTRA_FACET_CROWN",
];
