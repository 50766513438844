import React, { useEffect, memo } from "react";

import { HeadingCalc } from "./ConfirmStonePopup";
import { ListingCards } from "./MobileComfirmStone";

import useRowSelect from "../../../util/useRowSelect";
import Heading from "../Heading";

const FinalCalcMobile = (props) => {
  const { toggleRowSelection } = useRowSelect(props.currentType);
  useEffect(() => {
    props.data.map((row) => {
      toggleRowSelection(row, true);
      return true;
    });
  }, [props.data, toggleRowSelection]);

  return (
    <div className="offerWrapper">
      <div className="d-flex align-items-center justify-space-between offerTopBlock">
        <div className="d-flex align-items-center">
          <Heading className="popupInnerTitle" title="Final Calculation" />
        </div>
        {HeadingCalc({ display: "flex", flexDirection: "row-reverse", width: "48%" }, props.VALUES)}
      </div>
      <div className="searchInnerResult tabInnerTable">
        <div className="diamondListMobile mobileOfferScroll">
          <ListingCards data={props.data} finalCalc currentType={props.currentType} />
        </div>
        <div className="d-flex justify-content-between offerBottomBox flex-wrap" />
      </div>
      <div className="sideBarPopupButton">
        <span className="commonButton" onClick={props.parent.submit}>
          Confirm Stone
        </span>
        <span className="commonButton" onClick={props.parent.onClose}>
          Cancel Stone
        </span>
      </div>
    </div>
  );
};

export default memo(FinalCalcMobile);
