import React from "react";

import urlJoin from "url-join";

import { Checkbox } from "antd";
import { memoize } from "lodash";

// import { MediaService } from 'services/MediaService';
import { callingApi } from "services/util";

import IntlMessage from "util/IntlMessage";
import { useIsMobile } from "util/hooks";
import { notify } from "util/notify";
import { classNames, isArray, isEmpty, isNotEmpty } from "util/utils";

import { BASE_DOWNLOAD_URL } from "constants/Common";
import { DOWNLOAD_ZIP } from "constants/apiConstant";

import { downloadZip } from "../../DiamondList/DiamondListFunctions";
import Heading from "../Heading";

const optionsList = [
  // { id: 0, label: 'Excel', field: 'isExcel' },
  { id: 1, label: "Main Image", field: "img" },
  // { id: 3, label: 'Front', field: 'frontFile' },
  // { id: 2, label: 'Side', field: 'sideFile' },
  { id: 4, label: "Video", field: "mp4Video" },
  { id: 6, label: "Certificate", field: "certFile" },
  { id: 5, label: "Plot", field: "pltFile" },
  { id: 7, label: "Prop", field: "prpFile" },
];

const ListOption = React.memo((props) => {
  const [isMobile] = useIsMobile();

  const { label, checked, onClick, ...restProps } = props;

  return (
    <li onClick={!isMobile ? onClick : undefined} {...restProps}>
      <div className="dropdownBottomItem">
        {isMobile ? (
          <div className="dropdownIconCheckBox" onClick={isMobile ? onClick : undefined}>
            <Checkbox checked={checked}>{label}</Checkbox>
          </div>
        ) : (
          <span className="shareOptionLabel">{label}</span>
        )}
      </div>
    </li>
  );
});

function DownloadPopup(props) {
  const [selectedOptions, setSelectedOptionsList] = React.useState([]);
  const [isMobile] = useIsMobile();

  const { rows, filter } = props;
  const { clearAll, onClose } = props;

  const selectedRows = React.useMemo(() => (isArray(rows) ? rows : []), [rows]);

  React.useEffect(() => {
    if (!selectedRows) notify.error({ message: "No stone(stones) to download." });
  }, [selectedRows]);

  const toggleOptionsRet = React.useMemo(() => {
    return memoize((field) => () => {
      setSelectedOptionsList((list) => {
        const indexOfField = list.findIndex((item) => item === field);
        indexOfField < 0 ? list.push(field) : list.splice(indexOfField, 1);
        return [...list].sort();
      });
    });
  }, []);

  const downloadFiles = React.useCallback(async () => {
    if (isEmpty(selectedOptions)) return notify.error({ message: "Please select type to download." });

    const vStnIdList = rows.map((record) => record?.vStnId).filter(isNotEmpty);


    if (vStnIdList.length > 5) {
      notify.error({ message: "Please ensure that you select a maximum of 5 stones.." })
      props.onClose()
      return;
    }

    const selectedfields = (() => {
      const entries = optionsList.map((opt) => [opt?.field, selectedOptions.includes(opt?.field)]);
      const output = Object.fromEntries(entries);
      output.faceUp = false;
      return output;
    })();
    let fileCount = vStnIdList.length
    fileCount = selectedOptions.length ? fileCount * selectedOptions.length : fileCount

    if (fileCount > 10) {
      notify.error({ message: `Please make sure to carefully choose no more than 10 files. The current count is ${vStnIdList.length} * ${selectedOptions.length} = ${fileCount}. ` })
      props.onClose()
      return;
    }
    if (selectedOptions.length >= 2 || vStnIdList.length >= 2) {
      let obj = { zip: true };
      obj = {
        ...obj,
        ...selectedfields,
        filter: isEmpty(vStnIdList) ? { ...filter, wSts: ["A", "M", "H"] } : { vStnId: vStnIdList },
      };
      downloadZip(obj, () => {
        props.onCloseDownloadPopup();
      });
    } else {
      const _request = {
        ...DOWNLOAD_ZIP,
        request: {
          ...selectedfields,
          filter: isEmpty(vStnIdList) ? { ...filter, wSts: ["A", "M", "H"] } : { vStnId: vStnIdList },
        },
      };



      const [, res] = await callingApi(_request);
      if (res?.code !== "OK") return notify.error({ message: "File(s) Not Found." });
      notify.success({
        message: "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
      });

      await new Promise((r) => setTimeout(r, 2000));

      if (isArray(res.data)) {
        // res.data.forEach((url) => MediaService.downloadFile(`${BASE_DOWNLOAD_URL}${url}`));
        // res.data.forEach((url) => MediaService.downloadFile(urlJoin(BASE_DOWNLOAD_URL, url)));
        res.data.forEach((url) => window.open(urlJoin(BASE_DOWNLOAD_URL, url), "_blank"));
      }
    }
    void (onClose?.(), clearAll?.());
  }, [selectedOptions, rows, filter, selectedRows, onClose, clearAll]);

  return (
    <>
      <div>
        {isMobile && <Heading title="Download Stone" className="p-0" />}

        <div className={classNames([!isMobile && "searchPopupCommon"])}>
          <div className={classNames([isMobile ? "shareStoneMobileItem" : "shareOptionSet d-flex"])}>
            <div className={classNames([isMobile ? "mobileShareOption" : "shareOptionSetItem"])}>
              <ul>
                {optionsList.map((option) => {
                  const isActive = selectedOptions.includes(option?.field);

                  return (
                    <ListOption
                      key={option?.field}
                      onClick={toggleOptionsRet(option?.field)}
                      className={classNames([isActive && "active"])}
                      label={option?.label}
                      checked={isActive}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={classNames([isMobile ? "sideBarPopupButton" : "commonModalButton mt-20"])}>
            {isEmpty(selectedRows) ? (
              <button className={classNames([isMobile ? "commonButton" : "fillButton"])}>Please select diamonds</button>
            ) : (
              // ) : selectedOptions?.length < 1 && !selectedOptions.includes('isExcel') ? (
              //   <button className={classNames([isMobile ? 'commonButton' : 'fillButton'])}>
              //     Please select options
              //   </button>
              // )
              <button onClick={downloadFiles} className={classNames([isMobile ? "commonButton" : "fillButton"])}>
                <IntlMessage id="app.Download" />
              </button>
            )}
            <button onClick={onClose} className={classNames([isMobile ? "commonOutline" : "outLineButton ml-5"])}>
              <IntlMessage id="app.Cancel" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(DownloadPopup);
