import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { memoize } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import CellInput from "components/CellInput";

import { BidService } from "services/BidService";

import IntlMessage from "util/IntlMessage";
import { roundBidDiscount } from "util/_extra-utils";
import { withCurrentType } from "util/hocs";
import { isArray, isNumber, isNumeric } from "util/utils";

import BIDStoneMobile from "./BIDStoneMobile";
import { newDiamondPrice } from "./FinalCalculations";
import { calculate, formatNumber } from "./SelectStone";

import { getColumn, handleBidUpsert, isMobile } from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import OpenNotification from "../CommonButton/OpenNotification";
import Heading from "../Heading";

class BidPopup extends Component {
  state = {
    columns: [],
    checked: [],
    data: [],
    nextBidData: {},
  };

  componentDidMount() {
    const data = this.props.checked
      .map((x) => ({
        ...x,
        bidPricePerCarat: x.bidPricePerCarat ? x.bidPricePerCarat : x.ctPr,
        bidAmount: x.bidAmount ? x.bidAmount : x.amt,
        bidDiscount: x.bidDiscount ? x.bidDiscount : x.back,
        baseDiscount: x.bidDiscount ? x.bidDiscount : x.back,
      }))
      .map((x) => newDiamondPrice(x, "bid"))
      .map((record) => ({ ...record, _original: { ...record } }));
    const Columns = getColumn();
    let index = findIndex(Columns, { id: "rap" });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1).filter((el) => !["back", "amt", "ctPr"].includes(el.id)),
      find(Columns, { id: "back" }),
      find(Columns, { id: "ctPr" }),
      find(Columns, { id: "amt" }),
      {
        Header: "Bid Disc(%)",
        accessor: "bidDiscount",
        id: "bidDiscount",
        Cell: ({ row }) => {
          const diffInterval = isNumeric(this.state.nextBidData?.sub_types?.diffInterval, true)
            ? Number(this.state.nextBidData?.sub_types?.diffInterval)
            : 0.5;
          const max = this.state.nextBidData?.sub_types?.upwardInterval + row.original.back;
          const min = row.original.back - this.state.nextBidData?.sub_types?.downwardInterval;
          return (
            <div className="tableInput">
              <CellInput
                diffInterval={diffInterval}
                minInterval={min}
                maxInterval={max}
                style={{ paddingLeft: Number(row.original.bidDiscount) > 0 ? "8px" : 0, width: "70%" }}
                onChange={this.handleDiscountInput(row.original.stoneId)}
                transformInput={roundBidDiscount}
                value={row.original.bidDiscount}
                baseDiscount={row.original.baseDiscount}
                showIncrButton
                showDecrButton
                stoneId={row.original.stoneId}
                data={this.state.data}
              />
            </div>
          );
        },
      },
      {
        Header: "Bid Price/Ct",
        accessor: "bidPricePerCarat",
        id: "bidPricePerCarat",
        width: 230,
        Cell: ({ row }) => this.getBidPrCell(row.original),
      },
      {
        Header: "Bid Value",
        accessor: "bidAmount",
        id: "bidAmount",
        Cell: ({ row }) => (
          <div className="tableInput">
            <input value={formatNumber(parseFloat(row.original.bidAmount || 0).toFixed(2))} disabled />
          </div>
        ),
      },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !["back", "amt", "ctPr"].includes(el.id)),
    ].filter(Boolean);
    this.getNextBidConfig();
    this.setState({ columns, data, oldData: data, checked: data });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedRows[this.props.currentType] !== this.props.selectedRows[this.props.currentType]) {
      this.setState({ checked: this.props.selectedRows[this.props.currentType] });
    }

    if (isArray(this.state.data) && prevState.data !== this.state.data) {
      const checked = isArray(this.state.checked)
        ? this.state.data.filter((record) => this.state.checked.find((_record) => _record.id === record.id))
        : [];
      this.setState({ checked });
    }
  }

  getNewBid = (stoneId, minus) => {
    const data = cloneDeep(this.state.data);

    const index = findIndex(data, { stoneId });

    const val = minus ? data[index].bidPricePerCarat - 20 : data[index].bidPricePerCarat + 20;
    if (val < data[index].ctPr && minus) return;
    data[index].bidPricePerCarat = val;
    data[index].bidAmount = (val || 0) * data[index].crt;
    const sum = calculate([{ ...data[index], ctPr: val, amt: data[index].bidAmount }]);
    data[index].bidDiscount = sum.final_discount;
    data[index] = newDiamondPrice(data[index], "bid");
    this.setState({ data }, () => {
      const checked = this.state.data.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ checked });
    });
  };

  bidHeading = () => {
    const _isMobile = isMobile();
    if (_isMobile && !this.state.data?.length) return null;
    const sum = calculate(this.state.data);
    const obj = {
      "CT. :": parseFloat(sum.total_carat).toFixed(2),
      "Disc% :": parseFloat(sum.final_discount).toFixed(2),
      "Price/Ct :": formatNumber(parseFloat(sum.final_price).toFixed(2)),
      "Total Value :": formatNumber(parseFloat(sum.final_value).toFixed(2)),
      "Bid Disc :": formatNumber(parseFloat(sum.bidFinalDisc).toFixed(2)),
      "Bid PR/CT :": formatNumber(parseFloat(sum.bidFinalPr).toFixed(2)),
      "Bid Amount :": formatNumber(parseFloat(sum.bidFinalAmt).toFixed(2)),
    };

    return (
      <div className="DiamondDetailPopup">
        {Object.entries(obj).map(([key, value]) => {
          const label = _isMobile ? value : key;
          const content = _isMobile ? key.slice(0, key.length - 1) : value;

          return (
            <div className="DiamondDetailPopupItem" key={key}>
              <span>{label}</span>
              <span>{content}</span>
            </div>
          );
        })}
      </div>
    );
  };

  getBidPrCell = (row) => {
    return (
      <div className="tableInput">
        <input value={formatNumber(parseFloat(row.bidPricePerCarat).toFixed(2))} disabled />
      </div>
    );
  };

  getBidDiscCell = (row) => {
    const diffInterval = isNumeric(this.state.nextBidData?.sub_types?.diffInterval, true)
      ? Number(this.state.nextBidData?.sub_types?.diffInterval)
      : 0.5;
    return (
      <div className="tableInput">
        <CellInput
          diffInterval={diffInterval}
          style={{ paddingLeft: Number(row.bidDiscount) > 0 ? "5px" : 0, width: "50%" }}
          onChange={this.handleDiscountInput(row.stoneId)}
          transformInput={roundBidDiscount}
          value={row.bidDiscount}
          showIncrButton
          showDecrButton
        />
      </div>
    );
  };

  getBidAmountCell = (row) => {
    return (
      <div className="tableInput">
        <input value={row.bidAmount.toFixed(2)} disabled />
      </div>
    );
  };
  handleDiscountChange = (value, row) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    data[index].bidDiscount = value;
    data[index].bidPricePerCarat = parseFloat((1 + value / 100) * data[index].rap).toFixed(2);
    data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleDiscountInput = memoize((stoneId) => (value) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId });
    data[index] = {
      ...data[index],
      bidDiscount: isNumeric(data[index]?._original?.bidDiscount, true)
        ? Number(data[index]?._original?.bidDiscount)
        : data[index]?._original?.bidDiscount,
      bidPricePerCarat: isNumeric(data[index]?._original?.bidPricePerCarat, true)
        ? Number(data[index]?._original?.bidPricePerCarat)
        : data[index]?._original?.bidPricePerCarat,
      bidAmount: isNumeric(data[index]?._original?.bidAmount, true) ? Number(data[index]?._original?.bidAmount) : 0,
    };

    const max = this.state.nextBidData?.sub_types?.upwardInterval + data[index].back;
    const min = data[index].back - this.state.nextBidData?.sub_types?.downwardInterval;
    value = Number(value);
    if (!isNumber(value)) {
      OpenNotification({ type: "error", title: "Not a Number" });
    } else if (value > max) {
      OpenNotification({ type: "error", title: "Discount can not be Increased." });
    } else if (value < min) {
      OpenNotification({ type: "error", title: "Discount can not be decreased." });
    } else {
      data[index].bidDiscount = value;
      data[index].bidPricePerCarat = ((value / 100 + 1) * data[index].rap).toFixed(2);
      data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
    }

    this.setState({ data });
  });

  getNewBidDiscount = (stoneId, minus) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId });
    const max = this.state.nextBidData?.sub_types?.upwardInterval + Math.abs(data[index].back);
    const min = data[index].back - this.state.nextBidData?.sub_types?.downwardInterval;
    const diffInterval = this.state.nextBidData?.sub_types?.diffInterval;
    const val = minus ? data[index].bidDiscount - diffInterval : data[index].bidDiscount + diffInterval;
    data[index] = { ...data[index] };

    if (val >= -100 && val <= 100 && val > max) {
      OpenNotification({
        type: "error",
        title: "Discount can not be Increased.",
      });
    } else if (val < min) {
      OpenNotification({
        type: "error",
        title: "Discount can not be decreased.",
      });
    } else {
      data[index].bidDiscount = val;
      data[index].bidPricePerCarat = ((val / 100 + 1) * data[index].rap).toFixed(2);
      data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
    }

    this.setState({ data });
  };

  // handleDiscountBlur = (...args) => this.handleDiscountChange(...args, true);
  getNextBidConfig = async () => {
    const nextBidData = await BidService.getConfig(this.props.dynamicBidType);
    this.setState({ nextBidData });
  };

  checkCheck = () => {
    if (!this.state.checked.length) OpenNotification({ type: "error", title: "Please select stone(s) to bid." });
    return Boolean(this.state.checked.length);
  };

  // submit = () => {
  //   let self = this;
  //   if (!this.checkCheck()) return;
  //   handleBidUpsert(this.state.checked, getBidType(), (flag) => {
  //     self.props.onClose();
  //     self.props.clearAll();
  //     if (flag && self.props.fetch) self.props.fetch();
  //   });
  // };
  submit = () => {
    const self = this;
    let sameDis = false;
    if (!this.checkCheck()) return;
    const newData = [];
    this.state.data.forEach((d) => {
      this.state.checked.forEach((c) => {
        if (d.id === c.id) {
          if (d.back === d.bidDiscount) {
            OpenNotification({ type: "error", title: `Discount and Bid Discount can't be same` });
            sameDis = true;
          }
          newData.push(d);
        }
      });
    });
    !sameDis &&
      handleBidUpsert(
        newData,

        this.state.nextBidData?.id,
        this.state.nextBidData?.sub_types?.subTypeCode,
        (flag) => {
          self.props.throwNotifyCount(self.props.notifyCount + 1);
          self.props.onClose();
          self.props.clearAll();
          if (flag && self.props.fetch) self.props.fetch();
        }
      );
  };

  render() {
    const summation = calculate(this.state.checked);
    const totalCarat = parseFloat(summation.total_carat).toFixed(2);
    const netValue = formatNumber(parseFloat(summation.final_value).toFixed(2));
    const avgDisc = parseFloat(summation.final_discount).toFixed(2);
    const finalAvgDisc = parseFloat(summation.final_term_discount).toFixed(2);
    const finalNetRate = formatNumber(parseFloat(summation.final_rate).toFixed(2));
    const finalNetValue = formatNumber(parseFloat(summation.final_net_value).toFixed(2));
    const netRate = formatNumber(parseFloat(summation.final_price).toFixed(2));

    const VALUES = isMobile()
      ? { "CT.:": totalCarat, "Total Value:": netValue, "Price/Ct:": netRate, "Disc%:": avgDisc }
      : [
          { title: "Total Carats", value: totalCarat },
          { title: "Final Net Rate", value: finalNetRate },
          { title: "Avg. Discount", value: avgDisc },
          { title: "Final Avg Discount", value: finalAvgDisc },
          { title: "Net Value", value: netValue },
          { title: "Final Net Value", value: finalNetValue },
        ];

    return isMobile() ? (
      <BIDStoneMobile
        {...this.state}
        currentType={this.state.currentType}
        onClose={this.props.onClose}
        VALUES={VALUES}
        parent={this}
        bidPopup
        submit={this.submit}
        handleDiscountInput={this.handleDiscountInput}
      />
    ) : (
      <div>
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
          <Heading className="popupInnerTitle mr-20" title={<IntlMessage id="app.BidStone" />} />
          {this.bidHeading()}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll onlyTableShow">
            <Table
              {...this.state}
              nodots
              areAllChecked={true}
              handleCheck={(e) => this.setState({ checked: e })}
              currentType={this.state.currentType}
              canSort={false}
              noGrp
              FilterOption={false}
              noCheckBox
            />
          </div>
          {/* <div className="mt-10 finalCalDetail">
            <div className="DiamondDetailPopup">
              {VALUES.map((x) => (
                <div className="DiamondDetailPopupItem">
                  <span>{x.title} : </span>
                  <span>{x.value}</span>
                </div>
              ))}
            </div>
            <p className="offerNote mt-20">
              <b>Note:</b> The additional {parseFloat(getExtraPer()).toFixed(2)}
              {getBidType() === DIAMOND_BID.TYPE.BLIND ? "+0.5" : ""}% on amount value has already
              been added in the final calculation.
            </p>
          </div> */}
          <div className="sideBarPopupButton">
            <span id="bid-stone" className="commonButton" onClick={this.submit}>
              {<IntlMessage id="app.BidStone" />}
            </span>
            <span className="commonButton" onClick={this.props.onClose}>
              {<IntlMessage id="app.CancelBid" />}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { selectedRows: diamondData.selectedRows || [] };
};

export default compose(connect(mapStateToProps), withCurrentType)(BidPopup);
