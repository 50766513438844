import React from "react";

// import HeaderStrip from 'components/common/Header/HeaderStrip';

import urlJoin from "url-join";

import bnrPNG from "../../assets/img/dashboard/banner1.png";
import { BASE_URL } from "../../constants/Common";

const TopImage = (props) => {
  return (
    <div className="advertisementImage" onClick={props.onClick}>
      {props.textShow && (
        <div className="dashboardBannerText">
          <h2>{props.event}</h2>
          <p>
            {props.desc}
            {' '}
          </p>
        </div>
      )}
      <img src={props.image} alt="banner" />
    </div>
  );
};

const Advertisement = (props) => {
  return (
    <>
      <div className="d-flex j-space-between flex-wrap">
        <div className="dashboradTopLeft">
          {props.main && props.main.length !== 0 ? (
            props.main[0].images &&
            props.main[0].images.length !== 0 && (
              <TopImage
                onClick={() => {
                  if (props.main[0].url) {
                    window.open(props.main[0].url, "_self");
                  }
                }}
                desc={props.main[0].description && props.main[0].description}
                event={props.main[0].eventName && props.main[0].eventName}
                textShow
                image={urlJoin(BASE_URL, props.main[0].images[0])}
              />
            )
          ) : (
            <TopImage textShow image={bnrPNG} />
          )}
        </div>
        <div className="dashboardTopRight">
          {props.other && props.other.length !== 0 ? (
            props.other.map((x, k) => {
              return (
                x.images &&
                x.images.length !== 0 && (
                  <TopImage
                    key={k}
                    onClick={() => {
                      if (x.url) {
                        window.open(x.url, "_self");
                      }
                    }}
                    image={urlJoin(BASE_URL, x.images[0])}
                  />
                )
              );
            })
          ) : (
            <>
              {/* {props.version1 ? null :
                <>
                  <TopImage image={require("../../assets/img/dashboard/banner2.jpg")} onClick={() => {
                    window.open(`/diamond-list?5f96919e7cb9e93122911ee4`, "_self");
                  }} />
                  <TopImage image={require("../../assets/img/dashboard/banner2-1.jpg")} onClick={() => {
                    window.open(`/diamond-list?5f96919e7cb9e93122911ee4`, "_self");
                  }} />
                </>
              } */}
              {/* <TopImage image={require("../../assets/img/dashboard/banner2-2.jpg")} onClick={() => {
                window.open(`/diamond-list?5f96919e7cb9e93122911ee4`, "_self");
              }} />
              <TopImage image={require("../../assets/img/dashboard/banner2-3.jpg")} onClick={() => {
                window.open(`/diamond-list?5f96919e7cb9e93122911ee4`, "_self");
              }} /> */}
            </>
          )}
          {/* <TopImage image={require("../../assets/img/dashboard/banner2.jpg")} onClick={() => {
            window.open(`/diamond-list?5f96919e7cb9e93122911ee4`, "_self");
          }} /> */}
        </div>
      </div>
    </>
  );
};
export default Advertisement;
