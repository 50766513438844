import React from "react";
import { useHistory } from "react-router-dom";

import sumBy from "lodash/sumBy";

import IntlMessage from "util/IntlMessage";

import rSVG from "../../../assets/svg/Header/right.svg";

const HeaderAccountList = (props) => {
  const history = useHistory();
  let totalpiece = 0;
  if (props.orderThi) {
    totalpiece = sumBy(props.details, "pieces");
  }
  return (
    <li
      onClick={() =>
        props.link && !props.isGuest
          ? props.noKyc
            ? history.push(props.noKycUrl)
            : props.isKyc
            ? props.showKyc()
            : history.push(props.link)
          : props.showGuest()}
    >
      <div className="userDetailListItem" onClick={() => (props.onClick ? props.onClick() : {})}>
        {props.noCount && (
          <>
            {props.orderThi ? (
              <div className="count">{totalpiece || 0}</div>
            ) : (
              <div className="count">{props.details && props.details.pieces ? props.details.pieces : 0}</div>
            )}
          </>
        )}
        <span className="detailItemName">{props.title}</span>
        {props.arrownone ? null : <img src={rSVG} alt="icon" />}
      </div>
      {props.basicDetailnull ? null : (
        <>
          {props.orderThi ? (
            props.details && props.details.length !== 0 ? (
              props.details.map((ord, k) => {
                return (
                  <div key={k} className="uerDetailBasic">
                    <div className="userDetailBasicItem">
                      {/* Status */}
                      <span
                        style={{ cursor: "pointer" }}
                        title={`${ord.status === 1 ? "Pending" : "Approved"}`}
                        className={`stausButton ${ord.status === 1 ? "pending" : "active"}`}
                      >
                        <span>{ord.status === 1 ? "P" : "A"}</span>
                      </span>
                    </div>
                    <div className="userDetailBasicItem">
                      <IntlMessage id="app.Pieces" />
                      <span>
                        {" "}
                        &nbsp;
                        {ord && ord.pieces ? ord.pieces : 0}
                      </span>
                    </div>
                    <div className="userDetailBasicItem">
                      <IntlMessage id="app.Carats" />
                      <span>
                        {" "}
                        &nbsp;
                        {ord && ord.carat ? parseFloat(ord.carat || 0).toFixed(2) : 0}
                      </span>
                    </div>
                    <div className="userDetailBasicItem">
                      <IntlMessage id="app.Value" />
                      <span> ${ord ? Math.round(ord.totals || 0).toLocaleString("en-US") : 0}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              "No data"
            )
          ) : (
            <div className="uerDetailBasic">
              <div className="userDetailBasicItem">
                <IntlMessage id="app.Pieces" />
                <span>
                  {" "}
                  &nbsp;
                  {props.details && props.details.pieces ? props.details.pieces : 0}
                </span>
              </div>
              <div className="userDetailBasicItem">
                <IntlMessage id="app.Carats" />
                <span>
                  {" "}
                  &nbsp;
                  {props.details && props.details.carat ? parseFloat(props.details.carat || 0).toFixed(2) : 0}
                </span>
              </div>
              <div className="userDetailBasicItem">
                <IntlMessage id="app.Value" />
                <span>
                  {" "}
                  &nbsp;$
                  {props.details ? Math.round(props.details.totals || 0).toLocaleString("en-US") : 0}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </li>
  );
};
export default HeaderAccountList;
