export class Action {
  constructor(type) {
    this.type = type;
  }

  emit(detail) {
    const event = new CustomEvent(this.type, { detail });
    window.dispatchEvent(event);
  }

  listen(_callback) {
    const callback = (event) => {
      _callback(event, { type: this.type, data: event?.detail });
    };
    window.addEventListener(this.type, callback);
    return () => {
      window.removeEventListener(this.type, callback);
    };
  }
}
