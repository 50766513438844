import React, { Component } from "react";

import { Select } from "antd";

import { RowSelectService } from "services/RowSelectService";

import lArrSVG from "../../assets/svg/DiamondList/left-arrow.svg";
import rArrSVG from "../../assets/svg/DiamondList/right-arrow.svg";

const { Option } = Select;

const LIMITS = [100, 200, 300];
const dots = "...";
function getPages(totalPage, pg) {
  const pages = [];
  const prevPage = pg - 1 > 0 ? pg - 1 : 1;
  if (prevPage > 1) pages.push(1);
  if (prevPage > 1) pages.push(dots);
  for (let i = prevPage; i <= prevPage + 2 && i <= totalPage; i++) pages.push(i);
  if (prevPage + 3 < totalPage) pages.push(dots);
  if (prevPage + 2 < totalPage) pages.push(totalPage);
  return pages;
}

class Pagination extends Component {
  render() {
    const { props } = this;
    // count - total count
    // page - current page
    // limit - current limit
    // noLimitChange - display limit Selection
    // noItemCount - display items 1-10 of 1000
    // data - actual page data length
    const totalPage = props.count === 0 ? 1 : parseInt((props.count + props.limit - 1) / props.limit);
    const pairdData = !!props.pairdData;
    return (
      <div className="listingPagePagination">
        <div className="clientPagination">
          {!props.noLimitChange && (
            <Select
              value={props.limit}
              onChange={(e) => {
                props.onPaginationChange(1, e);
                // RowSelectService.resetRowsOnPagination({});
              }}
              className="paginationSelect"
            >
              {LIMITS.map((l) => (
                <Option value={l} key={l}>
                  {l}
                </Option>
              ))}
            </Select>
          )}
          <ul>
            <li>
              <img
                onClick={() => {
                  if (props.page !== 1) {
                    props.onPaginationChange(props.page - 1, props.limit);
                    // RowSelectService.resetRowsOnPagination({});
                  }
                }}
                src={lArrSVG}
                alt="arrow"
              />
            </li>
            {getPages(totalPage, props.page).map((x, i) => (
              <li
                key={i}
                className={x === props.page ? "active" : null}
                onClick={() => {
                  if (x !== dots) {
                    props.onPaginationChange(x, props.limit);
                    // RowSelectService.resetRowsOnPagination({});
                  }
                }}
              >
                {x}
              </li>
            ))}
            <li>
              <img
                onClick={() => {
                  if (props.page !== totalPage) {
                    props.onPaginationChange(props.page + 1, props.limit);
                    // RowSelectService.resetRowsOnPagination({});
                  }
                }}
                src={rArrSVG}
                alt="arrow"
              />
            </li>
          </ul>
          {!props.noItemCount && (
            <span className="paginationText">
              {props.count > 0 ? (props.page - 1) * props.limit + 1 : 0} -{" "}
              {props.count > 0 ? (props.page - 1) * props.limit + (props.dataLength || props.data.length) : 0} of{" "}
              {props.count} {pairdData ? "Couples" : "Items"}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default Pagination;
