import React from "react";

import { Radio } from "antd";
import PropTypes from "prop-types";

import IntlMessage from "util/IntlMessage";
import { classNames, hasKey } from "util/utils";

export const KeyToSymbol = (props) => {
  const { master, value, onChange } = props;

  const key = React.useMemo(() => {
    return hasKey(value, "in") ? "in" : hasKey(value, "nin") ? "nin" : "in";
  }, [value]);

  const idList = React.useMemo(() => {
    return master?.map?.((record) => record?.id?.[0])?.sort() ?? [];
  }, [master]);

  const selectedIdList = React.useMemo(() => {
    return value?.[key]?.sort?.() ?? [];
  }, [key, value]);

  const areAllSelected = idList?.length === selectedIdList?.length;

  const toggleKey = React.useCallback(
    (e) => {
      const {value} = e.target;
      onChange({ [value]: [] });
    },
    [onChange]
  );

  const toggleAll = React.useCallback(() => {
    onChange({ [key]: areAllSelected ? [] : idList });
  }, [areAllSelected, idList, key, onChange]);

  const toggle = React.useCallback(
    (id) => {
      const list = [...selectedIdList];
      const index = list.indexOf(id);
      index > -1 ? list.splice(index, 1) : list.push(id);
      onChange({ [key]: list });
    },
    [key, onChange, selectedIdList]
  );

  return (
    <div className="diamondSearchItem b-b-0">
      <div className="diamondSearchDetail width-100">
        <p
          style={{
            fontWeight: 500,
            alignSelf: "center",
            fontSize: 18,
            textDecoration: "underline",
          }}
        >
          STEP 1
        </p>
        <Radio.Group
          onChange={toggleKey}
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <Radio value="in" checked={key === "in"}>
            <IntlMessage id="app.Contains" />
          </Radio>
          <Radio value="nin" checked={key === "nin"}>
            <IntlMessage id="app.Doesnotcontains" />
          </Radio>
        </Radio.Group>
        <p
          style={{
            fontWeight: 500,
            alignSelf: "center",
            fontSize: 18,
            textDecoration: "underline",
          }}
        >
          STEP 2
        </p>
        <ul className="diamondSearchList mt-10">
          <li onClick={toggleAll} className={classNames([areAllSelected && "active"])}>
            All
          </li>
          {master?.map?.((record) => {
            const checked = selectedIdList.includes(record?.id?.[0]);
            return (
              <li
                key={record?.code}
                onClick={() => toggle(record?.id?.[0])}
                className={classNames([checked && "active"])}
              >
                {record?.webDisplay}
              </li>
            );
          }) ?? null}
        </ul>
      </div>
    </div>
  );
};

KeyToSymbol.displayName = "KeyToSymbol";

KeyToSymbol.propTypes = {
  master: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
};
