import UtilService from "./util";

import { diamondParameters } from "../constants/Common";

const Common = {
  DiamondTrackType(req, api, cb) {
    const obj = {
      method: "post",
      url: api,
      request: req,
    };

    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        cb(null, data);
      } else {
        cb(err, null);
      }
    });
  },
};
export default Common;

export const setDateFormat = (date) => {
  const selectedDate = new Date(date);
  if (selectedDate) {
    const newYear = selectedDate.getFullYear();
    let newMonth = selectedDate.getMonth();
    let newDate = selectedDate.getDate();
    newMonth = newMonth >= 9 ? newMonth + 1 : `0${newMonth + 1}`;
    newDate = newDate > 9 ? newDate : `0${newDate}`;
    const newSetFormat = `${newDate}/${newMonth}/${newYear}`;
    return newSetFormat;
  }
};

export const setTimeFormat = (date) => {
  const selectedDate = new Date(date);

  if (selectedDate) {
    let Hours = selectedDate.getHours();
    let Minutes = selectedDate.getMinutes();
    const ampm = Hours >= 12 ? "PM" : "AM";
    Hours %= 12;
    Hours = Hours ? (Hours < 10 ? `0${Hours}` : Hours) : 12; // the hour '0' should be '12'
    Minutes = Minutes < 10 ? `0${Minutes}` : Minutes;

    return `${Hours}:${Minutes}:${ampm}`;
  }
};

export const checkForMaster = (key) => {
  let keyMaster = "";
  if (diamondParameters && diamondParameters.length !== 0) {
    diamondParameters.forEach((mas) => {
      if (mas.key === key) {
        keyMaster = mas.master;
      }
    });
  }
  return keyMaster;
};
