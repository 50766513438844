import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Menu, Dropdown } from "antd";
import groupBy from "lodash/groupBy";
import last from "lodash/last";
import size from "lodash/size";

import BasicDetail from "./BasicDetail";
import Compare from "./Compare";
import DiamondDetailAction from "./DiamondDetailAction";
import EnquiryDetail from "./EnquiryDetail";
import ExtraButton from "./ExtraButton";
import DiamondDetailLeft from "./LeftSection2";
import RecentlyView from "./RecentlyView";

import ddArrowSVG from "../../assets/svg/dropdown-arrow.svg";
import { KYC_STATUS, PAGES } from "../../constants/Common";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import DetailView from "../../constants/DiamondDetail";
import { VIEW_DIAMOND } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import { getGridHeight } from "../DiamondList";
import { downloadZip, isMobile } from "../DiamondList/DiamondListFunctions";
import { floatkeys, roundkeys } from "../DiamondList/TableBack";
import { GuestUserPopup } from "../GuestUserPopup";
import { NoKycPop } from "../NoKycPop";
import ConfirmStonePopup from "../common/DiamondListing/ConfirmStonePopup";
import SendEmail from "../common/DiamondListing/SendEmail";
import Heading from "../common/Heading";
import "../../styles/DiamondDetail2.less";

const LISTINGPAGES = PAGES;

class DiamondDetail2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      show: false,
      detail: true,
      data: {},
      sendEmail: false,
      isGuest: UtilService.getLocalStorageItem(
        window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
      ),
      showGuest: false,
      more: false,
      noKyc: false,
      isKyc: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
  }

  placeOrderDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        this.setState({
          visible: true,
          detail: false,
          show: false,
        });
      }
    }
  };

  EnquiryDetail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        this.setState({
          show: true,
          detail: false,
          visible: false,
        });
      }
    }
  };
  handleMoreOption = () => {
    this.setState({
      more: !this.state.more,
    });
  };

  popupClose = () => {
    this.setState({
      detail: true,
      visible: false,
      show: false,
    });
  };

  componentDidMount = () => {
    if (this.props.diamondPopup) return;
    const id = last(window.location.pathname.split("/"));
    if (id !== LISTINGPAGES.DETAILS && id !== LISTINGPAGES.DETAIL) {
      const self = this;
      const api = `${VIEW_DIAMOND.url}/${id}`;
      const objData = {
        method: VIEW_DIAMOND.method,
        url: api,
        showLoader: true,
      };
      UtilService.callApi(objData, async (err, data) => {
        if (err) throw err;
        if (data && data.code === "OK") {
          self.setState({ data: data.data });
        }
      });
    }
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

    if (authUser && authUser.account) {
      if (!authUser.account.isKycUploaded) {
        this.setState({ isKyc: !authUser.account.isKycUploaded });
      } else {
        if (authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.authUser && nextProps.auth.authUser.account) {
      if (!nextProps.auth.authUser.account.isKycUploaded) {
        this.setState({ isKyc: !nextProps.auth.authUser.account.isKycUploaded });
      } else {
        if (nextProps.auth.authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }
  verifyCerti = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        window.open(`https://gia.edu/report-check?reportno=${data.rptNo}`);
      }
    }
  };

  downloadZip = (data) => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        downloadZip(
          {
            filter: {
              vStnId: [data.vStnId],
            },
            isExcel: true,
            img: true,
            certFile: true,
            videoFile: true,
            hAFile: true,
            arrowFile: true,
            assetFile: true,
          },
          () => {}
        );
      }
    }
  };

  sendMail = () => {
    if (this.state.isGuest) {
      this.setState({ showGuest: true });
    } else {
      if (this.state.isKyc) {
        this.setState({ noKyc: true });
      } else {
        this.setState({ sendEmail: true });
      }
    }
  };

  actionButtons = (data) => {
    return !data || data.wSts === "U" ? null : (
      <div>
        <div className="detailTopBasic">
          <div className="detailBasic">
            <BasicDetail />
            <div className="basicSmallDetail">
              <span>Polish</span>
              <span> | Symmetry</span>
              <span> | Fluorescence</span>
            </div>
          </div>
          <div className="detailPriceBlock">
            <span>$25.00</span>
          </div>
        </div>
        <div className="diamondDetailBottomAction">
          <div className="d-flex flex-wrap">
            {window.location.pathname.split("/")[1] === LISTINGPAGES.DETAILS ? (
              <>
                <div className="botoomStickyBar diamondSearchSticky">
                  <div className="d-flex align-items-center">
                    <a className="bottomStickyButton" href="/signup">
                      <span>Create An Account</span>
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <a className="bottomStickyButton" href="/signin">
                      <span>Log In</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <>
                <DiamondDetailAction
                  fillButton
                  title="Buy Now"
                  //   image={require("../../assets/svg/DiamondDetail/cart.svg")}
                  //   tooltip={<IntlMessage id="app.confirmStone" />}
                  //   placement="bottomLeft"
                  onClick={this.placeOrderDetail}
                />
                {/* <DiamondDetailAction
                    image={require('../../assets/svg/DiamondDetail/enquiry.svg')}
                    tooltip='Enquiry'
                    placement='bottomLeft'
                    onClick={this.EnquiryDetail}
                /> */}
                {isMobile() ? (
                  <Dropdown
                    overlay={
                      <Menu className="moreOptionDropdown">
                        <Menu.Item key="0">Add To Offer</Menu.Item>
                        <Menu.Item key="1">Add To Cart</Menu.Item>
                        <Menu.Item key="3">Place an Offer</Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <DiamondDetailAction arrow={ddArrowSVG} onClick={(e) => e.preventDefault()} outline title="More" />
                  </Dropdown>
                ) : (
                  <DiamondDetailAction
                    outline
                    title="Add To Offer"
                    //   image={require("../../assets/svg/DiamondDetail/add-cart.svg")}
                    //   tooltip={<IntlMessage id="app.AddToCart" />}
                    //   placement="bottomLeft"
                    //   onClick={() => {
                    //     if (this.state.isGuest) {
                    //       this.setState({ showGuest: true });
                    //     } else {
                    //       handleInsertTrack(trackTypeObj.CART, [data], () => {});
                    //     }
                    //   }}
                  />
                )}
                {/* <DiamondDetailAction
                  onClick={() => {
                    if (this.state.isGuest) {
                      this.setState({ showGuest: true });
                    } else {
                      this.verifyCerti(data);
                    }
                  }}
                  image={require("../../assets/svg/DiamondDetail/certificate.svg")}
                  tooltip={<IntlMessage id="app.VerifyCertificate" />}
                  placement="bottomLeft"
                />
                <DiamondDetailAction
                  onClick={() => {
                    if (this.state.isGuest) {
                      this.setState({ showGuest: true });
                    } else {
                      this.sendMail();
                    }
                  }}
                  image={require("../../assets/svg/DiamondDetail/x-ray.svg")}
                  tooltip={<IntlMessage id="app.ShareXRay" />}
                  placement="bottomLeft"
                />
                <DiamondDetailAction
                  image={require("../../assets/svg/DiamondDetail/download-zip.svg")}
                  tooltip={<IntlMessage id="app.downloadZip" />}
                  placement="bottomLeft"
                  onClick={() => {
                    if (this.state.isGuest) {
                      this.setState({ showGuest: true });
                    } else {
                      this.downloadZip(data);
                    }
                  }}
                /> */}
              </>
            )}
          </div>
          {/* {isMobile() ? (
              <>
            {this.state.more &&
                <>
                <ExtraButton/>
                </>
                }
                </>
                ):
                (<ExtraButton/>
            )
           } */}
          <ExtraButton lb={data.lbNm} rptNo={data.rptNo} />
          {/* {isMobile() &&
          <span className="moreLink" onClick={this.handleMoreOption}>{!this.state.more ? <>More</>:<>Less</>}</a>
          } */}
        </div>
      </div>
    );
  };

  diamondDetail = (data, key) => {
    const grouped = groupBy(data, key);
    let first = grouped[1] || [];
    const second = grouped[2] || [];
    if ((!first || !first.length) && (!second || !second.length)) {
      first = Object.values(grouped)[0];
    }
    const maxIndex = Math.max(first.length, second.length);
    const arr = [];
    for (let i = 0; i < maxIndex; i++) {
      first[i] && arr.push(first[i]);
      second[i] && arr.push(second[i]);
    }
    return arr;
  };

  leftPart = (data) => {
    const gridHeight1 = getGridHeight(["headerTopMenu", "covidStrip", "HeaderStripBlock", "diamondDetailTopTab"], 15);
    return (
      <div className="diamondDetailInnerDetails">
        {data && size(data) ? (
          <DiamondDetailLeft
            item={data}
            rptNo={data.rptNo}
            pktNo={data.vStnId}
            style={
              isMobile() ? {} : !this.props.diamondPopup ? { height: gridHeight1, overflow: "auto" } : { height: "" }
            }
          />
        ) : null}
      </div>
    );
  };

  getEmailModal = (data) => {
    return (
      <SendEmail
        path={`${window.location.origin}/${LISTINGPAGES.DETAILS}/${data.id}`}
        xRay={true}
        sendEmail={this.state.sendEmail}
        onCancel={() => this.setState({ sendEmail: false })}
        onDetailClose={() => this.setState({ sendEmail: false })}
      />
    );
  };

  render() {
    const data = this.props.diamondPopup ? this.props.data : this.state.data;
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;

    const gridHeight = getGridHeight(["headerTopMenu", "covidStrip", "HeaderStripBlock", "diamondDetailBottomBox"], 15);

    const params = Object.keys(DetailView).map((k) => (
      <div className="diamondDetailMore" key={DetailView[k]["name"]}>
        <h2 className="detailSmallTitle">{DetailView[k]["name"]}</h2>
        <div className="diamondDetailMoreHalf">
          {this.diamondDetail(DetailView[k]["data"], "part").map((item) => {
            if (data.wSts === "U" && ["back", "ctPr", "amt"].includes(item.key)) return false;
            return (
              <ul key={item.key}>
                <li>
                  {!item.hide && (
                    <>
                      <span className="detailLabel">{item.label}</span>
                      <span className="detailvalue">
                        {!data[item["key"]]
                          ? "-"
                          : floatkeys.includes(item.key)
                          ? parseFloat(data[item["key"]]).toFixed(2)
                          : roundkeys.includes(item.key)
                          ? Math.round(data[item["key"]])
                          : data[item["key"]]}
                      </span>
                    </>
                  )}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    ));

    // if (isMobile())
    //   return (
    //     <MobileDiamondDetail params={params} {...this.state} parent={this} />
    //   );

    return (
      <div className="diamondDetailWrapper diamondDetailVersion2">
        <div className="diamondDetailInner">
          {this.props.diamondPopup ? this.actionButtons(data) : null}
          <div className="diamondDetailMainTab">
            {this.props.diamondPopup && (
              <>
                <div className="detailHalfWhite"></div>
                <div className="detailHalfGray"></div>
              </>
            )}
            <div className="diamondDetailLeft">{this.leftPart(data)}</div>
            <div className={`diamondDetailRight ${!this.props.diamondPopup && "diamondDetailPage"}`}>
              {!this.props.diamondPopup ? this.actionButtons(data) : null}
              {this.state.show && (
                <EnquiryDetail
                  style={!this.props.diamondPopup ? { height: gridHeight, overflow: "auto" } : { height: "auto" }}
                  checked={[data]}
                  onCancel={() => this.popupClose()}
                  onClose={() => this.popupClose()}
                />
              )}
              {this.state.visible && (
                <div className="p-10">
                  <ConfirmStonePopup
                    style={!this.props.diamondPopup ? { height: gridHeight, overflow: "auto" } : { height: "" }}
                    popupClass={!this.props.diamondPopup ? null : "halfBlockPopup"}
                    detail
                    checked={[data]}
                    onClose={this.popupClose}
                  />
                </div>
              )}
              {/* {this.state.detail && (
                <div
                  className="mt-10"
                  // style={
                  //   !this.props.diamondPopup
                  //     ? { height: gridHeight, overflow: "auto" }
                  //     : { height: "auto" }
                  // }
                >
                  {params}
                </div>
              )} */}
            </div>
          </div>

          <div className="detailCompareBlock">
            <Heading title="Compare Alternate Diamonds" className="p-0" />
            <Compare />
          </div>
          <div className={`diamondDetailBlock ${this.state.more && "active"}`}>
            <Heading title="Diamond Details" className="p-0" />
            {this.state.detail && (
              <div
                className="mt-10 d-flex flex-wrap"
                // style={
                //   !this.props.diamondPopup
                //     ? { height: gridHeight, overflow: "auto" }
                //     : { height: "auto" }
                // }
              >
                {params}
              </div>
            )}
            {isMobile() && (
              <span className="moreLink" onClick={this.handleMoreOption}>
                {!this.state.more ? <>More</> : <>Less</>}
              </span>
            )}
          </div>
          <div className="recentlyViewProduct">
            <Heading title="Recently Viewed" />
            <div>
              <RecentlyView />
            </div>
          </div>
        </div>
        {this.getEmailModal(data)}
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open("/signup", "_self");
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
        {this.state.noKyc && (
          <NoKycPop
            status={authUser.isApproved}
            visible={this.state.noKyc}
            handleCancel={() => {
              this.setState({ noKyc: false });
            }}
          />
        )}
      </div>
    );
  }
}
export default connect((props) => {
  return props;
}, {})(withRouter(DiamondDetail2));
