import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { Select, Spin } from "antd";
import { isEqual, xorWith, join, find, map, filter, invert } from "lodash";

import GridHeightContainer from "containers/GridHeightContainer/GridHeightContainer";

import { stagePopoverSelectAction } from "components/DiamondList/StagePopover";

import { Storage } from "services/storage";
import UtilService from "services/util";

import IntlMessage from "util/IntlMessage";
import { useCompositeState, useIsMobile, usePathname } from "util/hooks";
import { isEmpty, isNotEmpty } from "util/utils";

import {
  trackTypeObj,
  DIAMOND_FEATURED_GROUP_TYPE,
  DIAMOND_WEB_STATUS,
  DIAMOND_DISPLAY_TYPE,
  LOCAL_STORAGE_VAR,
  MULTIPLE_SEARCH,
  PAGES,
} from "constants/Common";

import gridSvg from "assets/svg/DiamondList/grid.svg";
import listSvg from "assets/svg/DiamondList/list.svg";
import DiamondGridView from "./DiamondGrid";
import {
  getColumn,
  fetchDiamondList,
  fetchDiamondPaginate,
  getPath,
  fetchCart,
  fetchWatch,
  TITLE,
  fetchReminder,
  fetchConfirmStone,
  fetchStoneOfDay,
  fetchMatchPair,
  handleExclusiveSearchListId,
  fetchNotes,
  fetchOfficeList,
  handleCollectionId,
  handleFancySearchList,
  handlewhiteSearchList,
} from "./DiamondListFunctions";
import MobileViewList from "./MobileViewList";
import Table, { LIMIT, LIMIT_500 } from "./TableBack";

import { SearchService } from "../../services/SearchService";
import QuickSearch from "../Dashboard/QuickSearch";
import { GuestUserPopup } from "../GuestUserPopup";
import { NoKycPop } from "../NoKycPop";
import DiamondListingAction from "../common/DiamondListing/DiamondListingAction";
import ListingTop from "../common/DiamondListing/ListingTop";
import SelectStone, { formatNumber } from "../common/DiamondListing/SelectStone";
import Heading from "../common/Heading";
import NoDataShow from "../common/NoDataShow";

const LISTINGPAGES = PAGES;

function getElementByClass(name) {
  if (document.getElementsByClassName(name) && document.getElementsByClassName(name)[0])
    return document.getElementsByClassName(name)[0];
  return null;
}

export function getGridHeight(allClasses, initial) {
  // const allContainers = {};
  const allContainers = {};
  allClasses.map((classname) => {
    allContainers[classname] = getElementByClass(classname) ? getElementByClass(classname) : 0;
    return true;
  });
  let totalHeight = window.innerHeight - initial;
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight -= allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}

export function emptyData(loading, length) {
  return loading ? (
    <NoDataShow message={<Spin />} />
  ) : length === 0 ? (
    <NoDataShow message={<IntlMessage id="app.BidInactive" />} />
  ) : null;
}

export const getState = (override) => ({
  listView: true,
  data: [],
  needLogout: false,
  filterArrayData: {},
  exportFilterData: {},
  page: 1,
  limit: getPath() === LISTINGPAGES.MATCHPAIR ? LIMIT_500 : LIMIT,
  sum: {},
  columns: [],
  count: 0,
  loading: false,
  searchId: null,
  inTrackDiamonds: [],
  defaultChecked: [],
  ...override,
});

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const DiamondList = (props) => {
  const { location, history, onSumChange } = props;

  const [isMobile] = useIsMobile();
  const pathname = usePathname();

  const [listView, setListView] = useState(true);
  const [payReq, setPayReq] = useState({});
  const [searchIds, setSearchIds] = useState([]);

  const [orderStatus, setOrderStatus] = useState(1);
  const [officeStatus, setOfficeStatus] = useState(1);

  const [state, setState] = useCompositeState(() => ({
    ...getState(),
    sort: [],
  }));

  const currentType = React.useMemo(() => {
    return (history.location.state && history.location.state.currentType) || location.pathname;
  }, [history.location.state, location.pathname]);

  const tableRef = useRef();
  const searchId = useRef();
  // const searchId = useRef();

  const dispatch = useDispatch();
  const clearAllSelectedRows = useCallback(() => dispatch({ type: "RESET_SELECTED_ROWS" }), [dispatch]);

  const isAccount = useMemo(() => location.pathname.split("/")[1] === "account", [location]);

  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const setResponse = (res) => {
    const columns = getColumn();
    if (res) {
      // CHECK THIS
      if (res.needLogout) {
        setState({
          ...res,
          count: 0,
          data: [],
          needLogout: res.needLogout,
          defaultChecked: [],
          columns,
          loading: false,
          filterArrayData: {},
        });
      } else {
        const filterArray = res.seachFilterTag && res.seachFilterTag.displayData ? res.seachFilterTag.displayData : {};
        const diamondData = res.data || [];
        let defaultChecked = [];
        if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
          defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
            ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
            : [];
          if (defaultChecked && defaultChecked.length > 0) {
            diamondData.map((d) => {
              if (defaultChecked.includes(d.id)) {
                d.isDefaultChecked = true;
              }
              return d;
            });
          }
          localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
        }
        setState({
          ...res,
          data: diamondData,
          defaultChecked,
          columns,
          loading: false,
          filterArrayData: filterArray,
        });
      }
    } else {
      setState({ loading: false, defaultChecked: [] });
    }
  };

  const fetch = useCallback(
    (override) => {
      const currentId = searchIds.find((id) => Boolean(id?.isActive))?.id ?? searchIds?.[0]?.id;

      const payload = {
        ...state,
        ...payReq,
        ...override,
      };

      setState({ loading: true, data: [] });
      if (pathname === LISTINGPAGES.LIST) {
        if (currentId) {
          fetchDiamondList(payload, [{ diamondSearchId: currentId, ...override }], setResponse, true);
        }
      } else if (pathname === LISTINGPAGES.UPCOMING) {
        fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.UPCOMING, setResponse);
      } else if (pathname === LISTINGPAGES.FANCY_SEARCH) {
        handleFancySearchList(payload, setResponse);
      } else if (pathname === LISTINGPAGES.CART) {
        fetchCart(payload, setResponse);
      } else if (pathname === LISTINGPAGES.WATCHLIST) {
        fetchWatch(payload, setResponse);
      } else if (pathname === LISTINGPAGES.REMINDER) {
        fetchReminder(payload, setResponse);
      } else if (pathname === LISTINGPAGES.CONFIRM) {
        const _payload = { ...payload, status: orderStatus };
        setPayReq(_payload);
        fetchConfirmStone(_payload, setResponse, orderStatus);
      } else if (pathname === LISTINGPAGES.STONE_OF_DAY) {
        fetchStoneOfDay(payload, DIAMOND_FEATURED_GROUP_TYPE.STONE_OF_DAY, setResponse);
      } else if (pathname === LISTINGPAGES.NEWARR) {
        fetchDiamondPaginate(payload, { viewType: DIAMOND_DISPLAY_TYPE.NEW }, setResponse);
      } else if (pathname === LISTINGPAGES.MATCHPAIR) {
        // fetchMatchPair(payload, window.location.search.split('?')[1], setResponse)
        fetchMatchPair(payload, window.location.search.split("?")[1], setResponse);
      } else if (pathname === LISTINGPAGES.EXCLUSIVE) {
        handleExclusiveSearchListId((filter) => {
          if (filter) fetchDiamondList(payload, [filter], setResponse);
        });
      } else if (pathname === LISTINGPAGES.COLLECTION) {
        handleCollectionId((filter) => {
          if (filter) fetchDiamondList(payload, [{ diamondSearchId: filter }], setResponse);
        });
      } else if (pathname === LISTINGPAGES.NOTE) {
        fetchNotes(payload, setResponse);
      } else if (pathname === LISTINGPAGES.OFFICE) {
        const _payload = { ...payload, status: !isMobile ? payload?.status ?? 1 : officeStatus };
        setPayReq(_payload);
        fetchOfficeList(_payload, setResponse);
      } else if (pathname === LISTINGPAGES.WHITE_SEARCH) {
        handlewhiteSearchList(payload, setResponse);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.page, state.limit, state.sort, searchIds, orderStatus, pathname, officeStatus]
  );

  const handleSort = useCallback(
    (sort) => {
      setState((state) => ({ ...state, sort }));
    },
    [setState]
  );

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ page, limit });
    },
    [setState]
  );

  const onFilterChange = (_id) => {
    setSearchIds((ids) => ids.map((i) => ({ ...i, isActive: i.id === _id })));
    setState({ page: 1 });
  };

  const handleRemoveFilter = (_id) => {
    const _ids = filter(searchIds, (val) => val.id !== _id);
    history.push(`/${LISTINGPAGES.LIST}?${join(map(_ids, "id"), ",")}`);
  };

  useEffect(() => {
    if (isAccount && !isMobile) void onSumChange?.(state.sum, currentType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType, isAccount, state.sum]);

  useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        dispatch({ type: "RESET_SELECTED_ROWS" });
        setState(getState(), false);
      }
      if (props.location.search !== route.search) {
        setState(getState(), false);
      }
    });
    return function cleanup() {
      listener();
    };
  }, [dispatch, history, props.location.pathname, props.location.search, setState]);

  const emptyDataNew = (loading, length) => {
    if (loading) return <NoDataShow message={<Spin />} />;

    if (length === 0) return <NoDataShow />;
    // if (count > 1000)
    //   return (
    //     <div className="empty-data" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //       <div>
    //         <h3>
    //           <span> Please modify your search, maximum 1000 stones can be displayed </span>
    //         </h3>
    //         <div>
    //           <DiamondListingAction
    //             limitOut={true}
    //             ActionFalse={false}
    //             payReq={payReq}
    //             orderThi={pathname === LISTINGPAGES.CONFIRM}
    //             modify={
    //               pathname === LISTINGPAGES.LIST ||
    //               pathname === LISTINGPAGES.UPCOMING ||
    //               pathname === LISTINGPAGES.WHITE_SEARCH ||
    //               pathname === LISTINGPAGES.FANCY_SEARCH ||
    //               // pathname === LISTINGPAGES.MATCHPAIR ||
    //               (location.search && location.search.length)
    //                 ? location.search.split('?').length < 1
    //                 : true
    //             }
    //             onModify={() =>
    //               pathname === LISTINGPAGES.WHITE_SEARCH
    //                 ? history.push(`/${LISTINGPAGES.SEARCH}`)
    //                 : history.push(`/${LISTINGPAGES.SEARCH}/${find(searchIds, { isActive: true }).id}`)
    //             }
    //             trackType={trackTypeObj[invert(LISTINGPAGES)[getPath()]]}
    //             dataLength={
    //               pathname === LISTINGPAGES.OFFICE
    //                 ? state.data.filter((el) => el.isOfficeHeader).length
    //                 : pathname === LISTINGPAGES.CONFIRM
    //                 ? state.data.filter((el) => el.isConfirmHeader).length
    //                 : 0
    //             }
    //             noconfirm
    //             nocart
    //             nowatch
    //             noquote
    //             nonote
    //             nonocompare
    //             nodownload
    //             noprint
    //             noshare
    //             noappointment
    //             nopaginate
    //             {...state}
    //             {...props}
    //             currentType={currentType}
    //             nofinalcalc
    //             clearAll={clearAllSelectedRows}
    //             fetch={fetch}
    //           />
    //         </div>
    //       </div>
    //       ;
    //     </div>
    //   );

    return <></>;
  };

  useEffect(() => {
    const params = props.location?.search?.split?.("?")?.pop?.() ?? "";

    if (isEmpty(params) && pathname === LISTINGPAGES.LIST) {
      history.push(`/${LISTINGPAGES.DASHBOARD}`);
    }

    const searchIds = params
      .split(",")
      .filter(isNotEmpty)
      .map((id, index) => ({ id, isActive: index === 0 }));

    setSearchIds(searchIds);
  }, [history, pathname, props.location?.search]);

  useEffect(() => {
    fetch();
  }, [fetch]);
  const [resetColumns, setResetColumns] = useState(false);

  useEffect(() => {
    fetch([]);
    // getStatus={(status) => {
    //   setPayReq((state) => ({ ...state, status }));
    //   fetch({ status });
    // }}
  }, [resetColumns]);

  const getSelection = React.useCallback(() => {
    if (isAccount && !isMobile) return null;

    return (
      <SelectStone
        sum={state.sum}
        profilListing={isAccount ? "profileStonSelect" : "diamondListStone"}
        currentType={currentType}
      />
    );
  }, [isAccount, isMobile, state.sum, currentType]);

  const getListTop = () => {
    return (
      <ListingTop
        quickSearch={pathname === LISTINGPAGES.LIST}
        confirmList={pathname === LISTINGPAGES.CONFIRM}
        officeList={pathname === LISTINGPAGES.OFFICE}
        getStatus={(status) => {
          setPayReq((state) => ({ ...state, status }));
          fetch({ status });
        }}
        modify={
          [LISTINGPAGES.LIST, LISTINGPAGES.UPCOMING, LISTINGPAGES.OFFICE].includes(getPath()) ||
          (location.search && location.search.length)
            ? location.search.split("?").length < 1
            : true
        }
        onModify={() => history.push(`/${LISTINGPAGES.SEARCH}${searchId.current ? `/${searchId.current}` : ""}`)}
        listGridIcon={location.pathname.split("/")[1] === "account" ? null : true}
        Cart={pathname === LISTINGPAGES.CART || pathname === LISTINGPAGES.WATCHLIST ? true : null}
        onClick={() => setListView((v) => !v)}
        image={listView ? gridSvg : listSvg}
        filterTop={location.pathname.split("/")[1] === "account" ? null : MULTIPLE_SEARCH && searchIds.length}
        handleRemoveFilter={handleRemoveFilter}
        handleFilterChange={onFilterChange}
        ids={searchIds}
        resetColumns={resetColumns}
        setResetColumns={setResetColumns}
      />
    );
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        nonocompare={USER_PERMIT && USER_PERMIT.COMPARE && !USER_PERMIT.COMPARE.insert}
        payReq={payReq}
        orderThi={pathname === LISTINGPAGES.CONFIRM}
        modify={
          pathname === LISTINGPAGES.LIST ||
          pathname === LISTINGPAGES.UPCOMING ||
          pathname === LISTINGPAGES.WHITE_SEARCH ||
          pathname === LISTINGPAGES.FANCY_SEARCH ||
          (location.search && location.search.length)
            ? location.search.split("?").length < 1
            : true
        }
        onModify={() =>
          pathname === LISTINGPAGES.WHITE_SEARCH
            ? history.push(`/${LISTINGPAGES.SEARCH}`)
            : pathname === LISTINGPAGES.FANCY_SEARCH
            ? history.push(`/${LISTINGPAGES.SEARCH}`, { isFcCol: true })
            : history.push(`/${LISTINGPAGES.SEARCH}/${find(searchIds, { isActive: true }).id}`)
        }
        trackType={trackTypeObj[invert(LISTINGPAGES)[getPath()]]}
        dataLength={
          pathname === LISTINGPAGES.OFFICE
            ? state.data.filter((el) => el.isOfficeHeader).length
            : pathname === LISTINGPAGES.CONFIRM
            ? state.data.filter((el) => el.isConfirmHeader).length
            : 0
        }
        {...state}
        {...props}
        currentType={currentType}
        nowatch={
          (USER_PERMIT && USER_PERMIT.WATCHLIST && !USER_PERMIT.WATCHLIST.view) ||
          pathname === LISTINGPAGES.WATCHLIST ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.UPCOMING
        }
        nocart={
          (USER_PERMIT && USER_PERMIT.CART && !USER_PERMIT.CART.view) ||
          pathname === LISTINGPAGES.CART ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.UPCOMING
        }
        delete={
          (USER_PERMIT && USER_PERMIT.CART && USER_PERMIT.CART.delete && pathname === LISTINGPAGES.CART) ||
          (USER_PERMIT &&
            USER_PERMIT.WATCHLIST &&
            USER_PERMIT.WATCHLIST.delete &&
            pathname === LISTINGPAGES.WATCHLIST) ||
          (USER_PERMIT && USER_PERMIT.NOTES && USER_PERMIT.NOTES.delete && pathname === LISTINGPAGES.NOTE) ||
          (USER_PERMIT && USER_PERMIT.OFFICE && USER_PERMIT.OFFICE.delete && pathname === LISTINGPAGES.OFFICE)
        }
        noconfirm={
          (USER_PERMIT && USER_PERMIT.CONFIRM_STONE && !USER_PERMIT.CONFIRM_STONE.view) ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.UPCOMING
        }
        noappointment={
          (USER_PERMIT && USER_PERMIT.OFFICE && !USER_PERMIT.OFFICE.view) ||
          pathname === LISTINGPAGES.OFFICE ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.UPCOMING
        }
        nonote={
          (USER_PERMIT && USER_PERMIT.NOTES && !USER_PERMIT.NOTES.view) ||
          pathname === LISTINGPAGES.NOTE ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.UPCOMING
        }
        noquote={
          (USER_PERMIT && USER_PERMIT.QUOTE && !USER_PERMIT.QUOTE.view) ||
          pathname === LISTINGPAGES.QUOTE ||
          pathname === LISTINGPAGES.CONFIRM ||
          pathname === LISTINGPAGES.UPCOMING
        }
        update={
          pathname === LISTINGPAGES.REMINDER ||
          (USER_PERMIT && USER_PERMIT.NOTES && USER_PERMIT.NOTES.update && pathname === LISTINGPAGES.NOTE)
        }
        nofinalcalc
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        noshare={
          USER_PERMIT &&
          !USER_PERMIT.SHARE_VIA_MAIL.view &&
          !USER_PERMIT.SHARE_VIA_WHATSAPP.view &&
          !USER_PERMIT.SHARE_VIA_SKYPE.view
        }
        nodownload={USER_PERMIT && USER_PERMIT.DOWNLOAD && !USER_PERMIT.DOWNLOAD.view}
        noprint={USER_PERMIT && USER_PERMIT.PRINT && !USER_PERMIT.PRINT.view}
        exportexcel
      />
    );
  };

  const getGridView = () => {
    return (
      <>
        <DiamondGridView data={state.data} loading={state.loading} currentType={currentType} />
        {emptyDataNew(state.loading, state.data.length)}
      </>
    );
  };

  const getTitle = () => {
    return (
      <>
        {TITLE[getPath()]} {`( ${formatNumber(state.count)} )`}
      </>
    );
  };

  React.useEffect(() => {
    return stagePopoverSelectAction.listen(async (event, { data }) => {
      const page = getPath();
      const pagesAllowed = [
        LISTINGPAGES.LIST,
        LISTINGPAGES.FANCY_SEARCH,
        LISTINGPAGES.EXCLUSIVE,
        // LISTINGPAGES.COLLECTION,
      ];
      if (!pagesAllowed.includes(page)) return;

      const searchIdFound = window?.location?.search?.split("?")?.pop();

      const filterFound = await SearchService.getFilterFromId(searchIdFound);
      const lastFilter = Storage.get("last-filter") ?? undefined;

      const filter = isEmpty(searchIdFound) ? lastFilter : filterFound;
      const id = await SearchService.getIdFromFilter({ ...filter, wSts: data });

      history.push(`/${LISTINGPAGES.LIST}?${id}`);
    });
  }, [history]);

  return (
    <>
      {isMobile && Number(state.count) <= 1000 ? (
        <MobileViewList
          mathPair={pathname === LISTINGPAGES.MATCHPAIR}
          showStatus={pathname === LISTINGPAGES.CONFIRM}
          status={pathname === LISTINGPAGES.OFFICE}
          {...props}
          {...state}
          listView={listView}
          currentType={currentType}
          parent={{ getTitle, getListTop, getSelection, getGridView, getAction, setOrderStatus, setOfficeStatus }}
        />
      ) : isAccount ? (
        <>
          <div className="position-relative">{getSelection()}</div>
          {[LISTINGPAGES.CONFIRM, LISTINGPAGES.OFFICE].includes(getPath()) &&
            !(pathname === LISTINGPAGES.CONFIRM) &&
            getListTop()}
          {pathname === LISTINGPAGES.CONFIRM && (
            <div className="searchInnerFilterMain">
              <Select
                className="updateStausBlock md-sm-size"
                placeholder="Please select"
                value={orderStatus}
                onChange={setOrderStatus}
                style={{ marginBottom: 10 }}
              >
                <Select.Option value={1}>
                  <IntlMessage id="app.pending" />
                </Select.Option>
                <Select.Option value={2}>
                  <IntlMessage id="app.approved" />
                </Select.Option>
                <Select.Option value={3}>
                  <IntlMessage id="app.rejected" />
                </Select.Option>
              </Select>
            </div>
          )}
          <div className="searchResultListWrapper profilePage">
            <div className="searchInnerResult">
              <GridHeightContainer
                account={
                  [LISTINGPAGES.CART, LISTINGPAGES.WATCHLIST, LISTINGPAGES.NOTE].includes(getPath())
                    ? 49
                    : pathname === LISTINGPAGES.OFFICE
                    ? 100
                    : [LISTINGPAGES.CONFIRM, LISTINGPAGES.QUOTE].includes(getPath())
                    ? 93
                    : 0
                }
                className="searchResultTable profilePageTable"
                subtractFrom=".HeaderSticky,.dashboardQuickSearch,.selectStoneHeader,.mobileSearchBottom,.diamondListingAction,.accountTabBox"
                adjustment={-10}
              >
                <Table
                  showSts={pathname === LISTINGPAGES.CONFIRM}
                  data={state.data}
                  loading={state.loading}
                  ref={tableRef}
                  currentType={currentType}
                  defaultChecked={state.defaultChecked}
                  handleSort={handleSort}
                  sort={state.sort}
                  detailRemoveFromOrderList={props.detailRemoveFromOrderList}
                />
              </GridHeightContainer>
              {getAction()}
            </div>
          </div>
        </>
      ) : (
        <div className="searchResultListWrapper">
          {!isMobile ? <QuickSearch dataFilter={state.filterArrayData} diamondList /> : ""}
          <div className="selectStoneHeader">
            <div
              style={{ overflow: "auto" }}
              className="d-flex justify-content-between align-items-center width-100 listingTopBlock"
            >
              {Number(state.count) <= 1000 && (
                <>
                  <Heading className="mb-0 ms-4">{getTitle()}</Heading>
                  {getSelection()}
                  {getListTop()}
                </>
              )}
            </div>
          </div>
          <div className="searchInnerResult">
            {listView ? (
              <GridHeightContainer
                className="searchResultTable"
                subtractFrom=".HeaderSticky,.dashboardQuickSearch,.selectStoneHeader,.mobileSearchBottom,.diamondListingAction,.accountTabBox"
                adjustment={-10}
              >
                {Number(state.count) <= 1000 && (
                  <Table
                    showSts={pathname === LISTINGPAGES.CONFIRM}
                    data={state.data}
                    loading={state.loading}
                    ref={tableRef}
                    handleSort={handleSort}
                    sort={state.sort}
                    currentType={currentType}
                    defaultChecked={state.defaultChecked}
                  />
                )}
                {emptyDataNew(state.loading, state.data.length)}
              </GridHeightContainer>
            ) : (
              <GridHeightContainer
                className="searchResultGridView"
                subtractFrom=".HeaderSticky,.dashboardQuickSearch,.selectStoneHeader,.mobileSearchBottom,.diamondListingAction,.accountTabBox"
                adjustment={-10}
              >
                {/* {getGridView()} */}
                {Number(state.count) <= 1000 && getGridView()}
              </GridHeightContainer>
            )}
            {getAction()}
          </div>
        </div>
      )}
      {state.needLogout && (
        <>
          {UtilService.getLocalStorageItem(
            window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
          ) ? (
            <GuestUserPopup
              noCancel
              visible
              register={() => {
                window.open("/signup", "_self");
                window.localStorage.clear();
                window.sessionStorage.clear();
              }}
            />
          ) : (
            <NoKycPop handleCancel={() => {}} visible search />
          )}
        </>
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect((state) => ({ commonData: state.commonData }))
)(DiamondList);
