import React from "react";

import moment from "moment";

import { handleAddDemand } from "../../containers/APIFunctions";
import CommonModal from "../common/CommonModal";
import DatePicker from "../common/DatePicker";
import InputBlock from "../common/InputBlock";

const AddDemandPopup = (props) => {
  return (
    <>
      <CommonModal
        visible={props.showDemand}
        handleOk={() =>
          handleAddDemand(props.selectedMaster, props.demandTitle, props.expiryDate, (success) => {
            if (success) {
              props.handleReverseState("showDemand");
              props.handleRefresh();
            }
          })
        }
        handleCancel={() => props.handleReverseState("showDemand")}
        title="Add Demand"
        footerShow
        submitTitle="Submit"
        cancelTitle="Cancel"
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue">
            <InputBlock
              label="Demand Title"
              placeholder="Demand Title"
              value={props.demandTitle}
              onChange={(e) => props.handleChange("demandTitle", e.target.value)}
            />
            <DatePicker
              label="Select Date"
              {...props}
              handleChange={(dt) => props.handleChange("expiryDate", dt ? dt.toISOString() : "")}
              disabledDate={(current) => {
                return current && current <= moment().startOf("day");
              }}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AddDemandPopup;
