import React, { Component } from "react";

import { HeadingCalc } from "./ConfirmStonePopup";

import Heading from "../Heading";
import TextArea from "../TextArea";
// import MobileViewListCard from '../MobileDiamondList/MobileViewListCard';
// import { Drawer } from 'antd';

class NotePopupMobile extends Component {
  render() {
    return (
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title="Add Note" />
          </div>
          {HeadingCalc(this.props.checked)}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <div className="offerInputBox width-100">
              <TextArea
                className="width-100"
                label="Comment"
                placeholder="Comment"
                value={this.props.data[0] ? this.props.data[0].note : ""}
                onChange={(e) => this.props.parent.ipValChange(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="sideBarPopupButton">
          <span className="commonButton" onClick={this.props.parent.submit}>
            Add Note
          </span>
          <span className="commonOutline" onClick={this.props.onClose}>
            Cancel
          </span>
        </div>
      </div>
    );
  }
}

export default NotePopupMobile;
