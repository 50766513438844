import React from "react";

import PropTypes from "prop-types";

import { blurOnEnter, formatDecimal, isFunction, isNumber, isNumeric, parseDecimal } from "util/utils";

import plusSVG from "assets/img/minus.jpg";
import minusSVG from "assets/img/plus.jpg";

function CellInput(props) {
  const {
    onChange,
    args,
    showDecrButton,
    showIncrButton,
    diffInterval,
    transformInput,
    minInterval,
    maxInterval,
    ..._props
  } = props;

  const [value, setValue] = React.useState(isNumeric(props.value, true) ? formatDecimal(props.value) : "");

  const handleBlur = React.useCallback(async () => {
    const _value = Number(value);
    onChange(
      isNumber(_value)
        ? isFunction(transformInput)
          ? transformInput(_value, diffInterval, props.baseDiscount, {
              minInterval,
              maxInterval,
            })
          : _value
        : undefined,
      args
    );
    setValue(null);
  }, [args, diffInterval, onChange, transformInput, value, minInterval, maxInterval]);

  const handleChange = React.useCallback((e) => {
    void e?.preventDefault?.();
    const value = e?.target?.value ?? e;
    if (isNumeric(value)) setValue(value);
  }, []);

  const increaseValue = React.useCallback(() => {
    onChange(parseDecimal(value) + diffInterval, args);
    setValue(null);
  }, [onChange, value, args, diffInterval]);

  const decreaseValue = React.useCallback(() => {
    onChange(parseDecimal(value) - diffInterval, args);
    setValue(null);
  }, [onChange, value, args, diffInterval]);

  React.useEffect(() => {
    setValue((value) =>
      value === null && value !== props.value ? (isNumeric(props.value, true) ? formatDecimal(props.value) : "") : value
    );
  }, [props.value, value]);

  return (
    <>
      {showDecrButton && <img src={plusSVG} onClick={decreaseValue} width="15px" alt="minus" />}
      &nbsp;
      <input {..._props} onKeyPress={blurOnEnter} onChange={handleChange} onBlur={handleBlur} value={value} />
      &nbsp;
      {showIncrButton && <img src={minusSVG} onClick={increaseValue} width="15px" alt="plus" />}
    </>
  );
}

CellInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default React.memo(CellInput);
