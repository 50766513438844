import React from "react";

import { Tooltip } from "antd";

import IntlMessage from "util/IntlMessage";
import "./default.less";

import shareSVG from "../../../assets/svg/Header/share.svg";
import userwhiteSVG from "../../../assets/svg/Header/user-white.svg";

const UserProfileDefault = (props) => {
  return (
    <div className={`UserProfileDefault ${props.size}`}>
      <span>
        {props.social ? (
          <img src={shareSVG} alt="" />
        ) : (
          <Tooltip arrow title={<IntlMessage id="app.myAccount" />} placement="bottom">
            <img src={userwhiteSVG} alt="" />
          </Tooltip>
        )}
      </span>
    </div>
  );
};
export default UserProfileDefault;
