import { createBrowserHistory } from "history";

import { runInDevelopment } from "./utils";

export const history = createBrowserHistory();

const addGlobal = () => {
  runInDevelopment(() => {
    window.__history = history;
  });
};

addGlobal();
