import React from "react";

import { Popover } from "antd";
import PropTypes from "prop-types";

import { Action } from "services/action";

import { isFunction } from "util/utils";

import { STATUS_INDICATION_LIST } from "constants/Common";

import statusSvg from "./status.svg";

const actions = {
  selected: new Action("stage-selected"),
};

export const stagePopoverSelectAction = actions.selected;

const StagePopoverContent = React.memo((props) => {
  const { onClick, page } = props;
  const STATUS_INDICATION = page ? STATUS_INDICATION_LIST[page] : STATUS_INDICATION_LIST.SEARCH;

  const handleClick = React.useCallback(
    (status) => {
      if (isFunction(onClick)) onClick(status);
      actions.selected.emit(status);
    },
    [onClick]
  );

  return (
    <div className="d-flex flex-wrap filterDropdown">
      {Object.keys(STATUS_INDICATION).map((status, index) => {
        return (
          <span
            key={index}
            className="showSatusBlock"
            style={{ minWidth: "180px" }}
            onClick={() => handleClick(status)}
          >
            <div style={{ backgroundColor: STATUS_INDICATION[status].color }} className="SelectcolorWiseList" />
            <span>{[status, STATUS_INDICATION[status].name].join(" - ")}</span>
          </span>
        );
      })}
    </div>
  );
});
StagePopoverContent.displayName = "StagePopoverContent";

export const getStagePopoverContent = (onClick, page) => <StagePopoverContent onClick={onClick} page={page} />;

const StagePopover = React.memo((props) => {
  const { onClick, page } = props;

  const popoverContent = React.useMemo(() => {
    return <StagePopoverContent onClick={onClick} page={page} />;
  }, [onClick, page]);

  return (
    <Popover content={popoverContent} placement="bottomRight" overlayStyle={{ width: "570px" }}>
      <div className="filterOption">
        <img style={{ width: "12px", height: "12px" }} src={statusSvg} alt="" />
      </div>
    </Popover>
  );
});
StagePopover.displayName = "StagePopover";

StagePopover.propTypes = {
  onClick: PropTypes.func,
  page: PropTypes.string,
};

export default StagePopover;
