import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SpeechRecognition from "react-speech-recognition";

import { Input } from "antd";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import split from "lodash/split";

import IntlMessage from "util/IntlMessage";

import muteSVG from "./mute.svg";

import closeSVG from "../../../assets/svg/Header/close.svg";
import searchSVG from "../../../assets/svg/Header/search.svg";
import { LOCAL_STORAGE_VAR, PAGES } from "../../../constants/Common";
import { SEARCH_DIAMOND_LIST } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
// import { LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
// import { PAGES } from 'constants/Common';

const LISTINGPAGES = PAGES;
const { Search } = Input;

const HeaderSearch = (props) => {
  const history = useHistory();
  const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState({});
  const [recordOn, setRecordOn] = useState(false);
  let support = true;

  const handleSearchChange = (e) => {
    let value = e.target.value.toLowerCase();
    let searched = [];
    const splitted = value ? split(value, " ") : [];

    if (!isEmpty(splitted)) value = last(splitted);

    if (value) {
      const others = [];
      const savedSearch = [];
      Object.keys(masterData).forEach((m) => {
        masterData[m].forEach((dt) => {
          if (dt.name.toLowerCase().includes(value)) {
            others.push({ label: dt.name, type: "OTHER" });
          }
        });
      });

      props.searchList.forEach((sl) => {
        if (sl.name.toLowerCase().includes(value)) {
          savedSearch.push({ label: sl.name, type: "SAVEDSEARCH", id: sl.id });
        }
      });

      searched = concat(others.slice(0, 5), savedSearch.slice(0, 5));
    } else {
      searched = [];
    }

    setSearchInput({ str: e.target.value, type: "OTHER" });
    setSearchData(searched);
  };

  const handleSearch = () => {
    if (searchInput.type === "OTHER") {
      const obj = {
        search: searchInput.str,
        isReturnCountOnly: true,
        isNotReturnTotal: true,
        isDirectSearch: true,
        page: 1,
        limit: 100,
      };
      const objData = {
        ...SEARCH_DIAMOND_LIST,
        request: obj,
      };
      UtilService.callApi(objData, (err, data) => {
        if (err) throw err;
        if (data && data.code === "OK") {
          history.push(`/${LISTINGPAGES.LIST}?${data.data[0].filter.id}`);
          setSearchInput({});
          props.onClick();
        }
      });
    }
    if (searchInput.type === "SAVEDSEARCH") {
      history.push(`/${LISTINGPAGES.LIST}?${searchInput.id}`);
      setSearchInput({});
      props.onClick();
    }
  };

  const catchFunc = (func) => {
    setSearchInput({ str: props.transcript, type: "OTHER" });
    props.abortListening();
    func();
  };

  const stop = (func, reset) => {
    setRecordOn(false);
    catchFunc(func, reset);
    if (!isEmpty(searchInput)) handleSearch();
  };

  const start = (func) => {
    setRecordOn(true);
    setSearchInput({ str: "", type: "OTHER" });
    func();
  };
  const { resetTranscript, browserSupportsSpeechRecognition, startListening, stopListening } = props;
  if (!browserSupportsSpeechRecognition) {
    support = false;
  }

  useEffect(() => {
    if (props.transcript !== "") {
      setSearchInput({ str: props.transcript, type: "OTHER" });
    }
  }, [props.transcript]);
  return (
    <div className={`searchWrapper ${props.className}`}>
      <div className="searchWrapperInner">
        <div className="searchBox d-flex align-items-center">
          <span className="searchIcon">
            <img src={searchSVG} alt="" />
          </span>
          <IntlMessage id="app.Search">
            {(placeholder) => (
              <Search
                value={searchInput.str}
                placeholder={placeholder}
                onChange={handleSearchChange}
                suffix={false}
                onPressEnter={handleSearch}
              />
            )}
          </IntlMessage>

          {support && (
            <>
              {!recordOn ? (
                <span
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  className="searchIcon"
                  onClick={() => {
                    // resetTranscript();
                    start(startListening);
                  }}
                />
              ) : (
                <span
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  className="searchIcon"
                  onClick={() => {
                    props.abortListening();
                    stop(stopListening, resetTranscript);
                  }}
                >
                  <img src={muteSVG} className="voiceSearchImg" alt="" />
                </span>
              )}
            </>
          )}

          <span
            className="searchIconClose"
            onClick={() => {
              setSearchInput({});
              setSearchData([]);
              setRecordOn(false);
              props.onClick();
            }}
          >
            <img src={closeSVG} alt="" />
          </span>
        </div>
        {!isEmpty(searchData) && (
          <div className="searchDropdown">
            <h2>
              <IntlMessage id="app.SearchRecent" />
            </h2>
            <ul>
              {searchData.map((s, i) => (
                <li
                  key={i}
                  onClick={() => {
                    const {str} = searchInput;
                    let res = str ? str.split(" ") : [];
                    res.pop();
                    res.push(s.label);
                    res = res.join(" ");
                    setSearchInput({ str: res, type: s.type, id: s.id });
                  }}
                >
                  {s.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const options = {
  autoStart: false,
  continuous: false,
};
export default SpeechRecognition(options)(HeaderSearch);
