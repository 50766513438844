export const TABLE_PAGE_LIMIT = 100;
export const FRACTION_LENGTH = 2;
export const MOBILE_MAX_WIDTH = 767;
export const TABLET_MAX_WIDTH = 991;

export const LOCALE = "en-US";
export const CURRENCY = "USD";
export const CURRENCY_SYMBOL = "$";

export const TIMEZONE_IANA = "Asia/Kolkata";
export const LUXON_FORMAT = { DATE: "dd/LL/y", TIME: "hh:mm a", DATE_TIME: "dd/LL/y hh:mm a", DURATION: "hh:mm:ss" };
export const REGEX = { NUMERIC: { STRICT: /^[+-]?[\d]+[.]?[\d]*$/gm, LOOSE: /^[+-]?[\d]*[.]?[\d]*$/gm } };

export const BASE_URL = process.env.REACT_APP_API_URL;

export const MEDIA_URL = "https://s3.ap-south-1.amazonaws.com/finestargroup";

export const BASE_DOWNLOAD_URL = process.env.REACT_APP_ASSETS_URL; // FOR HEROKU
// export const BASE_DOWNLOAD_URL = `https://assets.kgk.live/`; // FOR AWS INFRA
export const BASE_URL_ATTACH = BASE_URL;
export const LOCAL_STORAGE_VAR = "kgk";

export const SEARCH_TAGS = true;
export const MULTIPLE_SEARCH = true;
export const QUICK_SEARCH = true;

export const LAB_LINKS = {
  IGI: "https://igi.org/verify.php?r=***",
  GSI: "http://wg.gemscience.net/vr/veri.aspx",
  GIA: "https://www.gia.edu/report-check?reportno=***",
  HRD: "https://my.hrdantwerp.com/?id=34&record_number=***",
};

export const PAGES = {
  LIST: "diamond-list",
  UPCOMING: "upcoming",
  DASHBOARD: "dashboard",
  SEARCH: "diamond-search",
  FANCY_SEARCH: "fancy-search",
  CART: "cart",
  WATCHLIST: "watchlist",
  REMINDER: "reminder",
  CONFIRM: "order-list",
  BID: "bid-to-buy",
  QUOTE: "offer-list",
  STONE_OF_DAY: "stone-of-the-day",
  NEWARR: "new-arrival",
  MATCHPAIR: "match-pair",
  MYBID: "my-bid",
  EXCLUSIVE: "kgk-exclusive",
  NOTE: "notes",
  OFFICE: "view-diamonds",
  DETAIL: "diamond-detail",
  DETAILS: "diamond-detail",
  // COLLECTION: 'kgk-collection',
  COLLECTION: "offerlist",
  WHITE_SEARCH: "white-search",
};

export const KYC_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECT: 3,
};

export const SEARCH_ALL_CONST = ["SHAPE", "CARAT", "COLOR", "CLARITY", "CUT"];

export const NOTIFY_REDIRECT = {
  OFFER: "offer",
  CART: "cart",
  WATCHLIST: "watchlist",
  ORDER: "order-place",
  ENQUIRY: "enquiry",
  REQUEST: "request",
  OFFICE: "Appointment",
  COMMENT: "comment",
};

export const NOTIFY_STATUS = { TODAY: "Today", YESTERDAY: "Yesterday", PAST: "Past" };

export const FILE_URLS = {
  // img: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/imaged/***/still.jpg',
  img: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/V360Mini5/imaged/***/still.jpg",
  certFile: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Cert/***.jpeg",
  side: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Side/***.jpeg",
  front: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Front/***.jpeg",
  plotCerti: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Plot/***PL.jpg",
  propCerti: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/Prop/***PR.jpg",
  // videoFile: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/Vision360.html?d=***&autoPlay=1",
  videoFile: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/V360Mini5/Vision360.html?d=***&autoPlay=1",
  // mp4video: 'https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/imaged/***/video.mp4',
  mp4video: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/V360Mini5/imaged/***/video.mp4",
  hAFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/HeartImages/***.jpg",
  arrowFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/ArrowImages/***.jpg",
  assetFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/AssetScopeImages/***.jpg",
  // mainImage: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/VISION360/imaged/***/still.jpg",
  mainImage: "https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/V360Mini5/imaged/***/still.jpg",
};

export const STATUS_INDICATION_LIST = {
  SEARCH: {
    A: { name: "Available", color: "#6bc950", hover: "Available" },
    M: { name: "Memo", color: "#307bea", salesperson: true, date: true, time: true },
    H: { name: "Hold", color: "#db1c1c", salesperson: true, date: true, time: true },
    U: { name: "Upcoming", color: "#acdb1c", salesperson: true, date: true, time: true },
    // B: { name: 'Bid', color: '#1cdbc1', salesperson: true, date: true, time: true },
    C: { name: "Confirm", color: "#ffcc00", salesperson: true, date: true, time: true },
    // C: { name: 'Confirm', color: '#457c22', salesperson: true, date: true, time: true },
    E: { name: "Exclusive", color: "#ff9100", salesperson: true, date: true, time: true },
  },
};

export const bussinessTypeArray = {
  "Independent Jeweler": "Independent Jeweler",
  "Online Jewelry Store": "Online Jewelry Store",
  "Diamond Dealer/ Broker": "Diamond Dealer/ Broker",
  "Diamond Manufacturer / Cutter": "Diamond Manufacturer / Cutter",
  "Jewelry Manufacturer": "Jewelry Manufacturer",
  "Jewelry Retail Chain": "Jewelry Retail Chain",
  "Pawn shop": "Pawn shop",
  Appraiser: "Appraiser",
  Designer: "Designer",
  "Not in the Diamond / Jewelry Trade": "Not in the Diamond / Jewelry Trade",
  "Gold Buyer": "Gold Buyer",
  "Diamond Mining": "Diamond Mining",
  Auctioneer: "Auctioneer",
  "Trade Association": "Trade Association",
  "Watch Dealer": "Watch Dealer",
  "Finance / Banking": "Finance / Banking",
  Investor: "Investor",
  "Jewelry Appraiser": "Jewelry Appraiser",
  "Jewelry Retailer": "Jewelry Retailer",
  "Diamond and Pearl Dealer / Broker": "Diamond and Pearl Dealer / Broker",
  "Colored Stone Dealer / Broker": "Colored Stone Dealer / Broker",
  "Estate Jewelry Dealer / Broker": "Estate Jewelry Dealer / Broker",
  Other: "Other",
};

export const ADDRESS_TYPES = ["HOME", "ACCOUNT", "BUYER", "SHIPPING", "BILLING", "OTHER"];

export const page = { path: "/search-result", component: "DiamondSearchGrid" };

export const reference = [
  "Select Reference",
  "Sales Person",
  "Third Party Reference",
  "RapNet",
  "VDB App",
  "LinkedIn",
  "WhatsApp",
  "Facebook",
  "Instagram",
  "Google",
  "WeChat",
  "Twitter",
  "QQ",
  "News",
  "Visit",
  "Walk In",
  "Other",
];

export const authorizedPages = [
  "diamond-search",
  "fancy-search",
  "quick-search",
  "search-result",
  "match-pair",
  "parcel-search",
  "parcel-list",
  "upcoming-diamonds,",
  "compare",
  "selected-stones",
  "dashboard",
  "my-cart",
  "my-tracked-diamonds",
  "my-reminder",
  "my-comment",
  "my-enquiry",
  "price-calculator",
  "my-account",
  "appointment-history",
  "my-demand",
  "my-saved-search",
  "change-password",
  "settings",
  "company-details",
  "address-book",
  "change-email-address",
  "invoice",
  "best-of-KGK",
  "new-diamonds",
  "customer-care/:name",
  "matchpair-settings",
  "my-order",
  "recommended",
  "customer-care/appointment",
  "upload-diamonds",
  "upload-images",
  "upload-certificates",
  "diamond-history",
  "people",
  "manage-diamonds",
  "uploaded-sheet-details",
  "feedback",
  "thankyou-for-feedback",
  "new-arrival",
];

export const myAccount = {
  personalDetails: { key: "my-account", label: "Personal Details", logo: "personal-detail.svg" },
  companyDetails: { key: "company-details", label: "Company Details", hideType: [3], logo: "company-detail.svg" },
  userDetails: { key: "user-details", label: "User Details", hideType: [3], logo: "user-detail.svg" },
  settings: { key: "settings", label: "Settings", logo: "setting.svg" },
  changePswd: { key: "change-password", label: "Change Password", logo: "change-password.svg" },
  otherDetails: { key: "other-details", label: "Other Details", hideType: [3], logo: "otherDetail.svg" },
  manufacturingDetails: {
    key: "manufacturing-details",
    label: "Manufacturing Facility Details",
    hideType: [3],
    logo: "manufacturing.svg",
  },
};

export const diamondParameters = [
  { key: "shp", name: "Shape", master: "SHAPE" },
  { key: "col", name: "Color", master: "COLOR" },
  { key: "clr", name: "Clarity", master: "CLARITY" },
  { key: "flu", name: "Fluorescence", master: "FLUORESCENCE" },
  { key: "shd", name: "Tinge", master: "SHADE" },
  { key: "lb", name: "Lab", master: "LAB" },
  { key: "cut", name: "Cut", master: "CUT" },
  { key: "pol", name: "Polish", master: "POLISH" },
  { key: "sym", name: "Symmetry", master: "SYMMETRY" },
  { key: "loc", name: "Location" },
  { key: "inten", name: "Intensity", master: "INTENSITY" },
  { key: "ovrtn", name: "Overtone", master: "OVERTONE" },
  { key: "blkInc", name: "Black Table", master: "BLACK_INCLUSION" },
  { key: "eCln", name: "Eye Clean", master: "EYECLEAN" },
  { key: "mlk", name: "Milky", master: "MILKEY" },
  { key: "hA", name: "Heart and Arrow", master: "H_AND_A" },
  { key: "rap", name: "Rap Discount" },
  { key: "ctPr", name: "Price/Carat" },
  { key: "amt", name: "Amount" },
  { key: "depPer", name: "Depth%" },
  { key: "tblPer", name: "Table%" },
  { key: "grdl", name: "Girdle" },
  { key: "pAng", name: "Pavilion Angle" },
  { key: "cAng", name: "Crown Angle" },
  { key: "cHgt", name: "Crown Height" },
  { key: "pHgt", name: "Pavilian Depth" },
  { key: "dmtrMn", name: "Diameter Min" },
  { key: "dmtrMxn", name: "Diameter Max" },
  { key: "or", name: "Carat", master: "CARAT" },
  { key: "ratio", name: "Ratio" },
  { key: "showSrNo", name: "Show ID" },
  { key: "kToSArr", name: "Key To Symbol", master: "KEY_TO_SYMBOLS" },
  { key: "fcCol", name: "Fancy Color" },
  { key: "grdlCond", name: "Girdle Condition" },
  { key: "cultCond", name: "Culet Condition" },
  { key: "cult", name: "Culet" },
  { key: "stoneId", name: "Stone ID/ Report Number" },
  { key: "vnd", name: "Seller Name" },
  { key: "vStnId", name: "Seller Stone Number" },
  { key: "length", name: "Length" },
  { key: "width", name: "Width" },
  { key: "height", name: "Depth" },
  { key: "pairStkNo", name: "Pair No" },
  { key: "isSeal", name: "Seal" },
  { key: "brlncy", name: "Brilliance" },
  { key: "isDor", name: "DOR" },
  { key: "isFm", name: "FM" },
  { key: "blkTbl", name: "Cenetr Natts" },
  { key: "blkSd", name: "Side Natts" },
  { key: "wTbl", name: "Center" },
  { key: "wSd", name: "Side" },
  { key: "lwr", name: "Lower Half" },
  { key: "strLn", name: "Star Length" },
  { key: "isXray", name: "Xray" },
  { key: "type2", name: "Type2" },
];

export const TRACK_TYPES = {
  CART: 1,
  WATCHLIST: 2,
  REQUEST: 3,
  QUOTE: 4,
  REMINDER: 5,
  ENQUIRY: 6,
  COMMENT: 99,
  SHIPMENT: 7,
  SHOWSELECT: 8,
};

export const fields = {
  firstName: "First Name",
  lastName: "Last Name",
  name: "Name",
  email: "Email",
  emails: "Email Address",
  mobile: "Mobile No.",
  mobiles: "Mobile No.",
  countryCode: "Countrycode",
  username: "Username",
  password: "Password",
  cpassword: "Confirm Password",
  dob: "Birth Date",
  phone: "Telephone No.",
  companyName: "Company Name",
  designation: "Designation",
  BusinessType: "Business Type",
  address1: "Address Line1",
  address2: "Address Line2",
  country: "Country",
  state: "State",
  city: "City",
  pinCode: "Zip Code",
  fax: "Fax",
  howKnow: "How do you know us?",
  photoId: "Photo Identity Proof",
  anniversary: "Anniversary Date",
  businessId: "Business identity Proof",
  isTermsCondAgree: "Terms & Conditions",
};

export const requiredFields = [
  "firstName",
  "lastName",
  "email",
  "username",
  "password",
  "cpassword",
  "mobile",
  "companyName",
  "BusinessType",
  "address1",
  "country",
  "state",
  "city",
  "pinCode",
  "photoId",
  "businessId",
  "isTermsCondAgree",
];

export const requiredCompanyFields = [
  "companyName",
  "address",
  "landMark",
  "zipCode",
  "businessType",
  "designation",
  "howKnow",
  "country",
  "state",
  "city",
  "faxes",
];

export const requiredaddressField = ["address", "zipCode", "country", "state", "city"];

export const requiredContactFields = ["name", "email", "mobile", "messege"];

export const contactFields = { name: "Name", email: "Email", mobile: "Mobile", messege: "Message" };

export const companyFields = {
  companyName: "Company Name",
  address: "Address",
  landMark: "LandMark",
  zipCode: "ZipCode",
  businessType: "BusinessType",
  designation: "Designation",
  howKnow: "HowKnow",
  country: "Country",
  state: "State",
  city: "City",
  faxes: "Faxes",
};

export const addressField = {
  address: "Address",
  zipCode: "ZipCode",
  country: "Country",
  state: "State",
  city: "City",
};

export const changePswdFields = {
  currentPassword: "Current Password",
  newPassword: "New Password",
  repeatPassword: "Repeat Password",
};

export const requiredChangePswdFields = ["currentPassword", "newPassword", "repeatPassword"];

export const requiredResetPswdFields = ["confirmPassword", "newPassword"];

export const resetPswdFields = ["confirmPassword", "newPassword"];

export const fieldsrequiredGuest = ["name", "companyName", "mobile", "email"];

export const fieldsGuest = {
  name: "Name",
  companyName: "Company Name",
  countryCode: "Countrycode",
  mobile: "Contact No.",
  email: "Email",
};

export const BANNERS_TYPES = {
  HOMEPAGE_TOP: 0,
  HOMEPAGE_SECOND: 1,
  HOMEPAGE_THIRD_LEFT: 2,
  HOMEPAGE_THIRD_RIGHT: 3,
  HOMEPAGE_FOUR: 4,
  HOMEPAGE_BOTTOM: 5,
};

export const BANNER_TYPES = {
  LOGIN_PAGE: 1,
  REGISTER_PAGE: 2,
  FORGOT_PASSWORD_PAGE: 3,
  GUEST_LOGIN: 4,
  CALENDAR: 5,
  DASHBOARD: 6,
};

export const USERS_TYPE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUB_USER: 3,
  PRIMARY: 4,
  PERMANENT_GUEST: 5,
  EMPLOYEE: 6,
  API_USER: 7,
  SELLER: 8,
  CUSTOMER: 9,
  SECONDARY: 10,
  MASTER_SALES: 11,
  VISITOR: 12,
  DUPLICATE: 13,
};

export const quickSearch = [
  {
    allowColors: ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'], // prettier-ignore
    TotalColorsGroups: ["DEF", "GHI", "J-", "FANCY COLOR"],
    TotalClarityGroups: ["IF", "VVS", "VS", "SI", "I", "OTHER"],
    colors: [
      { id: "D", name: "DEF", sequence: 1, innerSequence: 1 },
      { id: "E", name: "DEF", sequence: 1, innerSequence: 2 },
      { id: "F", name: "DEF", sequence: 1, innerSequence: 3 },
      { id: "G", name: "GHI", sequence: 2, innerSequence: 4 },
      { id: "H", name: "GHI", sequence: 2, innerSequence: 5 },
      { id: "I", name: "GHI", sequence: 2, innerSequence: 6 },
      { id: "J", name: "J-", sequence: 3, innerSequence: 7 },
      { id: "K", name: "J-", sequence: 3, innerSequence: 8 },
      { id: "L", name: "J-", sequence: 3, innerSequence: 9 },
      { id: "M", name: "J-", sequence: 4, innerSequence: 10 },
      { id: "N", name: "J-", sequence: 4, innerSequence: 11 },
      { id: "O", name: "J-", sequence: 4, innerSequence: 12 },
      { id: "P", name: "J-", sequence: 4, innerSequence: 13 },
      { id: "Q-R", name: "J-", sequence: 4, innerSequence: 14 },
      { id: "S-T", name: "J-", sequence: 4, innerSequence: 15 },
      { id: "U-V", name: "J-", sequence: 4, innerSequence: 16 },
      { id: "W-X", name: "J-", sequence: 4, innerSequence: 17 },
      { id: "Y-Z", name: "J-", sequence: 4, innerSequence: 18 },
    ],
    clarity: [
      { id: "FL", name: "IF", sequence: 1, innerSequence: 1 },
      { id: "IF", name: "IF", sequence: 1, innerSequence: 2 },
      { id: "VVS1", name: "VVS", sequence: 2, innerSequence: 3 },
      { id: "VVS2", name: "VVS", sequence: 2, innerSequence: 4 },
      { id: "VS1", name: "VS", sequence: 3, innerSequence: 5 },
      { id: "VS1-", name: "VS", sequence: 3, innerSequence: 6 },
      { id: "VS1+", name: "VS", sequence: 3, innerSequence: 7 },
      { id: "VS2", name: "VS", sequence: 3, innerSequence: 8 },
      { id: "VS2-", name: "VS", sequence: 3, innerSequence: 9 },
      { id: "VS2+", name: "VS", sequence: 3, innerSequence: 10 },
      { id: "SI1", name: "SI", sequence: 4, innerSequence: 11 },
      { id: "SI1-", name: "SI", sequence: 4, innerSequence: 12 },
      { id: "SI1+", name: "SI", sequence: 4, innerSequence: 13 },
      { id: "SI2", name: "SI", sequence: 4, innerSequence: 14 },
      { id: "SI2+", name: "SI", sequence: 4, innerSequence: 15 },
      { id: "SI2-", name: "SI", sequence: 4, innerSequence: 16 },
      { id: "SI3", name: "SI", sequence: 4, innerSequence: 17 },
      { id: "SI3-", name: "SI", sequence: 4, innerSequence: 18 },
      { id: "SI3+", name: "SI", sequence: 4, innerSequence: 19 },
      { id: "I1", name: "I", sequence: 5, innerSequence: 20 },
      { id: "I1-", name: "I", sequence: 5, innerSequence: 21 },
      { id: "I1+", name: "I", sequence: 5, innerSequence: 22 },
      { id: "I2", name: "I", sequence: 5, innerSequence: 23 },
      { id: "I2-", name: "I", sequence: 5, innerSequence: 24 },
      { id: "I2+", name: "I", sequence: 5, innerSequence: 25 },
      { id: "I3", name: "I", sequence: 5, innerSequence: 26 },
      { id: "I3-", name: "I", sequence: 5, innerSequence: 27 },
      { id: "I3+", name: "I", sequence: 5, innerSequence: 28 },
    ],
    width: "",
  },
];

export const MonthName = {
  JAN: "JAN",
  FEB: "FEB",
  MAR: "MAR",
  APR: "APR",
  MAY: "MAY",
  JUNE: "JUNE",
  JULY: "JULY",
  AUG: "AUG",
  SEP: "SEP",
  OCT: "OCT",
  NOV: "NOV",
  DEC: "DEC",
  INFO: "INFO",
  SHOW: "SHOW",
};

export const RapPer = [-99, -80, -60, -40, -20, 0, 20, 40, 60, 80, 99];

export const Inventory = (() => {
  const labMaster = localStorage.getItem("fs-master");
  const labGIA = labMaster && JSON.parse(labMaster) && JSON.parse(labMaster).LAB.find((x) => x.code === "GIA");
  return [
    { carat: { ">=": 0.18, "<=": 0.96 }, lab: labGIA && labGIA.id },
    { carat: { ">=": 0.96, "<=": 2 }, lab: labGIA && labGIA.id },
  ];
})();

export const FILE_TYPES = {
  "image/png": ["89504e47"],
  "image/jpeg": ["ffd8ffe0", "ffd8ffe3", "ffd8ffe2", "ffd8dde0"],
  "image/jpg": ["ffd8ffe0", "ffd8ffe8", "ffd8ffe1"],
};

export const FILE_TYPES_PDF = {
  "image/png": ["89504e47"],
  "image/jpeg": ["ffd8ffe0", "ffd8ffe3", "ffd8ffe2", "ffd8dde0"],
  "image/jpg": ["ffd8ffe0", "ffd8ffe8", "ffd8ffe1"],
  "application/pdf": ["25504446"],
};

export const CALENDER_FIELD = [
  { action: "JANUARY", label: "JAN" },
  { action: "FEBRUARY", label: "FEB" },
  { action: "MARCH", label: "MAR" },
  { action: "APRIL", label: "APR" },
  { action: "MAY", label: "MAY" },
  { action: "JUNE", label: "JUN" },
  { action: "JULY", label: "JUL" },
  { action: "AUGUST", label: "AUG" },
  { action: "SEPTEMBER", label: "SEP" },
  { action: "OCTOBER", label: "OCT" },
  { action: "NOVEMBER", label: "NOV" },
  { action: "DECEMBER", label: "DEC" },
  { action: "SHOW", label: "SHOW" },
  { action: "INFO", label: "INFO" },
];

export const DiamondAllotingDate = "April 5,2018";
export const DiamondAssesingDate = "April 4,2018";
export const DiamondGradingDate = "May 22,2018";
export const DiamondOriginDate = "March 21,2018";
export const DiamondPlanningDate = "April 10,2018";
export const DiamondPolishingDate = "April 27,2018";
export const DiamondQualityDate = "May 3,2018";
export const DiamondLaserDate = "April 10,2018";
export const storeSessionUUID = () => {
  const getSessionUUID = localStorage.getItem("fs-uuid");
  if (!getSessionUUID || !getSessionUUID.length) {
    localStorage.setItem("fs-uuid", new Date().getTime());
  }
};
storeSessionUUID();

export const commonLogout = () => {
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-token`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-master`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-column`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-keywords`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-Cart-Counter`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-user`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-tooltip-steps`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-isTrusted`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-uuid`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-terms`);
  localStorage.removeItem(`${LOCAL_STORAGE_VAR}-guest`);
  localStorage.removeItem(`Imtnay1ndWVzdCI=`);
  const tokens = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`));
  if (!tokens) {
    window.location.href = `${window.location.origin}/signin`;
  }
};

export const DASHBOARD_TOOLTIP = [
  { element: ".user", intro: "My Portal" },
  { element: ".lang-steps", intro: "Language DropDown" },
  { element: ".calculator-tooltip", intro: "Calculator" },
  { element: ".notification-tooltip", intro: "View Notification Here" },
  { element: ".cart-tooltip", intro: "Your Cart" },
  { element: ".message-tooltip", intro: "Chat with us" },
  { element: ".sidebar-steps", intro: "Discover Menu" },
  { element: ".search-voice ", intro: "Search diamonds directly" },
];

export const DETAIL_PAGE_TOOLTIP_STEPS = [
  { element: ".packetNo-tooltip", intro: "Diamond Packet-No" },
  { element: ".verify-tooltip", intro: "Verify Certificate" },
  { element: ".x-ray-tooltip", intro: "Share Diamond X-ray" },
  { element: ".whatsapp-tooltip", intro: "Share On Whatsapp" },
  { element: ".send-x-ray-tooltip", intro: "Copy Detail Link" },
  { element: ".download-zip-tooltip", intro: "Download Zip File" },
];

export const SEARCH_RESULT_STEPS = [
  { element: ".add-to-cart", intro: "Add to cart" },
  { element: ".add-watchList", intro: "Add to Tracklist" },
  { element: ".compare", intro: "Compare Diamonds" },
  { element: ".showSelect", intro: "Show Selected Diamonds" },
  { element: ".comment", intro: "Note" },
  { element: ".reminder", intro: "Reminder" },
  { element: ".export-tooltip", intro: "you can Print,Download and Email listed diamond excel" },
  { element: ".filter-tooltip", intro: "You can Apply Filter" },
  { element: ".setting-tooltip", intro: "You can Change Column Settings" },
];

export const DIAMOND_SEARCH_STEPS = [
  { element: ".all-shape", intro: "Select All Criteria" },
  { element: ".advance-tooltip", intro: "Advance Search" },
  { element: ".search-tooltip", intro: "Search Diamond" },
  { element: ".add-more-tooltip", intro: "Add more Search" },
  { element: ".save-tooltip", intro: "You Can Save Your Search" },
  { element: ".demand-tooltip", intro: "You Can add Your demand Diamond Search" },
  { element: ".match-pair-tooltip", intro: "Match Pair" },
  { element: ".match-pair-tooltip", intro: "Match Pair" },
  { element: ".create-excel-tooltip", intro: "You can Create Excel" },
  { element: ".import-excel-tooltip", intro: "You can Import Excel" },
  { element: ".sample-excel-tooltip", intro: "Download Sample" },
  { element: ".reset-filter-tooltip", intro: "Reset Your Filter" },
];

export const SIZEEX = [
  { name: "sizeEx", value: "3EX" },
  { name: "sizeEx", value: "2EX" },
  { name: "sizeEx", value: "2EX" },
  { name: "sizeEx", value: "NO BGM" },
];

export const MOBILE_VIEW_ACTION_BTN = [
  { intelMsg: "add-track", icon: "add-track" },
  { intelMsg: "compare", icon: "compare" },
  { intelMsg: "comment", icon: "comment" },
  { intelMsg: "showSelect", icon: "showSelect" },
  { intelMsg: "print", icon: "print" },
  { intelMsg: "downloadExcel", icon: "downloadExcel" },
];

export const uploadDiamond = {
  actions: { ReplaceAll: 2, AddUpdate: 1 },
  uploadStatus: {
    PENDING: 1,
    PROCESSING: 2,
    COMPLETED: 3,
    FAILED: 4,
    UPLOADING: 5,
    UPLOADING_COMPLETED: 6,
    UPLOADING_FAILED: 7,
    REPROCESSING: 8,
  },
  uploadType: { IMAGE: 1, CERTIFICATE: 2 },
};

export const gridColumns = { GRID_MY_DIAMOND_PAGE_LIST: "GRID_MY_DIAMOND_PAGE_LIST" };

export const ACTIVITIES = {
  "sheet-upload-V1": "Diamond Sheet Upload",
  "sheet-upload-V2": "Diamond Sheet Reload",
  enquiry: "Enquiry Added",
  user: "User Added",
  track: "Diamond Added in Track",
  comment: "Note added on Diamond",
};

export const ACTIVITY_REDIRECTS = {
  "sheet-upload-V1": "/diamond-history",
  "sheet-upload-V2": "/diamond-history",
  enquiry: "/my-enquiry",
  user: "/user-details",
  track: "/my-tracked-diamonds",
};

export const TIME_ZONE_OPTIONS = [
  { name: "Mumbai", timeZone: "IST", offset: 330 },
  { name: "New York", timeZone: "PST", offset: -240 },
  { name: "Antwerp", timeZone: "CEST", offset: 120 },
  { name: "Tel Aviv", timeZone: "IDT", offset: 180 },
  { name: "Hong Kong", timeZone: "HKT", offset: 480 },
  { name: "Shanghai", timeZone: "CST", offset: 480.1 },
  { name: "Dubai", timeZone: "GST", offset: 240 },
  { name: "Tokyo", timeZone: "JST", offset: 540 },
];

export const EVENT_TYPES = { EVENT: "1", NEWS: "2", SHOW: "3", OTHER: "4", CSR: "5" };

export const ADD_REQUEST = "/add-request";
export const DIAMOND_SEARCH = "/diamond-search";
export const MIME_TYPES = {
  "image/png": ["89504e47"],
  "image/jpeg": ["ffd8ffe0", "ffd8ffe1", "ffd8ffee", "ffd8ffdb"],
  "image/jpg": ["ffd8ffe0", "ffd8ffe1", "ffd8ffee", "ffd8ffdb"],
  "application/pdf": ["25504446"],
};

export const DIAMOND_BID = { TYPE: { BLIND: 1, OPEN: 2 }, STATUS: { PENDING: 1, WON: 2, LOSS: 3 } };

export const DIAMOND_FEATURED_GROUP_TYPE = {
  PAIR: "pair",
  STONE: "stone",
  EXCLUSIVE: "exclusive",
  BEST: "best",
  STONE_OF_DAY: "stone_of_day",
};

export const DIAMOND_WEB_STATUS = {
  A: "A",
  M: "M",
  NA: "NA",
  UPCOMING: "U",
  BID: "B",
  SHOW: "S",
  OFFICE: "O",
  NEWARRIVAL: "N",
};

export const DIAMOND_AVAILABLE_WEB_STATUS = ["A", "M"];

export const PURCHASED_STATUS = { PENDIND: 1, APPROVED: 2, REJECT: 3, RELEASE: 4 };

export const DIAMOND_DISPLAY_TYPE = {
  BUSINESS: 1,
  NEW: 2,
  AVAILABLE: 3,
  INLAB: 4,
  EXCLUSIVE: 11,
  BEST: 12,
  STONE_OF_DAY: 13,
  LUCKY: 14,
};

export const pageDetails = {
  "search-result": "Search Result",
  "my-enquiry": "Enquiry",
  "match-pair": "Match Pair",
  invoice: "Invoice",
  "parcel-list": "Parcel Result",
  compare: "Compare Diamonds",
  "parcel-search": "Parcel Search",
  "diamond-details": "Diamond Details",
  "selected-stones": "Selected Stones",
  "my-reminder": "My Reminder",
  "my-comment": "My Note",
  "my-tracked-diamonds": "Track",
  "my-cart": "Cart",
  "my-saved-search": "Saved Search",
  "diamond-search": "Search Page",
  "fancy-search": "Fancy Search",
  signin: "Login",
  signup: "Registration",
  "forgot-password": "Forget Password",
  "change-password": "Change Password",
  "reset-password": "Reset Password",
  settings: "Settings",
  "my-account": "My Account",
  dashboard: "Home Page",
  "new-diamonds": "New Goods",
  "best-of-KGK": "Best of KGK",
  "upcoming-diamonds": "Upcoming Goods",
  "my-demand": "My Demand",
  csr: "CSR",
  "KGK-front": "Home Page",
  career: "Career",
  "our-story": "Our Story",
  "entity-brand": "Entity Brand",
  services: "Services",
  "KGK-grading": "Grading",
  help: "Help",
  "KGK-achivement": "KGK Achivement",
  "terms-and-condition": "Terms And Condition",
  event: "Event",
  "csr-detail": "CSR Detail",
  "corporate-social-responsibility": "Corporate Social Responsibility",
  "guest-login": "Guest Login",
  calendar: "Calendar",
  recommended: "I m Lucky",
  "loose-diamonds": "Loose Diamonds",
  "4cs": "4c",
  feedback: "Feedback",
  sitemap: "Sitemap",
  presence: "Presence",
  "news-media": "KGK News",
  "why-KGK": "Why KGK",
  policy: "Policy",
  "customer-testimonial": "Customer Testimonials",
  "cut-and-polished-diamonds": "Cut and Polished Diamonds",
  "wholesale-diamonds": "Wholesale Diamonds",
  "certified-diamonds": "Certified Diamonds",
  "fancy-diamonds": "Fancy Diamonds",
  "quick-search": "quick-search",
  "price-calculator": "Price Calculator",
  "appointment-history": "Appointment History",
  "company-details": "Company Details",
  "terms-and-documentation": "Terms and Documentation",
  "change-email-address": "Change Email Address",
  "customer-care": "Customer-Care",
  "KGK-care": "KGK Care",
  "matchpair-settings": "Match pair Settings",
  "my-order": "Order",
  fAQs: "FAQs",
  "concierge-services": "Concierge Services",
  appointment: "Appointment",
  contact: "Contact Us",
  "address-book": "Address Book",
  "upload-diamonds": "Upload Diamonds",
  "upload-images": "Upload Images",
  "upload-certificates": "Upload Certificates",
  "diamond-history": "Diamond History",
  people: "People",
};

export const PROJECT_SETTINGS_TYPE = { CM_CHARGES: 1, BANK_RATES: 2, SYNC_JOB_SETTINGS: 3, FM_CHARGES: 4 };

export const DISCOUNT_SETTINGS_TYPE = { MIN_MAX: 18 };

export const DIAMONDCONFIRM = { 1: "PENDING", 2: "ACCEPTED", 3: "REJECTED", 4: "CANCELED" };

export const ORDER_LIST_STATUS = { 1: "PENDING", 2: "APPROVED", 3: "REJECTED" };

export const DIAMOND_STATUS = { PENDING: 1, APPROVED: 2, REJECTED: 3 };

export const STATUS_COLOR = { PENDING: "#293855", ACCEPTED: "#50a013", REJECTED: "#c02036", CANCELED: "#bc6d3f" };

export const CONFIRMSTATUSCOLOR = STATUS_COLOR;
export const WHITE_LABEL = false;
export const trackTypeObj = TRACK_TYPES;
export const fileKey = "";
