const initialState = {};

export const BID_ACTION_TYPES = {
  SET_BID_CONFIG: "@bid-config/set",
  RESET_BID_CONFIG: "@bid-config/reset",
};

export const BID_ACTIONS = {
  setBidConfig: (payload) => ({ type: BID_ACTION_TYPES.SET_BID_CONFIG, payload }),
  resetBidConfig: () => ({ type: BID_ACTION_TYPES.RESET_BID_CONFIG }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case BID_ACTION_TYPES.SET_BID_CONFIG:
      return { ...payload };

    case BID_ACTION_TYPES.RESET_BID_CONFIG:
      return { ...initialState };

    default:
      return state;
  }
};
