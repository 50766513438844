import React from "react";

import urlJoin from "url-join";

import { map } from "lodash";

// import { LISTINGPAGES } from 'components/DiamondList/DiamondListFunctions';
import Heading from "components/common/Heading";

import UtilService from "services/util";

import { notify } from "util/notify";
import { isEmpty } from "util/utils";

import { BASE_URL, PAGES } from "constants/Common";
import { EXPORT_EXCEL } from "constants/apiConstant";

import SendEmailPopup from "./SendEmail";

const LISTINGPAGES = PAGES;

function SharePopup(props) {
  const [sendEmail, setSendEmail] = React.useState(false);

  const [isCert, setIsCert] = React.useState(false);
  const [typeIIA, setTypeIIA] = React.useState(false);
  const [isImg, setIsImg] = React.useState(false);
  const [isImg2, setIsImg2] = React.useState(false);
  const [isFaceUp, setIsFaceUp] = React.useState(false);
  const [DarkField, setDarkField] = React.useState(false);
  const [Ideal, setIdeal] = React.useState(false);
  const [flourescence, setFlourescence] = React.useState(false);
  const [isVideo, setIsVideo] = React.useState(false);
  const [isPlt, setIsPlt] = React.useState(false);
  const [isHa, setIsHa] = React.useState(false);
  const [isAsset, setIsAsset] = React.useState(false);
  const [isArrow, setIsArrow] = React.useState(false);
  const [isVideo2, setIsVideo2] = React.useState(false);
  const [roughImage, setRoughimage] = React.useState(false);
  const [roughVideo, setRoughVideo] = React.useState(false);
  const [DImage, setDImage] = React.useState(false);

  const isExcel = false;
  const images = false;
  const videos = false;
  const certificates = false;
  const roughs = false;

  const WhatsAppShare = () => {
    if (props.checked.length === 1) {
      const path_ = urlJoin(window.location.origin, LISTINGPAGES.DETAILS, props.checked[0].id);
      // const path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0].id}`;

      window.open(`https://web.whatsapp.com/send?text=${path_}`);
    } else if (props.checked.length >= 2) {
      let msg = "KGK : Diamonds Detail%0A%0A";

      props.checked.forEach((element) => {
        const path_ = urlJoin(window.location.origin, LISTINGPAGES.DETAILS, element.id);
        // const path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element.id}`;
        msg += path_ + "%0A%0A";
      });
      window.open(`https://web.whatsapp.com/send?text=${msg}`);
    }
  };

  const SkypeShare = () => {
    if (props.checked.length === 1) {
      const path_ = urlJoin(window.location.origin, LISTINGPAGES.DETAILS, props.checked[0].id);
      window.open(`https://web.skype.com/share?url=${path_}`);
    } else if (props.checked.length >= 2) {
      let msg = "KGK : Diamonds Detail%0A%0A";

      props.checked.forEach((element) => {
        const path_ = urlJoin(window.location.origin, LISTINGPAGES.DETAILS, element.id);
        msg += path_ + "%0A%0A";
      });
      window.open(`https://web.skype.com/share?url=${msg}`);
    }
  };

  const Wechat = () => {
    if (props.checked && props.checked.length === 0) {
      notify.error({ message: "No stone(stones) to share." });
    } else {
      if (props.checked.length === 1) {
        const path_ = urlJoin(window.location.origin, LISTINGPAGES.DETAILS, props.checked[0].id);
        window.open(`https://web.wechat.com/send?text=${path_}`);
      } else if (props.checked.length >= 2) {
        let msg = "KGK : Diamonds Detail%0A%0A";

        props.checked.forEach((element) => {
          const path_ = urlJoin(window.location.origin, LISTINGPAGES.DETAILS, element.id);
          msg += path_ + "%0A%0A";
        });
        window.open(`https://web.wechat.com/send?text=${msg}`);
      }
  };

  React.useEffect(() => {
    setIsImg(images);
    setIsImg2(images);
    setIsPlt(images);
    setIsHa(images);
    setIsAsset(images);
    setIsFaceUp(images);
    setDarkField(images);
    setIdeal(images);
    setFlourescence(images);
    setIsArrow(images);
  }, [images]);

  React.useEffect(() => {
    setIsVideo(videos);
    setIsVideo2(videos);
  }, [videos]);

  React.useEffect(() => {
    setIsCert(certificates);
    setTypeIIA(certificates);
  }, [certificates]);

  React.useEffect(() => {
    setRoughVideo(roughs);
    setRoughimage(roughs);
    setDImage(roughs);
  }, [roughs]);

  const onDownloadClick = (emailData) => {
    let checked = [];
    if (props.checked && props.checked.length !== 0) {
      checked = props.checked;
    } else if (props.noCheckedForDownload && props.noCheckedForDownload.length !== 0) {
      checked = props.noCheckedForDownload;
    } else {
      notify.error({ message: "No stone(stones) to share." });
    }
    if (checked && checked.length !== 0) {
      const obj = {
        isExcel: true,
        img: false,
        certFile: false,
        videoFile: false,
        pltFile: false,
        sideFile: false,
        frontFile: false,
        prpFile: false,
        faceUp: false,
        zip: true,
      };

      if (sendEmail) {
        obj.sendEmail = true;
        obj.email = { ...emailData };
      }
      const id = map(checked, "vStnId");
      const objData = {
        ...EXPORT_EXCEL,
        request: {
          filter: isEmpty(id) ? { vStnId: [...id], isFcCol: false } : { vStnId: [...id], isFcCol: false },
          ...obj,
        },
      };

      UtilService.callApi(objData, async (err, res) => {
        if (err) {
          const message = err.data.message ?? err.data.data.message ?? "Failed to Share.";
          return notify.error({ message });
        }
        if ((await res) && res.code === "OK") {
          const file_path = urlJoin(BASE_URL, res.data);
          if (!obj.sendEmail) {
            window.open(file_path, "Download");
          }
          if (props.onCloseSharePopup) {
            props.onCloseSharePopup();
          }
          setSendEmail(false);
          if (props.clearAll) {
            props.clearAll();
          }
          notify.success({ message: res.message });
        } else {
          notify.error({ message: res.message });
        }
      });
    }
  };

  return (
    <div>
      <Heading title="Share Stone" className="popupInnerTitle mb-10 p-0" />
      <div className="MoreSearchOptionItem">
        <ul>
          <li>
            <a href="#!" onClick={() => setSendEmail(true)}>
              Email
            </a>
          </li>
          <li>
            <a href="#!" onClick={WhatsAppShare}>
              WhastApp
            </a>
          </li>
          <li>
            <a href="#!" onClick={SkypeShare}>
              Skype
            </a>
          </li>
          {/* <li>
            <a href="#!" onClick={Wechat}>
              WeChat
            </a>
          </li> */}
        </ul>
      </div>
      <SendEmailPopup
        sendEmail={sendEmail}
        onCancel={() => setSendEmail(!sendEmail)}
        ids={map(props.checked, "id")}
        certFile={isCert}
        hAFile={isHa}
        img={isImg}
        isPlt={isPlt}
        isReal={isImg}
        assetFile={isAsset}
        videoFile={isVideo}
        isExcel={isExcel}
        arrowFile={isArrow}
        mp4Video={isVideo2}
        type2={typeIIA}
        roughVideo={roughVideo}
        darkFieldImage={DarkField}
        flsImage={flourescence}
        idealWhiteImage={Ideal}
        roughImage={roughImage}
        planImg={DImage}
        faceUp={isFaceUp}
        b2cRealImage={isImg2}
        sucess={onDownloadClick}
      />
    </div>
  );
} 

}

export default React.memo(SharePopup);
