import React from "react";

import closeSVG from "../../../../assets/svg/Header/close.svg";

const CovidStrip = (props) => {
  return props.event && props.event.shortDescription ? (
    <div className="covidStrip">
      <span>
        Evaluate COVID‑19 symptoms and understand next steps
        {/* {props.event.shortDescription} */}
      </span>
      <span className="closeImg" onClick={props.onClick}>
        <img src={closeSVG} alt="" />
      </span>
    </div>
  ) : (
    <></>
  );
};
export default CovidStrip;
