import React from "react";
import { withRouter } from "react-router-dom";

// import { Popover } from 'antd';

import IntlMessage from "util/IntlMessage";
import { formatDecimal, isArray, formatNumber, parseDecimal } from "util/utils";

import MagnifierImage from "./MagnifierImage";

import { FILE_URLS, CURRENCY_SYMBOL } from "../../constants/Common";
import { floatkeys, roundkeys } from "../DiamondList/TableBack";

const Compare = (props) => {
  const getMap = (name, key, extra = "") => {
    return (
      <tr>
        <td className="compareTableHead">
          <span className="d-flex j-space-between align-items-center">{name}</span>
        </td>
        {props.compareList &&
          props.compareList.length !== 0 &&
          props.compareList.map((x, index) => {
            return (
              <td key={index}>
                {(!x[key]
                  ? "-"
                  : floatkeys.includes(key)
                  ? formatDecimal(x[key])
                  : roundkeys.includes(key)
                  ? formatNumber(Math.round(x[key]))
                  : x[key]) + extra}
              </td>
            );
          })}
      </tr>
    );
  };
  return (
    <div className="compareMainPage">
      <div className="compareTable">
        <table>
          <tr>
            <td className="compareHead"><IntlMessage id="app.diamondImage" /></td>
            {props.compareList &&
              props?.compareList?.length !== 0 &&
              props?.compareList?.map((item, index) => {
                return (
                  <td key={index} className="compareHead compareImage">
                    <MagnifierImage image={FILE_URLS.img.replace("***", item.vStnId)} className="diamondCompareImg" />
                    <div className="diamondGridLabel">{/* <label>DD:1</label> */}</div>
                  </td>
                );
              })}
          </tr>
          {getMap(`Price/Carat (${CURRENCY_SYMBOL})`, "ctPr")}
          <tr>
            <td className="compareTableHead">
              <span className="d-flex j-space-between align-items-center">
                Amount Difference (
                {CURRENCY_SYMBOL}
                )
              </span>
            </td>
            {isArray(props.compareList) &&
              props.compareList.map((item, index) => {
                const amtDisplay = parseDecimal(props?.dataAmt) - parseDecimal(item?.amt);

                return (
                  <td key={index} className={`${amtDisplay}`.includes("-") ? "redColor" : "greenColor"}>
                    {formatDecimal(amtDisplay)}
                  </td>
                );
              })}
          </tr>
          {getMap("Carat", "crt")}
          {getMap("Cut", "cutNm")}
          {getMap("Color", "colNm")}
          {getMap("Clarity", "clrNm")}
          <tr>
            <td />
            {props.compareList &&
              props.compareList.length !== 0 &&
              props.compareList.map((x, i) => {
                return (
                  <td key={i}>
                    <span
                      onClick={() => {
                        window.open(`/diamond-detail/${x.id}`);
                      }}
                      className="linkText"
                    >
                      <IntlMessage id="app.ViewDetail" />
                    </span>
                  </td>
                );
              })}
          </tr>
        </table>
      </div>
    </div>
  );
};
export default withRouter(Compare);
