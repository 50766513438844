import React, { Component } from "react";

import { shareEmail } from "../../Auth/SignupValidator";
import CommonModal from "../CommonModal";
import InputBlock from "../InputBlock";
// import _ from 'lodash';

// import { handleSendEmail, handleSendXRayEmail } from '../../DiamondList/DiamondListFunctions';

class SendEmailPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      subject: props.xRay ? "X-Ray Detail" : "",
      cc: "",
      email: "",
      message: props.xRay,
      subjectInvalid: false,
      emailInvlaid: true,
      errors: null,
    };
  }

  handleEmailChange(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors["email"];
    }

    this.setState({ email: e.target.value, errors });
    // let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let result = regEx.test(String(this.state.email).toLowerCase());
    // if (result) {
    //   this.setState({ emailInvalid: false });
    // }
  }

  handleSubjectChange(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors["subject"];
    }
    this.setState({ subject: e.target.value, errors });
    // if (e.target.value === "") {
    //   this.setState({ subjectInvalid: true });
    // } else {
    //   this.setState({ subjectInvalid: false });
    // }
  }
  // handleValidation = () => {
  //   let errors = this.state.errors;
  //   let formIsValid = true;

  //   if (!this.state.name || _.isEmpty(this.state.name)) {

  //     formIsValid = false
  //     errors.name = 'Please enter name'
  //   }
  //   if (this.state.name) {
  //     if (!this.state.name.match(/^[a-zA-Z ]+$/)) {
  //       formIsValid = false
  //       errors.name = 'Please enter valid name'
  //     } else {
  //       delete errors['name']
  //     }
  //   }

  //   if (!this.state.email || _.isEmpty(this.state.email)) {
  //     formIsValid = false;
  //     errors.email = 'Please enter email'

  //   }
  //   if (this.state.email) {
  //     let lastAtPos = this.state.email.lastIndexOf("@");
  //     let lastDotPos = this.state.email.lastIndexOf(".");

  //     if (
  //       this.state.email &&
  //       !this.state.email.match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)
  //     ) {
  //       formIsValid = false;
  //       errors.email = 'Please enter valid email'

  //     }
  //   }
  //   if (this.state.cc) {
  //     let lastAtPos = this.state.email.lastIndexOf("@");
  //     let lastDotPos = this.state.email.lastIndexOf(".");

  //     if (
  //       this.state.cc &&
  //       !this.state.cc.match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)
  //     ) {
  //       formIsValid = false;
  //       errors.cc = 'Please enter valid email'

  //     }
  //   }
  //   if (!this.state.message || _.isEmpty(this.state.message)) {

  //     formIsValid = false
  //     errors.message = 'Please enter message'
  //   }
  //   if (!this.state.subject || _.isEmpty(this.state.subject)) {

  //     formIsValid = false
  //     errors.subject = 'Please enter subject'
  //   }
  //   this.setState({ errors })
  //   return formIsValid

  // }
  handleOk = () => {
    // if (this.handleValidation()) {

    const data = {
      name: this.state.name,
      to: this.state.email,
      subject: this.state.subject,
      cc: this.state.cc,
      message: this.state.message,
    };
    const errors = shareEmail({ ...data });
    if (!errors.isValid) {
      this.setState({ errors: errors.errors });
    } else {
      this.props.sucess({ ...data });
    }
    // }
    // if (
    //   this.state.emailInvalid === false &&
    //   this.state.subjectInvalid === false
    // ) {
    //   let obj = {
    //     ...this.state,
    //     id: this.props.ids,
    //     isCert: this.props.isCert,
    //     isHa: this.props.isHa,
    //     isImg: this.props.isImg,
    //     isPlt: this.props.isPlt,
    //     isStock: this.props.isStock,
    //     isVideo: this.props.isVideo,
    //   };
    //   for (var key in obj) {
    //     if (obj[key] === false) {
    //       delete obj[key];
    //     }
    //   }

    //   this.props.xRay
    //     ? handleSendXRayEmail({ ...this.state }, this.props.path, (status) => {
    //         if (status) this.props.onDetailClose();
    //       })
    //     : handleSendEmail(obj, (status) => {
    //         if (status) this.props.onCancel();
    //       });
    // }
  };
  onBlur = (name, val) => {
    const self = this;
    const data = {
      [name]: val,
    };

    const errors = shareEmail(data);
    if (!errors.isValid) {
      self.setState({ errors: errors.errors });
    }
  };
  clearState = () => {
    this.setState({ name: "", cc: "", email: "", errors: null });
  };
  render() {
    const { errors } = this.state;
    return (
      <>
        <CommonModal
          visible={this.props.sendEmail}
          handleOk={() => this.handleOk()}
          handleCancel={() => {
            this.clearState();
            this.props.onCancel();
          }}
          title="Send Email"
          footerShow
          submitTitle="Send Email"
          cancelTitle="Cancel"
        >
          <div className="searchPopupCommon">
            <div className="searchPopupTwoValue">
              <div className="searchPopupTwoValue">
                <InputBlock
                  required
                  onBlur={(e) => this.onBlur("name", e.target.value)}
                  label="Name *"
                  value={this.state.name}
                  hasError={errors !== null && errors["name"] ? errors["name"] : false}
                  onChange={(e) => {
                    let errors = null;
                    if (this.state.errors) {
                      errors = Object.assign("", this.state.errors);
                      delete errors["name"];
                    }

                    this.setState({ name: e.target.value, errors });
                  }}
                />
              </div>
              <div className="searchPopupTwoValue">
                <InputBlock
                  required
                  onBlur={(e) => this.onBlur("to", e.target.value)}
                  label="Email *"
                  value={this.state.email}
                  hasError={errors !== null && errors["email"] ? errors["email"] : false}
                  //onChange={(e) => this.setState({ email: e.target.value })}
                  onChange={(e) => this.handleEmailChange(e)}
                />
              </div>
              <InputBlock
                onBlur={(e) => this.onBlur("subject", e.target.value)}
                required
                label="Subject *"
                autoFocus
                value={this.state.subject}
                hasError={errors !== null && errors["subject"] ? errors["subject"] : false}
                //onChange={(e) => this.setState({ subject: e.target.value })}
                onChange={(e) => this.handleSubjectChange(e)}
              />
            </div>

            {/* <div className="searchPopupTwoValue">
              <InputBlock
                hasError={errors !== null && errors['cc'] ? errors['cc'] : false}
                onBlur={(e) => this.onBlur('cc', e.target.value)}
                label="Mail CC"
                value={this.state.cc}
                onChange={(e) => {
                  let errors = null;
                  if (this.state.errors) {
                    errors = Object.assign('', this.state.errors);
                    delete errors['cc'];
                  }
                  this.setState({ cc: e.target.value, errors });
                }}
              />
            </div> */}
            <div className="searchPopupTwoValue">
              <InputBlock
                hasError={errors !== null && errors["message"] ? errors["message"] : false}
                onBlur={(e) => this.onBlur("message", e.target.value)}
                required
                label="Message"
                value={this.state.message}
                onChange={(e) => {
                  let errors = null;
                  if (this.state.errors) {
                    errors = Object.assign("", this.state.errors);
                    delete errors["message"];
                  }
                  this.setState({ message: e.target.value, errors });
                }}
              />
            </div>
          </div>
        </CommonModal>
      </>
    );
  }
}

export default SendEmailPopup;
