import React, { useEffect, useState } from "react";

import urlJoin from "url-join";

import { EditOutlined } from "@ant-design/icons";
import _, { isEmpty } from "lodash";
// import find from 'lodash/find';

import IntlMessage from "util/IntlMessage";

import companyIcon from "../../../assets/svg/Header/company-details.svg";
import callSVG from "../../../assets/svg/Profile/call.svg";
// import editSVG from '../../../assets/svg/Profile/edit.svg';
import emailSVG from "../../../assets/svg/Profile/email.svg";
import locationSVG from "../../../assets/svg/Profile/location.svg";
import skypeSVG from "../../../assets/svg/Profile/skype.svg";
import userSVG from "../../../assets/svg/Profile/user.svg";
import wappSVG from "../../../assets/svg/Profile/whatsapp.svg";
import { BASE_URL_ATTACH } from "../../../constants/Common";
import FileUploaded from "../../common/FileUploaded";
import DefaultUser from "../../common/UserProfileDefault";

const DetailItem = (props) => {
  return (
    <div className="detail">
      <span>{props.value}</span>
    </div>
  );
};

const CardView = (props) => {
  const [userName, setUserName] = useState("");
  const pd = (props && props.pDetails) || {};
  useEffect(() => {
    const splitStr = pd && pd.name && pd.name.toLowerCase().split(" ");
    if (splitStr && splitStr.length) {
      const result = splitStr.map((item) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      });
      const finalVal = result.join(" ");
      setUserName(finalVal);
    }
  }, []);

  const cd = (props && props.cDetails) || {};
  const sc = (props && props.socialDetail) || {};

  if (pd && pd.account && pd.account?.kyc && !isEmpty(pd.account?.kyc)) {
    const doc = pd && pd.account && pd.account?.kyc && pd.account?.kyc.find((item) => item.type === "personal");
    pd.photoId = doc && doc.path;
  }
  if (cd && cd.account && cd.account?.kyc && !isEmpty(cd.account?.kyc)) {
    const doc = cd && cd.account && cd.account?.kyc && cd.account?.kyc.find((item) => item.type === "business");
    cd.businessId = doc && doc.path;
  }

  return (
    <div className="KycCardBlock">
      <div className="kycDetailBox d-flex flex-wrap">
        <div className="kycUploadDoc">
          {props.companyDetail &&
            (cd.account.vendorLogo ? (
              <img src={urlJoin(BASE_URL_ATTACH, cd.account.vendorLogo)} alt="" />
            ) : (
              <img src={companyIcon} alt="" style={{ margin: "15px 0 0 15px" }} />
            ))}
          {props.personalDetails &&
            (pd.profileImage ? (
              <img src={urlJoin(BASE_URL_ATTACH, pd.profileImage)} alt="" />
            ) : (
              <DefaultUser size="md-size" />
            ))}
          {props.social && <DefaultUser social size="md-size" />}
        </div>

        {props.social ? (
          sc.socialNetworks && _.isArray(sc.socialNetworks) && sc.socialNetworks.length !== 0 ? (
            <div style={{ marginLeft: "15px" }} className="KycDetailItem">
              {sc.socialNetworks.slice(0, 5).map((soc, i) => {
                return (
                  <div key={i}>
                    <div className="companyNameCode">
                      <span>
                        {soc.name.charAt(0).toUpperCase() + soc.name.slice(1).toLowerCase()}
                        {' '}
                        :
                      </span>
                      {soc.socialtext && <span>{soc.socialtext}</span>}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ marginLeft: "5px" }} className="KycDetailItem">
              <div className="">
                <div className="companyNameCode">
                  <span>
                    <IntlMessage id="app.NoSocialData" />
                  </span>
                </div>
              </div>
            </div>
          )
        ) : null}
        <div className="kycDetailItem">
          {props.companyDetail && (
            <>
              <div className="">
                <div className="companyNameCode">
                  {cd.account && <span>{cd.account.vendorCode ? `(${cd.account.vendorCode})` : ""}</span>}
                </div>
                {props.companyDetail && cd.account.businessType && (
                  <div className="detail mt-2">
                    <span>Business Type: </span>
                    <p>JEWELLERY DESIGN</p>
                  </div>
                )}
                {cd.account.companyName && (
                  <div className="detail mt-2">
                    <span>Company Name: </span>
                    <p>{cd.account.companyName.toUpperCase()}</p>
                  </div>
                )}
              </div>
              {cd.account.address && (
                <>
                  <div className="detail mt-2">
                    <span>Company Address: </span>
                    <p>
                      {cd.account.address && <>{cd.account.address.toUpperCase()}</>}
                      {cd.account.landMark.toUpperCase()}
                    </p>
                  </div>
                </>
              )}
              {cd.account.zipCode && (
                <>
                  <div className="detail mt-2">
                    <span>Zip Code: </span>
                    {cd.account.zipCode && <p>{cd.account.zipCode}</p>}
                  </div>
                </>
              )}
            </>
          )}
          {props.companyDetail ? null : (
            <>
              {props.personalDetails && (
                <>
                  {/* <DetailItem image={userSVG} value={pdName.charAt(0).toUpperCase() + pdName.slice(1)} /> */}
                  <div>
                    <DetailItem image={userSVG} value={userName} />
                    <div className="mr-20">
                      {pd.mobile && (
                        <DetailItem image={callSVG} value={`+${pd.countryCode.split("/")[0]} ${pd.mobile}`} />
                      )}
                      {pd.whatsapp && <DetailItem image={wappSVG} value={pd.whatsapp} />}
                    </div>
                    <div>
                      {pd.email && <DetailItem image={emailSVG} value={pd.email} />}
                      {pd.skype && <DetailItem image={skypeSVG} value={pd.skype} />}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="detailEditBloxk" onClick={props.onClick}>
          <EditOutlined />
        </div>
      </div>
      {props.companyDetail ? null : (
        <>
          {pd.companyAddress && (
            <div className="kycDetailBox mt-10 addressBlock">
              <div className="kycDetailItem">
                <div className="detail">
                  <span>
                    <img src={locationSVG} alt="" />
                  </span>
                  <span>
                    <p>{pd.companyAddress}</p>
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {(cd.businessId || pd.businessId || pd.photoId || cd.photoId) && (
        <FileUploaded
          title={props.companyDetail ? <IntlMessage id="app.BusinessProof" /> : <IntlMessage id="app.PersonalID" />}
          file={
            props.companyDetail
              ? urlJoin(
                  BASE_URL_ATTACH,
                  cd.account.businessId || (cd.account && cd.account?.businessId) || pd.businessId
                )
              : urlJoin(BASE_URL_ATTACH, pd.photoId)
          }
          className="mt-20"
        />
      )}
    </div>
  );
};
export default CardView;
