import React from "react";

import "./fileUploaded.less";
import pdfSVG from "./pdf.svg";

const FileUploaded = (props) => {
  return (
    <div className={`fileUploadedBlockItem ${props.className}`}>
      <div className={`fileUplodImg ${props.sizeSmall && "smallSize"}`}>
        {props.file
          .split("/")
          .slice(-1)[0]
          .match(/.(pdf)$/i) ? (
          <img style={{ width: "85px", height: "85px", marginLeft: "22px" }} src={pdfSVG} alt="pdf" />
        ) : (
          <img src={props.file} alt="fileAdd" />
        )}
      </div>
      <div className="fileUplodContent">
        <p>{props.title}</p>
      </div>
    </div>
  );
};
export default FileUploaded;
