import React, { Component } from "react";
import { Link } from "react-router-dom";

import IntlMessage from "util/IntlMessage";

import SavedSearchCard from "./SavedSearchCard";

import Heading from "../../common/Heading";

class HomeSavedSearch extends Component {
  state = {
    savedData: [],
  };
  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      this.setState({ savedData: this.props.save });
    }
  }
  render() {
    const { savedData } = this.state;

    return (
      <div className="rightBlockItem">
        <div className="d-flex j-space-between align-items-center dashboardCardTop">
          <Heading title={<IntlMessage id="app.savedsearchtitle" />} />
          <Link to="/account/saved-search" className="textViewAll">
            <IntlMessage id="app.viewallDashboard" />
          </Link>
        </div>
        <div className="sacedSearchBox">
          {savedData && savedData.length !== 0
            ? savedData.map((save, ind) => {
                return <SavedSearchCard saveId={save.id} saveDetail={save} key={ind} />;
              })
            : "No Save Search"}
        </div>
      </div>
    );
  }
}
export default HomeSavedSearch;
