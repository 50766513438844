import React from "react";

import { Dropdown, Tooltip } from "antd";

import { FILTER_COLUMNS } from "util/tableConstant";

// import { FILTER_COLUMNS } from './TableBack';

import closeIconSVG from "assets/svg/DiamondList/close.svg";
import shortinglistdownWhiteSVG from "assets/svg/DiamondList/shortinglistdown-white.svg";
import shortinglistdownSVG from "assets/svg/DiamondList/shortinglistdown.svg";
import shortinglistupSVG from "assets/svg/DiamondList/shortinglistup.svg";
import sortingSVG from "assets/svg/DiamondList/sorting.svg";
import SortingFilterDropdown from "./OverlayDropdownBack";

export const TableHeaderBack = React.memo(({ column, FilterOption, nodots, sortBy = [], setSortBy, toggleSortBy }) => {
  const sortedColumnIndex = column.sortedIndex;
  const asc = column.isSortedDesc === false;

  return (
    /** filter added in amount column */
    column.id === "amt" && (column.sort = "amt"),
    (column.id === "vStnId" || column.id === "rptNo" || column.id === "lbNm") && (column.sort = undefined),
    (
      <Dropdown
        overlayClassName="tabelDropdownClass"
        key={`filterOverlayDropdown${column.sort ?? column.id}`}
        overlay={
          column.isSorted ||
          (FilterOption !== false && (FILTER_COLUMNS.includes(column.id) || FILTER_COLUMNS.includes(column.sort))) ? (
            <SortingFilterDropdown
              // sorting props
              setSortBy={setSortBy}
              sortBy={sortBy}
              toggleSortBy={toggleSortBy}
              // common props
              column={column}
            />
          ) : (
            <></>
          )
        }
        trigger={["click"]}
      >
        <div
          className={`tableHeaderMain ${nodots && "squeceChange"} ${!column.isSorted && "shortingBoxShow"}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {!nodots && (
            <span className="listIcon">
              <img src={sortingSVG} alt="icon" />
            </span>
          )}
          {column.desc ? (
            <Tooltip key={`headerDesc${column.desc}`} title={column.desc}>
              <span className="taleHeadTitle">{column.render("Header")}</span>
            </Tooltip>
          ) : (
            <span className="taleHeadTitle">{column.render("Header")}</span>
          )}
          {column.isSorted ? (
            <div className="selectShortingMainBlock">
              <div className="selectShorting" {...column.getSortByToggleProps()}>
                <span className="selectShortingCount" style={!column.isSorted ? { margin: 0 } : {}}>
                  {sortedColumnIndex + 1}
                </span>
                {column.isSorted && (
                  <span className="selectShortingArrow">
                    <img src={asc ? shortinglistupSVG : shortinglistdownWhiteSVG} alt="" />
                  </span>
                )}
              </div>
              <div
                className="shortingClose"
                onClick={(e) => {
                  e.stopPropagation();
                  column.clearSortBy();
                }}
              >
                <img src={closeIconSVG} alt="icon" />
              </div>
            </div>
          ) : null}
          {column.sort && !column.isSorted && (
            <div className="shortingRound" {...column.getSortByToggleProps()}>
              <div className="shortingRoundInner">
                <img src={shortinglistupSVG} alt="icon" />
                <img src={shortinglistdownSVG} alt="icon" />
              </div>
            </div>
          )}
        </div>
      </Dropdown>
    )
  );
});
TableHeaderBack.displayName = "TableHeaderBack";

export default TableHeaderBack;
