import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import _ from "lodash";

import IntlMessage from "util/IntlMessage";

import blueLoadingSVG from "assets/img/blue_loading.gif";

import { getGridHeight } from "./DiamondList";
import OpenNotification from "./common/CommonButton/OpenNotification";
import Heading from "./common/Heading";
import LeftMultipleSelect from "./common/LeftMultipleSelect";

import { setLoader } from "../appRedux/actions/Common";
import { LOCAL_STORAGE_VAR } from "../constants/Common";
import { QUICK_SEARCH, GET_DIAMOND_DATA } from "../constants/apiConstant";
import UtilService from "../services/util";

import closethinSVG from "assets/svg/DiamondList/close-thin.svg";

export const quickSearchData = {
  shape: [
    { value: "Round" },
    { value: "Princess" },
    { value: "Pear" },
    { value: "Oval" },
    { value: "Marquise" },
    { value: "Emerald" },
    { value: "Heart" },
    { value: "Radiant" },
    { value: "Cushion" },
    { value: "Other" },
  ],
  color: [
    { value: "DEF" },
    { value: "GHI" },
    { value: "J-" },
    { value: "Oval" },
    { value: "Marquise" },
    { value: "Emerald" },
    { value: "Heart" },
    { value: "Radiant" },
    { value: "Cushion" },
    { value: "Other" },
  ],
  clarity: [{ value: "LC" }, { value: "FL-IF" }, { value: "VVS" }, { value: "VS" }, { value: "SI" }, { value: "I" }],
};

const quickSearch = [
  {
    colors: [
      { id: "D", name: "DEF" },
      { id: "E", name: "DEF" },
      { id: "F", name: "DEF" },
      { id: "G", name: "GHI" },
      { id: "H", name: "GHI" },
      { id: "I", name: "GHI" },
      { id: "J", name: "J-Z" },
      { id: "K", name: "J-Z" },
      { id: "L", name: "J-Z" },
      { id: "M", name: "J-Z" },
      { id: "N", name: "J-Z" },
      { id: "O", name: "J-Z" },
      { id: "P", name: "J-Z" },
      { id: "Q", name: "J-Z" },
      { id: "R", name: "J-Z" },
      { id: "S", name: "J-Z" },
      { id: "T", name: "J-Z" },
      { id: "U", name: "J-Z" },
      { id: "V", name: "J-Z" },
      { id: "W", name: "J-Z" },
      { id: "X", name: "J-Z" },
      { id: "Y", name: "J-Z" },
      { id: "Z", name: "J-Z" },
    ],
    clarity: [
      { id: "LC", name: "FL-IF" },
      { id: "FL", name: "FL-IF" },
      { id: "IF", name: "FL-IF" },
      { id: "VVS1", name: "VVS" },
      { id: "VVS2", name: "VVS" },
      { id: "VS1", name: "VS" },
      { id: "VS2", name: "VS" },
      { id: "SI1", name: "SI" },
      { id: "SI2", name: "SI" },
      { id: "SI3", name: "SI" },
      { id: "I1", name: "I" },
      { id: "I2", name: "I" },
      { id: "I3", name: "I" },
    ],
    cut: [
      { id: "I", name: "I/EX" },
      { id: "EX", name: "I/EX" },
      { id: "VG", name: "VG" },
      { id: "G", name: "G" },
      { id: "F", name: "F/P" },
      { id: "P", name: "F/P" },
    ],
    polish: [
      { id: "I", name: "I/EX" },
      { id: "EX", name: "I/EX" },
      { id: "VG", name: "VG" },
      { id: "G", name: "G" },
      { id: "F", name: "F/P" },
      { id: "P", name: "F/P" },
    ],
    symmetry: [
      { id: "I", name: "I/EX" },
      { id: "EX", name: "I/EX" },
      { id: "VG", name: "VG" },
      { id: "G", name: "G" },
      { id: "F", name: "F/P" },
      { id: "P", name: "F/P" },
    ],
  },
];

class QuickSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shape: [],
      caratSizeGroup: {},
      finalResult: [],
      selectedShapes: [],
      loader: false,
      searched0: "",
      searched1: "",
      searched2: "",
      cut: [],
      polish: [],
      symmetry: [],
      fluor: [],
      lab: [],
      selectedCuts: [],
      selectedPolish: [],
      selectedSym: [],
      selectedFlour: [],
      selectedLab: [],
      otherLabs: [],
      ShowFilter: false,
    };
  }

  getData() {
    const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
    if (masterData) {
      const fluorMaster = ["NONE", "F", "M", "S"];
      const labMaster = ["GIA", "IGI", "HRD", "OTHER"];
      const fluor = [];
      const lab = [];
      let otherLabs = [];
      const cut = [];
      const polish = [];
      const sym = [];
      let IEXids = [];
      let FPids = [];
      let IEXForPol = [];
      let FPForPol = [];
      let IEXForSym = [];
      let FPForSym = [];

      masterData.CUT.map((c) => {
        if (c.code === "I" || c.code === "EX") {
          IEXids = IEXids.concat(c.id);
        }
        if (c.code === "F" || c.code === "P") {
          FPids = FPids.concat(c.id);
        }
        return true;
      });

      masterData.CUT.forEach((c) => {
        if (c.code === "I") {
          cut.push({
            id: IEXids,
            name: "I/EX",
            code: "I/EX",
            isActive: true,
          });
        } else if (c.code === "F") {
          cut.push({
            id: FPids,
            name: "F/P",
            code: "F/P",
            isActive: true,
          });
        } else if (c.code === "VG" || c.code === "G") {
          cut.push(c);
        }
      });

      masterData.POLISH.forEach((p) => {
        if (p.code === "I" || p.code === "EX") {
          IEXForPol = IEXForPol.concat(p.id);
        }
        if (p.code === "F" || p.code === "P") {
          FPForPol = FPForPol.concat(p.id);
        }
      });

      masterData.POLISH.forEach((p) => {
        if (p.code === "I") {
          polish.push({
            id: IEXForPol,
            name: "I/EX",
            code: "I/EX",
            isActive: true,
          });
        } else if (p.code === "F") {
          polish.push({
            id: FPForPol,
            name: "F/P",
            code: "F/P",
            isActive: true,
          });
        } else if (p.code === "VG" || p.code === "G") {
          polish.push(p);
        }
      });

      masterData.SYMMETRY.forEach((s) => {
        if (s.code === "I" || s.code === "EX") {
          IEXForSym = IEXForSym.concat(s.id);
        }
        if (s.code === "F" || s.code === "P") {
          FPForSym = FPForSym.concat(s.id);
        }
      });

      masterData.SYMMETRY.forEach((s) => {
        if (s.code === "I") {
          sym.push({
            id: IEXForSym,
            name: "I/EX",
            code: "I/EX",
            isActive: true,
          });
        } else if (s.code === "F") {
          sym.push({
            id: FPForPol,
            name: "F/P",
            code: "F/P",
            isActive: true,
          });
        } else if (s.code === "VG" || s.code === "G") {
          sym.push(s);
        }
      });

      masterData.FLUORESCENCE.forEach((f) => {
        if (_.includes(fluorMaster, f.code)) {
          fluor.push(f);
        }
      });

      masterData.LAB.forEach((l) => {
        if (_.includes(labMaster, l.code)) {
          lab.push(l);
        } else {
          otherLabs = _.concat(otherLabs, l.id);
        }
      });

      this.setState(
        {
          shape: masterData.SHAPE,
          caratSizeGroup: _.groupBy(masterData.SIZE, "group"),
          cut: cut,
          polish: polish,
          symmetry: sym,
          fluor: fluor,
          lab: lab,
          otherLabs: otherLabs,
        }
        // () => {
        //   this.selectedMaster([]);
        // }
      );

      const colorGroup = _.groupBy(quickSearch[0].colors, "name");
      const clarityGroup = _.groupBy(quickSearch[0].clarity, "name");
      const carats = _.groupBy(masterData.SIZE, "group");

      const colorIds = [];
      const clarityIds = [];
      const finalResult = [];

      _.each(colorGroup, (val1) => {
        let arr = [];
        _.each(val1, (val2) => {
          _.each(masterData.COLOR, (clr) => {
            if (val2.id === clr.webDisplay) {
              arr = _.concat(arr, clr.id);
            }
          });
        });
        colorIds.push({ group: val1[0].name, Ids: arr });
      });

      _.each(clarityGroup, (val1) => {
        let arr = [];
        _.each(val1, (val2) => {
          _.each(masterData.CLARITY, (clar) => {
            if (val2.id === clar.webDisplay) {
              arr = _.concat(arr, clar.id);
            }
          });
        });
        clarityIds.push({ group: val1[0].name, Ids: arr });
      });

      _.each(colorIds, (val1) => {
        const clarity = [];
        _.each(clarityIds, (val2) => {
          const carat = [];
          _.each(carats, (val3) => {
            carat.push({
              caratName: val3[0].group,
              from: _.head(val3).fromCarat,
              to: _.last(val3).toCarat,
              count: 0,
              totalAmount: 0,
              totalCarat: 0,
              MinMarketingPricePerCarat: 0,
              MaxMarketingPricePerCarat: 0,
            });
          });
          clarity.push({
            clarityName: val2.group,
            clarityIds: val2.Ids,
            carat: carat,
          });
        });
        finalResult.push({
          colorName: val1.group,
          colorIds: val1.Ids,
          clarity: clarity,
        });
      });
      this.setState({ finalResult: finalResult });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.forceUpdate();
    });
    this.getData();
  }

  selectedMaster = async (selectedArray, master) => {
    const sizes = [];
    if (master === "shp") {
      await this.setState({ selectedShapes: selectedArray });
    } else if (master === "cut") {
      await this.setState({ selectedCuts: selectedArray });
    } else if (master === "pol") {
      await this.setState({ selectedPolish: selectedArray });
    } else if (master === "sym") {
      await this.setState({ selectedSym: selectedArray });
    } else if (master === "flu") {
      await this.setState({ selectedFlour: selectedArray });
    } else if (master === "lb") {
      let otherCode = "";
      this.state.lab.forEach((l) => {
        if (l.code === "OTHER") {
          otherCode = _.first(l.id);
        }
      });
      if (_.includes(selectedArray, otherCode)) {
        await this.setState({ selectedLab: this.state.otherLabs });
      } else {
        await this.setState({ selectedLab: selectedArray });
      }
    }

    _.each(this.state.caratSizeGroup, (size) => {
      sizes.push({
        id: size[0].group,
        from: _.head(size).fromCarat,
        to: _.last(size).toCarat,
      });
    });

    const apiObj = {
      range: sizes,
      shp: this.state.selectedShapes,
      cut: this.state.selectedCuts,
      pol: this.state.selectedPolish,
      sym: this.state.selectedSym,
      flu: this.state.selectedFlour,
      lb: this.state.selectedLab,
    };

    _.each(apiObj, (val, key) => {
      if (_.isArray(val) && val.length === 0) delete apiObj[key];
      else if (_.isArray(val)) apiObj[key] = val;
    });

    const finalResult = this.state.finalResult;
    _.each(finalResult, (val1) => {
      _.each(val1.clarity, (val2) => {
        _.each(val2.carat, (val3) => {
          val3.count = 0;
          val3.totalCarat = 0;
          val3.totalAmount = 0;
          val3.MinMarketingPricePerCarat = 0;
          val3.MaxMarketingPricePerCarat = 0;
        });
      });
    });
    this.props.setLoader(true);

    const objData = { ...QUICK_SEARCH, request: apiObj };
    UtilService.callApi(objData, (err, data) => {
      this.props.setLoader(false);
      if (err) throw err;
      if (!data) return;
      let result = data.data.list;
      result = _.groupBy(result, "color");
      _.each(result, (colorVal, colorKey) => {
        result[colorKey] = _.groupBy(colorVal, "clarity");
        _.each(result[colorKey], (clarityVal, clarityKey) => {
          result[colorKey][clarityKey] = _.groupBy(clarityVal, "pointer");
          _.each(result[colorKey][clarityKey], (pointerVal, pointerKey) => {
            result[colorKey][clarityKey][pointerKey] = {
              count: 0,
              totalAmount: 0,
              totalCarat: 0,
              MinMarketingPricePerCarat: 0,
              MaxMarketingPricePerCarat: 0,
            };
            _.each(pointerVal, (pointerVal) => {
              result[colorKey][clarityKey][pointerKey].count += pointerVal.count || 0;
              result[colorKey][clarityKey][pointerKey].totalAmount += pointerVal.totalAmount
                ? pointerVal.totalAmount
                : 0;
              result[colorKey][clarityKey][pointerKey].totalCarat += pointerVal.carat ? pointerVal.carat : 0;

              const minPrice = Math.round(pointerVal.MinMarketingPricePerCarat);
              const maxPrice = Math.round(pointerVal.MaxMarketingPricePerCarat);

              if (
                !result[colorKey][clarityKey][pointerKey].MinMarketingPricePerCarat ||
                result[colorKey][clarityKey][pointerKey].MinMarketingPricePerCarat > minPrice
              ) {
                result[colorKey][clarityKey][pointerKey].MinMarketingPricePerCarat = minPrice;
              }

              if (
                !result[colorKey][clarityKey][pointerKey].MaxMarketingPricePerCarat ||
                result[colorKey][clarityKey][pointerKey].MaxMarketingPricePerCarat < maxPrice
              ) {
                result[colorKey][clarityKey][pointerKey].MaxMarketingPricePerCarat = maxPrice;
              }
            });
          });
        });
      });

      // eslint-disable-next-line array-callback-return
      Object.keys(result).map((colorKey) => {
        _.each(finalResult, (val1) => {
          _.each(val1.colorIds, (clrId) => {
            if (clrId === colorKey) {
              _.each(val1.clarity, (clar) => {
                _.each(clar.clarityIds, (clarId) => {
                  // eslint-disable-next-line array-callback-return
                  Object.keys(result[colorKey]).map((clarKey) => {
                    if (clarKey === clarId) {
                      _.each(clar.carat, (car) => {
                        // eslint-disable-next-line array-callback-return
                        Object.keys(result[colorKey][clarKey]).map((caratKey) => {
                          if (caratKey === car.caratName) {
                            car.count += result[colorKey][clarKey][caratKey].count;
                            car.totalAmount += result[colorKey][clarKey][caratKey].totalAmount * 1;
                            car.totalCarat += result[colorKey][clarKey][caratKey].totalCarat;

                            const minPrice = result[colorKey][clarKey][caratKey].MinMarketingPricePerCarat;
                            const maxPrice = result[colorKey][clarKey][caratKey].MaxMarketingPricePerCarat;

                            if (!car.MinMarketingPricePerCarat || car.MinMarketingPricePerCarat > minPrice) {
                              car.MinMarketingPricePerCarat = minPrice;
                            }

                            if (!car.MaxMarketingPricePerCarat || car.MaxMarketingPricePerCarat < maxPrice) {
                              car.MaxMarketingPricePerCarat = maxPrice;
                            }
                          }
                        });
                      });
                    }
                  });
                });
              });
            }
          });
        });
      });

      this.setState({
        finalResult: finalResult,
        // loader: false,
        searched0: "",
        searched1: "",
        searched2: "",
      });
    });
  };

  handleSearch(caratFrom, caratTo, clar, color, index0, index1, index2) {
    const apiObj = {
      shp: this.state.selectedShapes,
      col: color,
      or: [{ crt: { ">=": caratFrom, "<=": caratTo } }],
      clr: clar,
      cut: this.state.selectedCuts,
      pol: this.state.selectedPolish,
      sym: this.state.selectedSym,
      flu: this.state.selectedFlour,
      lb: this.state.selectedLab,
    };

    _.each(apiObj, (val, key) => {
      if (_.isArray(val) && val.length === 0) delete apiObj[key];
      else if (_.isArray(val)) apiObj[key] = val;
    });

    const obj = {
      filters: [apiObj],
      isNotReturnTotal: true,
      isReturnCountOnly: true,
    };
    const objData = {
      ...GET_DIAMOND_DATA,
      request: obj,
    };
    this.props.setLoader(true);
    UtilService.callApi(objData, (err, data) => {
      this.props.setLoader(false);
      if (err) {
        if (err.data && err.data.message) {
          this.setState({
            searched1: index1,
            searched2: index2,
            searched0: index0,
          });
          return OpenNotification({
            type: "error",
            title: err.data.message,
          });
        }
      }
      if (data && data.code === "OK") {
        this.setState({
          searched1: index1,
          searched2: index2,
          searched0: index0,
        });
        window.open("/search-result/" + data.data[0].filter.id);
      }
    });
  }

  OpenFilter = () => this.setState((state) => ({ ShowFilter: !state.ShowFilter }));

  render() {
    const gridHeight = getGridHeight(["headerTopMenu", "quickSearchTopHeader", "selectdValue"], 48);

    return (
      <React.Fragment>
        <div className="quickSearchMainWrapper">
          {!this.props.dashboardQuickSearch && (
            <>
              <div className="d-flex justify-content-between quickSearchTopHeader">
                <Heading title="Quick Search" />
                <div className="headerSelectOption">
                  <LeftMultipleSelect
                    label={<IntlMessage id="app.SelectShape" />}
                    defaultValue="Round"
                    selectValue={quickSearchData.shape}
                  />
                  <LeftMultipleSelect
                    label={<IntlMessage id="app.SelectColorRange" />}
                    defaultValue="DEF"
                    selectValue={quickSearchData.color}
                  />
                  <LeftMultipleSelect
                    label={<IntlMessage id="app.SelectClarityRange" />}
                    defaultValue="LC"
                    selectValue={quickSearchData.clarity}
                  />
                </div>
              </div>
              <div className="selectdValue">
                <div className="selectValueItem">
                  <img src={closethinSVG} alt="" />
                  <span>Round</span>
                </div>
                <div className="selectValueItem">
                  <img src={closethinSVG} alt="" />
                  <span>Pear</span>
                </div>
                <div className="selectValueItem">
                  <img src={closethinSVG} alt="" />
                  <span>Oval</span>
                </div>
                <div className="selectValueItem">
                  <img src={closethinSVG} alt="" />
                  <span>DEF</span>
                </div>
                <div className="selectValueItem">
                  <img src={closethinSVG} alt="" />
                  <span>LC</span>
                </div>
              </div>
            </>
          )}
          {this.state.loader ? (
            <div id="loader" className="main-loader quick-search-loader">
              <div id="loader-inner">
                <div className="logo">
                  <img src={blueLoadingSVG} alt="KGK" />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="quickSearchRight">
                <div className="quickSearchTable">
                  <div className="settingLeftScroll" style={{ height: gridHeight, overflow: "auto" }}>
                    <table>
                      <tr>
                        <th rowSpan="2">Color</th>
                        <th rowSpan="2">Clarity</th>
                        <th colSpan="11">Carat</th>
                      </tr>
                      <tr className="rowback">
                        <td>30s Down</td>
                        <td>30s</td>
                        <td>40s</td>
                        <td>50s - 60s</td>
                        <td>70s - 80s</td>
                        <td>90s</td>
                        <td>1ct</td>
                        <td>1.5ct</td>
                        <td>2ct</td>
                        <td>3ct - 4ct</td>
                        <td>5ct +</td>
                      </tr>
                      <tr>
                        <td rowSpan="6" className="quickSearchTitleTd">
                          DEF
                        </td>
                        <td className="quickSearchTitleTd">LC</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">FL-IF</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">VVS</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">VS</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">SI</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">I</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td rowSpan="6" className="quickSearchTitleTd">
                          GHI
                        </td>
                        <td className="quickSearchTitleTd">LC</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">FL-IF</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">VVS</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">VS</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">SI</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">I</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td rowSpan="6" className="quickSearchTitleTd">
                          J-
                        </td>
                        <td className="quickSearchTitleTd">LC</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">FL-IF</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">VVS</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">VS</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">SI</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td className="quickSearchTitleTd">SI</td>
                        <td>8</td>
                        <td>81</td>
                        <td>24</td>
                        <td>44</td>
                        <td>27</td>
                        <td>16</td>
                        <td>74</td>
                        <td>29</td>
                        <td>14</td>
                        <td>19</td>
                        <td>11</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(connect(null, { setLoader }), withRouter)(QuickSearch);
