import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { find } from "lodash";

import "react-tippy/dist/tippy.css";
import UtilService, { getCurrentTime } from "services/util";

import IntlMessage from "util/IntlMessage";

import { GET_BANNERS } from "constants/apiConstant";

// import downArrowSVG from "../../assets/svg/MobileHeader/down-arrow.svg";
// import upArrowSVG from "../../assets/svg/MobileHeader/up-arrow.svg";
import { commonLogout } from "../../constants/Common";
import HeaderStrip from "../common/Header/HeaderStrip";

class MobileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topBanner: [],
      time: [],
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
    };
  }
  setTimers = async () => {
    const serverTime = await getCurrentTime();
    this.setState({ time: serverTime });
  };

  fetchdata = () => {
    this.setState({ load: true }, () => {
      const obj = {
        ...GET_BANNERS,
        request: {
          filter: { type: ["9"], isActive: true },
        },
      };
      UtilService.callApi(obj, (err, data) => {
        this.setState({ load: false });
        if (data && data.code === "OK") {
          if (data.data && data.data.list && data.data.list.length !== 0) {
            const bannerTop = find(data.data.list, {
              type: "9",
            });
            this.setState({ topBanner: [{ ...bannerTop }] });
          }
        }
      });
    });
  };
  submenu = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  submenu1 = () => {
    this.setState({
      visible1: !this.state.visible1,
    });
  };
  submenu2 = () => {
    this.setState({
      visible2: !this.state.visible2,
    });
  };
  submenu3 = () => {
    this.setState({
      visible3: !this.state.visible3,
    });
  };
  componentDidMount() {
    this.fetchdata();
    this.setTimers();
  }
  render() {
    return (
      <div className="headerMenuBox">
        <div className="headerMeniInner">
          <HeaderStrip
            time={this.state.time.iso}
            main={this.state.topBanner && this.state.topBanner.length !== 0 ? this.state.topBanner : []}
          />
          <ul id="links" className="">
            {this.props.showOnlySearch && this.props.showOnlySearch !== undefined ? (
              <li className={"d-flex align-items-center"}>
                <Link to="/diamond-search" className="menuItem active">
                  Search
                  {/* {this.state.visible ? (
                  <img onClick={this.submenu.bind(this)} src={downArrowSVG} alt="" />
                ) : (
                  <img onClick={this.submenu.bind(this)} src={upArrowSVG} alt="" />
                )} */}
                </Link>
                {/* <div className={`mobileSubMenu ${this.state.visible && "active"}`}>
                <ul>
                  <li onClick={() => this.props.onClose()}>
                    <Link to="/diamond-list?6112bff42027797e5ad1e757">
                      <IntlMessage id="app.white-diamonds" />
                    </Link>
                  </li>
                  <li onClick={() => this.props.onClose()}>
                    <Link to="/fancy-search">
                      <IntlMessage id="app.fancy-diamonds" />
                    </Link>
                  </li>
                  <li onClick={() => this.props.onClose()}>
                    <Link to="/match-pair">
                      <IntlMessage id="app.match-pair" />
                    </Link>
                  </li>
                  <li onClick={() => this.props.onClose()}>
                    <Link to="/upcoming">
                      <IntlMessage id="app.Upcomming" />
                    </Link>
                  </li>
                  <li onClick={() => this.props.onClose()}>
                    <Link to="/kgk-exclusive">
                      <IntlMessage id="app.Exclusive" />
                    </Link>
                  </li>
                  <li onClick={() => this.props.onClose()}>
                    <Link to="/offerlist">
                      <IntlMessage id="app.KgkCollection" />
                    </Link>
                  </li>
                </ul>
              </div> */}
              </li>
            ) : (
              !this.props.showOnlySearch &&
              this.props.showOnlySearch !== undefined && (
                <>
                  <li className={"d-flex align-items-center"}>
                    <Link to="/diamond-search" className="menuItem active">
                      Search
                    </Link>
                  </li>
                  {this?.props?.showArr && this?.props?.totalNewArrDiamondCountBywebStatus > 0 && (
                    <li onClick={() => this.props.onClose()}>
                      <Link to="/new-arrival">
                        <IntlMessage id="app.newArrival" />
                        {/* <span
                        style={{
                          color:
                            this?.props?.showArr && this?.props?.totalNewArrDiamondCountBywebStatus > 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {this?.props?.showArr && this?.props?.totalNewArrDiamondCountBywebStatus > 0 ? "open" : "close"}
                      </span> */}
                      </Link>
                    </li>
                  )}
                  {this?.props?.showArr && this?.props?.totalBidDiamondCountBywebStatus > 0 && (
                    <li onClick={() => this.props.onClose()}>
                      <Link to="/bid-to-buy">
                        <IntlMessage id="app.bid-to-buy" />
                        {/* <span
                        style={{
                          color:
                            this?.props?.showBid && this?.props?.totalBidDiamondCountBywebStatus > 0 ? "green" : "red",
                        }}
                      >
                        {this?.props?.showArr && this?.props?.totalBidDiamondCountBywebStatus > 0 ? "open" : "close"}
                      </span> */}
                        {/* {this.props.totalBidDiamondCountBywebStatus > 0 ? (
                  <span style={{ color: this.props.showBid ? 'green' : 'red' }}>
                    {this.props.showBid ? 'open' : 'close'}
                  </span>
                ) : (
                  <span style={{ color: 'red' }}>{'close'}</span>
                )} */}
                      </Link>
                    </li>
                  )}
                  {/* <li onClick={() => this.props.onClose()}>
                    <Link to="/my-account/summary">
                      <IntlMessage id="app.myAccount" />
                    </Link>
                  </li> */}
                </>
              )
            )}
            <li
              style={{ fontSize: 18, fontWeight: "bold", textAlign: "center", padding: 5, border: "1px solid black" }}
              onClick={commonLogout}
            >
              <span>Sign Out</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// export default withRouter(Sidebar);
const mapStateToProps = function (props) {
  return props;
};

export default connect(mapStateToProps)(withRouter(MobileSidebar));
