import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntlMessage from "util/IntlMessage";
import { LOCAL_STORAGE_VAR, WHITE_LABEL } from "../../../constants/Common";
import UtilService from "../../../services/util";
class Footer extends Component {
  render() {
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    return (
      <>
        <div className="footerBlock">
          <div className="footerBottomBlock">
            <ul className="d-flex flex-wrap">
              <li>
                <Link to="/about-us">
                  <IntlMessage id="app.AboutUs" />
                </Link>
              </li>
              {/* <li>
                <Link to="/about-us-only">
                  <IntlMessage id="app.AboutUsOnly" />
                </Link>
              </li> */}
              <li>
                <Link to="/contact-us">
                  <IntlMessage id="app.ContactUs" />
                </Link>
              </li>
              <li>
                <Link to="/terms-condition">
                  <IntlMessage id="app.signup.temsCondition" />
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">
                  <IntlMessage id="app.privacyPolicy" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="footerTopblock">
            <p>
              <IntlMessage id="app.Copyright" /> {WHITE_LABEL ? `${authUser.companyName}.` : "KGK Live."}
              <IntlMessage id="app.allRightsReserved" />
            </p>
          </div>
        </div>
      </>
    );
  }
}
export default Footer;
