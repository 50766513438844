import React from "react";

import { Select } from "antd";

import { classNames } from "util/utils";

import "./selectOption.less";

const SelectOption = (props) => {
  const {selectValue} = props;
  let groupClass = classNames(["formSelect from-group", props.hasError && "hasError"]);
  if (props.disabledClass) {
    groupClass = props.disabledClass;
  }
  return (
    <div className={groupClass}>
      <label>{props.diamond ? props?.defaultValue?.length !== 0 && props.label : props.label}</label>
      {props.social ? (
        <Select
          getPopupContainer={(trigger) => trigger}
          dropdownClassName="selectDopdown"
          className={props?.className}
          mode={props?.mode}
          defaultValue={props?.defaultValue}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          value={props?.value}
          disabled={props?.disabled}
          showSearch={props?.showSearch}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
        >
          {selectValue.map((d) => (
            <Select.Option key={d?.id} value={`${d.id}/${d.name}`}>
              {d.name}
            </Select.Option>
          ))}
        </Select>
      ) : !props.diamond ? (
        <Select
          allowClear={props?.allowClear}
          getPopupContainer={(trigger) => trigger}
          // dropdownClassName="selectDopdown"
          className={props?.className}
          mode={props?.mode}
          defaultValue={props?.defaultValue}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          value={props?.value}
          disabled={props?.disabled}
          showSearch={props?.showSearch}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {selectValue.map((d) => {
            return (
              <Select.Option key={d.id} value={d.id}>
                {d.name}
              </Select.Option>
            );
          })}
        </Select>
      ) : props.mode === "tags" ? (
        <Select
          // dropdownClassName="selectDopdown"
          className={props?.className}
          mode={props?.mode}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          defaultValue={props?.defaultValue}
          value={props?.value}
          onBlur={props?.onBlur}
          showSearch={props?.showSearch}
          onSearch={(e) => props.getFil(e)}
          filterOption={false}
          onDeselect={(e) => {
            props.onDeselect(e);
          }}
          // onInputKeyDown={(e) => {
          //   props.onInputKeyDown(e);
          // }}
        >
          {props.diamond && props.diamondInput.length < 3 && selectValue.length === 0 && (
            <Select.Option value="word" disabled>
              Please enter 3 words to search
            </Select.Option>
          )}
          {selectValue.map((d) => (
            <Select.Option key={d.id}>{d.name}</Select.Option>
          ))}
        </Select>
      ) : (
        <Select
          dropdownClassName="selectDopdown"
          className={props?.className}
          mode={props?.mode}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          defaultValue={props?.defaultValue}
          value={props?.value}
          onBlur={props?.onBlur}
          showSearch={props?.showSearch}
          onSearch={(e) => props.getFil(e)}
          filterOption={false}
          onDeselect={(e) => {
            props.onDeselect(e);
          }}
        >
          {props.diamond && props.diamondInput.length < 3 && selectValue.length === 0 && (
            <Select.Option value="word" disabled>
              Please enter 3 words to search
            </Select.Option>
          )}
          {selectValue.map((d) => (
            <Select.Option key={d.id}>{d.name}</Select.Option>
          ))}
        </Select>
      )}

      <span style={{ color: "red" }}>{props.hasError ?? ""}</span>
    </div>
  );
};
export default SelectOption;
