import React from "react";

import IntlMessage from "util/IntlMessage";

const DetailView = {
  section1: {
    name: <IntlMessage id="app.basicDetail" />,
    data: [
      { part: 1, label: <IntlMessage id="app.StockNo" />, key: "vStnId" },
      { part: 1, label: <IntlMessage id="app.ReportNo" />, key: "rptNo" },
      { part: 1, label: <IntlMessage id="app.Lab" />, key: "lbNm" },
      { part: 1, label: "Rap.($)", key: "rap" },
      { part: 1, label: <IntlMessage id="app.Shape" />, key: "shpNm" },
      { part: 1, label: <IntlMessage id="app.carat" />, key: "crt" },
      { part: 1, label: <IntlMessage id="app.Color" />, key: "colNm" },
      { part: 1, label: <IntlMessage id="app.Clarity" />, key: "clrNm" },
      { part: 1, label: <IntlMessage id="app.Shade" />, key: "shdNm" },
      { part: 1, label: <IntlMessage id="app.cut" />, key: "cutNm" },
      { part: 1, label: <IntlMessage id="app.polish" />, key: "polNm" },
      { part: 1, label: <IntlMessage id="app.symm" />, key: "symNm" },
      { part: 1, label: <IntlMessage id="app.flor" />, key: "fluNm" },
      { part: 1, label: <IntlMessage id="app.location" />, key: "locNm" },
    ],
  },
  section2: {
    name: <IntlMessage id="app.Measurements" />,
    data: [
      { label: <IntlMessage id="app.compare.tableper" />, key: "tblPer" },
      { label: <IntlMessage id="app.total-depth" />, key: "depPer" },
      { label: <IntlMessage id="app.length" />, key: "length" },
      { label: <IntlMessage id="app.width" />, key: "width" },
      { label: <IntlMessage id="app.depth" />, key: "height" },
      { label: <IntlMessage id="app.ratio" />, key: "ratio" },
      { label: <IntlMessage id="app.crAn" />, key: "cAng" },
      { label: <IntlMessage id="app.crHt" />, key: "cHgt" },
      { label: <IntlMessage id="app.pavAn" />, key: "pAng" },
      { label: <IntlMessage id="app.pavHt" />, key: "pHgt" },
      { label: <IntlMessage id="app.Girdle" />, key: "girdleStr" },
      { label: <IntlMessage id="app.culet" />, key: "cultNm" },
      { label: <IntlMessage id="app.diamonddetails.Inclusion.luster" />, key: "lstrNm" },
      { label: <IntlMessage id="app.diamonddetails.Additionalinfo.laserinscription" />, key: "lsrInc" },
    ],
  },
  section3: {
    name: <IntlMessage id="app.diamonddetails.Inclusion" />,
    data: [
      { label: <IntlMessage id="app.TableBlack" />, key: "blkCrnNm" },
      { label: <IntlMessage id="app.SideBlack" />, key: "blkSdNm" },
      { label: <IntlMessage id="app.TableInclusion" />, key: "wCrnNm" },
      { label: <IntlMessage id="app.Sideinclusion" />, key: "wSdNm" },
      { label: <IntlMessage id="app.TableOpen" />, key: "opTblNm" },
      { label: <IntlMessage id="app.CrownOpen" />, key: "opCrwnNm" },
      { label: <IntlMessage id="app.PavilionOpen" />, key: "opPavNm" },
      { label: <IntlMessage id="app.eye-clean" />, key: "eClnNm" },
      { label: <IntlMessage id="app.heart-arrow" />, key: "hANm" },
      { label: <IntlMessage id="app.Brilliancy" />, key: "brlncyNm" },
      { label: <IntlMessage id="app.Type2Cert" />, key: "type2Nm" },
      { label: <IntlMessage id="app.CountryOfOrigin" />, key: "org" },
      { label: <IntlMessage id="app.RoughMine" />, key: "" },
      { label: <IntlMessage id="app.NaturalGirdle" />, key: "nrlCrwnNm" },
      { label: <IntlMessage id="app.NaturalCrown" />, key: "nrlPavNm" },
      { label: <IntlMessage id="app.NaturalPavilion" />, key: "nrlGrdlNm" },
      { label: <IntlMessage id="app.InternalGraining" />, key: "intrnlGrningNm" },
      { label: <IntlMessage id="app.SurfaceGraining" />, key: "srfcGrningNm" },
    ],
  },
  section4: {
    name: <IntlMessage id="app.OtherInformation" />,
    data: [
      { label: <IntlMessage id="app.keyToS" />, key: "kToSStr" },
      { hide: true },
      { label: <IntlMessage id="app.ReportComments" />, key: "lbCmt" },
    ],
  },
};

export default DetailView;
