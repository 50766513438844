import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useTable } from "react-table";

import { Drawer, Tooltip } from "antd";
import { get, groupBy } from "lodash";

import NoDataFound from "components/common/NoDataFound";

import { RowSelectService } from "services/RowSelectService";

import { useRefEffect } from "util/hooks";
import useFilters from "util/useFilters";
import useSortBy from "util/useSortBy";
import { classNames, isNotEmpty, objectToQueryString, sortArrayByKey, titleCase , isArray, isEmpty } from "util/utils";

import { LAB_LINKS, FILE_URLS, LOCAL_STORAGE_VAR, TABLE_PAGE_LIMIT, PAGES } from "constants/Common";

import buyNowSvg from "assets/svg/buy-now.svg";
import imageSvg from "assets/svg/camera.svg";
import certSvg from "assets/svg/certi.svg";
import videoSvg from "assets/svg/video.svg";
import { getPath, getColumn } from "./DiamondListFunctions";
import DiamondListingHead from "./ListTableHeaderBack";

import DiamondDetail from "../DiamondDetail";
import CheckBox from "../common/CheckBox";
import Status from "../common/DiamondListing/Status";
import TableGrouping from "../common/DiamondListing/TableGrouping";


const LISTINGPAGES = PAGES;

export const canSortCommon = {
  stoneId: "Stone No",
  shpNm: "Shape",
  crt: "Carat",
  colNm: "Color",
  clrNm: "Clarity",
};
export const LIMIT = TABLE_PAGE_LIMIT;
export const LIMIT_500 = 500;
// export const FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm', 'locNm'];
export const floatkeys = [
  "amt",
  "back",
  "cAng",
  "cHgt",
  "crt",
  "ctPr",
  "depPer",
  "fnDis",
  "grdlPer",
  "height",
  "length",
  "pAng",
  "pHgt",
  "rap",
  "rapAvg",
  "ratio",
  "strLn",
  "width",
];
const NOCHECKIDS = ["Details", "Action", "quote", "expBack", "hours", "bidPricePerCarat", "note"];
export const roundkeys = ["tblPer", "lwrHal"];
const DISPLAY_TOTAL = ["ctPr", "amt", "rap", "crt", "fnCtpr", "fnAmt"];
export const BOLD_DISPLAY = [
  "ctPr",
  "amt",
  "rap",
  "crt",
  "clrNm",
  "back",
  "rapAvg",
  "bidDiscount",
  "bidPricePerCarat",
  "bidAmount",
];
const getBoldId = (id) => {
  if (BOLD_DISPLAY.includes(id)) return true;
  return false;
};
const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass ? item.cellClass.replace("text-", "") : "center",
      width: `${item.width || "100"  }px`,
      fontWeight: type === "cell" && getBoldId(item.id) ? "600" : "",
      color:
        type === "cell" && (item.id === "lbNm" || item.id === "rptNo" || item.link)
          ? "#344cbb"
          : item.id === "back"
          ? "green"
          : "",
    },
  },
];
const headerProps = (props, { column }) => getStyles(props, column, "header");
const cellProps = (props, { cell }) => getStyles(props, cell.column, "cell");

const Actions = React.memo((props) => {
  const {
    isHeader,
    row = {},
    displayedRows,
    disabled,
    nostatus,
    pairStkNo,
    showSts,
    detailRemoveFromOrderList,
  } = props;
  const { currentType, noCheckBox } = props;

  const propsRef = React.useRef(props);
  useRefEffect(propsRef, props);

  useEffect(() => {
    const { row, isHeader, areAllChecked, currentType } = propsRef.current ?? {};
    if ((isHeader && areAllChecked) || row?.isDefaultChecked) RowSelectService.selectRows(currentType, [row]);
  }, []);

  const isChecked = useSelector((state) => {
    const { row, isHeader, displayedRows } = propsRef.current ?? {};
    const idList = state.diamondData.selectedRowIds?.[currentType] ?? [];
    return isHeader
      ? Boolean(displayedRows.length > 0 && displayedRows.map((r) => r.id).every((id) => idList.includes(id)))
      : idList.includes(row?.selectionKey ?? row?.id);
  });

  const toggleSelection = React.useCallback(() => {
    // if (row?.disableSelection) {
    //   if (row?.onCheckError) notify.error({ message: row?.onCheckError });
    //   return;
    // }

    if (row?.pairStkNo) {
      const rows = displayedRows.filter((_row) => [_row.pairStkNo, _row.pairStkNoList].includes(row.pairStkNo));
      isChecked ? RowSelectService.unSelectRows(currentType, rows) : RowSelectService.selectRows(currentType, rows);
    }

    isHeader
      ? isChecked
        ? RowSelectService.unSelectRows(currentType, displayedRows)
        : RowSelectService.selectRows(currentType, displayedRows)
      : isChecked
      ? RowSelectService.unSelectRows(currentType, [row])
      : RowSelectService.selectRows(currentType, [row]);
  }, [row, isHeader, isChecked, currentType, displayedRows]);

  return (
    <div className="selectActionIcon">
      {!nostatus && !detailRemoveFromOrderList && (
        <Status
          status={showSts ? row.sSts : isHeader ? "all" : row.wSts}
          winloss={getPath() === LISTINGPAGES.MYBID ? row.status : null}
        />
      )}
      {!noCheckBox && (!row || (row && !row.pairStkNoList)) && (
        <div
          className={
            `selectActionIconWrapper${ 
            pairStkNo && row.pairStkNo ? " pairStkNo" : "" 
            }${row.altCert ? " doubleRow" : ""}`
          }
          style={pairStkNo && row.pairStkNo ? { bottom: row.altCert ? "-31px" : "-14px" } : {}}
        >
          <CheckBox
            disabled={disabled}
            key={`selection_${isHeader ? "header" : row.id}`}
            checked={isChecked}
            onChange={toggleSelection}
            className={classNames([row?.disableSelection && ".disabled"])}
          />
        </div>
      )}
    </div>
  );
});
Actions.displayName = "Actions";

const Table = React.memo((props) => {
  const {
    overrideColumns = {},
    handleSort,
    sort,
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    currentType,
    areAllChecked = false,
    FilterOption = true,
    canSort = true,
    pairStkNo = true,
    showSts = false,
    detailRemoveFromOrderList = false,
    setFilteredDiamondCount
  } = props;

  const [visible, setVisible] = useState(undefined);
  const [detail, setDetail] = useState(undefined);

  const data = React.useMemo(() => {
    if (!isArray(props.data)) return props.data;
    const list = [...props.data];
    return list
      .map((record, i) => {
        if (record.pairStkNo) {
          if (list[i - 1] && list[i - 1].pairStkNo === record.pairStkNo) {
            record.pairStkNo = undefined;
            record.pairStkNoList = list[i - 1].pairStkNo;
          }
        }
        return { ...record };
      })
      .sort((a, b) => a.pairStkNo - b.pairStkNo);
  }, [props.data]);

  const columns = useMemo(() => {
    let columns = isEmpty(props.columns) ? getColumn() : props.columns;
    const grdlPerIndex = columns.findIndex((c) => get(c, "id") === "grdlPer");
    const girdleStrIndex = columns.findIndex((c) => get(c, "id") === "girdleStr");
    if (grdlPerIndex > -1 && girdleStrIndex > -1) {
      const grdlPerColumn = { ...columns[grdlPerIndex] };
      columns[grdlPerIndex] = undefined;
      columns.splice(girdleStrIndex + 1, 0, grdlPerColumn);
    }

    const mlkNmIndex = columns.findIndex((c) => get(c, "id") === "mlkNm");
    const shdNmIndex = columns.findIndex((c) => get(c, "id") === "shdNm");
    if (mlkNmIndex > -1 && shdNmIndex > -1) {
      const mlkNmColumn = { ...columns[mlkNmIndex] };
      columns[mlkNmIndex] = undefined;
      columns.splice(shdNmIndex + 1, 0, mlkNmColumn);
    }

    const lstrIndex = columns.findIndex((c) => get(c, "id") === "lstr");
    const strLnIndex = columns.findIndex((c) => get(c, "id") === "strLn");
    if (lstrIndex > -1 && strLnIndex > -1) {
      const lstrColumn = { ...columns[lstrIndex] };
      columns[lstrIndex] = undefined;
      columns.splice(strLnIndex + 1, 0, lstrColumn);
    }

    columns = columns.filter(Boolean);
    columns = columns.filter((column) => {
      return column.id !== "dna";
    });

    if (detailRemoveFromOrderList === true) {
      columns = columns.filter((column) => {
        return column.id !== "invoice" && column.id !== "Details";
      });
    }

    return nocheck
      ? columns
      : [
          {
            id: "selection",
            Header({ rows }) {
              const originalRows = rows.map((r) => r.original);
              return (
                <Actions
                  showSts={showSts}
                  currentType={currentType}
                  areAllChecked={areAllChecked}
                  noCheckBox={noCheckBox}
                  displayedRows={originalRows}
                  isHeader
                  detailRemoveFromOrderList={detailRemoveFromOrderList}
                />
              );
            },
            Cell({ row, rows }) {
              return (
                <Actions
                  showSts={showSts}
                  currentType={currentType}
                  areAllChecked={areAllChecked}
                  noCheckBox={noCheckBox}
                  pairStkNo={pairStkNo}
                  nostatus={nostatus}
                  row={row.original}
                  disabled={"groupNo" in row.original}
                  displayedRows={rows.map((r) => r.original)}
                  detailRemoveFromOrderList={detailRemoveFromOrderList}
                />
              );
            },
          },
          ...columns,
        ];
  }, [props.columns, nocheck, showSts, currentType, areAllChecked, noCheckBox, pairStkNo, nostatus]);

  const isMultiSortEvent = useCallback(() => true, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
    filteredRows,
  } = useTable(
    {
      columns: columns.sort(sortArrayByKey("sequence")),
      data,
      manualSortBy: true,
      isMultiSortEvent,
      disableSortBy: !canSort,
      initialState: {
        sortBy:
          sort
            ?.map?.((item) => {
              return Object.entries(item).map(([id, desc]) => {
                return { id, title: titleCase(id), desc: `${desc}`.toUpperCase() === "DESC" };
              })?.[0];
            })
            .filter(isNotEmpty) ?? [],
      },
    },
    useFilters,
    useSortBy
  );
  setFilteredDiamondCount && setFilteredDiamondCount(rows.length)
  useEffect(() => {
    const newSort = sortBy?.map?.((sort) => ({ [sort.id]: sort.desc ? "DESC" : "ASC" })) ?? [];
    if (JSON.stringify(sort ?? []) !== JSON.stringify(newSort)) void handleSort?.(newSort);
  }, [handleSort, sort, sortBy]);

  const clickHandler = useCallback(
    (e, row, cell) => {
      if (cell.column.id === "selection") return;
      if (cell.column.link && cell.value) {
        let field = cell.column.link.slice(cell.column.link.indexOf("$") + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf("$"));
        const link = cell.column.link.replace(`$${  field  }$`, row.original[field]);
        window.open(link);
      } else if (["shpNm"].includes(cell.column?.id)) {
        //
      } else if (cell.column.id === "dna") {
        window.open(`
         https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/DNA.html?id=${row?.original?.vStnId}`);
      } else if (cell.column.id === "vStnId") {
        const id = row.original?.diamondId ?? row.original?.id;
        const view = ["/account/order-list", "/bid-to-buy", "/new-arrival", "/my-bid"].includes(
          window.location.pathname
        )
          ? 0
          : 1;
        window.open(`/diamond-detail/${id}${objectToQueryString({ view })}`);
      } else if (cell.column.id === "shpNm") {
        setVisible(row.original);
      } else if (cell.column.id === "ftc" && cell.value) {
        window.open(`/ftc/${row.original.id}`);
      } else if ((cell.column.id === "lbNm" || cell.column.id === "rptNo") && row.original.lbNm && row.original.rptNo) {
        window.open(LAB_LINKS[row.original.lbNm.toUpperCase()].replace("***", row.original.rptNo));
      } else if (!NOCHECKIDS.includes(cell.column.id)) {
        if (props.nocheck || props.noCheckBox) return;
        const {parentNode} = e.currentTarget;
        if (parentNode) {
          const checkboxRef = parentNode.getElementsByTagName("input");
          if (checkboxRef && checkboxRef.length > 0) {
            checkboxRef[0].click();
          }
        }
      }
    },
    [props.noCheckBox, props.nocheck]
  );

  const finalFilteredRows = React.useMemo(() => filteredRows.map((r) => r.original), [filteredRows]);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.sort(sortArrayByKey("sequence")).map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps(headerProps)}
                  style={{ width: `${column.width || 100  }px` }}
                >
                  {column.id === "selection"
                    ? column.render("Header")
                    : column.id !== "action" && (
                    <DiamondListingHead
                      toggleSortBy={toggleSortBy}
                      setSortBy={setSortBy}
                      sortBy={sortBy}
                      column={column}
                      filters={filters}
                      nodots={nodots}
                      FilterOption={FilterOption}
                    />
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading
            ? null
            : !!rows.length &&
              rows.map((row) => {
                prepareRow(row);
                return (
                  <>
                    {!noGrp && (
                      <>
                        {row.original.isMatchHeader && (
                          <TableGrouping
                            displayTotal={DISPLAY_TOTAL.filter((el) => columns.find((c) => c.id === el))}
                            multipleCheckBox
                            row={row.original}
                          >
                            <Actions
                              showSts={showSts}
                              currentType={currentType}
                              areAllChecked={areAllChecked}
                              noCheckBox={noCheckBox}
                              displayedRows={groupBy(finalFilteredRows, "groupNo")[row.original.groupNo]}
                              isHeader
                              nostatus
                            />
                          </TableGrouping>
                        )}
                        {(row.original.isConfirmHeader ||
                          row.original.isUpcomingHeader ||
                          row.original.isBidHeader ||
                          row.original.isOfferHeader) && <TableGrouping row={row.original} />}
                        {row.original.isOfficeHeader && <TableGrouping row={row.original} />}
                      </>
                    )}
                    <tr
                      className={
                        row.original.LowerBid === 3
                          ? "low-bid"
                          : row.original.LowerBid === 1
                          ? "highest-bid"
                          : row.original.LowerBid === 2
                          ? "equal-bid"
                          : row.original.lbNm !== "GIA"
                          ? row.original.lbNm
                          : ""
                      }
                      {...row.getRowProps({
                        "table-row": currentType + row.original.id,
                      })}
                    >
                      {row.cells.map((cell) => (
                        <MemoizedCell
                          key={`${cell.column.id  }_cell`}
                          row={row}
                          cell={cell}
                          overrideColumns={overrideColumns}
                          clickHandler={clickHandler}
                          setDetail={setDetail}
                          setVisible={setVisible}
                        />
                      ))}
                    </tr>
                    {!noGrp && (
                      <>
                        {row.original.isMatchFooter && (
                          <TableGrouping
                            displayTotal={DISPLAY_TOTAL.filter((el) => columns.find((c) => c.id === el))}
                            columns={columns}
                            row={row.original}
                          />
                        )}
                      </>
                    )}
                  </>
                );
              })}
        </tbody>
      </table>
      <Drawer
        onClose={() => setVisible(undefined)}
        visible={!isEmpty(visible)}
        wrapClassName="diamondDetailPopup xl-size"
        destroyOnClose
      >
        {!isEmpty(visible) && <DiamondDetail diamondPopup data={visible} onClose={() => setVisible(undefined)} />}
      </Drawer>
      <Drawer
        onClose={() => setDetail(undefined)}
        visible={!isEmpty(detail)}
        wrapClassName="diamondListinSidebar onlyImageSlider"
        destroyOnClose
      >
        <div className="diamondImagePopup">
          {detail?.i && (
            <div className="diamondDetailImageBox">
              <img alt="" height="100%" width="100%" src={FILE_URLS.img.replace("***", detail?.i.vStnId)} />
            </div>
          )}
          {detail?.v && (
            <iframe
              title={FILE_URLS.videoFile.replace("***", detail?.v.vStnId)}
              src={FILE_URLS.videoFile.replace("***", detail?.v.vStnId)}
              height="100%"
              width="100%"
            />
          )}
          {detail?.c && (
            <img alt="" src={FILE_URLS.certFile.replace("***", detail?.c.rptNo)} height="100%" width="100%" />
          )}
        </div>
      </Drawer>
    </>
  );
});
Table.displayName = "Table";

const MemoizedCell = React.memo((props) => {
  const { cell, row, overrideColumns } = props;
  const { clickHandler, setDetail } = props;
  const { setVisible } = props;

  const OverrideCell = overrideColumns?.[cell?.column?.id];

  if (cell.column.id === "maxBid") {
    return <td style={{ color: "#00008b", fontWeight: "500" }}>{parseFloat(cell.value).toFixed(2)}</td>;
  }

  if (cell.column.id === "shpNm") {
    const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).SHAPE || {};
    const value = masterData?.find((x) => x.code === cell.value)?.webDisplay;
    const shpValue = value === undefined ? cell.value : value;

    return (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {shpValue}
      </td>
    );
  }

  if (cell.column.id === "colNm") {
    return (
      <td {...cell.getCellProps(cellProps)}>
        {cell.row.original.isFcCol === true ? (cell.row.original.fcColNm ? cell.row.original.fcColNm : "") : cell.value}
      </td>
      // <td {...cell.getCellProps(cellProps)}>
      //   {cell.row.original.isFcCol === true
      //     ? (cell.row.original.intenNm ? cell.row.original.intenNm : '') +
      //       ' ' +
      //       (cell.row.original.ovrtnNm ? cell.row.original.ovrtnNm : '') +
      //       ' ' +
      //       (cell.row.original.fcColNm ? cell.row.original.fcColNm : '')
      //     : cell.value}
      // </td>
    );
  }

  return (
    <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
      {!isEmpty(OverrideCell) ? (
        <OverrideCell {...props} />
      ) : cell.column.id === "Details" ? (
        <Resource setVisible={setVisible} original={row.original} setDetail={setDetail} />
      ) : cell.column.id === "org" ? (
        !cell.value || cell.value.trim().length === 0 ? (
          "-"
        ) : (
          cell.value
        )
      ) : (
        cell.render("Cell")
      )}
    </td>
  );
});
MemoizedCell.displayName = "MemoizedCell";

const Resource = React.memo((props) => {
  const { original, setDetail, setVisible } = props;
  // const [showBuyIcon, setShowBuyIcon] = useState(true);
  let showBuyIcon = true;
  const clickHandler = useCallback((key) => setDetail({ [key]: original }), [original, setDetail]);
  // const currentType = useCurrentType();
  const tab = ["/new-arrival", "/bid-to-buy", "/my-bid"];

  if (tab.includes(window && window.location && window.location.pathname)) {
    // setShowBuyIcon(false);
    showBuyIcon = false;
  }

  return (
    <div className="tableSmallImage">
      <Tooltip placement="bottom" title={<span>Image</span>}>
        <img src={imageSvg} width="15px" alt="" onClick={() => clickHandler("i")} />
      </Tooltip>
      <Tooltip placement="bottom" title={<span>Video</span>}>
        <img src={videoSvg} width="15px" alt="" onClick={() => clickHandler("v")} />
      </Tooltip>
      <Tooltip placement="bottom" title={<span>Certificate</span>}>
        <img src={certSvg} width="15px" alt="" onClick={() => clickHandler("c")} />
      </Tooltip>
      {showBuyIcon && (
        <Tooltip placement="bottom" title={<span>Buy Now</span>}>
          <img src={buyNowSvg} width="15px" alt="" onClick={() => setVisible(original)} />
        </Tooltip>
      )}
    </div>
  );
});
Resource.displayName = "Resource";

const TableWrapper = React.memo((props) => {
  if (props.loading || !isArray(props.data) || isEmpty(props.data)) return <NoDataFound loading={props.loading} />;

  return <Table {...props} />;
});
TableWrapper.displayName = "TableWrapper";

export default TableWrapper;
