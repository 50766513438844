import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import urlJoin from "url-join";

import { notification } from "antd";
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import join from "lodash/join";

import IntlMessage from "util/IntlMessage";

import { BASE_URL, MIME_TYPES, LOCAL_STORAGE_VAR, KYC_STATUS } from "../../../constants/Common";
import {
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  GET_CITY_LIST,
  FETCH_USER,
  ADMIN_UPDATE_USER,
  UPLOAD_FILE,
} from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import { isMobile } from "../../DiamondList/DiamondListFunctions";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";
import ProfileUpload from "../../common/ProfileUpload";
import SelectOption from "../../common/SelectOption";
import UploadImage from "../../common/UploadImage/";
import DefaultUser from "../../common/UserProfileDefault";

class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      states: [],
      cities: [],
      errors: [],
      data: {},
      doc: [],
      path: "",
      isApproved: KYC_STATUS.PENDING,
      changes: false,
    };
  }
  componentDidMount() {
    this.fetchUser();
    this.getCountries();
  }

  getCountries = () => {
    const self = this;
    const obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        self.setState({ countries: data.data });
      }
    });
  };

  getStates = (countryId) => {
    const self = this;
    const obj = {
      ...GET_STATE_LIST,
      request: { country: countryId },
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        self.setState({ states: data.data });
      }
    });
  };

  getCities = (stateId) => {
    const self = this;
    const obj = {
      ...GET_CITY_LIST,
      request: { state: stateId },
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        self.setState({ cities: data.data });
      }
    });
  };

  fetchUser() {
    const self = this;

    const obj = {
      ...FETCH_USER,
    };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;
        if (authUser.isApproved) {
          self.setState({ isApproved: authUser.isApproved });
        }
        !isEmpty(data.data.kyc) &&
          data.data.kyc.forEach((k) => {
            if (k.type === "personal") self.setState({ doc: [k.docType], path: k.path }, () => {});
          });

        self.setState({ data: data.data });
        self.getStates(data.data.country);
        self.getCities(data.data.state);
      }
    });
  }

  updateUser() {
    const self = this;
    const reqObj = clone(self.state.data);

    reqObj.name = `${reqObj.firstName} ${reqObj.lastName}`;
    //reqObj.kyc = [{ ...reqObj.kyc, path: reqObj.photoId }];
    reqObj.kyc = self.props.kyc;
    if (self.state.changes) {
      reqObj.isKycUploaded = true;
      reqObj.isApproved = 1;
    }
    reqObj.address = reqObj.address;
    //reqObj.address = join([reqObj.address, reqObj.address2, reqObj.address3], ",");

    const obj = {
      ...ADMIN_UPDATE_USER,
      request: reqObj,
    };

    if (self.handleValidation()) {
      UtilService.callApi(obj, (err, data) => {
        if (err && err.data) {
          if (err.data.code === "E_USER_NOT_FOUND") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
          if (err.data.code === "E_DUPLICATE") {
            return OpenNotification({
              type: "error",
              title: err.data.message,
            });
          }
        }
        if (data && data.code === "OK") {
          //self.props.onClose();
          const storageObj = reqObj;
          storageObj.account = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;

          UtilService.setLocalStorageItem(storageObj, `${LOCAL_STORAGE_VAR}-user`);

          OpenNotification({
            type: "success",
            title: "Personal details are updated successfully.",
          });
          self.props.onClose();
        }
      });
    }
  }

  uploadDocument = (ee, type) => {
    const e = cloneDeep(ee);
    const self = this;
    const errors = clone(this.state.errors);
    const formData = clone(this.state.data);
    let path = clone(this.state.path);

    const blob = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      const arr = new Uint8Array(ev.target.result).subarray(0, 4);
      let header = "";
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: "error",
          title: "File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.",
        });
      } else {
        const data = new FormData();
        data.append("folder", "user");
        data.append("file", e.target.files[0]);

        const objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, (err, data) => {
          if (err) throw err;
          if (data && data.code === "OK") {
            errors[type] = undefined;
            if (type === "photoId") {
              self.props.handleKyc("personal", data.data.files[0].absolutePath, self.state.doc);
              path = data.data.files[0].absolutePath;
            }
            formData[type] = data.data.files[0].absolutePath;
            self.setState({ data: formData, path: path, errors });
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };
  handleInputChange = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ data: data });
  };

  handleValidation = () => {
    const formData = this.state.data;
    const errors = this.state.errors;
    let formIsValid = true;
    const blankField = [];
    const invalidFiels = [];
    if (!formData["firstName"]) {
      blankField.push("First Name");
      formIsValid = false;
    }
    if (formData["firstName"]) {
      if (!formData["firstName"].match(/^[a-zA-Z ]+$/)) {
        invalidFiels.push("firstName");
        formIsValid = false;
        errors["firstName"] = "Please enter valid First Name.";
      } else {
        errors["firstName"] = undefined;
      }
    }

    if (!formData["address"]) {
      blankField.push("Address 1");
      formIsValid = false;
    }

    if (!formData["email"]) {
      blankField.push("Email Address");
      formIsValid = false;
    }
    if (formData["email"]) {
      if (formData["email"] && !formData["email"].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)) {
        invalidFiels.push("Email");
        formIsValid = false;
        errors["email"] = "Please enter valid Email.";
      } else {
        errors["email"] = undefined;
      }
    }
    if (!formData["mobile"]) {
      blankField.push("Mobile No.");
      formIsValid = false;
    }
    if (formData["mobile"] && errors["mobile"]) {
      invalidFiels.push("Mobile No.");
      formIsValid = false;
    }

    if (blankField.length > 0) {
      const arr = blankField;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    if (invalidFiels.length > 0) {
      const arr = invalidFiels;
      let outStr = "";
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(" and ");
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
      }
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  render() {
    const { countries, data, states, cities } = this.state;
    const PERSONAL_DOCS =
      (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
        JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_PERSONAL) ||
      [];
    //const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    return (
      <div>
        <div className="profileHead">
          <Heading title={<IntlMessage id="app.PersonalInformation" />} className="p-0 popupInnerTitle"></Heading>
          <div className="personalProfileWrapper">
            <div className="editProfileLogo">
              {data.profileImage ? (
                <img src={urlJoin(BASE_URL, data.profileImage)} alt="" />
              ) : (
                <DefaultUser size="lg-size" />
              )}
            </div>
            <div className="profileAction">
              <ProfileUpload
                label={<IntlMessage id="app.EditProfile" />}
                accept="jpg, png,jpeg,PNG,JPEG,JPG"
                onChange={(e) => this.uploadDocument(e, "profileImage")}
              />
              {data.profileImage && (
                <span onClick={() => this.handleInputChange("profileImage", "")}>
                  <IntlMessage id="app.RemoveProfile" />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="profileDetailSpace">
          <IntlMessage id="app.FirstNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("firstName", e.target.value)}
                placeholder={placeholder}
                value={data.firstName}
                label={
                  <>
                    <IntlMessage id="app.FirstNameLabel" />
                  </>
                }
              />
            )}
          </IntlMessage>

          <IntlMessage id="app.LastNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("lastName", e.target.value)}
                placeholder={placeholder}
                value={data.lastName}
                label={
                  <>
                    <IntlMessage id="app.LastNameLabel" />
                  </>
                }
              />
            )}
          </IntlMessage>
          <IntlMessage id="app.address">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("address", e.target.value)}
                placeholder={placeholder}
                value={data.address}
                label={
                  <>
                    <IntlMessage id="app.address" /> *
                  </>
                }
              />
            )}
          </IntlMessage>

          <InputBlock
            ISDplaceholder="ISD"
            STDplaceholder="STD"
            onChange={(e) => this.handleInputChange("phone1", e.target.value)}
            value={data.phone1}
            // hasError={errors["phone2"] ? true : false}
            type="number"
            label="Phone No 1"
            placeholder="ISD/STD - Phone No 1"
          />
          <InputBlock
            ISDplaceholder="ISD"
            STDplaceholder="STD"
            onChange={(e) => this.handleInputChange("phone2", e.target.value)}
            value={data.phone2}
            // hasError={errors["phone2"] ? true : false}
            type="number"
            label="Phone No 2"
            placeholder="ISD/STD - Phone No 2"
            // hasError={errors["phone2"] ? true : false}
          />
          {/* <IntlMessage id="app.Address3Label">
            {(placeholder) => (
              <InputBlock
                onChange={(e) =>
                  this.handleInputChange("address3", e.target.value)
                }
                value={data.address3}
                placeholder={placeholder}
                label={<IntlMessage id="app.Address3Label" />}
              />
            )}
          </IntlMessage> */}
          <IntlMessage id="app.EmailLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("email", e.target.value)}
                placeholder={placeholder}
                value={data.email}
                label={
                  <>
                    <IntlMessage id="app.EmailLabel" />
                  </>
                }
              />
            )}
          </IntlMessage>
          <SelectOption
            value={data.country}
            showSearch
            selectValue={countries}
            onChange={(value) => {
              const data = clone(this.state.data);
              data["state"] = undefined;
              data["city"] = undefined;

              data["country"] = value;
              this.getStates(value);
              this.setState({ data, cities: [] });
            }}
            label={
              <>
                <IntlMessage id="app.CountryLabel" />
              </>
            }
          />
          <SelectOption
            showSearch
            value={data.state}
            selectValue={states}
            onChange={(value) => {
              const data = clone(this.state.data);

              data["state"] = value;
              this.getCities(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessage id="app.StateLabel" />
              </>
            }
          />
          <SelectOption
            value={data.city}
            showSearch
            selectValue={cities}
            onChange={(value) => {
              const data = clone(this.state.data);
              data["city"] = value;
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessage id="app.CityLabel" />
              </>
            }
          />
          <IntlMessage id="app.ZipCode">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("pinCode", e.target.value)}
                type="number"
                placeholder={placeholder}
                value={data.pinCode}
                label={
                  <>
                    <IntlMessage id="app.ZipCode" />
                  </>
                }
              />
            )}
          </IntlMessage>

          <IntlMessage id="app.MobileNoLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange("mobile", e.target.value)}
                type="number"
                placeholder={placeholder}
                value={data.mobile}
                label={
                  <>
                    <IntlMessage id="app.MobileNoLabel" />
                  </>
                }
              />
            )}
          </IntlMessage>

          <InputBlock
            ISDplaceholder="ISD"
            STDplaceholder="STD"
            onChange={(e) => this.handleInputChange("fax", e.target.value)}
            value={data.fax}
            type="number"
            label={
              <>
                <IntlMessage id="app.FaxNo" />
              </>
            }
            placeholder="ISD/STD - Fax No"
          />
          <SelectOption
            // disabled={this.state.isApproved === KYC_STATUS.APPROVED}
            value={
              find(PERSONAL_DOCS, { id: this.state.doc }) ? find(PERSONAL_DOCS, { id: this.state.doc }).name : undefined
            }
            placeholder={"Select Document"}
            selectValue={PERSONAL_DOCS}
            onChange={(value) => {
              this.setState({ doc: value, changes: true }, () =>
                this.props.handleKyc("personal", null, this.state.doc)
              );
            }}
            label={
              <>
                <IntlMessage id="app.DocumentType*" />
              </>
            }
          />
          <UploadImage
            // disabled={this.state.isApproved === KYC_STATUS.APPROVED}
            // file={data.photoId}
            file={this?.state?.path}
            leftAlign
            onUpload={(e) => {
              this.setState({ changes: true });
              this.uploadDocument(e, "photoId");
            }}
          />
          {this.state.isApproved === KYC_STATUS.APPROVED && (
            <span>
              <IntlMessage id="app.KYCApproved" />
            </span>
          )}
        </div>
        <div className="sideBarPopupButton">
          <span className="commonButton" onClick={() => this.updateUser()}>
            <IntlMessage id="app.my-account.SavePersonalDetail" />
          </span>
          <span
            className="commonOutline"
            onClick={() => {
              isMobile() ? this.props.history.goBack() : this.props.onClose();
            }}
          >
            <IntlMessage id="app.Cancel" />
          </span>
        </div>
      </div>
    );
  }
}
export default withRouter(PersonalInformation);
