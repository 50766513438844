import debounce from "lodash/debounce";

import { isArray, isFunction, isHTMLElement, isString } from "util/utils";

export class HeightUpdater {
  constructor(calculateFrom, setHeightTo, initialAdjustment) {
    this.setCalculateFrom(calculateFrom);
    this.setSetHeightTo(setHeightTo);
    this.setInitialAdjustment(initialAdjustment);
    this.resizeListener = false;
  }

  setCalculateFrom(calculateFrom = []) {
    if (isString(calculateFrom)) calculateFrom = calculateFrom.split(",");
    this.calculateFrom = calculateFrom;
  }

  setSetHeightTo(setHeightTo = []) {
    if (isString(setHeightTo)) setHeightTo = setHeightTo.split(",");
    this.setHeightTo = setHeightTo;
  }

  setInitialAdjustment(initialAdjustment = 0) {
    this.initialAdjustment = initialAdjustment;
  }

  setHeight = debounce((account) => {
    try {
      const height =
        isArray(this.calculateFrom) &&
        this.calculateFrom
          .map((item) => (isHTMLElement(item?.current) ? item.current : document.querySelector(item)))
          .filter((container) => container?.offsetHeight)
          .map((container) => container?.offsetHeight)
          .reduce(
            (totalHeight, containerHeight) => totalHeight - containerHeight,
            isFunction(this.initialAdjustment) ? this.initialAdjustment() : window.innerHeight + this.initialAdjustment
          );

      isArray(this.setHeightTo) &&
        height &&
        this.setHeightTo
          .map((item) => (isHTMLElement(item?.current) ? item.current : document.querySelector(item)))
          .filter((element) => isHTMLElement(element))
          .forEach((element) => (element.style.height = `${account ? Number(height) - account : height}px`));
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, 120);

  addResizeListener = (account) => {
    window.addEventListener("resize", () => this.setHeight(account));
    this.resizeListener = true;
  };

  removeResizeListener = (account) => {
    window.removeEventListener("resize", () => this.setHeight(account));
    this.resizeListener = false;
  };
}
