import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { notification } from "antd";
// import _ from 'lodash';

import IntlMessage from "util/IntlMessage";
import { withMobile } from "util/hocs";

import { RESET_PWD_BYUSER } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
// import { isMobile } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from "../../common/CommonButton/OpenNotification";
import Heading from "../../common/Heading";
import InputBlock from "../../common/InputBlock";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        currentPassword: "",
        newPassword: "",
        confPassword: "",
      },
      errors: {},
      showCurrentPassword: false,
      showNewPassword: false,
      showonfPassword: false,
    };
  }

  updatePassword() {
    const self = this;
    const obj = {
      // url: "/auth/reset-password-by-user",
      // method: "post",
      ...RESET_PWD_BYUSER,
      request: self.state.formData,
    };
    if (this.handleValidation()) {
      UtilService.callApi(obj, (err, data) => {
        if (err && err.data) {
          return OpenNotification({
            type: "error",
            title: err.data.message,
          });
        } if (data.code === "OK") {
          self.setState({ formData: {} });
          return OpenNotification({
            type: "success",
            title: data.message,
          });
        }
      });
    }
  }

  handleInputChange(key, value) {
    const {formData} = this.state;
    formData[key] = value;
    this.setState({ formData });
  }

  handleValidation() {
    const self = this;
    const fields = self.state.formData;
    const errors = {};
    let formIsValid = true;
    const blankField = [];
    const invalidFields = [];

    if (!fields.currentPassword) {
      blankField.push("Current Password");
      formIsValid = false;
      errors.currentPassword = "Please enter current password.";
    }

    if (!fields.newPassword) {
      blankField.push("New Password");
      formIsValid = false;
      errors.newPassword = "Please enter new password.";
    } else if (
      fields.newPassword &&
      // (!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/) || value.length > 15 || value.length < 6)
      fields.newPassword.length < 3
    ) {
      invalidFields.push("Password");
      formIsValid = false;
      errors.newPassword = "Password must be 3 digit long.";
    }

    if (!fields.confPassword) {
      blankField.push("Confirm Password");
      formIsValid = false;
      errors.confPassword = "Please enter confirm password";
    }
    if (
      fields &&
      fields.confPassword &&
      fields.confPassword.length &&
      fields.confPassword !== fields.newPassword
    ) {
      invalidFields.push("Confirm Password");
      formIsValid = false;
      errors.confPassword = "Please make sure your passwords match.";
    }
    if (blankField.length > 0) {
      const validateFields = blankField.map((f, index) => {
        return <span key={index}>{`${f}${index !== blankField.length - 1 ? ", " : ""}`}</span>;
      });
      notification.error({
        message: "Required Fields",
        placement: "bottomLeft",
        description: validateFields,
      });
    }
    if (invalidFields.length > 0) {
      const validateFields = invalidFields.map((f, index) => {
        return <span key={index}>{`${f} ${index !== invalidFields.length - 1 ? "," : ""}`}</span>;
      });
      notification.error({
        message: "Invalid Fields",
        placement: "bottomLeft",
        description: validateFields,
      });
    }

    self.setState({ errors });
    return formIsValid;
  }

  handleCancel() {
    this.setState({
      formData: {
        currentPassword: "",
        newPassword: "",
        confPassword: "",
      },
      errors: {},
    });
  }

  hiddenAndshowpass() {}

  render() {
    const { isMobile } = this.props;
    const { formData, errors, showonfPassword, showCurrentPassword, showNewPassword } = this.state;

    return (
      <div>
        {isMobile && <Heading title={<IntlMessage id="app.CurrentPassword" />} className="p-0" />}

        <div className="profileDetailSpace changePasswordBox">
          <IntlMessage id="app.CurrentPassword">
            {(placeholder) => (
              <div style={{ position: "relative" }}>
                <div>
                  <InputBlock
                    placeholder={placeholder}
                    value={formData.currentPassword ? formData.currentPassword : ""}
                    type={showCurrentPassword ? "text" : "password"}
                    onChange={(e) => this.handleInputChange("currentPassword", e.target.value.trim())}
                    label={<IntlMessage id="app.CurrentPassword" />}
                    hasError={!!errors.currentPassword}
                  />
                </div>

                <span onClick={() => this.setState({ showCurrentPassword: !showCurrentPassword })} className="eye">
                  {showCurrentPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </span>
              </div>
            )}
          </IntlMessage>

          <IntlMessage id="app.NewPassword">
            {(placeholder) => (
              <div style={{ position: "relative" }}>
                <div>
                  <InputBlock
                    placeholder={placeholder}
                    value={formData.newPassword ? formData.newPassword : ""}
                    type={showNewPassword ? "text" : "password"}
                    onChange={(e) => this.handleInputChange("newPassword", e.target.value.trim())}
                    label={<IntlMessage id="app.NewPassword" />}
                    hasError={!!errors.newPassword}
                  />
                </div>

                <span onClick={() => this.setState({ showNewPassword: !showNewPassword })} className="eye">
                  {showNewPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </span>
              </div>
            )}
          </IntlMessage>

          <IntlMessage id="app.ConfirmPassword">
            {(placeholder) => (
              <div style={{ position: "relative" }}>
                <div>
                  <InputBlock
                    placeholder={placeholder}
                    value={formData.confPassword ? formData.confPassword : ""}
                    type={showonfPassword ? "text" : "password"}
                    onChange={(e) => this.handleInputChange("confPassword", e.target.value.trim())}
                    label={<IntlMessage id="app.ConfirmPassword" />}
                    hasError={!!errors.confPassword}
                  />
                </div>
                <span onClick={() => this.setState({ showonfPassword: !showonfPassword })} className="eye">
                  {showonfPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </span>
              </div>
            )}
          </IntlMessage>
        </div>
        <div className="botoomStickyBar diamondListingAction justify-content-center">
          <span className="bottomStickyButton" onClick={() => this.updatePassword()}>
            <IntlMessage id="app.ChangePassword" />
          </span>
          <span className="bottomStickyButton commonOutline" onClick={() => this.handleCancel()}>
            <IntlMessage id="app.Cancel" />
          </span>
        </div>
        {isMobile && (
          <div className="sideBarPopupButton">
            <span className="commonButton" onClick={() => this.updatePassword()}>
              <IntlMessage id="app.ChangePassword" />
            </span>
            <span
              className="commonOutline"
              onClick={() => {
                isMobile ? this.props.history.goBack() : this.handleCancel();
              }}
            >
              <IntlMessage id="app.Cancel" />
            </span>
          </div>
        )}
      </div>
    );
  }
}
export default compose(withMobile, withRouter)(ChangePassword);
