import React from "react";

import NoDataShow from "components/common/NoDataShow";

import { ListingCards } from "../common/DiamondListing/MobileComfirmStone";
import Heading from "../common/Heading";

const BidListMobile = (props) => {
  return (
    <div className="searchResultListWrapper">
      <div className="selectStoneHeader">
        <div className="listingTopBlock width-100">
          <div className="d-flex justify-content-between align-items-center width-100">
            <Heading title={props.parent.getTitle()} />
          </div>
          {props.parent.getSelection()}
        </div>
      </div>
      <div className="bidEndBlock flex-wrap">
        {props.parent.getBidBlock()}
        <div className="width-100">
          <div className="liveBidOption d-flex j-content-center mt-5">{props.parent.getRadio()}</div>
          <div className="liveBidOption d-flex j-content-center mt-5">{props.parent.getDropDown()}</div>
        </div>
      </div>
      <div className="searchInnerResult">
        <div className="diamondListMobile">
          <ListingCards
            BidTab={props.BidTab}
            bid={props.displayStatus}
            data={props.data}
            currentType={props.currentType}
          />
        </div>
        {props.data.length === 0 ? <NoDataShow /> : undefined}
        {props.parent.getAction()}
      </div>
    </div>
  );
};

export default BidListMobile;
