import React, { useState, useEffect } from "react";

import "intro.js/introjs.css";
import introJs from "intro.js";

import { useIsMobile } from "util/hooks";

import logoSVG from "../../assets/svg/logo.svg";
import Header from "../Header";
import MobileHeader from "../MobileHeader";

import Dashboard from ".";
import "../../styles/version1.less";

const VersionOne = () => {
  const [isMobile] = useIsMobile();
  const [showIntro, setShowIntro] = useState(false);

  const steps = [
    {
      title: "Welcome",
      intro:
        "<p>Welcome to <b>KGK</b> Live, the ultimate destination for diamond traders! The vast inventory of <b>KGK</b> diamonds is now at your fingertips. Start exploring.</p>",
    },
    {
      element: `#step1`,
      title: "Search",
      intro: "<p>Filter through our inventory of diamonds to find the ones that match your criteria.</p>",
    },
    {
      element: `#step8`,
      title: "New Arrival",
      intro:
        "<p>Don't miss out on these stunning new arrivals - browse our collection now and find your perfect diamond!</p>",
    },
    {
      element: `#step9`,
      title: "Bid to Buy",
      intro:
        "<p>Don't miss out on these stunning Bid to Buy - browse our collection now and find your perfect diamond!</p>",
    },
    {
      element: `#step2`,
      title: "Cart",
      intro: "<p>Select the diamonds you wish to purchase, add them to your <b>cart</b>, and proceed to checkout.</p>",
    },
    {
      element: `#step3`,
      title: "Notification",
      intro: "<p>Get notified about the latest updates here.</p>",
    },
    {
      element: `#step4`,
      title: "My Account",
      intro:
        "<p>Access your <b>KGK</b> Live account to manage your profile, update your personal information, and view your past purchases, ongoing orders, and more.</p>",
    },
    {
      element: `#step5`,
      title: "Quick Search",
      intro:
        "<p>Browse through the latest collection of diamonds available on the website using our <b>Quick search</b> feature.</p> ",
    },
    {
      element: `#step6`,
      title: "Advance Search",
      intro:
        "<p>Use our <b>advanced search </b>criteria to filter through our entire inventory and find the diamond that best suits your style and budget.</p>",
    },
    {
      element: "#step7",
      title: " Yippeee!!",
      intro: `<img  class="intro-image" src="${logoSVG}" alt="KGk"  /> </br>
      <p class="intro-p">Congratulations! Your KGK Live account is now set up and ready to go.
Start <b> <a href="/diamond-search" class="intro-a">shopping now  </a></b>and discover the perfect diamond for your collection.</p>
 </p>`,
    },
  ];

  useEffect(() => {
    const hadSeenIntro = localStorage.getItem("hadSeenIntro");
    if (!hadSeenIntro || hadSeenIntro === "false") {
      setShowIntro(true);
      localStorage.setItem("hadSeenIntro", true);
    }
  }, []);

  useEffect(() => {
    if (showIntro) {
      introJs()
        .setOptions({
          steps,
          tooltipClass: "customTooltip",
          exitOnOverlayClick: false,
          exitOnEsc: false,
          showBullets: false,
          showProgress: true,
          showStepNumbers: false,
          keyboardNavigation: false,
          scrollToElement: true,
          scrollTo: "element",
          disableInteraction: false,
          highlightClass: "intro-highlight",
          hintClass: "intro-hint",
        })
        .start();

      setShowIntro(false);
    }
  }, [showIntro, steps]);

  return (
    <div className="dashboardVersion1">
      {isMobile ? <MobileHeader className="versionOneMobileHeader" /> : <Header className="versionOneHeader" />}

      <Dashboard />
    </div>
  );
};

export default VersionOne;
