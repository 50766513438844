import React from "react";

import { Modal } from "antd";
import "./modal.less";

const CommonModal = (props) => {
  return (
    <div>
      {props.noCancel ? (
        <Modal
          closable={false}
          title={props.title}
          className={`commonModal ${props.modalSize ? props.modalSize : ""}`}
          visible={props.visible}
          footer={
            props.footerShow ? (
              <div className="commonModalButton">
                <button className="fillButton" onClick={props.handleOk}>
                  {props.submitTitle}
                </button>
                {!props.noCancelGuest && (
                  <button className="outLineButton" onClick={props.handleCancel}>
                    {props.cancelTitle}
                  </button>
                )}
              </div>
            ) : null
          }
          destroyOnClose
        >
          {props.children}
        </Modal>
      ) : (
        <Modal
          title={props.title}
          className={`commonModal ${props.modalSize ? props.modalSize : ""}`}
          visible={props.visible}
          onOk={props.handleOk}
          onCancel={props.handleCancel}
          footer={
            props.footerShow ? (
              <div className="commonModalButton">
                <button className="fillButton" onClick={props.handleOk}>
                  {props.submitTitle}
                </button>
                <button className="outLineButton" onClick={props.handleCancel}>
                  {props.cancelTitle}
                </button>
              </div>
            ) : null
          }
          destroyOnClose
        >
          {props.children}
        </Modal>
      )}
    </div>
  );
};

export default CommonModal;
