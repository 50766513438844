import { notify } from "util/notify";
import { isString } from "util/utils";

import { NEXT_BID as GET_SERVER_TIME, BID_IMPORT_EXCEL } from "constants/apiConstant";

import { BID_ACTIONS } from "appRedux/reducers/bidConfig";
import { store } from "appRedux/store";

import { callingApi } from "./util";

export class BidService {
  static get DYNAMIC_BID_TYPES() {
    return { NEW_ARRIVAL: 1, BID_TO_BUY: 2 };
  }

  static async getConfig(request) {
    const [err, res] = await callingApi({
      ...GET_SERVER_TIME,
      url: `${GET_SERVER_TIME.url}?t=${Math.random()}`,
      request,
    });

    const bidConfig = !err ? res?.data : undefined;
    store.dispatch(BID_ACTIONS.setBidConfig(bidConfig));
    return bidConfig;
  }

  static async uploadExcel(params = {}) {
    const request = new FormData();
    Object.entries(params).forEach(([key, value]) => request.append(key, value));

    const [err, res] = await callingApi({ ...BID_IMPORT_EXCEL, request });
    if (err) {
      const errorMessage = err?.data?.message;
      if (isString(errorMessage)) notify.error({ message: errorMessage });
    }
    const statusMessage = res?.data?.message;
    const importMessage = res?.data?.[0]?.result?.message;

    if (isString(statusMessage)) notify.success({ message: statusMessage });
    if (isString(importMessage)) notify.success({ message: importMessage });
    return res;
  }
}

window.__BidService = BidService;
