import React from "react";
import { withRouter, Link } from "react-router-dom";

import "../../common/Tab";
import IntlMessage from "util/IntlMessage";

import KYCDetail from "./KYCDetail";
import Summary from "./Summary";

import { LOCAL_STORAGE_VAR, KYC_STATUS } from "../../../constants/Common";
import UtilService from "../../../services/util";
import ChangePassword from "../ChangePassword";
import { ManageClientMail } from "../ManageClientMail/Index";
import ManageDiamondSequence from "../ManageDiamondSequence";

let gridHeight = 0;
export const setGridHeightFn = () => {
  const allContainers = {};
  let totalHeight = window.innerHeight - 0;
  allContainers.headerHeight = document.getElementsByClassName("HeaderSticky")[0];
  allContainers.sideBarPopupButton = document.getElementsByClassName("sideBarPopupButton")[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight -= allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
};

const MyAccount = (props) => {
  const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;

  gridHeight = setGridHeightFn();
  const { location } = props;
  const summaryPath = location.pathname === "/my-account/summary";
  const kycPath = location.pathname === "/my-account/kyc-detail";
  const changePasswordPath = location.pathname === "/my-account/change-password";
  const manageDiamondPath = location.pathname === "/my-account/manage-sequence";
  const clientMailNotification = location.pathname === "/my-account/manage-client-mail";
  if (
    UtilService.getLocalStorageItem(
      window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
    )
  ) {
    props.history.push("/dashboard");
    return null;
  }

  return (
    <div className="accountSparetPage" style={{ height: gridHeight, overflow: "auto" }}>
      <div className="customerSubTab">
        <ul className="accountTabBox">
          {authUser && authUser.isApproved === KYC_STATUS.APPROVED && (
            <li className={`${summaryPath ? "react-tabs__tab--selected" : ""}`}>
              <Link to="/my-account/summary">
                <IntlMessage id="app.sumary" />
              </Link>
            </li>
          )}

          <li className={`${kycPath ? "react-tabs__tab--selected" : ""}`}>
            <Link to="/my-account/kyc-detail">
              <IntlMessage id="app.aboutYou" />
            </Link>
          </li>
          {authUser && authUser.isApproved === KYC_STATUS.APPROVED && (
            <li className={`${changePasswordPath ? "react-tabs__tab--selected" : ""}`}>
              <Link to="/my-account/change-password">
                <IntlMessage id="app.changePassword" />
              </Link>
            </li>
          )}
          {authUser && authUser.isApproved === KYC_STATUS.APPROVED && (
            <li className={`${manageDiamondPath ? "react-tabs__tab--selected" : ""}`}>
              <Link to="/my-account/manage-sequence">
                <IntlMessage id="app.manageDiamondSequence" />
              </Link>
            </li>
          )}

          <li className={`${clientMailNotification ? "react-tabs__tab--selected" : ""}`}>
            <Link to="/my-account/manage-client-mail">
              <IntlMessage id="app.ClientNotification" />
            </Link>
          </li>
        </ul>
      </div>
      <div className="accountMianPage">
        {summaryPath && <Summary />}
        {kycPath && <KYCDetail />}
        {changePasswordPath && <ChangePassword />}
        {manageDiamondPath && <ManageDiamondSequence />}
        {clientMailNotification && <ManageClientMail />}
      </div>
    </div>
  );
};
export default withRouter(MyAccount);
