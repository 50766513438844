import React from "react";

import "./staticHeader.less";
import LandingHeader from "components/LandingHeader/LandingHeader";

const StaticHeader = () => {
  return (
    <>
      <LandingHeader />
    </>
  );
};
export default StaticHeader;
