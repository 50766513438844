import { SALES_DATA, USER_DATA } from "../../constants/ActionTypes";

export const salesPersonAction = (sales) => {
  return (dispatch) => {
    dispatch({ type: SALES_DATA, payload: sales });
  };
};

export const storeUser = (userDetails) => {
  return (dispatch) => {
    dispatch({ type: USER_DATA, payload: userDetails });
  };
};
