import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { Drawer, Modal } from "antd";
import isEmpty from "lodash/isEmpty";

import { BidService } from "services/BidService";
import { RowSelectService } from "services/RowSelectService";

import IntlMessage from "util/IntlMessage";
import { useIsMobile, useRefEffect, usePathname } from "util/hooks";
import { notify } from "util/notify";
import { prepareStoneForBargain } from "util/stone-utils";
import { getPathName, isFunction, isNotEmpty } from "util/utils";

import { trackTypeObj, LOCAL_STORAGE_VAR, KYC_STATUS, TRACK_TYPES, PAGES } from "constants/Common";

import { setCount } from "appRedux/actions/deleteReminder";
import { throwNotifyCount, getCartCount } from "appRedux/actions/notifyAction";

import bidStoneSVG from "assets/svg/DiamondList/bid-stone.svg";
import calSVG from "assets/svg/DiamondList/cal.svg";
import cartSVG from "assets/svg/DiamondList/cart.svg";
import certiSVG from "assets/svg/DiamondList/certi.svg";
import compareSVG from "assets/svg/DiamondList/compare.svg";
import downloadSVG from "assets/svg/DiamondList/download.svg";
import myBIdSVG from "assets/svg/DiamondList/my-bid.svg";
import offerSVG from "assets/svg/DiamondList/offer.svg";
import printSVG from "assets/svg/DiamondList/print-list.svg";
import shareSVG from "assets/svg/DiamondList/share.svg";
import BidPopup from "./BidPopup";
import ConfirmStonePopup from "./ConfirmStonePopup";
import DownloadPopup from "./DownloadPopup";
import FinalCalculations from "./FinalCalculations";
import ImportExcelPopUp from "./ImportExcelPopUp";
import NotePopup from "./NotePopup";
import OfficeSidebar from "./OfficeSidebar";
import QuotePopup from "./QuotePopup";
import ShareDropdown from "./ShareDropdown";
import SharePopup from "./SharePopup";
import WatchlistPopup from "./WatchlistPopup";

import {
  handleInsertTrack,
  compareStones,
  handlePrint,
  handleTrackDelete,
  handleUpsertTrack,
  getPath,
  handleBidDelete,
  handleNoteDelete,
  handleDeleteOffice,
  DiamondTrackStatusUpdate,
  fetchConfirmStone,
  downloadZip,
  handleDownloadExcel,
  unHoldFromCart,
} from "../../DiamondList/DiamondListFunctions";
import { GuestUserPopup } from "../../GuestUserPopup";
import { NoKycPop } from "../../NoKycPop";
import OpenNotification from "../CommonButton/OpenNotification";
import CommonModal from "../CommonModal";
import Pagination from "../Pagination";
import Reminder from "../Reminder";
import UpdateReminder from "../UpdateReminder";

import updatestoneSVG from "assets/svg/DiamondList/updatestone.svg";
import watchSVG from "assets/svg/DiamondList/watch.svg";
import confirmSVG from "assets/svg/DiamondSearch/confirm.svg";
import moreSVG from "assets/svg/DiamondSearch/more.svg";
import deleteSVG from "assets/svg/delete.svg";

const LISTINGPAGES = PAGES;

const DiamondListingAction = (props) => {
  const { currentType, deleteReminder } = props;
  const deleteReminderCount = deleteReminder?.count;
  const propsRef = React.useRef(props);
  useRefEffect(propsRef, props);

  const path = usePathname();

  const [visible, setVisible] = useState("");
  const [moreSearch, moreSearchOption] = useState(false);
  const [more, setMore] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [showGuest, setShowGuest] = useState(false);
  const [noKyc, setNoKyc] = useState(false);
  const [isMobile, isTablet] = useIsMobile();
  const selectedRows = useSelector((state) => state.diamondData.selectedRows[currentType]);
  const checked = React.useMemo(() => selectedRows ?? props.checkedData ?? [], [props.checkedData, selectedRows]);

  const clearSelectedRows = React.useCallback(() => {
    const { currentType, clearAll } = propsRef.current;
    isFunction(clearAll) ? clearAll() : RowSelectService.resetSelectedRows(currentType);
  }, []);

  // const isGuest = Storage.get('guest', false);
  const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
  const authUser = useSelector((state) => state?.auth?.authUser);

  const isKyc = React.useMemo(() => {
    return !authUser?.account?.isKycUploaded || authUser?.account?.isApproved !== KYC_STATUS.APPROVED;
  }, [authUser?.account?.isApproved, authUser?.account?.isKycUploaded]);

  const uploadExcel = React.useCallback(
    async (file) => {
      BidService.uploadExcel({ file, subTypeCode: props.subTypeCode, bidConfigurationId: props.bidConfigurationId });
    },
    [props.bidConfigurationId, props.subTypeCode]
  );

  const moreSearchDrawer = () => moreSearchOption(true);
  const onClose = React.useCallback(() => {
    const { setCount } = propsRef.current;
    setVisible("");
    setReminder("");
    moreSearchOption(false);
    setCount(!deleteReminderCount);
  }, [deleteReminderCount]);
  // Before adding any new functionality or removing any functionality from action constant please check where action is used.
  // It is used with slice so on removal or adding, it can effect.
  const actions = React.useMemo(() => {
    return [
      !props.myBidStone
        ? null
        : {
            name: <IntlMessage id="app.bidStone" />,
            key: "bid",
            img: bidStoneSVG,
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to bid.",
                    })
                  : setVisible("bid");
              } else {
                setShowGuest(true);
              }
            },
          },
      !props.myBid
        ? null
        : {
            name: <IntlMessage id="app.myBid" />,
            key: "mybid",
            img: myBIdSVG,
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : currentType === "/new-arrival"
                  ? props.history.push("/my-bid", { tab: currentType })
                  : props.history.push("/my-bid", { tab: currentType });
              } else {
                setShowGuest(true);
              }
            },
          },
      props.modify || currentType === "/new-arrival" || currentType === "/bid-to-buy"
        ? null
        : {
            name: <IntlMessage id="app.modify-search" />,
            key: "confirm",
            img: confirmSVG,
            onClick: () => {
              props.onModify();
            },
          },
      props.noconfirm || props.offerStatus === 2 || props.offerStatus === 3
        ? null
        : {
            name: props.acceptBtn ? <IntlMessage id="app.accept" /> : <IntlMessage id="app.confirmStone" />,
            key: "confirm",
            onClick: () => {
              if (
                checked.find((chk) => chk.wSts === "N" || chk?.wSts === "B") &&
                (path === "view-diamonds" || path === "watchlist")
              ) {
                return notify.error({
                  message: "You can not  confirm New arrival /Bid to Buy stone(s).",
                });
              }
              if (!isGuest) {
                if (isKyc) return setNoKyc(true);
                if (isEmpty(checked)) return notify.error({ message: "Please select stone(s) to confirm." });
              } else {
                setShowGuest(true);
              }
              if (currentType === "/account/offer-list" || isMobile) {
                let noCounterAdmin = 0;
                checked
                  .map((stone) => prepareStoneForBargain(stone, { noModify: true }))
                  .forEach((record) => {
                    if (record?.disableSelection) {
                      if (record?.onCheckError) {
                        noCounterAdmin++;
                      }
                    }
                  });
                if (noCounterAdmin !== 0) {
                  notify.error({ message: "You are not allowed to accept this offer without a counter offer." });
                  return;
                }
              }
              setVisible("confirm");
            },
            img: confirmSVG,
          },
      props.permKey !== "offer" || props.offerStatus === 2 || props.offerStatus === 3
        ? null
        : {
            name: "Reject",
            key: "rejectoffer",
            onClick: () => {
              if (!isGuest) {
                if (isKyc) {
                  setNoKyc(true);
                } else if (isEmpty(checked)) {
                  OpenNotification({
                    type: "error",
                    title: "Please select stone(s) to reject offer.",
                  });
                } else {
                  if (currentType === "/account/offer-list" || isMobile) {
                    let noCounterAdmin = 0;
                    checked
                      .map((stone) => prepareStoneForBargain(stone, { noModify: true }))
                      .forEach((record) => {
                        if (record?.disableSelection) {
                          if (record?.onCheckError) {
                            noCounterAdmin++;
                          }
                        }
                      });
                    if (noCounterAdmin !== 0) {
                      notify.error({ message: "You are not allowed to Reject this offer without a counter offer." });
                      return;
                    }
                  }
                  DiamondTrackStatusUpdate(
                    checked.map((x) => x.trackId),
                    { offerStatus: 3 },
                    () => {
                      clearSelectedRows();
                      props.fetch();
                    }
                  );
                }
              } else {
                setShowGuest(true);
              }
            },
          },
      props.nocart || props.offerStatus === 2
        ? null
        : {
            name: <IntlMessage id="app.Cart" />,
            key: "cart",
            onClick: () => {
              if (
                checked.find((chk) => chk.wSts === "N" || chk?.wSts === "B") &&
                (path === "view-diamonds" || path === "watchlist")
              ) {
                return notify.error({
                  message: "You can not add to cart  New arrival /Bid to Buy stone(s).",
                });
              }
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to add in cart.",
                    })
                  : handleInsertTrack(trackTypeObj.CART, checked, (status) => {
                      if (status) {
                        // props.throwNotifyCount(props.notifyCount + 1);
                        props.getCartCount();
                        props.setCount(!deleteReminderCount);
                        props.fetch();
                      }
                    });
              } else {
                setShowGuest(true);
              }
            },
            img: cartSVG,
          },
      props.nowatch || props.offerStatus === 2
        ? null
        : {
            name: <IntlMessage id="app.Watch" />,
            key: "watch",
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to add in watch.",
                    })
                  : handleInsertTrack(trackTypeObj.WATCHLIST, checked, (status) => {
                      if (status) {
                        props.throwNotifyCount(props.notifyCount + 1);
                        props.setCount(!deleteReminderCount);
                      }
                    }); // setVisible("watchlist");
              } else {
                setShowGuest(true);
              }
            },
            img: watchSVG,
          },

      props.noquote
        ? null
        : {
            name: <IntlMessage id="app.Offer" />,
            key: "quote",
            img: offerSVG,
            onClick: () => {
              if (
                checked.find((chk) => chk.wSts === "N" || chk?.wSts === "B") &&
                (path === "view-diamonds" || path === "watchlist")
              ) {
                return notify.error({
                  message: "You can not apply offer on New arrival /Bid to Buy stone(s).",
                });
              }
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to apply quote.",
                    })
                  : setVisible("quote");
              } else {
                setShowGuest(true);
              }
            },
          },
      props.nofinalcalc
        ? null
        : {
            name: <IntlMessage id="app.FinalCalculation" />,
            key: "calc",
            img: calSVG,
            onClick: () => {
              if (!isGuest) {
                if (isKyc) {
                  setNoKyc(true);
                } else if (isEmpty(checked)) {
                  OpenNotification({
                    type: "error",
                    title: "Please select stone(s) for final calculation.",
                  });
                } else {
                  const fm = checked.filter((el) => el.isFm === "ELIG");
                  if (fm.length === checked.length || fm.length === 0) setVisible("finalCalc");
                  else
                    OpenNotification({
                      type: "error",
                      title: "Please select FM stones separately.",
                    });
                }
              } else {
                setShowGuest(true);
              }
            },
          },
      props.noappointment
        ? null
        : {
            name: <IntlMessage id="app.appointment" />,
            key: "appointment",
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to book an appointment.",
                    })
                  : setVisible("appointment");
              } else {
                setShowGuest(true);
              }
            },
          },
      props.nonote
        ? null
        : {
            name: <IntlMessage id="app.comment" />,
            key: "note",
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to add note.",
                    })
                  : setVisible("note");
              } else {
                setShowGuest(true);
              }
            },
          },
      props.delete
        ? {
            name: <IntlMessage id="app.removeStone" />,
            img: deleteSVG,
            key: "delete",
            onClick: () => {
              if (!isGuest) {
                if (isKyc) {
                  setNoKyc(true);
                } else {
                  if (isEmpty(checked)) {
                    OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to delete.",
                    });
                    return;
                  }
                  if (getPath() === LISTINGPAGES.MYBID) {
                    handleBidDelete(
                      checked.map((row) => row.trackId),
                      (flag) => {
                        props.fetch();
                        clearSelectedRows();
                        if (flag) props.setCount(!deleteReminderCount);
                      }
                    );
                  } else if (getPath() === LISTINGPAGES.NOTE) {
                    handleNoteDelete(
                      checked.map((row) => row.trackId),
                      (flag) => {
                        clearSelectedRows();
                        if (flag) {
                          props.fetch();
                          props.setCount(!deleteReminderCount);
                        }
                      }
                    );
                  } else if (getPath() === LISTINGPAGES.OFFICE) {
                    const schedule = [];
                    checked.map((ck) => {
                      schedule.push({
                        diamonds: [ck.id],
                        id: ck.slottId,
                      });
                      return true;
                    });

                    handleDeleteOffice({ schedule }, () => {
                      clearSelectedRows();
                      props.fetch();
                      OpenNotification({
                        type: "success",
                        title: "Your stone(s) is removed from office successfully.",
                      });
                    });
                  } else if (getPath() === LISTINGPAGES.CART) {
                    unHoldFromCart(
                      TRACK_TYPES.CART,
                      checked.map((row) => row.id),
                      props.inTrackDiamonds,
                      (flag) => {
                        if (flag) {
                          clearSelectedRows();
                          props.getCartCount();
                          props.fetch();
                          props.setCount(!deleteReminderCount);
                        }
                      }
                    );
                  } else {
                    handleTrackDelete(
                      props.trackType,
                      checked.map((row) => row.id),
                      props.inTrackDiamonds,
                      (flag) => {
                        if (flag) {
                          clearSelectedRows();
                          props.getCartCount();
                          props.fetch();
                          props.setCount(!deleteReminderCount);
                        }
                      }
                    );
                  }
                }
              } else {
                setShowGuest(true);
              }
            },
          }
        : null,
      props.update
        ? {
            name: !props.myBid ? <IntlMessage id="app.updateStone" /> : <IntlMessage id="app.updateBid" />,
            img: updatestoneSVG,
            key: "update",
            onClick: () => {
              if (!isGuest) {
                if (isKyc) {
                  setNoKyc(true);
                } else {
                  if (isEmpty(checked)) {
                    OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to update.",
                    });
                    return;
                  }
                  if (getPath() === LISTINGPAGES.REMINDER) {
                    isEmpty(checked)
                      ? OpenNotification({
                          type: "error",
                          title: "Please select stone(s) to update reminder.",
                        })
                      : setReminder("update");
                  } else if (getPath() === LISTINGPAGES.MYBID) {
                    isEmpty(checked)
                      ? OpenNotification({
                          type: "error",
                          title: "Please select stone(s) to update bid.",
                        })
                      : setVisible("bid");
                  } else if (getPath() === LISTINGPAGES.NOTE) {
                    isEmpty(checked)
                      ? OpenNotification({
                          type: "error",
                          title: "Please select stone(s) to update note.",
                        })
                      : setVisible("note");
                  }
                }
              } else {
                setShowGuest(true);
              }
            },
          }
        : null,
      props.nonocompare
        ? null
        : props.compareList
        ? null
        : props.nocompare
        ? {
            name: (
              <span>
                Compare
                {` (${props.data.length})`}
              </span>
            ),
            key: "compare",
            img: compareSVG,
            onClick: () => {
              if (!isGuest) {
                isKyc ? setNoKyc(true) : localStorage.setItem(`${LOCAL_STORAGE_VAR}-more`, true);
                props.history.push(JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)));
              } else {
                this.setState({
                  showGuest: true,
                });
              }
            },
          }
        : {
            name: <IntlMessage id="app.CompareStone" />,
            key: "compare",
            img: compareSVG,
            onClick: () => {
              if (!isGuest) {
                isKyc ? setNoKyc(true) : compareStones(checked);
              } else {
                setShowGuest(true);
              }
            },
          },

      // !props.importexcel
      //   ? null
      //   : {
      //       name: <IntlMessage id="app.importExcel" />,
      //       key: 'importExcel',
      //       onClick: () => {
      //         setVisible('importexcel');
      //       },
      //     },

      !props.excel
        ? null
        : {
            name: <IntlMessage id="app.exportExcel" />,
            onClick: () => {
              let filter = isEmpty(checked)
                ? { wSts: props.currentType === "/new-arrival" ? ["N"] : ["B"] }
                : { vStnId: checked.map((chk) => chk.vStnId) };
              if (props.reqparamId && isEmpty(checked)) {
                filter = {
                  ...filter,
                  ...props.exportFilterData,
                };
              }
              downloadZip({
                filter,
                isExcel: true,
                isForBid: true,
                img: false,
                frontFile: false,
                sideFile: false,
                mp4Video: false,
                certFile: false,
                pltFile: false,
                prpFile: false,
                faceUp: false,
                zip: false,
              });
            },
          },
      !props.exportexcel
        ? null
        : {
            name: <IntlMessage id="app.exportExcel" />,
            onClick: () => {
              if (!isGuest) {
                if (checked.find((chk) => chk.wSts === "B") && path === "account") {
                  OpenNotification({
                    type: "error",
                    title: "You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).",
                  });
                } else if (checked.length > 0) {
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => {}
                  );
                } else {
                  return OpenNotification({
                    type: "error",
                    title: "No Stones Found",
                  });
                }
              } else {
                setShowGuest(true);
              }
            },
          },

      props.noshare ||
      currentType === "/account/order-list" ||
      currentType === "/account/watchlist" ||
      currentType === "/account/cart" ||
      currentType === "/account/view-diamonds" ||
      currentType === "/account/offer-list" ||
      currentType === "/account/notes"
        ? null
        : {
            name: <IntlMessage id="app.Share" />,
            key: "share",
            img: shareSVG,
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to share.",
                    })
                  : setVisible("share");
              } else {
                setShowGuest(true);
              }
            },
          },
      props.nodownload
        ? null
        : {
            name: <IntlMessage id="app.Download" />,
            key: "download",
            img: downloadSVG,
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : isEmpty(checked)
                  ? OpenNotification({
                      type: "error",
                      title: "Please select stone(s) to download.",
                    })
                  : setVisible("download");
                setMore(!more);
              } else {
                setShowGuest(true);
              }
            },
          },
      props.noprint
        ? null
        : {
            name: <IntlMessage id="app.PrintList" />,
            key: "print",
            img: printSVG,
            onClick: () => {
              if (!isGuest) {
                isKyc
                  ? setNoKyc(true)
                  : props.data && props.data.length
                  ? props.orderThi
                    ? checked.length
                      ? handlePrint(
                          props.seachFilterTag ? props.seachFilterTag.searchData : {},
                          checked.map((chk) => chk.id),
                          () => {},
                          props.orderThi
                        )
                      : fetchConfirmStone({ ...props.payReq, isPdf: true }, () => {})
                    : [
                        LISTINGPAGES.WATCHLIST,
                        LISTINGPAGES.CART,
                        LISTINGPAGES.OFFICE,
                        LISTINGPAGES.NOTE,
                        LISTINGPAGES.QUOTE,
                      ].includes(getPathName())
                    ? handlePrint(checked.map((chk) => chk.id))
                    : // : consoleA(props)
                      handlePrint(
                        props.seachFilterTag && checked.map((chk) => chk.id)
                          ? props.seachFilterTag.searchData && !isEmpty(checked.map((chk) => chk.id))
                            ? props.seachFilterTag.searchData && checked.map((chk) => chk.id)
                            : OpenNotification({
                                type: "error",
                                title: "No stone(s) found for print.  ",
                              })
                          : OpenNotification({
                              type: "error",
                              title: "No stone(s) found for print. ",
                            })

                        // props.seachFilterTag && checked.map((chk) => chk.id)
                        //   ? props.seachFilterTag.searchData && !isEmpty(checked.map((chk) => chk.id))
                        //     ? props.seachFilterTag.searchData && checked.map((chk) => chk.id)
                        //     : OpenNotification({
                        //         type: 'error',
                        //         title: 'No stone(s) found for print. 1',
                        //       })
                        //   : OpenNotification({
                        //       type: 'error',
                        //       title: 'No stone(s) found for print. 2',
                        //     }),
                        // OpenNotification({
                        //   type: 'error',
                        //   title: 'No stone(s) found for print. 3',
                        // }),
                        // // checked.map((chk) => chk.id),
                      )
                  : OpenNotification({
                      type: "error",
                      title: "No stone(s) found for print.4",
                    });
              } else {
                setShowGuest(true);
              }
            },
          },
      props.sharexray
        ? {
            name: <>Share X-Ray</>,
            key: "xray",
            onClick: () => {
              if (!isGuest) {
                isKyc ? setNoKyc(true) : props.shareXray();
              } else {
                setShowGuest(true);
              }
            },
            img: certiSVG,
          }
        : null,
      props.viewcerti
        ? {
            name: <>Verify Certificate</>,
            key: "certi",
            onClick: () => {
              if (!isGuest) {
                isKyc ? setNoKyc(true) : props.verifyCerti();
                onClose();
              } else {
                setShowGuest(true);
              }
            },
          }
        : null,
      props.downloadzip
        ? {
            name: <>Download Zip</>,
            key: "zip",
            onClick: () => {
              if (!isGuest) {
                isKyc ? setNoKyc(true) : props.downloadZip();
                onClose();
              } else {
                setShowGuest(true);
              }
            },
          }
        : null,
    ].filter(isNotEmpty);
  }, [checked, isGuest, isKyc, more, onClose, props, currentType]);

  return (
    <>
      <div className="mobileSearchBottom">
        {actions.slice(0, actions.length > 4 ? 2 : 4).map((x, key) => {
          return (
            <div className="mobileSearchActionItem" key={key}>
              <span onClick={x.onClick}>
                <img alt="" src={x.img} />
                <span>{x.key === "calc" ? <IntlMessage id="app.FinalCalc" /> : x.name}</span>
              </span>
            </div>
          );
        })}
        {actions.length > 4 && (
          <div className="mobileSearchActionItem">
            <span onClick={moreSearchDrawer}>
              <img alt="" src={moreSVG} />
              <span>More</span>
            </span>
          </div>
        )}
      </div>

      <Drawer onClose={onClose} visible={moreSearch} wrapClassName="mobileSidebar mobileBottomSidebar" destroyOnClose>
        {moreSearch && (
          <div className="MoreSearchOptionItem">
            <ul>
              {actions.slice(2, actions.length).map((x, key) => {
                return (
                  <li onClick={x.onClick} key={key}>
                    <span>{x.name}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </Drawer>

      <div
        className={`botoomStickyBar diamondListingAction${
          props.ActionFalse === false ? " justify-content-center" : ""
        }`}
        style={props.limitOut ? { position: "relative", width: "auto", boxShadow: "none", background: "none" } : null}
      >
        <div className="d-flex listingAction">
          {actions.slice(0, isTablet ? 3 : 5).map((x, key) => {
            return (
              <span key={key} className="bottomStickyButton" onClick={x.onClick}>
                <span>{x.name}</span>
              </span>
            );
          })}
          {((isTablet && actions.length > 3) || actions.length > 5) && (
            <>
              {/* {more &&  */}
              <Dropdown
                isOpen={more}
                toggle={() => {
                  setMore(!more);
                }}
              >
                <DropdownToggle className="bottomStickyButton" caret>
                  {/* <span> */}
                  <IntlMessage id="app.More" />
                  {/* </span> */}
                </DropdownToggle>
                <DropdownMenu className="diamondListBottomDropdown">
                  <ul>
                    {actions.slice(isTablet ? 3 : 5, actions.length).map((x, i) => {
                      return (
                        <li key={`${i}li`} onClick={x.onClick}>
                          <span>
                            <div className="dropdownBottomItem">
                              <span>{x.name}</span>
                            </div>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </DropdownMenu>
              </Dropdown>
            </>
          )}
          {Boolean(props.showStatus) && (
            <>
              <div className="status-display">
                <div className="circle-high" />
                <span>Winning</span>
              </div>
              <div className="status-display">
                <div className="circle-equal" />
                <span>Equal</span>
              </div>
              <div className="status-display">
                <div className="circle-low" />
                <span>Losing</span>
              </div>
            </>
          )}
        </div>
        {!props.nopaginate && <Pagination {...props} />}
      </div>

      <Drawer
        visible={visible === "confirm"}
        onClose={onClose}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === "confirm" && <ConfirmStonePopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={() => {
          onClose();
          if (props.editOffer) props.fetch();
        }}
        visible={Boolean(visible === "quote" || props.editOffer)}
        wrapClassName="diamondListinSidebar offerPopup xl-size"
        destroyOnClose
      >
        {
          Boolean(visible === "quote" || props.editOffer) && (
            // (isMobile ? (
            //   <MobileOfferPopup />
            // ) : (
            <QuotePopup
              {...props}
              nobox
              offerCollection={getPath() === LISTINGPAGES.COLLECTION}
              checked={checked}
              onClose={() => {
                onClose();
                if (props.editOffer) props.fetch();
              }}
            />
          )
          // )
          // )
        }
      </Drawer>

      <Drawer onClose={onClose} visible={visible === "bid"} wrapClassName="diamondListinSidebar xl-size" destroyOnClose>
        {visible === "bid" && <BidPopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>

      <Drawer
        onClose={onClose}
        visible={visible === "note"}
        wrapClassName={`diamondListinSidebar xl-size ${isMobile && "smallSidebarMobilePopup"}`}
        destroyOnClose
      >
        {visible === "note" && <NotePopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>

      <Drawer
        onClose={onClose}
        visible={visible === "noteUpdate"}
        wrapClassName={`diamondListinSidebar xl-size ${isMobile && "smallSidebarMobilePopup"}`}
        destroyOnClose
      >
        {visible === "noteUpdate" && (
          <NotePopup
            {...props}
            checked={checked}
            update
            onClose={() => {
              onClose();
              props.fetch();
            }}
          />
        )}
      </Drawer>

      <Drawer
        onClose={onClose}
        visible={visible === "appointment"}
        wrapClassName="diamondListinSidebar OfficePopup xl-size"
        destroyOnClose
      >
        {visible === "appointment" && (
          <OfficeSidebar
            // bid={getPath() === LISTINGPAGES.BID}
            {...props}
            checked={checked}
            onClose={onClose}
          />
        )}
      </Drawer>

      <Drawer
        onClose={onClose}
        visible={visible === "finalCalc"}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === "finalCalc" && <FinalCalculations {...props} checked={checked} onClose={onClose} />}
      </Drawer>

      <Drawer
        onClose={onClose}
        visible={visible === "watchlist"}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === "watchlist" && <WatchlistPopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Modal
        visible={visible === "importexcel" && !isMobile}
        onCancel={onClose}
        className="commonModals ListingModal"
        title="Bid Stone"
        closable
        footer={false}
        destroyOnClose
      >
        {visible === "importexcel" && !isMobile && (
          <ImportExcelPopUp
            {...props}
            subTypeCode={props?.subTypeCode}
            bidConfigurationId={props?.bidConfigurationId}
            // upload={uploadExcel}
            onClose={onClose}
          />
        )}
      </Modal>
      <Drawer
        visible={visible === "importexcel" && isMobile}
        onCancel={onClose}
        wrapClassName="diamondListinSidebar xl-size smallSidebarMobilePopup"
        title="Bid Stone"
        destroyOnClose
      >
        {visible === "importexcel" && isMobile && (
          <ImportExcelPopUp upload={uploadExcel} mobile={isMobile} onClose={onClose} />
        )}
      </Drawer>

      <Modal
        visible={visible === "share" && !isMobile}
        onCancel={onClose}
        className="commonModal ListingModal"
        title={<IntlMessage id="app.Download" />}
        closable={false}
        footer={false}
        destroyOnClose
      >
        {visible === "share" && !isMobile && <ShareDropdown {...props} checked={checked} onCloseSharePopup={onClose} />}
      </Modal>

      <Drawer
        visible={visible === "share" && isMobile}
        onClose={onClose}
        wrapClassName="diamondListinSidebar xl-size smallSidebarMobilePopup"
        destroyOnClose
      >
        {visible === "share" && isMobile && <SharePopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>

      <Modal
        visible={visible === "download" && !isMobile}
        onCancel={onClose}
        className="commonModal ListingModal"
        title={<IntlMessage id="app.Download" />}
        closable={false}
        footer={false}
        destroyOnClose
      >
        {visible === "download" && !isMobile && <DownloadPopup {...props} rows={checked} onClose={onClose} />}
      </Modal>

      <Drawer
        onClose={onClose}
        visible={visible === "download" && isMobile}
        wrapClassName="diamondListinSidebar xl-size smallSidebarMobilePopup"
        destroyOnClose
      >
        {visible === "download" && isMobile && <DownloadPopup {...props} rows={checked} onClose={onClose} />}
      </Drawer>

      <Drawer onClose={onClose} visible={reminder === "insert"} wrapClassName="diamondListinSidebar" destroyOnClose>
        {reminder === "insert" && (
          <Reminder
            handleCancel={() => setReminder(false)}
            handleSetReminder={(e) => handleInsertTrack(trackTypeObj.REMINDER, checked, () => setReminder(false), e)}
            checked={checked}
          />
        )}
      </Drawer>

      <Drawer onClose={onClose} visible={reminder === "update"} wrapClassName="diamondListinSidebar xl-size">
        {reminder === "update" && (
          <UpdateReminder
            handleCancel={() => setReminder(false)}
            date={checked && checked[0] ? checked[0].reminderDate : null}
            handleUpdateReminder={(e) =>
              handleUpsertTrack(
                trackTypeObj.REMINDER,
                checked,
                props.inTrackDiamonds,
                () => {
                  props.fetch();
                  setReminder(false);
                },
                e
              )
            }
            checked={checked}
          />
        )}
      </Drawer>

      {checked && checked.length ? (
        <CommonModal
          handleCancel={() => setDisclaimer(false)}
          handleOk={() => {
            setVisible(disclaimer);
            setDisclaimer(false);
          }}
          visible={disclaimer}
          modalSize="xs-size"
          footerShow
          title="Disclaimer"
          submitTitle="I Agree"
          cancelTitle="Quit"
        >
          {checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : "")).filter((el) => el !== "INDIA").length ? (
            <p>
              {/* Packet No: {checked.map((x) => x.vStnId).join(", ")}, is currently
              located in the{" "} */}
              of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
              the leap into electronic typesetting,
              {/* {checked
                .map((x) => (x.locNm ? x.locNm.toUpperCase() : ""))
                .join("/")}{" "}
              and will not be available for delivery in India. If you wish to
              confirm the stone, kindly get in touch with your respective Sales
              Person. */}
              of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
              the leap into electronic typesetting,
              {/* <br />
              <p className="mt-10">
                **With the current scenario due to the Pandemic, any shipment,
                from any location, will take at least 7-10 working days.
              </p> */}
              of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
              the leap into electronic typesetting,
            </p>
          ) : (
            <p>
              {/* Packet No: {checked.map((x) => x.vStnId).join(", ")}, is currently
              located in India and for delivery in any other country apart from
              India will take at least 7-10 working days. */}
              of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
              the leap into electronic typesetting,
            </p>
          )}
        </CommonModal>
      ) : null}
      {showGuest && (
        <GuestUserPopup
          visible={showGuest}
          register={() => {
            window.open("/signup", "_self");
            window.localStorage.clear();
            window.sessionStorage.clear();
          }}
          handleCancel={() => {
            setShowGuest(false);
          }}
        />
      )}
      {noKyc && (
        <NoKycPop
          status={authUser.account && authUser.account.isApproved}
          visible={noKyc}
          handleCancel={() => {
            setNoKyc(false);
          }}
        />
      )}
    </>
  );
};

export default connect(
  (state) => ({
    deleteReminder: state.deleteReminder,
    notifyCount: state.notify.notifyCount,
  }),
  { setCount, throwNotifyCount, getCartCount }
)(DiamondListingAction);
