import React from "react";
import { withRouter, Link } from "react-router-dom";

import urlJoin from "url-join";

import IntlMessage from "util/IntlMessage";

import cpSVG from "../../../assets/svg/Account/change-password.svg";
import compSVG from "../../../assets/svg/Account/company.svg";
import diaSVG from "../../../assets/svg/Account/diamond.svg";
import prsnlSVG from "../../../assets/svg/Account/personal.svg";
import summSVG from "../../../assets/svg/Account/summary.svg";
import { LOCAL_STORAGE_VAR, BASE_URL } from "../../../constants/Common";
import UtilService from "../../../services/util";
import DefaultUser from "../../common/UserProfileDefault";

const MobileAccount = () => {
  const { name, mobile, countryCode, profileImage } = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  const AccountList = (props) => {
    return (
      <Link to={props.link} className={`manageListItem ${props.className}`}>
        <div className="listIcon d-flex justify-content-center align-items-center">
          <img src={props.image} alt="" />
        </div>
        <span className="listTitle">{props.title}</span>
      </Link>
    );
  };
  return (
    <div className="">
      <div className="accountWrapper">
        <Link className="accountTopBlock" to="/my-account/personal-information">
          <div className="accountUser">
            {profileImage ? <img src={urlJoin(BASE_URL, profileImage)} alt="" /> : <DefaultUser />}
            <div className="userRightDetail">
              <h2 className="mobileUserTitle">{name}</h2>
              <p>
                +
                {countryCode} 
                {' '}
                {mobile}
              </p>
            </div>
          </div>
        </Link>
        <div className="mobileAccountBlock">
          <div className="mobileAccountList">
            <h2>
              <IntlMessage id="app.ManageAccount" />
            </h2>
            <div className="accountManageList d-flex">
              <AccountList className="active" link="/my-account/summary" title="Sumamry" image={summSVG} />
              <AccountList link="/my-account/personal-information" title="Personal" image={prsnlSVG} />
              <AccountList link="/my-account/company-profile" title="Company" image={compSVG} />
              <AccountList link="/my-account/change-password" title="Password" image={cpSVG} />
              <AccountList link="/my-account/manage-sequence" title="Sequence" image={diaSVG} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(MobileAccount);
