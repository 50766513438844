import { isArray, isEmpty, isObject, isString } from "util/utils";

const initialState = {
  selectedRows: {},
  selectedRowIds: {},
};

export const DiamondActionTypes = {
  SELECT_ROW: "SELECT_ROW",
  SELECT_ROWS: "SELECT_ROWS",
  UNSELECT_ROW: "UNSELECT_ROW",
  UNSELECT_ROWS: "UNSELECT_ROWS",
  RESET_SELECTED_ROWS: "RESET_SELECTED_ROWS",
  RESET_ROWS_ON_PAGINATION: "RESET_ROWS_ON_PAGINATION",
};

export const DiamondActions = {
  selectRow: (payload = {}) => ({ type: DiamondActionTypes.SELECT_ROW, ...payload }),
  unSelectRow: (payload = {}) => ({ type: DiamondActionTypes.UNSELECT_ROW, ...payload }),
  selectRows: (payload = {}) => ({ type: DiamondActionTypes.SELECT_ROWS, ...payload }),
  unSelectRows: (payload = {}) => ({ type: DiamondActionTypes.UNSELECT_ROWS, ...payload }),
  resetSelectedRows: (payload = {}) => ({ type: DiamondActionTypes.RESET_SELECTED_ROWS, ...payload }),
  resetRowsOnPagination: (payload = {}) => ({ type: DiamondActionTypes.RESET_ROWS_ON_PAGINATION, ...payload }),
};

export default (state = initialState, { type, ...data }) => {
  const payload = isObject(data?.payload) ? [data?.payload] : isArray(data?.payload) ? data?.payload : [];
  const currentType = isString(data?.currentType) ? data?.currentType : undefined;
  const currentRows = data?.replace ? [] : state?.selectedRows?.[currentType] ?? [];
  const currentRowIds = data?.replace ? [] : state?.selectedRowIds?.[currentType] ?? [];

  switch (type) {
    case DiamondActionTypes.SELECT_ROW:
    case DiamondActionTypes.SELECT_ROWS: {
      const newRows = payload.filter((row) => {
        if (isEmpty(row)) return false;
        // if (row?.disableSelection) return false;
        if (currentRowIds.includes(row?.selectionKey ?? row?.id)) return false;
        return true;
      });

      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);

      return {
        ...state,
        selectedRows: { ...state?.selectedRows, [currentType]: [...currentRows, ...newRows] },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: [...currentRowIds, ...newRowIds] },
      };
    }

    case DiamondActionTypes.UNSELECT_ROW:
    case DiamondActionTypes.UNSELECT_ROWS: {
      const rowIdsToRemove = payload.map((row) => row?.selectionKey ?? row?.id);
      const newRows = currentRows.filter((row) => !rowIdsToRemove.includes(row?.selectionKey ?? row?.id));
      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);

      return {
        ...state,
        selectedRows: { ...state?.selectedRows, [currentType]: newRows },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: newRowIds },
      };
    }

    case DiamondActionTypes.RESET_ROWS_ON_PAGINATION: {
      return { ...initialState };
    }

    case DiamondActionTypes.RESET_SELECTED_ROWS: {
      if (!isEmpty(currentType)) {
        delete state?.selectedRows?.[currentType];
        delete state?.selectedRowIds?.[currentType];
        return { ...state, selectedRows: { ...state?.selectedRows }, selectedRowIds: { ...state?.selectedRowIds } };
      }
      return { ...initialState };
    }

    default:
      return state;
  }
};
