import React from "react";

import { Tooltip } from "antd";
import { memoize } from "lodash";

import { TOOLTIP_FIELDS } from "components/DiamondSearch/_data";

import { isArray } from "util/utils";

function MasterFilter(props) {
  const { field = {}, name, value, labelBold } = props;
  const { onChange } = props;

  const list = React.useMemo(() => {
    const list_ = field.list?.map?.((item) => {
      item.checked = (() => {
        if (!isArray(item.id) || !isArray(value)) return false;
        return item.id.every((id) => value.includes(id));
      })();
      return item;
    });
    return list_ ?? [];
  }, [field.list, value]);

  const labelStyle = React.useMemo(() => {
    return { fontWeight: labelBold ? "400" : undefined };
  }, [labelBold]);

  const handleChange = React.useMemo(() => {
    return memoize(
      (...args) => {
        return () => onChange(...args);
      },
      (...args) => JSON.stringify(args)
    );
  }, [onChange]);

  return (
    <>
      {name && (
        <div>
          <h3 style={labelStyle} className="title clarity-range">
            {name}
          </h3>
        </div>
      )}

      <ul className="diamondSearchList">
        {list.map((item) => {
          const listItem = (
            <li
              // title={item?.description}
              key={`content-${field?.key}-${item.id}`}
              onClick={handleChange(item.id, !item.checked, field?.key)}
              className={item.checked && `active`}
            >
              {item.webDisplay}
            </li>
          );

          return isArray(TOOLTIP_FIELDS) && TOOLTIP_FIELDS.includes(field?.key) ? (
            <Tooltip
              key={`tooltip-${field?.key}-${item.id}`}
              title={item?.description}
              getTooltipContainer={(trigger) => trigger.parentElement}
            >
              {listItem}
            </Tooltip>
          ) : (
            listItem
          );
        })}
      </ul>
    </>
  );
}

export default React.memo(MasterFilter);
