import React from "react";

import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import CardView from "../Profile/CardView";

const SocialCardView = (props) => {
  const dt = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  return <CardView socialDetail={dt} social onClose={props.onClose} onClick={props.onClick} />;
};
export default SocialCardView;
