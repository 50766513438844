import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GotoTop() {
  const routePath = useLocation();
  const googleAnaTrack = () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-HQT395WHHT";
    script.async = true;
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-HQT395WHHT");
    document.body.appendChild(script);
  };

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    onTop();
  }, [routePath]);

  return null;
}
