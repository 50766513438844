import React from "react";

import moment from "moment";

class Countdown extends React.Component {
  state = {
    time: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
  };

  componentDidMount() {
    setInterval(() => this.forceUpdate(), 1000);
    this.setTimer(this.props.currTime);
  }

  setTimer = (tm) => {
    let time = tm;
    setInterval(async () => {
      if (time && moment(time)._isValid) {
        time = moment(time).add(1, "seconds");
        this.setState({ time: moment(time) });
      }
    }, 1000);
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.currTime !== nextProps.currTime) this.setTimer(nextProps.currTime);
  };

  leadingZero = (num) => {
    return num <= 0 ? "00" : num < 10 && num > 0 ? "0" + num : num;
  };

  getses = (num, label) => {
    if (num === 1 || num === "01") return label;
    else return label + "s";
  };

  render() {
    if (!this.props.date || !this.props.date.diff) return null;
    //let current = this.props.currTime ? moment(this.props.currTime) : moment();
    //let current = moment();
    const current = this.state.time;
    const dateTime = this.props.date;

    const diff = moment.duration(dateTime?.diff(current))._data;
    if (diff?.days) diff.hours += diff.days * 24;
    if (diff?.hours + diff?.minutes + diff?.seconds === 0 && this.props.bidEndTime) this.props.bidEndTime();
    return (
      <div className="App">
        <div className="countDownBlock">
          <div className="countdownItem">
            <span>{this.leadingZero(diff.hours)}</span>
            <span>{this.getses(diff.hours, "hour")}</span>
          </div>
          <div className="countdownItem">
            <span>{this.leadingZero(diff.minutes)}</span>
            <span>{this.getses(diff.minutes, "minute")}</span>
          </div>
          <div className="countdownItem">
            <span>{this.leadingZero(diff.seconds)} </span>
            <span>{this.getses(diff.seconds, "second")}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Countdown;
