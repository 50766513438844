import React from "react";

import FileType from "file-type/browser";
import PropTypes from "prop-types";

import { notify } from "util/notify";
import { isEmpty, isNotEmpty } from "util/utils";

function FilePicker(props) {
  const { label = "Upload File", typeError, extensions = "", onChange } = props;

  const extensionList = React.useMemo(() => extensions.split(",").filter(isNotEmpty), [extensions]);

  const handleChange = React.useCallback(
    ({ target }) => {
      const file = target.files?.[0];
      if (!(file instanceof File)) return;

      const reader = new FileReader();
      reader.onload = async ({ target: { result } }) => {
        const type = await FileType.fromBuffer(result);
        if (!isEmpty(extensionList) && !extensionList.includes(type?.ext)) {
          const message = typeError ?? `File format is not supported. Please upload ${extensionList.join(", ")} file.`;
          return notify.error({ message });
        }
        onChange(file);
      };
      reader.readAsArrayBuffer(file);
    },
    [extensionList, onChange, typeError]
  );

  return (
    <div>
      <input type="file" id="file" accept="xlsx" onChange={handleChange} hidden />
      <label htmlFor="file" className="d-block mb-0">
        {label}
      </label>
    </div>
  );
}

FilePicker.propTypes = {
  label: PropTypes.string,
  typeError: PropTypes.string,
  extensions: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(FilePicker);
