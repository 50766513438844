import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Avatar, Badge } from "@material-ui/core";
import { Drawer, Dropdown } from "antd";

import { getSearchList } from "containers/APIFunctions";

import CountBox from "components/common/CountBox";
import getData from "components/common/svgSidebar";

import { BidService } from "services/BidService";

import { LOCAL_STORAGE_VAR, trackTypeObj } from "constants/Common";
import { GET_DASHBOARD_DATA, GET_NOTIFICATIONS, MARK_AS_READ } from "constants/apiConstant";

import MobileSidebar from "./sidebar";

import { setLoader, covidStrip } from "../../appRedux/actions/Common";
import { throwNotifyCount, throwCartCount } from "../../appRedux/actions/notifyAction";
import cartWhiteSVG from "../../assets/svg/Header/cart-white.svg";
import cartWhiteBLankSVG from "../../assets/svg/Header/cart_blank_icon.svg";
import notificationSVG from "../../assets/svg/Header/notification.svg";
import searchSVG from "../../assets/svg/Header/search.svg";
import menuSVG from "../../assets/svg/MobileHeader/menu.svg";
import calBackSVG from "../../assets/svg/PriceCalc/calc-black.svg";
import UtilService from "../../services/util";
import Logo from "../Header/Logo";
import PriceCalculator from "../PriceCalculator";
import CovidStrip from "../common/Header/CovidStrip";
import HeaderSearch from "../common/Header/Search";

const BID_TYPE = {
  NEW_ARRIVAL: 1,
  BID_TO_BUY: 2,
};
class MobileHeader extends Component {
  constructor() {
    super();
    this.state = {
      isGuest: UtilService.getLocalStorageItem(
        window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
      ),
      showGuest: false,
      search: false,
      searchList: [],
      isHovered: false,
      isHovered1: false,
      isHovered2: false,
      visible: false,
      covid: true,
      HeaderStripList: true,
      showBid: false,
      showArr: false,
      totalBidDiamondCountBywebStatus: 0,
      totalNewArrDiamondCountBywebStatus: 0,
      showOnlySearch: undefined,
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleHover1 = this.handleHover1.bind(this);
    this.handleHover2 = this.handleHover2.bind(this);
  }

  componentDidMount() {
    this.props.covidStrip(this.state.covid);
    this.getNotifications();
    this.getSidebarCounts();
    getSearchList((list) => this.setState({ searchList: list }));
  }
  getSidebarCounts = () => {
    const self = this;
    const obj = {
      track: true,
    };
    const api = GET_DASHBOARD_DATA.url;
    const objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        self.props.throwCartCount(data?.data?.tracks?.[trackTypeObj.CART]?.pieces);
      }
    });
  };
  closeStrip = () => {
    this.props.covidStrip(!this.props.commonData.strip);
    this.setState({
      covid: false,
    });
  };
  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.commonData.strip);
    this.setState({
      HeaderStripList: !this.state.HeaderStripList,
    });
  };
  // Api is just called to get flag
  setBid = async () => {
    let nextBid = {};
    nextBid = await BidService.getConfig({ dynamicBidType: BID_TYPE.BID_TO_BUY });
    if (nextBid?.dynamicBidType === 2 && JSON.stringify(nextBid) !== "[]") {
      this.setState({ showBid: true });

      if (nextBid?.totalBidDiamondCountBywebStatus !== undefined) {
        this.setState({
          showOnlySearch: nextBid?.showOnlySearch,
        });
        this.setState({ totalBidDiamondCountBywebStatus: nextBid?.totalBidDiamondCountBywebStatus });
      }
    } else {
      this.setState({ showBid: false });
    }

    let nextArr = {};
    nextArr = await BidService.getConfig({ dynamicBidType: BID_TYPE.NEW_ARRIVAL });

    if (nextArr?.dynamicBidType === 1 && JSON.stringify(nextArr) !== "[]") {
      this.setState({ showArr: true });

      if (nextArr?.totalNewArrDiamondCountBywebStatus !== undefined) {
        this.setState({
          showOnlySearch: nextArr?.showOnlySearch,
        });
        this.setState({ totalNewArrDiamondCountBywebStatus: nextArr?.totalNewArrDiamondCountBywebStatus });
      }
    } else {
      this.setState({ showArr: false });
    }

    if (nextArr === undefined && nextBid === undefined) {
      this.setState({
        showOnlySearch: true,
      });
    }
  };
  showDrawer = () => {
    this.setState(
      {
        visible: true,
      },
      () => {
        this.setBid();
      }
    );
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  handleHover() {
    this.setState({
      isHovered: !this.state.isHovered,
      isHovered1: false,
      isHovered2: false,
    });
  }
  handleHover1() {
    this.setState({
      isHovered1: !this.state.isHovered1,
      isHovered: false,
      isHovered2: false,
    });
  }
  handleHover2() {
    this.setState({
      isHovered2: !this.state.isHovered2,
      isHovered: false,
      isHovered1: false,
    });
  }
  handleOut = () => {
    this.setState({
      isHovered: false,
    });
  };
  handleOut1 = () => {
    this.setState({
      isHovered1: false,
    });
  };
  handleOut2 = () => {
    this.setState({
      isHovered2: false,
    });
  };
  searchOption = () => {
    if (!this.state.isGuest) {
      this.setState({
        search: !this.state.search,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  getNotifications = () => {
    const self = this;
    const obj = {
      ...GET_NOTIFICATIONS,
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        if (data.data) {
          const total = data.data.unReadCount;
          self.setState({
            notifications: total,
          });
          self.props.throwNotifyCount(total);
        }
      }
    });
  };

  render() {
    const permissionData = this.props.commonData.pagePermission();
    const sidebarSvgFiles = getData(permissionData);
    const { name } = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    return (
      <>
        {this.state.covid && <CovidStrip onClick={this.closeStrip} />}
        <div className="headerTopMenu">
          <div className="headerWrapper">
            <div className="mobileMenu" onClick={this.showDrawer}>
              <img src={menuSVG} alt="menuIcon" />
            </div>
            <div className="headerLeft">
              <div className="headerLogo">
                <Link to="/dashboard" className="">
                  <Logo />
                </Link>
              </div>
              <div className="headerMobileIcon">
                <div className="headerMobileIconItem" onClick={this.searchOption}>
                  <img alt="img" src={searchSVG} />
                </div>
                <HeaderSearch
                  className={`${this.state.search && "active"}`}
                  onClick={this.searchOption}
                  searchList={this.state.searchList}
                />
                <Link className="headerMobileIconItem" to="/account/cart" id="step2">
                  {this.props.cartCount ? (
                    <>
                      <img src={cartWhiteSVG} alt="" />
                      <Badge className="countBoxNew" badgeContent={this.props.cartCount}></Badge>
                    </>
                  ) : (
                    <>
                      <img src={cartWhiteBLankSVG} alt="" />
                    </>
                  )}
                </Link>
                <Dropdown
                  placement="bottomLeft"
                  overlayClassName="priceCalcDropWrapper"
                  overlay={<PriceCalculator onClick={this.calculatorClick} />}
                  trigger={["click"]}
                >
                  <Link className="headerMobileIconItem" to="/" onClick={(e) => e.preventDefault()}>
                    <img alt="img" src={calBackSVG} />
                  </Link>
                </Dropdown>
                <Link className="headerMobileIconItem" to="/my-account/summary" id="step4">
                  {name && (
                    <Avatar
                      style={{
                        backgroundColor: "#7F5F42",
                        fontFamily: "CronosPro",
                        fontSize: 10,
                        letterSpacing: "2px",
                        width: 24,
                        height: 24,
                      }}
                    >
                      {`${name && name.trim().charAt(0).toUpperCase()}${
                        name && name.trim().split(/\s+/)?.[1].charAt(0).toUpperCase()
                      }`}{" "}
                    </Avatar>
                  )}
                </Link>

                <Link
                  onClick={() => {
                    if (this.props.notifyCount > 0) {
                      const obj = {
                        ...MARK_AS_READ,
                        request: {},
                      };
                      UtilService.callApi(obj, (err, data) => {
                        if (data && data.code === "OK") {
                          if (data.data) {
                            const total = data.data.unReadCount;
                            this.setState({
                              notifications: total,
                            });
                            this.props.throwNotifyCount(total);
                          }
                        }
                      });
                    }
                  }}
                  className="headerMobileIconItem"
                  to="/notifications"
                  id="step3"
                >
                  <img alt="img" src={notificationSVG} />
                  {this.props.notifyCount > 0 && (
                    <CountBox
                      className="countBoxMobile"
                      count={
                        this.props.notifyCount >= 100
                          ? "99+"
                          : this.props.notifyCount < 10
                          ? "0" + this.props.notifyCount
                          : this.props.notifyCount
                      }
                    />
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Drawer
          title={false}
          onClose={this.onClose}
          visible={this.state.visible}
          wrapClassName={`mobileSidebar mobileSidebarHeader ${this.props.className}`}
        >
          <div>
            <MobileSidebar
              showArr={this.state.showArr}
              showBid={this.state.showBid}
              totalBidDiamondCountBywebStatus={this.state.totalBidDiamondCountBywebStatus}
              totalNewArrDiamondCountBywebStatus={this.state.totalNewArrDiamondCountBywebStatus}
              sidebarSvgFiles={sidebarSvgFiles}
              onClose={this.onClose}
              showOnlySearch={this.state.showOnlySearch}
            />
          </div>
        </Drawer>
      </>
    );
  }
}

// export default Header;
function mapStateToProps(props) {
  return {
    cartCount: props.notify?.cartCount,
    commonData: props.commonData,
    notifyCount: props.notify?.notifyCount,
  };
}

export default connect(mapStateToProps, {
  throwCartCount,
  throwNotifyCount,
  setLoader,
  covidStrip,
})(MobileHeader);
