import React, { useEffect, useMemo, useState } from "react";

import { Checkbox } from "antd";

import OpenNotification from "components/common/CommonButton/OpenNotification";

import { Storage } from "services/storage";
import UtilService from "services/util";

import IntlMessage from "util/IntlMessage";

import { ALLOWED_NOTIFICATIONS, ALLOWED_NOTIFICATIONS_USER } from "constants/apiConstant";

export const ManageClientMail = () => {
  const [allowedNotification, setAllowedNotification] = useState({
    newArrival: true,
    myOffer: true,
    bidToBuy: true,
    newsLetter: true,
  });
  const [userId, setUserId] = useState(null);

  const cardStyle = { padding: 12, margin: 10, background: "#f8f8f8", width: 180, height: "auto" };

  const updateHandle = () => {
    const reqObj = {
      allowedNotification,
    };

    const obj = {
      ...ALLOWED_NOTIFICATIONS,
      request: reqObj,
    };

    UtilService.callApi(obj, async (err, data) => {
      if (err && err.data) {
        OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } else if (data && data.code === "OK") {
        OpenNotification({
          type: "success",
          title: data.message,
        });
      }
    });
  };

  const handaleCheck = (val, type) => {
    if (type === "newArrival") {
      setAllowedNotification({ ...allowedNotification, newArrival: val });
    }

    if (type === "myOffer") {
      setAllowedNotification({ ...allowedNotification, myOffer: val });
    }

    if (type === "bidToBuy") {
      setAllowedNotification({ ...allowedNotification, bidToBuy: val });
    }

    if (type === "newsLetter") {
      setAllowedNotification({ ...allowedNotification, newsLetter: val });
    }
  };

  useMemo(() => {
    if (userId) {
      const reqObj = {
        userId,
      };

      const obj = {
        ...ALLOWED_NOTIFICATIONS_USER,
        request: reqObj,
      };

      UtilService.callApi(obj, async (err, data) => {
        if (err && err.data) {
          OpenNotification({
            type: "error",
            title: err.data.message,
          });
        } else if (data && data.code === "OK") {
          setAllowedNotification(data.data[0].allowedNotification);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    const user = Storage.get("user");
    if (user && Object.keys(user).length) {
      setUserId(user.id);
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="row client_mail_main">
          <Checkbox
            onChange={(e) => {
              handaleCheck(e.target.checked, "newArrival");
            }}
            checked={allowedNotification.newArrival}
            style={cardStyle}
          >
            New Arrival
          </Checkbox>

          <Checkbox
            onChange={(e) => {
              handaleCheck(e.target.checked, "bidToBuy");
            }}
            checked={allowedNotification.bidToBuy}
            style={cardStyle}
          >
            Bid To Buy
          </Checkbox>

          <Checkbox
            onChange={(e) => {
              handaleCheck(e.target.checked, "myOffer");
            }}
            checked={allowedNotification.myOffer}
            style={cardStyle}
          >
            My Offer
          </Checkbox>

          <Checkbox
            onChange={(e) => {
              handaleCheck(e.target.checked, "newsLetter");
            }}
            checked={allowedNotification.newsLetter}
            style={cardStyle}
          >
            News Letter
          </Checkbox>
          <div className="botoomStickyBar diamondListingAction justify-content-center">
            <button className="bottomStickyButton" onClick={updateHandle}>
              <IntlMessage id="app.updateButton" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
