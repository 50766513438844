import _, { isEmpty, includes } from "lodash";

import { pruneEmpty } from "util/utils";

import OpenNotification from "../components/common/CommonButton/OpenNotification";
import {
  SEARCH_DIAMOND_LIST,
  SEARCH_LIST,
  CREATE_SAVED_SEARCH,
  SELECT_SEARCH,
  GET_MATCH_PAIR,
} from "../constants/apiConstant";
import UtilService from "../services/util";

export const handleSearch = async (filter, newArr, cb, loader, isDashboardSearch = false) => {
  const apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });

  const obj = {
    filters: [apiObj],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  if (isDashboardSearch) {
    obj.isDashboardSearch = true;
  }
  if (newArr) obj.viewType = 2;

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    showLoader: !!loader,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }
    } else if (data && data.code === "OK") {
      if (data.data[0].count === 0) {
        cb({ NOSTONE: "NOSTONE", id: data.data[0].filter.id, count: data.data[0].count });
      } else {
        cb({ NOSTONE: "", id: data.data[0].filter.id, count: data.data[0].count });
      }
    }
  });
};

export const getSearchList = (cb) => {
  const obj = {
    type: 2,
    isAppendMasters: true,
  };
  const api = SEARCH_LIST.url;
  const objData = {
    method: SEARCH_LIST.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(data.data.list);
    }
  });
};

export const handleMultiSearch = (filters, cb) => {
  const masterApiObj = [];
  filters.forEach((filter) => {
    let apiObj = {
      ...filter,
    };

    if (!isEmpty(filter.isFm)) {
      apiObj = {
        ...apiObj,
        lbNm: "FM",
      };
    }

    if (includes(filter.wSts, "B")) {
      delete apiObj.prcStg;
      apiObj = {
        ...apiObj,
      };
    }

    _.each(apiObj, (val, key) => {
      if (_.isArray(val) && val.length === 0) delete apiObj[key];
      else if (_.isArray(val)) apiObj[key] = val;
    });
    masterApiObj.push(apiObj);
  });

  const obj = {
    filters: masterApiObj,
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }
    } else if (data && data.code === "OK") {
      const ids = [];
      data.data.map((d) => ids.push(d.filter.id));
      cb({ ids });
    }
  });
};

export const handleSavedSearch = (filter, title, id, cb) => {
  const apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === "") {
    return OpenNotification({
      type: "error",
      title: "Please enter search title.",
    });
  }

  const obj = {
    filter: apiObj,
    name: title,
    searchType: 2,
    sort: [{ createdAt: "DESC" }],
    id,
  };

  const api = CREATE_SAVED_SEARCH.url;
  const objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      OpenNotification({
        type: "error",
        title: err.data.message || "Something went wrong.",
      });
    } else if (data && data.code === "OK") {
      cb(data.data.id);
    }
  });
};

export const handleMatchPair = (filter, cb) => {
  const apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });
  const obj = {
    filter: apiObj,
    page: 1,
    limit: 1000,
  };
  const api = GET_MATCH_PAIR.url;
  const objData = {
    method: GET_MATCH_PAIR.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err && err.data && err.data.message) {
      return OpenNotification({
        type: "error",
        title: err.data.message,
      });
    } if (data && data.code === "OK") {
      cb(data.data.filter.id);
    }
  });
};

export const handleNewArrival = async (filter, cb) => {
  const apiObj = filter;
  apiObj.wSts = "N";
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });

  const obj = {
    filters: [apiObj],
    page: 1,
    limit: 100,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }
    } else if (data && data.code === "OK") {
      if (data.data[0].count === 0) {
        cb({ id: data.data[0].filter.id });
      } else {
        cb({ id: data.data[0].filter.id });
      }
    }
  });
};

export const handleBidToBuy = async (filter, cb) => {
  const apiObj = filter;
  apiObj.wSts = "B";
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });

  const obj = {
    filters: [apiObj],
    page: 1,
    limit: 100,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      }
    } else if (data && data.code === "OK") {
      if (data.data[0].count === 0) {
        cb({ id: data.data[0].filter.id });
      } else {
        cb({ id: data.data[0].filter.id });
      }
    }
  });
};

export const handleSelectSearch = (searchId, cb) => {
  const obj = {
    id: searchId,
    isAppendMasters: true,
  };
  const api = SELECT_SEARCH.url;
  const objData = {
    method: SELECT_SEARCH.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(data.data.list[0].searchData, data.data.list[0].name, data.data.list[0].type);
    }
  });
};

export const getCount = (filter, cb) => {
  filter = { ...filter };
  _.each(filter, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete filter[key];
    else if (_.isArray(val)) filter[key] = _.uniq(val);
  });

  filter.kToSArr = pruneEmpty({ value: filter.kToSArr })?.value;

  const obj = {
    isNotReturnTotal: true,
    isReturnCountOnly: true,
    isSkipSave: true,
    filters: [filter],
  };

  const api = SEARCH_DIAMOND_LIST.url;
  const objData = {
    method: SEARCH_DIAMOND_LIST.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, (err, data) => {
    if (err) throw err;
    if (data && data.code === "OK") {
      cb(data.data[0].count, data.data[0].newArrivalCount, data.data[0].bidToBuyCount);
    }
  });
};

export const handleAddDemand = (filter, title, date, cb) => {
  const apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === "") {
    return OpenNotification({
      type: "error",
      title: "Please enter demand title.",
    });
  }

  if (!date) {
    return OpenNotification({
      type: "error",
      title: "Please select date for adding demand.",
    });
  }

  const obj = {
    filter: apiObj,
    name: title,
    searchType: 3,
    expiryDate: date,
  };

  const api = CREATE_SAVED_SEARCH.url;
  const objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, (err, data) => {
    if (err) {
      OpenNotification({
        type: "error",
        title: err.data.message || "Something went wrong.",
      });
    } else if (data && data.code === "OK") {
      cb(true);
      OpenNotification({
        type: "success",
        title: "Your demand is added successfully.",
      });
    }
  });
};
