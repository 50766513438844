import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";

import queryString from "query-string";

import DiamondSearch from "containers/DiamondSearch";

import Account from "components/Account";
import ChangePassword from "components/Account/ChangePassword";
import CompanyInformation from "components/Account/CompanyInformation";
import MyAccount from "components/Account/MyAccount";
import PersonalInformation from "components/Account/PersonalInformation";
import BidToBuy from "components/BidToBuy";
import Version1 from "components/Dashboard/Version1";
import Compare from "components/DiamondCompare";
import DiamondDetail2 from "components/DiamondDetail/DetailVersion2";
import DiamondList from "components/DiamondList";
import BidList from "components/DiamondList/BidList";
import MyDemand from "components/MyDemand/index";
import NewArrivalBidIt from "components/NewArrivalBidIt";
import PairDiamond from "components/PairDiamond";
import QuickSearch from "components/QuickSearch";
import SavedSearch from "components/SavedSearch";
import NotificationDropdown from "components/common/Header/NotificationDropdown";
import Notifications from "components/common/Header/Notifications";

import { Storage } from "services/storage";

import { withMobile } from "util/hocs";
import { isEmpty } from "util/utils";

import { LOCAL_STORAGE_VAR, commonLogout } from "constants/Common";

import MainApp from "App";

import UtilService from "../services/util";

class FrontRoute extends Component {
  redirectPage = () => {
    const path = window.location.pathname.split("/").pop();

    if (path === "device") {
      const values = queryString.parse(this.props.location.search);
      if (values && values.token) UtilService.setLocalStorageItem(values.token, `${LOCAL_STORAGE_VAR}-token`);
    }
    const tokens = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);

    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    if (tokens && isEmpty(USER_PERMIT)) commonLogout();
    const isTrusted = localStorage.getItem(`${LOCAL_STORAGE_VAR}-isTrusted`);

    const isLoggedInUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);

    if (isLoggedInUser && isLoggedInUser.length && isTrusted === "false") {
      return <Redirect to="/verify-otp" />;
    } else if (!isLoggedInUser || !isLoggedInUser.length) {
      window.location.assign("/signin");
      // return <Redirect to="/signin" />;
    }
  };

  render() {
    const { isMobile } = this.props;

    if (window.drift) {
      window.drift.on("campaign:dismiss", () => {});

      if (window.drift && window.drift.api) {
        window.drift.api.widget.hide();
      }
    }

    const P = Storage.get("userPermissions") ?? {};

    return (
      <MainApp loading={this.props.commonData?.loading}>
        <Switch>
          {this.redirectPage()}
          {P?.DASHBOARD?.view && <Route exact path="/dashboard" component={Version1} />}
          <Route exact path="/my-account/summary" component={MyAccount} />
          <Route exact path="/my-account/kyc-detail" component={MyAccount} />
          <Route exact path="/my-account/change-password" component={MyAccount} />
          <Route exact path="/my-account/manage-sequence" component={MyAccount} />
          <Route exact path="/my-account/manage-client-mail" component={MyAccount} />
          <Route exact path="/my-account/manage-client-mail/user" component={MyAccount} />
          {P?.SEARCH_LIST?.view && <Route exact path="/diamond-list" component={DiamondList} />}
          {P?.QUICK_SEARCH?.view && <Route exact path="/quick-search" component={QuickSearch} />}
          {isMobile && <Route exact path="/my-account/personal-information" component={PersonalInformation} />}
          {isMobile && <Route exact path="/my-account/company-profile" component={CompanyInformation} />}
          {isMobile && <Route exact path="/my-account/change-password" component={ChangePassword} />}
          {P?.COMPARE?.view && <Route exact path="/compare" component={Compare} />}
          {isMobile && <Route exact path="/diamond-detail2/:id" component={DiamondDetail2} />}
          {P?.CART?.view &&
            (isMobile ? (
              <Route exact path="/account/cart" component={DiamondList} />
            ) : (
              <Route exact path="/account/cart" component={Account} />
            ))}
          {P?.WATCHLIST?.view &&
            (isMobile ? (
              <Route exact path="/account/watchlist" component={DiamondList} />
            ) : (
              <Route exact path="/account/watchlist" component={Account} />
            ))}
          {P?.OFFICE?.view &&
            (isMobile ? (
              <Route exact path="/account/view-diamonds" component={DiamondList} />
            ) : (
              <Route exact path="/account/view-diamonds" component={Account} />
            ))}
          {P?.NOTES?.view &&
            (isMobile ? (
              <Route exact path="/account/notes" component={DiamondList} />
            ) : (
              <Route exact path="/account/notes" component={Account} />
            ))}
          {P?.QUOTE?.view && <Route exact path="/account/offer-list" component={Account} />}
          {Boolean(P?.CONFIRM_STONE?.view) &&
            (isMobile ? (
              <Route exact path="/account/order-list" component={DiamondList} />
            ) : (
              <Route exact path="/account/order-list" component={Account} />
            ))}
          {P?.SAVE_SEARCH?.view &&
            (isMobile ? (
              <Route exact path="/account/saved-search" component={SavedSearch} />
            ) : (
              <Route exact path="/account/saved-search" component={Account} />
            ))}
          {P?.DEMAND?.view &&
            (isMobile ? (
              <Route exact path="/account/my-demand" component={MyDemand} />
            ) : (
              <Route exact path="/account/my-demand" component={Account} />
            ))}
          {P?.NOTIFICATIONS?.view && <Route exact path="/account/notification" component={Account} />}
          {P?.NOTIFICATIONS?.view && <Route exact path="/notifications" component={Notifications} />}
          {/* {P?.MATCH_PAIR?.view && <Route exact path="/match-pair" component={DiamondList} />} */}
          {P?.UPCOMING?.view && <Route exact path="/upcoming" component={DiamondList} />}
          {<Route exact path="/white-search" component={DiamondList} />}
          {P?.EXCLUSIVE?.view && <Route exact path="/kgk-exclusive" component={DiamondList} />}
          {P && <Route exact path="/offerlist" component={DiamondList} />}
          {P?.SEARCH_DIAMOND?.view && <Route path="/diamond-search" component={DiamondSearch} />}
          {P?.FANCY_SEARCH?.view && <Route exact path="/fancy-search" component={DiamondList} />}
          {P?.FANCY_SEARCH?.view && <Route path="/fancy-search" component={DiamondSearch} />}
          {P?.NOTIFICATIONS?.view && isMobile && <Route exact path="/notification" component={NotificationDropdown} />}
          {P?.NEW_ARRIVAL?.view && <Route exact path="/new-arrival" component={NewArrivalBidIt} />}
          {P?.BID_TO_BUY?.view && <Route exact path="/bid-to-buy" component={BidToBuy} />}
          {P?.MY_BID?.view && <Route exact path="/my-bid" component={BidList} />}
          {<Route exact path="/pair-diamonds" component={PairDiamond} />}
          <Redirect from="*" to="/not-found" />
        </Switch>
      </MainApp>
    );
  }
}

export default compose(withMobile, withRouter)(FrontRoute);
