import React, { Component } from "react";

import isEmpty from "lodash/isEmpty";

import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import { GET_USER_PROFILE } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import OpenNotification from "../../common/CommonButton/OpenNotification";
import CardView from "../Profile/CardView";

// const CompanyCardView = (props) => {
//   let dt = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
//   return <CardView cDetails={dt} companyDetail onClose={props.onClose} onClick={props.onClick} />;
// };

class CompanyCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
    };
  }

  componentDidMount() {
    this.fetchAccount();
  }

  fetchAccount() {
    const self = this;
    const obj = {
      ...GET_USER_PROFILE,
    };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === "E_BAD_REQUEST") {
        return OpenNotification({
          type: "error",
          title: err.data.message,
        });
      } if (data && data.code === "OK") {
        !isEmpty(data.data.kyc) &&
          data.data.kyc.forEach((k) => {
            if (k.type === "business") self.setState({ doc: [k.docType], path: k.path }, () => {});
          });
      }
    });
  }

  render() {
    const dt = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));

    return (
      <CardView
        companyDetail
        personalDetails={false}
        cDetails={dt}
        path={this.state.path}
        onClose={this.props.onClose}
        onClick={this.props.onClick}
      />
    );
  }
}
export default CompanyCardView;
