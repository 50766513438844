import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { history } from "util/history";

import reducers from "../reducers";
import reducerIndex from "../reducers";

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 5 })
  : compose;

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept(reducerIndex, () => {
      // const nextRootReducer = require(reducerIndex);
      // store.replaceReducer(nextRootReducer);
      import("../reducers").then((nextRootReducer) => {
        store.replaceReducer(nextRootReducer.default || nextRootReducer);
      });
    });
  }
  return store;
}

export const store = configureStore();

export { history };
