import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

const useRowSelect = (currentType) => {
  const dispatch = useDispatch();
  const selectedRowIds = useSelector((state) => state.diamondData.selectedRowIds[currentType]) || [];
  const toggleRowSelection = (row, setValue = true) => {
    if (!row.id) return;
    dispatch({ type: setValue ? "SELECT_ROW" : "UNSELECT_ROW", payload: row, currentType });
  };

  const clearAllRows = () => {
    dispatch({ type: "RESET_SELECTED_ROWS" });
  };

  const toggleAllRowSelection = useCallback(
    (displayedRows) => {
      const displayedRowIds = displayedRows.map((r) => r.id);

      const areAllChecked = displayedRowIds.every((id) => selectedRowIds.includes(id));
      const isAnyChecked = displayedRowIds.some((id) => selectedRowIds.includes(id));
      const areAllUnChecked = displayedRowIds.every((id) => !selectedRowIds.includes(id));

      if (areAllChecked) {
        return displayedRows.map((r) => {
          toggleRowSelection(r, false);
          return true;
        });
      } if (isAnyChecked) {
        return displayedRows.map((r) => {
          if (!selectedRowIds.includes(r.id)) {
            toggleRowSelection(r, true);
          }
          return true;
        });
      } if (areAllUnChecked) {
        return displayedRows.map((r) => {
          toggleRowSelection(r, true);
          return true;
        });
      }
    },
    [selectedRowIds]
  );

  const isHeaderChecked = (displayedRows) =>
    Boolean(displayedRows.length > 0 && displayedRows.map((r) => r.id).every((id) => selectedRowIds.includes(id)));

  const isRowChecked = (rowId) => selectedRowIds.includes(rowId);

  return {
    isRowChecked,
    isHeaderChecked,
    toggleRowSelection,
    toggleAllRowSelection,
    clearAllRows,
  };
};
export default useRowSelect;
