import Axios from "axios";
import Fingerprint from "fingerprintjs";

import { BASE_URL } from "constants/Common";

export const axiosFunction = Axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 60 * 10,
  headers: {
    "Content-Type": "application/json",
    uuid: new Fingerprint().get(),
  },
});

// export default axiosFunction;
