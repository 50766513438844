import React from "react";

import urlJoin from "url-join";

import { Tooltip } from "antd";
import concat from "lodash/concat";
import filter from "lodash/filter";
import includes from "lodash/includes";

import { Storage } from "services/storage";

import IntlMessage from "util/IntlMessage";
import { useRefEffect } from "util/hooks";

import { BASE_URL } from "constants/Common";
import { connect } from "react-redux";

// prettier-ignore
const SHAPES_TO_DISPLAY = ['ROUND', 'PEAR', 'EMERALD', 'ASSCHER', 'CUSHION', 'RN', 'PRINCESS', 'OV', 'MARQUISE', 'HEART'];

function ShapeSelector(props) {
  const propsRef = React.useRef(props);
  useRefEffect(propsRef, props);
  React.useEffect(() => {
    const { shpIds, setIds } = propsRef.current;
    if (shpIds && shpIds.length !== 0) {
      void setIds?.([...shpIds]);
    }
  }, []);

  const shapeSelection = React.useCallback((id) => {
    const { shpIds, setShapeIds } = propsRef.current;
    const shapes = !shpIds.includes(id[0]) ? concat(shpIds, id[0]) : filter(shpIds, (val) => id[0] !== val);
    void setShapeIds?.(shapes);
  }, []);

  const shapes = React.useMemo(() => {
    return Storage.get("master", false)?.SHAPE ?? [];
  }, []);

  return (
    <div className="dashquickSearchItem d-flex align-items-center dashboardSearchBlock">
      {!props.titleHide && (
        <div className="dashquickSearchLabel">
          <span>
            <IntlMessage id="app.Shape" />
          </span>
        </div>
      )}
      <div className="dashquickSearchBlock">
        <ul className="dashquickSearchInnerBox d-flex flex-wrap">
          {shapes.map((s, index) => {
            return (
              includes(SHAPES_TO_DISPLAY, s.code) && (
                <li
                  key={index}
                  className={includes(props.shpIds, s.id[0]) ? "active" : ""}
                  onClick={() => shapeSelection(s.id)}
                >
                  <Tooltip title={s.name} placement="bottom">
                    {/* {find(svgFiles, { name: s.code }).file} */}
                    <img src={urlJoin(BASE_URL, s.image)} alt="" />

                    <span className="shapeTitle">{s.name}</span>
                  </Tooltip>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </div>
  );
}

// export default React.memo(ShapeSelector);

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps)(ShapeSelector);
