import React from "react";

import { invert, size } from "lodash";
import moment from "moment";

// import Datepicker from '../DatePicker';

import { Storage } from "services/storage";

import { formatDecimal, formatNumber , isEmpty, isArray } from "util/utils";

import MobileConfirmStone from "./MobileComfirmStone";
import { calculate } from "./SelectStone";

import { handleConfirmStone, isMobile, memoizedGetColumn } from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import OpenNotification from "../CommonButton/OpenNotification";
import ExpandTable from "../ExpandTable";
import Heading from "../Heading";
import InputBlock from "../InputBlock";
import SelectOption from "../SelectOption";
import TextArea from "../TextArea";

export const savedSearch = {
  saleman: [{ value: "John Doe" }, { value: "John Doe" }],
  invoicDate: Storage.get("master", false)?.DAY_TERM ?? [],
  shipArray: Storage.get("master", false)?.PRE_SHIP_COMPANY ?? [],
  country: [{ value: "India" }, { value: "Hong Kong" }, { value: "US" }],
  city: [{ value: "Gujarat" }, { value: "baroda" }, { value: "Vapi" }],
  billType: [{ value: "Dollar Bill" }],
  couriername: [{ value: "Any" }, { value: "Malca Amit4" }],
  sale: [{ value: "Idex Ok" }, { value: "Blue Nile Ok" }],
  terms: [{ value: "7 Days" }, { value: "Advance" }, { value: "COD" }],
  assi: [{ value: "Vishal Virani" }, { value: "Vishal Virani" }, { value: "Vishal Virani" }],
};

const currentType = "ConfirmStonePopup";

export function HeadingCalc(checked, VALUES = null) {
  if (isMobile() && !checked.length && !VALUES) return null;
  let obj = {};
  if (VALUES) obj = VALUES;
  else {
    const sum = calculate(checked);
    obj = {
      "CT. :": parseFloat(sum.total_carat).toFixed(2),
      "Disc% :": parseFloat(sum.final_discount).toFixed(2),
      "Price/Ct :": formatNumber(parseFloat(sum.final_price).toFixed(2)),
      "Total Value :": formatNumber(parseFloat(sum.final_value).toFixed(2)),
    };
  }
  if (isMobile()) obj = invert(obj);
  if (size(obj) < 4) return null;
  return (
    <div className="DiamondDetailPopup">
      {Object.keys(obj).map((x) => {
        return (
          <div key={x} className="DiamondDetailPopupItem" style={VALUES ? checked : {}}>
            <span>{x}</span>
            <span>{isMobile() ? obj[x].slice(0, obj[x].length - 1) : obj[x]}</span>
          </div>
        );
      })}
    </div>
  );
}

const ConfirmStonePopup = (props) => {
  const defaultTerms = React.useMemo(() => {
    const payTerm = props.checked?.[0]?.terms?.id;
    return props.checked?.every?.((x) => x?.terms?.id === payTerm) ? payTerm : undefined;
  }, [props.checked]);

  const [date, setDate] = React.useState();
  const [terms, setTerms] = React.useState(defaultTerms);
  const [shippingCompany, setShippingCompany] = React.useState();
  const [courierAccNo, setCourierAccNo] = React.useState();
  const [comment, setComment] = React.useState();

  const [termsList, setTermsList] = React.useState([]);
  const [shippingCompanyList, setShippingCompanyList] = React.useState([]);

  const [columns, setColumn] = React.useState(memoizedGetColumn());
  const defaultColumn = React.useMemo(() => memoizedGetColumn(), []);

  // const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) ;

  const user = Storage.get("user");
  const company = user?.account?.companyName ?? "";

  const memoDep = props && props.checked;
  const data = React.useMemo(() => {
    if (!isArray(memoDep)) return;
    const data = memoDep;
    return data.map((stone) => ({ ...stone }));
  }, [memoDep]);

  const overrideColumns = React.useMemo(() => {
    if (!["/spanccount/offer-list"].includes(props.currentType)) return {};

    return {
      back: function Cell({ row }) {
        return !isEmpty(row?.original?.bargainTrack) && row?.original?.hasCounterOffer
          ? `${formatDecimal(row?.original?.currentValidTrack?.trackDiscount ?? 0)}% (${formatDecimal(
              row?.original?.original.back ?? 0
            )}%)`
          : `${formatDecimal(row?.original?.original?.back ?? row?.original?.back ?? 0)}%`;
      },
      ctPr: function Cell({ row }) {
        return !isEmpty(row?.original?.bargainTrack) && row?.original?.hasCounterOffer
          ? formatDecimal(row?.original?.currentValidTrack?.trackPricePerCarat ?? 0)
          : formatDecimal(row?.original?.original?.ctPr ?? row?.original?.ctPr ?? 0);
      },
      amt: function Cell({ row }) {
        return !isEmpty(row?.original?.bargainTrack) && row?.original?.hasCounterOffer
          ? formatDecimal(row?.original?.currentValidTrack?.trackAmount ?? 0)
          : formatDecimal(row?.original?.original?.amt ?? row?.original?.amt ?? 0);
      },
    };
  }, [props.currentType]);

  React.useEffect(() => {
    const dayTerms = Storage.get("master", false)?.DAY_TERM;
    const preShips = Storage.get("master", false)?.PRE_SHIP_COMPANY;

    if (isArray(dayTerms) && !isEmpty(dayTerms))
      setTermsList(dayTerms.map((term) => ({ id: term?.id?.[0], name: term?.name })));

    if (isArray(preShips) && !isEmpty(preShips))
      setShippingCompanyList(preShips.map((term) => ({ id: term?.id?.[0], name: term?.name })));
  }, []);

  const getcompany = () => {
    return <InputBlock label="Company Name*" placeholder="Company Name" value={company} disabled />;
  };

  const getinvoicedate = () => {
    return (
      <SelectOption selectValue={savedSearch.invoicDate} label="Select Invoice Date*" value={date} onChange={setDate} />
    );
  };

  const getPaymentTerms = () => {
    const payTerm = props.checked?.[0]?.terms?.id;
    return (
      <SelectOption
        selectValue={termsList}
        placeholder="Payment Terms"
        label="Payment Terms *"
        defaultValue={terms}
        value={terms}
        onChange={setTerms}
        disabled={!!payTerm}
      />
    );
  };

  const getPreShip = () => {
    return (
      <SelectOption
        selectValue={shippingCompanyList}
        placeholder="Prefer Shipping Company"
        label="Prefer Shipping Company"
        value={shippingCompany}
        onChange={setShippingCompany}
      />
    );
  };

  const checkListLength = () => {
    if (isArray(props.checked) && !isEmpty(props.checked)) return true;
    OpenNotification({ type: "error", title: "Please select stone(s) to confirm." });
    return false;
  };

  const cancel = () => {
    props.onClose();
    if (props.clearAll) props.clearAll();
  };

  const submit = () => {
    // if (!date) return OpenNotification({ type: 'error', title: 'Please select invoice date.' });
    if (!props.detail && !company) return OpenNotification({ type: "error", title: "Please enter company name." });
    if (!terms) return OpenNotification({ type: "error", title: "Please select payment terms." });
    // if (!shippingCompany) return OpenNotification({ type: 'error', title: 'prefer shipping company.' });
    if (!checkListLength()) return;

    handleConfirmStone(
      props.checked ? props.checked.map((x) => x.id) : props.checked.map((x) => x.id),
      comment,
      date,
      company,
      (flag) => {
        if (flag) {
          props.throwNotifyCount(props.notifyCount + 1);
          props.onClose();
          if (props.clearAll) props.clearAll();
          if (props.fetch) props.fetch();
        } else {
          props.throwNotifyCount(props.notifyCount + 1);
          props.onClose();
        }
      },
      terms,
      shippingCompany,
      courierAccNo,
      ["/spanccount/offer-list"].includes(props.currentType)
    );
  };

  const gettextarea = () => (
    <TextArea
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      label="Comment"
      placeholder={isMobile() ? "Comment" : undefined}
    />
  );
  const gettextareaa = () => (
    <InputBlock
      type="text"
      label="Courier Account No"
      placeholder="Courier Account No"
      value={courierAccNo}
      onChange={(e) => setCourierAccNo(e.target.value)}
    />
  );

  const dateHandleChange = (e) => {
    setDate(e ? new Date(e._d).toISOString() : null);
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  if (!props.detail) {
    if (isMobile())
      return (
        <MobileConfirmStone
          detail={props.detail}
          handleChange={(e) => dateHandleChange(e)}
          expiryDate={date || null}
          disabledDate={disabledDate}
          data={props.checked}
          currentType={currentType}
          parent={{
            gettextareaa,
            getPreShip,
            getcompany,
            getinvoicedate,
            submit,
            cancel,
            gettextarea,
            checkCheck: checkListLength,
            getPaymentTerms,
          }}
        />
      );
  }

  return (
    <div className="confirmStonePopUp">
      {!props.detail && (
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-10 j-space-between">
          <Heading className="popupInnerTitle mr-40" title="Confirm Stone" />
          <div>
            <ExpandTable defaultColumn={defaultColumn} setColumn={setColumn} />
          </div>
        </div>
      )}
      <div
        className={`searchPopupCommonTable PopupHeight ${props.detail && "diamondetailInfoBox"} ${
          props.popupClass ?? ""
        }`}
      >
        {!props.detail && (
          <div
            className="searchResultTable tabInnerTableScroll tableHideCol hidecss"
            style={props.detail ? { height: "auto" } : {}}
          >
            <Table
              data={data}
              columns={columns}
              overrideColumns={overrideColumns}
              currentType={currentType}
              canSort={false}
              noCheckBox
              nodots
              noGrp
            />
          </div>
        )}

        <div className="mt-10 finalCalDetail confirmListOption" style={props.detail ? { padding: "5px" } : {}}>
          <div className="DiamondDetailPopup width-100 buynowDetails">
            {/* {getcompany()} */}
            {/* {getinvoicedate()} */}
            {getPaymentTerms()}
            {/* {getPreShip()} */}

            {/* <Datepicker
              handleChange={dateHandleChange}
              expiryDate={date ?? null}
              disabledDate={disabledDate}
              label="Invoice date *"
            /> */}
            <SelectOption
              selectValue={shippingCompanyList}
              placeholder="Prefer Shipping Company"
              label="Prefer Shipping Company"
              value={shippingCompany}
              onChange={setShippingCompany}
            />
            <InputBlock
              type="text"
              label="Courier Account No"
              placeholder="Courier Account No"
              value={courierAccNo}
              onChange={(e) => setCourierAccNo(e.target.value)}
            />
            {gettextarea()}
          </div>
        </div>
        <div className={`sideBarPopupButton ${props.detail && "diamonddetailPopupButton"}`}>
          <span className="commonButton" onClick={submit}>
            Confirm Stone
          </span>
          <span className="commonOutline" onClick={cancel}>
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStonePopup;
