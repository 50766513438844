import { Action } from "services/action";

import { hasKey, isArray, isEmpty, isString, logWarn, runInDevelopment } from "util/utils";

import { LOCAL_STORAGE_VAR } from "constants/Common";

const encoder = (input, encode) => {
  return encode ? btoa(unescape(encodeURIComponent(JSON.stringify(input)))) : JSON.stringify(input);
};
const decoder = (input, decode) => {
  return decode ? JSON.parse(decodeURIComponent(escape(atob(input)))) : JSON.parse(input);
};

export class Storage {
  static get prefix() {
    return `${LOCAL_STORAGE_VAR}-`;
  }

  static get action() {
    return new Action("storage");
  }

  /**
   * Store
   * @param {String} key
   * @param {*} value
   */
  static set(key, value, encode = true) {
    try {
      const output = localStorage.setItem(`${this.prefix}${key}`, encoder(value, encode)) === undefined;
      Storage.action.emit({ [key]: value });
      return output;
    } catch (error) {
      logWarn({ StorageError: error, key, value });
      return null;
    }
  }

  /**
   * Retrieve
   * @param {String} key
   */
  static get(key, decode = true) {
    try {
      const value = localStorage.getItem(`${this.prefix}${key}`);
      if (isEmpty(value)) return value;
      return decoder(value, decode);
    } catch (error) {
      logWarn({ StorageError: error, key });
      return null;
    }
  }

  /**
   * Remove values by key
   * @param {String|Array} keys String or Array of string
   */
  static delete(keys) {
    keys = isArray(keys) ? keys : isString(keys) ? [keys] : [];
    keys.forEach((key) => {
      try {
        const _key = `${this.prefix}${key}`;
        const deleted = localStorage.removeItem(_key);
        Storage.action.emit({ [key]: deleted });
        return deleted === undefined;
      } catch (error) {
        logWarn({ StorageError: error, key });
        return false;
      }
    });
  }

  static listen(key, callback) {
    const unlisten = Storage.action.listen((event) => {
      if (!hasKey(event?.detail, key)) return;
      callback({ [key]: event?.detail?.[key] });
    });
    return unlisten;
  }
}

void runInDevelopment?.(() => void (window.__Storage = Storage));
