import { trackTypeObj } from "constants/Common";
import { GET_DASHBOARD_DATA } from "constants/apiConstant";

import { CART_COUNT, NOTIFY_COUNT } from "../../constants/ActionTypes";
import UtilService from "../../services/util";

export const throwNotifyCount = (count) => {
  return (dispatch) => {
    dispatch({ type: NOTIFY_COUNT, payload: count });
  };
};
export const throwCartCount = (count) => {
  return (dispatch) => {
    dispatch({ type: CART_COUNT, payload: count });
  };
};

export const getCartCount = () => {
  return (dispatch) => {
    const obj = {
      track: true,
    };
    const api = GET_DASHBOARD_DATA.url;
    const objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        dispatch({ type: CART_COUNT, payload: data?.data?.tracks?.[trackTypeObj.CART]?.pieces });
      }
    });
  };
};
