import { Storage } from "services/storage";

import { formatDecimal, isNotEmpty, isNumber } from "./utils";

export const getStoneSummary = (stone, join = " ") =>
  [
    stone.vStnId ?? stone.stoneId,
    stone.shpNm ? Storage.get("master", false)?.SHAPE?.find((x) => x.code === stone.shpNm)?.name : stone.shpNm,
    formatDecimal(stone.crt),
    stone.isFcCol ? stone.fcColNm : stone.colNm,
    stone.clrNm,
    stone.cutNm,
    stone.polNm,
    stone.symNm,
    stone.fluNm,
  ]
    .filter(isNotEmpty)
    .join(join);

export const roundOfferDiscount = (discount) => {
  if (!isNumber(discount)) return 0;

  const signedReal = Number(discount - (discount % 1)); // -10.75 - (-10.75 % 1) = -10
  const signedDecimal = Number((discount % 1).toFixed(2)); // -0.75
  const unSignedDecimal = Math.abs(Number(signedDecimal)); // 0.75
  const adjustment = (() => {
    if (unSignedDecimal > 0.75) return signedDecimal < 0 ? -1 : 1;
    if (unSignedDecimal > 0.25) return signedDecimal < 0 ? -0.5 : 0.5;
    return 0;
  })(); // -0.5

  const output = signedReal + adjustment;
  return output;
};

export const roundBidDiscount = (discount, diffInterval, blur, minMax) => {
  if (!isNumber(discount)) return 0;
  if (blur) {
    if (discount >= -100 && discount <= 100 && discount > minMax.maxInterval) {
      return discount;
    } if (discount < minMax.minInterval) {
      return discount;
    } 
      // User Input Calculation
      const D13 = blur - discount;
      const E13 = D13 - diffInterval * Math.round(D13 / diffInterval);
      const result = discount + E13;
      return result;
    
  } 
    const signedReal = Number(discount - (discount % 1)); // -10.75 - (-10.75 % 1) = -10
    const signedDecimal = Number((discount % 1).toFixed(2)); // -0.75
    const unSignedDecimal = Math.abs(Number(signedDecimal)); // 0.75
    const adjustment = (() => {
      if (diffInterval === 0.5 && unSignedDecimal === 0)
        return signedDecimal < 0 ? -Number(unSignedDecimal) : Number(unSignedDecimal);
      if (diffInterval === unSignedDecimal || unSignedDecimal <= diffInterval)
        return signedDecimal < 0 ? -Number(diffInterval) : Number(diffInterval);
      if (unSignedDecimal > diffInterval)
        return signedDecimal < 0 ? -Number(diffInterval * 2) : Number(diffInterval * 2);
      return 0;
    })();

    const output = signedReal + adjustment;
    return output;
  
};
