import React, { useState, useMemo } from "react";
import { useTable } from "react-table";

import { Tooltip } from "antd";

import StagePopover from "components/DiamondList/StagePopover";
import { BOLD_DISPLAY } from "components/DiamondList/TableBack";

import { objectToQueryString } from "util/utils";

import { LAB_LINKS } from "constants/Common";

import CheckBox from "./CheckBox";
import Status from "./DiamondListing/Status";
import TableGrouping from "./DiamondListing/TableGrouping";

export const canSortCommon = {
  stoneId: "Stone No",
  shpNm: "Shape",
  crt: "Carat",
  colNm: "Color",
  clrNm: "Clarity",
};
export const LIMIT = 100;
// export const FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm'];
export const floatkeys = [
  "depPer",
  "ratio",
  "crt",
  "rapAvg",
  "pAng",
  "pHgt",
  "cHgt",
  "back",
  "cAng",
  "fnDis",
  "height",
  "width",
  "length",
  "grdlPer",
  "strLn",
  "newDiscount",
  "calcDiscount",
  "calcAmount",
  "newAmount",
  "calcPricePerCarat",
  "newPricePerCarat",
];
export const roundkeys = ["ctPr", "amt", "rap", "tblPer", "lwrHal"];
// const DISPLAY_TOTAL = ['ctPr', 'amt', 'rap', 'crt', 'fnCtpr', 'fnAmt', 'calcAmount'];
export const NOCHECKIDS = ["Details", "Action", "quote", "expBack", "hours", "bidPricePerCarat", "note"];

const getBoldId = (id) => {
  if (BOLD_DISPLAY.includes(id)) return true;
  return false;
};

const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass ? item.cellClass : "center",
      width: `${item.width || "100"  }px`,
      fontWeight: type === "cell" && getBoldId(item.id) ? "600" : "",
      color:
        type === "cell" && (item.id === "lbNm" || item.id === "rptNo" || item.link)
          ? "#344cbb"
          : item.id === "back"
          ? "green"
          : "",
    },
  },
];

const headerProps = (props, { column }) => getStyles(props, column, "header");
const cellProps = (props, { cell }) => getStyles(props, cell.column, "cell");
// const cellProps = (props, {}) => getStyles(props.column, 'cell');

const PairDiamondTable = (props) => {
  const [visible, setVisible] = useState(false);
  const [coupleBothChecked, setCoupleBothChecked] = useState(false);
  const { data, selectedRows, setSelectedRows, pairSummary } = props;
  const filterRows = data;

  useMemo(() => {
    if (Object.keys(visible).length > 0) {
      const {id} = visible;
      const view = ["/account/order-list", "/bid-to-buy", "/new-arrival", "/my-bid"].includes(window.location.pathname)
        ? 0
        : 1;
      window.open(`/diamond-detail/${id}${objectToQueryString({ view })}`);
    }
  }, [visible]);

  const selectCouple = (selection) => {
    const allRows = props.data;
    let selectedFilter = [];
    const alreadySelected = selectedRows.find((r) => r.pairGroup === selection.pairGroup);
    if (alreadySelected) {
      selectedFilter = selectedRows.filter((row) => {
        return row.pairGroup !== selection.pairGroup;
      });
      setSelectedRows(selectedFilter);
    } else {
      selectedFilter = allRows.filter((row) => {
        return row.pairGroup === selection.pairGroup;
      });
      setSelectedRows([...selectedRows, ...selectedFilter]);
    }
  };

  const stnIndex = props.columns.findIndex((d) => d.dataField === "amt");
  if (stnIndex !== -1)
    props.columns[stnIndex] = {
      Header: "Amt($)",
      width: 100,
      accessor: "amt",
      id: "amt",
      Cell: ({ row }) => <>{row.original.amt ? parseFloat(row.original.amt).toFixed(2) : "-"}</>,
    };

  const columns = [
    {
      Header: "",
      width: 50,
      accessor: "action",
      id: "action",
      Cell: ({ row }) => (
        <>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
            <Status status={row.original.wSts} />
            <CheckBox checked={selectedRows.find((r) => r.pairGroup === row.original.pairGroup)} />
          </div>
        </>
      ),
    },
    {
      Header: "Stock No.",
      accessor: "vStnId",
      id: "vStnId",
      width: 100,
      Cell: ({ row }) => <div className="packetNumber">{row.original.vStnId ? row.original.vStnId : "-"}</div>,
    },
    ...props.columns,
  ];

  const Table = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
      columns,
      data,
    });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, k) => (
            <tr key={k} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  {...column.getHeaderProps(headerProps)}
                  style={{ width: column.width ? `${column.width  }px` : "100px" }}
                >
                  {column.id !== "action" && (
                    <div className="coupleDiamondHeader" onClick={(e) => e.preventDefault()}>
                      <Tooltip title={column.render("Header")}>
                        <span>{column.render("Header")}</span>
                      </Tooltip>
                    </div>
                  )}
                  {column.id === "action" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: 100,
                      }}
                    >
                      <div className="coupleDiamondHeader">Status</div>
                      <StagePopover page="SEARCH" />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  {row?.id % 2 === 0 && (
                    <TableGrouping
                      row={row.original}
                      coupleStones
                      pairSummary={pairSummary.filter((s) => s.pairGroup === row.original.pairGroup)}
                    />
                  )}
                  <tr key={i} {...row.getRowProps()}>
                    {row.cells.map((cell, k) => {
                      return (
                        <td
                          key={k}
                          {...cell.getCellProps(cellProps)}
                          onClick={() => {
                            if (cell.column.link && cell.value) {
                              let field = cell.column.link.slice(
                                cell.column.link.indexOf("$") + 1,
                                cell.column.link.length
                              );
                              field = field.slice(0, field.indexOf("$"));
                              const link = cell.column.link.replace(`$${  field  }$`, row.original[field]);
                              window.open(link);
                            } else if (cell.column.id === "dna") {
                              window.open(`
                                 https://storageweweb.blob.core.windows.net/files/INVENTORYDATA/DNA.html?id=${row?.original?.vStnId}`);
                            } else if (cell.column.id === "vStnId" || cell.column.id === "shpNm") {
                              setVisible(row.original);
                            } else if (cell.column.id === "ftc" && cell.value) {
                              window.open(`/ftc/${row.original.id}`);
                            } else if (
                              (cell.column.id === "lbNm" || cell.column.id === "rptNo") &&
                              row.original.lbNm &&
                              row.original.rptNo
                            ) {
                              window.open(
                                LAB_LINKS[row.original.lbNm.toUpperCase()].replace("***", row.original.rptNo)
                              );
                            } else if (cell.column.id === "action") {
                              selectCouple(row.original);
                              setCoupleBothChecked(!coupleBothChecked);
                            } else if (!NOCHECKIDS.includes(cell.column.id)) {
                              if (props.nocheck || props.noCheckBox) return;
                              // const newchecked = handleAlter(props.checked, row.original, 'stoneId');
                              // props.handleCheck(newchecked);
                            }
                          }}
                        >
                          {cell.column.id !== "Details" && cell.column.id !== "action" && cell.column.id !== "dna" && (
                            <div>{cell.value ? cell.render("Cell") : "-"}</div>
                          )}

                          {cell.column.id !== "Details" && cell.column.id === "action" && cell.column.id !== "dna" && (
                            <div>{cell.render("Cell")}</div>
                          )}
                          {cell.column.id === "dna" && (
                            <div className="underline" style={{ width: 100 }}>
                              {cell.column.id.toUpperCase()}
                            </div>
                          )}
                          {/* {parseFloat(cell.value).toFixed(2)} */}
                          {/* {cell.column.id === 'Details' && (
                              <>
                                {true && (
                                  <img
                                    style={{ marginRight: '5px' }}
                                    src={require('../../assets/svg/camera.svg')}
                                    width="15px"
                                    alt="img"
                                    onClick={() => setDetail({ i: row.original })}
                                  />
                                )}
                                {true && (
                                  <img
                                    style={{ marginRight: '5px' }}
                                    src={require('../../assets/svg/video.svg')}
                                    width="15px"
                                    alt="img"
                                    onClick={() => setDetail({ v: row.original })}
                                  />
                                )}
                                {true && (
                                  <img
                                    src={require('../../assets/svg/certi.svg')}
                                    width="15px"
                                    alt="img"
                                    onClick={() => setDetail({ c: row.original })}
                                  />
                                )}
                              </>
                            )} */}
                        </td>
                      );
                    })}
                  </tr>
                  {/* {row?.id % 2 !== 0 && <Card style={{ width: '0px', border: 'none', outline: 'none' }} />} */}
                </>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    );
  };

  const colData = React.useMemo(() => columns, [columns]);
  return (
    <>
      <Table columns={colData} data={filterRows} />
    </>
  );
};

export default PairDiamondTable;
