import { DiamondActions } from "appRedux/reducers/diamond";
import { store } from "appRedux/store";

export class RowSelectService {
  static selectRows(type, rows, replace) {
    return store.dispatch(DiamondActions.selectRows({ payload: rows, currentType: type, replace }));
  }

  static unSelectRows(type, rows, replace) {
    return store.dispatch(DiamondActions.unSelectRows({ payload: rows, currentType: type, replace }));
  }

  static resetSelectedRows(type) {
    return store.dispatch(DiamondActions.resetSelectedRows({ currentType: type }));
  }

  static resetRowsOnPagination(rows) {
    return store.dispatch(DiamondActions.resetRowsOnPagination({ payload: rows }));
  }
}
