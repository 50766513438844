import React from "react";

import "./ExpandButton.less";

function ExpanTable(props) {
  const [more, setMore] = React.useState(false);

  const columnListRef = React.useRef(props.defaultColumn);
  const setColumnRef = React.useRef(props.setColumn);

  React.useEffect(() => {
    columnListRef.current = props.defaultColumn;
  }, [props.defaultColumn]);

  React.useEffect(() => {
    setColumnRef.current = props.setColumn;
  }, [props.setColumn]);

  const onClick = React.useCallback(() => {
    if (!columnListRef.current.length) return;
    const table = document.querySelector(".tableHideCol");
    if (table) {
      setMore((more) => !more);
      if (!table?.classList?.contains?.("hidecss")) {
        void setColumnRef?.current?.(columnListRef.current);
        void table?.classList?.toggle?.("hidecss");
        return;
      }
      const child = table?.childNodes?.[0]?.childNodes;
      const thead = child?.[0]?.childNodes?.[0]?.childNodes;
      const width = document.querySelector(".searchPopupCommonTable")?.offsetWidth;

      let w = 0;
      let it = 0;
      for (it = 0; w < width; it++) {
        w += thead?.[it] ? thead?.[it]?.offsetWidth ?? 0 : 0;
      }

      void setColumnRef?.current?.(
        table?.classList?.contains?.("hidecss") ? columnListRef.current?.slice?.(0, it - 2) : columnListRef.current
      );
      void table?.classList?.toggle?.("hidecss");
    }
  }, []);

  React.useEffect(() => {
    setTimeout(onClick, 0);
  }, [onClick]);

  return (
    <button className="expandTableButton commonButton" onClick={onClick}>
      {more ? "More" : "Less"}
    </button>
  );
}

export default React.memo(ExpanTable);
