import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Badge from "@material-ui/core/Badge";
import { Avatar, Tooltip } from "antd";
import _ from "lodash";
import { find } from "lodash";

import { BidService } from "services/BidService";

import IntlMessage from "util/IntlMessage";

import { getPath } from "./DiamondList/DiamondListFunctions";
import { GuestUserPopup } from "./GuestUserPopup";
import Logo from "./Header/Logo";
import { NoKycPop } from "./NoKycPop";
import Sidebar from "./Sidebar";
// import CountBox from './common/CountBox';
import CovidStrip from "./common/Header/CovidStrip";
import HeaderStrip from "./common/Header/HeaderStrip";
import NotificationDropdown from "./common/Header/NotificationDropdown";
import HeaderSearch from "./common/Header/Search";
import HeaderAccountList from "./common/HeaderAccountList";
import HeaderRightSidebar from "./common/HeaderRightSidebar";
import SignOutIcon from "./common/SignOut";
import UpDownBox from "./common/UpDownBox";
// import UserProfileDefault from './common/UserProfileDefault';

import {
  setLoader,
  covidStrip,
  setNewArrivalFlag,
  setBidFlag,
  setNewArrivalTotalCount,
  setBidTotalCount,
} from "../appRedux/actions/Common";
import { salesPersonAction, storeUser } from "../appRedux/actions/DashActions";
import { throwNotifyCount, throwCartCount } from "../appRedux/actions/notifyAction";
import cartWhiteBLankSVG from "../assets/svg/Header/cart_blank_icon.svg";
import cartWhiteSVG from "../assets/svg/Header/cart_icon.svg";
import notificationhWhiteSVG from "../assets/svg/Header/notification-white.svg";
// import searchWhiteSVG from '../assets/svg/Header/search-white.svg';
import { trackTypeObj, commonLogout, LOCAL_STORAGE_VAR, KYC_STATUS, PAGES } from "../constants/Common";
import { GET_BANNERS, GET_DASHBOARD_DATA, GET_EVENTS, GET_NOTIFICATIONS, MARK_AS_READ } from "../constants/apiConstant";
import { getSearchList } from "../containers/APIFunctions";
import UtilService, { getCurrentTime } from "../services/util";

const LISTINGPAGES = PAGES;

const BID_TYPE = {
  NEW_ARRIVAL: 1,
  BID_TO_BUY: 2,
};

class Header extends Component {
  // new Code
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isHovered1: false,
      isHovered2: false,
      covid: true,
      search: false,
      details: {},
      topBanner: [],
      time: [],
      HeaderStripList:
        // localStorage.getItem(`${LOCAL_STORAGE_VAR}-HeaderStripList`) === true ||
        // localStorage.getItem(`${LOCAL_STORAGE_VAR}-HeaderStripList`) === 'true'
        //   ?
        true,
      // : false
      counts: {},
      rates: {},
      notifications: 0,
      event: [],
      searchList: [],
      isGuest: UtilService.getLocalStorageItem(
        window.btoa(unescape(encodeURIComponent(JSON.stringify(`${LOCAL_STORAGE_VAR}-guest`))))
      ),
      showGuest: false,
      noKyc: false,
      isKyc: false,
      showOnlySearch: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.deleteReminder.count !== prevProps.deleteReminder.count) {
      this.getSidebarCounts();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (getPath() !== LISTINGPAGES.NEWARR && getPath() !== LISTINGPAGES.BID) this.setBid();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.authUser && nextProps.authUser.account) {
      if (!nextProps.authUser.account.isKycUploaded) {
        this.setState({ isKyc: !nextProps.authUser.account.isKycUploaded });
      } else {
        if (nextProps.authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }
  componentDidMount() {
    this.fetchdata();
    this.setTimers();
    this.getSidebarCounts();
    this.getEvents();
    this.getNotifications();
    this.props.covidStrip(this.state.covid);
    this.setBid();
    if (!["/dashboard", "/diamond-search"].includes(window.location.pathname)) {
      getSearchList((list) => this.setState({ searchList: list }));
    }
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

    if (authUser && authUser.account) {
      if (!authUser.account.isKycUploaded) {
        this.setState({ isKyc: !authUser.account.isKycUploaded });
      } else {
        if (authUser.account.isApproved !== KYC_STATUS.APPROVED) {
          this.setState({ isKyc: true });
        }
      }
    }
  }

  closeStrip = () => {
    this.props.covidStrip(!this.props.commonData.strip);
    this.setState({
      covid: false,
    });
  };
  searchOption = () => {
    if (!this.state.isGuest) {
      if (this.state.isKyc) {
        this.setState({
          noKyc: true,
        });
      } else {
        this.setState({
          search: !this.state.search,
        });
      }
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };

  cartOption = () => {
    if (!this.state.isGuest) {
      if (this.state.isKyc) {
        this.setState({
          noKyc: true,
        });
      }
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  setTimers = async () => {
    const serverTime = await getCurrentTime();
    this.setState({ time: serverTime });
  };
  fetchdata = () => {
    this.setState({ load: true }, () => {
      const obj = {
        ...GET_BANNERS,
        request: {
          filter: { type: ["9"], isActive: true },
        },
      };
      UtilService.callApi(obj, (err, data) => {
        this.setState({ load: false });
        if (data && data.code === "OK") {
          if (data.data && data.data.list && data.data.list.length !== 0) {
            const bannerTop = find(data.data.list, {
              type: "9",
            });
            this.setState({ topBanner: [{ ...bannerTop }] });
          }
        }
      });
    });
  };

  showGuest = () => {
    this.setState({ showGuest: true });
  };
  // Api is just called to get flag
  setBid = async () => {
    let nextBid = {};
    nextBid = await BidService.getConfig({ dynamicBidType: BID_TYPE.BID_TO_BUY });

    if (nextBid?.dynamicBidType === 2 && JSON.stringify(nextBid) !== "[]") {
      this.props.setBidFlag(true);
      //this.props.setBidTotalCount(2);

      if (nextBid?.totalBidDiamondCountBywebStatus !== undefined) {
        this.setState({
          showOnlySearch: nextBid?.showOnlySearch,
        });
        this.props.setBidTotalCount(nextBid?.totalBidDiamondCountBywebStatus);
      }
    } else {
      this.props.setBidFlag(false);
    }

    let nextArr = {};
    nextArr = await BidService.getConfig({ dynamicBidType: BID_TYPE.NEW_ARRIVAL });

    if (nextArr?.dynamicBidType === 1 && JSON.stringify(nextArr) !== "[]") {
      this.props.setNewArrivalFlag(true);
      //  this.props.setNewArrivalTotalCount(2);

      if (nextArr?.totalNewArrDiamondCountBywebStatus !== undefined) {
        this.setState({
          showOnlySearch: nextArr?.showOnlySearch,
        });
        this.props.setNewArrivalTotalCount(nextArr?.totalNewArrDiamondCountBywebStatus);
      }
    } else {
      this.props.setNewArrivalFlag(false);
    }

    if (nextArr === undefined && nextBid === undefined) {
      this.setState({
        showOnlySearch: true,
      });
    }
  };
  handleHover = () => {
    this.setState(
      {
        isHovered: !this.state.isHovered,
        isHovered1: false,
        isHovered2: false,
      },
      () => {
        this.getSidebarCounts();
      }
    );
  };
  handleHover1 = () => {
    this.setState({
      isHovered1: !this.state.isHovered1,
      isHovered: false,
      isHovered2: false,
    });
  };
  handleHover2 = () => {
    this.setState({
      isHovered2: !this.state.isHovered2,
      isHovered: false,
      isHovered1: false,
    });
  };
  handleOut = () => {
    this.setState({
      isHovered: false,
    });
  };
  handleOut1 = () => {
    this.setState({
      isHovered1: false,
    });
  };
  handleOut2 = () => {
    this.setState({
      isHovered2: false,
    });
    // this.MarkAsRead();
  };

  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.commonData.strip);
    this.setState(
      {
        HeaderStripList: !this.state.HeaderStripList,
      },
      () => {
        localStorage.setItem(`${LOCAL_STORAGE_VAR}-HeaderStripList`, this.state.HeaderStripList);
      }
    );
  };
  getSidebarCounts = () => {
    const self = this;
    const obj = {
      savedSearch: true,
      recentSearch: true,
      track: true,
      orderData: true,
      dashboardCount: true,
      shippingCount: true,
      seller: true,
      account: true,
    };
    const api = GET_DASHBOARD_DATA.url;
    const objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };

    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        self.props.throwCartCount(data?.data?.tracks?.[trackTypeObj.CART]?.pieces);
        self.setState({
          details: data.data.tracks,
          rates: data.data.rates,
          counts: _.omit(data.data, "tracks"),
        });
        const us = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
        if (data.data.account) {
          us.account = data.data.account;
          UtilService.setLocalStorageItem(us, `${LOCAL_STORAGE_VAR}-user`);
        }
        self.props.storeUser(us);

        if (data.data && data.data.seller) {
          self.props.salesPersonAction(data.data.seller);
        }
      }
    });
  };

  getEvents = () => {
    const self = this;
    const obj = {
      ...GET_EVENTS,
      request: {
        page: 1,
        limit: 10,
        type: 6,
      },
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        if (data.data) {
          const eventData = data.data.list[0];

          self.setState({
            event: eventData,
          });
        }
      }
    });
  };

  getNotifications = () => {
    const self = this;
    const obj = {
      ...GET_NOTIFICATIONS,
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        if (data.data) {
          const total = data.data.unReadCount;

          self.setState({
            notifications: total,
          });
          self.props.throwNotifyCount(total);
        }
      }
    });
  };

  MarkAsRead = () => {
    const self = this;
    const obj = {
      ...MARK_AS_READ,
      // request: {
      //   id: id,
      // },
    };
    UtilService.callApi(obj, (err, data) => {
      if (data && data.code === "OK") {
        if (data.data) {
          //setData(data.data.list);
          self.setState({
            notifications: 0,
          });
        }
      }
    });
  };
  getUpdatedCount = (countRead) => {
    this.setState({ notifications: countRead });
  };
  showKyc = () => {
    this.setState({ noKyc: true });
  };
  render() {
    // new Code
    const btnClass = this.state.isHovered ? "active" : "";
    const btnClass1 = this.state.isHovered1 ? "active" : "";
    const btnClass2 = this.state.isHovered2 ? "active" : "";
    // const name = JSON.parse(localStorage.getItem("fs-user")).name;
    const { name } = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const HeaderLinks = [
      {
        link: "/account/cart",
        title: <IntlMessage id="app.cart" />,
        details: this?.state?.details?.[trackTypeObj?.CART],
        noCount: true,
        isShow: USER_PERMIT?.CART?.view,
      },
      {
        link: "/account/watchlist",
        title: <IntlMessage id="app.watchlist" />,
        details: this?.state?.details?.[trackTypeObj?.WATCHLIST],
        noCount: true,
        isShow: USER_PERMIT?.WATCHLIST?.view,
      },
      {
        link: "/account/view-diamonds",
        title: <IntlMessage id="app.office-list" />,
        details: this?.state?.details?.[trackTypeObj?.REQUEST],
        noCount: true,
        isShow: USER_PERMIT?.OFFICE?.view,
      },
      {
        link: "/account/order-list",
        title: <IntlMessage id="app.orderHistory" />,
        // details:
        //   this.state.counts.orderData && this.state.counts.orderData.order
        //     ? this.state.counts.orderData.order
        //     : {},
        details: this?.state?.counts?.orderData?.length !== 0 ? this?.state?.counts?.orderData : [],
        noCount: true,
        isShow: USER_PERMIT?.CONFIRM_STONE?.view,
        orderYes: true,
      },
      {
        link: "/account/offer-list",
        title: <IntlMessage id="app.Offer" />,
        details: this?.state?.details?.[trackTypeObj?.QUOTE],
        noCount: true,
        isShow: USER_PERMIT?.QUOTE?.view,
      },
      {
        link: "/account/notes",
        title: <IntlMessage id="app.Notes" />,
        details: this?.state?.details?.[trackTypeObj?.COMMENT],
        noCount: true,
        isShow: USER_PERMIT?.NOTES?.view,
      },
      // {
      //   link: "/my-bid",
      //   title: <IntlMessage id="app.myBid" />,
      //   basicDetailnull: true,
      // },
      {
        link: "/account/saved-search",
        title: <IntlMessage id="app.saved-search" />,
        basicDetailnull: true,
        noCount: false,
        isShow: USER_PERMIT?.SAVE_SEARCH?.view,
      },
      {
        link: "/account/my-demand",
        title: <IntlMessage id="app.Demand" />,
        basicDetailnull: true,
        noCount: false,
        isShow: USER_PERMIT?.DEMAND?.view,
      },

      {
        link: "/my-account/summary",
        title: <IntlMessage id="app.Profile" />,
        basicDetailnull: true,
        isShow: true,

        // noKyc: true,
        // kUrl: '/my-account/kyc-detail'
      },

      {
        link: "",
        title: <IntlMessage id="app.SignOut" />,
        onClick: () => commonLogout(),
        basicDetailnull: true,
        isShow: true,
        logout: true,
      },
    ];
    const authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;
    const {
      showBid,
      showArr,
      // totalBidDiamondCountBywebStatus, totalNewArrDiamondCountBywebStatus
    } = this.props?.commonData;

    return (
      <>
        <div className={`HeaderSticky ${this.props.className}`}>
          {this.state.HeaderStripList && (
            <>
              <HeaderStrip
                time={this.state.time.iso}
                main={this?.state?.topBanner?.length !== 0 ? this.state.topBanner : []}
                version2={this.props.version2}
                onClick={this.HeaderStripCick}
              />
              {/* <Event main={this?.state?.topBanner?.length !== 0 ? this.state.topBanner : []} /> */}
            </>
          )}
          {this.state.HeaderStripList && <UpDownBox onClick={this.HeaderStripCick} />}
          {!this.state.HeaderStripList && <UpDownBox downArrow onClick={this.HeaderStripCick} />}
          <div
            className={`headerTopMenu ${this.state.HeaderStripList && "iconActive"} ${!this.state.HeaderStripList && "active"
              }`}
          >
            <div
              className="headerWrapper"
              style={{
                userSelect: "none",
              }}
            >
              <div className="headerLeft">
                <div className="headerLogo">
                  <Link to="/dashboard" className="">
                    <Logo />
                  </Link>
                </div>
                <div className="headerMenuOption">
                  <Sidebar flagArr={[{ 1: showArr }, { 2: showBid }]} showOnlySearch={this.state.showOnlySearch} />
                </div>
              </div>
              <div className="headerRightSide">
                <div className={`headerRightBlock ${btnClass || btnClass1 || btnClass2}`}>
                  {/* <Tooltip arrow={true} title={<IntlMessage id="app.Search" />} placement="bottom" arrowSize="true">
                    <div className="headerIcon" onClick={this.searchOption}>
                      <img src={searchWhiteSVG} alt="icon" />
                    </div>
                  </Tooltip> */}
                  <HeaderSearch
                    className={`${this.state.search && "active"} ${!this.state.covid && "covidStiphide"} ${!this.state.HeaderStripList && "headerStriphide"
                      }`}
                    onClick={this.searchOption}
                    searchList={this.state.searchList}
                  />
                  {USER_PERMIT?.CART?.view && (
                    <div className="headerRightOption headerIcon" onClick={this.cartOption} id="step2">
                      <Tooltip arrow={true} title={<IntlMessage id="app.Cart" />} placement="bottom" arrowSize="true">
                        {!this.state.isGuest ? (
                          <Link to="/account/cart">
                            {this.props.cartCount ? (
                              <>
                                <img src={cartWhiteSVG} alt="" />
                                <Badge className="countBoxNew" badgeContent={this.props.cartCount}></Badge>
                              </>
                            ) : (
                              <>
                                <img src={cartWhiteBLankSVG} alt="" />
                              </>
                            )}
                          </Link>
                        ) : (
                          <img src={cartWhiteBLankSVG} alt="" />
                        )}
                      </Tooltip>
                    </div>
                  )}
                  {/* {USER_PERMIT && USER_PERMIT.NOTIFICATIONS && USER_PERMIT.NOTIFICATIONS.view && ( */}
                  {USER_PERMIT?.NOTIFICATIONS?.view && (
                    <div
                      className="headerRightOption"
                      id="step3"
                    >
                      <Link to="/notifications">
                        <Tooltip arrow={true} title={<IntlMessage id="app.Notification" />} placement="bottom">
                          <div className="headerIcon">
                            <img src={notificationhWhiteSVG} alt="icon" />
                            {this.props.notifyCount > 0 && (
                              <Badge className="countBoxNew" badgeContent={this.props.notifyCount}></Badge>
                            )}
                          </div>
                        </Tooltip>

                        {/* <HeaderRightSidebar activeClass={btnClass2} handleOut={this.handleOut2}>
                          {this.state.isHovered2 && (
                            <NotificationDropdown
                              throwNotifyCount={this.props.throwNotifyCount}
                              handleOut={this.handleOut2}
                              getUpdatedCount={this.getUpdatedCount}
                            />
                          )}
                        </HeaderRightSidebar> */}
                      </Link>
                    </div>
                  )}

                  <div
                    className="headerRightOption"
                  // onMouseEnter={() => this.handleHover()}
                  //onClick={() => this.handleHover()}
                  >
                    <div className="dropdownUserIcon" id="step4">
                      {/* <UserProfileDefault /> */}
                      <span className="userProfileContent">
                        <Link to="/my-account/summary">
                          <Tooltip arrow={true} title={<IntlMessage id="app.myAccount" />} placement="bottom">
                            {name && (
                              <Avatar
                                style={{
                                  backgroundColor: "#8C7459",
                                  fontFamily: "CronosPro",
                                  fontSize: 20,
                                  letterSpacing: "2px",
                                  // userSelect: 'none',
                                }}
                                size="large"
                              >
                                {(name.trim().split(/\s+/)).map((n) => (n.charAt(0).toUpperCase())).join("")}
                              </Avatar>
                            )}
                          </Tooltip>
                        </Link>
                      </span>
                    </div>

                    <HeaderRightSidebar
                      activeClass={`${btnClass} ${!this.state.HeaderStripList && "headerStriphide"}`}
                      handleOut={this.handleOut}
                    >
                      <div className="userDetailList headerSidebarList">
                        <ul>
                          {HeaderLinks.map((x, i) => {
                            return (
                              x.isShow && (
                                <HeaderAccountList
                                  orderThi={x.orderYes}
                                  noKyc={x.noKyc}
                                  noKycUrl={x.kUrl}
                                  arrownone
                                  key={i}
                                  isKyc={this.state.isKyc}
                                  showKyc={this.showKyc}
                                  isGuest={this.state.isGuest}
                                  showGuest={this.showGuest}
                                  {...this.props}
                                  {...x}
                                />
                              )
                            );
                          })}
                        </ul>
                      </div>
                    </HeaderRightSidebar>
                  </div>
                  <div className="headerRightOption">
                    <SignOutIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.covid && <CovidStrip onClick={this.closeStrip} />}
          {this.state.showGuest && (
            <GuestUserPopup
              visible={this.state.showGuest}
              register={() => {
                window.open("/signup", "_self");
                window.localStorage.clear();
                window.sessionStorage.clear();
              }}
              handleCancel={() => {
                this.setState({ showGuest: false });
              }}
            />
          )}
          {this.state.noKyc && (
            <NoKycPop
              status={authUser.isApproved}
              visible={this.state.noKyc}
              handleCancel={() => {
                this.setState({ noKyc: false });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

// export default Header;
function mapStateToProps(props) {
  return {
    notifyCount: props.notify?.notifyCount,
    cartCount: props.notify?.cartCount,
    commonData: props.commonData,
    deleteReminder: props.deleteReminder,
    authUser: props?.auth?.authUser ?? {},
  };
}

export default connect(mapStateToProps, {
  throwNotifyCount,
  throwCartCount,
  setLoader,
  covidStrip,
  salesPersonAction,
  storeUser,
  setNewArrivalFlag,
  setBidFlag,
  setNewArrivalTotalCount,
  setBidTotalCount,
})(withRouter(Header));
