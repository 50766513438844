import React from "react";

import MainFilterTop from "./FilterItem";

const FilterTop = (props) => {
  return (
    <div className="filterMainDropdown" onClick={() => props.handleFilterChange(props.id)}>
      <MainFilterTop
        handleRemoveFilter={props.handleRemoveFilter}
        id={props.id}
        activeClass={props.activeClass}
        title={props.filterTitle}
        eyeImage={props.image}
      />
    </div>
  );
};
export default FilterTop;
