import React from "react";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";

//import NotificationSetting from './NotificationSetting';
import DiamondList from "components/DiamondList/index";

import { Storage } from "services/storage";

import IntlMessage from "util/IntlMessage";
import { history } from "util/history";
import { withMobile } from "util/hocs";

import MyDemand from "../MyDemand/";
import Offer from "../Offer";
import SavedSearch from "../SavedSearch";
import SelectStone from "../common/DiamondListing/SelectStone";

import "components/common/Tab/tab.less";

class Account extends React.PureComponent {
  state = { sum: {} };

  componentDidMount() {
    if (Storage.get("guest", false)) history.push("/dashboard");
    this.tabs = this.getTabs();
  }

  handleSumChange = (sum, currentType) => this.setState({ sum, currentType });

  getTabs = () => {
    const USER_PERMIT = Storage.get("userPermissions");

    return [
      {
        show: USER_PERMIT?.CART?.view,
        path: "/cart",
        title: <IntlMessage id="app.cart" />,
        content: <DiamondList onSumChange={this.handleSumChange} />,
      },
      {
        show: USER_PERMIT?.WATCHLIST?.view,
        path: "/watchlist",
        title: <IntlMessage id="app.watchlist" />,
        content: <DiamondList onSumChange={this.handleSumChange} />,
      },
      {
        show: USER_PERMIT?.OFFICE?.view,
        path: "/view-diamonds",
        title: <IntlMessage id="app.office-list" />,
        content: <DiamondList onSumChange={this.handleSumChange} />,
      },
      {
        show: USER_PERMIT?.CONFIRM_STONE?.view,
        path: "/order-list",
        title: <IntlMessage id="app.orderHistory" />,
        content: <DiamondList onSumChange={this.handleSumChange} detailRemoveFromOrderList={true} />,
      },
      {
        show: USER_PERMIT?.QUOTE?.view,
        path: "/offer-list",
        title: <IntlMessage id="app.Offer" />,
        content: (
          <Offer
            {...this.props}
            isUpdate={USER_PERMIT?.QUOTE?.update}
            isDelete={USER_PERMIT?.QUOTE?.delete}
            onSumChange={this.handleSumChange}
          />
        ),
      },
      {
        show: USER_PERMIT?.NOTES?.view,
        path: "/notes",
        title: <IntlMessage id="app.Comment" />,
        content: <DiamondList onSumChange={this.handleSumChange} />,
      },
      {
        show: USER_PERMIT?.SAVE_SEARCH?.view,
        path: "/saved-search",
        title: <IntlMessage id="app.saved-search" />,
        content: (
          <SavedSearch isUpdate={USER_PERMIT?.SAVE_SEARCH?.update} isDelete={USER_PERMIT?.SAVE_SEARCH?.delete} />
        ),
      },
      {
        show: USER_PERMIT?.DEMAND?.view,
        path: "/my-demand",
        title: <IntlMessage id="app.Demand" />,
        content: <MyDemand isUpdate={USER_PERMIT?.DEMAND?.update} isDelete={USER_PERMIT?.DEMAND?.delete} />,
      },
      // {
      //   show: USER_PERMIT?.NOTIFICATIONS?.view,
      //   path: '/notification',
      //   title: <IntlMessage id="app.Notification" />,
      //   content: <NotificationSetting />,
      // },
    ].filter((tab) => tab.show);
  };

  tabs = this.getTabs();

  render() {
    const { location } = this.props;
    const isAccount = location.pathname.split("/")?.[1] === "account";

    return (
      <div>
        <div className="accountWrapper">
          <div className="accountTopBlock">
            <div className="accountUser">
              <h2 className="accountTitle">
                <IntlMessage id="app.YourAccount" />
              </h2>
            </div>
            {isAccount && !this.props.isMobile && (
              <SelectStone
                currentType={location.pathname}
                clearStyle={{ position: "inherit" }}
                blockStyle={{ position: "initial", borderBottom: "none", paddingRight: "0px", marginTop: "5px" }}
                profilListing={isAccount ? "profileStonSelect" : null}
                sum={this.state.sum}
              />
            )}
          </div>
          <div className="customerSubTab">
            <ul className="accountTabBox">
              {this.tabs.map((tab) => (
                <li
                  key={tab?.path}
                  className={location.pathname === "/account" + tab.path ? "react-tabs__tab--selected" : null}
                >
                  <Link to={"/account" + tab.path}>{tab.title}</Link>
                </li>
              ))}
            </ul>
            {this.tabs.map((tab) => (
              <React.Fragment key={tab?.path}>
                {location.pathname === "/account" + tab.path && tab.content}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withMobile, withRouter)(Account);
