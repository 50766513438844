import React, { Component } from "react";
// import { DndProvider, useDrag, useDrop } from 'react-dnd';
// import HTML5Backend from 'react-dnd-html5-backend';
// import { useTable, useRowSelect } from 'react-table';
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { compose } from "redux";

import { Card, Checkbox as CheckboxAntd } from "antd";
// import update from 'immutability-helper';
import cloneDeep from "lodash/cloneDeep";

import IntlMessage from "util/IntlMessage";
import { withMobile } from "util/hocs";

// import sortingLightSVG from '../../../assets/svg/DiamondList/sorting-light.svg';
import { LOCAL_STORAGE_VAR } from "../../../constants/Common";
import { USER_SETTING_LIST, USER_SETTING_UPSERT } from "../../../constants/apiConstant";
// import { sortingData } from '../../../constants/sorting';
import UtilService from "../../../services/util";
import CheckBox from "../../common/CheckBox";
import OpenNotification from "../../common/CommonButton/OpenNotification";
// import DiamondListingHead from '../../common/DiamondListing/DiamondListingHead';
// import SortingFilterDropdown from '../../common/DiamondListing/SortingFilterDropdown';
import Heading from "../../common/Heading";
// import _ from 'lodash';
const setting1 = [
  "Report No",
  "DNA",
  "Details",
  "Remark",
  "Stock No",
  "Loc",
  "Shape",
  "Carat",
  "Col",
  "Clarity",
  "Shade",
  "Rap($)",
  "Rap Value",
  "Disc%",
  "Price/Ct.",
  "Amt($)",
];
const setting2 = ["Stock No", "Loc", "Disc%", "Price/Ct.", "Amt($)"];
const setting3 = ["Stock No", "Report No", "Stock No", "Loc", "Disc%", "Price/Ct.", "Amt($)"];
const setting4 = ["Stock No", "Loc", "Shape", "Carat", "Col", "Clarity", "Shade"];

const gridStyle = {
  width: "100%",
  textAlign: "center",
  color: "#000",
  fontWeight: 800,
  background: "#eee",
  border: "0px",
  outline: "0px",
  fontFamily: "CronosPro",
  letterSpacing: "1px",
  fontSize: "17px",
  padding: 12,
};
const cardHeaderStyle = {
  color: "#000",
  fontWeight: 800,
  background: "#eee",
  border: "0px",
  outline: "0px",
  fontFamily: "CronosPro",
  letterSpacing: "1px",
  fontSize: "17px",
  textTransform: "uppercase",
};

// const ColorMenuConst = <SortingFilterDropdown OtherTable data={sortingData.colunmSetting} FilterOption={false} />;
let gridHeight = 0;
export function setGridHeightFn() {
  const allContainers = {};
  let totalHeight = window.innerHeight - 80;
  allContainers.headerHeight = document.getElementsByClassName("headerTopMenu")[0];
  allContainers.pageHeadHeight = document.getElementsByClassName("accountTopBlock")[0];
  allContainers.pinHeight = document.getElementsByClassName("accountTabBox")[0];
  allContainers.actionBtnHeight = document.getElementsByClassName("userManageTop")[0];
  allContainers.accountSubTab = document.getElementsByClassName("accountSubTab")[0];
  allContainers.diamondListingAction = document.getElementsByClassName("diamondListingAction")[0];

  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }

    return true;
  });
  return totalHeight;
}
class DiamondColumnListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: false,
      gridHeight: 0,
      checked: false,
      settings: [],
      allChecked: null,
      showGroups: false,
      groupSettings: [],
      setting1: [],
      setting2: [],
      setting3: [],
      setting4: [],
      defaultGroups: [],
      err: null,
    };
  }

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings = () => {
    const self = this;
    const obj = {
      // url: "/admin/user-setting/paginate",
      // method: "post",
      ...USER_SETTING_LIST,
    };

    UtilService.callApi(obj, (err, data) => {
      if (err && err.data) {
        self.setState({ err: err });
      } else if (data && data.code === "OK") {
        if (data.data.setting) {
          self.setState({ settings: data.data.setting, groupSettings: data.data.setting });
        }
      }
    });
  };

  handleInputChange(key, value, index) {
    const settings = this.state.settings;
    settings[index][key] = value;
    this.setState({ settings: settings });
  }

  handleCheckChange = (key, checked) => {
    const { settings } = this.state;
    const newSetting = settings;
    newSetting.forEach((s) => {
      if (s.field === key) {
        s.isActive = checked;
      }
    });
    this.setState({ settings: newSetting, allChecked: null });
  };

  handleAllCheckChange = (checked) => {
    const newSetting = cloneDeep(this.state.settings);
    newSetting.forEach((s) => {
      s.isActive = checked;
    });
    this.setState({ allChecked: checked, settings: newSetting });
  };

  handleUpdateSetting = () => {
    const self = this;
    const obj = {
      setting: this.state.settings,
    };

    //const api = '/admin/user-setting/upsert';
    const objData = {
      // method: "post",
      // url: api,
      ...USER_SETTING_UPSERT,
      request: obj,
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(self.state.settings));
        return OpenNotification({
          type: "success",
          title: "Your diamond sequence is updated successfully.",
        });
      }
    });
  };

  setNewSettings = (newSettings) => {
    this.setState({ settings: newSettings });
  };

  render() {
    let settings = this.state.settings;
    settings = settings.filter((item, i) => {
      return item.field !== "dna" && { ...item, sequence: i + 1 };
    });

    const self = this;
    gridHeight = setGridHeightFn();
    if (self.state.gridHeight !== gridHeight) self.setState({ gridHeight });
    const columns = [
      // {
      //   Header: 'Sr. Number',
      //   width: '50px',
      //   accessor: 'srnu',
      //   totalDropdown: true,
      //   Cell: ({ row }) => <div>{row.index + 1}</div>,
      // },
      {
        Header: "Name",
        accessor: "title",
        shortingSelect: true,
        totalDropdown: true,
        shortingCount: "2",
      },
      {
        Header: "Sequence",
        accessor: "sequence",
      },
    ];
    // Define a sortable table row component
    const SortableTableRow = SortableElement(({ rowData, onCheckboxChange }) => {
      const [isActive, setIsActive] = React.useState(rowData.isActive);
      React.useEffect(() => {
        setIsActive(rowData.isActive);
      }, [rowData.isActive]);
      const handleCheckboxChange = () => {
        setIsActive(!isActive);
        onCheckboxChange(rowData.field, !isActive);
      };

      return (
        <tr>
          <td>
            <div className="selectActionIconWrapper">
              <CheckBox checked={isActive} onChange={handleCheckboxChange} />
            </div>
          </td>
          {/* <td>{sr + 1}</td> */}
          <td>{rowData.title}</td>
          <td>{rowData.sequence}</td>
        </tr>
      );
    });

    // Define a sortable table component
    const SortableTable = SortableContainer(({ columns, data, onCheckboxChange }) => (
      <table>
        <thead>
          <tr>
            <th>
              <div className="selectActionIconWrapper">
                <CheckBox onChange={(e) => onCheckboxChange("all", e.target.checked)} />
              </div>
            </th>
            {columns.map((column) => (
              <th key={column.accessor}>{column.Header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((rowData, index) => (
            <SortableTableRow key={rowData.field} index={index} rowData={rowData} onCheckboxChange={onCheckboxChange} />
          ))}
        </tbody>
      </table>
    ));

    // Define the main table component
    class Table extends Component {
      constructor(props) {
        super(props);
        this.state = {
          tableData: props.data,
          allChecked: false,
        };
      }

      onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(
          ({ tableData }) => ({
            tableData: arrayMove(tableData, oldIndex, newIndex),
          }),
          () => {
            // Update the settings state with the new order of rows
            const newSettings = this.state.tableData.map((rowData, i) => {
              return {
                ...rowData,
                sequence: i + 1,
              };
            });
            this.props.setNewSettings(newSettings);
            // this.setState({ settings: newSettings });
            // this.props.setState({ settings: newSettings });
          }
        );
      };

      handleCheckboxChange = (field, isChecked) => {
        if (field === "all") {
          this.setState(({ tableData }) => ({
            allChecked: isChecked,
            tableData: tableData.map((rowData) => {
              rowData.isActive = isChecked;
              return rowData;
            }),
          }));
        } else {
          this.setState(({ tableData }) => ({
            tableData: tableData.map((rowData) => {
              if (rowData.field === field) {
                rowData.isActive = isChecked;
              }
              return rowData;
            }),
          }));
        }
      };

      render() {
        const { columns } = this.props;
        const { tableData } = this.state;

        return (
          <SortableTable
            columns={columns}
            data={tableData}
            onSortEnd={this.onSortEnd}
            onCheckboxChange={this.handleCheckboxChange}
          />
        );
      }
    }

    const { isMobile } = this.props;

    const handleGroupCheckBox = async (e, settingType) => {
      let res = [];
      const checked = e.target.checked;
      const { groupSettings } = this.state;
      if (checked && groupSettings && groupSettings.length) {
        if (settingType === "setting1") {
          res = await groupSettings.filter((s) => {
            return setting1.find((item) => {
              return item === s.title;
            });
          });
        }
        if (settingType === "setting2") {
          res = await groupSettings.filter((s) => {
            return setting2.find((item) => {
              return item === s.title;
            });
          });
        }
        if (settingType === "setting3") {
          res = await groupSettings.filter((s) => {
            return setting3.find((item) => {
              return item === s.title;
            });
          });
        }
        if (settingType === "setting4") {
          res = await groupSettings.filter((s) => {
            return setting4.find((item) => {
              return item === s.title;
            });
          });
        }
      }
      if (res && res.length) {
        res = await res.map((i) => {
          i.isActive = checked;
          return i;
        });
        this.setState({ settings: res });
      }
    };

    return (
      <>
        {this.state.showGroups && (
          <>
            <div className="configurationInnerSubTb d-flex justify-content-between align-items-start ">
              <Card
                headStyle={cardHeaderStyle}
                title="Columns Group 1"
                extra={<CheckboxAntd onClick={(e) => handleGroupCheckBox(e, "setting1")} style={{ marginLeft: 20 }} />}
                style={{
                  width: "100%",
                  margin: "0px 20px",
                }}
              >
                {setting1.map((s) => {
                  return (
                    <>
                      <Card.Grid style={gridStyle}>{s}</Card.Grid>
                    </>
                  );
                })}
              </Card>
              <Card
                headStyle={cardHeaderStyle}
                title="Columns Group 2"
                extra={<CheckboxAntd onClick={(e) => handleGroupCheckBox(e, "setting1")} style={{ marginLeft: 20 }} />}
                style={{
                  width: "100%",
                  margin: "0px 20px",
                }}
              >
                {setting2.map((s) => {
                  return (
                    <>
                      <Card.Grid style={gridStyle}>{s}</Card.Grid>
                    </>
                  );
                })}
              </Card>
              <Card
                headStyle={cardHeaderStyle}
                title="Columns Group 3"
                extra={<CheckboxAntd onClick={(e) => handleGroupCheckBox(e, "setting1")} style={{ marginLeft: 20 }} />}
                style={{
                  width: "100%",
                  margin: "0px 20px",
                }}
              >
                {setting3.map((s) => {
                  return (
                    <>
                      <Card.Grid style={gridStyle}>{s}</Card.Grid>
                    </>
                  );
                })}
              </Card>
              <Card
                headStyle={cardHeaderStyle}
                title="Columns Group 4"
                extra={<CheckboxAntd onClick={(e) => handleGroupCheckBox(e, "setting1")} style={{ marginLeft: 20 }} />}
                style={{
                  width: "100%",
                  margin: "0px 20px",
                }}
              >
                {setting4.map((s) => {
                  return (
                    <>
                      <Card.Grid style={gridStyle}>{s}</Card.Grid>
                    </>
                  );
                })}
              </Card>
            </div>
            {isMobile && (
              <div onClick={() => this.handleUpdateSetting()} className="sideBarPopupButton mobileAction">
                <span className="commonButton">Update</span>
              </div>
            )}
            <div className="botoomStickyBar diamondListingAction justify-content-center">
              <button className="bottomStickyButton" onClick={() => this.handleUpdateSetting()}>
                <IntlMessage id="app.updateButton" />
              </button>
            </div>{" "}
          </>
        )}

        {!this?.state?.showGroups && (
          <div className="configurationInnerSubTb manageDiamondSeq">
            <div className="searchResultListWrapper">
              <div className="editProfilePage">
                {isMobile && (
                  <div className="d-flex justify-content-between align-items-center profileHead userManageTop">
                    <Heading title={<IntlMessage id="app.ManageDiamondSequence" />} className="p-0 m-0" />
                  </div>
                )}
                <div className="searchInnerResult">
                  <div className="searchResultTable columnSettingPage">
                    <Table columns={columns} data={settings} setNewSettings={this.setNewSettings} />
                  </div>
                </div>
                {isMobile && (
                  <div onClick={() => this.handleUpdateSetting()} className="sideBarPopupButton mobileAction">
                    <span className="commonButton">Update</span>
                  </div>
                )}
                <div className="botoomStickyBar diamondListingAction justify-content-center">
                  <button className="bottomStickyButton" onClick={() => this.handleUpdateSetting()}>
                    <IntlMessage id="app.updateButton" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default compose(withMobile)(DiamondColumnListing);
