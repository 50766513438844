import React, { Component } from "react";

import IntlMessage from "util/IntlMessage";

class NoDataShow extends Component {
  render() {
    return (
      <div className="empty-data" style={this.props.style && this.props.style}>
        <h3>{this.props.message ? this.props.message : <IntlMessage id="app.noData" />}</h3>
      </div>
    );
  }
}

export default NoDataShow;
