import { groupBy } from "lodash";

import { callingApi } from "services/util";

import {
  formatCurrency,
  formatDate,
  formatDateTime,
  formatDecimal,
  isArray,
  isEmpty,
  isNotEmpty,
  reduceTotal,
} from "util/utils";

export class OfferService {
  static async getOfferList(payload) {
    const [, res] = await callingApi({
      method: "post",
      url: `web/v1/diamond-track/paginate`,
      request: payload,
    });

    const list = (() => {
      if (!isArray(res?.data?.list)) return [];

      let _list = res?.data?.list.map((record) => ({
        ...record,
        ...record.diamond,
        trackId: record.id,
        groupKey: formatDateTime(record.createdAt),
      }));
      _list = Object.entries(groupBy(_list, "groupKey")).map(([createdAt, groupList]) => {
        if (isEmpty(groupList)) return [];
        const groupData = {
          createdAt,
          expiresAt: formatDate(groupList?.[0]?.offerValidDate),
          pcs: groupList?.length,
          totalCts: formatDecimal(reduceTotal(groupList, "crt")),
          totalAmount: formatCurrency(reduceTotal(groupList, "amt")),
        };

        const groupTitle = [
          `Created At: ${groupData.createdAt || "-"}`,
          `Expires At: ${groupData.expiresAt || "-"}`,
          `Pcs.: ${groupData.pcs || "-"}`,
          `Total Cts.: ${formatDecimal(groupData.totalCts || "-")}`,
          `Total Amount: ${groupData.totalAmount || "-"}`,
        ].join(" | ");

        groupList[0].groupData = groupData;
        groupList[0].groupTitle = groupTitle;
        return groupList;
      });

      return _list.filter(isNotEmpty).flat();
    })();
    const count = res?.data?.count ?? 0;
    const total = res?.data?.total?.[0] ?? {};
    const columns = res?.data?.gridColumns ?? [];
    const inTrackDiamonds = res?.data?.inTrackDiamonds ?? [];
    const inBlockDiamonds = res?.data?.inBlockDiamonds ?? [];

    return { list, count, total, columns, inTrackDiamonds, inBlockDiamonds };
  }
}
