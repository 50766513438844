import React from "react";

// import { Storage } from 'services/storage';

import { formatDecimal, isEmpty } from "util/utils";

const BasicDetail = (props) => {
  const { data, shape } = props;

  return (
    <div className="">
      <ul className="basicDetailFull">
        {!isEmpty(data.shpNm) && <li>{shape?.find?.((x) => x?.code === data?.shpNm)?.name}</li>}
        {!isEmpty(data.crt) && <li>{formatDecimal(data.crt)}</li>}
        {!isEmpty(data.colNm) && <li>{data.colNm}</li>}
        {!isEmpty(data.clrNm) && <li>{data.clrNm}</li>}
      </ul>
    </div>
  );
};
export default BasicDetail;
