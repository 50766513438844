import React, { useState, useEffect, useCallback } from "react";

import { Slider } from "antd";
import first from "lodash/first";
import includes from "lodash/includes";
import last from "lodash/last";

import IntlMessage from "util/IntlMessage";

import { LOCAL_STORAGE_VAR } from "../../../constants/Common";

const marks = {
  0: "D",
  10: "E",
  20: "F",
  30: "G",
  40: "H",
  50: "I",
  60: "J",
  70: "K",
  80: "L",
  90: "M",
  100: "N-",
  // 100: "N-",
};

const ColorRange = (props) => {
  const [def, setDef] = useState([0, 100]);
  const [inSide, setInside] = useState(false);

  const throwId = useCallback(
    (fil) => {
      const getArray = [];
      if (!inSide) {
        fil.forEach((co) => {
          Object.keys(marks).forEach((v) => {
            if (marks[v] === co) {
              getArray.push(Number(v));
            }
          });
        });
        const sorted = getArray.slice().sort((a, b) => {
          return a - b;
        });

        const data = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).COLOR;
        const dt = [];
        let ids = [];

        const ft = Number(sorted[0]);
        const lt = Number(sorted[sorted.length - 1]);

        Object.keys(marks).forEach((v) => {
          // if (ft !== 0 && !ft) {
          // } else if (lt !== 0 && !lt) {
          // } else

          if (v >= ft && v <= lt) {
            dt.push(marks[v].replace("-", ""));
          }
        });
        data.forEach((d) => {
          if (includes(dt, d.code)) {
            ids = ids.concat(d.id);
          }
        });
        return props.setIds(ids);
      }
    },
    [inSide, props]
  );

  const setPreFilters = useCallback(
    (fil) => {
      const getArray = [];
      if (!inSide) {
        fil.forEach((co) => {
          Object.keys(marks).forEach((v) => {
            if (marks[v] === co) {
              getArray.push(Number(v));
            }
          });
        });
        const sorted = getArray.slice().sort((a, b) => {
          return a - b;
        });

        const defaultArray = [Number(sorted[0]), Number(sorted[sorted.length - 1])];
        setDef([...defaultArray]);
      }
    },
    [inSide]
  );

  useEffect(() => {
    if (props.preRange && props.preRange.length !== 0) {
      setPreFilters([...props.preRange]);
      throwId([...props.preRange]);
    }
  }, [props.preRange]);

  const getColorRange = (range) => {
    setDef([...range]);
    setInside(true);
  };

  const getColorRangeAfter = (range) => {
    const data = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).COLOR;
    const dt = [];
    let ids = [];

    const ft = first(range);
    const lt = last(range);
    Object.keys(marks).forEach((v) => {
      // if (ft !== 0 && !ft) {
      // } else if (lt !== 0 && !lt) {
      // } else if (v >= ft && v <= lt) {
      //   dt.push(marks[v].replace('-', ''));
      // }

      if (v >= ft && v <= lt) {
        dt.push(marks[v].replace("-", ""));
      }
    });
    data.forEach((d) => {
      if (includes(dt, d.code)) {
        ids = ids.concat(d.id);
      }
    });
    return props.setColorIds(ids);
  };
  return (
    <div className="dashquickSearchItem d-flex align-items-center">
      {props.titleHide ? null : (
        <div className="dashquickSearchLabel">
          <span>
            <IntlMessage id="app.color" />
          </span>
        </div>
      )}
      <div className="dashquickSearchBlock">
        <Slider
          onAfterChange={getColorRangeAfter}
          onChange={getColorRange}
          step={10}
          marks={marks}
          range
          value={def}
          tooltipVisible={false}
        />
      </div>
    </div>
  );
};
export default React.memo(ColorRange);
