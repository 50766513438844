import { memoize } from "lodash";
import { DateTime } from "luxon";

import { Storage } from "services/storage";

import { isNumeric, isNumber, isArray, isEmpty , parseDecimal, sortArrayByKey } from "util/utils";

export const prepareStoneForBargain = (_stone, options) => {
  const { noModify, isUpdate } = { noModify: true, isUpdate: false, ...options };

  const stone = { ...(_stone?.original ?? _stone) }; // get original value
  stone.original = { ...stone }; // preserve original values for recalculation

  // cast to number or undefined
  stone.amt = isNumeric(stone.amt) ? parseDecimal(stone.amt) : undefined;
  stone.back = isNumeric(stone.back) ? parseDecimal(stone.back) : undefined;
  stone.ctPr = isNumeric(stone.ctPr) ? parseDecimal(stone.ctPr) : undefined;
  stone.rap = isNumeric(stone.rap) ? parseDecimal(stone.rap) : undefined;
  stone.crt = isNumeric(stone.crt) ? parseDecimal(stone.crt) : undefined;

  stone.newAmount = isNumeric(stone.newAmount) ? parseDecimal(stone.newAmount) : undefined;
  stone.newDiscount = isNumeric(stone.newDiscount) ? parseDecimal(stone.newDiscount) : undefined;
  stone.newPricePerCarat = isNumeric(stone.newPricePerCarat) ? parseDecimal(stone.newPricePerCarat) : undefined;

  stone.amt = stone.newAmount ?? stone.amt;
  stone.back = stone.newDiscount ?? stone.back;
  stone.ctPr = stone.newPricePerCarat ?? stone.ctPr;

  if (!noModify && !isUpdate) {
    stone.back = isNumber(stone.back) ? parseDecimal(stone.back - 1) : stone.back;
    stone.ctPr = parseDecimal((stone.back * stone.rap) / 100 + stone.rap);
    stone.amt = parseDecimal(stone.ctPr * stone.crt);
  }

  stone.calcAmount = stone.amt;
  stone.calcDiscount = stone.back;
  stone.calcPricePerCarat = stone.ctPr;
  stone.baseCalcPricePerCarat = stone.ctPr;

  stone.newAmount = stone.calcAmount;
  stone.newDiscount = stone.calcDiscount;
  stone.newPricePerCarat = stone.calcPricePerCarat;

  stone.bargainTrack =
    isArray(stone.bargainTrack) && !isEmpty(stone.bargainTrack)
      ? stone.bargainTrack
          .map((track) => ({ ...track, timestamp: DateTime.fromISO(track.updatedAt).valueOf() }))
          .sort(sortArrayByKey("timestamp", true))
      : [];

  stone.defaultTrack = {
    userType: stone.coUserType,
    trackAmount: stone.amt,
    trackDiscount: stone.back,
    trackPricePerCarat: stone.ctPr,
  };

  stone.currentValidTrack = (() => {
    if ([1, 2, 8].includes(stone.coUserType)) return stone.defaultTrack;

    const latestAdminTrack = stone.bargainTrack.find((track) => [1, 2, 8].includes(track.userType));
    if (!isEmpty(latestAdminTrack)) return latestAdminTrack;

    return undefined;
  })();

  stone.hasCounterOffer = [1, 2, 8].includes(stone.currentValidTrack?.userType);

  stone.disableSelection = !stone.hasCounterOffer;
  stone.onCheckError = "You are not allowed to accept this offer without a counter offer";

  return stone;
};

export const findInMaster = memoize(
  (master, key, value) => {
    const list = Storage.get("master", false);
    return list?.[master]?.find?.((item) => item[key] === value) ?? undefined;
  },
  (...args) => JSON.stringify(args)
);
