import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Router, Route, Switch } from "react-router-dom";
import { compose } from "redux";

import urlJoin from "url-join";

import Analytics from "components/Analytics";
import GradingSystem from "components/GradingSystem";
import KgkHome from "components/KgkHome";

import { Storage } from "services/storage";
import UtilService from "services/util";

import { history } from "util/history";
import { withMobile } from "util/hocs";
import { loadable } from "util/loadable";

import { BASE_URL, LOCAL_STORAGE_VAR, WHITE_LABEL } from "constants/Common";

import AppLocale from "lngProvider";

import "components/auth.less";
import GotoTop from "./GoToTop";
import FrontRoute from "./Routes";

import logoSvg from "assets/svg/diamondLogo.svg";

const ForgotPassword = loadable(() => import("components/Auth/ForgotPassword"));
const ResetPassword = loadable(() => import("components/Auth/ResetPassword"));
const BiddingProcedure = loadable(() => import("components/BiddingProcedure"));
const BookAppointment = loadable(() => import("components/BookAppointment"));
const Career = loadable(() => import("components/Career"));
const CareerDetail = loadable(() => import("components/Career/CareerDetail"));
const DNA = loadable(() => import("components/DNA"));
const DiamondDetail = loadable(() => import("components/DiamondDetail"));
const EmailVerify = loadable(() => import("components/EmailVerify"));
const FTC = loadable(() => import("components/FTC"));
const GuestLogin = loadable(() => import("components/GuestLogin"));
const PageNotFound = loadable(() => import("components/PageNotFound"));
const SignIn = loadable(() => import("components/SignIn"));
const SignUp = loadable(() => import("components/SignUp"));
const ThankYouAfterSignup = loadable(() => import("components/ThankYou"));
const VerifyDevice = loadable(() => import("components/VerifyDevice"));
const VerifyOtp = loadable(() => import("components/VerifyOtp"));
const XRAY = loadable(() => import("components/XRAY"));

const StaticPages = loadable(() => import("containers/StaticPages/StaticPages"));

class App extends Component {
  setFavicon = () => {
    const authUser = Storage.get("user");
    const favicon = document.getElementById("favicon");
    if (authUser && authUser.account && authUser.account.vendorLogo !== "") {
      // favicon.href = BASE_URL + '/' + authUser.account.vendorLogo;
      favicon.href = urlJoin(BASE_URL, authUser.account.vendorLogo);
    } else {
      favicon.href = logoSvg;
    }
  };

  setTitle = () => {
    const authUser = Storage.get("user");
    document.title = authUser && authUser.companyName;
    this.setFavicon();
  };

  render() {
    const token = Storage.get("token");

    const lang =
      this.props.commonData.locale && this.props.commonData.locale.locale ? this.props.commonData.locale.locale : "en";
    const currentAppLocale = AppLocale[lang];
    const RestrictedRoutes = ({ component: Component, ...rest }) => {
      const pagePermissions = this.props.commonData.pagePermission();
      if (token && pagePermissions && (!Object.values(pagePermissions) || !Object.values(pagePermissions).length)) {
        localStorage.clear();
        return <Redirect to={"/"} />;
      }
      return (
        <Route
          {...rest}
          render={(props) =>
            !UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) ? (
              <Component {...props} />
            ) : (
              <Redirect to={"/dashboard"} />
            )
          }
        />
      );
    };

    const pagePermissions = this.props.commonData.pagePermission();
    return (
      <>
        {WHITE_LABEL && this.setTitle()}
        <Router history={history}>
          <Analytics />
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <Switch>
              <RestrictedRoutes path="/" component={KgkHome} exact />
              <RestrictedRoutes path="/grading" component={GradingSystem} exact />
              {/* <RestrictedRoutes path="/" component={SignIn} exact /> */}
              <RestrictedRoutes path="/signin" component={SignIn} />
              <Route exact path="/verify-otp" component={VerifyOtp} />
              <RestrictedRoutes path="/signup" component={SignUp} />
              <RestrictedRoutes path="/device/signup" component={SignUp} />
              <RestrictedRoutes path="/guest-login" component={GuestLogin} />
              <RestrictedRoutes path="/forgot-password" component={ForgotPassword} />
              <RestrictedRoutes path="/reset-password/:token" component={ResetPassword} />
              <Route exact path="/thank-you" component={ThankYouAfterSignup} />
              <Route exact path="/verify-email/:id" component={EmailVerify} />
              <Route exact path="/verify-device" component={VerifyDevice} />
              {/* <Route path="/device/contact-us" component={ContactUs} /> */}
              <Route exact path="/xrays" component={XRAY} />
              <Route exact path="/DiamFTC" component={FTC} />
              <Route exact path="/dna" component={DNA} />
              <Route exact path="/career" component={Career} />
              <Route exact path="/career-detail" component={CareerDetail} />
              <Route exact path="/bidding-procedure" component={BiddingProcedure} />
              <Route exact path="/book-appointment" component={BookAppointment} />
              {Boolean(!token || pagePermissions?.SEARCH_LIST?.view) && (
                <Route exact path="/diamond-detail/:id" component={DiamondDetail} />
              )}
              <Route
                exact
                path=":type(/device|)?/:page(home|about-us|contact-us|news|privacy-policy|terms-condition)"
                component={StaticPages}
              />
              <Route exact path="/not-found" component={PageNotFound} />
              <FrontRoute />
            </Switch>
          </IntlProvider>
          <GotoTop />
        </Router>
      </>
    );
  }
}

const mapStateToProps = (props) => ({ commonData: props.commonData });
export default compose(connect(mapStateToProps), withMobile)(App);
