import React from "react";

import { Tooltip } from "antd";

const SlotItem = (props) => {
  return (
    <div className={`officeBookSoltTime ${props.className ? props.className : ""}`} onClick={props.onClick}>
      <Tooltip title={props.tooltip} overlayClassName="popupZindex">
        {props.time}
      </Tooltip>
    </div>
  );
};
export default SlotItem;
