import React, { Component } from "react";
import { connect } from "react-redux";

import find from "lodash/find";

import MagnifierImage from "./DiamondDetail/MagnifierImage";
import { getGridHeight } from "./DiamondList";
import { handleAlter } from "./DiamondList/DiamondListFunctions";
import { floatkeys, roundkeys } from "./DiamondList/TableBack";
import { getBidType } from "./NewArrivalBidIt";
import CheckBox from "./common/CheckBox";
import DiamondListingAction from "./common/DiamondListing/DiamondListingAction";
import { formatNumber } from "./common/DiamondListing/SelectStone";
import Heading from "./common/Heading";
import NoDataShow from "./common/NoDataShow";

import closeRedSVG from "../assets/svg/Compare/close-red.svg";
import { LOCAL_STORAGE_VAR, FILE_URLS, DIAMOND_BID, PAGES } from "../constants/Common";
import { DIAMOND_DATA_BY_ID } from "../constants/apiConstant";
import UtilService from "../services/util";

const LISTINGPAGES = PAGES;

const currentType = "DiamondCompare";

class DiamondCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      checked: [],
      inTrackDiamonds: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
  }

  fetch = () => {
    const self = this;
    const ids = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
      : [];
    if (ids.length === 0 || ids.length === 1) {
      this.setState({ data: [] });
      return;
    }
    const obj = DIAMOND_DATA_BY_ID;
    obj.request = {
      id: ids,
      isReturnTotal: true,
      isReturnIntrack: true,
    };
    this.setState({ loading: true });
    UtilService.callApi(obj, (err, data) => {
      let rows = [];
      let inTrackDiamonds = [];
      if (data && data.code === "OK") {
        rows = data.data.list;
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
      self.setState({ data: rows, inTrackDiamonds, loading: false });
    });
  };

  getMap = (name, key, extra = "") => {
    const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).SHAPE || {};
    const shape = ["shpNm"];

    return (
      <tr>
        <td>{name}</td>
        {this.state.data.map((data, index) => {
          return (
            <td key={index}>
              {(!data[key]
                ? "-"
                : shape.includes(key)
                ? masterData?.find((x) => x.code === data?.shpNm ?? data?.shpNm)?.name === undefined
                  ? data.shpNm
                  : masterData?.find((x) => x.code === data?.shpNm)?.name
                : floatkeys.includes(key)
                ? formatNumber(parseFloat(data[key]).toFixed(2))
                : roundkeys.includes(key)
                ? formatNumber(Math.round(data[key]))
                : data[key]) + extra}
            </td>
          );
        })}
      </tr>
    );
  };

  removeCompare = (id) => {
    let ids = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
      : [];
    ids = ids.filter((el) => el !== id);
    if (ids.length === 1) ids = [];
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-compare-diamond`, JSON.stringify(ids));
    this.setState({ data: this.state.data.length === 2 ? [] : this.state.data.filter((el) => el.id !== id) });
  };

  render() {
    const gridHeight = getGridHeight(
      [
        "diamondListingAction",
        "headerTopMenu",
        "selectStoneHeader",
        "covidStrip",
        "HeaderStripBlock",
        "comparPageHead",
        "mobileSearchBottom",
      ],
      25
    );
    const fsNow = localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)
      ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)).split("/")[1]
      : "";
    return (
      <>
        <div className="compareMainPage">
          <div className="d-flex justify-content-between align-items-center mb-10 comparPageHead">
            <Heading title={"Compare Stones (" + this.state.data.length + ")"} className="mb-0" />
          </div>
          {!this.state.loading && !this.state.data.length ? <NoDataShow /> : null}
          {!this.state.loading && this.state.data.length ? (
            <div className="compareTable" style={{ height: gridHeight, overflow: "auto" }}>
              <table>
                <tr>
                  <td className="compareHead">Diamond Image</td>
                  {this.state.data.map((x, i) => {
                    return (
                      <td key={i} className="compareHead compareImage">
                        {true && (
                          // x.img
                          <>
                            <MagnifierImage
                              image={FILE_URLS.img.replace("***", x.vStnId)}
                              // image={require()}
                              className="diamondCompareImg"
                            />
                            <div className="diamondGridLabel">{/* <label>DD:1</label> */}</div>
                          </>
                        )}
                        <div className="closeImage" onClick={() => this.removeCompare(x.id)}>
                          <img src={closeRedSVG} alt="" />
                        </div>
                        <div className="checkImageBox">
                          <CheckBox
                            checked={find(this.state.checked, {
                              stoneId: x.stoneId,
                            })}
                            onChange={() => {
                              const checked = handleAlter(this.state.checked, x, "stoneId");
                              this.setState({ checked });
                            }}
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
                {this.getMap("Stone No", "vStnId")}
                {this.getMap("Lab", "lbNm")}
                {this.getMap("Shape", "shpNm")}
                {this.getMap("Carat", "crt")}
                {this.getMap("Color", "colNm")}
                {this.getMap("Clarity", "clrNm")}
                {this.getMap("Shade", "shdNm")}
                {this.getMap("Rap ($)", "rap")}
                {this.getMap("Discount%", "back")}
                {this.getMap("Price/Carat ($)", "ctPr")}
                {this.getMap("Amount ($)", "amt")}
                {this.getMap("Certificate No", "rptNo")}
                {this.getMap("Fluorescence", "fluNm")}
                {this.getMap("Cut", "cutNm")}
                {this.getMap("Polish", "polNm")}
                {this.getMap("Symmetry", "symNm")}
                {this.getMap("Eye Clean", "eClnNm")}
                {this.getMap("Depth %", "depPer")}
                {this.getMap("Table %", "tblPer")}
                {this.getMap("Length", "length")}
                {this.getMap("Width", "width")}
                {this.getMap("Depth", "height")}
                {this.getMap("Crown Angle", "cAng")}
                {this.getMap("Crown Height", "cHgt")}
                {this.getMap("Pav Angle", "pAng")}
                {this.getMap("Pav Height", "pHgt")}
                {this.getMap("Girdle", "grdl")}
                {this.getMap("Culet", "cult")}
                {this.getMap("H&A", "hANm")}
              </table>
            </div>
          ) : null}
        </div>
        <DiamondListingAction
          {...this.state}
          {...this.props}
          checkedData={this.state.checked}
          bidStone={fsNow === LISTINGPAGES.NEWARR || fsNow === LISTINGPAGES.BID}
          myBid={fsNow === LISTINGPAGES.NEWARR || fsNow === LISTINGPAGES.BID}
          excel={fsNow === LISTINGPAGES.NEWARR || fsNow === LISTINGPAGES.BID}
          nowatch={fsNow === LISTINGPAGES.NEWARR || fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID}
          noreminder={fsNow === LISTINGPAGES.NEWARR || fsNow === LISTINGPAGES.BID}
          nofinalcalc
          modify
          nocompare
          compareList
          nopaginate
          currentType={currentType}
          clearAll={() => this.setState({ checked: [] })}
          fetch={this.fetch}
          noconfirm={fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID || fsNow === LISTINGPAGES.NEWARR}
          nocart={fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID || fsNow === LISTINGPAGES.NEWARR}
          noquote={fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID || fsNow === LISTINGPAGES.NEWARR}
          noappointment={
            getBidType() === DIAMOND_BID.TYPE.BLIND ||
            fsNow === LISTINGPAGES.BID ||
            fsNow === LISTINGPAGES.MYBID ||
            fsNow === LISTINGPAGES.NEWARR
          }
          nonote={fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID || fsNow === LISTINGPAGES.NEWARR}
        />
      </>
    );
  }
}

export default connect((props) => {
  return props;
}, {})(DiamondCompare);
