import React, { Component } from "react";

import IntlMessage from "util/IntlMessage";

import diamondPNG from "../../../assets/img/DiamondDetail/diamond.png";

export default class Image extends Component {
  state = {
    src: this.props.src,
    error: false,
  };
  onError = () => {
    this.setState({ src: diamondPNG, error: true });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };
  render() {
    const { error } = this.state;
    return (
      <>
        {error ? (
          this.props.list ? (
            <div style={{ marginLeft: "465px" }} className="diamondDetailImageBox htmlViewImage">
              <img alt="img" style={{ width: "306px", height: "306px" }} src={this.props.tempSrc} />
              <br />
              <br />

              <span style={{ marginLeft: "92px" }}>
                <IntlMessage id="app.NoImage" />
              </span>
            </div>
          ) : (
            <span style={{ marginTop: "92px" }} className="GridViewNoImage">
              <IntlMessage id="app.NoImage" />
            </span>
          )
        ) : (
          <img alt="img" onError={this.onError} src={this.state.src} />
        )}
      </>
    );
  }
}
