import React, { memo, useEffect, useState } from "react";

import { Drawer } from "antd";
import { invert, size } from "lodash";
import find from "lodash/find";

// import { HeadingCalc } from './ConfirmStonePopup';

import { isMobile } from "components/DiamondList/DiamondListFunctions";

import { calculate, formatNumber } from "./SelectStone";

import useRowSelect from "../../../util/useRowSelect";
import MobileViewListCard from "../../DiamondList/MobileViewListCard";
// import Datepicker from '../DatePicker';
import Heading from "../Heading";

function HeadingCalculate(checked, VALUES = null) {
  if (isMobile() && !checked.length && !VALUES) return null;
  let obj = {};
  if (VALUES) obj = VALUES;
  else {
    const sum = calculate(checked);
    obj = {
      "CT. :": parseFloat(sum.total_carat).toFixed(2),
      "Disc% :": parseFloat(sum.final_discount).toFixed(2),
      "Price/Ct :": formatNumber(parseFloat(sum.final_price).toFixed(2)),
      "Total Value :": formatNumber(parseFloat(sum.final_value).toFixed(2)),
    };
  }
  if (isMobile()) obj = invert(obj);
  if (size(obj) < 4) return null;
  return (
    <div className="DiamondDetailPopup">
      {Object.keys(obj).map((x) => {
        return (
          <div key={x} className="DiamondDetailPopupItem" style={VALUES ? checked : {}}>
            <span>{x}</span>
            <span>{isMobile() ? obj[x].slice(0, obj[x].length - 1) : obj[x]}</span>
          </div>
        );
      })}
    </div>
  );
}

export const ListingCards = memo(
  ({
    data,
    currentType,
    finalCalc = false,
    bidPopup = false,
    quote = false,
    quoteClick,
    checked = null,
    bid = false,
    note = false,
    watchpopup = false,
    quotePage = false,
    matchPair = false,
    onClick,
    BidTab,
  }) => {
    const { isRowChecked, toggleRowSelection } = useRowSelect(currentType);
    return (
      <>
        {data.map((x, i) => {
          const isChecked = !checked ? isRowChecked(x.id) : !!find(checked, { id: x.id });
          return (
            <MobileViewListCard
              key={i}
              BidTab={BidTab}
              quote={quote}
              quotePage={quotePage}
              note={note}
              watchpopup={watchpopup}
              bidPopup={bidPopup}
              bid={bid}
              finalCalc={finalCalc}
              quoteClick={quoteClick}
              data={x}
              onClick={() => {
                if (!matchPair && !bid) {
                  toggleRowSelection(x, !isChecked);
                } else if (!bid) onClick();
              }}
              activeClass={isChecked ? "active" : ""}
            />
          );
        })}
      </>
    );
  }
);

const MobileConfirmStone = (props) => {
  const { toggleRowSelection, isRowChecked } = useRowSelect(props.currentType);
  const [menu, showMenu] = useState(false);
  useEffect(() => {
    props.data.map((row) => {
      toggleRowSelection(row, true);
      return true;
    });
  });

  return (
    <>
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title="Confirm Stone" />
          </div>
          {HeadingCalculate(props.data.filter((x) => isRowChecked(x.id)))}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards data={props.data} currentType={props.currentType} />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap" />
        </div>
        <div className="sideBarPopupButton">
          <span
            className="commonButton"
            onClick={() => {
              if (props.parent.checkCheck()) showMenu(true);
            }}
          >
            Confirm Stone
          </span>
        </div>
      </div>
      {menu && (
        <Drawer
          title={false}
          onClose={() => showMenu(false)}
          visible
          wrapClassName="diamondListinSidebar smallSidebarMobilePopup"
        >
          <div>
            <div className="offerInputBox">
              {props.parent.getPaymentTerms()}
              {props.parent.getPreShip()}

              {/* <Datepicker
                handleChange={(e) => {
                  props.handleChange(e);
                }}
                expiryDate={props.expiryDate}
                disabledDate={disabledDate}
                label="Invoice date"
              /> */}
              {/* {props.parent.getinvoicedate()} */}
              {props.parent.gettextareaa()}
              {props.parent.gettextarea()}
            </div>
            <div className="sideBarPopupButton">
              <span className="commonButton" onClick={props.parent.submit}>
                Confirm
              </span>
              <span className="commonOutline" onClick={props.parent.cancel}>
                Cancel
              </span>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default memo(MobileConfirmStone);
