import React from "react";

import { DatePicker } from "antd";
import moment from "moment";

import IntlMessage from "util/IntlMessage";

import OpenNotification from "./CommonButton/OpenNotification";
import Heading from "./Heading";

import nextWeekPNG from "../../assets/img/next-week.png";
import todayPNG from "../../assets/img/today.png";
import reminderDatePNG from "../../assets/img/today.png";
import tomorrowPNG from "../../assets/img/tomorrow.png";

let input = "blank";
function disabledDate(current) {
  return current && current < moment().startOf("day");
}

class Reminder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImg: 0,
    };
  }

  handleCancel = () => {
    this.props.handleCancel();
  };

  handleOk = () => {
    if (this.state.currentImg === 0) {
      OpenNotification({
        type: "error",
        title: "Please select any option to add reminder",
      });
      return;
    }
    if (input === "blank") return;
    if (input === "") input = moment().toISOString();
    this.props.handleSetReminder(input);
  };

  laterToday = () => {
    this.setState({ currentImg: 1 });
    const ips = moment().startOf("day").add(18, "hour");
    if (ips > moment()) {
      input = ips.toISOString();
    }
  };
  tomorrow = () => {
    this.setState({ currentImg: 2 });
    const ips = moment().startOf("day").add(1, "day").add(8, "hour");
    if (ips > moment()) {
      input = ips.toISOString();
    }
  };
  nextWeek = () => {
    this.setState({ currentImg: 3 });
    const ips = moment().startOf("day").add(1, "week").add(8, "hour");
    if (ips > moment()) {
      input = ips.toISOString();
    }
  };
  pick = () => {
    this.setState({ currentImg: 4 });
    input = "";
  };

  render() {
    return (
      <div>
        {/* <div className='d-flex align-items-center justify-space-between offerTopBlock offerWrapper'> */}
        <Heading className="popupInnerTitle" title={<IntlMessage id="app.addreminder" />} />
        {/* {HeadingCalc(this.props.checked)}
                </div> */}
        <div role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <div className="card-table-body no-padding">
            <div>
              <div className="d-flex justify-content-between flex-wrap">
                <div
                  className={this.state.currentImg === 1 ? "reminder-block active" : "reminder-block"}
                  onClick={this.laterToday}
                >
                  <div className="reminder-wrapper">
                    <div className="reminderImageBlock">
                      <img src={todayPNG} alt="" />
                    </div>
                    <div className="reminderDetail">
                      <h3>
                        <IntlMessage id="app.laterToday" />
                      </h3>
                      <span>6:00 PM</span>
                    </div>
                  </div>
                </div>
                <div
                  className={this.state.currentImg === 2 ? "reminder-block active" : "reminder-block"}
                  onClick={this.tomorrow}
                >
                  <div className="reminder-wrapper">
                    <div className="reminderImageBlock">
                      <img src={tomorrowPNG} alt="" />
                    </div>
                    <div className="reminderDetail">
                      <h3>
                        <IntlMessage id="app.tomorrow" />
                      </h3>
                      <span>
                        {moment().startOf("day").add(1, "day").add(8, "hour").format("dddd").slice(0, 3).toUpperCase()}
                        &nbsp; 8:00 AM
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={this.state.currentImg === 3 ? "reminder-block active" : "reminder-block"}
                  onClick={this.nextWeek}
                >
                  <div className="reminder-wrapper">
                    <div className="reminderImageBlock">
                      <img src={nextWeekPNG} alt="" />
                    </div>
                    <div className="reminderDetail">
                      <h3>
                        <IntlMessage id="app.nextWeek" />
                      </h3>
                      <span>
                        {moment().startOf("day").add(1, "week").add(8, "hour").format("dddd").slice(0, 3).toUpperCase()}
                        &nbsp; 8:00 AM
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={this.state.currentImg === 4 ? "reminder-block active" : "reminder-block"}
                  onClick={this.pick}
                >
                  <div className="reminder-wrapper">
                    <div className="reminderImageBlock">
                      <img src={reminderDatePNG} alt="" />
                    </div>
                    <div className="reminderDetail">
                      <h3>
                        <IntlMessage id="app.pickdatetime" />
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.currentImg === 4 && (
              <div className="align-items-center mt-30">
                <div className="leftSelectItem">
                  <div className="leftSelectTitle">
                    <h2>
                      <IntlMessage id="app.Date" />
                    </h2>
                  </div>
                  <DatePicker
                    dropdownClassName="datePickerDropdown"
                    className="SelectDateRange"
                    onChange={(e) => {
                      if (!e) {
                        input = "blank";
                        return;
                      }
                      if (e._d <= moment()) {
                        OpenNotification({
                          type: "error",
                          title: "Please select time for current date.",
                        });
                        input = "blank";
                        return;
                      }
                      input = e._d.toISOString();
                    }}
                    format="ll"
                    placeholderText={<IntlMessage id="app.selectdate" />}
                    disabledDate={disabledDate}
                    defaultValue={moment()}
                    minTime={moment()}
                    showTime={{
                      use12Hours: true,
                      format: "HH:mm a",
                      minTime: moment(),
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="sideBarPopupButton">
            <span className="commonButton" onClick={this.handleOk}>
              <IntlMessage id="app.addreminder" />
            </span>
            <span className="commonButton" onClick={this.handleCancel}>
              <IntlMessage id="app.Cancel" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default Reminder;
