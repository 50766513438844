import React, { Component } from "react";

import { DatePicker, Select } from "antd";
import moment from "moment";

import IntlMessage from "util/IntlMessage";

const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;
const { Option } = Select;

class FilterTopOption extends Component {
  render() {
    return (
      <div className="filterTopOption">
        {this.props.dateFilter === false ? null : (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">From-To Date : </h2>
            <RangePicker
              defaultValue={[moment("01/07/2020", dateFormat), moment("10/07/2020", dateFormat)]}
              format={dateFormat}
            />
          </div>
        )}
        {/* <div className="filterSidebarItem">
                    <h2 className="filterSidebarTitle">Filter by Customer Name : </h2>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        defaultValue={['1', '2']}
                    >
                        <Option value="1">Vishal Virani</Option>
                        <Option value="2">Kalpesh Zalavadiya</Option>
                    </Select>
                </div> */}
        {this.props.updateStatus && (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">Update Status : </h2>
            <Select
              className="updateStausBlock sm-size"
              // mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue="1"
              // onChange={handleChange}
            >
              <Option value="1">New</Option>
              <Option value="2">Open</Option>
              <Option value="3">Won</Option>
              <Option value="4">Close</Option>
            </Select>
          </div>
        )}
        {this.props.confirmList && (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">
              <IntlMessage id="app.Status" />
              :
              {' '}
            </h2>
            <Select
              className="updateStausBlock md-sm-size"
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue={1}
              onChange={(e) => this.props.onClick(e)}
            >
              <Option value={1}>
                <IntlMessage id="app.pending" />
              </Option>
              <Option value={2}>
                <IntlMessage id="app.approved" />
              </Option>
              <Option value={3}>
                <IntlMessage id="app.rejected" />
              </Option>
              {/* <Option value={4} >Outstanding</Option> */}
            </Select>
          </div>
        )}
        {this.props.offerList && (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">Status : </h2>
            <Select
              className="updateStausBlock md-sm-size"
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue={1}
              onChange={(e) => this.props.onClick(e)}
            >
              <Option value={1}>Pending</Option>
              <Option value={2}>Approved</Option>
              <Option value={3}>Rejected</Option>
            </Select>
          </div>
        )}
        {this.props.SearchReultOption && (
          <div className="filterSidebarItem">
            <h2 className="filterSidebarTitle">
              <IntlMessage id="app.SelectGrouping" />
            </h2>
            <Select
              className="updateStausBlock md-size"
              // mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue="1"
              // onChange={handleChange}
            >
              <Option value="1">
                <IntlMessage id="app.DateWiseGrouping" />
              </Option>
              <Option value="2">
                <IntlMessage id="app.DiamondWiseGrouping" />
              </Option>
            </Select>
          </div>
        )}
      </div>
    );
  }
}
export default FilterTopOption;
