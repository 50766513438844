import React from "react";

import IntlMessage from "util/IntlMessage";

import CheckBox from "../common/CheckBox";
import CommonModal from "../common/CommonModal";
import InputBlock from "../common/InputBlock";

const SavedSearchPopup = (props) => {
  return (
    <>
      <CommonModal
        visible={props.showSavedSearch}
        handleOk={() => props.handleSavedSearch()}
        handleCancel={() => props.handleReverseState("showSavedSearch")}
        title={props.searchId ? <IntlMessage id="app.update-search" /> : <IntlMessage id="app.AddSaveNSearch" />}
        footerShow
        submitTitle={<IntlMessage id="app.signup.Submit" />}
        cancelTitle={<IntlMessage id="app.Cancel" />}
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue">
            <IntlMessage id="app.SearchTitle">
              {(placeholder) => (
                <InputBlock
                  label={placeholder}
                  placeholder={placeholder}
                  value={props.searchTitle}
                  onChange={(e) => props.handleChange("searchTitle", e.target.value)}
                />
              )}
            </IntlMessage>
            {props.searchId && (
              <div>
                <CheckBox
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.handleChange("searchTitle", "");
                      props.handleChange("searchId", "");
                    }
                  }}
                  label="New Search"
                />
              </div>
            )}
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default SavedSearchPopup;
