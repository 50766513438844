import React, { Component } from "react";
import { compose } from "redux";

import { Drawer } from "antd";
import head from "lodash/head";
import last from "lodash/last";
import map from "lodash/map";
import split from "lodash/split";
import moment from "moment";

import IntlMessage from "util/IntlMessage";
import { withMobile } from "util/hocs";

import SlotItem from "./SlotItem";

import { LOCAL_STORAGE_VAR } from "../../../../constants/Common";
import { GET_SLOTS, CREATE_SCHEDULE, GET_CABIN } from "../../../../constants/apiConstant";
import UtilService from "../../../../services/util";
import OpenNotification from "../../CommonButton/OpenNotification";
import Heading from "../../Heading";
import LeftDatePicker from "../../LeftMultipleSelect/LeftDatePicker";
import SelectOption from "../../SelectOption";
import TextArea from "../../TextArea";

export const Appointment = {
  location: [{ name: "India" }, { name: "Spain" }, { name: "Hong Kong" }, { name: "Dubai" }],
  virtualType: [
    { name: "Phone Call", id: 1 },
    { name: "Web Conference", id: 2 },
    { name: "In Person", id: 3 },
  ],
};
export const OfficeFill = () => {
  return (
    <div>
      <div className="offerInputBox">
        <SelectOption
          selectValue={Appointment.virtualType}
          onChange={(value) => this.setState({ meetingType: value })}
          placeholder="Select virtual Type*"
          label="Select virtual Type*"
        ></SelectOption>
        <TextArea label="Comment" placeholder="Comment"></TextArea>
      </div>
      <div className="sideBarPopupButton">
        <span className="commonButton">Request Appointment</span>
        <span className="commonOutline">Cancel</span>
      </div>
    </div>
  );
};
class OfficeSidebar extends Component {
  constructor() {
    super();
    this.state = {
      // date: moment().toISOString(),
      date: "",
      slot: "",
      comment: "",
      slots: [],
      apiSlot: {},
      meetingType: undefined,
      bidSlots: [],
      office: false,

      cabinArray: [],
      cabinId: "",
      tempList: [
        {
          createdAt: "2020-01-06T11:29:35.453Z",
          updatedAt: "2020-01-06T11:29:35.453Z",
          id: "6017cd07d8a2494964f1f3fb",
          start: "1970-01-01T03:30:00.000Z",
          end: "1970-01-01T04:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 4,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.456Z",
          updatedAt: "2020-01-06T11:29:35.456Z",
          id: "6017cd07d8a2494964f1f3fa",
          start: "1970-01-01T04:00:00.000Z",
          end: "1970-01-01T04:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 4,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.460Z",
          updatedAt: "2020-01-06T11:29:35.460Z",
          id: "6017cd07d8a2494964f1f3f9",
          start: "1970-01-01T04:30:00.000Z",
          end: "1970-01-01T05:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 4,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.466Z",
          updatedAt: "2020-01-06T11:29:35.466Z",
          id: "6017cd07d8a2494964f1f3f8",
          start: "1970-01-01T05:00:00.000Z",
          end: "1970-01-01T05:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 4,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f7",
          start: "1970-01-01T05:30:00.000Z",
          end: "1970-01-01T06:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 4,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f6",
          start: "1970-01-01T06:00:00.000Z",
          end: "1970-01-01T06:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f5",
          start: "1970-01-01T06:30:00.000Z",
          end: "1970-01-01T07:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f4",
          start: "1970-01-01T07:00:00.000Z",
          end: "1970-01-01T07:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f3",
          start: "1970-01-01T07:30:00.000Z",
          end: "1970-01-01T08:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f2",
          start: "1970-01-01T08:00:00.000Z",
          end: "1970-01-01T08:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f1",
          start: "1970-01-01T08:30:00.000Z",
          end: "1970-01-01T09:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3f0",
          start: "1970-01-01T09:00:00.000Z",
          end: "1970-01-01T09:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3ef",
          start: "1970-01-01T09:30:00.000Z",
          end: "1970-01-01T10:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3ee",
          start: "1970-01-01T10:00:00.000Z",
          end: "1970-01-01T10:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3ed",
          start: "1970-01-01T10:30:00.000Z",
          end: "1970-01-01T11:00:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
        {
          createdAt: "2020-01-06T11:29:35.469Z",
          updatedAt: "2020-01-06T11:29:35.469Z",
          id: "6017cd07d8a2494964f1f3ec",
          start: "1970-01-01T11:00:00.000Z",
          end: "1970-01-01T11:30:00.000Z",
          weekDay: 4,
          type: 2,
          slotDurationType: 1,
          isActive: true,
          appliedFrom: "2020-01-06T11:29:35Z",
          appliedTo: "",
          reason: "",
          isDeleted: false,
          addedBy: null,
          updatedBy: null,
          createdBy: null,
          cabinId: "5e1319eab8c1995aaf471f1f",
        },
      ],
    };
  }
  onClose = () => {
    this.setState({
      office: false,
    });
  };
  officeView = () => {
    this.setState({
      office: true,
    });
  };

  componentDidMount = () => {
    this.GetCabin();
  };
  GetCabin = () => {
    const objData = {
      ...GET_CABIN,
      request: {},
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        if (data.data && data.data.list && data.data.list.length !== 0) {
          this.setState({ cabinArray: data.data.list });
        }
      }
    });
  };
  GetSlot = (id) => {
    const objData = {
      ...GET_SLOTS,
      request: { sort: [{ end: "ASC" }], cabin: id },
    };
    UtilService.callApi(objData, async (err, data) => {
      if (err) throw err;
      if (data && data.code === "OK") {
        const bidSlots = [];
        data.data.list.forEach((ls) => {
          const start = moment(ls.start).format("hh:mm A");
          const end = moment(ls.end).format("hh:mm A");
          const bidTime = start < "11:00 AM" && end > "03:00 PM";
          if (!bidTime) {
            bidSlots.push(ls);
          }
        });
        this.setState({ slots: data.data.list, bidSlots });
      }
    });
  };
  handleSave = () => {
    const { comment, slot, date, apiSlot, meetingType, cabinId } = this.state;

    if (!slot)
      return OpenNotification({
        type: "error",
        title: "Please select slot.",
      });

    if (!date)
      return OpenNotification({
        type: "error",
        title: "Please select date.",
      });
    if (!cabinId)
      return OpenNotification({
        type: "error",
        title: "Please select branch.",
      });
    const start = moment(`${date} ${head(split(slot, " - "))}`, "YYYY-MM-DD HH:mm:ss A").toISOString();
    const end = moment(`${date} ${last(split(slot, " - "))}`, "YYYY-MM-DD HH:mm:ss A").toISOString();

    const obj = {
      purpose: comment,
      date: moment(date).toISOString(),
      type: 2,
      start: start,
      end: end,
      // cabin: cabinId,
      cabinSlot: [apiSlot],
      diamonds: map(this.props.checked, "id"),
      meetingType: meetingType,
    };

    const objData = {
      ...CREATE_SCHEDULE,
      request: obj,
    };

    UtilService.callApi(objData, (err, data) => {
      if (err) {
        return OpenNotification({
          type: "success",
          title: err.data.message,
        });
      }
      if (data && data.code === "OK") {
        OpenNotification({
          type: "success",
          title: data.message,
        });
        this.props.throwNotifyCount(this.props.notifyCount + 1);
        this.props.onClose();
        this.props.clearAll();
      }
    });
  };

  getWorkingDays = (workingDays) => {
    const workingDates = [];
    let date = moment(new Date());
    workingDays = workingDays - 1;
    if (workingDates?.length === 0 && date.isoWeekday() !== 7) {
      workingDates.push(moment(date).toISOString());
    }
    while (workingDays > 0) {
      date = date.add(1, "days");
      if (date.isoWeekday() !== 7) {
        workingDays -= 1;
        workingDates.push(moment(date).toISOString());
      }
    }
    return workingDates;
  };

  render() {
    const { isTablet } = this.props;
    const { companyName } = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    let finalSlots = this.props.bid ? this.state.bidSlots : this.state.slots;
    if (!this.state.cabinId) {
      finalSlots = this.state.tempList;
    }
    return (
      <>
        <div className="appoinmentOfficeRightTop">
          <Heading className="popupInnerTitle" title={<IntlMessage id="app.Appointment" />}></Heading>
          {companyName && <span className="companyName">({companyName})</span>}
          <span className="appointmentDate">
            {this.state.date && moment(this.state.date).format("ddd, MMM Do YYYY")}
          </span>
        </div>
        <div className="officeBookWrapper">
          {!this.props.bid && (
            <div className="officeBookLeft">
              <div className="officeDateListing">
                <SelectOption
                  allowClear
                  value={this.state.cabinId ? this.state.cabinId : undefined}
                  placeholder={"Select Branch"}
                  selectValue={this.state.cabinArray}
                  onChange={(value) => {
                    this.setState({ cabinId: value }, () => {
                      this.GetSlot(this.state.cabinId);
                    });
                  }}
                  label="Select Branch *"
                />
                <div className="officeTopLeft">
                  <LeftDatePicker
                    date={this.state.date ? this.state.date : ""}
                    Singledate
                    title={<IntlMessage id="app.selectdate" />}
                    onDateChange={(dt) => {
                      this.setState({ date: moment(dt).toISOString() });
                    }}
                    disabledDate={(current) => {
                      return current <= moment().startOf("day") || moment(current).isoWeekday() === 7;
                    }}
                  />
                </div>
                <div className="cardListinOffice">
                  {this.getWorkingDays(7).map((date, i) => (
                    <div
                      key={i}
                      onClick={() => this.setState({ date })}
                      className={`cardListOfficeItem ${moment(this.state.date).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY") && `active`
                        }`}
                    >
                      <div className="cardListOfficeTop">
                        <div className="cardListOfficeDate">{moment(date).format("ddd, MMM Do YYYY")}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="officeBookRight">
            <div className="soltWrapper">
              <div className="soltBookLeft">
                <div className="officeBookSolt smallScroll grayScroll">
                  <div className="officeBookSoltItem">
                    <div className="officeBookSoltTitle">Select time slot *</div>
                    <div className="officeBookSoltInner">
                      {finalSlots.map((d, index) => {
                        const start = moment(d.start).format("hh:mm A");
                        const end = moment(d.end).format("hh:mm A");
                        const display = `${start} - ${end}`;
                        const disabled = moment(`${moment(this.state.date).format("YYYY-MM-DD")} ${end}`) < moment().add(30, 'minutes');
                        return (
                          <SlotItem
                            key={index}
                            onClick={() => this.setState({ slot: display, apiSlot: d })}
                            time={display}
                            className={
                              !this.state.cabinId || !this.state.date
                                ? `allReadyBook`
                                : this.state.slot === display
                                  ? `soltBook`
                                  : disabled
                                    ? `allReadyBook`
                                    : ""
                            }
                            tooltip={"Book Appointment"}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {/* <OfficeSlot title={<IntlMessage id="app.Appointment" />} data={timeSlot.slot1} /> */}
                </div>
                {isTablet ? (
                  <div className="sideBarPopupButton">
                    <span className="commonButton" onClick={this.officeView}>
                      <IntlMessage id="app.RequestAppointment" />
                    </span>
                  </div>
                ) : (
                  <div className="selectVirtualAction">
                    <div className="d-flex">
                      <SelectOption
                        value={this.state.meetingType}
                        placeholder="Select Appointment Type *"
                        //defaultValue={<IntlMessage id="app.SelectVirtualType" />}
                        selectValue={Appointment.virtualType}
                        label="Select Appointment Type *"
                        onChange={(value) => this.setState({ meetingType: value })}
                      />
                      <div className="commonTextArea from-group">
                        <label>Comment</label>
                        <textarea
                          placeholder="Comment"
                          onChange={(e) => this.setState({ comment: e.target.value })}
                        ></textarea>
                      </div>
                      <button onClick={() => this.handleSave()}>
                        <IntlMessage id="app.RequestAppointment" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.office && (
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.office}
            wrapClassName="diamondListinSidebar smallSidebarMobilePopup"
          >
            {/* <OfficeFill /> */}
            <div>
              <div className="offerInputBox">
                <SelectOption
                  value={this.state.meetingType}
                  selectValue={Appointment.virtualType}
                  onChange={(value) => this.setState({ meetingType: value })}
                  placeholder="Select virtual Type*"
                  label="Select virtual Type*"
                ></SelectOption>
                <TextArea
                  value={this.state.comment}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                  label="Comment"
                  placeholder="Comment"
                ></TextArea>
              </div>
              <div className="sideBarPopupButton">
                <span onClick={this.handleSave} className="commonButton">
                  Request Appointment
                </span>
                <span className="commonOutline">Cancel</span>
              </div>
            </div>
          </Drawer>
        )}
      </>
    );
  }
}

export default compose(withMobile)(OfficeSidebar);
